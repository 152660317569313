/* eslint-disable react/jsx-props-no-spreading */
import * as R from 'ramda';
import React, { useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDropzone } from 'react-dropzone';
import { Portal } from 'react-overlays';
import { formatShortDate } from '../../common/util';
import { useLocalValue } from '../../hooks/useLocalValue';
import './Fields.css';

export const TextField = ({
    label,
    style,
    value,
    editable = true,
    onChange,
    autoFocus,
    className = '',
    noBottomLabel,
    realtime = false,
    disabled,
    valueStyle,
    fieldDivStyle,
    inputClass,
    labelClass,
    type,
}) => {
    const [localValue, setLocalValue] = useLocalValue(value);

    return (
        <div
            style={fieldDivStyle}
            className={`field text-field ${className} ${localValue ? 'selected' : ''}`}
        >
            {editable && onChange ? (
                <Focusable autoFocus={autoFocus}>
                    <input
                        type={type}
                        disabled={disabled}
                        style={style}
                        className={`input ${inputClass}`}
                        value={localValue || ''}
                        placeholder={label}
                        onChange={e => {
                            setLocalValue(e.target.value);
                            realtime && onChange(e.target.value);
                        }}
                        onBlur={e => e.target.value !== value && onChange(e.target.value)}
                    />
                </Focusable>
            ) : (
                <div style={{ ...valueStyle, cursor: 'default' }}>{value}</div>
            )}
            {!noBottomLabel && <div className={`label ${labelClass}`}>{label}</div>}
        </div>
    );
};

export const LargeTextField = ({
    label,
    value,
    editable = true,
    onChange,
    rows,
    autoFocus,
    className = '',
    style,
    noBottomLabel,
    valueStyle,
    containerStyle,
    labelClass,
}) => {
    const [localValue, setLocalValue] = useLocalValue(value);
    const lineBreaks = localValue && localValue.match(/\n/g) ? localValue.match(/\n/g).length : 0;

    return (
        <div
            style={containerStyle}
            className={`field large-text-field ${className} ${localValue ? 'selected' : ''}`}
        >
            {editable && onChange ? (
                <Focusable autoFocus={autoFocus}>
                    <textarea
                        className="input"
                        style={{ ...style, height: rows ? null : 15 * lineBreaks + 46 }}
                        rows={rows}
                        value={localValue || ''}
                        placeholder={label}
                        onChange={e => setLocalValue(e.target.value)}
                        onBlur={e => e.target.value !== value && onChange(e.target.value)}
                    />
                </Focusable>
            ) : (
                <div
                    style={{
                        cursor: 'default',
                        whiteSpace: 'pre-line',
                        minWidth: 300,
                        ...valueStyle,
                    }}
                >
                    {value}
                </div>
            )}
            {!noBottomLabel && <div className={`label ${labelClass}`}>{label}</div>}
        </div>
    );
};

const CalendarContainer = ({ children }) => {
    const el = document.getElementById('calendar-portal');
    return (
        <Portal style={{ width: '100vw', transform: null }} container={el}>
            {children}
        </Portal>
    );
};

export const DateField = ({
    label,
    value,
    editable = true,
    onChange,
    className = '',
    late,
    lastVersion,
    noBottomLabel,
    containerStyle,
    labelClass,
    mobile,
}) => (
    <div
        style={{ marginRight: 4, ...containerStyle }}
        className={`field date-field ${className} ${value ? 'selected' : ''}`}
    >
        {editable && onChange ? (
            <DatePicker
                popperContainer={mobile ? undefined : CalendarContainer}
                popperPlacement={mobile ? 'bottom-middle' : undefined}
                popperClassName="datepicker-calender"
                className="datepicker"
                isClearable
                placeholderText={label}
                dateFormat="dd/MM/yyyy"
                value={formatShortDate(value)}
                onChange={e => {
                    e.setHours(12);
                    onChange(e.toISOString());
                }}
                onKeyDown={e => mobile && e.preventDefault()}
            />
        ) : (
            <div
                style={{
                    cursor: 'default',
                    color:
                        late && lastVersion
                            ? 'var(--color-error)'
                            : late
                            ? 'var(--color-error-light)'
                            : null,
                }}
            >
                {formatShortDate(value)}
            </div>
        )}
        {!noBottomLabel && <div className={`label ${labelClass}`}>{label}</div>}
    </div>
);

export const CurrencyField = ({
    label,
    style,
    value,
    editable = true,
    onChange,
    autoFocus,
    className = '',
    labelClass,
    noBottomLabel,
    formato,
}) => {
    const [localValue, setLocalValue] = useLocalValue(value);
    return (
        <div className={`field currency-field ${className} ${localValue ? 'selected' : ''}`}>
            {editable && onChange ? (
                <Focusable autoFocus={autoFocus}>
                    <input
                        style={style}
                        className="input"
                        value={localValue || ''}
                        placeholder={label}
                        onChange={e => {
                            e.target.value.replace(/[^0-9]/g, '').startsWith(0)
                                ? setLocalValue(R.tail(e.target.value.replace(/[^0-9]/g, '')))
                                : setLocalValue(e.target.value.replace(/[^0-9]/g, ''));
                        }}
                        onBlur={e => e.target.value !== value && onChange(e.target.value)}
                    />
                </Focusable>
            ) : (
                <div style={{ width: 150 }}>{formato ? formato(value) : value}</div>
            )}
            {!noBottomLabel && <div className={`label ${labelClass}`}>{label}</div>}
        </div>
    );
};

export const ImageField = ({
    label,
    value,
    editable = true,
    onChange,
    className = '',
    labelStyle,
    labelClass,
}) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: acceptedFiles => {
            onChange && onChange(acceptedFiles[0]);
        },
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
    });

    return (
        <>
            <div
                className={`field image-field ${className} ${value ? 'selected' : ''}`}
                {...getRootProps()}
            >
                {editable && <input {...getInputProps()} />}
                <div
                    className="input"
                    style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {value && (
                        <img
                            src={value}
                            alt="preview"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                aspectRatio: 'keep',
                                objectFit: 'cover',
                            }}
                        />
                    )}
                    {isDragActive && <p>Drop file here ...</p>}
                    {!isDragActive && !value && <p style={labelStyle}>{label}</p>}
                </div>
            </div>
            <div className={`label ${labelClass}`}>{label}</div>
        </>
    );
};
export const FileField = ({
    label,
    value,
    // editable = true,
    onChange,
    className = '',
    style = {},
    dragMessage,
}) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: acceptedFiles => {
            onChange && onChange(acceptedFiles);
        },
    });
    return (
        <div
            className={`field file-field ${className} ${value ? 'selected' : ''}`}
            style={style}
            {...getRootProps()}
        >
            <input {...getInputProps()} />
            <div
                className="input"
                style={{
                    // border: '2px dashed var(--corporate-color-2)',
                    border: `2px dashed ${
                        isDragActive ? 'var(--corporate-color-13)' : 'var(--corporate-color-2)'
                    }`,
                    backgroundColor: isDragActive && 'var(--corporate-color-13-light4)',
                    borderRadius: 'var(--space-normbig)',
                    transition: '0.3s ease',
                }}
            >
                <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        fontSize: '1.2rem',
                        color: 'var(--corporate-color-2)',
                    }}
                >
                    {dragMessage || 'Drop file here ...'}
                </div>
                {!isDragActive && !value && <p>{label}</p>}
            </div>
            <div className="label">{label}</div>
        </div>
    );
};

export const ListField = ({ label, children, className = '', style, inputStyle }) => (
    <div style={style} className={`field list-field ${className} ${children ? 'selected' : ''}`}>
        <div className="input" style={inputStyle}>
            {children}
        </div>
        <div className="label">{label}</div>
    </div>
);

export const Focusable = ({ children, autoFocus }) => {
    const childRef = useRef(null);
    useEffect(() => {
        autoFocus && childRef.current && childRef.current.focus && childRef.current.focus();
    }, []);
    const newChildren = React.Children.map(children, element =>
        React.cloneElement(element, { ref: childRef }),
    );
    return newChildren;
};
