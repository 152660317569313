/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import * as R from 'ramda';
import { IoLinkOutline, IoTrashOutline } from 'react-icons/io5';
import {
    CONNECT_ACTIONS_MODAL,
    PROJECT_ID_KEY,
    store,
    useGraph,
} from '../../common/hooks.config.new';
import { useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { OppTops } from '../Elements/OppTops';
import { useToast } from '../../hooks/useToast';
import { Toast } from '../Elements/Toast';
import { useProjectActions } from '../../hooks/useProjectActions';
import { createNodePatch, createOfflinePush } from '../../common/got-adjunct';
import { Button } from '../Common/Controls';

export const ConnectActionsModal = ({ stack, noEdit }) => {
    const [isOpen, close] = useModalState(CONNECT_ACTIONS_MODAL);
    return (
        <Modal
            name={CONNECT_ACTIONS_MODAL}
            style={{
                height: '90vh',
                top: '5vh',
                width: '80vw',
                left: '10vw',
            }}
            topbar={<IoLinkOutline size={26} color="var(--corporate-color-7)" />}
        >
            {isOpen ? (
                <ProjectActionsModalContent stack={stack} noEdit={noEdit} close={close} />
            ) : null}
        </Modal>
    );
};

const ProjectActionsModalContent = ({ stack: parentStack, noEdit, close }) => {
    const stack = R.append(CONNECT_ACTIONS_MODAL, parentStack);
    const { update } = useGraph(...stack);
    const [selectedAction] = useModalState(CONNECT_ACTIONS_MODAL);

    const projectId = store.getVar(...stack)(PROJECT_ID_KEY);
    const projectActions = useProjectActions(stack, projectId);

    const setToast = useToast('dashboard');

    const toastConfig = {
        textOnStart: 'Saving data...',
        textOnSuccess: 'Successfully saved.',
        textOnError: 'You are offline. Please sync when back online.',
    };

    const save = createOfflinePush(stack, setToast, toastConfig);

    const connectSuccessor = nextTop => {
        const prevTopId = selectedAction.top.id;
        const nextTopId = nextTop.top.id;
        const nextBusinessId = nextTop.top.businessId;
        const prevBusinessId = selectedAction.top.businessId;
        update(createNodePatch(prevTopId, 'next', { id: nextTopId, businessId: nextBusinessId }));
        update(
            createNodePatch(nextTopId, 'previous', { id: prevTopId, businessId: prevBusinessId }),
        );
        save();
        close();
    };

    const connectPredecessor = prevTop => {
        const prevTopId = prevTop.top.id;
        const nextTopId = selectedAction.top.id;
        const nextBusinessId = selectedAction.top.businessId;
        const prevBusinessId = prevTop.top.businessId;
        update(createNodePatch(prevTopId, 'next', { id: nextTopId, businessId: nextBusinessId }));
        update(
            createNodePatch(nextTopId, 'previous', { id: prevTopId, businessId: prevBusinessId }),
        );
        save();
        close();
    };

    const [actionSearch, setActionSearch] = useState('');

    const searchedActions = searchString => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]+[u00C0-u017F]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.filter(
                a =>
                    (a.description && a.description.toUpperCase().search(string) !== -1) ||
                    (a.businessId && a.businessId.toUpperCase().search(string) !== -1),
            )(projectActions);
        }
        return projectActions;
    };

    return (
        <>
            <div className="columns">
                <div>
                    <Headline actionSearch={actionSearch} setActionSearch={setActionSearch} />
                    <OppTops
                        stack={stack}
                        opps={searchedActions(actionSearch)}
                        connectSuccessor={connectSuccessor}
                        connectPredecessor={connectPredecessor}
                        noEdit={noEdit}
                        connectMode={selectedAction.mode}
                    />
                </div>
            </div>
            <Toast name="assoc-message" />
        </>
    );
};

const Headline = ({ actionSearch, setActionSearch }) => (
    <div className="modal-headline">
        <div style={{ flex: 1 }}>Available actions</div>
        <div style={{ display: 'flex', alignItems: 'center', flex: 2 }}>
            <div style={{ flex: 1 }} />
            <div className="animated-searchbar-half" style={{ flex: 3, animation: 'none' }}>
                <input
                    placeholder="search project actions..."
                    value={actionSearch}
                    onChange={e => setActionSearch(e.target.value)}
                    style={{ marginBottom: 0 }}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Button style={{ marginLeft: 3 }} onClick={() => setActionSearch('')}>
                    <IoTrashOutline size={17} />
                </Button>
            </div>
        </div>
        <div style={{ flex: 1 }} />
    </div>
);
