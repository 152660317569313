import * as R from 'ramda';
import * as Util from '@gothub-team/got-util';
import * as RA from 'ramda-adjunct';
import { useEffect, useMemo } from 'react';
import { APPLICATION_ROOT_NODE } from '../../../common/config';
import { filterCriticalOpps } from '../../../common/filterCriticalOpps';
import {
    ALL_ACTION_TYPES,
    CONTACT_DIRECTORY_CONTACT_EDGE,
    OPP_TRACKING_GLOBAL,
    ORGANIZATION_OPPORTUNITY_TRACKING_EDGE,
    ORGANIZATION_PROJECT_EDGE,
    ROOT_ORGANIZATION_EDGE,
    SORTED_GROUPED_TOPS_KEY,
    store,
    TOP_RESPONSIBLE_EDGE,
    useGraph,
    USER_TOPS,
} from '../../../common/hooks.config.new';
import { Id } from '../../../common/util';
import { useModalState } from '../../../hooks/useModal';
import { useUserEmail } from '../../../hooks/useUserEmail';
import { PROJECT_CONTACT_CHOICES } from '../../../hooks/useChoices';

const constructContactTopView = contactId =>
    contactId
        ? {
              [contactId]: {
                  edges: {
                      [TOP_RESPONSIBLE_EDGE]: {
                          reverse: true,
                          as: 'tops',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

const constructProjectContactsView = projectId =>
    projectId
        ? {
              [Id.contactDirectory(projectId)]: {
                  as: projectId,
                  edges: {
                      [CONTACT_DIRECTORY_CONTACT_EDGE]: {
                          as: 'contacts',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

const constructAllProjectsView = rootNodeId =>
    rootNodeId
        ? {
              [rootNodeId]: {
                  as: 'root',
                  include: {
                      node: true,
                      edges: true,
                  },
                  edges: {
                      [ROOT_ORGANIZATION_EDGE]: {
                          as: 'organizations',
                          include: {
                              node: true,
                              edges: true,
                          },
                          edges: {
                              [ORGANIZATION_PROJECT_EDGE]: {
                                  as: 'projects',
                                  include: {
                                      node: true,
                                      edges: true,
                                  },
                              },
                          },
                      },
                  },
              },
          }
        : {};

const constructAllOppTrackingRightsView = rootNodeId =>
    rootNodeId
        ? {
              [rootNodeId]: {
                  as: 'root',
                  include: {
                      node: true,
                  },
                  edges: {
                      [ROOT_ORGANIZATION_EDGE]: {
                          as: 'organizations',
                          include: {
                              node: true,
                              edges: true,
                          },
                          edges: {
                              [ORGANIZATION_OPPORTUNITY_TRACKING_EDGE]: {
                                  as: 'oppTracking',
                                  include: {
                                      node: true,
                                      rights: true,
                                  },
                              },
                          },
                      },
                  },
              },
          }
        : {};

export const GlobalDashboardCalculator = ({ stack }) => {
    const user = useUserEmail();
    const { useView, pull, useVar } = useGraph(...stack);
    const [, stopLoading] = useModalState('loader');

    // find all users opp tracking rights
    const allOppTrackingRightsView = constructAllOppTrackingRightsView(APPLICATION_ROOT_NODE);
    useEffect(() => {
        APPLICATION_ROOT_NODE && pull(allOppTrackingRightsView);
    }, [APPLICATION_ROOT_NODE]);
    const [, setContactChoices] = useVar(PROJECT_CONTACT_CHOICES);
    const allOppTrackingRights = useView(allOppTrackingRightsView);
    const mergedOppTrackingRights = R.compose(
        R.values,
        R.mergeAll,
        R.map(R.pathOr({}, ['oppTracking'])),
        R.values,
        R.pathOr({}, ['root', 'organizations']),
    )(allOppTrackingRights);
    const canUserTrackOpps = R.filter(
        orgTracking =>
            R.has(user)(R.pathOr({}, ['rights', 'user'], orgTracking)) &&
            R.path(['rights', 'user', user, 'read'], orgTracking) === true,
    )(mergedOppTrackingRights);
    const [, setOppTrackingGlobal] = useVar(OPP_TRACKING_GLOBAL);

    // find all users projects
    const allProjectsView = constructAllProjectsView(APPLICATION_ROOT_NODE);
    useEffect(() => {
        APPLICATION_ROOT_NODE && pull(allProjectsView);
    }, [APPLICATION_ROOT_NODE]);
    const allProjects = useView(allProjectsView);

    const mergedProjects = R.compose(
        R.mergeAll,
        R.map(R.pathOr({}, ['projects'])),
        R.values,
        R.pathOr({}, ['root', 'organizations']),
    )(allProjects);

    const projectsOrgId = R.compose(
        R.map(org => ({ orgId: org.nodeId, projectIds: R.keys(org.projects) })),
        R.filter(org => org.projects && org),
        R.values,
        R.pathOr({}, ['root', 'organizations']),
    )(allProjects);

    const allActionStatusTypes = R.compose(
        R.map(status => ({ label: status })),
        R.uniq,
        R.unnest,
        R.map(p => R.propOr([], 'actionStatusTypes', p.node)),
        R.values,
    )(mergedProjects);

    const projectsContactsView = R.compose(
        R.mergeAll,
        R.map(constructProjectContactsView),
    )(R.keys(mergedProjects));

    useEffect(() => {
        RA.isNotNilOrEmpty(projectsContactsView) && pull(projectsContactsView);
    }, [allProjects]);
    const cdsContactsViewRes = useView(projectsContactsView);

    const projectsUserContacts = R.map(
        R.compose(
            R.values,
            R.filter(R.pathEq(['node', 'email'], user)),
            R.pathOr({}, ['contacts']),
        ),
    )(cdsContactsViewRes);

    const filterDefaultUserContacts = R.compose(
        R.filter(R.compose(R.not, R.pathEq(['node', 'name'], `User - ${user}`))),
        R.flatten,
        R.values,
    )(projectsUserContacts);
    const userNodeBag = R.head(filterDefaultUserContacts);

    const userContactChoice = [
        {
            value: userNodeBag?.node?.id,
            label: userNodeBag?.node?.name,
            node: userNodeBag?.node,
        },
    ];

    const userContactTopsView = R.compose(
        R.mergeAll,
        R.map(({ nodeId: contactId }) => constructContactTopView(contactId)),
        R.flatten,
        R.values,
    )(projectsUserContacts);

    useEffect(() => {
        const load = async () => {
            await pull(userContactTopsView);
            stopLoading();
        };
        load();
    }, [cdsContactsViewRes]);
    const contactTopsViewResult = useView(userContactTopsView);

    // Create user tops with history (user = responsible)
    const allUserTops = R.compose(
        R.mergeAll,
        R.map(R.prop('tops')),
        R.filter(R.propOr(false, 'tops')),
        R.values,
    )(contactTopsViewResult);
    const groupedTops = groupTopsByBID({
        tops: allUserTops,
        userId: userNodeBag?.nodeId,
        userName: userNodeBag?.node?.name,
    });

    const sortedGroupedTops = useMemo(
        () =>
            R.map(
                R.compose(
                    R.sortBy(
                        R.compose(
                            entry =>
                                R.propOr(false, 'meetingDate', entry) ||
                                R.propOr(false, 'modifiedDate', entry) ||
                                R.propOr(false, 'createdDate', entry),
                        ),
                    ),
                    R.values(),
                ),
            )(groupedTops),
        [groupedTops],
    );

    const topRows = useMemo(
        () =>
            R.compose(
                R.sortBy(R.prop('businessId')),
                R.values,
                R.map(entries => ({
                    ...R.last(entries),
                    previousVersions: R.dropLast(1, entries),
                })),
            )(sortedGroupedTops),
        [sortedGroupedTops],
    );

    setOppTrackingGlobal(canUserTrackOpps);

    const projectsUserTops = useMemo(
        () =>
            R.compose(
                R.mergeAll,
                R.map(projectId => {
                    const userContacts = R.propOr([], projectId, projectsUserContacts);

                    const projectUserTops = R.compose(
                        R.values,
                        R.mergeAll,
                        R.map(({ nodeId: contactId }) =>
                            R.pathOr({}, [contactId, 'tops'], contactTopsViewResult),
                        ),
                    )(userContacts);

                    const projectUserTopsFromTopRows = R.compose(
                        R.filter(t => t),
                        R.map(userTop => {
                            const topId = R.prop('nodeId', userTop);
                            const topRow = R.find(R.pathEq(['top', 'id'], topId))(topRows);
                            if (!topRow) return null;
                            return topRow;
                        }),
                    )(projectUserTops);

                    const getProjectsOrgId = pId => {
                        const projectsOrg = R.compose(
                            R.values,
                            R.filter(o => R.includes(pId, o.projectIds)),
                        )(projectsOrgId);
                        return projectsOrg[0]?.orgId || '';
                    };

                    // const actionsPrep = R.filter(R.pathEq(['node', 'type'], 'a'))(projectUserTops);
                    const actionsPrep = R.filter(R.pathEq(['top', 'type'], 'a'))(
                        projectUserTopsFromTopRows,
                    );
                    const actions = R.map(a => {
                        const newA = {
                            node: a.top,
                            nodeId: a.top?.id,
                            projectId,
                            orgId: getProjectsOrgId(projectId),
                        };
                        return newA;
                    })(actionsPrep);
                    const criticalActionsPrep = R.filter(R.pathEq(['node', 'status'], 'Late'))(
                        actions,
                    );
                    const criticalActions = R.map(ca => {
                        const newA = {
                            ...ca,
                            projectId,
                            orgId: getProjectsOrgId(projectId),
                        };
                        return newA;
                    })(criticalActionsPrep);
                    const oppsPrep = R.filter(R.pathEq(['top', 'type'], 'o'))(
                        projectUserTopsFromTopRows,
                    );
                    const opps = R.map(o => {
                        const newO = {
                            node: o.top,
                            nodeId: o.top?.id,
                            projectId,
                            orgId: getProjectsOrgId(projectId),
                        };
                        return newO;
                    })(oppsPrep);
                    const criticalOppsPrep = filterCriticalOpps(stack, projectId)(opps);
                    const criticalOpps = R.map(co => {
                        const newO = {
                            ...co,
                            projectId,
                            orgId: getProjectsOrgId(projectId),
                        };
                        return newO;
                    })(criticalOppsPrep);
                    if (R.length(projectUserTops) === 0) return null;

                    return {
                        [projectId]: {
                            projectId,
                            tops: {
                                all: projectUserTops,
                                actions,
                                criticalActions,
                                opps,
                                criticalOpps,
                            },
                        },
                    };
                }),
            )(R.keys(mergedProjects)),
        [contactTopsViewResult],
    );

    useEffect(() => {
        setContactChoices({ choices: userContactChoice });
        store.setVar(R.last(stack))(SORTED_GROUPED_TOPS_KEY, { tops: sortedGroupedTops });
    }, [sortedGroupedTops]);

    useEffect(() => {
        store.setVar(R.last(stack))(USER_TOPS, projectsUserTops);
        store.setVar(R.last(stack))(ALL_ACTION_TYPES, allActionStatusTypes);
    }, [projectsUserTops]);

    return null;
};

const groupTopsByBID = ({ tops = {}, userId, userName }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [getResult, , overResult] = Util.useResult({});
    R.map(({ node: top }) => {
        const businessId = R.prop('businessId', top);
        return overResult(
            Util.assocPathMutate([businessId, top.id], {
                top,
                responsible: { id: userId },
                author: { name: userName },
                [top.modifiedDate ? 'editedDate' : 'createdDate']:
                    top.modifiedDate || top.createdDate,
            }),
        );
    })(tops || {});
    return getResult();
};
