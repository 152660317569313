import { useAtom } from '@gothub-team/got-atom';
import React from 'react';
import { useMouse } from '../../../../hooks/useMouse';

export const ChartToolTip = ({ tooltipAtom, children }) => {
    const isOpen = useAtom(tooltipAtom);

    if (isOpen == null) return null;

    return <Content>{children}</Content>;
};

const Content = ({ children }) => {
    const [mouseX, mouseY] = useMouse();

    return (
        <div
            style={{
                position: 'fixed',
                top: mouseY + 20,
                left: mouseX + 10,
                zIndex: 1000,
            }}
        >
            {children}
        </div>
    );
};
