import React, { useMemo } from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { ReadingViewTopMobile } from './ReadingViewTopMobile';
import { useMeetingTops } from '../../hooks/useMeetingTops';
import { topHierarchy } from '../../common/util';

export const ReadingViewMobile = ({ stack, meetingId }) => {
    const sortedTopBags = useMeetingTops(stack, meetingId);
    const hierarchy = useMemo(
        () => R.compose(topHierarchy, R.map(R.prop('node')))(sortedTopBags),
        [sortedTopBags],
    );

    return (
        <div style={{ padding: 24, background: 'white' }} className="fader">
            <div
                style={{
                    display: 'flex',
                    fontSize: '4rem',
                    flex: 1,
                    justifyContent: 'center',
                }}
            >
                Meeting Content
            </div>
            <div
                style={{
                    borderBottom: '2px solid black',
                    display: 'flex',
                    marginTop: 24,
                }}
            >
                <div style={{ fontSize: '2rem', fontWeight: 'bold', width: '10%' }}>No.</div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        fontSize: '2rem',
                        fontWeight: 'bold',
                        width: '5%',
                    }}
                >
                    T.
                </div>
                <div style={{ fontSize: '2rem', fontWeight: 'bold', width: '65%' }}>
                    Description
                </div>
                <div
                    style={{
                        display: 'flex',
                        fontSize: '2rem',
                        fontWeight: 'bold',
                        width: '20%',
                        justifyContent: 'flex-end',
                    }}
                >
                    Responsible
                </div>
            </div>
            {RA.mapIndexed(({ node: top }, i) => (
                <ReadingViewTopMobile hierarchy={hierarchy[top.id]} top={top} stack={stack} i={i} />
            ))(sortedTopBags)}
            <div style={{ height: '20vh' }} />
        </div>
    );
};
