import React from 'react';
import * as R from 'ramda';
import { IoCreateOutline } from 'react-icons/io5';
import { useModalState } from '../../hooks/useModal';
import { ImageField, TextField } from '../Common/Fields';
import { Modal } from '../Common/Modal';
import { SaveButton } from '../Elements/SaveButton';

export const EditOrgModal = ({
    organization,
    organizationId,
    organizationLogoUrl,
    save,
    update,
    setFile,
}) => {
    const [isOpen, close] = useModalState('new-org');
    return (
        <Modal
            name="new-org"
            style={{
                height: '69vh',
                width: '38vw',
                top: '15.5vh',
                left: '31vw',
            }}
            topbar={<IoCreateOutline size={25} color="var(--corporate-color-7)" />}
        >
            {isOpen ? (
                <EditOrgModalContent
                    isOpen={isOpen}
                    organization={organization}
                    organizationId={organizationId}
                    organizationLogoUrl={organizationLogoUrl}
                    save={save}
                    update={update}
                    setFile={setFile}
                    close={close}
                />
            ) : null}
        </Modal>
    );
};

const EditOrgModalContent = ({
    organization,
    organizationId,
    organizationLogoUrl,
    save,
    update,
    setFile,
    close,
}) => (
    <div className="columns">
        <div>
            <div className="modal-headline">
                <div>Edit Organization</div>
            </div>
            <TextField
                label="Organization name"
                value={organization.name || ''}
                onChange={value => update(R.assoc('name', value, organization))}
            />
            <ImageField
                label="Organization logo"
                value={organizationLogoUrl || ''}
                onChange={file => {
                    if (!file) {
                        return;
                    }
                    setFile(organizationId)('organizationLogo', file.name, file);
                    save();
                }}
                className="org-img-field"
                labelClass="label-desktop"
            />
            <SaveButton save={save} close={close} />
        </div>
    </div>
);
