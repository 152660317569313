import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { useModalState } from '../../hooks/useModal';
import { ModalMobile } from '../Common/ModalMobile';
import {
    EDIT_DASHBOARD_TOP_MODAL_MOBILE,
    GROUPED_TOPS_KEY,
    MEETING_ATTENDEE_EDGE,
    MEETING_TOP_EDGE,
    PROJECT_ID_KEY,
    store,
    SUBPROJECT_ID_KEY,
    SUBPROJECT_MEETING_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { Button } from '../../Components/Common/Controls';
import { useContact } from '../../hooks/useContact';
import { useNode, useNodeFromVar } from '../../hooks/useNode';
import { createDummyGraph } from '../../common/got-adjunct';
import { getTopInfo } from '../../common/topinfo.util';
import {
    CurrencyField,
    DateField,
    LargeTextField,
    TextField,
} from '../../Components/Common/Fields';
import {
    PROJECT_ACTION_STATUS_TYPE_CHOICES,
    PROJECT_CONTACT_CHOICES,
    PROJECT_OPP_CATEGORY_TYPE_CHOICES,
    PROJECT_OPP_STATUS_TYPE_CHOICES,
    PROJECT_PHASE_CHOICES,
} from '../../hooks/useChoices';
import {
    ChoiceDropdownField,
    ResponsibleChoiceDropdownField,
} from '../../Components/Elements/ChoiceDropdownField';
import { useTopResponsible } from '../../hooks/useTopResponsible';
import { newId, newIsoDate, newMsDate } from '../../common/util';

export const EditDashboardTopModalMobile = ({ stack: parentStack }) => {
    const [isOpen] = useModalState(EDIT_DASHBOARD_TOP_MODAL_MOBILE);
    const stack = R.append(EDIT_DASHBOARD_TOP_MODAL_MOBILE, parentStack);
    const { clear } = useGraph(...stack);
    return (
        <ModalMobile name={EDIT_DASHBOARD_TOP_MODAL_MOBILE} stack={stack} onBack={clear} onSave>
            {isOpen ? <ModalContent stack={stack} /> : null}
        </ModalMobile>
    );
};

const ModalContent = ({ stack }) => {
    const { useVar, update } = useGraph(...stack);
    const [{ isNew, user }] = useModalState(EDIT_DASHBOARD_TOP_MODAL_MOBILE);
    const [topId] = useState(newId());
    const [meetingId] = useState(newId());
    const [adjustedTop, setAdjustedTop] = useState({});
    const [topToCreate] = useModalState('createDashboardTop');

    const [projectId] = useVar(PROJECT_ID_KEY);
    const { contact } = useContact(stack, projectId, user);

    const [responsible, setResponsible] = useTopResponsible(stack, topId);

    const author = contact && [
        {
            node: contact.node,
            nodeId: contact.nodeId,
            metadata: { presence: 'M', order: newMsDate() },
        },
    ];

    const { node: project } = useNodeFromVar(stack, PROJECT_ID_KEY);
    const { node: subproject } = useNodeFromVar(stack, SUBPROJECT_ID_KEY);

    const createTop = () => {
        const subprojectId = store.getVar(...stack)(SUBPROJECT_ID_KEY);
        const {
            add,
            inheritRights,
            assoc,
            getGraph,
        } = createDummyGraph();

        const newMeeting = {
            id: meetingId,
            isDummy: true,
            title: 'DUMMY',
            closed: true,
            date: newIsoDate(),
        };
        add(SUBPROJECT_MEETING_EDGE)(subprojectId)(newMeeting);
        inheritRights(meetingId)(subprojectId);
        assoc(MEETING_ATTENDEE_EDGE)(meetingId)(contact.node, {
            presence: 'M',
            order: newMsDate(),
        });

        const newTop = {
            id: topId,
            createdDate: newIsoDate(),
            businessId: '',
            dashboard: true,
            type: R.propOr('', 'type', topToCreate),
            status: R.propOr('', 'status', topToCreate),
            category: R.propOr('', 'category', topToCreate),
        };

        add(MEETING_TOP_EDGE)(meetingId)(newTop, { order: newMsDate() });
        inheritRights(topId)(subprojectId);

        store.mergeGraph(getGraph(), EDIT_DASHBOARD_TOP_MODAL_MOBILE);

        // update topinfo
        const topInfo = getTopInfo({
            project,
            subproject,
            meeting: newMeeting,
            attendees: contact ? author : {},
            top: newTop,
            protocolId: 'DB',
        });
        setAdjustedTop(topInfo);
    };

    useEffect(() => {
        contact && createTop();
    }, [contact]);

    const top = useNode(stack, topId);

    const setTopBusinessID = () => {
        const { tops: groupedProjectTops } = store.getVar(...stack)(GROUPED_TOPS_KEY);
        const pbid = project.businessId || project.title;
        const spbid = subproject.businessId || subproject.title;
        const id = R.slice(9, 13, newId());
        const topType = top.node.type || 'missingType';
        const noOfTops = R.length(R.values(groupedProjectTops));
        const topBusinessId = `${pbid}-${spbid}-Dashboard-${id}-${topType}-${noOfTops}`;
        setAdjustedTop({
            ...adjustedTop,
            businessId: topBusinessId,
            top: { ...adjustedTop.top, businessId: topBusinessId },
        });
        update(R.assoc('businessId', topBusinessId, top.node));
    };

    useEffect(() => {
        top?.node?.type && setTopBusinessID();
    }, [top?.node?.type]);

    const [animation, setAnimation] = useState('pulse-type-indicator');

    return (
        <>
            <div className="modal-headline-mobile">
                {isNew ? 'Create' : 'Edit'} Agenda Item
                <div
                    className="modal-headline-filler"
                    style={{ backgroundColor: 'var(--corporate-color-14-ligh)' }}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {!top?.node?.type ? (
                    <div
                        onClick={() => update(R.assoc('type', '', top.node))}
                        className={`no-top-type-indicator ${animation}`}
                    >
                        ?
                    </div>
                ) : (
                    <div
                        onClick={() => update(R.assoc('type', '', top.node))}
                        className={`item-type type-${top?.node?.type} fade-scale-mobile-buttons`}
                    >
                        {top?.node?.type.toUpperCase()}
                    </div>
                )}
                {!top?.node?.type && (
                    <div className="select-top-type-message fader">Select top type</div>
                )}
                <div
                    className="select-top-type-message fader"
                    style={{ fontSize: '2.5rem', color: 'var(--corporate-color-2-light)' }}
                >
                    {R.pathOr('', ['node', 'businessId'], top)}
                </div>
            </div>
            <div style={{ marginTop: '4rem' }}>
                <TopInputsSwitch
                    top={top.node || {}}
                    topType={R.pathOr('', ['node', 'type'], top)}
                    stack={stack}
                    update={update}
                    responsible={responsible}
                    setResponsible={setResponsible}
                />
            </div>
            {!top?.node?.type && (
                <SelectTopTypeButtons
                    top={top.node || {}}
                    update={update}
                    animation={animation}
                    setAnimation={setAnimation}
                />
            )}
            <div style={{ height: '20vh' }} />
        </>
    );
};

const SelectTopTypeButtons = ({ top, update, animation, setAnimation }) => (
    <div>
        <Button
            className="item-button item-button-a"
            style={{
                backgroundColor: 'var(--corporate-color-14)',
                bottom: '75rem',
                boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
            }}
            onClick={() => {
                update(R.assoc('type', 'a', top));
                animation !== 'fade-scale-type' && setAnimation('fade-scale-type');
            }}
        >
            A
        </Button>
        <Button
            className="item-button item-button-o"
            style={{
                backgroundColor: 'var(--corporate-color-13)',
                bottom: '63rem',
                boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
            }}
            onClick={() => {
                update(R.assoc('type', 'o', top));
                animation !== 'fade-scale-type' && setAnimation('fade-scale-type');
            }}
        >
            O
        </Button>
        <Button
            className="item-button item-button-s"
            style={{
                backgroundColor: 'grey',
                bottom: '51rem',
                boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
            }}
            onClick={() => {
                update(R.assoc('type', 's', top));
                animation !== 'fade-scale-type' && setAnimation('fade-scale-type');
            }}
        >
            S
        </Button>
        <Button
            className="item-button item-button-d"
            style={{
                backgroundColor: 'var(--corporate-color-7)',
                bottom: '39rem',
                boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
            }}
            onClick={() => {
                update(R.assoc('type', 'd', top));
                animation !== 'fade-scale-type' && setAnimation('fade-scale-type');
            }}
        >
            D
        </Button>
        <Button
            className="item-button item-button-i"
            style={{
                backgroundColor: '#C5C9D3',
                bottom: '27rem',
                boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
            }}
            onClick={() => {
                update(R.assoc('type', 'i', top));
                animation !== 'fade-scale-type' && setAnimation('fade-scale-type');
            }}
        >
            I
        </Button>
        <Button
            className="item-button item-button-r"
            style={{
                backgroundColor: 'var(--corporate-color-12)',
                bottom: '15rem',
                boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
            }}
            onClick={() => {
                update(R.assoc('type', 'r', top));
                animation !== 'fade-scale-type' && setAnimation('fade-scale-type');
            }}
        >
            R
        </Button>
    </div>
);

const TopInputsSwitch = ({ top, topType, update, stack, responsible, setResponsible }) => {
    switch (topType) {
        case 'a':
            return (
                <TypeAInputs
                    top={top}
                    update={update}
                    stack={stack}
                    responsible={responsible}
                    setResponsible={setResponsible}
                />
            );
        case 'o':
            return (
                <TypeOInputs
                    top={top}
                    update={update}
                    stack={stack}
                    responsible={responsible}
                    setResponsible={setResponsible}
                />
            );
        case 's':
            return <TypeISDInputs stack={stack} top={top} update={update} />;
        case 'd':
            return <TypeISDInputs stack={stack} top={top} update={update} />;
        case 'i':
            return <TypeISDInputs stack={stack} top={top} update={update} />;
        case 'r':
            return (
                <TypeRInputs
                    top={top}
                    update={update}
                    stack={stack}
                    responsible={responsible}
                    setResponsible={setResponsible}
                />
            );
        default:
            return null;
    }
};

const TypeAInputs = ({ top, update, stack, responsible, setResponsible }) => (
    <div className="fader">
        <div className="mobile-textarea-height-25">
            <LargeTextField
                rows={18}
                label="Description"
                value={R.propOr('', 'description', top)}
                onChange={value => update(R.assoc('description', value, top))}
                className="input-mobile"
                labelClass="label-mobile"
            />
        </div>
        <ChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_ACTION_STATUS_TYPE_CHOICES}
            onChange={value => update(R.assoc('status', value.value, top))}
            value={R.propOr('', 'status', top)}
            label="Status"
            labelClass="dd-label-mobile"
        />
        <ChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_PHASE_CHOICES}
            label="Phase"
            value={R.propOr('', 'phase', top)}
            onChange={option => update(R.assoc('phase', option.value, top))}
            labelClass="dd-label-mobile"
        />
        <div className="mobile-dates">
            <DateField
                className="mobile"
                containerStyle={{ display: 'flex', flex: 1 }}
                labelClass="date-label-mobile"
                late={R.propOr('', 'status', top) === 'Late'}
                label="Due Date"
                value={R.propOr('', 'dueDate', top)}
                locale="en-gb"
                onChange={value => update(R.assoc('dueDate', value, top))}
                mobile
            />
        </div>
        <ResponsibleChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_CONTACT_CHOICES}
            onChange={contact => setResponsible(contact.node)}
            value={R.propOr('', 'id', responsible)}
            label="Responsible"
            labelClass="dd-label-mobile"
        />
    </div>
);

const TypeOInputs = ({ top, update, stack, responsible, setResponsible }) => (
    <div className="fader">
        <div className="mobile-textarea-height-25">
            <LargeTextField
                rows={18}
                label="Description"
                value={R.propOr('', 'description', top)}
                onChange={value => update(R.assoc('description', value, top))}
                className="input-mobile"
                labelClass="label-mobile"
            />
        </div>
        <CurrencyField
            stack={stack}
            label="Savings"
            value={R.propOr('', 'savings', top)}
            onChange={value => update(R.assoc('savings', value, top))}
            className="input-mobile"
            labelClass="label-mobile"
        />
        <ChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_OPP_STATUS_TYPE_CHOICES}
            onChange={value => update(R.assoc('status', value.value, top))}
            value={R.propOr('', 'status', top)}
            label="Status"
            labelClass="dd-label-mobile"
        />
        <ChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_OPP_CATEGORY_TYPE_CHOICES}
            onChange={value => update(R.assoc('category', value.value, top))}
            value={R.propOr('', 'category', top)}
            label="Category"
            labelClass="dd-label-mobile"
        />
        <ChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_PHASE_CHOICES}
            label="Phase"
            value={R.propOr('', 'phase', top)}
            onChange={option => update(R.assoc('phase', option.value, top))}
            labelClass="dd-label-mobile"
        />
        <ResponsibleChoiceDropdownField
            mobile
            stack={R.init(stack)}
            choiceType={PROJECT_CONTACT_CHOICES}
            onChange={contact => setResponsible(contact.node)}
            value={R.propOr('', 'id', responsible)}
            label="Responsible"
            labelClass="dd-label-mobile"
        />
    </div>
);

const TypeRInputs = ({ top, update, stack, responsible, setResponsible }) => (
    <div className="fader">
        <div className="mobile-textarea-height-25">
            <LargeTextField
                rows={18}
                label="Description"
                value={R.propOr('', 'description', top)}
                onChange={value => update(R.assoc('description', value, top))}
                className="input-mobile"
                labelClass="label-mobile"
            />
        </div>
        <TextField
            label="Category"
            value={R.propOr('', 'category', top)}
            onChange={value => update(R.assoc('category', value, top))}
            className="input-mobile"
            labelClass="label-mobile"
        />
        <TextField
            label="Reference"
            value={R.propOr('', 'reference', top)}
            onChange={value => update(R.assoc('reference', value, top))}
            className="input-mobile"
            labelClass="label-mobile"
        />
        <TextField
            label="Cost Increase"
            value={R.propOr('', 'costIncrease', top)}
            onChange={value => update(R.assoc('costIncrease', value, top))}
            className="input-mobile"
            labelClass="label-mobile"
        />
        <ChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_PHASE_CHOICES}
            label="Project phase"
            value={R.propOr('', 'phase', top)}
            onChange={option => update(R.assoc('phase', option.value, top))}
            labelClass="dd-label-mobile"
        />
        <ResponsibleChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_CONTACT_CHOICES}
            onChange={contact => setResponsible(contact.node)}
            value={R.propOr('', 'id', responsible)}
            label="Responsible"
            labelClass="dd-label-mobile"
        />
    </div>
);

const TypeISDInputs = ({ stack, top, update }) => (
    <div className="fader">
        <div className="mobile-textarea-height-25">
            <LargeTextField
                rows={18}
                label="Description"
                value={R.propOr('', 'description', top)}
                onChange={value => update(R.assoc('description', value, top))}
                className="input-mobile"
                labelClass="label-mobile"
            />
        </div>
        <ChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_PHASE_CHOICES}
            label="Project phase"
            value={R.propOr('', 'phase', top)}
            onChange={option => update(R.assoc('phase', option.value, top))}
            labelClass="dd-label-mobile"
        />
    </div>
);
