import React from 'react';
import { TopsAndChartsArea } from './TopsAndChartsArea';
import { DashboardStateSelector } from './DashboardStateSelector';

export const DashboardContent = ({ stack }) => (
    <>
        <DashboardStateSelector stack={stack} />
        <TopsAndChartsArea stack={stack} />
    </>
);
