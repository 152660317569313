import React, { useState } from 'react';
import * as R from 'ramda';
import { IoAddOutline, IoCloseOutline, IoLockOpenOutline } from 'react-icons/io5';
import { Modal } from '../Common/Modal';
import { ListField, TextField } from '../Common/Fields';
import { Button, Checkbox } from '../Common/Controls';
import { useModalState } from '../../hooks/useModal';
import { constructNodeView } from '../../common/got-adjunct';
import { useGraph } from '../../common/hooks.config.new';
import { useUserEmail } from '../../hooks/useUserEmail';
import { SaveButton } from '../Elements/SaveButton';

export const OrganizationRightsModal = ({ stack, save }) => {
    const [isOpen, close] = useModalState('organization-rights');
    return (
        <Modal
            name="organization-rights"
            style={{
                width: '50vw',
                left: '25vw',
                height: '75vh',
                top: '12.5vh',
            }}
            topbar={<IoLockOpenOutline size={23} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <ModalContent stack={stack} save={save} close={close} /> : null}
        </Modal>
    );
};

export const ModalContent = ({ stack, save, close }) => {
    const { useView, setRights } = useGraph(...stack);
    const [organizationId] = useModalState('organization-rights');
    const organizationView = constructNodeView(organizationId, { rights: true });
    const { [organizationId]: { rights } = {} } = useView(organizationView);

    const user = useUserEmail();
    const userRights = R.compose(
        R.sortBy(R.ifElse(R.propOr(false, 'isCurrentUser'), R.always('0000'), R.prop('user'))),
        R.filter(
            R.anyPass([
                R.propOr(false, 'read'),
                R.propOr(false, 'write'),
                R.propOr(false, 'admin'),
            ]),
        ),
        R.values,
        R.mapObjIndexed((val, key) => ({ ...val, user: key, isCurrentUser: key === user })),
        R.prop('user'),
    )(rights);

    const [input, setInput] = useState();

    return (
        <div className="columns">
            <div>
                <div className="modal-headline">
                    <div>Manage Organization Admins</div>
                </div>
                <div className="field-column">
                    <TextField value={input} onChange={value => setInput(value)} label="E-Mail" />
                    <Button
                        onClick={() => {
                            if (input) {
                                setRights(organizationId)(input, {
                                    read: true,
                                    write: true,
                                    admin: true,
                                });
                                setInput();
                            }
                        }}
                    >
                        <IoAddOutline size={20} />
                        <p style={{ marginLeft: 5 }}>Add Organization Admin</p>
                    </Button>
                </div>
                <ListField
                    className
                    style={{ flex: 1, flexBasis: 150 }}
                    label="Authorized contacts"
                >
                    {R.map(userRight => (
                        <div key={userRight.user} className="row">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {userRight.user}
                                {user === userRight.user && <p className="user-badge">User</p>}
                            </div>
                            <div className="buttons">
                                <Checkbox label="Read" value={userRight.read} disabled />
                                <Checkbox label="Write" value={userRight.write} disabled />
                                <Checkbox label="Admin" value={userRight.admin} disabled />
                                <Button
                                    onClick={() => {
                                        setRights(organizationId)(userRight.user, {
                                            read: false,
                                            write: false,
                                            admin: false,
                                        });
                                    }}
                                    disabled={userRight.isCurrentUser}
                                >
                                    <IoCloseOutline size={20} />
                                </Button>
                            </div>
                        </div>
                    ))(userRights)}
                </ListField>
                <SaveButton save={save} close={close} />
            </div>
        </div>
    );
};
