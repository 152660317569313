import React, { memo } from 'react';
import * as R from 'ramda';
import { Button } from '../../Components/Common/Controls';
import { useModal } from '../../hooks/useModal';
import { _DashboardTopMobile } from './DashboardTopMobile';
import { _ProtocolTopMobile } from './ProtocolTopMobile';

const _TopMobile = ({ stack, meetingId, topId, protocolId, global, protocol, onRemove }) => {
    const openMeetingPdfModal = useModal('protocol-pdf');
    return protocol ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <_ProtocolTopMobile
            noButton
            global={global}
            stack={stack}
            topId={topId}
            onRemove={onRemove}
            editable={false}
            renderPreviousControls={pv =>
                !global && (
                    <Button
                        style={{
                            width: 130,
                            fontSize: 'var(--font-size-small)',
                            justifyContent: 'end',
                            backgroundColor:
                                (pv.protocolId === 'DB' || R.endsWith('-', pv.protocolId)) &&
                                'transparent',
                            cursor:
                                (pv.protocolId === 'DB' || R.endsWith('-', pv.protocolId)) &&
                                'default',
                            color:
                                (pv.protocolId === 'DB' || R.endsWith('-', pv.protocolId)) &&
                                'var(--corporate-color-1-light2)',
                        }}
                        onClick={() => openMeetingPdfModal({ id: pv.meetingId })}
                        disabled={pv.protocolId === 'DB' || R.endsWith('-', pv.protocolId)}
                    >
                        {pv.protocolId === 'DB' || R.endsWith('-', pv.protocolId)
                            ? 'Manually created in dashboard'
                            : `${'Protocol '}${pv.protocolId}`}
                    </Button>
                )
            }
            renderLatestControls={() =>
                !global && (
                    <Button
                        style={{
                            width: 130,
                            fontSize: 'var(--font-size-small)',
                            justifyContent: 'end',
                            backgroundColor:
                                (protocolId === 'DB' || R.endsWith('-', protocolId)) &&
                                'transparent',
                            cursor:
                                (protocolId === 'DB' || R.endsWith('-', protocolId)) && 'default',
                            color:
                                (protocolId === 'DB' || R.endsWith('-', protocolId)) &&
                                'var(--corporate-color-1-light2)',
                        }}
                        onClick={() => openMeetingPdfModal({ id: meetingId })}
                        disabled={protocolId === 'DB' || R.endsWith('-', protocolId)}
                    >
                        {protocolId === 'DB' || R.endsWith('-', protocolId)
                            ? 'Manually created in dashboard'
                            : `${'Protocol '}${protocolId}`}
                    </Button>
                )
            }
        />
    ) : (
        // eslint-disable-next-line react/jsx-pascal-case
        <_DashboardTopMobile
            noButton
            global={global}
            stack={stack}
            topId={topId}
            editable={false}
            renderPreviousControls={pv =>
                !global && (
                    <Button
                        style={{
                            width: 130,
                            fontSize: 'var(--font-size-small)',
                            justifyContent: 'end',
                            backgroundColor:
                                (pv.protocolId === 'DB' || R.endsWith('-', pv.protocolId)) &&
                                'transparent',
                            cursor:
                                (pv.protocolId === 'DB' || R.endsWith('-', pv.protocolId)) &&
                                'default',
                            color:
                                (pv.protocolId === 'DB' || R.endsWith('-', pv.protocolId)) &&
                                'var(--corporate-color-1-light2)',
                        }}
                        onClick={() => openMeetingPdfModal({ id: pv.meetingId })}
                        disabled={pv.protocolId === 'DB' || R.endsWith('-', pv.protocolId)}
                    >
                        {pv.protocolId === 'DB' || R.endsWith('-', pv.protocolId)
                            ? 'Manually created in dashboard'
                            : `${'Protocol '}${pv.protocolId}`}
                    </Button>
                )
            }
            renderLatestControls={() =>
                !global && (
                    <Button
                        style={{
                            width: 130,
                            fontSize: 'var(--font-size-small)',
                            justifyContent: 'end',
                            backgroundColor:
                                (protocolId === 'DB' || R.endsWith('-', protocolId)) &&
                                'transparent',
                            cursor:
                                (protocolId === 'DB' || R.endsWith('-', protocolId)) && 'default',
                            color:
                                (protocolId === 'DB' || R.endsWith('-', protocolId)) &&
                                'var(--corporate-color-1-light2)',
                        }}
                        onClick={() => openMeetingPdfModal({ id: meetingId })}
                        disabled={protocolId === 'DB' || R.endsWith('-', protocolId)}
                    >
                        {protocolId === 'DB' || R.endsWith('-', protocolId)
                            ? 'Manually created in dashboard'
                            : `${'Protocol '}${protocolId}`}
                    </Button>
                )
            }
        />
    );
};

export const TopMobile = memo(_TopMobile, R.equals);
