import React, { useEffect, useState } from 'react';
import { DASHBOARD_STATE_KEY, SEARCH_STRING_KEY, useGraph } from '../../../common/hooks.config.new';

export const SearchBar = ({ stack }) => {
    const { useVar } = useGraph(...stack);
    const [dashboardState] = useVar(DASHBOARD_STATE_KEY);
    const [searchString, setSearchString] = useVar(SEARCH_STRING_KEY);
    const [localSearchString, setLocalSearchString] = useState('');
    const [inputIsFocused, setInputIsFocused] = useState(false);

    useEffect(() => {
        const searchTops = setTimeout(() => {
            inputIsFocused && setSearchString(localSearchString);
        }, 500);
        return () => clearTimeout(searchTops);
    }, [localSearchString]);

    useEffect(() => {
        searchString === '' && setLocalSearchString('');
    }, [searchString === '']);

    return (
        <input
            onFocus={() => setInputIsFocused(true)}
            onBlur={() => setInputIsFocused(false)}
            className="dashboard-searchbar"
            value={localSearchString}
            onChange={e => setLocalSearchString(e.target.value)}
            placeholder={
                dashboardState === 'user'
                    ? 'search your items...'
                    : dashboardState === 'subproject'
                    ? 'search subproject items...'
                    : 'search project items...'
            }
        />
    );
};
