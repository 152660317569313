import * as R from 'ramda';
import { atom, useAtom } from '@gothub-team/got-atom';

const selectIsLoaded = (id, state) => !id || (!state.loading[id] && state.loaded[id]);
const selectIdLoading = (id, state) => id && state.loading[id];

export const loadAtom = () => {
    const baseAtom = atom({ loading: {}, loaded: {} });

    const load = id => {
        baseAtom.set(R.assocPath(['loading', id], true));
    };

    const loaded = id => {
        baseAtom.set(
            R.compose(R.assocPath(['loaded', id], true), R.assocPath(['loading', id], false)),
        );
    };

    const clear = id => {
        baseAtom.set(
            R.compose(R.assocPath(['loaded', id], false), R.assocPath(['loading', id], false)),
        );
    };

    const isLoaded = id => selectIsLoaded(id, baseAtom.get());

    const loadWith = async (id, fn, forceReload) => {
        if (!forceReload && isLoaded(id)) return; // already loading or loaded
        load(id);
        try {
            await fn();
            loaded(id);
        } catch (err) {
            clear(id);
            throw err;
        }
    };

    const useIsLoaded = id => useAtom(baseAtom, state => selectIsLoaded(id, state));
    const useIsLoading = id => useAtom(baseAtom, state => selectIdLoading(id, state));

    return {
        ...baseAtom,
        clear,
        load,
        loaded,
        isLoaded,
        loadWith,
        useIsLoaded,
        useIsLoading,
    };
};
