/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useSpring, animated, config } from 'react-spring';
import './Controls.css';

export const Button = ({ children, ...props }) => (
    <button tabIndex="-1" {...props}>
        {children}
    </button>
);

export const Checkbox = ({
    label,
    value,
    onChange,
    className,
    disabled,
    style,
    labelSize,
    labelStyle,
    checkboxStyle,
}) => (
    <div
        className={`checkbox${className ? ` ${className}` : ''}`}
        onClick={() => onChange && onChange(!value)}
        style={{
            ...style,
            display: 'flex',
            alignItems: !style?.alignItems && 'center',
            cursor: 'pointer',
        }}
    >
        <input
            style={{ ...checkboxStyle, cursor: 'pointer' }}
            disabled={disabled}
            type="checkbox"
            tabIndex="-1"
            checked={!!value}
            onChange={() => {}}
            onClick={e => {
                e.stopPropagation();
                onChange && onChange(!value);
            }}
        />
        <div style={{ ...labelStyle, fontSize: labelSize, cursor: 'pointer' }} className="label">
            {label}
        </div>
    </div>
);

export const FancyCheckbox = ({
    label,
    value,
    onChange,
    labelSize,
    labelStyle,
    placeholderStyle,
    customHeight,
    containerStyle,
    mobile,
}) => {
    const [set, setSet] = useState(false);
    const springPosition = useSpring({
        left: value ? '1em' : '-0.3em',
        config: config.wobbly,
    });
    const springColors1 = useSpring({
        background: value ? '#e0f7d4' : 'white',
        borderColor: !value ? 'var(--corporate-color-1-light)' : 'var(--corporate-color-13)',
    });
    const springColors2 = useSpring({
        background: value ? 'var(--corporate-color-13)' : '#66859B',
    });
    if (mobile) {
        return (
            <div
                onClick={e => {
                    e.stopPropagation();
                    onChange && onChange(!value);
                }}
                style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    height: customHeight,
                    ...containerStyle,
                }}
            >
                <input
                    type="checkbox"
                    id="check1"
                    className={mobile ? 'toggle-mobile' : 'toggle'}
                    checked={!!value}
                    onChange={() => {}}
                    onClick={e => {
                        e.stopPropagation();
                        onChange && onChange(!value);
                    }}
                />
                <div
                    style={{
                        ...labelStyle,
                        fontSize: labelSize,
                        cursor: 'pointer',
                    }}
                    className="blabel"
                />
                <div
                    style={{
                        ...placeholderStyle,
                        color: placeholderStyle?.color || 'var(--corporate-color-1)',
                        marginRight: 6,
                        cursor: 'pointer',
                    }}
                >
                    {label}
                </div>
            </div>
        );
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'center',
                height: customHeight,
                ...containerStyle,
            }}
            onClick={e => {
                e.stopPropagation();
                onChange && onChange(!value);
            }}
        >
            <div>
            <animated.div
                style={springColors1}
                className="fancy-checkbox-container"
                onClick={() => setSet(!set)}
            >
                <animated.div
                    style={{
                        ...springPosition,
                        ...springColors2,
                    }}
                    className="box-bobble"
                />
            </animated.div>
            </div>
            <div
                style={{
                    ...placeholderStyle,
                    color: placeholderStyle?.color || 'var(--corporate-color-1)',
                    marginRight: 6,
                    cursor: 'pointer',
                }}
            >
                {label}
            </div>
        </div>
    );
};
