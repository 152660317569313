import React from 'react';
import { DASHBOARD, MAIN, OFFLINE } from '../../common/hooks.config.new';
import { Screen } from '../Common/Screen';
import { DashboardTopBar } from '../Elements/ProjectDashboard/DashboardTopBar';
import { ExistingMeetingsModal } from '../Modals/ExistingMeetingsModal';
import { ContactsModal } from '../Modals/ContactsModal';
import { ProjectsModal } from '../Modals/ProjectsModal';
import './DashboardScreen.css';
import { TemplatesModal } from '../Modals/TemplatesModal';
import { DashboardContent } from '../Elements/ProjectDashboard/DashboardContent';
import { DashboardTopCalculator } from '../Elements/ProjectDashboard/DashboardTopCalculator';
import { useInitProjectChoices } from '../../hooks/useChoices';
import { ScoreCardModal } from '../Modals/ScoreCardModal';
import { ProtocolPdfModal } from '../Modals/ProtocolPdfModal';
import { Toast } from '../Elements/Toast';
import { Loader } from '../Elements/Loader';
import { useModalState } from '../../hooks/useModal';
import { ReminderModal } from '../Modals/ReminderModal/ReminderModal';
import { ProjectOppsModal } from '../Modals/ProjectOppsModal';
import { ProjectActionsModal } from '../Modals/ProjectActionsModal';
import { ConnectActionsModal } from '../Modals/ConnectActionsModal';
import { TopsToUpdateModal } from '../Modals/TopsToUpdateModal';
import { CreateDashboardTopModal } from '../Modals/CreateDashboardTopModal';
import { OrganizationsModal } from '../Modals/OrganizationsModal';
import { EditScorecardTopModal } from '../Modals/EditScorecardTopModal';
import { ShowTopModal } from '../Modals/ShowTopModal';
import { ProjectMilestonesModal } from '../Modals/ProjectMilestonesModal';
import { MilestoneTopsModal } from '../Modals/MilestoneTopsModal';
import { TopLinksModal } from '../Modals/TopLinksModal';
import { PhaseTopsModal } from '../Modals/PhaseTopsModal';
import { ProjectPhasesModal } from '../Modals/ProjectPhasesModal';
import { AttachmentsModal } from '../Modals/AttachmentsModal';
import { DeleteContactModal } from '../Modals/DeleteContactModal';

const stack = [MAIN, OFFLINE, DASHBOARD];

export const DashboardScreen = () => (
    <div className="screen-fader">
        <Screen topbar={<DashboardTopBar stack={stack} />}>
            <Content />
        </Screen>
        <DashboardTopCalculator stack={stack} />
        <ProjectChoicesContainer stack={stack} />
        <ExistingMeetingsModal stack={stack} />
        <TemplatesModal stack={stack} />
        <OrganizationsModal stack={stack} />
        <ProjectsModal stack={stack} />
        <ContactsModal stack={stack} />
        <TopsToUpdateModal stack={stack} />
        <CreateDashboardTopModal stack={stack} />
        <ShowTopModal stack={stack} />
        <ProtocolPdfModal stack={stack} />
        <ProjectMilestonesModal stack={stack} />
        <ProjectPhasesModal stack={stack} />
        <PhaseTopsModal stack={stack} />
        <MilestoneTopsModal stack={stack} />
        <ScoreCardModal stack={stack} autoPush />
        <ReminderModal stack={stack} />
        <ProjectOppsModal stack={stack} />
        <EditScorecardTopModal stack={stack} />
        <TopLinksModal stack={stack} />
        <ProjectActionsModal stack={stack} />
        <ConnectActionsModal stack={stack} />
        <AttachmentsModal stack={stack} />
        <DeleteContactModal stack={stack} />
        <Toast name="offlineSync" />
        <Toast name="dashboard" />
    </div>
);

const Content = () => {
    const [isLoading] = useModalState('loader');
    return isLoading ? (
        <div
            style={{
                display: 'flex',
                height: '70vh',
                alignItems: 'center',
            }}
        >
            <Loader />
        </div>
    ) : (
        <DashboardContent stack={stack} />
    );
};

const ProjectChoicesContainer = () => {
    useInitProjectChoices(stack);
    return null;
};
