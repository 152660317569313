import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import {
    IoGitNetwork,
    IoGitNetworkOutline,
    IoHome,
    IoHomeOutline,
    IoOpenOutline,
} from 'react-icons/io5';
import { animated, useSpring, easings } from 'react-spring';
import { useSelector } from 'react-redux';
import { VscCircleFilled, VscCircleOutline } from 'react-icons/vsc';
import { Button } from '../../Components/Common/Controls';
import {
    CD_ID_KEY,
    DASHBOARD_FILTER_KEY,
    OPP_TRACKING,
    ORGANIZATION_ID_KEY,
    ORGANIZATION_OPPORTUNITY_TRACKING_EDGE,
    ORGANIZATION_PROJECT_EDGE,
    PROJECT_ID_KEY,
    PROJECT_SUBPROJECT_EDGE,
    ROOT_ORGANIZATION_EDGE,
    SEARCH_STRING_KEY,
    store,
    SUBPROJECT_ID_KEY,
    TOPS_TO_UPDATE_KEY,
    useGraph,
} from '../../common/hooks.config.new';
import { useNodeFromVar } from '../../hooks/useNode';
import { APPLICATION_ROOT_NODE } from '../../common/config';
import { DropdownField } from '../../Components/Common/Dropdown';
import { Id } from '../../common/util';
import { useNavigator } from '../../hooks/useNavigator';
import { useModal, useModalState } from '../../hooks/useModal';
import { MiniLoaderMobile } from '../../Components/Elements/Loader';
import { useUserEmail } from '../../hooks/useUserEmail';
import { useToast } from '../../hooks/useToast';

const constructOrgsView = rootNodeId =>
    rootNodeId
        ? {
              [rootNodeId]: {
                  as: 'root',
                  include: {
                      node: true,
                  },
                  edges: {
                      [ROOT_ORGANIZATION_EDGE]: {
                          as: 'organizations',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

const constructOppTrackingView = organizationId =>
    organizationId
        ? {
              [organizationId]: {
                  edges: {
                      [ORGANIZATION_OPPORTUNITY_TRACKING_EDGE]: {
                          as: 'oppTracking',
                          include: {
                              edges: true,
                              node: true,
                              rights: true,
                          },
                      },
                  },
              },
          }
        : {};

const constructProjectsView = organizationId =>
    organizationId
        ? {
              [organizationId]: {
                  as: 'organization',
                  edges: {
                      [ORGANIZATION_PROJECT_EDGE]: {
                          as: 'projects',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

const constructSubprojectsView = projectId =>
    projectId
        ? {
              [projectId]: {
                  as: 'project',
                  edges: {
                      [PROJECT_SUBPROJECT_EDGE]: {
                          as: 'subprojects',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const SelectorFlyout = ({ stack, global }) => {
    const { useView, pull, useVar } = useGraph(...stack);
    const { useVar: useGlobalVar } = useGraph(R.head(stack));
    const [selectorMenu, setSelectorMenu] = useState(false);
    const openModals = R.compose(
        R.filter(m => m !== false),
        R.values,
        R.omit(['loader', 'loaded-projects']),
    )(useSelector(R.propOr({}, 'modals')));
    const hasOpenModals = R.length(openModals) > 0;
    const selectorMenuFlyout = useSpring({
        top: selectorMenu ? '0rem' : '-40rem',
        config: { duration: 500, easing: easings.easeOutCubic },
    });
    const flyoutOpacity = useSpring({
        opacity: hasOpenModals ? 0 : 1,
    });
    const locationIndiatorStyle = useSpring({
        opacity: selectorMenu ? 0 : 1,
    });
    const projectSelectorStyle = useSpring({
        opacity: !selectorMenu ? 0 : 1,
    });

    const { navigateToDashboard } = useNavigator(stack);

    const [organizationId, setOrganizationId] = useVar(ORGANIZATION_ID_KEY);
    const [projectId, setProjectId] = useVar(PROJECT_ID_KEY);
    const [, setCDId] = useVar(CD_ID_KEY);
    const [subprojectId, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [, setFilter] = useVar(DASHBOARD_FILTER_KEY);
    const [, setSearchString] = useVar(SEARCH_STRING_KEY);

    const setLoading = useModal('loader');
    const [isLoading, stopLoading] = useModalState('loader');

    const { node: organization } = useNodeFromVar(stack, ORGANIZATION_ID_KEY);
    const { node: project } = useNodeFromVar(stack, PROJECT_ID_KEY);
    const { node: subproject } = useNodeFromVar(stack, SUBPROJECT_ID_KEY);

    const organizationName = R.propOr('Organization', 'name', organization);
    const projectName = R.propOr('Project', 'title', project);
    const subprojectName = R.propOr('Subproject', 'title', subproject);

    const orgsView = constructOrgsView(APPLICATION_ROOT_NODE);
    useEffect(() => {
        const load = async () => {
            setLoading();
            APPLICATION_ROOT_NODE && (await pull(orgsView));
            !projectId && stopLoading();
        };
        load();
    }, [APPLICATION_ROOT_NODE]);
    const { root: { organizations = {} } = {} } = useView(orgsView);
    const organizationChoices = R.compose(
        R.sortBy(R.prop('label')),
        R.map(organizationBag => ({
            value: R.path(['node', 'id'], organizationBag),
            label: R.path(['node', 'name'], organizationBag),
            body: R.path(['node'], organizationBag),
        })),
    )(R.values(organizations));

    const projectsView = constructProjectsView(organizationId);
    useEffect(() => {
        organizationId && pull(projectsView);
    }, [organizationId]);
    const { organization: { projects = {} } = {} } = useView(projectsView);
    const projectChoices = R.compose(
        R.sortBy(R.prop('label')),
        R.map(projectBag => ({
            value: R.path(['node', 'id'], projectBag),
            label: R.path(['node', 'title'], projectBag),
            body: R.path(['node'], projectBag),
        })),
    )(R.values(projects));

    const subprojectsView = constructSubprojectsView(projectId);
    useEffect(() => {
        projectId && pull(subprojectsView);
    }, [projectId]);
    const { project: { subprojects = {} } = {} } = useView(subprojectsView);
    const subprojectChoices = R.compose(
        R.sortBy(R.prop('label')),
        R.map(subprojectBag => ({
            value: R.path(['node', 'id'], subprojectBag),
            label: R.path(['node', 'title'], subprojectBag),
            body: R.path(['node'], subprojectBag),
        })),
    )(R.values(subprojects));

    const user = useUserEmail();
    const oppTrackingView = constructOppTrackingView(organizationId);
    useEffect(() => {
        organizationId && pull(oppTrackingView);
    }, [organizationId]);
    const oppTracking = R.compose(
        R.unnest,
        R.map(o => R.values(R.prop('oppTracking', o))),
        R.map(o => R.pick(['oppTracking'], o)),
        R.values,
    )(useView(oppTrackingView));

    const oppTrackingRights = R.propOr('', 'rights', oppTracking[0]);
    // TODO add oppTracking nodes to existing companie
    const canTrackOpportunities = R.pathOr(false, ['user', user, 'read'], oppTrackingRights);
    const [, setOppTracking] = useGlobalVar(OPP_TRACKING);
    useEffect(() => {
        setOppTracking(canTrackOpportunities);
    }, [organizationId, canTrackOpportunities]);

    const setToast = useToast('mobile-project-dashboard');
    const selectedOrganization = name =>
        setToast('success', `${name} selected automatically.`, 5000);

    useEffect(() => {
        const standardOrganization = R.find(R.pathEq(['node', 'default'], true))(
            R.values(organizations),
        );
        if (!organizationId && standardOrganization) {
            setOrganizationId(standardOrganization.nodeId);
            selectedOrganization(standardOrganization.node.name);
        }
    }, [organizations]);

    return (
        <>
            <animated.div style={flyoutOpacity}>
                <Flyout
                    selectorMenuFlyout={selectorMenuFlyout}
                    organizationId={organizationId}
                    organizationChoices={organizationChoices}
                    stack={stack}
                    setOrganizationId={setOrganizationId}
                    setProjectId={setProjectId}
                    setSearchString={setSearchString}
                    setFilter={setFilter}
                    setSubprojectId={setSubprojectId}
                    setLoading={setLoading}
                    setCDId={setCDId}
                    projectChoices={projectChoices}
                    projectId={projectId}
                    subprojectChoices={subprojectChoices}
                    subprojectId={subprojectId}
                    isLoading={isLoading}
                    navigateToDashboard={navigateToDashboard}
                    setSelectorMenu={setSelectorMenu}
                    selectorMenu={selectorMenu}
                    global={global}
                    projectSelectorStyle={projectSelectorStyle}
                    locationIndiatorStyle={locationIndiatorStyle}
                    organizationName={organizationName}
                    projectName={projectName}
                    subprojectName={subprojectName}
                />
            </animated.div>
            {selectorMenu && (
                <div className="flyout-backdrop" onClick={() => setSelectorMenu(false)} />
            )}
        </>
    );
};

const Flyout = ({
    selectorMenuFlyout,
    organizationId,
    organizationChoices,
    stack,
    setOrganizationId,
    setProjectId,
    setSearchString,
    setFilter,
    setSubprojectId,
    setLoading,
    setCDId,
    projectChoices,
    projectId,
    subprojectChoices,
    subprojectId,
    isLoading,
    navigateToDashboard,
    setSelectorMenu,
    selectorMenu,
    global,
    organizationName,
    projectName,
    subprojectName,
    projectSelectorStyle,
    locationIndiatorStyle,
}) => {
    const [preSetId, setPreSetId] = useState(projectId);
    return (
        <animated.div
            className="selector-menu-flyout-container fader"
            style={{
                ...selectorMenuFlyout,
            }}
        >
            <animated.div style={{ ...projectSelectorStyle, position: 'absolute' }}>
                <DropdownField
                    mobile
                    label={
                        !organizationId ? (
                            'Organizations'
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: 'inherit',
                                }}
                            >
                                <IoHome
                                    size="1.75rem"
                                    color="var(--corporate-color-7)"
                                    style={{ marginRight: '0.5rem' }}
                                />
                                <p style={{ fontSize: 'inherit' }}>Organisation</p>
                            </div>
                        )
                    }
                    options={organizationChoices}
                    value={organizationId || ''}
                    onChange={option => {
                        store.setVar(R.last(stack))(TOPS_TO_UPDATE_KEY, {});
                        setOrganizationId(option.value);
                        setProjectId();
                        setSubprojectId();
                        setFilter({});
                        setSearchString('');
                    }}
                    labelClass="dd-label-mobile"
                />
                <DropdownField
                    mobile
                    label={
                        !projectId ? (
                            'Projects'
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: 'inherit',
                                }}
                            >
                                <IoGitNetwork
                                    size="2rem"
                                    color="var(--corporate-color-7)"
                                    style={{ marginRight: '0.5rem', transform: 'rotate(90deg)' }}
                                />
                                <p style={{ fontSize: 'inherit' }}>Project</p>
                            </div>
                        )
                    }
                    options={projectChoices}
                    value={global ? preSetId : projectId}
                    onChange={option => {
                        if (option.value !== projectId) {
                            !global && setLoading();
                            global && setPreSetId(option.value);
                            !global && setProjectId(option.value);
                            !global && setCDId(Id.contactDirectory(option.value));
                            !global && store.setVar(R.last(stack))(TOPS_TO_UPDATE_KEY, {});
                            setSubprojectId();
                            !global && setSearchString('');
                            !global && setFilter({});
                        }
                    }}
                    labelClass="dd-label-mobile"
                />
                {!global ? (
                    isLoading ? (
                        <div
                            style={{
                                display: 'flex',
                                width: '100vw',
                                justifyContent: 'center',
                                paddingLeft: 'calc(2rem - 4px)',
                                height: '16rem',
                                alignItems: 'center',
                            }}
                        >
                            <MiniLoaderMobile />
                        </div>
                    ) : (
                        <DropdownField
                            mobile
                            label={
                                !subprojectId ? (
                                    'Subprojects'
                                ) : (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: 'inherit',
                                        }}
                                    >
                                        <VscCircleFilled
                                            size="2rem"
                                            color="var(--corporate-color-7)"
                                            style={{ marginRight: '0.5rem' }}
                                        />
                                        <p style={{ fontSize: 'inherit' }}>Subproject</p>
                                    </div>
                                )
                            }
                            options={subprojectChoices}
                            value={subprojectId || ''}
                            onChange={option => {
                                setSubprojectId(option.value);
                                setSearchString('');
                                setFilter({});
                            }}
                            labelClass="dd-label-mobile"
                        />
                    )
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            paddingBottom: '4rem',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            style={{
                                backgroundColor: 'var(--corporate-color-2)',
                                width: 'fit-content',
                                fontSize: '3rem',
                                borderRadius: '5rem',
                                gap: '1rem',
                                padding: '2rem 3rem',
                                color: 'var(--corporate-color-7)',
                                boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
                            }}
                            onClick={() => {
                                setSelectorMenu(false);
                                setLoading();
                                setTimeout(() => {
                                    setProjectId(preSetId);
                                    navigateToDashboard();
                                }, 750);
                            }}
                        >
                            <IoOpenOutline size="5rem" />
                            Open
                        </Button>
                    </div>
                )}
                {(!isLoading || global) && (
                    <div
                        style={{
                            display: 'flex',
                            width: '100vw',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            zIndex: 200,
                        }}
                    >
                        <Button
                            onClick={() => setSelectorMenu(false)}
                            style={{
                                backgroundColor: 'white',
                            }}
                        >
                            <div
                                style={{
                                    marginTop: '3.5rem',
                                    width: '12rem',
                                    height: '0.5rem',
                                    borderRadius: '5rem',
                                    backgroundColor: 'var(--corporate-color-7)',
                                }}
                            />
                        </Button>
                    </div>
                )}
            </animated.div>
            {!global ? (
                <LocalProjectSelector
                    locationIndiatorStyle={locationIndiatorStyle}
                    organizationName={organizationName}
                    projectName={projectName}
                    subprojectName={subprojectName}
                    selectorMenu={selectorMenu}
                    setSelectorMenu={setSelectorMenu}
                />
            ) : (
                <GlobalProjectSelector
                    locationIndiatorStyle={locationIndiatorStyle}
                    organizationName={organizationName}
                    projectName={projectName}
                    subprojectName={subprojectName}
                    selectorMenu={selectorMenu}
                    setSelectorMenu={setSelectorMenu}
                    navigateToDashboard={navigateToDashboard}
                    setLoading={setLoading}
                    projectId={projectId}
                />
            )}
        </animated.div>
    );
};

const LocalProjectSelector = ({
    locationIndiatorStyle,
    organizationName,
    projectName,
    subprojectName,
    selectorMenu,
    setSelectorMenu,
}) => (
    <animated.div
        style={locationIndiatorStyle}
        className="project-selector-mobile"
        onMouseDown={() => setSelectorMenu(!selectorMenu)}
    >
        <div
            className="project-selector-entry-mobile"
            style={{
                color: organizationName === 'Organization' ? 'var(--color-3)' : 'inherit',
                justifyContent: 'flex-start',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '4rem',
                    minWidth: '4rem',
                    height: '4rem',
                    padding: '1rem',
                    paddingLeft: 0,
                    borderRadius: '5rem',
                }}
            >
                <IoHomeOutline size="3.5rem" color="var(--corporate-color-7)" />
            </div>
            {organizationName === 'Organization'
                ? 'Organization'
                : R.slice(0, 10, organizationName)}
        </div>
        <div
            className="project-selector-entry-mobile"
            style={{
                color: projectName === 'Project' ? 'var(--color-3)' : 'inherit',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '4rem',
                    minWidth: '4rem',
                    height: '4rem',
                    padding: '1rem',
                    borderRadius: '5rem',
                    marginRight: '0.25rem',
                }}
            >
                <IoGitNetworkOutline
                    size="3.75rem"
                    style={{ transform: 'rotate(90deg)' }}
                    color="var(--corporate-color-7)"
                />
            </div>
            {R.slice(0, 10, projectName)}
        </div>
        <div
            className="project-selector-entry-mobile"
            style={{
                color: subprojectName === 'Subproject' ? 'var(--color-3)' : 'inherit',
                justifyContent: 'flex-end',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '4rem',
                    minWidth: '4rem',
                    height: '4rem',
                    padding: '1rem',
                    paddingRight: '0.1rem',
                    borderRadius: '5rem',
                }}
            >
                <VscCircleOutline size="3.75rem" color="var(--corporate-color-7)" />
            </div>
            {R.slice(0, 10, subprojectName)}
        </div>
    </animated.div>
);

const GlobalProjectSelector = ({
    locationIndiatorStyle,
    selectorMenu,
    setSelectorMenu,
    organizationName,
    projectName,
    navigateToDashboard,
    setLoading,
}) => (
    <animated.div
        style={locationIndiatorStyle}
        className="project-selector-mobile"
        onClick={() => setSelectorMenu(!selectorMenu)}
    >
        <div
            className="project-selector-entry-mobile"
            style={{
                color: organizationName === 'Organization' ? 'var(--color-2)' : 'inherit',
                justifyContent: 'flex-start',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '4rem',
                    minWidth: '4rem',
                    height: '4rem',
                    padding: '1rem',
                    borderRadius: '5rem',
                }}
            >
                <IoHomeOutline size="3.5rem" color="var(--corporate-color-7)" />
            </div>
            {R.slice(0, 10, organizationName)}
        </div>
        <div
            className="project-selector-entry-mobile"
            style={{ color: projectName === 'Project' ? 'var(--color-2)' : 'inherit' }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '4rem',
                    minWidth: '4rem',
                    height: '4rem',
                    padding: '1rem',
                    borderRadius: '5rem',
                    marginRight: '0.25rem',
                }}
            >
                <IoGitNetworkOutline
                    size="3.75rem"
                    style={{ transform: 'rotate(90deg)' }}
                    color="var(--corporate-color-7)"
                />
            </div>
            {R.slice(0, 10, projectName)}
        </div>
        <div
            className="project-selector-entry-mobile"
            style={{
                position: 'relative',
                height: '7rem',
                padding: 0,
                justifyContent: 'flex-end',
            }}
        >
            <Button
                style={{
                    position: 'absolute',
                    width: '90%',
                    backgroundColor: 'var(--corporate-color-2)',
                    height: '5rem',
                    borderRadius: '2.5rem',
                    color: 'var(--corporate-color-7)',
                    boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
                    zIndex: 200,
                }}
                onClick={e => {
                    e.stopPropagation();
                    setLoading();
                    setTimeout(() => navigateToDashboard(), 1);
                }}
            >
                <IoOpenOutline size="4rem" />
                <p
                    style={{
                        fontSize: 'var(--font-size-mobile-normal)',
                        marginLeft: '1rem',
                    }}
                >
                    Open
                </p>
            </Button>
        </div>
    </animated.div>
);
