/* eslint-disable react/jsx-pascal-case */
import React, { memo } from 'react';
import * as R from 'ramda';
import { _Top } from '../Top';
import { OnlyOnFileDrag } from '../../Common/OnlyOnDrag';
import { TopFileField } from './TopFileField';

const _ProtocolTop = ({
    stack,
    topId,
    editable = true,
    noButton,
    scorecard,
    noId,
    oppTracking,
    allowDrag,
    inheritFromId,
    meetingId,
    shouldPush,
}) => {
    if (allowDrag) {
        return (
            <OnlyOnFileDrag
                renderFalse={() => (
                    <_Top
                        stack={stack}
                        topId={topId}
                        editable={editable}
                        noButton={noButton}
                        scorecard={scorecard}
                        noId={noId}
                        oppTracking={oppTracking}
                    />
                )}
                renderTrue={() => (
                    <TopFileField
                        stack={stack}
                        inheritFromId={inheritFromId}
                        topId={topId}
                        meetingId={meetingId}
                        shouldPush={shouldPush}
                    />
                )}
            />
        );
    }
    return (
        <_Top
            stack={stack}
            topId={topId}
            editable={editable}
            noButton={noButton}
            scorecard={scorecard}
            noId={noId}
            oppTracking={oppTracking}
        />
    );
};

export const ProtocolTop = memo(_ProtocolTop, R.equals);
