/* eslint-disable no-continue */
import * as R from 'ramda';
import { useMemo } from 'react';
import { useActionStatuses } from './useActionStatuses';

const groupTopsAndCount = (actions, statusTypes) => {
    if (!actions || !statusTypes) return { labels: [], data: [] };

    const responsibleDatas = {};
    // foreach business id
    for (let i = 0; i < actions.length; i += 1) {
        const action = actions[i];
        if (action?.topType !== 'a' || !action?.businessId) continue;

        const status = action?.top?.status;
        const responsible = action?.responsible;
        if (!status || !responsible) continue;

        // status counter object per responsible
        let responsibleData = responsibleDatas[responsible.id];
        if (!responsibleData) {
            responsibleData = { statusCounts: {}, responsible };
            responsibleDatas[responsible.id] = responsibleData;
        }

        // count status for responsible
        responsibleData.statusCounts[status] = (responsibleData.statusCounts[status] || 0) + 1;
    }

    // for each responsible create array of same order as statusTypes
    const responsibleIds = Object.keys(responsibleDatas);
    for (let i = 0; i < responsibleIds.length; i += 1) {
        const responsibleId = responsibleIds[i];
        const responsibleData = responsibleDatas[responsibleId];

        let total = 0;
        const counts = new Array(statusTypes.length);
        for (let j = 0; j < statusTypes.length; j += 1) {
            const count = responsibleData.statusCounts[statusTypes[j]] || 0;
            total += count;
            counts[j] = count;
        }

        responsibleData.counts = counts;
        responsibleData.total = total;
    }

    // sort data by total
    const sortedData = R.compose(R.reverse, R.sortBy(R.prop('total')), R.values)(responsibleDatas);

    // map sorted data to labels, data and responsibles
    const responsibles = sortedData.map(d => d.responsible);
    const labels = sortedData.map(d => d.responsible.name);
    const data = sortedData.map(({ statusCounts }) => {
        // create array of same order as statusTypes
        const counts = new Array(statusTypes.length);
        for (let i = 0; i < statusTypes.length; i += 1) {
            counts[i] = statusCounts[statusTypes[i]] || 0;
        }

        return counts;
    });

    return { labels, data, responsibles };
};

export const useResponsibleStatusChart = (stack, actions) => {
    const statusTypes = useActionStatuses(stack);

    const { labels, data, responsibles } = useMemo(
        () => groupTopsAndCount(actions, statusTypes),
        [actions, statusTypes],
    );

    return {
        labels,
        data,
        statusTypes,
        responsibles,
    };
};
