import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { IoRemoveOutline, IoLogOutOutline, IoCloudUploadOutline } from 'react-icons/io5';
import { Button } from '../../Components/Common/Controls';
import { useAuth } from '../../common/auth';
import { OfflineSyncerMobile } from './OfflineSyncerMobile';
import { OFFLINE, PROTOCOL } from '../../common/hooks.config.new';
import { useToast } from '../../hooks/useToast';
import { ClickAnimator } from './ClickAnimator';

export const GlobalMenu = () => {
    const [navMenu, setNavMenu] = useState(false);
    const [, , logout] = useAuth(
        'Offline data will be lost on logout. Make sure there is no blue save icon in the bottom middle!',
    );
    const menuButtonStyle = useSpring({
        transform: navMenu ? 'rotate(-180deg)' : 'rotate(0deg',
    });
    const logoutButtonStyle = useSpring({
        scale: navMenu ? 1 : 0,
        opacity: navMenu ? 1 : 0,
    });
    const setOfflineSyncToast = useToast('mobile-project-dashboard');
    return (
        <>
            <animated.div style={logoutButtonStyle} className="fit-content">
                <Button
                    className="menu-button-raw"
                    style={{
                        color: 'var(--corporate-color-2)',
                        backgroundColor: 'white',
                    }}
                    onClick={() => logout()}
                >
                    <IoLogOutOutline size="6.5rem" color="var(--corporate-color-2)" />
                </Button>
            </animated.div>
            <ClickAnimator
                content={
                    <Button
                        onClick={() => setNavMenu(!navMenu)}
                        className="menu-button"
                        style={{
                            backgroundColor: 'var(--corporate-color-1)',
                        }}
                    >
                        <animated.div
                            style={{
                                ...menuButtonStyle,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: 'relative',
                                    color: 'white',
                                }}
                            >
                                <IoRemoveOutline
                                    size="8rem"
                                    style={{ position: 'relative', top: '6.7rem' }}
                                />
                                <IoRemoveOutline size="8rem" />
                                <IoRemoveOutline
                                    size="8rem"
                                    style={{ position: 'relative', bottom: '6.7rem' }}
                                />
                            </div>
                        </animated.div>
                    </Button>
                }
                positioning={{ position: 'absolute', left: '0rem', bottom: '0rem' }}
            />
            <OfflineSyncerMobile
                global
                graphNames={[OFFLINE, PROTOCOL]}
                onProgress={() => setOfflineSyncToast('spinner', 'Syncing offline data...')}
                onSuccess={() => setOfflineSyncToast('success', 'Successfully saved.', 5000)}
                onError={error => {
                    if (error.status) {
                        setOfflineSyncToast(
                            'error',
                            error.message ? error.message : error.toString(),
                        );
                    } else {
                        setOfflineSyncToast(
                            'error',
                            'You are offline. Please sync when back online.',
                            5000,
                        );
                    }
                }}
            >
                <IoCloudUploadOutline size="5rem" />
            </OfflineSyncerMobile>
        </>
    );
};
