import * as RA from 'ramda-adjunct';
import * as R from 'ramda';
import React from 'react';
import { ListRow } from '../Common/ListRow';
import { ProtocolTop } from './ProtocolScreen/ProtocolTop';
import { TopButtonRow } from './TopButtonRow';

export const OppActions = ({
    stack,
    actionBags,
    dissocAction,
    canWriteProject,
    canAdminProject,
    editMessage,
    subprojectId,
    noEdit,
    oppTracking,
    milestone,
    showTopType,
}) => (
    <div style={{ marginBottom: 50, marginTop: showTopType === 'a' && 'var(--space-large-row)' }}>
        {RA.mapIndexed((ab, index) => (
            <>
                <ListRow
                    key={ab.id || ab.nodeId}
                    style={{
                        marginTop: showTopType === 'a' && 0,
                        marginLeft: 'var(--space-small)',
                        marginRight: 'var(--space-small)',
                    }}
                >
                    <div className="top-search-row">
                        <TopButtonRow
                            stack={stack}
                            top={ab}
                            scorecard
                            actions
                            canAdminProject={canAdminProject}
                            canWriteProject={canWriteProject}
                            dissocAction={dissocAction}
                            oppId={ab.oppId}
                            actionId={ab.nodeId}
                            editMessage={editMessage}
                            subprojectId={subprojectId}
                            noEdit={noEdit}
                            oppTracking={oppTracking}
                            milestone={milestone}
                        />
                        <ProtocolTop stack={stack} topId={ab.id} editable={false} scorecard />
                    </div>
                </ListRow>
                {showTopType === 'a' && index < R.length(actionBags) - 1 && (
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 70,
                            position: 'relative',
                        }}
                    >
                        <div className="arrow-base" />
                        <div className="arrow-indicator">Next</div>
                        <div className="arrow-head-left" />
                        <div className="arrow-head-right" />
                    </div>
                )}
            </>
        ))(actionBags)}
    </div>
);
