import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import {
    IoAppsOutline,
    IoChevronForwardOutline,
    IoSearchOutline,
    IoTrashOutline,
} from 'react-icons/io5';
import { Modal } from '../Common/Modal';
import { ListField } from '../Common/Fields';
import { Button, FancyCheckbox } from '../Common/Controls';
import { useModalState } from '../../hooks/useModal';
import {
    CONTACT_DIRECTORY_CONTACT_EDGE,
    ORGANIZATION_OPPORTUNITY_TRACKING_EDGE,
    ORGANIZATION_PROJECT_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { useUserEmail } from '../../hooks/useUserEmail';
import { Id } from '../../common/util';
import { SaveButton } from '../Elements/SaveButton';

const constructOrgProjectsView = orgId =>
    orgId
        ? {
              [orgId]: {
                  as: 'organization',
                  include: {
                      node: true,
                      edges: true,
                  },
                  edges: {
                      [ORGANIZATION_PROJECT_EDGE]: {
                          as: 'projects',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

const constructProjectContactsView = projectId =>
    projectId
        ? {
              [Id.contactDirectory(projectId)]: {
                  as: projectId,
                  edges: {
                      [CONTACT_DIRECTORY_CONTACT_EDGE]: {
                          as: 'contacts',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

const constructOppTrackingView = organizationId =>
    organizationId
        ? {
              [organizationId]: {
                  edges: {
                      [ORGANIZATION_OPPORTUNITY_TRACKING_EDGE]: {
                          as: 'oppTracking',
                          include: {
                              edges: true,
                              node: true,
                              rights: true,
                          },
                      },
                  },
              },
          }
        : {};

export const UserModulesModal = ({ stack, save }) => {
    const [isOpen, close] = useModalState('user-modules');
    return (
        <Modal
            name="user-modules"
            style={{
                height: '80vh',
                top: '10vh',
                width: '75vw',
                left: '12.5vw',
            }}
            topbar={<IoAppsOutline size={23} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <ModalContent stack={stack} save={save} close={close} /> : null}
        </Modal>
    );
};

export const ModalContent = ({ stack, save, close }) => {
    const { useView, setRights, pull } = useGraph(...stack);
    const [organizationId] = useModalState('user-modules');

    const user = useUserEmail();

    // organization contacts
    const orgProjectsView = constructOrgProjectsView(organizationId);
    useEffect(() => {
        organizationId && pull(orgProjectsView);
    }, [organizationId]);
    const allProjects = useView(orgProjectsView);
    const mergedProjects = R.compose(
        R.mergeAll,
        R.map(R.pathOr({}, ['projects'])),
        R.values,
    )(allProjects);

    const projectsContactsView = R.compose(
        R.mergeAll,
        R.map(constructProjectContactsView),
    )(R.keys(mergedProjects));

    useEffect(() => {
        RA.isNotNilOrEmpty(projectsContactsView) && pull(projectsContactsView);
    }, [allProjects]);
    const cdsContactsViewRes = useView(projectsContactsView);

    const projectsContacts = R.map(R.compose(R.values, R.pathOr({}, ['contacts'])))(
        cdsContactsViewRes,
    );

    const orgContacts = R.compose(
        R.sortBy(R.prop('name')),
        R.uniq,
        R.map(R.omit(['id', 'invited', 'department'])),
        R.map(R.prop('node')),
        R.unnest,
        R.values,
    )(projectsContacts);

    // opp tracking
    const oppTrackingView = constructOppTrackingView(organizationId);
    useEffect(() => {
        organizationId && pull(oppTrackingView);
    }, [organizationId]);
    const oppTracking = R.compose(
        R.unnest,
        R.map(o => R.values(R.prop('oppTracking', o))),
        R.map(o => R.pick(['oppTracking'], o)),
        R.values,
    )(useView(oppTrackingView));

    const oppTrackingId = R.propOr('', 'nodeId', oppTracking[0]);
    const oppTrackingRights = R.propOr('', 'rights', oppTracking[0]);

    const userRights = R.compose(
        R.sortBy(R.ifElse(R.propOr(false, 'isCurrentUser'), R.always('0000'), R.prop('user'))),
        R.filter(
            R.anyPass([
                R.propOr(false, 'read'),
                R.propOr(false, 'write'),
                R.propOr(false, 'admin'),
            ]),
        ),
        R.values,
        R.mapObjIndexed((val, key) => ({ ...val, user: key, isCurrentUser: key === user })),
        R.prop('user'),
    )(oppTrackingRights);

    const getUserModuleRight = email => {
        const entryInRights = R.find(R.propEq('user', email))(userRights);
        return {
            read: R.propOr(false, 'read', entryInRights),
            write: R.propOr(false, 'write', entryInRights),
            admin: R.propOr(false, 'admin', entryInRights),
        };
    };

    const [userSearchString, setUserSearchString] = useState('');
    const [search, setSearch] = useState(false);

    const userSearch = searchString => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]+[u00C0-u017F]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.filter(u => u.name && u.name.toUpperCase().search(string) !== -1)(orgContacts);
        }
        return orgContacts;
    };

    return (
        <div className="columns">
            <div>
                <Headline
                    search={search}
                    setSearch={setSearch}
                    userSearchString={userSearchString}
                    setUserSearchString={setUserSearchString}
                />
                <UsersList
                    user={user}
                    getUserModuleRight={getUserModuleRight}
                    setRights={setRights}
                    oppTrackingId={oppTrackingId}
                    userSearch={userSearch}
                    userSearchString={userSearchString}
                />
                <SaveButton save={save} close={close} />
            </div>
        </div>
    );
};

const Headline = ({ search, setSearch, userSearchString, setUserSearchString }) => {
    const inputRef = useRef();
    useEffect(() => {
        search && setTimeout(() => inputRef.current.focus(), 600);
    }, [search]);
    return (
        <div className="modal-headline">
            <div>Manage User Modules</div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                <Button style={{ marginLeft: 6 }} onClick={() => setSearch(!search)}>
                    {search ? <IoChevronForwardOutline size={19} /> : <IoSearchOutline size={21} />}
                </Button>
                {search && (
                    <div className="animated-searchbar">
                        <input
                            ref={inputRef}
                            placeholder="search users..."
                            value={userSearchString}
                            onChange={e => setUserSearchString(e.target.value)}
                        />
                        <Button style={{ marginLeft: 3 }} onClick={() => setUserSearchString('')}>
                            <IoTrashOutline size={17} />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

const UsersList = ({
    user,
    getUserModuleRight,
    setRights,
    oppTrackingId,
    userSearch,
    userSearchString,
}) => (
    <ListField className style={{ flex: 1, flexBasis: 150 }} label="Organization contacts">
        {R.map(contact => (
            <div key={contact.email + contact.name} className="row hover-effect">
                <div className="list-row-entry">
                    {contact.name}
                    {user === contact.email && <p className="user-badge">User</p>}
                </div>
                <div style={{ color: 'var(--color-2)', flex: 1 }}>{contact.email}</div>
                <div className="buttons">
                    <FancyCheckbox
                        label="Opportunity Tracking"
                        value={getUserModuleRight(contact.email).read}
                        onChange={() =>
                            setRights(oppTrackingId)(contact.email, {
                                read: !getUserModuleRight(contact.email).read,
                                write: !getUserModuleRight(contact.email).write,
                                admin: !getUserModuleRight(contact.email).admin,
                            })
                        }
                    />
                </div>
            </div>
        ))(userSearch(userSearchString))}
    </ListField>
);
