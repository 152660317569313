import React from 'react';
import { IoSaveOutline } from 'react-icons/io5';
import { Button } from '../Common/Controls';

export const SaveButton = ({ save, close, onClick }) => (
    <div className="new-save-button-container">
        <Button
            onClick={() => {
                save && save();
                onClick && onClick();
                close && close();
            }}
            className="new-save-button"
        >
            <IoSaveOutline size={22} style={{ marginRight: 9 }} />
            Save
        </Button>
    </div>
);
