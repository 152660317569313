import * as R from 'ramda';

const namesOfMonths = [
    { number: '01', name: 'January' },
    { number: '02', name: 'February' },
    { number: '03', name: 'March' },
    { number: '04', name: 'April' },
    { number: '05', name: 'May' },
    { number: '06', name: 'June' },
    { number: '07', name: 'July' },
    { number: '08', name: 'August' },
    { number: '09', name: 'September' },
    { number: '10', name: 'October' },
    { number: '11', name: 'November' },
    { number: '12', name: 'December' },
];

export const useTimelineMeetings = (endDate, startDate, meetings) => {
    const endDateYear = R.slice(6, 10)(endDate);
    const startDateYear = R.slice(6, 10)(startDate);
    const years = endDateYear - startDateYear;

    const getYearsInBetween = (
        firstYear = parseFloat(startDateYear),
        numberOfYears = years,
        yb = [],
    ) => {
        const yearsInBetween = yb;
        if (numberOfYears > 1) {
            const newYear = firstYear + 1;
            const newNunmberOfYears = numberOfYears - 1;
            const newYears = R.concat(yearsInBetween, [
                { year: newYear.toString(), months: namesOfMonths },
            ]);
            return getYearsInBetween(newYear, newNunmberOfYears, newYears);
        }
        return yearsInBetween;
    };

    const getMeetingsPerMonth = () => {
        if (years > 0) {
            const endDateMonthFirstYear = '12';
            const startDateMonthFirstYear = R.startsWith('0', R.slice(3, 5)(startDate))
                ? R.slice(4, 5)(startDate)
                : R.slice(3, 5)(startDate);
            const endDateMonthSecondYear = R.startsWith('0', R.slice(3, 5)(endDate))
                ? R.slice(4, 5)(endDate)
                : R.slice(3, 5)(endDate);
            const startDateMonthSecondYear = '1';
            const activeMonthsFirstYear = R.slice(
                startDateMonthFirstYear - 1,
                endDateMonthFirstYear,
            )(namesOfMonths);
            const activeMonthsSecondYear = R.slice(
                startDateMonthSecondYear - 1,
                endDateMonthSecondYear,
            )(namesOfMonths);
            const activeMiddleYears = years > 1 ? R.unnest(getYearsInBetween()) : [];
            const activeYears = [
                { year: startDateYear, months: activeMonthsFirstYear },
                ...activeMiddleYears,
                { year: endDateYear, months: activeMonthsSecondYear },
            ];
            const meetingsPerMonth = R.map(year => {
                const meetingsPerYear = R.map(month => {
                    const monthMeetings = R.filter(
                        m =>
                            R.slice(5, 7)(m.node.date) === month.number &&
                            R.slice(0, 4)(m.node.date) === year.year,
                    )(meetings);
                    return { ...month, meetings: monthMeetings, monthId: year.year + month.name };
                })(year.months);
                return { year: year.year, meetingsPerMonth: meetingsPerYear };
            })(activeYears);

            return meetingsPerMonth;
        }
        // years === 0
        const endDateMonth = R.startsWith('0', R.slice(3, 5)(endDate))
            ? R.slice(4, 5)(endDate)
            : R.slice(3, 5)(endDate);

        const startDateMonth = R.startsWith('0', R.slice(3, 5)(startDate))
            ? R.slice(4, 5)(startDate)
            : R.slice(3, 5)(startDate);

        const activeMonths = R.slice(startDateMonth - 1, endDateMonth)(namesOfMonths);

        const meetingsPerMonth = R.map(month => {
            const monthMeetings = R.filter(m =>
                R.path(['node', 'date'], m) ? R.slice(5, 7)(m.node.date) === month.number : false,
            )(meetings);
            return { ...month, meetings: monthMeetings, monthId: startDateYear + month.name };
        })(activeMonths);

        return [{ year: startDateYear, meetingsPerMonth: R.unnest(meetingsPerMonth) }];
    };

    return getMeetingsPerMonth;
};
