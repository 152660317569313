import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import { IoCreateOutline } from 'react-icons/io5';
import { EDIT_TOP_MODAL, useGraph } from '../../common/hooks.config.new';
import { useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { Toast } from '../Elements/Toast';
import { ListRow } from '../Common/ListRow';
import { ProtocolTop } from '../Elements/ProtocolScreen/ProtocolTop';
import { useNode } from '../../hooks/useNode';
import { createOfflinePush } from '../../common/got-adjunct';
import { useToast } from '../../hooks/useToast';
import { TopButtonRow } from '../Elements/TopButtonRow';
import { SaveButton } from '../Elements/SaveButton';
import { useCreateHistory } from '../../hooks/useCreateHistory';

export const EditScorecardTopModal = ({ stack: parentStack }) => {
    const [isOpen, close] = useModalState('editScorecardTop');
    const stack = R.append(EDIT_TOP_MODAL, parentStack);
    const { clear } = useGraph(...stack);
    return (
        <Modal
            name="editScorecardTop"
            style={{
                height: '80vh',
                top: '10vh',
                width: '80vw',
                left: '10vw',
            }}
            close={() => {
                clear();
            }}
            topbar={<IoCreateOutline size={24} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <EditTopModalContent stack={stack} close={close} /> : null}
        </Modal>
    );
};

const EditTopModalContent = ({ stack, close }) => {
    const [selectedTop] = useModalState('editScorecardTop');
    const selectedTopBag = useNode(stack, selectedTop.top.id);
    const selectedTopBusinessId = R.path(['node', 'businessId'], selectedTopBag);

    const [topId, setTopId] = useState();
    const createHistory = useCreateHistory(stack, {
        setNewTops: newTops => {
            setTopId(newTops[0]?.top?.id);
        },
    });

    const promiseRef = useRef();
    useEffect(() => {
        if (selectedTop?.top?.id) {
            const promise = createHistory([selectedTop.top]);
            promiseRef.current = promise;
        }
    }, [selectedTop.top.id]);

    const setToast = useToast('editTop');
    const toastConfig = {
        textOnStart: 'Saving data...',
        textOnSuccess: 'Successfully saved.',
        textOnError: 'You are offline. Please sync when back online.',
    };
    const save = async () => {
        promiseRef.current && (await promiseRef.current);
        await createOfflinePush(stack, setToast, toastConfig);
    };

    return (
        <>
            <div className="columns">
                <div>
                    <div className="modal-headline">
                        <div>Edit TOP: {selectedTopBusinessId}</div>
                    </div>
                    <ListRow
                        key={selectedTop.top.id}
                        style={{ boxShadow: '0px 0px 10px 0px var(--shadow-color-light)' }}
                    >
                        <div className="top-search-row">
                            <TopButtonRow top={selectedTop.top} stack={stack} noEdit />
                            {topId && (
                                <ProtocolTop stack={stack} topId={topId} editable noButton noId />
                            )}
                        </div>
                    </ListRow>
                </div>
            </div>
            <SaveButton save={save} close={close} />
            <Toast name="editTop" />
        </>
    );
};
