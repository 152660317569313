import React, { useState } from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useNode } from '../../hooks/useNode';

export const GlobalTableMobile = ({
    stack,
    allActions,
    allCriticalActions,
    allOpps,
    allCriticalOpps,
    projectsUserTops,
    setDashboardTops,
    setDashboardState,
    userCanTrackOpps,
}) => {
    const [bold, setBold] = useState({});
    return (
        <div
            style={{
                display: 'inline-block',
                width: '100%',
                overflowX: 'scroll',
                padding: '2rem',
                boxSizing: 'border-box',
            }}
        >
            <div
                className="table-container-mobile"
                style={{
                    margin: 0,
                    border: 'none',
                    paddingLeft: '2rem',
                }}
            >
                <TableHeader
                    bold={bold}
                    allCriticalActions={allCriticalActions}
                    allCriticalOpps={allCriticalOpps}
                    userCanTrackOpps={userCanTrackOpps}
                />
                {RA.mapIndexed(({ projectId, tops }, index) => (
                    <TableRow
                        key={projectId}
                        stack={stack}
                        projectId={projectId}
                        tops={tops}
                        index={index}
                        bold={bold}
                        setBold={setBold}
                        setDashboardTops={setDashboardTops}
                        setDashboardState={setDashboardState}
                        userCanTrackOpps={userCanTrackOpps}
                    />
                ))(R.values(projectsUserTops))}
                <TableFooter
                    stack={stack}
                    bold={bold}
                    setBold={setBold}
                    allActions={allActions}
                    allCriticalActions={allCriticalActions}
                    allOpps={allOpps}
                    allCriticalOpps={allCriticalOpps}
                    setDashboardTops={setDashboardTops}
                    setDashboardState={setDashboardState}
                    userCanTrackOpps={userCanTrackOpps}
                />
            </div>
        </div>
    );
};

const TableHeader = ({ bold, allCriticalActions, allCriticalOpps, userCanTrackOpps }) => (
    <div className="table-header-mobile" style={{ marginBottom: 6 }}>
        <div className="header-entry-left-mobile">Project</div>
        {userCanTrackOpps && (
            <>
                <div
                    className="header-entry-middle-mobile"
                    style={{ fontWeight: bold.column === 1 && 'bold' }}
                >
                    Opportunities
                </div>
                <div
                    className="header-entry-middle-mobile"
                    style={{
                        color:
                            R.length(allCriticalOpps) > 0
                                ? 'var(--corporate-color-12)'
                                : 'darkgray',
                        fontWeight: bold.column === 2 && 'bold',
                    }}
                >
                    Critical Opps
                </div>
            </>
        )}
        <div
            className="header-entry-middle-mobile"
            style={{ fontWeight: bold.column === 3 && 'bold' }}
        >
            Actions
        </div>
        <div
            className="header-entry-middle-mobile"
            style={{
                color: R.length(allCriticalActions) > 0 ? 'var(--corporate-color-12)' : 'darkgray',
                fontWeight: bold.column === 4 && 'bold',
            }}
        >
            Critical Actions
        </div>
    </div>
);

const TableRow = ({
    stack,
    projectId,
    tops,
    index,
    bold,
    setBold,
    setDashboardTops,
    setDashboardState,
    userCanTrackOpps,
}) => {
    const { node: project } = useNode(stack, projectId);
    return (
        <div className="table-row-mobile" style={{ marginTop: 12, alignItems: 'center' }}>
            <div
                className="table-entry-left-mobile"
                style={{ fontWeight: bold.row === index && 'bold' }}
            >
                {R.propOr('', 'title', project)}
            </div>
            {userCanTrackOpps && (
                <>
                    <div
                        className="table-entry-middle-mobile"
                        style={{
                            color: R.length(tops.opps) === 0 && 'darkgray',
                            backgroundColor: R.length(tops.opps) === 0 && 'transparent',
                            fontWeight: R.length(tops.opps) === 0 && 'normal',
                            cursor: R.length(tops.opps) === 0 && 'default',
                        }}
                        onMouseEnter={() =>
                            R.length(tops.opps) > 0 && setBold({ row: index, column: 1 })
                        }
                        onMouseLeave={() => R.length(tops.opps) > 0 && setBold({})}
                        onClick={() => {
                            R.length(tops.opps) > 0 && setDashboardTops(tops.opps);
                            R.length(tops.opps) > 0 && setDashboardState('tops');
                        }}
                    >
                        {R.length(tops.opps)}
                    </div>
                    <div
                        className="table-entry-middle-mobile"
                        style={{
                            color:
                                R.length(tops.criticalOpps) > 0
                                    ? 'var(--corporate-color-12)'
                                    : 'darkgray',
                            backgroundColor: R.length(tops.criticalOpps) === 0 && 'transparent',
                            fontWeight: R.length(tops.criticalOpps) === 0 && 'normal',
                            cursor: R.length(tops.criticalOpps) === 0 && 'default',
                        }}
                        onMouseEnter={() =>
                            R.length(tops.criticalOpps) > 0 && setBold({ row: index, column: 2 })
                        }
                        onMouseLeave={() => R.length(tops.criticalOpps) > 0 && setBold({})}
                        onClick={() => {
                            R.length(tops.criticalOpps) > 0 && setDashboardTops(tops.criticalOpps);
                            R.length(tops.criticalOpps) > 0 && setDashboardState('tops');
                        }}
                    >
                        {R.length(tops.criticalOpps)}
                    </div>
                </>
            )}
            <div
                className="table-entry-middle-mobile"
                style={{
                    color: R.length(tops.actions) === 0 && 'darkgray',
                    backgroundColor: R.length(tops.actions) === 0 && 'transparent',
                    fontWeight: R.length(tops.actions) === 0 && 'normal',
                    cursor: R.length(tops.actions) === 0 && 'default',
                }}
                onMouseEnter={() =>
                    R.length(tops.actions) > 0 && setBold({ row: index, column: 3 })
                }
                onMouseLeave={() => R.length(tops.actions) > 0 && setBold({})}
                onClick={() => {
                    R.length(tops.actions) > 0 && setDashboardTops(tops.actions);
                    R.length(tops.actions) > 0 && setDashboardState('tops');
                }}
            >
                {R.length(tops.actions)}
            </div>
            <div
                className="table-entry-middle-mobile"
                style={{
                    color:
                        R.length(tops.criticalActions) > 0
                            ? 'var(--corporate-color-12)'
                            : 'darkgray',
                    backgroundColor: R.length(tops.criticalActions) === 0 && 'transparent',
                    fontWeight: R.length(tops.criticalActions) === 0 && 'normal',
                    cursor: R.length(tops.criticalActions) === 0 && 'default',
                }}
                onMouseEnter={() =>
                    R.length(tops.criticalActions) > 0 && setBold({ row: index, column: 4 })
                }
                onMouseLeave={() => R.length(tops.criticalActions) > 0 && setBold({})}
                onClick={() => {
                    R.length(tops.criticalActions) > 0 && setDashboardTops(tops.criticalActions);
                    R.length(tops.criticalActions) > 0 && setDashboardState('tops');
                }}
            >
                {R.length(tops.criticalActions)}
            </div>
        </div>
    );
};

const TableFooter = ({
    allActions,
    allCriticalActions,
    allOpps,
    allCriticalOpps,
    setBold,
    setDashboardTops,
    setDashboardState,
    bold,
    userCanTrackOpps,
}) => (
    <div
        className="table-footer-mobile"
        style={{
            borderColor: 'var(--corporate-color-2)',
            marginTop: 15,
        }}
    >
        <div
            className="header-entry-left-mobile"
            style={{
                color: 'var(--corporate-color-2)',
                fontWeight: bold && bold.row === -1 && 'bold',
            }}
        >
            Total
        </div>
        {userCanTrackOpps && (
            <>
                <div
                    className="table-entry-middle-mobile"
                    style={{
                        backgroundColor: R.length(allOpps) === 0 && 'transparent',
                        fontWeight: R.length(allOpps) === 0 && 'normal',
                        cursor: R.length(allOpps) === 0 && 'default',
                    }}
                    onMouseEnter={() => R.length(allOpps) > 0 && setBold({ column: 1, row: -1 })}
                    onMouseLeave={() => R.length(allOpps) > 0 && setBold({})}
                    onClick={() => {
                        R.length(allOpps) > 0 && setDashboardTops(allOpps);
                        R.length(allOpps) > 0 && setDashboardState('tops');
                    }}
                >
                    {R.length(allOpps)}
                </div>
                <div
                    className="table-entry-middle-mobile"
                    style={{
                        color:
                            R.length(allCriticalOpps) > 0
                                ? 'var(--corporate-color-12)'
                                : 'darkgray',
                        backgroundColor: R.length(allCriticalOpps) === 0 && 'transparent',
                        fontWeight: R.length(allCriticalOpps) === 0 && 'normal',
                        cursor: R.length(allCriticalOpps) === 0 && 'default',
                    }}
                    onMouseEnter={() =>
                        R.length(allCriticalOpps) > 0 && setBold({ column: 2, row: -1 })
                    }
                    onMouseLeave={() => R.length(allCriticalOpps) > 0 && setBold({})}
                    onClick={() => {
                        R.length(allCriticalOpps) > 0 && setDashboardTops(allCriticalOpps);
                        R.length(allCriticalOpps) > 0 && setDashboardState('tops');
                    }}
                >
                    {R.length(allCriticalOpps)}
                </div>
            </>
        )}
        <div
            className="table-entry-middle-mobile"
            style={{
                backgroundColor: R.length(allActions) === 0 && 'transparent',
                fontWeight: R.length(allActions) === 0 && 'normal',
                cursor: R.length(allActions) === 0 && 'default',
            }}
            onMouseEnter={() => R.length(allActions) > 0 && setBold({ column: 3, row: -1 })}
            onMouseLeave={() => R.length(allActions) > 0 && setBold({})}
            onClick={() => {
                R.length(allActions) > 0 && setDashboardTops(allActions);
                R.length(allActions) > 0 && setDashboardState('tops');
            }}
        >
            {R.length(allActions)}
        </div>
        <div
            className="table-entry-middle-mobile"
            style={{
                color: R.length(allCriticalActions) > 0 ? 'var(--corporate-color-12)' : 'darkgray',
                backgroundColor: R.length(allCriticalActions) === 0 && 'transparent',
                fontWeight: R.length(allCriticalActions) === 0 && 'normal',
                cursor: R.length(allCriticalActions) === 0 && 'default',
            }}
            onMouseEnter={() => R.length(allCriticalActions) > 0 && setBold({ column: 4, row: -1 })}
            onMouseLeave={() => R.length(allCriticalActions) > 0 && setBold({})}
            onClick={() => {
                R.length(allCriticalActions) > 0 && setDashboardTops(allCriticalActions);
                R.length(allCriticalActions) > 0 && setDashboardState('tops');
            }}
        >
            {R.length(allCriticalActions)}
        </div>
    </div>
);
