import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { IoCloseOutline, IoCreateOutline, IoHomeOutline, IoOpenOutline } from 'react-icons/io5';
import {
    useGraph,
    ORGANIZATIONS_MODAL_MOBILE,
    ROOT_ORGANIZATION_EDGE,
    ORGANIZATION_ID_KEY,
    PROJECT_ID_KEY,
    SEARCH_STRING_KEY,
    DASHBOARD_FILTER_KEY,
    SUBPROJECT_ID_KEY,
    EDIT_ORG_MODAL_MOBILE,
} from '../../common/hooks.config.new';
import { useModal, useModalState } from '../../hooks/useModal';
import { ModalMobile } from '../Common/ModalMobile';
import { ListField } from '../../Components/Common/Fields';
import { APPLICATION_ROOT_NODE } from '../../common/config';
import { Button } from '../../Components/Common/Controls';
import { useUserEmail } from '../../hooks/useUserEmail';

const constructOrgsView = rootNodeId =>
    rootNodeId
        ? {
              [rootNodeId]: {
                  as: 'root',
                  include: {
                      node: true,
                  },
                  edges: {
                      [ROOT_ORGANIZATION_EDGE]: {
                          as: 'organizations',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const OrganizationsModalMobile = ({ stack }) => {
    const [isOpen, close] = useModalState(ORGANIZATIONS_MODAL_MOBILE);
    const openEditOrgModalMobile = useModal(EDIT_ORG_MODAL_MOBILE);
    const user = useUserEmail();
    return (
        <ModalMobile
            name={ORGANIZATIONS_MODAL_MOBILE}
            add={() => openEditOrgModalMobile({ isNew: true, user })}
        >
            {isOpen ? <ModalContent stack={stack} close={close} /> : null}
        </ModalMobile>
    );
};

const ModalContent = ({ stack, close }) => {
    const { useView, pull, useVar } = useGraph(...stack);
    const [organizationId, setOrganizationId] = useVar(ORGANIZATION_ID_KEY);
    const [, setProjectId] = useVar(PROJECT_ID_KEY);
    const [, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [, setSearchString] = useVar(SEARCH_STRING_KEY);
    const [, setFilter] = useVar(DASHBOARD_FILTER_KEY);
    const organizationsView = constructOrgsView(APPLICATION_ROOT_NODE);
    const { root: { organizations = {} } = {} } = useView(organizationsView);
    useEffect(() => {
        APPLICATION_ROOT_NODE && pull(organizationsView);
    }, [APPLICATION_ROOT_NODE]);
    const openEditOrgModalMobile = useModal(EDIT_ORG_MODAL_MOBILE);
    const sortedOrgs = R.compose(R.sortBy(R.path(['node', 'title'])), R.values)(organizations);

    return (
        <>
            <div className="modal-headline-mobile">
                Organizations
                <div className="modal-headline-filler" />
            </div>
            <ListField className style={{ flex: 1, flexBasis: 150 }}>
                {R.map(organization => (
                    <OrgElement
                        key={organization.nodeId}
                        organization={organization}
                        organizationId={organizationId}
                        setOrganizationId={setOrganizationId}
                        openEditOrgModalMobile={openEditOrgModalMobile}
                        setProjectId={setProjectId}
                        setSubprojectId={setSubprojectId}
                        setSearchString={setSearchString}
                        setFilter={setFilter}
                        close={close}
                    />
                ))(sortedOrgs)}
            </ListField>
            <div style={{ height: '20vh' }} />
        </>
    );
};

const OrgElement = ({
    organization,
    organizationId,
    setOrganizationId,
    openEditOrgModalMobile,
    setProjectId,
    setSubprojectId,
    setSearchString,
    setFilter,
    close,
}) => {
    const [showButtons, setShowButtons] = useState(false);
    return (
        <div
            key={organization.nodeId}
            className="row-mobile"
            style={{
                borderColor: organization.nodeId === organizationId && 'var(--corporate-color-7)',
                borderWidth: organization.nodeId === organizationId && 3,
                boxShadow:
                    organization.nodeId === organizationId &&
                    '0 8px 8px -2px var(--corporate-color-7)',
            }}
            onClick={() => setShowButtons(true)}
        >
            <IoHomeOutline
                size="5rem"
                color={
                    organization.nodeId === organizationId
                        ? 'var(--corporate-color-7)'
                        : 'var(--corporate-color-2-light)'
                }
                style={{ marginRight: '2rem' }}
            />
            <div className="row-mobile-content">
                <div className="main-title">{organization.node.name}</div>
                {showButtons && (
                    <div className="list-buttons-mobile">
                        <Button
                            className="list-button"
                            onClick={e => {
                                e.stopPropagation();
                                setOrganizationId(organization.nodeId);
                                setProjectId();
                                setSubprojectId();
                                setSearchString('');
                                setFilter({});
                                close();
                            }}
                        >
                            <IoOpenOutline size="3rem" style={{ marginRight: '1rem' }} />
                            Open
                        </Button>
                        <Button
                            className="list-button"
                            onClick={e => {
                                e.stopPropagation();
                                setShowButtons(false);
                                openEditOrgModalMobile({ organizationId: organization.nodeId });
                            }}
                        >
                            <IoCreateOutline size="3rem" style={{ marginRight: '1rem' }} />
                            Edit
                        </Button>
                        <Button
                            className="list-button"
                            onClick={e => {
                                e.stopPropagation();
                                setShowButtons(false);
                            }}
                            style={{ paddingLeft: '1.5rem' }}
                        >
                            <IoCloseOutline size="3.5rem" style={{ marginRight: '0.5rem' }} />
                            Close
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};
