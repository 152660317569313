/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { IoLinkOutline } from 'react-icons/io5';
import {
    CONNECT_ACTIONS_MODAL,
    SORTED_GROUPED_TOPS_KEY,
    TOP_ATTACHMENT_EDGE,
    TOP_LINKS_MODAL,
    useGraph,
} from '../../common/hooks.config.new';
import { useModal, useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { Toast } from '../Elements/Toast';
import { useTopLinks } from '../../hooks/useTopLinks';
import { ListRow } from '../Common/ListRow';
import { TopButtonRow } from '../Elements/TopButtonRow';
import { DashboardTop } from '../Elements/ProjectDashboard/DashboardTop';
import { Button } from '../Common/Controls';
import { LoadAttachments } from '../Elements/HasAttachments';

export const TopLinksModal = ({ stack }) => {
    const [isOpen] = useModalState(TOP_LINKS_MODAL);
    return (
        <Modal
            name={TOP_LINKS_MODAL}
            style={{
                height: '84vh',
                top: '8vh',
                width: '84vw',
                left: '8vw',
            }}
            topbar={<IoLinkOutline size={26} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <ModalContent stack={stack} /> : null}
        </Modal>
    );
};

const ModalContent = ({ stack: parentStack }) => {
    const stack = R.append(TOP_LINKS_MODAL, parentStack);
    const { useVar } = useGraph(...stack);
    const [selectedTop] = useModalState(TOP_LINKS_MODAL);

    const [sortedGroupedTops] = useVar(SORTED_GROUPED_TOPS_KEY);

    const [prevTops, origintop, nextTops] = useTopLinks(
        selectedTop.businessId,
        sortedGroupedTops.tops,
    );

    const tops = [...prevTops, origintop, ...nextTops];

    const openConnectActionsModal = useModal(CONNECT_ACTIONS_MODAL);

    return (
        <>
            <div>
                <Headline
                    selectedTop={selectedTop}
                    openConnectActionsModal={openConnectActionsModal}
                />
                <LinksList stack={stack} tops={tops} selectedTop={selectedTop} />
            </div>
            <Toast name="assoc-message" />
        </>
    );
};

const Headline = ({ selectedTop, openConnectActionsModal }) => (
    <div className="modal-headline">
        <div style={{ flex: 1, marginBottom: 20 }}>Connected Actions</div>
        <Button
            onClick={() => openConnectActionsModal({ top: selectedTop, mode: 'connectActions' })}
        >
            <IoLinkOutline size={20} style={{ marginRight: 6 }} />
            Connect actions
        </Button>
    </div>
);

const LinksList = ({ stack, tops, selectedTop }) =>
    R.length(tops) > 0 ? (
        RA.mapIndexed(({ top, responsible, previousVersions, meetingId, protocolId }, index) => (
            <>
                <ListRow
                    key={top.id}
                    style={{
                        margin: '0 var(--space-small) 0 var(--space-small)',
                        backgroundColor:
                            top.businessId === selectedTop.businessId
                                ? 'var(--corporate-color-13-light4)'
                                : undefined,
                    }}
                >
                    <div className="top-search-row">
                        <TopButtonRow
                            top={top}
                            stack={stack}
                            responsible={responsible}
                            previousVersions={previousVersions}
                        />
                        <DashboardTop
                            noButton
                            stack={stack}
                            meetingId={meetingId}
                            topId={R.prop('id', top)}
                            protocolId={protocolId}
                        />
                        <LoadAttachments
                            stack={stack}
                            node={top}
                            edgeTypes={TOP_ATTACHMENT_EDGE}
                        />
                    </div>
                </ListRow>
                {index < R.length(tops) - 1 && (
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 70,
                            position: 'relative',
                        }}
                    >
                        <div className="arrow-base" />
                        <div className="arrow-indicator">Next</div>
                        <div className="arrow-head-left" />
                        <div className="arrow-head-right" />
                    </div>
                )}
            </>
        ))(tops)
    ) : (
        <div className="no-content-message">No agenda items found</div>
    );
