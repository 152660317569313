import { gotReducer } from '@gothub-team/got-react';
import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import localforage from 'localforage';
import { modalsReducer } from './modals';
import { toastsReducer } from './toasts';

const rootReducer = combineReducers({
    got2: gotReducer,
    modals: modalsReducer,
    toasts: toastsReducer,
});

// export const got2PersistTransform = createTransform(
//     // transform state on its way to being serialized and persisted.
//     inboundState => R.map(R.over(
//         R.lensProp('vars'),
//         R.compose(
//             R.omit(['groupedTops', 'sortedGroupedTops']),
//             R.defaultTo({}),
//         ),
//     ))(inboundState),
//     // transform state being rehydrated
//     outboundState => outboundState,
//     // define which reducers this transform gets called for.
//     { whitelist: ['got2'] },
// );

const persistConfig = {
    key: 'root',
    storage: localforage,
    whitelist: ['got2'],
    // transforms: [got2PersistTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
    persistedReducer,
    // applyMiddleware(
    //     logger,
    // ),
);
export const persistor = persistStore(store);
