import React from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { MdOutlineDragIndicator } from 'react-icons/md';
import { Button } from '../Controls';
import { DragHandle } from '../SortableList';
import { createListRow } from './createListRow';

const AttendeeTopBar = ({ onRemove }) => (
    <div className="list-row-buttons">
        <DragHandle>
            <Button style={{ height: 30 }}>
                <MdOutlineDragIndicator style={{ transform: 'rotate(90deg)' }} size={23} />
            </Button>
        </DragHandle>
        <Button
            onClick={onRemove}
            style={{
                right: '48px',
                height: 30,
            }}
        >
            <IoCloseOutline size={20} />
        </Button>
    </div>
);

const attendeeListRowStyle = {
    boxShadow: 'none',
    margin: '0 0 12 0',
    padding: '0',
};

export const AttendeeListRow = createListRow(AttendeeTopBar, attendeeListRowStyle);
