/* eslint-disable default-param-last */
import * as RA from 'ramda-adjunct';
import * as R from 'ramda';
import { useMemo } from 'react';
import {
    DASHBOARD_FILTER_KEY,
    DASHBOARD_STATE_KEY,
    MEETING_ATTACHMENT_EDGE,
    PROJECT_ID_KEY,
    SEARCH_STRING_KEY,
    SORTED_GROUPED_TOPS_KEY,
    SUBPROJECT_ID_KEY,
    api,
    procubionApi,
    useGraph,
} from '../common/hooks.config.new';
import { useMeetingAttendees } from './useMeetingAttendees';
import { useMeetingTopsWithResponsibles } from './useMeetingTops';
import { businessNodeString, formatShortDate, newId, topHierarchy } from '../common/util';
import { useAttachments } from './useAttachments';
import { useProjectCurrency } from './useChoices';
import { useGetPreviousVersions } from './useTopHistories';
import { setTimeoutPromise, useFile } from '../common/got.old';
import { selectContactBagWithEmail, useProjectContacts } from './useProjectContacts';
import { filterTops } from '../common/filteredtops.util';

export const usePrintProtocol = stack => {
    const { useVar, useNode } = useGraph(...stack);

    const [organizationId] = useVar('organizationId');
    const [projectId] = useVar('projectId');
    const [subprojectId] = useVar('subprojectId');
    const [meetingId] = useVar('meetingId');

    // const { node: organization } = useNode(organizationId);
    const project = useNode(projectId);
    const subproject = useNode(subprojectId);
    const meeting = useNode(meetingId);

    const attendees = useMeetingAttendees(stack, meetingId);
    const sortedTopsBags = useMeetingTopsWithResponsibles(stack, meetingId);
    const hierarchy = useMemo(
        () => R.compose(topHierarchy, R.map(R.prop('node')))(sortedTopsBags),
        [sortedTopsBags],
    );

    const currency = useProjectCurrency(stack);
    const format = val =>
        new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: R.defaultTo('EUR', currency),
        }).format(val);
    const getPreviousVersions = useGetPreviousVersions(stack);

    const { attachmentBags } = useAttachments(stack, meetingId, MEETING_ATTACHMENT_EDGE);

    const [protocolUrl, fetchProtocol] = useFile(meetingId, 'protocol', 'application/pdf');

    const lineMaker = (s, label) => `${s ? `\n${label}: ${s}` : ''}`;
    const formatDescription = ({ description, status, category, savings }) =>
        `${description}${lineMaker(status, 'Status')}${lineMaker(category, 'Category')}${lineMaker(
            savings && format(savings),
            'Savings',
        )}`;

    const date = new Date();
    const pdfDate = formatShortDate(date);
    const pdfTime = `${date.getUTCHours() + 1}:${date.getUTCMinutes()}`;

    // TODO refactor this into smaller components, render a la react?
    const getPdfData = () => ({
        organizationId,
        projectId,
        meetingId,
        showAd: project.showAd && project.showAd,
        createdDateTime: `${pdfDate} ${pdfTime}`,
        headline: 'Minutes of Meeting',
        headerFields: [
            ['Project', businessNodeString(project) || ''],
            ['Subproject', businessNodeString(subproject) || ''],
            ['Meeting', businessNodeString(meeting) || ''],
            ['Meeting Type', meeting.type || ''],
            ['Date/Time', formatShortDate(meeting.date) || ''],
            ['Location', meeting.location || ''],
            [
                'Protocol ID',
                R.join('-', [project.businessId, subproject.businessId, meeting.businessId]),
            ],
        ],
        attendeeTable: [
            ['Attendees', 'Status'],
            ...R.map(({ node: { name = '' } = {}, metadata: { presence = '' } = {} }) => [
                name,
                presence,
            ])(attendees),
        ],
        attendeeLegend: 'A… Attended, P… Partly Attended, N… Not Attended, M… Minutes Keeper',
        contentTable: [
            'Meeting Content',
            ['No.', 'T', 'Description', 'Due Date', 'Resp.'],
            ...R.compose(
                R.unnest,
                R.map(
                    ({
                        node: {
                            businessId = '',
                            type = '',
                            description = '',
                            status = '',
                            savings = '',
                            category = '',
                            dueDate = '',
                            id = '',
                        } = {},
                        responsibles = {},
                    }) => [
                        ...R.compose(
                            RA.mapIndexed(
                                (
                                    {
                                        top: {
                                            description: prevDescription = '',
                                            status: prevStatus = '',
                                            savings: prevSavings = '',
                                            category: prevCategory = '',
                                            dueDate: prevDueDate = '',
                                        } = {},
                                        responsible: { name: prevResponsible = '' } = {},
                                        editedDate = '',
                                        author: { name: authorName } = {},
                                    } = {},
                                    i,
                                ) => [
                                    i === 0 ? hierarchy[id] || '' : '',
                                    i === 0 ? R.ifElse(Number, RA.stubString, R.toUpper)(type) : '',
                                    [
                                        formatDescription({
                                            description: prevDescription,
                                            status: prevStatus,
                                            savings: prevSavings,
                                            category: prevCategory,
                                        }),
                                        editedDate
                                            ? `${
                                                  i === 0 ? 'created' : 'changed'
                                              } on ${formatShortDate(editedDate)}${
                                                  authorName ? ` by ${authorName}` : ''
                                              }`
                                            : '',
                                    ],
                                    formatShortDate(prevDueDate) || '',
                                    prevResponsible,
                                ],
                            ),
                            getPreviousVersions,
                        )(businessId),
                        [
                            R.ifElse(
                                R.anyPass([
                                    Number,
                                    () => getPreviousVersions(businessId).length === 0,
                                ]),
                                R.always(hierarchy[id] || ''),
                                RA.stubString,
                            )(type),
                            R.ifElse(
                                R.anyPass([
                                    Number,
                                    () => getPreviousVersions(businessId).length === 0,
                                ]),
                                R.toUpper,
                                RA.stubString,
                            )(type),
                            [
                                formatDescription({
                                    description,
                                    status,
                                    category,
                                    savings,
                                }),
                                businessId,
                            ],
                            formatShortDate(dueDate) || '',
                            R.compose(
                                R.pathOr('', ['node', 'name']),
                                R.head,
                                R.values,
                            )(responsibles),
                        ],
                    ],
                ),
            )(sortedTopsBags),
        ],
        contentLegend:
            'T… Type, A… Action, O… Opportunity, R… Risk, D… Decision, S… Statement, I… Information',
        actionsTable: [
            'Actions',
            ['No.', 'Description', 'Status', 'Due Date', 'Resp.'],
            ...R.compose(
                R.unnest,
                RA.mapIndexed(
                    (
                        {
                            node: {
                                businessId = '',
                                description = '',
                                status = '',
                                dueDate = '',
                            } = {},
                            responsibles = {},
                        },
                        i,
                    ) => [
                        ...R.compose(
                            RA.mapIndexed(
                                (
                                    {
                                        top: {
                                            description: prevDescription = '',
                                            status: prevStatus = '',
                                            dueDate: prevDueDate = '',
                                        } = {},
                                        responsible: { name: prevResponsible = '' } = {},
                                        editedDate = '',
                                        author: { name: authorName } = {},
                                    } = {},
                                    j,
                                ) => [
                                    j === 0 ? `${i + 1}` : '',
                                    [
                                        prevDescription,
                                        editedDate
                                            ? `${
                                                  i === 0 ? 'created' : 'changed'
                                              } on ${formatShortDate(editedDate)}${
                                                  authorName ? ` by ${authorName}` : ''
                                              }`
                                            : '',
                                    ],
                                    prevStatus,
                                    formatShortDate(prevDueDate),
                                    prevResponsible,
                                ],
                            ),
                            getPreviousVersions,
                        )(businessId),
                        [
                            getPreviousVersions(businessId).length === 0 ? `${i + 1}` : '',
                            [description, businessId],
                            status,
                            formatShortDate(dueDate) || '',
                            R.compose(
                                R.pathOr('', ['node', 'name']),
                                R.head,
                                R.values,
                            )(responsibles),
                        ],
                    ],
                ),
                R.filter(t => t.node.type === 'a'),
            )(sortedTopsBags),
        ],
        opportunitiesTable: [
            'Opportunities',
            ['No.', 'Description', 'Status', 'Category', 'Savings', 'Resp.'],
            ...R.compose(
                R.unnest,
                RA.mapIndexed(
                    (
                        {
                            node: {
                                businessId = '',
                                description = '',
                                status = '',
                                category = '',
                                savings = '',
                            } = {},
                            responsibles = {},
                        },
                        i,
                    ) => [
                        ...R.compose(
                            RA.mapIndexed(
                                (
                                    {
                                        top: {
                                            description: prevDescription = '',
                                            status: prevStatus = '',
                                            category: prevCategory = '',
                                            savings: prevSavings = '',
                                        } = {},
                                        responsible: { name: prevResponsible = '' } = {},
                                        editedDate = '',
                                        author: { name: authorName } = {},
                                    } = {},
                                    j,
                                ) => [
                                    j === 0 ? `${i + 1}` : '',
                                    [
                                        prevDescription,
                                        editedDate
                                            ? `${
                                                  i === 0 ? 'created' : 'changed'
                                              } on ${formatShortDate(editedDate)}${
                                                  authorName ? ` by ${authorName}` : ''
                                              }`
                                            : '',
                                    ],
                                    prevStatus,
                                    prevCategory,
                                    prevSavings && format(prevSavings),
                                    prevResponsible,
                                ],
                            ),
                            getPreviousVersions,
                        )(businessId),
                        [
                            getPreviousVersions(businessId).length === 0 ? `${i + 1}` : '',
                            [description, businessId],
                            status,
                            category,
                            savings && format(savings),
                            R.compose(
                                R.pathOr('', ['node', 'name']),
                                R.head,
                                R.values,
                            )(responsibles),
                        ],
                    ],
                ),
                R.filter(t => t.node.type === 'o'),
            )(sortedTopsBags),
        ],
        attachments: R.compose(R.values, R.map(R.path(['node', 'filename'])))(attachmentBags),
    });

    const pollFetchProtocol = async (retries = 5, timeout = 1000) => {
        const url = await fetchProtocol();
        if (!url && retries > 0) {
            await setTimeoutPromise(timeout);
            return pollFetchProtocol(retries - 1);
        }

        return url;
    };

    const generateProtocol = async () => {
        const pdfData = getPdfData();
        if (pdfData.meetingId) {
            await procubionApi.generateProtocol(pdfData);
            await pollFetchProtocol(30, 1000);
        }
    };

    return [protocolUrl, generateProtocol];
};

export const usePrintDashboardPDF = stack => {
    const { useVar, update, push, useNode } = useGraph(...stack);
    const randomNode = {
        id: newId(),
        type: 'dashboardExport',
    };
    const [organizationId] = useVar('organizationId');
    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);
    const subproject = useNode(subprojectId) || {};
    const [projectId] = useVar(PROJECT_ID_KEY);
    const project = useNode(projectId) || {};
    const [dashboardState] = useVar(DASHBOARD_STATE_KEY);
    const [filter] = useVar(DASHBOARD_FILTER_KEY);
    const [searchString] = useVar(SEARCH_STRING_KEY);
    const subprojectBusinessId = R.pathOr('', ['businessId'], subproject);
    const { contactBags } = useProjectContacts(stack, projectId);
    const user = api.getCurrentUser().email;
    const userName = R.compose(
        R.pathOr(user, ['node', 'name']),
        selectContactBagWithEmail(user),
    )(contactBags);
    const statusses = projectId && R.propOr([], 'oppStatusTypes', project);
    const [sortedGroupedTopsVar] = useVar(SORTED_GROUPED_TOPS_KEY);
    const sortedGroupedTops = sortedGroupedTopsVar?.tops || {};

    const topRows = R.compose(
        R.sortBy(R.prop('businessId')),
        R.values,
        R.map(R.last),
    )(sortedGroupedTops);
    const { searchedTops } = filterTops({
        filter,
        statusses,
        searchString,
        dashboardState,
        subprojectBusinessId,
        userName,
        showTops: true,
    })(topRows);
    const nodedSearchTops = searchedTops.map(t => ({
        node: t.top,
        ...t,
    }));
    const hierarchy = useMemo(
        () => R.compose(topHierarchy, R.map(R.prop('node')))(nodedSearchTops),
        [nodedSearchTops],
    );
    const currency = useProjectCurrency(stack);
    const format = val =>
        new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: R.defaultTo('EUR', currency),
        }).format(val);
    const getPreviousVersions = useGetPreviousVersions(stack);
    const [protocolUrl, fetchProtocol] = useFile(randomNode.id, 'protocol', 'application/pdf');
    const lineMaker = (s, label) => `${s ? `\n${label}: ${s}` : ''}`;
    const formatDescription = ({ description, status, category, savings }) => {
        const replaceNewlines = R.replace(/\n/g, '');
        const newDescription = replaceNewlines(description);
        return `${newDescription}${lineMaker(status, 'Status')}${lineMaker(
            category,
            'Category',
        )}${lineMaker(savings && format(savings), 'Savings')}`;
    };
    const date = new Date();
    const pdfDate = formatShortDate(date);
    const pdfTime = `${date.getUTCHours() + 1}:${date.getUTCMinutes()}`;

    // TODO refactor this into smaller components, render a la react?
    const getPdfData = () => ({
        dashboardExport: true,
        organizationId,
        projectId,
        meetingId: randomNode.id,
        showAd: true,
        createdDateTime: `${pdfDate} ${pdfTime}`,
        headline: `Exported TOPs of the project ${project.title || ''}`,
        headerFields: [
            ['Project', ''],
            ['Subproject', ''],
            ['Meeting', ''],
            ['Meeting Type', ''],
            ['Date/Time', ''],
            ['Location', ''],
            ['Protocol ID', ''],
        ],
        attendeeTable: [['Attendees', 'Status'], []],
        attendeeLegend: 'A… Attended, P… Partly Attended, N… Not Attended, M… Minutes Keeper',
        contentTable: [
            '',
            ['No.', 'T', 'Description', 'Due Date', 'Resp.'],
            ...R.compose(
                R.unnest,
                R.map(
                    ({
                        node: {
                            businessId = '',
                            type = '',
                            description = '',
                            status = '',
                            savings = '',
                            category = '',
                            dueDate = '',
                            id = '',
                        } = {},
                        responsibles = {},
                    }) => [
                        ...R.compose(
                            RA.mapIndexed(
                                (
                                    {
                                        top: {
                                            description: prevDescription = '',
                                            status: prevStatus = '',
                                            savings: prevSavings = '',
                                            category: prevCategory = '',
                                            dueDate: prevDueDate = '',
                                        } = {},
                                        responsible: { name: prevResponsible = '' } = {},
                                        editedDate = '',
                                        author: { name: authorName } = {},
                                    } = {},
                                    i,
                                ) => [
                                    i === 0 ? hierarchy[id] || '' : '',
                                    i === 0 ? R.ifElse(Number, RA.stubString, R.toUpper)(type) : '',
                                    [
                                        formatDescription({
                                            description: prevDescription,
                                            status: prevStatus,
                                            savings: prevSavings,
                                            category: prevCategory,
                                        }),
                                        editedDate
                                            ? `${
                                                  i === 0 ? 'created' : 'changed'
                                              } on ${formatShortDate(editedDate)}${
                                                  authorName ? ` by ${authorName}` : ''
                                              }`
                                            : '',
                                    ],
                                    formatShortDate(prevDueDate) || '',
                                    prevResponsible,
                                ],
                            ),
                            getPreviousVersions,
                        )(businessId),
                        [
                            R.ifElse(
                                R.anyPass([
                                    Number,
                                    () => getPreviousVersions(businessId).length === 0,
                                ]),
                                R.always(hierarchy[id] || ''),
                                RA.stubString,
                            )(type),
                            R.ifElse(
                                R.anyPass([
                                    Number,
                                    () => getPreviousVersions(businessId).length === 0,
                                ]),
                                R.toUpper,
                                RA.stubString,
                            )(type),
                            [
                                formatDescription({
                                    description,
                                    status,
                                    category,
                                    savings,
                                }),
                                businessId,
                            ],
                            formatShortDate(dueDate) || '',
                            R.compose(
                                R.pathOr('', ['node', 'name']),
                                R.head,
                                R.values,
                            )(responsibles),
                        ],
                    ],
                ),
            )(nodedSearchTops),
        ],
        contentLegend:
            'T… Type, A… Action, O… Opportunity, R… Risk, D… Decision, S… Statement, I… Information',
        actionsTable: ['Actions', ['No.', 'Description', 'Status', 'Due Date', 'Resp.']],
        opportunitiesTable: [
            'Opportunities',
            ['No.', 'Description', 'Status', 'Category', 'Savings', 'Resp.'],
        ],
    });

    const pollFetchProtocol = async (retries = 20, timeout = 1000) => {
        const url = await fetchProtocol();
        if (!url && retries > 0) {
            await setTimeoutPromise(timeout);
            return pollFetchProtocol(retries - 1);
        }

        return url;
    };

    const generateProtocol = async () => {
        update(randomNode);
        await push();
        const pdfData = getPdfData();
        if (pdfData.projectId) {
            await procubionApi.generateProtocol(pdfData);
            const url = await pollFetchProtocol(30, 1000);
            if (url) {
                window.open(url);
            }
        }
    };

    return [protocolUrl, generateProtocol];
};
