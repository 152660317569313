import * as R from 'ramda';
import { useCallback, useMemo } from 'react';
import { SORTED_GROUPED_TOPS_KEY, useGraph } from '../common/hooks.config.new';

export const useGetPreviousVersions = stack => {
    const { useVar } = useGraph(...stack);
    const [sortedGroupedTopsVar] = useVar(SORTED_GROUPED_TOPS_KEY);
    const tops = sortedGroupedTopsVar?.tops || {};

    const getPreviousVersions = useCallback(
        topBusinessId =>
            topBusinessId && R.compose(R.dropLast(1), R.propOr([], topBusinessId))(tops),
        [tops],
    );
    return getPreviousVersions;
};

export const usePreviousVersions = (stack, topBusinessId) => {
    const { useVar } = useGraph(...stack);
    const [{ tops = {} } = {}] = useVar(SORTED_GROUPED_TOPS_KEY);

    return useMemo(
        () =>
            R.compose(
                // R.dropLast(1),
                R.propOr([], topBusinessId),
            )(tops),
        [topBusinessId, tops],
    );
};

// export const useInitTopHistories = stack => {
//     const { useVar } = useGraph(...R.dropLast(1, stack));
//     const [, setHistories] = useVar(TOP_HISTORIES_KEY);

//     const refresh = view => {
//         const viewResult = store.getView(...stack)(view);
//         const {
//             project: {
//                 subprojects,
//             },
//         } = viewResult;
//         // eslint-disable-next-line react-hooks/rules-of-hooks
//         const [getResult, setResult] = Util.useResult({});
//         R.map(
//             ({ meetings }) => R.map(
//                 ({ node: meeting, attendees, tops }) => R.map(
//                     ({ node: top, responsibles }) => top && top.businessId && setResult(
//                         R.assocPath([
//                             top.businessId,
//                             top.id,
//                         ], {
//                             editedDate: R.propOr(false, 'date', meeting)
//                                 || R.propOr(false, 'modifiedDate', top)
//                                 || R.propOr(false, 'createdDate', top),
//                             top,
//                             responsible: R.compose(
//                                 R.propOr(undefined, 'node'),
//                                 R.head,
//                                 R.values,
//                             )(responsibles),
//                             author: R.compose(
//                                 R.propOr(undefined, 'node'),
//                                 R.find(R.pathEq(['metadata', 'presence'], 'M')),
//                                 R.values,
//                             )(attendees),
//                         }),
//                     ),
//                 )(tops || {}),
//             )(meetings || {}),
//         )(subprojects || {});
//         setHistories(getResult());
//     };

//     return {
//         refresh,
//     };
// };
