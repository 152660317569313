import { useEffect, useState } from 'react';

const SETTINGS_KEY = 'settings';

export const ALWAYS_PULL = 'alwaysPull';

export const getLocalSettings = () => {
    try {
        return JSON.parse(window.localStorage.getItem(SETTINGS_KEY));
    } catch (error) {
        return {};
    }
};

export const setLocalSettings = settings => {
    settings && window.localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));
};

export const useSettings = () => {
    const [val, setVal] = useState(getLocalSettings());

    useEffect(() => {
        const listener = e => {
            console.log('settings change detected', e);
            setVal(getLocalSettings());
        };
        window.addEventListener('storage', listener);

        return () => {
            window.removeEventListener('storage', listener);
        };
    }, []);

    const setSettings = newSettings => {
        setVal(newSettings);
        setLocalSettings(newSettings);
    };

    return [val, setSettings];
};
