import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { ImFilePdf } from 'react-icons/im';
import { IoTrashOutline } from 'react-icons/io5';
import {
    MEETING_ATTACHMENT_EDGE,
    PROTOCOL_PDF_MODAL,
    TOP_ATTACHMENT_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { useModalState } from '../../hooks/useModal';
import { Button } from '../Common/Controls';
import { Modal } from '../Common/Modal';
import './PrintModal.css';
import { Attachment } from './AttachmentsModal';
import { ListField } from '../Common/Fields';
import { useFile } from '../../common/got.old';
import { useAttachments } from '../../hooks/useAttachments';

export const ProtocolPdfModal = ({ stack }) => {
    const [isOpen] = useModalState('protocol-pdf');
    return (
        <Modal
            name="protocol-pdf"
            style={{
                width: '80vw',
                left: '10vw',
                height: '90vh',
                top: '5vh',
            }}
            topbar={<ImFilePdf size={20} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <ModalContent stack={stack} /> : null}
        </Modal>
    );
};

const ModalContent = ({ stack: parentStack }) => {
    const stack = R.append(PROTOCOL_PDF_MODAL, parentStack);
    const { push, remove } = useGraph(...stack);
    const [{ topId, meetingId }] = useModalState('protocol-pdf');

    const { refresh, attachmentBags: meetingAttachmentBags } = useAttachments(
        stack,
        meetingId,
        MEETING_ATTACHMENT_EDGE,
    );

    const { refresh: refreshTopAttachments, attachmentBags: topAttachmentBags } = useAttachments(
        stack,
        topId,
        TOP_ATTACHMENT_EDGE,
    );

    useEffect(() => {
        meetingId && refresh();
        topId && refreshTopAttachments();
    }, [topId, meetingId]);

    // TODO move pdfs to got2
    const [protocolUrl, fetchProtocol] = useFile(meetingId, 'protocol');
    const [pdfLoaded, setPdfLoaded] = useState(false);
    useEffect(() => {
        const load = async () => {
            if (meetingId) {
                setPdfLoaded(false);
                await fetchProtocol();
                setPdfLoaded(true);
            }
        };

        load();
    }, [meetingId]);

    return (
        <div style={{ height: '100%' }}>
            <div className="modal-headline">
                <div>TOP files</div>
            </div>
            <AttachmentsList
                attachmentBags={topAttachmentBags}
                remove={remove}
                push={push}
                nodeId={topId}
                edgeTypes={TOP_ATTACHMENT_EDGE}
                stack={stack}
                top
            />
            <div className="modal-headline" style={{ marginTop: 30 }}>
                <div>Meeting files</div>
            </div>
            <AttachmentsList
                attachmentBags={meetingAttachmentBags}
                remove={remove}
                push={push}
                nodeId={meetingId}
                edgeTypes={MEETING_ATTACHMENT_EDGE}
                stack={stack}
            />
            <PDFArea pdfLoaded={pdfLoaded} protocolUrl={protocolUrl} />
        </div>
    );
};

const AttachmentsList = ({ attachmentBags, remove, push, nodeId, edgeTypes, top, stack }) =>
    attachmentBags.length > 0 ? (
        <ListField>
            {R.map(({ node: attachment, files }) => (
                <div className="row" key={attachment?.id}>
                    <Attachment attachment={attachment} files={files} stack={stack} />
                    <Button
                        onClick={() => {
                            remove(edgeTypes)(nodeId)(attachment);
                            push();
                        }}
                    >
                        <IoTrashOutline size={16} />
                    </Button>
                </div>
            ))(attachmentBags)}
        </ListField>
    ) : (
        <p
            style={{
                marginBottom: 14,
                fontSize: 22,
                fontWeight: 100,
                color: 'var(--corporate-color-1)',
            }}
        >
            No {top ? 'top' : 'meeting'} attachments found.
        </p>
    );

const PDFArea = ({ pdfLoaded, protocolUrl }) => (
    <>
        <div className="modal-headline" style={{ marginTop: 30 }}>
            <div>Minute</div>
        </div>
        <div className="pdf-viewer">
            {protocolUrl ? (
                <object
                    style={{ width: '100%', height: '100%' }}
                    data={`${protocolUrl}&toolbar=0&navpanes=0&scrollbar=0`}
                    type="application/pdf"
                >
                    <embed
                        src={`${protocolUrl}&toolbar=0&navpanes=0&scrollbar=0`}
                        type="application/pdf"
                    />
                </object>
            ) : (
                <p
                    style={{
                        fontSize: 22,
                        fontWeight: 100,
                        color: 'var(--corporate-color-1)',
                    }}
                >
                    {pdfLoaded ? 'No PDF found.' : 'Loading PDF...'}
                </p>
            )}
        </div>
    </>
);
