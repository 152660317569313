import React from 'react';
import { useModalState } from '../../hooks/useModal';
import { ModalMobile } from '../Common/ModalMobile';
import { PullModeToggle } from '../../Components/Elements/ProjectDashboard/PullModeToggle';
import { SETTINGS_MODAL_MOBILE } from '../../common/hooks.config.new';
import { useSettings } from '../../hooks/useSettings';

export const SettingsModalMobile = () => {
    const [isOpen] = useModalState(SETTINGS_MODAL_MOBILE);
    return (
        <ModalMobile name={SETTINGS_MODAL_MOBILE}>{isOpen ? <ModalContent /> : null}</ModalMobile>
    );
};

const ModalContent = () => {
    const [settings, setSettings] = useSettings();
    return (
        <>
            <div className="modal-headline-mobile">
                Settings
                <div className="modal-headline-filler" />
            </div>
            <div style={{ padding: '2rem 2rem 0 2rem' }}>
                <PullModeToggle settings={settings} setSettings={setSettings} mobile />
            </div>

            <div style={{ height: '20vh' }} />
        </>
    );
};
