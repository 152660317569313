import React, { useEffect } from 'react';
import * as R from 'ramda';
import { useModalState } from '../../hooks/useModal';
import { ImageField, TextField } from '../../Components/Common/Fields';
import { ModalMobile } from '../Common/ModalMobile';
import {
    EDIT_ORG_MODAL_MOBILE,
    ORGANIZATION_ID_KEY,
    ORGANIZATION_OPPORTUNITY_TRACKING_EDGE,
    PROJECT_ID_KEY,
    ROOT_ORGANIZATION_EDGE,
    SUBPROJECT_ID_KEY,
    useGraph,
} from '../../common/hooks.config.new';
import { constructNodeView } from '../../common/got-adjunct';
import { APPLICATION_ROOT_NODE } from '../../common/config';
import { newId, newMsDate } from '../../common/util';

export const EditOrganizationModalMobile = ({ stack: parentStack }) => {
    const [isOpen] = useModalState(EDIT_ORG_MODAL_MOBILE);
    const stack = R.append(EDIT_ORG_MODAL_MOBILE, parentStack);
    const { clear } = useGraph(...stack);
    return (
        <ModalMobile name={EDIT_ORG_MODAL_MOBILE} stack={stack} onBack={clear} onSave>
            {isOpen ? <ModalContent stack={stack} parentStack={parentStack} /> : null}
        </ModalMobile>
    );
};

const ModalContent = ({ stack, parentStack }) => {
    const { useView, pull, update, setFile, add, setRights } = useGraph(...stack);
    const { useVar } = useGraph(...parentStack);

    const [{ organizationId: listOrgId, isNew, user }] = useModalState(EDIT_ORG_MODAL_MOBILE);
    const [, setProjectId] = useVar(PROJECT_ID_KEY);
    const [, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [organizationId, setOrganizationId] = useVar(ORGANIZATION_ID_KEY);

    const organizationIdToUse = isNew ? organizationId : listOrgId;

    useEffect(() => {
        if (isNew) {
            const newOrganizationId = newId();
            const newOrganization = {
                id: newOrganizationId,
            };
            add(ROOT_ORGANIZATION_EDGE)(APPLICATION_ROOT_NODE)(newOrganization, {
                order: newMsDate(),
            });
            setOrganizationId(newOrganizationId);
            setProjectId();
            setSubprojectId();
            setRights(newOrganizationId)(user, {
                read: true,
                write: true,
                admin: true,
            });
            const newOppTrackingId = newId();
            const newOppTracking = {
                id: newOppTrackingId,
                name: 'Module: Opportunity Tracking',
                orgId: newOrganizationId,
            };
            add(ORGANIZATION_OPPORTUNITY_TRACKING_EDGE)(newOrganizationId)(newOppTracking);
            setRights(newOppTrackingId)(user, {
                read: true,
            });
        }
    }, []);

    const organizationView = constructNodeView(organizationIdToUse, {
        node: true,
        rights: true,
        files: true,
    });
    useEffect(() => {
        organizationIdToUse && pull(organizationView);
    }, [organizationIdToUse]);
    const { [organizationIdToUse]: organizationBag = {} } = useView(organizationView);
    // const isOrganizationAdmin = R.pathOr(false, ['rights', 'user', user, 'admin'], organizationBag);
    const organizationLogoUrl = R.path(['files', 'organizationLogo', 'url'], organizationBag);
    const organization = R.propOr({}, 'node', organizationBag);

    return (
        <>
            <div className="modal-headline-mobile">
                Edit Organization
                <div
                    className="modal-headline-filler"
                    style={{ backgroundColor: 'var(--corporate-color-14-ligh)' }}
                />
            </div>
            <TextField
                label="Organization name"
                value={R.propOr('', 'name', organization)}
                onChange={value => update(R.assoc('name', value, organization))}
                className="input-mobile"
                labelClass="label-mobile"
            />
            <ImageField
                label="Organization logo"
                value={organizationLogoUrl || ''}
                onChange={file => {
                    if (!file) {
                        return;
                    }
                    setFile(organizationIdToUse)('organizationLogo', file.name, file);
                }}
                className="mobile-img-field"
                labelStyle={{ fontSize: '2rem', color: 'var(--color-2)' }}
                labelClass="label-mobile"
            />
        </>
    );
};
