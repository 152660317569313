import React from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { IoChevronDownOutline, IoGridOutline, IoShareSocialOutline } from 'react-icons/io5';
import { OppsCountDonut } from '../ProjectDashboard/OppCharts';
import { ActionsChart } from '../ProjectDashboard/ActionCharts';
import { ListRow } from '../../Common/ListRow';
import { TopButtonRow } from '../TopButtonRow';
import { DashboardTop } from '../ProjectDashboard/DashboardTop';
import { Button } from '../../Common/Controls';
import { LoadAttachments } from '../HasAttachments';
import { TOP_ATTACHMENT_EDGE } from '../../../common/hooks.config.new';

export const ItemsCount = ({
    setDashboardState,
    setDashboardTops,
    projectsCount,
    allActions,
    allOpps,
    userCanTrackOpps,
}) => (
    <div className="content-container">
        <div
            className="column2 hoverwienrover"
            style={{ alignItems: 'center' }}
            onClick={() => {
                setDashboardState('projects');
            }}
        >
            <p className="headline">Projects</p>
            <p className="count">{projectsCount}</p>
        </div>
        {userCanTrackOpps && (
            <div
                className="column2 hoverwienrover"
                style={{ alignItems: 'center' }}
                onClick={() => {
                    setDashboardTops(allOpps);
                    setDashboardState('tops');
                }}
            >
                <p className="headline">Opportunities</p>
                <p className="count">{R.length(allOpps)}</p>
            </div>
        )}
        <div
            className="column2 hoverwienrover"
            style={{ alignItems: 'center' }}
            onClick={() => {
                setDashboardTops(allActions);
                setDashboardState('tops');
            }}
        >
            <p className="headline">Actions</p>
            <p className="count">{R.length(allActions)}</p>
        </div>
    </div>
);

export const OppsArea = ({
    stack,
    chartedOpps,
    allCriticalOpps,
    setDashboardState,
    setDashboardTops,
}) => (
    <div
        className="global-dashboard-area"
        style={{
            width: '30vw',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
        }}
    >
        <div
            className="content-container"
            style={{ justifyContent: 'center', alignItems: 'center' }}
        >
            <div className="diagram-container">
                <div className="charts-area">
                    <OppsCountDonut
                        stack={stack}
                        chartedOpps={chartedOpps.tops}
                        criticalOppsCount={R.length(allCriticalOpps)}
                        setGlobalDashboardTops={e => {
                            setDashboardTops(e);
                            setDashboardState('tops');
                        }}
                        global
                        donutWidth={500}
                        donutHeight={500}
                        containerWidth={500}
                        fontBig={30}
                        fontSmall={26}
                        topMargin={20}
                    />
                </div>
            </div>
        </div>
    </div>
);

export const ActionsArea = ({
    stack,
    allActions,
    setDashboardState,
    setDashboardTops,
    allActionStatusTypes,
}) => {
    const [noOfRenderedTops, setNoOfRenderedTops] = React.useState(20);
    const sortedNotDoneActions = R.compose(
        R.reverse,
        R.sortBy(R.path(['node', 'dueDate'])),
        R.filter(a => a.status !== 'done'),
    )(allActions);
    return (
        <>
            <div
                style={{
                    fontSize: 28,
                    color: 'var(--corporate-color-1)',
                    position: 'relative',
                    top: 24,
                    fontWeight: 100,
                }}
            >
                Actions
            </div>
            <div className="content-container">
                <div className="diagram-container">
                    <ActionsChart
                        stack={stack}
                        actions={allActions}
                        allActionStatusTypes={allActionStatusTypes}
                        setGlobalDashboardTops={e => {
                            setDashboardTops(e);
                            setDashboardState('tops');
                        }}
                    />
                </div>
            </div>
            <div className="content-container">
                <div className="column">
                    {R.map(a => (
                        <ListRow
                            key={a.nodeId}
                            style={{
                                marginTop: 'var(--space-large-row)',
                                marginLeft: 'var(--space-small)',
                                marginRight: 'var(--space-small)',
                            }}
                        >
                            <div className="top-search-row">
                                <TopButtonRow stack={stack} top={a} noEdit global />
                                <DashboardTop
                                    stack={stack}
                                    topId={a.nodeId}
                                    editable
                                    noButton
                                    global
                                    noId
                                />
                                <LoadAttachments
                                    stack={stack}
                                    node={a.node}
                                    edgeTypes={TOP_ATTACHMENT_EDGE}
                                />
                            </div>
                        </ListRow>
                    ))(R.slice(0, noOfRenderedTops)(sortedNotDoneActions))}
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            marginTop: '2rem',
                        }}
                    >
                        <Button
                            className="show-more-tops-button"
                            onClick={() => setNoOfRenderedTops(noOfRenderedTops + 20)}
                        >
                            <div>
                                <IoChevronDownOutline size="2.25rem" />
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export const TopsList = ({ stack, setDashboardTops, setDashboardState, dashboardTops }) => (
    <div className="content-container" style={{ marginBottom: 12 }}>
        <ButtonsRow setDashboardTops={setDashboardTops} setDashboardState={setDashboardState} />
        <div className="column">
            {RA.mapIndexed((a, index) => (
                <ListRow
                    key={a.nodeId}
                    style={{
                        marginTop: index === 0 ? 12 : 'var(--space-large-row)',
                    }}
                >
                    <div className="top-search-row">
                        <TopButtonRow stack={stack} top={a} noEdit global />
                        <DashboardTop
                            stack={stack}
                            topId={a.nodeId}
                            global
                            editable
                            noButton
                            noId
                        />
                        <LoadAttachments
                            stack={stack}
                            node={a.node}
                            edgeTypes={TOP_ATTACHMENT_EDGE}
                        />
                    </div>
                </ListRow>
            ))(dashboardTops)}
        </div>
    </div>
);

const ButtonsRow = ({ setDashboardTops, setDashboardState }) => (
    <div
        style={{
            display: 'flex',
            padding: 24,
            paddingBottom: 0,
            paddingTop: 12,
        }}
    >
        <Button
            onClick={() => {
                setDashboardTops([]);
                setDashboardState('dashboard');
            }}
        >
            <IoGridOutline size={22} />
            <p style={{ marginLeft: 9 }}>Global Dashboard</p>
        </Button>
        <Button
            style={{ marginLeft: 24 }}
            onClick={() => {
                setDashboardState('projects');
            }}
        >
            <IoShareSocialOutline size={25} />
            <p style={{ marginLeft: 9 }}>Projects</p>
        </Button>
    </div>
);
