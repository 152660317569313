import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useEffect } from 'react';
import { IoDocumentAttachOutline, IoTrashOutline } from 'react-icons/io5';
import { FILE_PROP_NAME } from '../../common/hooks.config.new';
import { useModalState } from '../../hooks/useModal';
import { Button } from '../Common/Controls';
import { FileField, ListField } from '../Common/Fields';
import { Modal } from '../Common/Modal';
import { useAttachmentFns, useAttachments } from '../../hooks/useAttachments';
import { useToast } from '../../hooks/useToast';
import { useLocalBlobUrl } from '../../hooks/useLocalBlobUrl';

export const MODAL_ATTACHMENTS = 'attachments';
export const MODAL_ATTACHMENTS_DASHBOARD_TOP = 'attachments-dashboard-top';
export const MODAL_ATTACHMENTS_EDIT_TOPS = 'attachments-edit-tops';

export const AttachmentsModal = ({ stack, name = MODAL_ATTACHMENTS }) => {
    const [{ fromId, edgeTypes, inheritFromId, meetingId = '', shouldPush = true }] =
        useModalState(name);

    const { refresh, attachmentBags } = useAttachments(stack, fromId, edgeTypes);

    useEffect(() => {
        refresh();
    }, [fromId]);

    const setToast = useToast('app');
    const { addAttachments, removeAttachment, save, push } = useAttachmentFns(
        stack,
        fromId,
        edgeTypes,
        inheritFromId,
    );

    return (
        <Modal
            name={name}
            style={{
                width: '50vw',
                left: '25vw',
            }}
            topbar={<IoDocumentAttachOutline size={24} color="var(--corporate-color-7)" />}
        >
            <div className="columns">
                <div>
                    <FileField
                        dragMessage="Click or drop your files here..."
                        onChange={async files => {
                            addAttachments(files, meetingId);
                            if (shouldPush) {
                                setToast('spinner', 'Adding attachments', 5000);
                                await save();
                                setToast('success', 'Attachments added', 5000);
                            }
                        }}
                    />
                    <div className="attachments-container" style={{ marginBottom: 0 }}>
                        <ListField>
                            {RA.mapIndexed(({ node: attachment, files }) => (
                                <div className="row" key={attachment?.id}>
                                    <Attachment
                                        stack={stack}
                                        attachment={attachment}
                                        files={files}
                                    />
                                    <Button
                                        onClick={async () => {
                                            removeAttachment(attachment.id, meetingId);
                                            if (shouldPush) {
                                                setToast('spinner', 'Removing attachments', 5000);
                                                await push();
                                                setToast('success', 'Attachments removed', 5000);
                                            }
                                        }}
                                    >
                                        <IoTrashOutline size={16} />
                                    </Button>
                                </div>
                            ))(attachmentBags)}
                        </ListField>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export const Attachment = ({ stack, attachment, files }) => {
    const blobUrl = useLocalBlobUrl(stack, attachment.id, FILE_PROP_NAME);
    const remoteUrl = R.pathOr('', [FILE_PROP_NAME, 'url'], files);

    const url = blobUrl || remoteUrl;
    const filename = R.propOr('', 'filename', attachment);
    return (
        <div>
            <a href={url} target="_blank" rel="noreferrer" download={false}>
                {filename}
            </a>
        </div>
    );
};
