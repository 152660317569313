import React from 'react';

export const Loader = ({ containerStyle }) => (
    <div className="loader-container" style={containerStyle}>
        <div className="loader" />
        <div className="loader2" />
    </div>
);

export const MiniLoader = () => (
    <div className="mini-loader-container">
        <div className="mini-loader" />
        <div className="mini-loader2" />
    </div>
);

export const LoaderMobile = ({ style }) => (
    <div className="loader-container-mobile" style={style}>
        <div className="loader-mobile" />
        <div className="loader2-mobile" />
    </div>
);

export const MiniLoaderMobile = () => (
    <div className="mini-loader-container-mobile">
        <div className="mini-loader-mobile" />
        <div className="mini-loader2-mobile" />
    </div>
);
