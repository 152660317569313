import React, { useEffect, useMemo } from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { IoCheckmarkSharp, IoTrailSignOutline } from 'react-icons/io5';
import { useNodeFromVar } from '../../../hooks/useNode';
import {
    MILESTONE_ID_KEY,
    MILESTONE_TOPS_MODAL,
    PHASE_TOPS_MODAL,
    PROJECT_ID_KEY,
    PROJECT_MILESTONE_EDGE,
    SORTED_GROUPED_TOPS_KEY,
    useGraph,
} from '../../../common/hooks.config.new';
import { useModal } from '../../../hooks/useModal';
import { newIsoDate, newMsDate } from '../../../common/util';

const constructMilestonesView = projectId =>
    projectId
        ? {
              [projectId]: {
                  as: 'project',
                  include: {
                      edges: true,
                  },
                  edges: {
                      [PROJECT_MILESTONE_EDGE]: {
                          as: 'milestones',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

// TODO clean split between detailed and overview component

export const ProjectPhasesGanttChart = ({ stack, detailedView }) => {
    const { useVar, useView, pull } = useGraph(...stack);

    const { node: project } = useNodeFromVar(stack, PROJECT_ID_KEY);

    const projectId = R.propOr('', 'id', project);

    const milestonesView = constructMilestonesView(projectId);
    useEffect(() => {
        pull(milestonesView);
    }, []);
    const { project: { milestones = {} } = {} } = useView(milestonesView);

    const getPhasesMilestone = phase =>
        R.compose(R.find(R.pathEq(['node', 'phase'], phase)), R.values)(milestones);

    const calendarWeeks = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
        49, 50, 51, 52,
    ];
    const today = newIsoDate();
    // TODO Implement Schaltjahr condition until February 2024
    const daysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const thisYear = R.slice(0, 4, today);
    const startOfFirstCalenderWeek = year => {
        const firstYearsCW =
            8 - (new Date(`01.01.${year}`).getDay() === 0 ? 7 : new Date(`01.01.${year}`).getDay());
        return firstYearsCW;
    };

    const phases = R.values(R.propOr([], 'phases', project));
    const adjustedPhases = R.compose(
        R.unnest,
        R.map(phase => {
            if (R.slice(0, 4, phase.endDate) > R.slice(0, 4, phase.startDate)) {
                const splittedPhase = [
                    {
                        ...phase,
                        endDate: `${R.slice(0, 4, phase.startDate)}-12-31`,
                        splittedPhase: 'end',
                    },
                    {
                        ...phase,
                        startDate: `${R.slice(0, 4, phase.endDate)}.01.0${
                            1 + startOfFirstCalenderWeek(R.slice(0, 4, phase.endDate))
                        }`,
                        splittedPhase: 'start',
                    },
                ];

                return splittedPhase;
            }
            return phase;
        }),
        R.filter(phase => new Date(phase.startDate).getTime() < new Date(phase.endDate).getTime()),
        R.filter(phase => phase.startDate && phase.endDate),
    )(phases);

    const phasesStartYears = R.compose(
        R.uniq,
        R.map(R.slice(0, 4)),
        R.map(R.prop('startDate')),
    )(adjustedPhases);

    const phasesEndYears = R.compose(
        R.uniq,
        R.map(R.slice(0, 4)),
        R.map(R.prop('endDate')),
    )(adjustedPhases);

    const phasesYears = R.uniq(R.concat(phasesStartYears, phasesEndYears));

    const getDatesCalenderWeek = (date, year) => {
        if (date) {
            const fullMonthsTillDate = R.slice(5, 7, date) - 1;
            const avgDaysPerMonth =
                fullMonthsTillDate === 0
                    ? 0
                    : R.sum(R.slice(0, fullMonthsTillDate, daysPerMonth)) / fullMonthsTillDate;
            const daysTillDate =
                fullMonthsTillDate * avgDaysPerMonth + parseFloat(R.slice(8, 10, date));
            const currentCalenderWeek = Math.ceil(
                (daysTillDate - startOfFirstCalenderWeek(year)) / 7,
            );
            return currentCalenderWeek;
        }
        return null;
    };

    const currentCW = getDatesCalenderWeek(today, thisYear);

    const earliestCW = year => {
        if (R.indexOf(year, phasesYears) > 0) return 0;
        const startDatesToMS = R.compose(
            R.map(date => new Date(date).getTime()),
            R.map(R.propOr(undefined, 'startDate')),
        )(R.filter(phase => R.slice(0, 4, phase.startDate) === year)(adjustedPhases));
        const earliestStartDate =
            R.length(startDatesToMS) > 0 ? new Date(Math.min(...startDatesToMS)).toISOString() : [];
        const phasesFirstCW = getDatesCalenderWeek(earliestStartDate, year) - 1;
        return phasesFirstCW;
    };

    const latestCW = year => {
        if (R.indexOf(year, phasesYears) < R.length(phasesYears) - 1) return 52;
        const endDatesToMS = R.compose(
            R.map(date => new Date(date).getTime()),
            R.map(R.propOr(undefined, 'endDate')),
        )(R.filter(phase => R.slice(0, 4, phase.startDate) === year)(adjustedPhases));
        const startDatesToMS = R.compose(
            R.map(date => new Date(date).getTime()),
            R.map(R.propOr(undefined, 'startDate')),
        )(R.filter(phase => R.slice(0, 4, phase.startDate) === year)(adjustedPhases));
        const latestStartDate =
            R.length(startDatesToMS) > 0 ? new Date(Math.max(...endDatesToMS)).toISOString() : [];
        const phasesLastCW = getDatesCalenderWeek(latestStartDate, year);
        return phasesLastCW;
    };

    const [{ tops: sortedGroupedTops = {} } = {}] = useVar(SORTED_GROUPED_TOPS_KEY);

    const topRows = useMemo(
        () =>
            R.compose(
                R.sortBy(R.prop('businessId')),
                R.values,
                R.map(entries => ({
                    ...R.last(entries),
                    previousVersions: R.dropLast(1, entries),
                })),
            )(sortedGroupedTops),
        [sortedGroupedTops],
    );

    const topsCount = phaseName =>
        R.length(
            R.filter(top => R.pathOr('Not Assigned', ['top', 'phase'], top) === phaseName)(topRows),
        );

    const [, setMilestoneId] = useVar(MILESTONE_ID_KEY);
    const openMilestoneTopsModal = useModal(MILESTONE_TOPS_MODAL);
    const openPhaseTopsModal = useModal(PHASE_TOPS_MODAL);

    const cwElementWidth = 162;

    const calcWidth = ({ phase, year }) => {
        const width =
            (getDatesCalenderWeek(R.propOr(0, 'endDate', phase), year) -
                (getDatesCalenderWeek(R.propOr(0, 'startDate', phase), year) - 1)) *
                cwElementWidth +
            1;
        return width;
    };

    const calcPhaseMarginLeft = ({ phase, year }) => {
        const marginLeft =
            (getDatesCalenderWeek(R.propOr(0, 'startDate', phase), year) - 1 - earliestCW(year)) *
            cwElementWidth;
        return marginLeft;
    };

    const calcMilestoneMarginLeft = ({ phase, year }) => {
        const marginLeft =
            (getDatesCalenderWeek(R.propOr(0, 'startDate', phase), year) - earliestCW(year)) *
                cwElementWidth +
            (getDatesCalenderWeek(R.propOr(0, 'endDate', phase), year) -
                getDatesCalenderWeek(R.propOr(0, 'startDate', phase), year)) *
                cwElementWidth -
            25;
        return marginLeft;
    };

    const phaseColors = ({ phase, year }) => {
        const phaseIsActive =
            getDatesCalenderWeek(R.propOr(0, 'startDate', phase), year) <= currentCW &&
            currentCW < getDatesCalenderWeek(R.propOr(0, 'endDate', phase), year);
        const phaseIsFinished =
            getDatesCalenderWeek(R.propOr(0, 'endDate', phase), year) < currentCW;
        if (phaseIsActive) {
            return {
                borderColor: 'var(--corporate-color-14-light)',
                boxShadow: '0 5px 8px -2px var(--corporate-color-14-light)',
            };
        }
        if (phaseIsFinished) {
            return {
                borderColor: 'var(--corporate-color-13-light)',
                boxShadow: '0 5px 8px -2px var(--corporate-color-13-light)',
            };
        }
        return {};
    };

    return detailedView ? (
        <GanttDetailed
            openMilestoneTopsModal={openMilestoneTopsModal}
            openPhaseTopsModal={openPhaseTopsModal}
            phasesYears={phasesYears}
            currentCW={currentCW}
            calendarWeeks={calendarWeeks}
            earliestCW={earliestCW}
            latestCW={latestCW}
            calcPhaseMarginLeft={calcPhaseMarginLeft}
            calcMilestoneMarginLeft={calcMilestoneMarginLeft}
            calcWidth={calcWidth}
            phaseColors={phaseColors}
            topsCount={topsCount}
            setMilestoneId={setMilestoneId}
            getPhasesMilestone={getPhasesMilestone}
            adjustedPhases={adjustedPhases}
        />
    ) : (
        <GanttOverview
            openMilestoneTopsModal={openMilestoneTopsModal}
            openPhaseTopsModal={openPhaseTopsModal}
            topsCount={topsCount}
            setMilestoneId={setMilestoneId}
            phases={phases}
            milestones={milestones}
        />
    );
};

const GanttOverview = ({
    openMilestoneTopsModal,
    openPhaseTopsModal,
    topsCount,
    setMilestoneId,
    phases,
    milestones,
}) => {
    const phasesTotalWidth = () => {
        const startDatesToMS = R.compose(
            R.map(date => new Date(date).getTime()),
            R.map(R.propOr(undefined, 'startDate')),
        )(phases);
        const endDatesToMS = R.compose(
            R.map(date => new Date(date).getTime()),
            R.map(R.propOr(undefined, 'endDate')),
        )(phases);
        const earliestStartDate = Math.min(...startDatesToMS);
        const latestEndDate = Math.max(...endDatesToMS);
        const timeframeForWidthCalc = latestEndDate - earliestStartDate;
        return { widthMS: timeframeForWidthCalc, firstStartDateMS: earliestStartDate };
    };

    const getPhaseStyleAttributes = phase => {
        const phaseStartDateMS = new Date(R.propOr(undefined, 'startDate', phase)).getTime();
        const phaseEndDateMS = new Date(R.propOr(undefined, 'endDate', phase)).getTime();
        const phaseWidthMS = phaseEndDateMS - phaseStartDateMS;
        const phaseMarginLeftMS = phaseStartDateMS - phasesTotalWidth().firstStartDateMS;
        const phaseWidthRelative =
            Math.floor((phaseWidthMS / phasesTotalWidth().widthMS) * 100 * 100) / 100;
        const phaseMarginLeftRelative =
            Math.floor((phaseMarginLeftMS / phasesTotalWidth().widthMS) * 100 * 100) / 100;
        return { widthRelative: phaseWidthRelative, marginLeftRelative: phaseMarginLeftRelative };
    };

    const getMilestoneMarginLeft = milestone => {
        const dueDateMS = new Date(R.propOr(undefined, 'dueDate', milestone)).getTime();
        const milestonesPhase = milestone.final
            ? R.find(R.propEq('name', milestone.phase))(phases)
            : {};
        const milestonesPhaseMarginleft = milestone.final
            ? getPhaseStyleAttributes(milestonesPhase).marginLeftRelative +
              getPhaseStyleAttributes(milestonesPhase).widthRelative
            : 0;

        const milestoneMarginLeftMS = dueDateMS - phasesTotalWidth().firstStartDateMS;
        const milestoneMarginLeftRelative =
            Math.floor((milestoneMarginLeftMS / phasesTotalWidth().widthMS) * 100 * 100) / 100;
        return milestone.final ? milestonesPhaseMarginleft : milestoneMarginLeftRelative;
    };

    const getPhaseMilestones = phase =>
        R.compose(
            R.sortBy(R.path(['node', 'createdDate'])),
            R.filter(milestone => milestone.node.phase === phase.name),
            R.values,
        )(milestones);

    const todayMS = newMsDate();
    const checkActivePhase = phase => {
        const phaseStartDateMS = new Date(R.propOr(undefined, 'startDate', phase)).getTime();
        const phaseEndDateMS = new Date(R.propOr(undefined, 'endDate', phase)).getTime();
        const isPhaseActive = todayMS >= phaseStartDateMS && todayMS <= phaseEndDateMS;
        return isPhaseActive;
    };

    const checkFinalizedPhase = phase => {
        const phaseEndDateMS = new Date(R.propOr(undefined, 'endDate', phase)).getTime();
        const isPhaseFinalized = todayMS >= phaseEndDateMS;
        return isPhaseFinalized;
    };

    return (
        <div className="gantt-chart-overview fader">
            <div style={{ display: 'flex', paddingLeft: 24, marginTop: 24 }}>
                <div className="phases-rows">
                    {R.map(
                        phase =>
                            R.prop('startDate', phase) &&
                            R.prop('endDate', phase) && (
                                <div key={phase.id}>
                                    <div
                                        className="gantt-phase-overview"
                                        onClick={() => openPhaseTopsModal(phase)}
                                        style={{
                                            width: `${
                                                getPhaseStyleAttributes(phase).widthRelative * 0.925
                                            }vw`,
                                            marginLeft: `${
                                                getPhaseStyleAttributes(phase).marginLeftRelative *
                                                0.925
                                            }vw`,
                                            borderColor:
                                                checkActivePhase(phase) &&
                                                'var(--corporate-color-14-light)',
                                            boxShadow:
                                                checkActivePhase(phase) &&
                                                '0 5px 8px -2px var(--corporate-color-14-light)',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 1,
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <p className="top-count-overview">
                                                    {topsCount(R.propOr('', 'name', phase))}
                                                </p>
                                                <p className="phase-name">
                                                    {R.propOr('', 'name', phase)}
                                                </p>
                                            </div>
                                            {checkFinalizedPhase(phase) && (
                                                <IoCheckmarkSharp
                                                    size={22}
                                                    color="var(--corporate-color-13)"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            position: 'relative',
                                            width: '100%',
                                            height:
                                                R.length(getPhaseMilestones(phase)) > 0 ? 42 : 0,
                                        }}
                                    >
                                        {R.map(milestone => (
                                            <div
                                                key={milestone.modeId}
                                                onClick={() => {
                                                    setMilestoneId(milestone.nodeId);
                                                    openMilestoneTopsModal();
                                                }}
                                                className={
                                                    milestone.node.final
                                                        ? 'final-milestone-indicator-overview'
                                                        : 'milestone-indicator-overview'
                                                }
                                                style={{
                                                    position: 'absolute',
                                                    left: `calc(${
                                                        getMilestoneMarginLeft(milestone.node) *
                                                        0.925
                                                    }vw - 16px)`,
                                                }}
                                            >
                                                <IoTrailSignOutline
                                                    style={{
                                                        transform: 'rotate(-45deg)',
                                                        fontSize: 'inherit',
                                                        color: 'inherit',
                                                    }}
                                                />
                                            </div>
                                        ))(getPhaseMilestones(phase))}
                                    </div>
                                </div>
                            ),
                    )(phases)}
                    <div style={{ height: 500 }} />
                </div>
            </div>
        </div>
    );
};

const GanttDetailed = ({
    openMilestoneTopsModal,
    openPhaseTopsModal,
    phasesYears,
    currentCW,
    calendarWeeks,
    earliestCW,
    latestCW,
    calcPhaseMarginLeft,
    calcMilestoneMarginLeft,
    calcWidth,
    phaseColors,
    topsCount,
    setMilestoneId,
    getPhasesMilestone,
    adjustedPhases,
}) => (
    <div className="gantt-chart fader">
        <div style={{ display: 'flex', paddingLeft: 24 }}>
            {R.map(year => (
                <div
                    key={year}
                    className="gantt-content"
                    style={{ border: R.length(phasesYears) <= 1 && 'none' }}
                >
                    <div className="year">{year}</div>
                    <div className="cws-row">
                        {RA.mapIndexed((cw, index) => (
                            <div className="cw-container" key={cw}>
                                <div
                                    className="cw-line"
                                    style={{
                                        borderColor:
                                            cw === currentCW && 'var(--corporate-color-14)',
                                    }}
                                >
                                    {(R.indexOf(year, phasesYears) === 0 ||
                                        (R.indexOf(year, phasesYears) > 0 && index > 0)) && (
                                        <div
                                            className="cw-start"
                                            style={{
                                                backgroundColor:
                                                    cw === currentCW && 'var(--corporate-color-14)',
                                            }}
                                        />
                                    )}
                                </div>
                                <div
                                    className="cw-indicator"
                                    style={{
                                        borderColor:
                                            cw === currentCW && 'var(--corporate-color-14)',
                                        color: cw === currentCW && 'var(--corporate-color-14)',
                                        boxShadow:
                                            cw === currentCW &&
                                            '0 5px 8px -2px var(--corporate-color-14-light)',
                                    }}
                                >
                                    CW-0
                                    {cw}
                                </div>
                                <div
                                    className="cw-line"
                                    style={{
                                        justifyContent:
                                            cw === R.length(calendarWeeks) && 'flex-end',
                                        borderColor:
                                            cw === currentCW && 'var(--corporate-color-14)',
                                    }}
                                />
                            </div>
                        ))(calendarWeeks.slice(earliestCW(year), latestCW(year)))}
                    </div>
                    <div className="phases-rows">
                        {R.map(
                            phase =>
                                R.prop('startDate', phase) &&
                                R.prop('endDate', phase) && (
                                    <div key={phase.id}>
                                        <div
                                            style={{
                                                marginLeft: calcPhaseMarginLeft({ phase, year }),
                                                width: calcWidth({ phase, year }),
                                                borderColor: phaseColors({ phase, year })
                                                    .borderColor,
                                                boxShadow: phaseColors({ phase, year }).boxShadow,
                                                borderTopRightRadius:
                                                    phase.splittedPhase === 'end' && 0,
                                                borderBottomRightRadius:
                                                    phase.splittedPhase === 'end' && 0,
                                                borderTopLeftRadius:
                                                    phase.splittedPhase === 'start' && 0,
                                                borderBottomLeftRadius:
                                                    phase.splittedPhase === 'start' && 0,
                                            }}
                                            className="gantt-phase"
                                            onClick={() => openPhaseTopsModal(phase)}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <p className="top-count">
                                                    {topsCount(R.propOr('', 'name', phase))}
                                                </p>
                                                <p className="phase-name">
                                                    {R.propOr('', 'name', phase)}
                                                </p>
                                            </div>
                                        </div>
                                        {getPhasesMilestone(R.prop('name', phase)) && (
                                            <div
                                                onClick={() => {
                                                    setMilestoneId(
                                                        getPhasesMilestone(R.prop('name', phase))
                                                            .nodeId,
                                                    );
                                                    openMilestoneTopsModal();
                                                }}
                                                className="milestone-indicator"
                                                style={{
                                                    marginLeft: calcMilestoneMarginLeft({
                                                        phase,
                                                        year,
                                                    }),
                                                }}
                                            >
                                                <IoTrailSignOutline
                                                    style={{
                                                        transform: 'rotate(-45deg)',
                                                        fontSize: 'inherit',
                                                        color: 'inherit',
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ),
                        )(
                            R.filter(phase => R.slice(0, 4, phase.startDate) === year)(
                                adjustedPhases,
                            ),
                        )}
                        <div style={{ height: 50 }} />
                    </div>
                </div>
            ))(phasesYears)}
        </div>
    </div>
);
