import React, { useEffect } from 'react';
import * as R from 'ramda';
import { useModalState } from '../../hooks/useModal';
import { TextField } from '../../Components/Common/Fields';
import { ModalMobile } from '../Common/ModalMobile';
import {
    EDIT_SUBPROJECT_MODAL_MOBILE,
    useGraph,
    SUBPROJECT_MEETING_EDGE,
    PROJECT_SUBPROJECT_EDGE,
    PROJECT_ID_KEY,
    SUBPROJECT_ID_KEY,
} from '../../common/hooks.config.new';
import { newId, newIsoDate, newMsDate } from '../../common/util';

const constructMeetingsView = subprojectId =>
    subprojectId
        ? {
              [subprojectId]: {
                  as: 'subproject',
                  include: {
                      node: true,
                      rights: true,
                      files: true,
                  },
                  edges: {
                      [SUBPROJECT_MEETING_EDGE]: {
                          as: 'meetings',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const EditSubprojectModalMobile = ({ stack: parentStack }) => {
    const [isOpen] = useModalState(EDIT_SUBPROJECT_MODAL_MOBILE);
    const stack = R.append(EDIT_SUBPROJECT_MODAL_MOBILE, parentStack);
    const { clear } = useGraph(...stack);

    return (
        <ModalMobile name={EDIT_SUBPROJECT_MODAL_MOBILE} stack={stack} onBack={clear} onSave>
            {isOpen ? <ModalContent stack={stack} parentStack={parentStack} /> : null}
        </ModalMobile>
    );
};

const ModalContent = ({ stack, parentStack }) => {
    const { useView, pull, update, add, setRights, inheritRights } = useGraph(...stack);
    const { useVar } = useGraph(...parentStack);
    const [projectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [{ subprojectId: listSubprojectId, isNew, user }] = useModalState(
        EDIT_SUBPROJECT_MODAL_MOBILE,
    );
    const subprojectIdToUse = isNew ? subprojectId : listSubprojectId;
    const subprojectView = constructMeetingsView(subprojectIdToUse);
    useEffect(() => {
        subprojectIdToUse && pull(subprojectView);
    }, [subprojectIdToUse]);
    const { subproject: subprojectBag = {} } = useView(subprojectView);
    const subproject = R.propOr({}, 'node', subprojectBag);

    useEffect(() => {
        if (isNew) {
            const newSubprojectId = newId();
            const newSubproject = {
                id: newSubprojectId,
                createdDate: newIsoDate(),
            };
            add(PROJECT_SUBPROJECT_EDGE)(projectId)(newSubproject, { order: newMsDate() });
            setRights(newSubprojectId)(user, {
                read: true,
                write: true,
            });
            inheritRights(newSubprojectId)(projectId);
            setSubprojectId(newSubprojectId);
        }
    }, [isNew]);

    return (
        <>
            <div className="modal-headline-mobile">
                Edit Subproject
                <div
                    className="modal-headline-filler"
                    style={{ backgroundColor: 'var(--corporate-color-14-ligh)' }}
                />
            </div>
            <TextField
                label="Subproject name"
                value={subproject.title || ''}
                onChange={value => update(R.assoc('title', value, subproject))}
                className="input-mobile"
                labelClass="label-mobile"
            />
            <TextField
                label="Subproject ID"
                value={subproject.businessId || ''}
                onChange={value => update(R.assoc('businessId', value, subproject))}
                className="input-mobile"
                labelClass="label-mobile"
            />
        </>
    );
};
