import React, { useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import { IoAnalyticsOutline, IoRemoveOutline } from 'react-icons/io5';
import {
    OPP_TRACKING,
    PROJECT_ID_KEY,
    SCORE_CARD_MODAL,
    SORTED_GROUPED_TOPS_KEY,
    SUBPROJECT_ID_KEY,
    TOP_ACTION_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { OppActions } from '../Elements/OppActions';
import { Toast } from '../Elements/Toast';
import { ListRow } from '../Common/ListRow';
import { ProtocolTop } from '../Elements/ProtocolScreen/ProtocolTop';
import { useNode } from '../../hooks/useNode';
import { constructNodeView, createOfflinePush } from '../../common/got-adjunct';
import { OppTimeline } from '../Elements/OppTimeline';
import { useToast } from '../../hooks/useToast';
import { TopButtonRow } from '../Elements/TopButtonRow';
import { useUserEmail } from '../../hooks/useUserEmail';
import '../Screens/DashboardScreen.css';
import { ItemsCountBar } from '../Elements/ProjectDashboard/ItemsCountBar';

export const ScoreCardModal = ({ stack, autoPush, noEdit }) => {
    const [isOpen, close] = useModalState('scoreCard');
    return (
        <Modal
            name="scoreCard"
            style={{
                height: '87vh',
                top: '6.5vh',
                width: '95vw',
                left: '2.5vw',
            }}
            topbar={<IoAnalyticsOutline size={26} color="var(--corporate-color-7)" />}
        >
            {isOpen ? (
                <ScoreCardModalContent
                    stack={stack}
                    autoPush={autoPush}
                    noEdit={noEdit}
                    close={close}
                />
            ) : null}
        </Modal>
    );
};

const actionPullView = {
    edges: {
        [TOP_ACTION_EDGE]: {
            as: 'actions',
            include: {
                edges: true,
            },
        },
    },
};

const actionNodeView = {
    edges: {
        [TOP_ACTION_EDGE]: {
            as: 'actions',
            include: {
                node: true,
                edges: true,
            },
        },
    },
};

const ScoreCardModalContent = ({ stack: parentStack, autoPush, noEdit }) => {
    const stack = R.append(SCORE_CARD_MODAL, parentStack);
    const { useVar, useView, pull, dissoc, merge, update } = useGraph(...stack);
    const [oppTracking] = useVar(OPP_TRACKING);
    const [selectedOpp] = useModalState('scoreCard');
    const selectedOppId = selectedOpp.id;
    const selectedOppBag = useNode(stack, selectedOppId);
    const selectedOppBusinessId = R.path(['node', 'businessId'], selectedOppBag);

    const [{ tops: sortedGroupedTops = {} }] = useVar(SORTED_GROUPED_TOPS_KEY);

    const adjustedOpp = top => {
        const indexOfOpp = R.indexOf(top.node.businessId, R.keys(sortedGroupedTops));
        const allVersions1 = R.values(sortedGroupedTops)[indexOfOpp];
        const allVersions = allVersions1 || [];
        const adjustedTop = {
            id: allVersions.length === 0 ? top?.nodeId : R.last(allVersions)?.top?.id,
            oppId: top.oppId || '',
            top: {
                top: allVersions.length === 0 ? top.node : R.last(allVersions)?.top,
                responsible: allVersions.length === 0 ? '' : R.last(allVersions)?.responsible,
                previousVersions: R.init(allVersions),
            },
        };
        return adjustedTop;
    };
    const adjustedActions = top => {
        const indexOfOpp = R.indexOf(top.node.businessId, R.keys(sortedGroupedTops));
        const allVersions1 = R.values(sortedGroupedTops)[indexOfOpp];
        const allVersions = allVersions1 || [];
        const adjustedTop = {
            nodeId: top.nodeId || '',
            id: R.last(allVersions)?.top?.id,
            oppId: top.oppId || '',
            top: {
                top: R.last(allVersions)?.top,
                responsible: R.last(allVersions)?.responsible,
                previousVersions: R.init(allVersions),
            },
        };
        return adjustedTop;
    };

    const versionIds = useMemo(
        () =>
            R.compose(
                R.map(R.path(['top', 'id'])),
                R.propOr([], selectedOppBusinessId),
            )(sortedGroupedTops),
        [sortedGroupedTops, selectedOppBusinessId],
    );

    const allVersionsView = useMemo(
        () =>
            R.compose(
                R.mergeAll,
                R.map(topId => ({
                    [topId]: actionNodeView,
                })),
            )(versionIds),
        [versionIds],
    );

    const viewResult = useView(allVersionsView);

    useEffect(() => {
        if (versionIds && versionIds.length > 0) {
            const view = R.compose(
                R.mergeAll,
                R.map(topId => ({
                    [topId]: actionPullView,
                })),
            )(versionIds);
            pull(view);
        }
    }, [versionIds]);

    const sortedActionBags = useMemo(
        () =>
            R.compose(
                R.sortBy(R.path(['node', 'dueDate'])),
                R.values,
                R.mergeAll,
                R.values,
                R.map(({ actions = {}, nodeId: oppId }) =>
                    R.map(actionBag => ({ ...actionBag, oppId }))(actions),
                ),
            )(viewResult),
        [viewResult],
    );

    const setToast = useToast('scorecard');
    const toastConfig = {
        textOnStart: 'Saving data...',
        textOnSuccess: 'Successfully saved.',
        textOnError: 'You are offline. Please sync when back online.',
    };
    const push = createOfflinePush(stack, setToast, toastConfig);

    const dissocAction = oppId => action => {
        dissoc(TOP_ACTION_EDGE)(oppId)(action);
        autoPush ? push() : merge();
    };

    const [projectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);
    const projectView = constructNodeView(projectId, { node: true, rights: true, files: true });
    useEffect(() => {
        projectId && pull(projectView);
    }, [projectId]);
    const { [projectId]: projectBag = {} } = useView(projectView);
    const user = useUserEmail();
    const canAdminProject = R.pathOr(false, ['rights', 'user', user, 'admin'], projectBag);
    const canWriteProject = R.pathOr(false, ['rights', 'user', user, 'write'], projectBag);

    const setEditMessageToast = useToast('edit-message');
    // TODO change subproject in edit modal to tops sub
    const editMessage = () => {
        setEditMessageToast('error', 'Select a subproject to edit this item...', 5000);
    };

    const itemsCount = topType =>
        R.compose(
            R.length,
            R.filter(top => top.node.type === topType),
        )(sortedActionBags);

    const [showTopType, setShowTopType] = useState();
    const filteredTops = R.filter(top => (showTopType ? top.node.type === showTopType : true))(
        sortedActionBags,
    );

    const adjustedActionBags = R.map(a => adjustedActions(a))(filteredTops);

    return (
        <>
            <div className="columns" style={{ alignItems: 'flex-start' }}>
                <div style={{ flex: 2.5, marginRight: 15 }}>
                    <div className="modal-headline" style={{ marginBottom: 0 }}>
                        <div>
                            <p style={{ fontSize: 26, fontWeight: 'normal' }}>Scorecard</p>
                            <IoRemoveOutline size={24} style={{ margin: '0 12 0 12' }} />
                            <p style={{ fontSize: 26, fontWeight: 100 }}>
                                {selectedOppBag.node.businessId}
                            </p>
                        </div>
                    </div>
                    <ListRow
                        key={selectedOppId}
                        style={{
                            marginBottom: 20,
                            marginLeft: 'var(--space-small)',
                            marginRight: 'var(--space-small)',
                        }}
                    >
                        <div className="top-search-row">
                            <TopButtonRow
                                top={adjustedOpp(selectedOppBag)}
                                stack={stack}
                                canWriteProject={canWriteProject}
                                canAdminProject={canAdminProject}
                                editMessage={editMessage}
                                subprojectId={subprojectId}
                                scorecard
                                noEdit={noEdit}
                                oppTracking={oppTracking}
                            />
                            <ProtocolTop
                                stack={stack}
                                topId={adjustedOpp(selectedOppBag).id}
                                editable={false}
                                noButton
                                scorecard
                            />
                        </div>
                    </ListRow>
                    <div className="modal-headline" style={{ marginBottom: 0, marginTop: '3em' }}>
                        <div style={{ fontSize: 26 }}>Linked Items</div>
                    </div>
                    <ItemsCountBar
                        showTopType={showTopType}
                        setShowTopType={setShowTopType}
                        itemsCount={itemsCount}
                    />
                    {sortedActionBags.length > 0 && (
                        <OppActions
                            stack={stack}
                            actionBags={adjustedActionBags}
                            dissocAction={dissocAction}
                            canWriteProject={canWriteProject}
                            canAdminProject={canAdminProject}
                            editMessage={editMessage}
                            subprojectId={subprojectId}
                            noEdit={noEdit}
                            oppTracking={oppTracking}
                        />
                    )}
                </div>
                <div style={{ flex: 1, marginLeft: 15 }}>
                    <div className="modal-headline" style={{ marginBottom: 0 }}>
                        <div style={{ fontSize: 26 }}>Status Timeline</div>
                    </div>
                    <TimelineLegend />
                    <OppTimeline stack={stack} save={push} update={update} topId={selectedOppId} />
                </div>
            </div>
            <Toast name="scorecard" />
        </>
    );
};

const TimelineLegend = () => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            margin: 'var(--space-normal-row) 0 var(--space-small) 0',
        }}
    >
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    height: 20,
                    width: 40,
                    borderRadius: 20,
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 7px 0px var(--corporate-color-1)',
                    border: '1px solid var(--corporate-color-1)',
                }}
            />
            <p
                style={{
                    fontSize: 16,
                    fontWeight: 100,
                    color: 'var(--corporate-color-1)',
                    marginTop: 6,
                }}
            >
                Current
            </p>
        </div>
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    height: 20,
                    width: 40,
                    borderRadius: 20,
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 7px 0px var(--corporate-color-1-light)',
                    border: '1px solid var(--corporate-color-1-light)',
                }}
            />
            <p
                style={{
                    fontSize: 16,
                    fontWeight: 100,
                    color: 'var(--corporate-color-1)',
                    marginTop: 6,
                }}
            >
                Next
            </p>
        </div>
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    height: 20,
                    width: 40,
                    borderRadius: 20,
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 7px 0px var(--corporate-color-11)',
                    border: '1px solid var(--corporate-color-11)',
                }}
            />
            <p
                style={{
                    fontSize: 16,
                    fontWeight: 100,
                    color: 'var(--corporate-color-1)',
                    marginTop: 6,
                }}
            >
                Achieved
            </p>
        </div>
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    height: 20,
                    width: 40,
                    borderRadius: 20,
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 7px 0px var(--color-error)',
                    border: '1px solid var(--color-error)',
                }}
            />
            <p
                style={{
                    fontSize: 16,
                    fontWeight: 100,
                    color: 'var(--corporate-color-1)',
                    marginTop: 6,
                }}
            >
                Overdue
            </p>
        </div>
    </div>
);
