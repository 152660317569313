import * as R from 'ramda';
// import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsFilePdf } from 'react-icons/bs';
import { IoSendOutline } from 'react-icons/io5';
import { useModalState } from '../../hooks/useModal';
import { usePrintProtocol } from '../../hooks/usePrintProtocol';
import { useMeetingAttendees } from '../../hooks/useMeetingAttendees';
import {
    MEETING_ATTACHMENT_EDGE,
    PRINT_MODAL_MOBILE,
    store,
    useGraph,
    procubionApi,
} from '../../common/hooks.config.new';
import { useNode } from '../../hooks/useNode';
import { defaultEmailBody } from '../../common/config';
import { formatShortDate } from '../../common/util';
import { useToast } from '../../hooks/useToast';
import { ModalMobile } from '../Common/ModalMobile';
import { LargeTextField, TextField } from '../../Components/Common/Fields';
import { LoaderMobile } from '../../Components/Elements/Loader';
import { Button } from '../../Components/Common/Controls';
import { ClickAnimator } from '../Elements/ClickAnimator';

export const PrintModalMobile = ({ stack }) => {
    const [isOpen, close] = useModalState(PRINT_MODAL_MOBILE);
    return (
        <ModalMobile name={PRINT_MODAL_MOBILE}>
            {isOpen ? <PrintModalContent stack={stack} isOpen={isOpen} close={close} /> : null}
        </ModalMobile>
    );
};

const PrintModalContent = ({ stack, isOpen, close }) => {
    const { useVar, update, push } = useGraph(...stack);
    const [fields, setFields] = useState({});
    const [meetingId] = useVar('meetingId');
    const { node: meeting } = useNode(stack, meetingId);
    const attendeeBags = useMeetingAttendees(stack, meetingId);
    const sender = R.compose(
        R.pick(['name', 'email']),
        R.propOr({}, 'node'),
        R.find(R.pathEq(['metadata', 'presence'], 'M')),
        R.values,
    )(attendeeBags);

    const setPrintToast = useToast('mobile-project-dashboard');
    const [protocolUrl, generateProtocol] = usePrintProtocol(stack);
    const [projectId] = useVar('projectId');
    const { node: project } = useNode(stack, projectId);
    useEffect(() => {
        setFields({
            subject: `[${project.title}] ${meeting.title} (${R.compose(
                formatShortDate,
                R.prop('date'),
            )(meeting)})`,
            body: defaultEmailBody(
                meeting.title,
                R.compose(formatShortDate, R.prop('date'))(meeting),
                sender.name,
            ),
        });
    }, [isOpen]);
    const sendProtocol = async () => {
        const attachmentsView = {
            [meetingId]: {
                edges: {
                    [MEETING_ATTACHMENT_EDGE]: {
                        as: 'attachments',
                        include: {
                            node: true,
                            edges: true,
                            files: true,
                        },
                    },
                },
            },
        };

        await store.pull(attachmentsView, R.head(stack));
        const {
            [meetingId]: { attachments: attachmentBags = {} },
        } = store.getView(...stack)(attachmentsView);

        const attachmentUrls = R.compose(
            R.filter(obj => obj.filename && obj.url),
            R.values,
            R.map(attachmentBag => ({
                filename: R.path(['node', 'filename'], attachmentBag),
                url: R.path(['files', 'file', 'url'], attachmentBag),
            })),
        )(attachmentBags);

        const recipients = R.compose(
            R.values,
            R.map(R.compose(R.pick(['name', 'email']), R.prop('node'))),
        )(attendeeBags);

        if (sender.name && recipients.length > 0 && protocolUrl) {
            setPrintToast('spinner', 'Sending minute...');
            try {
                procubionApi.sendProtocol({
                    sender,
                    recipients,
                    protocolUrl,
                    attachmentUrls,
                    ...fields,
                });
                setPrintToast('success', 'Minute successfully sent.', 5000);
                update(R.assoc('submitted', true, meeting));
                push();
                close();
            } catch (error) {
                setPrintToast('error', error.toString(), 10000);
            }
        } else {
            setPrintToast(
                'error',
                'Some parts of your mail are missing. Make sure you chose a minutes keeper, meeting attendees and the protocol was generated.',
                10000,
            );
        }
    };

    useEffect(() => {
        isOpen && generateProtocol();
    }, [isOpen]);

    return (
        <>
            <div>
                <div className="modal-headline-mobile">
                    Send Minute
                    <div
                        className="modal-headline-filler"
                        style={{ backgroundColor: 'var(--corporate-color-14-ligh)' }}
                    />
                </div>
                <TextField
                    label="Subject"
                    value={fields.subject}
                    onChange={value => setFields(R.assoc('subject', value))}
                    className="input-mobile"
                    labelClass="label-mobile"
                />
                <div className="mobile-textarea-height-60">
                    <LargeTextField
                        label="Body"
                        rows={18}
                        value={fields.body}
                        onChange={value => setFields(R.assoc('body', value))}
                        className="input-mobile"
                        labelClass="label-mobile"
                    />
                </div>
            </div>
            {protocolUrl ? (
                <>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            height: '15rem',
                        }}
                    >
                        <Button className="open-pdf-button" style={{ backgroundColor: 'white' }}>
                            <a
                                href={protocolUrl}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '9rem',
                                    width: '9rem',
                                }}
                            >
                                <BsFilePdf size="5rem" color="#ED2224" />
                            </a>
                        </Button>
                    </div>
                    <ClickAnimator
                        content={
                            <Button
                                onClick={() => {
                                    sendProtocol();
                                }}
                                className="save-modal-button"
                                style={{ backgroundColor: 'white' }}
                            >
                                <IoSendOutline size="5.25rem" />
                            </Button>
                        }
                        positioning={{ position: 'absolute', bottom: '0rem', right: '0rem' }}
                    />
                </>
            ) : (
                <LoaderMobile
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(256, 256, 256, 0.65)',
                    }}
                />
            )}
            <div style={{ height: '20vh' }} />
            {/* {protocolUrl && (
            <iframe
                style={{
                    width: '98vw', height: '60vh', marginLeft: '1vw', fontSize: '3.5rem',
                }}
                src={protocolUrl}
                title="application/pdf"
            />
            )} */}
        </>
    );
};
