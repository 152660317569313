import React from 'react';
import * as R from 'ramda';
import {
    IoAddOutline,
    IoHeartCircleSharp,
    IoPersonOutline,
    IoPodiumOutline,
    IoSearchOutline,
    IoShareSocialOutline,
} from 'react-icons/io5';
import { BsViewList } from 'react-icons/bs';
import {
    DASHBOARD_FILTER_KEY,
    DASHBOARD_STATE_KEY,
    PROJECT_ID_KEY,
    SEARCH_STRING_KEY,
    SUBPROJECT_ID_KEY,
    TOPS_TO_UPDATE_KEY,
    useGraph,
} from '../../../common/hooks.config.new';
import { Button } from '../../Common/Controls';
import logo from '../../../logos/pclogo.png';
import { useModal } from '../../../hooks/useModal';
import { useNode } from '../../../hooks/useNode';
import { useUserEmail } from '../../../hooks/useUserEmail';

export const DashboardStateSelector = ({ stack }) => {
    const { useVar } = useGraph(...stack);
    const [projectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [filter, setFilter] = useVar(DASHBOARD_FILTER_KEY);
    const [dashboardState, setDashboardState] = useVar(DASHBOARD_STATE_KEY);
    const [searchString, setSearchString] = useVar(SEARCH_STRING_KEY);
    const [topsToUpdate] = useVar(TOPS_TO_UPDATE_KEY);
    const openCreateDashboardTopModal = useModal('createDashboardTop');
    const openTopsToUpdateModal = useModal('topsToUpdate');

    const user = useUserEmail();
    const subprojectBag = useNode(stack, subprojectId, { rights: true });
    const canWriteSubproject = R.pathOr(false, ['rights', 'user', user, 'write'], subprojectBag);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
                margin: '18 0 12 0',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flex: 1.5,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                {subprojectId ? (
                    canWriteSubproject && (
                        <>
                            <Button
                                onClick={() => openCreateDashboardTopModal({})}
                                style={{ marginLeft: 48 }}
                            >
                                <div className="button-icon">
                                    <IoAddOutline size={26} />
                                    <p style={{ marginLeft: 3 }}>Create item</p>
                                </div>
                            </Button>
                            {!R.isEmpty(topsToUpdate) && (
                                <Button onClick={openTopsToUpdateModal} style={{ marginLeft: 12 }}>
                                    <div className="button-icon">
                                        <IoSearchOutline size={21} style={{ marginRight: 6 }} />
                                        Items to edit
                                    </div>
                                </Button>
                            )}
                        </>
                    )
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            color: 'var(--corporate-color-1-light)',
                            marginLeft: 32,
                        }}
                    >
                        {projectId && 'Select a subproject to create items'}
                    </div>
                )}
                <Button
                    onClick={() => {
                        setSearchString('');
                        searchString && !filter.showTops
                            ? null
                            : setFilter({ ...filter, showTops: !filter.showTops });
                    }}
                    style={{
                        marginLeft: 24,
                    }}
                >
                    {R.propOr(false, 'showTops', filter) || searchString ? (
                        <>
                            <IoPodiumOutline size={19} style={{ marginRight: 6 }} />
                            Show charts
                        </>
                    ) : (
                        <>
                            <BsViewList size={19} style={{ marginRight: 6 }} />
                            Show items
                        </>
                    )}
                </Button>
            </div>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                }}
            >
                <img alt="logo" src={logo} style={{ width: 175 }} />
            </div>
            <div style={{ display: 'flex', flex: 1.5, justifyContent: 'flex-end' }}>
                <Button
                    onClick={() => {
                        setDashboardState('user');
                        setSearchString('');
                    }}
                    style={{
                        backgroundColor: 'white',
                        border:
                            dashboardState === 'user'
                                ? '1px solid var(--corporate-color-13)'
                                : '1px solid transparent',
                        color: dashboardState === 'user' && 'var(--corporate-color-13)',
                        boxSizing: 'borderBox',
                        marginLeft: 18,
                        boxShadow:
                            dashboardState === 'user'
                                ? '0 5px 8px -2px var(--corporate-color-13-light)'
                                : '0 5px 8px -2px var(--corporate-color-1-light4)',
                    }}
                    className="font-weight-100 hover-scale"
                >
                    <IoPersonOutline size={20} style={{ marginRight: 6 }} />
                    User data
                </Button>
                <Button
                    onClick={() => {
                        setDashboardState('project');
                        setSearchString('');
                    }}
                    style={{
                        backgroundColor: 'white',
                        border:
                            !dashboardState || dashboardState === 'project'
                                ? '1px solid var(--corporate-color-13)'
                                : '1px solid transparent',
                        color:
                            (!dashboardState || dashboardState === 'project') &&
                            'var(--corporate-color-13)',
                        boxSizing: 'borderBox',
                        marginLeft: 18,
                        boxShadow:
                            !dashboardState || dashboardState === 'project'
                                ? '0 5px 8px -2px var(--corporate-color-13-light)'
                                : '0 5px 8px -2px var(--corporate-color-1-light4)',
                    }}
                    className="font-weight-100 hover-scale"
                >
                    <IoShareSocialOutline size={20} style={{ marginRight: 6 }} />
                    Project data
                </Button>
                <Button
                    onClick={() => {
                        setDashboardState('subproject');
                        setSearchString('');
                    }}
                    style={{
                        backgroundColor: 'white',
                        border:
                            dashboardState === 'subproject'
                                ? '1px solid var(--corporate-color-13)'
                                : '1px solid transparent',
                        color: dashboardState === 'subproject' && 'var(--corporate-color-13)',
                        boxSizing: 'borderBox',
                        marginLeft: 18,
                        marginRight: 48,
                        boxShadow:
                            dashboardState === 'subproject'
                                ? '0 5px 8px -2px var(--corporate-color-13-light)'
                                : '0 5px 8px -2px var(--corporate-color-1-light4)',
                    }}
                    className="font-weight-100 hover-scale"
                >
                    <IoHeartCircleSharp size={8} style={{ marginRight: 6 }} />
                    Subproject data
                </Button>
            </div>
        </div>
    );
};
