import React, { useEffect, useRef, useState } from 'react';
import {
    IoAddOutline,
    IoChevronForwardOutline,
    IoCloseOutline,
    IoCloudDownloadOutline,
    IoCreateOutline,
    IoEllipsisHorizontalOutline,
    IoInformationOutline,
    IoLockOpenOutline,
    IoMenuOutline,
    IoSearchOutline,
    IoShareSocialOutline,
    IoTrailSignOutline,
    IoTrashOutline,
} from 'react-icons/io5';
import * as R from 'ramda';
import { MAIN, store, TOPS_TO_UPDATE_KEY } from '../../../common/hooks.config.new';
import { Button } from '../../Common/Controls';
import { ListField } from '../../Common/Fields';
import { TYPES } from '../../Modals/ProtocolsZipModal';
import { Id, newId } from '../../../common/util';
import { Spacer } from '../Spacer';

export const ProjectsList = ({
    canAdminOrganization,
    canAdminProject,
    organizationId,
    user,
    setCDId,
    setFilter,
    setProjectId,
    setSubprojectId,
    setSearchString,
    openEditProjectModal,
    openProjectRightsModal,
    openProjectTypesModal,
    openProjectMilestonesModal,
    openProjectPhasesModal,
    openZipModal,
    projectId,
    projectChoices,
    // project,
    // save,
    // dissoc,
}) => {
    const projectRef = useRef();
    const [menu, setMenu] = useState('');
    const projectSearch = searchString => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]+[u00C0-u017F]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.filter(p => p.label && p.label.toUpperCase().search(string) !== -1)(
                projectChoices,
            );
        }
        return projectChoices;
    };
    const [projectSearchString, setProjectSearchString] = useState('');
    const [search, setSearch] = useState(false);
    useEffect(() => {
        search && setTimeout(() => projectRef.current.focus(), 600);
    }, [search]);
    return (
        <div style={{ marginRight: 15 }}>
            <Headline
                canAdminOrganization={canAdminOrganization}
                organizationId={organizationId}
                user={user}
                setProjectId={setProjectId}
                projectRef={projectRef}
                projectSearchString={projectSearchString}
                setProjectSearchString={setProjectSearchString}
                search={search}
                setSearch={setSearch}
                openEditProjectModal={openEditProjectModal}
                // dissoc={dissoc}
                // project={project}
                // save={save}
            />
            <ListField className style={{ flex: 1, flexBasis: 150 }} label="Projects">
                {R.map(projectChoice => (
                    <div
                        key={projectChoice.body.id}
                        className="row hover-effect"
                        style={{
                            cursor: 'pointer',
                            backgroundColor:
                                projectChoice.body.id === projectId && 'var(--corporate-color-5l)',
                            borderBottomColor: projectChoice.body.id === projectId && 'transparent',
                            paddingLeft: 9,
                            height: 35,
                            alignItems: 'flex-start',
                        }}
                        onClick={() => {
                            if (projectId !== projectChoice.body.id) {
                                setFilter({});
                                setProjectId(projectChoice.body.id);
                                store.setVar(MAIN)(TOPS_TO_UPDATE_KEY, {});
                                setSubprojectId(undefined);
                                setSearchString('');
                                setCDId(Id.contactDirectory(projectChoice.body.id));
                            }
                        }}
                    >
                        <ProjectInfo projectChoice={projectChoice} projectId={projectId} />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                flex: 1,
                            }}
                        >
                            <MenuButton
                                menu={menu}
                                setMenu={setMenu}
                                projectChoice={projectChoice}
                            />
                            <Menu
                                menu={menu}
                                setMenu={setMenu}
                                openEditProjectModal={openEditProjectModal}
                                openProjectTypesModal={openProjectTypesModal}
                                openProjectPhasesModal={openProjectPhasesModal}
                                openProjectMilestonesModal={openProjectMilestonesModal}
                                openProjectRightsModal={openProjectRightsModal}
                                openZipModal={openZipModal}
                                projectChoice={projectChoice}
                                user={user}
                                organizationId={organizationId}
                                canAdminProject={canAdminProject}
                            />
                        </div>
                    </div>
                ))(projectSearch(projectSearchString))}
                <Spacer
                    style={{ backgroundColor: 'transparent', height: 1000, margin: '10rem 0' }}
                />
            </ListField>
        </div>
    );
};

const Headline = ({
    canAdminOrganization,
    organizationId,
    user,
    setProjectId,
    projectRef,
    projectSearchString,
    setProjectSearchString,
    search,
    setSearch,
    openEditProjectModal,
    // dissoc,
    // project,
    // save,
}) => (
    <div className="modal-headline">
        <div>Projects</div>
        <div style={{ display: 'flex', flex: 3, justifyContent: 'flex-end' }}>
            {/* <Button
                onClick={() => {
                    dissoc(ORGANIZATION_PROJECT_EDGE)(organizationId)(project);
                    save();
                }}
            >
                Delete Project
            </Button> */}
            {canAdminOrganization && (
                <Button
                    onClick={() => {
                        const newProjectId = newId();
                        setProjectId(newProjectId);
                        openEditProjectModal({
                            isNew: true,
                            projectId: newProjectId,
                            user,
                            organizationId,
                        });
                    }}
                >
                    <IoAddOutline size={23} />
                    <p style={{ marginLeft: 5 }}>Create Project</p>
                </Button>
            )}
            <Button style={{ marginLeft: 6 }} onClick={() => setSearch(!search)}>
                {search ? <IoChevronForwardOutline size={19} /> : <IoSearchOutline size={21} />}
            </Button>
            {search && (
                <div className="animated-searchbar-half">
                    <input
                        ref={projectRef}
                        placeholder="search projects..."
                        value={projectSearchString}
                        onChange={e => setProjectSearchString(e.target.value)}
                    />
                    <Button style={{ marginLeft: 3 }} onClick={() => setProjectSearchString('')}>
                        <IoTrashOutline size={17} />
                    </Button>
                </div>
            )}
        </div>
    </div>
);

const ProjectInfo = ({ projectChoice, projectId }) => (
    <div
        style={{
            fontSize: 'inherit',
            display: 'flex',
            justifyContent: 'flex-start',
            flex: 1,
            alignItems: 'center',
        }}
    >
        <IoShareSocialOutline
            size={20}
            color="var(--corporate-color-2-light)"
            style={{ marginRight: 12 }}
        />
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                height: '35',
                fontWeight: projectChoice.body.id === projectId ? 'normal' : 100,
                color: 'var(--corporate-color-1)',
                fontSize: 'inherit',
            }}
        >
            {projectChoice.label}
        </div>
    </div>
);

const Menu = ({
    menu,
    setMenu,
    openEditProjectModal,
    openProjectTypesModal,
    openProjectPhasesModal,
    openProjectMilestonesModal,
    openProjectRightsModal,
    openZipModal,
    projectChoice,
    user,
    organizationId,
    canAdminProject,
}) => {
    if (projectChoice.body.id !== menu) return null;
    return (
        <div className="top-menu-container">
            <div
                className="top-menu"
                style={{
                    width: 175,
                }}
            >
                <Button
                    onClick={() => setMenu('')}
                    style={{
                        height: 30,
                        color: 'var(--corporate-color-12)',
                        paddingLeft: 9,
                        width: 175,
                        justifyContent: 'space-between',
                    }}
                >
                    <IoCloseOutline size={20} />
                    <p>Close menu</p>
                </Button>
                <Button
                    style={{
                        color: 'white',
                        width: 175,
                        justifyContent: 'space-between',
                    }}
                    onClick={() => {
                        setMenu('');
                        openEditProjectModal({
                            projectId: projectChoice.body.id,
                            user,
                            organizationId,
                        });
                    }}
                >
                    <IoCreateOutline size={19} />
                    <p>{canAdminProject ? 'Edit' : 'Show'} project</p>
                </Button>
                {canAdminProject && (
                    <>
                        <Button
                            style={{
                                color: 'white',
                                width: 175,
                                justifyContent: 'space-between',
                            }}
                            onClick={() => {
                                setMenu('');
                                openProjectTypesModal(projectChoice.body.id);
                            }}
                        >
                            <IoInformationOutline size={20} />
                            <p>Edit types</p>
                        </Button>
                        <Button
                            style={{
                                color: 'white',
                                width: 175,
                                justifyContent: 'space-between',
                            }}
                            onClick={() => {
                                setMenu('');
                                openProjectPhasesModal(projectChoice.body.id);
                            }}
                        >
                            <IoEllipsisHorizontalOutline size={20} />
                            <p>Edit phases</p>
                        </Button>
                        <Button
                            style={{
                                color: 'white',
                                width: 175,
                                justifyContent: 'space-between',
                            }}
                            onClick={() => {
                                setMenu('');
                                openProjectMilestonesModal();
                            }}
                        >
                            <IoTrailSignOutline size={20} />
                            <p>Edit milestones</p>
                        </Button>
                        <Button
                            style={{
                                color: 'white',
                                width: 175,
                                justifyContent: 'space-between',
                            }}
                            onClick={() => {
                                setMenu('');
                                openProjectRightsModal(projectChoice.body.id);
                            }}
                        >
                            <IoLockOpenOutline size={17} />
                            <p>Manage Rights</p>
                        </Button>
                    </>
                )}
                <Button
                    style={{
                        color: 'white',
                        width: 175,
                        justifyContent: 'space-between',
                    }}
                    onClick={() => {
                        setMenu('');
                        openZipModal({
                            type: TYPES.PROJECT,
                            nodeId: projectChoice.body.id,
                            filename: `[${R.pathOr(
                                '',
                                ['body', 'businessId'],
                                projectChoice,
                            )}]_Minutes.zip`,
                        });
                    }}
                >
                    <IoCloudDownloadOutline size={19} />
                    <p>Download Minutes</p>
                </Button>
            </div>
        </div>
    );
};

const MenuButton = ({ menu, setMenu, projectChoice }) => {
    if (projectChoice.body.id === menu) return null;
    return (
        <div style={{ height: 35, display: 'flex', alignItems: 'center' }}>
            <Button
                onClick={() => setMenu(projectChoice.body.id)}
                style={{
                    height: 30,
                    justifyContent: 'flex-start',
                    borderRadius: 'var(--space-normbig)',
                }}
            >
                <IoMenuOutline size={21} />
                <p>Menu</p>
            </Button>
        </div>
    );
};
