import React, { useState } from 'react';
import * as R from 'ramda';
import { IoChevronBackOutline } from 'react-icons/io5';
import { ListRow } from '../../Components/Common/ListRow';
import { MeetingsTimelineMobile } from './MeetingsTimelineMobile';
import { TopButtonRowMobile } from './TopButtonRowMobile';
import { TopMobile } from './TopMobile';
import { Button } from '../../Components/Common/Controls';
import { DASHBOARD_FILTER_KEY, SEARCH_STRING_KEY, useGraph } from '../../common/hooks.config.new';

export const TopsAndMeetingsAreaMobile = ({
    topsToDisplay,
    stack,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
    oppTracking,
    filter,
    sortedMeetings,
}) => {
    const { useVar } = useGraph(...stack);
    const [, setSearchString] = useVar(SEARCH_STRING_KEY);
    const [, setFilter] = useVar(DASHBOARD_FILTER_KEY);
    const [showItems, setShowItems] = useState(true);
    return (
        <div>
            <div className="dashboard-top-buttons-container-mobile">
                <Button
                    onClick={() => {
                        setSearchString('');
                        setFilter({ ...filter, showTops: false });
                    }}
                    style={{
                        backgroundColor: 'white',
                    }}
                >
                    <IoChevronBackOutline size="5rem" color="var(--corporate-color-2)" />
                </Button>
                <Button
                    onClick={() => setShowItems(!showItems)}
                    className="items-meetings-toggle-button"
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid var(--corporate-color-14-light)',
                    }}
                >
                    {showItems ? 'Meetings' : 'Items'}
                </Button>
            </div>
            <div className="dashboard-area">
                {showItems ? (
                    <div style={{ flex: 1 }}>
                        {R.length(topsToDisplay) > 0 ? (
                            R.map(
                                ({ top, responsible, previousVersions, meetingId, protocolId }) => (
                                    <ListRow
                                        key={top.id}
                                        style={{
                                            margin: '2.5rem var(--space-small) 3.5rem var(--space-small)',
                                        }}
                                    >
                                        <div className="top-search-row">
                                            <TopButtonRowMobile
                                                top={top}
                                                stack={stack}
                                                responsible={responsible}
                                                previousVersions={previousVersions}
                                                canAdminProject={canAdminProject}
                                                canWriteProject={canWriteProject}
                                                subprojectId={subprojectId}
                                                editMessage={editMessage}
                                                userName={userName}
                                                oppTracking={oppTracking}
                                            />
                                            <TopMobile
                                                noButton
                                                stack={stack}
                                                meetingId={meetingId}
                                                topId={R.prop('id', top)}
                                                protocolId={protocolId}
                                            />
                                        </div>
                                    </ListRow>
                                ),
                            )(topsToDisplay)
                        ) : (
                            <div className="dashboard-message-mobile">No agenda items found</div>
                        )}
                    </div>
                ) : (
                    <div style={{ flex: 1 }}>
                        <MeetingsTimelineMobile
                            stack={stack}
                            filter={filter}
                            meetings={sortedMeetings}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
