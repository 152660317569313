import React from 'react';
import { ActionStatusesDonut } from './components/ActionStatusesDonut';
import { ResponsibleStatusChart } from './components/ResponsibleStatusChart';
import { ActionHistoryChart } from './components/ActionHistoryChart';
import { Legend } from './components/Legend';
import { ACTION_CHARTS_MODAL, ActionChartsModal } from './modals/ActionChartsModal';
import { useModal } from '../../../hooks/useModal';
import { RESPONSIBLE_STATUS_MODAL, ResponsibleStatusModal } from './modals/ResponsibleStatusModal';
import { ChartHeadline } from './components/ChartHeadline';

export const ProjectActionCharts = ({ stack, actions }) => {
    const openActionChartsModal = useModal(ACTION_CHARTS_MODAL);
    const openResponsibleStatusModal = useModal(RESPONSIBLE_STATUS_MODAL);

    return (
        <div
            className="fader"
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '2rem',
                    minWidth: '0',
                    marginBottom: '2rem',
                }}
            >
                <ChartHeadline onClick={openResponsibleStatusModal}>
                    Actions by responsible
                </ChartHeadline>
                <ChartHeadline>Actions by status</ChartHeadline>
                <ChartHeadline onClick={openActionChartsModal}>Actions by histories</ChartHeadline>
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '2rem',
                    minWidth: '0',
                }}
            >
                <ResponsibleStatusChart stack={stack} actions={actions} maxElements={10} reverse />
                <ActionStatusesDonut stack={stack} actions={actions} />
                <ActionHistoryChart stack={stack} actions={actions} maxElements={10} />
            </div>
            <Legend stack={stack} />
            <ActionChartsModal stack={stack} actions={actions} />
            <ResponsibleStatusModal stack={stack} actions={actions} />
        </div>
    );
};
