import React, { useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import { IoRemoveOutline, IoTrailSignOutline } from 'react-icons/io5';
import {
    MILESTONE_ID_KEY,
    MILESTONE_TOPS_MODAL,
    MILESTONE_TOP_EDGE,
    OPP_TRACKING,
    PROJECT_ID_KEY,
    SORTED_GROUPED_TOPS_KEY,
    SUBPROJECT_ID_KEY,
    useGraph,
} from '../../common/hooks.config.new';
import { useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { OppActions } from '../Elements/OppActions';
import { Toast } from '../Elements/Toast';
import { constructNodeView, createOfflinePush } from '../../common/got-adjunct';
import { useToast } from '../../hooks/useToast';
import '../Screens/DashboardScreen.css';
import { useUserEmail } from '../../hooks/useUserEmail';
import { useNodeFromVar } from '../../hooks/useNode';
import { sortWithTopLinks } from '../../hooks/useTopLinks';
import { ItemsCountBar } from '../Elements/ProjectDashboard/ItemsCountBar';
import { formatShortDate } from '../../common/util';

export const MilestoneTopsModal = ({ stack }) => {
    const [isOpen, close] = useModalState(MILESTONE_TOPS_MODAL);
    return (
        <Modal
            name={MILESTONE_TOPS_MODAL}
            style={{
                height: '87vh',
                top: '6.5vh',
                width: '85vw',
                left: '7.5vw',
            }}
            topbar={<IoTrailSignOutline size={24} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <ModalContent stack={stack} close={close} /> : null}
        </Modal>
    );
};

const constructMilestoneTopsView = milestoneId =>
    milestoneId
        ? {
              [milestoneId]: {
                  as: 'milestone',
                  include: {
                      node: true,
                  },
                  edges: {
                      [MILESTONE_TOP_EDGE]: {
                          as: 'tops',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

const selectMilestoneTops = R.compose(
    R.map(R.prop('node')),
    R.values,
    R.pathOr({}, ['milestone', 'tops']),
);

const ModalContent = ({ stack: parentStack }) => {
    const stack = R.append(MILESTONE_TOPS_MODAL, parentStack);
    const { useVar, useView, pull, dissoc } = useGraph(...stack);

    const [oppTracking] = useVar(OPP_TRACKING);
    const [milestoneId] = useVar(MILESTONE_ID_KEY);
    const { node: milestone } = useNodeFromVar(stack, MILESTONE_ID_KEY);

    const [showTopType, setShowTopType] = useState('a');

    const milestoneTopsView = constructMilestoneTopsView(milestoneId);
    useEffect(() => {
        pull(milestoneTopsView);
    }, []);
    const tops = useView(milestoneTopsView, selectMilestoneTops);
    const filteredTops = useMemo(
        () => R.filter(top => (showTopType ? top.type === showTopType : true))(tops),
        [showTopType, tops],
    );

    const [{ tops: sortedGroupedTops = {} }] = useVar(SORTED_GROUPED_TOPS_KEY);

    const topInfos = useMemo(
        () =>
            R.compose(
                R.filter(R.identity),
                R.map(topInfo => ({
                    nodeId: topInfo.top.nodeId || '',
                    id: topInfo.top?.id,
                    top: topInfo,
                })),
                topBusinessIds => {
                    const pickedGroupedTops = R.pick(topBusinessIds, sortedGroupedTops);
                    return sortWithTopLinks(topBusinessIds, pickedGroupedTops);
                },
                R.map(top => top.businessId),
            )(filteredTops),
        [filteredTops, sortedGroupedTops],
    );

    const [projectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);
    const projectView = constructNodeView(projectId, { node: true, rights: true, files: true });
    useEffect(() => {
        projectId && pull(projectView);
    }, [projectId]);
    const { [projectId]: projectBag = {} } = useView(projectView);
    const user = useUserEmail();
    const canAdminProject = R.pathOr(false, ['rights', 'user', user, 'admin'], projectBag);
    const canWriteProject = R.pathOr(false, ['rights', 'user', user, 'write'], projectBag);

    const setToast = useToast('milestone-tops');
    const toastConfig = {
        textOnStart: 'Removing item from milestone...',
        textOnSuccess: 'Successfully removed.',
        textOnError: 'You are offline. Please sync when back online.',
    };
    const push = createOfflinePush(stack, setToast, toastConfig);

    const dissocTop = oppId => action => {
        console.log(oppId);
        dissoc(MILESTONE_TOP_EDGE)(milestoneId)(action);
        push();
    };

    const itemsCount = topType =>
        R.compose(
            R.length,
            R.filter(top => top.type === topType),
        )(R.values(tops));

    const setEditMessageToast = useToast('edit-message');
    // TODO change subproject in edit modal to tops sub
    const editMessage = () => {
        setEditMessageToast('error', 'Select a subproject to edit this item...', 5000);
    };

    return (
        <>
            <div className="columns" style={{ alignItems: 'flex-start' }}>
                <div style={{ flex: 2.5, marginRight: 15 }}>
                    <Headline milestone={milestone} />
                    <ItemsCountBar
                        showTopType={showTopType}
                        setShowTopType={setShowTopType}
                        itemsCount={itemsCount}
                    />
                    {topInfos.length > 0 && (
                        <OppActions
                            stack={stack}
                            actionBags={topInfos}
                            dissocAction={dissocTop}
                            canWriteProject={canWriteProject}
                            canAdminProject={canAdminProject}
                            editMessage={editMessage}
                            subprojectId={subprojectId}
                            oppTracking={oppTracking}
                            showTopType={showTopType}
                            milestone
                        />
                    )}
                </div>
            </div>
            <Toast name="milestone-tops" />
        </>
    );
};

const Headline = ({ milestone }) => (
    <div className="modal-headline" style={{ marginBottom: 24, alignItems: 'flex-start' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <p style={{ fontSize: 26, fontWeight: 'normal', whiteSpace: 'nowrap' }}>Milestone</p>
            <IoRemoveOutline size={22} style={{ margin: '5 6 0 6' }} />
            <p style={{ fontSize: 26 }}>{R.propOr('', 'name', milestone)}</p>
        </div>
        <div style={{ justifyContent: 'flex-end' }}>
            <p style={{ fontSize: 26, fontWeight: 'normal', marginRight: 12 }}>Due Date:</p>
            {formatShortDate(milestone.dueDate)}
        </div>
    </div>
);
