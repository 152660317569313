import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { BsArrowRight } from 'react-icons/bs';
import { useHistory } from 'react-router';
import { IoOpenOutline } from 'react-icons/io5';
import {
    CD_ID_KEY,
    DASHBOARD_FILTER_KEY,
    MAIN,
    OPP_TRACKING,
    ORGANIZATION_ID_KEY,
    ORGANIZATION_OPPORTUNITY_TRACKING_EDGE,
    ORGANIZATION_PROJECT_EDGE,
    PROJECT_ID_KEY,
    PROJECT_SUBPROJECT_EDGE,
    ROOT_ORGANIZATION_EDGE,
    SEARCH_STRING_KEY,
    store,
    SUBPROJECT_ID_KEY,
    TOPS_TO_UPDATE_KEY,
    useGraph,
} from '../../common/hooks.config.new';
import { APPLICATION_ROOT_NODE } from '../../common/config';
import { DropdownField } from '../Common/Dropdown';

import './ProjectSelector.css';
import { useModal, useModalState } from '../../hooks/useModal';
import { Button } from '../Common/Controls';
import { useToast } from '../../hooks/useToast';
import { useUserEmail } from '../../hooks/useUserEmail';
import { Id } from '../../common/util';

const customStyles = {
    singleValue: base => ({
        ...base,
        'color': 'var(--corporate-color-6)',
        '&:hover': {
            cursor: 'pointer',
        },
    }),
    dropdownIndicator: base => ({
        ...base,
        '&:hover': {
            cursor: 'pointer',
        },
    }),
    menu: base => ({
        ...base,
        borderRadius: 'var(--space-small)',
        boxShadow: '0px 5px 8px -2px rgba(0, 0, 0, 0.3)',
        overflow: 'hidden',
        animation: 'dropdown 0.3s ease',
    }),
    option: (base, state) => ({
        ...base,
        '&:hover': {
            backgroundColor: 'var(--corporate-color-2)',
            color: 'white',
            cursor: 'pointer',
        },
        'backgroundColor': state.isSelected ? 'var(--corporate-color-5l)' : 'white',
        'color': 'black',
    }),
    control: base => ({
        ...base,
        'background': 'transparent',
        'borderColor': 'transparent',
        'borderRadius': 'var(--space-small)',
        '&:hover': {
            cursor: 'pointer',
            borderColor: 'var(--corporate-color-5l)',
        },
        'transition': '0.2s ease',
    }),
};

const constructOrgsView = rootNodeId =>
    rootNodeId
        ? {
              [rootNodeId]: {
                  as: 'root',
                  include: {
                      node: true,
                  },
                  edges: {
                      [ROOT_ORGANIZATION_EDGE]: {
                          as: 'organizations',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

const constructOppTrackingView = organizationId =>
    organizationId
        ? {
              [organizationId]: {
                  edges: {
                      [ORGANIZATION_OPPORTUNITY_TRACKING_EDGE]: {
                          as: 'oppTracking',
                          include: {
                              edges: true,
                              node: true,
                              rights: true,
                          },
                      },
                  },
              },
          }
        : {};

const constructProjectsView = organizationId =>
    organizationId
        ? {
              [organizationId]: {
                  as: 'organization',
                  edges: {
                      [ORGANIZATION_PROJECT_EDGE]: {
                          as: 'projects',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

const constructSubprojectsView = projectId =>
    projectId
        ? {
              [projectId]: {
                  as: 'project',
                  edges: {
                      [PROJECT_SUBPROJECT_EDGE]: {
                          as: 'subprojects',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const ProjectSelector = ({ stack, global }) => {
    const { useView, useVar, pull } = useGraph(...stack);
    const { useVar: useGlobalVar } = useGraph(R.head(stack));

    const [preSetId, setPreSetId] = useState('');
    const setLoading = useModal('loader');
    const [projectId, setProjectId] = useVar(PROJECT_ID_KEY);
    const [, stopLoading] = useModalState('loader');

    const orgsView = constructOrgsView(APPLICATION_ROOT_NODE);
    useEffect(() => {
        const loadOrgsView = async () => {
            setLoading();
            APPLICATION_ROOT_NODE && (await pull(orgsView));
            !projectId && stopLoading();
        };
        loadOrgsView();
    }, [APPLICATION_ROOT_NODE]);
    const { root: { organizations = {} } = {} } = useView(orgsView);
    const organizationChoices = R.compose(
        R.sortBy(R.prop('label')),
        R.map(organizationBag => ({
            value: R.path(['node', 'id'], organizationBag),
            label: R.path(['node', 'name'], organizationBag),
            body: R.path(['node'], organizationBag),
        })),
    )(R.values(organizations));

    // get projects
    const [organizationId, setOrganizationId] = useVar(ORGANIZATION_ID_KEY);
    const projectsView = constructProjectsView(organizationId);
    useEffect(() => {
        organizationId && pull(projectsView);
    }, [organizationId]);
    useEffect(() => {
        global && setPreSetId(projectId);
    }, [global]);
    const { organization: { projects = {} } = {} } = useView(projectsView);
    const projectChoices = R.compose(
        R.sortBy(R.prop('label')),
        R.map(projectBag => ({
            value: R.path(['node', 'id'], projectBag),
            label: R.path(['node', 'title'], projectBag),
            body: R.path(['node'], projectBag),
        })),
    )(R.values(projects));

    // get subprojects
    const [, setFilter] = useVar(DASHBOARD_FILTER_KEY);
    const [, setSearchString] = useVar(SEARCH_STRING_KEY);
    const [, setCDId] = useVar(CD_ID_KEY);
    const subprojectsView = constructSubprojectsView(projectId);
    useEffect(() => {
        projectId && pull(subprojectsView);
    }, [projectId]);
    const { project: { subprojects = {} } = {} } = useView(subprojectsView);
    const subprojectChoices = R.compose(
        R.sortBy(R.prop('label')),
        R.map(subprojectBag => ({
            value: R.path(['node', 'id'], subprojectBag),
            label: R.path(['node', 'title'], subprojectBag),
            body: R.path(['node'], subprojectBag),
        })),
    )(R.values(subprojects));

    // get and set opp tracking right
    const user = useUserEmail();
    const oppTrackingView = constructOppTrackingView(organizationId);
    useEffect(() => {
        organizationId && pull(oppTrackingView);
    }, [organizationId]);
    const oppTracking = R.compose(
        R.unnest,
        R.map(o => R.values(R.prop('oppTracking', o))),
        R.map(o => R.pick(['oppTracking'], o)),
        R.values,
    )(useView(oppTrackingView));

    const oppTrackingRights = R.propOr('', 'rights', oppTracking[0]);
    // TODO add oppTracking nodes to existing companie
    const canTrackOpportunities = R.pathOr(false, ['user', user, 'read'], oppTrackingRights);
    const [, setOppTracking] = useGlobalVar(OPP_TRACKING);

    useEffect(() => {
        setOppTracking(canTrackOpportunities);
    }, [organizationId, canTrackOpportunities]);

    const [subprojectId, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);
    const history = useHistory();

    const setToast = useToast('setOrg');
    const selectedOrganization = name =>
        setToast('success', `${name} selected automatically.`, 5000);

    useEffect(() => {
        const standardOrganization = R.find(R.pathEq(['node', 'default'], true))(
            R.values(organizations),
        );
        if (!organizationId && standardOrganization) {
            setOrganizationId(standardOrganization.nodeId);
            selectedOrganization(standardOrganization.node.name);
        }
    }, [organizations]);

    return (
        <div className="project-selector">
            <DropdownField
                className="project-selcetor-dropdown-field"
                styled
                label="Organization"
                options={organizationChoices}
                value={organizationId || ''}
                onChange={option => {
                    setOrganizationId(option.value);
                    setProjectId(undefined);
                    setSubprojectId(undefined);
                    setFilter({});
                    setSearchString('');
                }}
                customStyles={customStyles}
            />
            <p
                style={{
                    color: 'var(--corporate-color-6)',
                    marginRight: '32px',
                    marginLeft: '32px',
                }}
            >
                <BsArrowRight size={25} />
            </p>
            <DropdownField
                className="project-selcetor-dropdown-field"
                styled
                label="Project"
                options={projectChoices}
                value={global ? preSetId : projectId}
                onChange={option => {
                    if (option.value !== projectId) {
                        if (global) {
                            setPreSetId(option.value);
                        }
                        !global && setFilter({});
                        !global && setProjectId(option.value);
                        !global && setCDId(Id.contactDirectory(option.value));
                        !global && store.setVar(MAIN)(TOPS_TO_UPDATE_KEY, {});
                        !global && setSearchString('');
                        setSubprojectId(undefined);
                        !global && setLoading();
                    }
                }}
                customStyles={customStyles}
            />
            <p
                style={{
                    color: 'var(--corporate-color-6)',
                    marginRight: '32px',
                    marginLeft: '32px',
                }}
            >
                <BsArrowRight size={25} />
            </p>
            {!global ? (
                <DropdownField
                    className="project-selcetor-dropdown-field"
                    styled
                    label="Subproject"
                    options={subprojectChoices}
                    value={subprojectId || ''}
                    onChange={option => {
                        setSubprojectId(option.value);
                    }}
                    customStyles={customStyles}
                />
            ) : (
                <Button
                    className="dashboard-toggle"
                    onClick={() => {
                        preSetId && setLoading();
                        setProjectId(preSetId);
                        setTimeout(() => history.push('dashboard'), 1);
                    }}
                >
                    <IoOpenOutline size={22} />
                    <p style={{ marginLeft: 9 }}>Project Dashboard</p>
                </Button>
            )}
        </div>
    );
};
