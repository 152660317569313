import * as R from 'ramda';
import { useEffect, useMemo } from 'react';
import {
    CONTACT_DIRECTORY_CONTACT_EDGE,
    MAIN,
    PROJECT_ID_KEY,
    useGraph,
} from '../common/hooks.config.new';
import { Id } from '../common/util';
import { useNode, useNodeFromVar } from './useNode';

export const PROJECT_CURRENCY_CHOICES = 'projectCurrency';
export const PROJECT_CONTACT_CHOICES = 'contactChoices';
export const PROJECT_OPP_STATUS_TYPE_CHOICES = 'oppStatusTypeChoices';
export const PROJECT_OPP_STATUS_TYPE_MILESTONES_CHOICES = 'oppStatusTypeMilestonesChoices';
export const PROJECT_OPP_CATEGORY_TYPE_CHOICES = 'oppCategoryTypeChoices';
export const PROJECT_ACTION_STATUS_TYPE_CHOICES = 'actionStatusTypeChoices';
export const PROJECT_PHASE_CHOICES = 'projectPhaseChoices';

export const useMeetingTypesChoices = (stack = [MAIN]) => {
    const { node: project } = useNodeFromVar(stack, PROJECT_ID_KEY);
    return useMemo(
        () =>
            R.compose(
                R.map(mt => ({
                    value: mt,
                    label: mt,
                })),
                R.propOr([], 'meetingTypes'),
            )(project),
        [project],
    );
};

export const useInitProjectChoices = stack => {
    const { useVar, useView, pull } = useGraph(...stack);
    const [projectId] = useVar(PROJECT_ID_KEY);
    const [, setProjectCurrency] = useVar(PROJECT_CURRENCY_CHOICES);
    const [, setContactChoices] = useVar(PROJECT_CONTACT_CHOICES);
    const [, setOppStatusTypeChoices] = useVar(PROJECT_OPP_STATUS_TYPE_CHOICES);
    const [, setOppStatusTypeMilestonesChoices] = useVar(
        PROJECT_OPP_STATUS_TYPE_MILESTONES_CHOICES,
    );
    const [, setOppCategoryTypeChoices] = useVar(PROJECT_OPP_CATEGORY_TYPE_CHOICES);
    const [, setActionStatusTypeChoices] = useVar(PROJECT_ACTION_STATUS_TYPE_CHOICES);
    const [, setProjectPhaseChoices] = useVar(PROJECT_PHASE_CHOICES);

    /* #region coontacts */
    const contactDirectoryId = Id.contactDirectory(projectId);
    const contactsView = {
        [contactDirectoryId]: {
            as: 'contactDirectory',
            edges: {
                [CONTACT_DIRECTORY_CONTACT_EDGE]: {
                    as: 'contacts',
                    include: {
                        node: true,
                        edges: true,
                    },
                },
            },
        },
    };
    useEffect(() => {
        contactDirectoryId && pull(contactsView);
    }, [contactDirectoryId]);

    const {
        contactDirectory: { contacts: contactBags },
    } = useView(contactsView);

    const contacts = useMemo(
        () =>
            R.values(contactBags).map(contact => ({
                value: contact.node?.id,
                label: contact.node?.name,
                node: contact.node,
            })),
        [contactBags],
    );

    useEffect(() => {
        setContactChoices({ choices: contacts });
    }, [contacts]);
    /* #endregion */

    const {
        node: {
            oppStatusTypes = [],
            oppStatusTypesFurther = [],
            oppCategoryTypes = [],
            actionStatusTypes = [],
            phases = {},
            currency,
        } = {},
    } = useNode(stack, projectId);

    const allOppStatusTypes = R.concat(oppStatusTypes, oppStatusTypesFurther);

    const projectPhaseChoices = useMemo(
        () =>
            R.compose(
                R.map(pp => ({
                    value: pp.name,
                    label: pp.name,
                })),
                R.values,
            )(phases),
        [phases],
    );

    const oppStatusTypeChoices = useMemo(
        () =>
            R.map(ost => ({
                value: ost,
                label: ost,
            }))(allOppStatusTypes),
        [allOppStatusTypes],
    );
    const oppStatusTypeMilestonesChoices = useMemo(
        () =>
            R.map(ost => ({
                value: ost,
                label: ost,
            }))(oppStatusTypes),
        [oppStatusTypes],
    );
    const oppCategoryTypeChoices = useMemo(
        () =>
            R.map(oct => ({
                value: oct,
                label: oct,
            }))(oppCategoryTypes),
        [oppCategoryTypes],
    );
    const actionStatusTypeChoices = useMemo(
        () =>
            R.map(ast => ({
                value: ast,
                label: ast,
            }))(actionStatusTypes),
        [actionStatusTypes],
    );

    useEffect(() => {
        setProjectPhaseChoices({ choices: projectPhaseChoices });
    }, [projectPhaseChoices]);

    useEffect(() => {
        setOppStatusTypeChoices({ choices: oppStatusTypeChoices });
    }, [oppStatusTypeChoices]);

    useEffect(() => {
        setOppStatusTypeMilestonesChoices({ choices: oppStatusTypeMilestonesChoices });
    }, [oppStatusTypeMilestonesChoices]);

    useEffect(() => {
        setOppCategoryTypeChoices({ choices: oppCategoryTypeChoices });
    }, [oppCategoryTypeChoices]);

    useEffect(() => {
        setActionStatusTypeChoices({ choices: actionStatusTypeChoices });
    }, [actionStatusTypeChoices]);

    useEffect(() => {
        setProjectCurrency(currency);
    }, [currency]);
};

export const useProjectChoices = (stack, choiceType) => {
    const { useVar } = useGraph(...stack);
    const [choices] = useVar(choiceType);

    return choices || { choices: [] };
};

export const useProjectCurrency = stack => {
    const { useVar } = useGraph(...stack);
    const [currency] = useVar(PROJECT_CURRENCY_CHOICES);

    return currency || 'EUR';
};
