import React from 'react';
import * as R from 'ramda';

export const GlobalItemsCountMobile = ({
    setDashboardState,
    setDashboardTops,
    projectsCount,
    allActions,
    allOpps,
    userCanTrackOpps,
}) => (
    <div
        className="content-container-mobile"
        style={{ padding: '0', marginTop: '12rem', position: 'relative' }}
    >
        <div className="headline-mobile" style={{ marginBottom: '6rem' }}>
            My Responsibilities
        </div>
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
            }}
            className="scale0-8"
        >
            <div style={{ height: 'fit-content' }}>
                <div
                    className="global-count-container-mobile fade-scale-count-left"
                    style={{ flex: 1, marginRight: '2rem' }}
                    onClick={() => {
                        setDashboardState('projects');
                    }}
                >
                    <p className="global-count-headline-mobile">Projects</p>
                    <p className="global-count-number-mobile">{projectsCount}</p>
                </div>
            </div>
            <div className="global-count-middle-column">
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <FancyArrowsLeft />
                </div>
                <div className="global-count-opps-container">
                    {userCanTrackOpps && (
                        <div
                            className="global-count-container-mobile fade-mobile"
                            style={{ alignItems: 'center' }}
                            onClick={() => {
                                setDashboardTops(allOpps);
                                setDashboardState('tops');
                            }}
                        >
                            <p className="global-count-headline-mobile">Opportunities</p>
                            <p className="global-count-number-mobile">{R.length(allOpps)}</p>
                        </div>
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <FancyArrowsRight />
                </div>
            </div>
            <div className="global-count-right-column">
                <div
                    className="global-count-container-mobile global-count-actions-container fade-scale-count-right"
                    style={{ alignItems: 'flex-start' }}
                    onClick={() => {
                        setDashboardTops(allActions);
                        setDashboardState('tops');
                    }}
                >
                    <p className="global-count-headline-mobile">Actions</p>
                    <p className="global-count-number-mobile">{R.length(allActions)}</p>
                </div>
            </div>
        </div>
    </div>
);

const FancyArrowsLeft = () => (
    <div className="fancy-outer-arrow-left">
        <div className="fancy-inner-arrow-left" />
        <div className="outer-arrow-head-left" />
        <div className="inner-arrow-head-left" />
    </div>
);

const FancyArrowsRight = () => (
    <div className="fancy-outer-arrow-right">
        <div className="fancy-inner-arrow-right" />
        <div className="outer-arrow-head-right" />
        <div className="inner-arrow-head-right" />
    </div>
);
