import * as R from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import {
    IoAddOutline,
    IoAppsOutline,
    IoChevronForwardOutline,
    IoCreateOutline,
    IoHomeOutline,
    IoLockOpenOutline,
    IoSearchOutline,
    IoTrashOutline,
} from 'react-icons/io5';
import { Modal } from '../Common/Modal';
import { ListField } from '../Common/Fields';
import { Button, FancyCheckbox } from '../Common/Controls';
import { useModal, useModalState } from '../../hooks/useModal';
import {
    DASHBOARD_FILTER_KEY,
    ORGANIZATIONS_MODAL,
    ORGANIZATION_ID_KEY,
    ORGANIZATION_OPPORTUNITY_TRACKING_EDGE,
    PROJECT_ID_KEY,
    ROOT_ORGANIZATION_EDGE,
    SEARCH_STRING_KEY,
    SUBPROJECT_ID_KEY,
    useGraph,
} from '../../common/hooks.config.new';
import { APPLICATION_ROOT_NODE } from '../../common/config';
import { useUserEmail } from '../../hooks/useUserEmail';
import { constructNodeView, createOfflinePush } from '../../common/got-adjunct';
import { OrganizationRightsModal } from './OrganizationRightsModal';
import { Toast } from '../Elements/Toast';
import { useToast } from '../../hooks/useToast';
import { EditOrgModal } from './EditOrgModal';
import { UserModulesModal } from './UserModulesModal';
import { newId, newMsDate } from '../../common/util';

const constructOrgsView = rootNodeId =>
    rootNodeId
        ? {
              [rootNodeId]: {
                  as: 'root',
                  include: {
                      node: true,
                  },
                  edges: {
                      [ROOT_ORGANIZATION_EDGE]: {
                          as: 'organizations',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const OrganizationsModal = ({ stack, protocol, meetingId }) => {
    const [isOpen] = useModalState('organizations');
    return (
        <Modal
            name="organizations"
            style={{
                height: '87vh',
                top: '6.5vh',
                width: '80vw',
                left: '10vw',
            }}
            topbar={<IoHomeOutline size={24} color="var(--corporate-color-7)" />}
        >
            {isOpen ? (
                <ModalContent stack={stack} protocol={protocol} meetingId={meetingId} />
            ) : null}
        </Modal>
    );
};

const ModalContent = ({ stack: parentStack }) => {
    const user = useUserEmail();

    // organazation selection
    const { useVar } = useGraph(...parentStack);
    const [organizationId, setOrganizationId] = useVar(ORGANIZATION_ID_KEY);

    // our graph stack
    const stack = R.append(ORGANIZATIONS_MODAL, parentStack);
    const { useView, pull, add, update, setFile, setRights } = useGraph(...stack);

    // user rights on app
    const appView = constructNodeView(APPLICATION_ROOT_NODE, { rights: true });
    useEffect(() => {
        APPLICATION_ROOT_NODE && pull(appView);
    }, [APPLICATION_ROOT_NODE]);
    const { [APPLICATION_ROOT_NODE]: appBag = {} } = useView(appView);
    const canWriteApp = R.pathOr(false, ['rights', 'user', user, 'write'], appBag);

    // organization choices
    const orgsView = constructOrgsView(APPLICATION_ROOT_NODE);
    const { root: { organizations = {} } = {} } = useView(orgsView);

    // selected organization
    const organizationView = constructNodeView(organizationId, {
        node: true,
        rights: true,
        files: true,
    });
    useEffect(() => {
        organizationId && pull(organizationView);
    }, [organizationId]);
    const { [organizationId]: organizationBag = {} } = useView(organizationView);
    const isOrganizationAdmin = R.pathOr(false, ['rights', 'user', user, 'admin'], organizationBag);
    const organizationLogoUrl = R.path(['files', 'organizationLogo', 'url'], organizationBag);
    const organization = R.propOr({}, 'node', organizationBag);

    const [, setProjectId] = useVar(PROJECT_ID_KEY);
    const [, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [, setSearchString] = useVar(SEARCH_STRING_KEY);
    const [, setFilter] = useVar(DASHBOARD_FILTER_KEY);

    const save = async () => {
        setOrganizationToast('spinner', 'Saving organization data...');
        const toastConfig = {
            textOnStart: 'Saving organization data...',
        };
        const push = createOfflinePush(stack, setOrganizationToast, toastConfig);
        return push() // TODO maybe include uploads in offlinePush
            .then(({ uploads }) => {
                uploads.subscribe({
                    complete: () => {
                        setOrganizationToast('success', 'Successfully saved.', 5000);
                        pull(organizationView);
                    },
                });
                uploads.start();
            })
            .catch(error => {
                if (error.status) {
                    setOrganizationToast('error', error.message ? error.message : error.toString());
                } else {
                    setOrganizationToast(
                        'error',
                        'You are offline. Please sync when back online.',
                        5000,
                    );
                }
            });
    };

    const openRightsModal = useModal('organization-rights');
    const openUserModulesModal = useModal('user-modules');
    const openEditOrgModal = useModal('new-org');
    const setOrganizationToast = useToast('organizations');

    const [orgSearchString, setOrgSearchString] = useState('');
    const [search, setSearch] = useState(false);

    const orgsSearch = searchString => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]+[u00C0-u017F]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.compose(
                R.sortBy(R.path(['node', 'name'])),
                R.filter(c => c.node.name && c.node.name.toUpperCase().search(string) !== -1),
            )(R.values(organizations));
        }
        return R.values(organizations);
    };

    // const orgCleaner = R.forEach(
    //     org => !org.node.name && dissoc(ROOT_ORGANIZATION_EDGE)(APPLICATION_ROOT_NODE)(org.node),
    // )(R.values(organizations));

    return (
        <>
            <div className="columns">
                <div>
                    <Headline
                        canWriteApp={canWriteApp}
                        add={add}
                        setRights={setRights}
                        user={user}
                        setOrganizationId={setOrganizationId}
                        setProjectId={setProjectId}
                        setSubprojectId={setSubprojectId}
                        openEditOrgModal={openEditOrgModal}
                        search={search}
                        setSearch={setSearch}
                        orgSearchString={orgSearchString}
                        setOrgSearchString={setOrgSearchString}
                        // dissoc={dissoc}
                        // organization={organization}
                        // save={save}
                    />
                    <OrgsList
                        organizationId={organizationId}
                        setOrganizationId={setOrganizationId}
                        setProjectId={setProjectId}
                        setSubprojectId={setSubprojectId}
                        setSearchString={setSearchString}
                        setFilter={setFilter}
                        isOrganizationAdmin={isOrganizationAdmin}
                        openEditOrgModal={openEditOrgModal}
                        openUserModulesModal={openUserModulesModal}
                        openRightsModal={openRightsModal}
                        update={update}
                        save={save}
                        orgsSearch={orgsSearch}
                        orgSearchString={orgSearchString}
                        organizations={organizations}
                    />
                </div>
            </div>
            <OrganizationRightsModal stack={stack} save={save} />
            <UserModulesModal stack={stack} save={save} />
            <EditOrgModal
                organization={organization}
                organizationId={organizationId}
                organizationLogoUrl={organizationLogoUrl}
                save={save}
                update={update}
                setFile={setFile}
            />
            <Toast name="organizations" />
        </>
    );
};

const Headline = ({
    canWriteApp,
    add,
    setRights,
    user,
    setOrganizationId,
    setProjectId,
    setSubprojectId,
    openEditOrgModal,
    search,
    setSearch,
    orgSearchString,
    setOrgSearchString,
    // dissoc,
    // organization,
    // save,
}) => {
    const inputRef = useRef();
    useEffect(() => {
        search && setTimeout(() => inputRef.current.focus(), 600);
    }, [search]);
    return (
        <div className="modal-headline">
            <div>Organizations</div>
            <div style={{ display: 'flex', flex: 3, justifyContent: 'flex-end' }}>
                {/* {organization.name !== 'Avencore GmbH' && (
                    <Button
                        onClick={() => {
                            dissoc(ROOT_ORGANIZATION_EDGE)(APPLICATION_ROOT_NODE)(organization);
                            setOrganizationId(undefined);
                            save();
                        }}
                    >
                        Delete Organization
                    </Button>
                )} */}
                {canWriteApp && (
                    <Button
                        onClick={() => {
                            const newOrganizationId = newId();
                            const newOrganization = {
                                id: newOrganizationId,
                            };
                            add(ROOT_ORGANIZATION_EDGE)(APPLICATION_ROOT_NODE)(newOrganization, {
                                order: newMsDate(),
                            });
                            setOrganizationId(newOrganizationId);
                            setRights(newOrganizationId)(user, {
                                read: true,
                                write: true,
                                admin: true,
                            });
                            const newOppTrackingId = newId();
                            const newOppTracking = {
                                id: newOppTrackingId,
                                name: 'Module: Opportunity Tracking',
                                orgId: newOrganizationId,
                            };
                            add(ORGANIZATION_OPPORTUNITY_TRACKING_EDGE)(newOrganizationId)(
                                newOppTracking,
                            );
                            setRights(newOppTrackingId)(user, {
                                read: true,
                            });
                            setProjectId(undefined);
                            setSubprojectId(undefined);
                            openEditOrgModal();
                        }}
                    >
                        <IoAddOutline size={23} />
                        <p style={{ marginLeft: 5 }}>Create Organization</p>
                    </Button>
                )}
                <Button style={{ marginLeft: 6 }} onClick={() => setSearch(!search)}>
                    {search ? <IoChevronForwardOutline size={19} /> : <IoSearchOutline size={21} />}
                </Button>
                {search && (
                    <div className="animated-searchbar" style={{ flex: 0.65 }}>
                        <input
                            ref={inputRef}
                            placeholder="search organizations..."
                            value={orgSearchString}
                            onChange={e => setOrgSearchString(e.target.value)}
                        />
                        <Button style={{ marginLeft: 3 }} onClick={() => setOrgSearchString('')}>
                            <IoTrashOutline size={17} />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

const OrgsList = ({
    organizationId,
    setOrganizationId,
    setProjectId,
    setSubprojectId,
    setSearchString,
    setFilter,
    isOrganizationAdmin,
    openEditOrgModal,
    openUserModulesModal,
    openRightsModal,
    update,
    save,
    orgsSearch,
    orgSearchString,
    organizations,
}) => (
    <ListField className style={{ flex: 1, flexBasis: 150 }} label="Organizations">
        {R.map(org => (
            <div
                key={R.propOr('', 'nodeId', org)}
                className="row hover-effect"
                style={{
                    cursor: 'pointer',
                    backgroundColor: org.nodeId === organizationId && 'var(--corporate-color-5l)',
                    borderBottomColor: org.nodeId === organizationId && 'transparent',
                    paddingLeft: 9,
                }}
                onClick={() => {
                    if (org.nodeId !== organizationId) {
                        setOrganizationId(org.nodeId);
                        setProjectId();
                        setSubprojectId();
                        setSearchString('');
                        setFilter({});
                    }
                }}
            >
                <div
                    style={{
                        fontSize: 'inherit',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flex: 1,
                        alignItems: 'center',
                    }}
                >
                    <IoHomeOutline
                        size={20}
                        color="var(--corporate-color-2-light)"
                        style={{ marginRight: 12 }}
                    />
                    <div
                        style={{
                            fontSize: 'inherit',
                            fontWeight: org.nodeId === organizationId ? 'normal' : 100,
                        }}
                    >
                        {R.pathOr('', ['node', 'name'], org)}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 2 }}>
                    {/* <Button
                                            onClick={() => {
                                                const newOppTrackingId = newId();
                                                const newOppTracking = {
                                                    id: newOppTrackingId,
                                                    name: 'Module: Opportunity Tracking',
                                                    orgId: organizationId,
                                                };
                                                add(ORGANIZATION_OPPORTUNITY_TRACKING_EDGE)(organizationId)(newOppTracking);
                                                setRights(newOppTrackingId)(user, {
                                                    read: true,
                                                    write: true,
                                                    admin: true,
                                                });
                                            }}
                                            style={{ marginRight: 12, color: 'var(--corporate-color-2)' }}
                                        >
                                            <IoCreateOutline size={20} />
                                            <p>OT Node</p>
                                        </Button>
                                        <Button
                                            onClick={() => save()}
                                            style={{ marginRight: 12, color: 'var(--corporate-color-2)' }}
                                        >
                                            <IoCreateOutline size={20} />
                                            <p>Push</p>
                                        </Button> */}
                    {isOrganizationAdmin && (
                        <>
                            <Button
                                onClick={() => openEditOrgModal()}
                                style={{ marginRight: 12, color: 'var(--corporate-color-2)' }}
                            >
                                <IoCreateOutline size={20} />
                                <p>Edit</p>
                            </Button>
                            <Button
                                style={{ marginRight: 12, color: 'var(--corporate-color-2)' }}
                                onClick={() => {
                                    openRightsModal(org.nodeId);
                                }}
                            >
                                <IoLockOpenOutline size={17} />
                                <p style={{ marginLeft: 5 }}>Manage Organization Admins</p>
                            </Button>
                            <Button
                                style={{ color: 'var(--corporate-color-2)' }}
                                onClick={() => {
                                    openUserModulesModal(org.nodeId);
                                }}
                            >
                                <IoAppsOutline size={17} />
                                <p style={{ marginLeft: 5 }}>Manage User Modules</p>
                            </Button>
                        </>
                    )}
                    <div
                        style={{
                            width: 0,
                            borderLeft: '1px solid var(--corporate-color-1-light)',
                            margin: '0 20 0 12',
                        }}
                    />
                    <FancyCheckbox
                        placeholderStyle={{ color: 'var(--corporate-color-2)', marginRight: 6 }}
                        label="Set as default"
                        value={R.pathOr(false, ['node', 'default'], org)}
                        onChange={() => {
                            update(R.assoc('default', !org.node.default, org.node));
                            R.forEach(orga => update(R.assoc('default', false, orga.node)))(
                                R.filter(c => c.nodeId !== org.nodeId)(R.values(organizations)),
                            );
                            save();
                        }}
                    />
                </div>
            </div>
        ))(orgsSearch(orgSearchString))}
    </ListField>
);
