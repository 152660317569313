import React, { useCallback } from 'react';
import * as R from 'ramda';
import {
    EDIT_PROTOCOL_TOP_MODAL_MOBILE,
    MEETING_TOP_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { useMeetingTops } from '../../hooks/useMeetingTops';
import { useTopBusinessIds } from '../../hooks/useTopBusinessIds';
import { ListRow } from '../../Components/Common/ListRow';
import { TopMobile } from './TopMobile';
import { useModal } from '../../hooks/useModal';

export const MeetingContentMobile = ({ stack, meetingId }) => {
    const { remove } = useGraph(...stack);

    const sortedTopBags = useMeetingTops(stack, meetingId);
    useTopBusinessIds(stack, sortedTopBags);
    const openEditProtocolTopMobileModal = useModal(EDIT_PROTOCOL_TOP_MODAL_MOBILE);
    const removeTop = useCallback(remove(MEETING_TOP_EDGE)(meetingId), [meetingId]);

    return (
        <div className="meeting-content-area-mobile">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div
                    className="modal-headline-mobile"
                    style={{ marginLeft: 0, marginBottom: '1rem', fontSize: '4.5rem' }}
                >
                    Content
                </div>
            </div>
            <div className="meeting-content-mobile fader">
                {R.map(({ node, protocolId }) => (
                    <ListRow
                        key={node.id}
                        style={{
                            margin: '2.5rem 0 3.5rem 0',
                            borderRadius: '5rem',
                        }}
                        onClick={() => openEditProtocolTopMobileModal({ topId: node.id })}
                    >
                        <div
                            className="top-search-row"
                            style={{
                                backgroundColor: R.includes(node.type, ['1', '2', '3'])
                                    ? 'var(--corporate-color-14-light3)'
                                    : 'white',
                            }}
                        >
                            <TopMobile
                                noButton
                                protocol
                                stack={stack}
                                meetingId={meetingId}
                                topId={node.id}
                                protocolId={protocolId}
                                onRemove={() => removeTop({ id: node.id })}
                            />
                        </div>
                    </ListRow>
                ))(sortedTopBags)}
                <div style={{ height: '20vh' }} />
            </div>
        </div>
    );
};
