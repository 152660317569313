import React, { useEffect } from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { DateField } from '../Common/Fields';
import { useNode } from '../../hooks/useNode';
import {
    PROJECT_OPP_STATUS_TYPE_MILESTONES_CHOICES,
    useProjectChoices,
} from '../../hooks/useChoices';
import { formatShortDate } from '../../common/util';

export const OppTimeline = ({ stack, topId, update, save }) => {
    const { choices: oppStatusTypeMilestonesChoices } = useProjectChoices(
        stack,
        PROJECT_OPP_STATUS_TYPE_MILESTONES_CHOICES,
    );
    const top = useNode(stack, topId);
    const statusses = R.map(ost => ost.label)(oppStatusTypeMilestonesChoices);
    const topStatusIndex = R.indexOf(top.node.status, statusses);
    const statusDueDate = `${'dueDate_'}${statusses[topStatusIndex + 1]}`;
    const msDueDate = new Date(top.node[statusDueDate]).getTime();
    const msDate = Date.now();

    useEffect(() => {
        msDate > msDueDate && update(R.assoc('critical', true, top.node));
        msDate < msDueDate && update(R.assoc('critical', false, top.node));
    }, [topId, msDueDate]);

    return (
        <div className="opp-timeline">
            <div className="opp-date-badge-container">
                <p className="opp-date-badge-indicator">
                    {formatShortDate(
                        R.pathOr(
                            R.pathOr('', ['node', 'modifiedDate'], top),
                            ['node', 'createdDate'],
                            top,
                        ),
                    )}
                </p>
                <p className="opp-date-badge">created date</p>
            </div>
            <div className="opp-timeline-elements-container">
                {RA.mapIndexed((status, index) => (
                    <TimelineElementNew
                        i={index}
                        status={status.label}
                        topStatusIndex={topStatusIndex}
                        top={top.node}
                        save={save}
                        update={update}
                        msDate={msDate}
                        msDueDate={msDueDate}
                    />
                ))(oppStatusTypeMilestonesChoices)}
            </div>
        </div>
    );
};

const TimelineElementNew = ({
    i,
    top,
    save,
    status,
    update,
    msDate,
    msDueDate,
    topStatusIndex,
}) => {
    const indicatedStyles = {
        color:
            top.status !== status && i < topStatusIndex
                ? 'var(--corporate-color-13)'
                : top.status === status
                ? 'var(--corporate-color-1)'
                : msDate > msDueDate && i === topStatusIndex + 1
                ? 'var(--color-error)'
                : 'var(--corporate-color-1)',
        boxShadow:
            top.status !== status && i < topStatusIndex
                ? '0px 0px 7px 0px var(--corporate-color-13'
                : top.status === status
                ? '0px 0px 7px 0px var(--corporate-color-1)'
                : msDate > msDueDate && i === topStatusIndex + 1
                ? '0px 0px 7px 0px var(--color-error)'
                : '0px 0px 7px 0px var(--corporate-color-1-light)',
        borderColor:
            top.status !== status && i < topStatusIndex
                ? 'var(--corporate-color-13)'
                : top.status === status
                ? 'var(--corporate-color-1)'
                : msDate > msDueDate && i === topStatusIndex + 1
                ? 'var(--color-error)'
                : 'var(--corporate-color-1-light)',
        borderWidth: 1,
    };
    return (
        <div className="opp-timeline-element" style={{ top: i * -38 }}>
            <div
                style={{
                    width: 0,
                    height: i === 0 ? 60 : 75,
                    borderLeft: '1px solid var(--corporate-color-1)',
                    ...indicatedStyles,
                }}
            />
            <div className="opp-timeline-status-row">
                <div className="opp-date-badge-light" style={indicatedStyles}>
                    <DateField
                        className="xtra-small"
                        label="Due Date"
                        value={R.propOr('', `dueDate_${status}`, top)}
                        locale="en-gb"
                        onChange={value => {
                            update(R.assoc(`dueDate_${status}`, value, top));
                            save();
                        }}
                        editable
                        noBottomLabel
                    />
                </div>
                <div className="timeline-row-line" style={indicatedStyles} />
                <div className="opp-date-badge-light" style={indicatedStyles}>
                    {status}
                </div>
            </div>
        </div>
    );
};
