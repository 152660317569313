import React from 'react';
import * as R from 'ramda';
import { ActionsChartMobile } from './ActionChartsMobile';
import { TopMobile } from './TopMobile';
import { TopButtonRowMobile } from './TopButtonRowMobile';
import { ListRow } from '../../Components/Common/ListRow';

export const GlobalActionsAreaMobile = ({
    stack,
    allActions,
    setDashboardState,
    setDashboardTops,
    allActionStatusTypes,
}) => (
    <div className="content-container-mobile">
        <div className="headline-mobile" style={{ margin: '10rem 0 5rem 0' }}>
            My Actions
        </div>
        <div className="diagram-container">
            <ActionsChartMobile
                stack={stack}
                actions={allActions}
                allActionStatusTypes={allActionStatusTypes}
                setGlobalDashboardTops={e => {
                    setDashboardTops(e);
                    setDashboardState('tops');
                }}
            />
        </div>
        <div className="content-container" style={{ marginTop: '7.5rem', marginBottom: '30vh' }}>
            <div className="column">
                {R.map(a => (
                    <ListRow
                        key={a.nodeId}
                        style={{
                            margin: '2.5rem 0 3.5rem 0',
                        }}
                    >
                        <div className="top-search-row">
                            <TopButtonRowMobile stack={stack} top={a} noEdit global />
                            <TopMobile
                                stack={stack}
                                topId={a.nodeId}
                                editable
                                noButton
                                global
                                noId
                            />
                        </div>
                    </ListRow>
                ))(R.slice(0, 20)(allActions))}
            </div>
        </div>
    </div>
);
