import MsgReader from '@kenjiuno/msgreader';
import * as R from 'ramda';

const parseMsgDateToIso = msgDate => {
    try {
        const isoDate = new Date(msgDate).toISOString();
        return isoDate;
    } catch (err) {
        return '';
    }
};

export const parseMsg = msgBuffer => {
    const msgReader = new MsgReader(msgBuffer);
    const msgData = msgReader.getFileData();

    if (msgData.error) {
        throw new Error(msgData.error);
    }

    const data = {};
    data.startDate = parseMsgDateToIso(msgData.clipStart);
    data.endDate = parseMsgDateToIso(msgData.clipEnd);
    data.description = msgData.subject;
    data.location = msgData.location;
    data.summary = msgData.conversationTopic;
    data.attendees = R.compose(
        R.uniq,
        R.map(attendee => ({
            name: attendee.name,
            email: attendee.smtpAddress || attendee.email,
        })),
    )(msgData.recipients);
    return data;
};
