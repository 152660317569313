import React from 'react';
import * as R from 'ramda';
import { useAtom, useCreateAtom } from '@gothub-team/got-atom';
import { useActionHistoryChart } from '../hooks/useActionHistoryChart';
import { BarChart } from './HorizontalBarChart';
import { ChartToolTip } from './ChartToolTip';
import {
    DASHBOARD_FILTER_KEY,
    SEARCH_STRING_KEY,
    useGraph,
} from '../../../../common/hooks.config.new';

export const ActionHistoryChart = ({
    stack,
    actions,
    maxElements = Number.MAX_SAFE_INTEGER,
    onClick,
}) => {
    const { data: _data, labels, bids, colors } = useActionHistoryChart(stack, actions);

    const data = _data.slice(0, maxElements);

    const TooltipAtom = useCreateAtom(null);

    const { useVar } = useGraph(...stack);
    const [filter, setFilter] = useVar(DASHBOARD_FILTER_KEY);
    const [, setSearchString] = useVar(SEARCH_STRING_KEY);
    return (
        <div
            style={{
                flex: 1.5,
            }}
        >
            <BarChart
                colors={colors}
                data={data}
                labels={labels}
                handleHoverStart={index => {
                    TooltipAtom.set(index);
                }}
                handleHoverStop={() => {
                    TooltipAtom.set(null);
                }}
                onClick={index => {
                    setFilter(
                        R.compose(
                            R.assoc('showTops', true),
                            R.assocPath(['topType', 'values'], [{ value: 'a', label: 'a' }]),
                        )(filter),
                    );

                    setSearchString(`@${bids[index]}`); // TODO: this doesnt update search bar

                    onClick && onClick(labels[index]);
                }}
            />
            <ChartToolTip tooltipAtom={TooltipAtom}>
                <ToolTipContent
                    tooltipAtom={TooltipAtom}
                    data={data}
                    labels={labels}
                    colors={colors}
                />
            </ChartToolTip>
        </div>
    );
};

const ToolTipContent = ({ tooltipAtom, data, labels, colors }) => {
    const index = useAtom(tooltipAtom);

    if (index == null) return null;

    const color = colors[index];
    const count = data[index];

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                backgroundColor: 'white',
                borderRadius: '1rem',
                padding: '1rem 2rem',
                boxShadow: '0 5px 8px -2px var(--corporate-color-1-light4)',
            }}
        >
            <h3>{labels[index]}</h3>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '0.5rem',
                }}
            >
                <div className="legend-color" style={{ backgroundColor: color }} />
                <div>
                    <b>{`${count}`}</b> Histories
                </div>
            </div>
        </div>
    );
};
