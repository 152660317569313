import React, { useState } from 'react';
import { IoCloudDownloadOutline, IoSyncOutline } from 'react-icons/io5';
import { MAIN, PROJECT_ID_KEY, useGraph } from '../../../common/hooks.config.new';
import { Button } from '../../Common/Controls';
import { pullProject } from './DashboardTopCalculator';

export const RefreshButton = ({ stack = [MAIN], mobile }) => {
    const { useVar } = useGraph(...stack);
    const [projectId] = useVar(PROJECT_ID_KEY);

    const [loading, setLoading] = useState(false);
    if (mobile) {
        return (
            <Button
                onClick={async () => {
                    setLoading(true);
                    try {
                        await pullProject(stack, projectId);
                    } finally {
                        setLoading(false);
                    }
                }}
                style={{ backgroundColor: mobile && 'white' }}
                className="logout-button-mobile"
            >
                {loading ? (
                    <div className="spinit">
                        <IoSyncOutline size="5.5rem" color="var(--corporate-color-13)" />
                    </div>
                ) : (
                    <IoCloudDownloadOutline color="var(--corporate-color-2)" size="4.75rem" />
                )}
            </Button>
        );
    }
    return (
        <Button
            onClick={async () => {
                setLoading(true);
                try {
                    await pullProject(stack, projectId);
                } finally {
                    setLoading(false);
                }
            }}
        >
            {loading ? (
                <div className="spinit">
                    <IoSyncOutline size={24} color="var(--corporate-color-13)" />
                </div>
            ) : (
                <IoCloudDownloadOutline size={24} color="white" />
            )}
        </Button>
    );
};
