import React, { memo, useState } from 'react';
import * as R from 'ramda';
import '../ChartsArea.css';
import { BsLayoutThreeColumns } from 'react-icons/bs';
import {
    IoBarChartOutline,
    IoCodeOutline,
    IoEllipsisHorizontalOutline,
    IoFilterOutline,
    IoTrailSignOutline,
} from 'react-icons/io5';
import { PROJECT_OPP_STATUS_TYPE_CHOICES, useProjectChoices } from '../../../hooks/useChoices';
import { OpportunitiesTable } from './OpportunitiesTable';
import { Button } from '../../Common/Controls';
import { OppLegend, OppsCountDonut, OppsSavingsDonut } from './OppCharts';
import {
    ActionsKanbanBoard,
    OppsCategoryKanbanBoard,
    OppsStatusKanbanBoard,
    ProjectPhasesKanbanBoard,
} from './KanbanBoards';
import { ProjectPhasesGanttChart } from './GanttCharts';
import { useModal } from '../../../hooks/useModal';
import { PROJECT_MILESTONES_MODAL, PROJECT_PHASES_MODAL } from '../../../common/hooks.config.new';
import { ProjectActionCharts } from '../ActionCharts/ProjectActionCharts';

const _Opportunities = ({
    stack,
    opps,
    criticalOpps,
    dashboardState,
    oppTracking,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
}) => {
    const { choices: oppStatusTypeChoices } = useProjectChoices(
        stack,
        PROJECT_OPP_STATUS_TYPE_CHOICES,
    );
    const oppStatus = R.groupBy(R.path(['top', 'status']), opps);
    const chartedOpps = oppStatusTypeChoices.map(statusType => ({
        type: statusType.label,
        tops: oppStatus[statusType.label] || [],
    }));
    const [oppsChart, setOppsChart] = useState(true);
    const [sortByCategory, setSortByCategory] = useState(true);

    return (
        <div
            style={{
                borderRadius: 30,
                marginTop: 75,
            }}
        >
            <div style={{ margin: '0 24' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                        className="headline font-weight-100"
                        style={{
                            border: 'none',
                            fontSize: 32,
                            color: 'var(--corporate-color-2)',
                        }}
                    >
                        {dashboardState === 'user'
                            ? 'My'
                            : dashboardState === 'subproject'
                            ? 'Subproject'
                            : 'Project'}{' '}
                        Opportunities
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginLeft: 12,
                            flex: 1,
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Button
                                onClick={() => setOppsChart(true)}
                                style={{
                                    backgroundColor: 'white',
                                    border: oppsChart
                                        ? '1px solid var(--corporate-color-14-light)'
                                        : '1px solid transparent',
                                    color: oppsChart && 'var(--corporate-color-14)',
                                    boxShadow: oppsChart
                                        ? '0 5px 8px -2px var(--corporate-color-14-light)'
                                        : '0 5px 8px -2px var(--corporate-color-1-light4)',
                                }}
                                className="hover-scale font-weight-100"
                            >
                                <IoBarChartOutline size={19} style={{ marginRight: 9 }} />
                                Chart mode
                            </Button>
                            <Button
                                onClick={() => {
                                    setOppsChart(false);
                                }}
                                style={{
                                    backgroundColor: 'white',
                                    border: !oppsChart
                                        ? '1px solid var(--corporate-color-14-light)'
                                        : '1px solid transparent',
                                    color: !oppsChart && 'var(--corporate-color-14)',
                                    marginLeft: 12,
                                    boxShadow: !oppsChart
                                        ? '0 5px 8px -2px var(--corporate-color-14-light)'
                                        : '0 5px 8px -2px var(--corporate-color-1-light4)',
                                }}
                                className="hover-scale font-weight-100"
                            >
                                <BsLayoutThreeColumns size={17} style={{ marginRight: 6 }} />
                                Board mode
                            </Button>
                            {!oppsChart && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <IoCodeOutline
                                        size={20}
                                        style={{ margin: '0 12 0 12' }}
                                        color="var(--corporate-color-2-light)"
                                    />
                                    <Button
                                        onClick={() => setSortByCategory(true)}
                                        style={{
                                            backgroundColor: 'white',
                                            border: sortByCategory
                                                ? '1px solid var(--corporate-color-13-light)'
                                                : '1px solid transparent',
                                            color: sortByCategory && 'var(--corporate-color-13)',
                                            boxShadow: sortByCategory
                                                ? '0 5px 8px -2px var(--corporate-color-13-light)'
                                                : '0 5px 8px -2px var(--corporate-color-1-light4)',
                                        }}
                                        className="hover-scale font-weight-100"
                                    >
                                        <IoFilterOutline size={20} style={{ marginRight: 9 }} />
                                        Category
                                    </Button>
                                    <Button
                                        onClick={() => setSortByCategory(false)}
                                        style={{
                                            backgroundColor: 'white',
                                            border: !sortByCategory
                                                ? '1px solid var(--corporate-color-13-light)'
                                                : '1px solid transparent',
                                            color: !sortByCategory && 'var(--corporate-color-13)',
                                            marginLeft: 12,
                                            boxShadow: !sortByCategory
                                                ? '0 5px 8px -2px var(--corporate-color-13-light)'
                                                : '0 5px 8px -2px var(--corporate-color-1-light4)',
                                        }}
                                        className="hover-scale font-weight-100"
                                    >
                                        <IoFilterOutline size={20} style={{ marginRight: 6 }} />
                                        Status
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {oppsChart ? (
                <div className="layout fader" style={{ margin: '0 36' }}>
                    <div className="opp-charts">
                        <div className="chart-legend" style={{ marginTop: 30 }}>
                            <OppsCountDonut
                                stack={stack}
                                chartedOpps={chartedOpps}
                                criticalOppsCount={R.length(criticalOpps)}
                            />
                            <OppLegend oppStatusTypeChoices={oppStatusTypeChoices} />
                            <OppsSavingsDonut
                                stack={stack}
                                chartedOpps={chartedOpps}
                                criticalOpps={criticalOpps}
                            />
                        </div>
                    </div>
                    <div className="tables fader" style={{ marginTop: 12 }}>
                        <OpportunitiesTable
                            stack={stack}
                            opps={opps}
                            criticalOpps={criticalOpps}
                            chartedOpps={chartedOpps}
                        />
                    </div>
                </div>
            ) : sortByCategory ? (
                <OppsCategoryKanbanBoard
                    stack={stack}
                    opps={opps}
                    criticalOpps={criticalOpps}
                    canAdminProject={canAdminProject}
                    canWriteProject={canWriteProject}
                    subprojectId={subprojectId}
                    editMessage={editMessage}
                    userName={userName}
                    oppTracking={oppTracking}
                />
            ) : (
                <OppsStatusKanbanBoard
                    stack={stack}
                    opps={opps}
                    criticalOpps={criticalOpps}
                    canAdminProject={canAdminProject}
                    canWriteProject={canWriteProject}
                    subprojectId={subprojectId}
                    editMessage={editMessage}
                    userName={userName}
                    oppTracking={oppTracking}
                />
            )}
        </div>
    );
};
const Opportunities = memo(_Opportunities, R.equals);

const _Actions = ({
    stack,
    dashboardState,
    fnSelectNode, // TODO: what is this? (Answer: it's a function that selects the top object in the extended top bag/info object, we should probably get rid of this)
    actions,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
}) => {
    const [actionsChart, setActionsChart] = useState(true);
    return (
        <div
            style={{
                borderRadius: 30,
                marginTop: 30,
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', margin: '0 24' }}>
                <div
                    className="headline font-weight-100"
                    style={{
                        border: 'none',
                        fontSize: 32,
                        color: 'var(--corporate-color-2)',
                    }}
                >
                    {dashboardState === 'user'
                        ? 'My'
                        : dashboardState === 'subproject'
                        ? 'Subproject'
                        : 'Project'}{' '}
                    Actions
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 12 }}>
                    <Button
                        onClick={() => setActionsChart(true)}
                        style={{
                            backgroundColor: 'white',
                            border: actionsChart
                                ? '1px solid var(--corporate-color-14-light)'
                                : '1px solid transparent',
                            color: actionsChart && 'var(--corporate-color-14)',
                            boxShadow: actionsChart
                                ? '0 5px 8px -2px var(--corporate-color-14-light)'
                                : '0 5px 8px -2px var(--corporate-color-1-light4)',
                        }}
                        className="hover-scale font-weight-100"
                    >
                        <IoBarChartOutline size={19} style={{ marginRight: 6 }} />
                        Chart mode
                    </Button>
                    <Button
                        onClick={() => setActionsChart(false)}
                        style={{
                            backgroundColor: 'white',
                            border: !actionsChart
                                ? '1px solid var(--corporate-color-14-light)'
                                : '1px solid transparent',
                            color: !actionsChart && 'var(--corporate-color-14)',
                            marginLeft: 12,
                            boxShadow: !actionsChart
                                ? '0 5px 8px -2px var(--corporate-color-14-light)'
                                : '0 5px 8px -2px var(--corporate-color-1-light4)',
                        }}
                        className="hover-scale font-weight-100"
                    >
                        <BsLayoutThreeColumns size={17} style={{ marginRight: 6 }} />
                        Board mode
                    </Button>
                </div>
            </div>
            {actionsChart ? (
                <div className="actions-chart" style={{ width: '100%' }}>
                    <div
                        style={{
                            width: '100%',
                            margin: '36',
                        }}
                        className="inner"
                    >
                        <ProjectActionCharts stack={stack} actions={actions} />
                    </div>
                </div>
            ) : (
                <ActionsKanbanBoard
                    stack={stack}
                    actions={actions}
                    canAdminProject={canAdminProject}
                    canWriteProject={canWriteProject}
                    subprojectId={subprojectId}
                    editMessage={editMessage}
                    userName={userName}
                />
            )}
        </div>
    );
};
const Actions = memo(_Actions, R.equals);

const _Phases = ({
    stack,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
    oppTracking,
}) => {
    const [phasesChart, setPhasesChart] = useState(false);
    const [detailed, setDetailed] = useState(false);
    const openProjectPhasesModal = useModal(PROJECT_PHASES_MODAL);
    const openProjectMilestonesModal = useModal(PROJECT_MILESTONES_MODAL);
    return (
        <div
            style={{
                borderRadius: 30,
                marginTop: !oppTracking ? 20 : 75,
                marginBottom: 50,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '0 24',
                }}
            >
                <div
                    className="headline font-weight-100"
                    style={{
                        border: 'none',
                        fontSize: 32,
                        color: 'var(--corporate-color-2)',
                    }}
                >
                    Project Phases
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginLeft: 12,
                        flex: 1,
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            onClick={() => setPhasesChart(false)}
                            style={{
                                backgroundColor: 'white',
                                border: !phasesChart
                                    ? '1px solid var(--corporate-color-14-light)'
                                    : '1px solid transparent',
                                color: !phasesChart && 'var(--corporate-color-14)',
                                boxShadow: !phasesChart
                                    ? '0 5px 8px -2px var(--corporate-color-14-light)'
                                    : '0 5px 8px -2px var(--corporate-color-1-light4)',
                            }}
                            className="hover-scale font-weight-100"
                        >
                            <IoBarChartOutline size={19} style={{ marginRight: 6 }} />
                            Chart mode
                        </Button>
                        {!phasesChart && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IoCodeOutline
                                    size={20}
                                    style={{ margin: '0 12 0 12' }}
                                    color="var(--corporate-color-2-light)"
                                />
                                <Button
                                    onClick={() => setDetailed(false)}
                                    style={{
                                        backgroundColor: 'white',
                                        border: !detailed
                                            ? '1px solid var(--corporate-color-13-light)'
                                            : '1px solid transparent',
                                        color: !detailed && 'var(--corporate-color-13)',
                                        boxShadow: !detailed
                                            ? '0 5px 8px -2px var(--corporate-color-13-light)'
                                            : '0 5px 8px -2px var(--corporate-color-1-light4)',
                                    }}
                                    className="hover-scale font-weight-100"
                                >
                                    <IoFilterOutline size={20} style={{ marginRight: 6 }} />
                                    Overview
                                </Button>
                                <Button
                                    onClick={() => setDetailed(true)}
                                    style={{
                                        backgroundColor: 'white',
                                        border: detailed
                                            ? '1px solid var(--corporate-color-13-light)'
                                            : '1px solid transparent',
                                        color: detailed && 'var(--corporate-color-13)',
                                        marginLeft: 12,
                                        marginRight: 12,
                                        boxShadow: detailed
                                            ? '0 5px 8px -2px var(--corporate-color-13-light)'
                                            : '0 5px 8px -2px var(--corporate-color-1-light4)',
                                    }}
                                    className="hover-scale font-weight-100"
                                >
                                    <IoFilterOutline size={20} style={{ marginRight: 6 }} />
                                    Detailed
                                </Button>
                            </div>
                        )}
                        <Button
                            onClick={() => setPhasesChart(true)}
                            style={{
                                backgroundColor: 'white',
                                border: phasesChart
                                    ? '1px solid var(--corporate-color-14-light)'
                                    : '1px solid transparent',
                                color: phasesChart && 'var(--corporate-color-14)',
                                marginLeft: 12,
                                boxShadow: phasesChart
                                    ? '0 5px 8px -2px var(--corporate-color-14-light)'
                                    : '0 5px 8px -2px var(--corporate-color-1-light4)',
                            }}
                            className="hover-scale font-weight-100"
                        >
                            <BsLayoutThreeColumns size={17} style={{ marginRight: 6 }} />
                            Board mode
                        </Button>
                    </div>
                </div>
                <div className="gantt-buttons" style={{ marginRight: 24 }}>
                    <Button onClick={openProjectPhasesModal}>
                        <IoEllipsisHorizontalOutline size={20} style={{ marginRight: 6 }} />
                        Manage project phases
                    </Button>
                    <Button style={{ marginLeft: 12 }} onClick={openProjectMilestonesModal}>
                        <IoTrailSignOutline size={20} style={{ marginRight: 6 }} />
                        Manage milestones
                    </Button>
                </div>
            </div>
            {phasesChart ? (
                <ProjectPhasesKanbanBoard
                    stack={stack}
                    canAdminProject={canAdminProject}
                    canWriteProject={canWriteProject}
                    subprojectId={subprojectId}
                    editMessage={editMessage}
                    userName={userName}
                    oppTracking={oppTracking}
                />
            ) : (
                <ProjectPhasesGanttChart stack={stack} detailedView={detailed} />
            )}
        </div>
    );
};
const Phases = memo(_Phases, R.equals);

const _ChartsAndBoardsArea = ({
    stack,
    opps,
    criticalOpps,
    actions,
    tops,
    dashboardState,
    fnSelectNode,
    oppTracking,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
}) => (
    <div className="charts-area">
        <Actions
            stack={stack}
            dashboardState={dashboardState}
            oppTracking={oppTracking}
            actions={actions}
            fnSelectNode={fnSelectNode}
            canAdminProject={canAdminProject}
            canWriteProject={canWriteProject}
            subprojectId={subprojectId}
            editMessage={editMessage}
            userName={userName}
        />
        {oppTracking && (
            <Opportunities
                stack={stack}
                opps={opps}
                criticalOpps={criticalOpps}
                dashboardState={dashboardState}
                oppTracking={oppTracking}
                canAdminProject={canAdminProject}
                canWriteProject={canWriteProject}
                subprojectId={subprojectId}
                editMessage={editMessage}
                userName={userName}
            />
        )}
        <Phases
            stack={stack}
            dashboardState={dashboardState}
            oppTracking={oppTracking}
            tops={tops}
            fnSelectNode={fnSelectNode}
            canAdminProject={canAdminProject}
            canWriteProject={canWriteProject}
            subprojectId={subprojectId}
            editMessage={editMessage}
            userName={userName}
        />
    </div>
);
export const ChartsAndBoardsArea = memo(_ChartsAndBoardsArea, R.equals);
