/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import * as R from 'ramda';

export const createListRow = (TopBar, staticStyle = {}) =>
    memo(props => {
        const { className = '', style = {}, draggable = false, onDrag, onClick, children } = props;

        return (
            <div
                className={`list-row ${className}`}
                style={{ ...staticStyle, ...style }}
                draggable={draggable}
                onDragStart={onDrag}
                onClick={onClick}
            >
                <TopBar {...props} />
                {children}
            </div>
        );
    }, R.equals);
