import * as R from 'ramda';
import { useMemo } from 'react';
import { createGraph, useGraph } from '../common/hooks.config.new';
import { constructSubprojectsView } from '../Components/Modals/ProjectsModal';

const selectSubprojectChoices = R.compose(
    R.map(subprojectBag => ({
        id: R.path(['node', 'id'], subprojectBag),
        title: R.path(['node', 'title'], subprojectBag),
        businessId: R.path(['node', 'businessId'], subprojectBag),
    })),
    R.values,
    R.pathOr({}, ['project', 'subprojects']),
);

export const getSubprojectChoices = (stack, projectId) => {
    const { getView } = createGraph(...stack);
    const view = constructSubprojectsView(projectId);
    const choices = selectSubprojectChoices(getView(view));

    return choices;
};

export const useSubprojectChoices = (stack, projectId) => {
    const { useView } = useGraph(...stack);
    const view = useMemo(() => constructSubprojectsView(projectId), [projectId]);
    const choices = useView(view, selectSubprojectChoices);

    return choices;
};
