import React from 'react';

export const ItemsCountBar = ({ showTopType, setShowTopType, itemsCount }) => (
    <div className="scorecard-linked-items-container">
        <div
            className="column2 hoverwienrover"
            style={{
                alignItems: 'center',
                backgroundColor: itemsCount('a') === 0 && 'transparent',
                cursor: itemsCount('a') === 0 && 'default',
                border: showTopType === 'a' && '1px solid var(--corporate-color-1)',
            }}
            onClick={() => {
                showTopType !== 'a' && itemsCount('a') > 0 ? setShowTopType('a') : setShowTopType();
            }}
        >
            <p className="count" style={{ cursor: itemsCount('a') === 0 && 'default' }}>
                {itemsCount('a')}
            </p>
            <p className="headline" style={{ cursor: itemsCount('a') === 0 && 'default' }}>
                Actions
            </p>
        </div>
        <div
            className="column2 hoverwienrover"
            style={{
                alignItems: 'center',
                backgroundColor: itemsCount('o') === 0 && 'transparent',
                cursor: itemsCount('o') === 0 && 'default',
                border: showTopType === 'o' && '1px solid var(--corporate-color-1)',
            }}
            onClick={() => {
                showTopType !== 'o' && itemsCount('o') > 0 ? setShowTopType('o') : setShowTopType();
            }}
        >
            <p
                className="count"
                style={{
                    cursor: itemsCount('o') === 0 && 'default',
                    backgroundColor: 'var(--corporate-color-13)',
                }}
            >
                {itemsCount('o')}
            </p>
            <p className="headline" style={{ cursor: itemsCount('o') === 0 && 'default' }}>
                Opportunities
            </p>
        </div>
        <div
            className="column2 hoverwienrover"
            style={{
                alignItems: 'center',
                backgroundColor: itemsCount('d') === 0 && 'transparent',
                cursor: itemsCount('d') === 0 && 'default',
                border: showTopType === 'd' && '1px solid var(--corporate-color-1)',
            }}
            onClick={() => {
                showTopType !== 'd' && itemsCount('d') > 0 ? setShowTopType('d') : setShowTopType();
            }}
        >
            <p
                className="count"
                style={{
                    cursor: itemsCount('d') === 0 && 'default',
                    backgroundColor: 'var(--corporate-color-7)',
                }}
            >
                {itemsCount('d')}
            </p>
            <p className="headline" style={{ cursor: itemsCount('d') === 0 && 'default' }}>
                Decisions
            </p>
        </div>
        <div
            className="column2 hoverwienrover"
            style={{
                alignItems: 'center',
                backgroundColor: itemsCount('s') === 0 && 'transparent',
                cursor: itemsCount('s') === 0 && 'default',
                border: showTopType === 's' && '1px solid var(--corporate-color-1)',
            }}
            onClick={() => {
                showTopType !== 's' && itemsCount('s') > 0 ? setShowTopType('s') : setShowTopType();
            }}
        >
            <p
                className="count"
                style={{ cursor: itemsCount('s') === 0 && 'default', backgroundColor: 'grey' }}
            >
                {itemsCount('s')}
            </p>
            <p className="headline" style={{ cursor: itemsCount('s') === 0 && 'default' }}>
                Statements
            </p>
        </div>
        <div
            className="column2 hoverwienrover"
            style={{
                alignItems: 'center',
                backgroundColor: itemsCount('i') === 0 && 'transparent',
                cursor: itemsCount('i') === 0 && 'default',
                border: showTopType === 'i' && '1px solid var(--corporate-color-1)',
            }}
            onClick={() => {
                showTopType !== 'i' && itemsCount('i') > 0 ? setShowTopType('i') : setShowTopType();
            }}
        >
            <p
                className="count"
                style={{ cursor: itemsCount('i') === 0 && 'default', backgroundColor: '#C5C9D3' }}
            >
                {itemsCount('i')}
            </p>
            <p className="headline" style={{ cursor: itemsCount('i') === 0 && 'default' }}>
                Informations
            </p>
        </div>
        <div
            className="column2 hoverwienrover"
            style={{
                alignItems: 'center',
                backgroundColor: itemsCount('r') === 0 && 'transparent',
                cursor: itemsCount('r') === 0 && 'default',
                border: showTopType === 'r' && '1px solid var(--corporate-color-1)',
            }}
            onClick={() => {
                showTopType !== 'r' && itemsCount('r') > 0 ? setShowTopType('r') : setShowTopType();
            }}
        >
            <p
                className="count"
                style={{
                    cursor: itemsCount('r') === 0 && 'default',
                    backgroundColor: 'var(--corporate-color-12)',
                }}
            >
                {itemsCount('r')}
            </p>
            <p className="headline" style={{ cursor: itemsCount('r') === 0 && 'default' }}>
                Risks
            </p>
        </div>
    </div>
);
