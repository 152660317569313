import * as R from 'ramda';
import { useGraph } from '../common/hooks.config.new';

export const useTopResponsible = (stack, topId) => {
    const { useView, assoc, dissoc } = useGraph(...stack);
    const topResponsible = 'top/responsible';
    const {
        [topId]: { [topResponsible]: responsibles },
    } = useView({
        [topId]: {
            edges: {
                [topResponsible]: {
                    include: {
                        node: true,
                    },
                },
            },
        },
    });

    const assocResponsible = assoc(topResponsible)(topId);
    const dissocResponsible = dissoc(topResponsible)(topId);

    const responsible = R.compose(R.prop('node'), R.head, R.values)(responsibles);
    const setResponsible = contact => {
        responsible && dissocResponsible(responsible);
        assocResponsible(contact);
    };
    return [responsible, setResponsible];
};
