import React, { useEffect, useState } from 'react';
import { IoCloseOutline, IoCloudDownloadOutline } from 'react-icons/io5';
import { Modal } from '../Common/Modal';
import { useModalState } from '../../hooks/useModal';
import { ZIP_MODAL } from '../../common/hooks.config.new';
import { projectZipDowload, STATES, subprojectZipDowload } from '../../common/protocol-zip.util';
import { Loader } from '../Elements/Loader';
import { Button } from '../Common/Controls';

export const TYPES = {
    PROJECT: 'project',
    SUBPROJECT: 'subproject',
};

export const ProtocolsZipModal = ({ stack }) => {
    const [isOpen] = useModalState(ZIP_MODAL);
    if (isOpen) {
        return (
            <Modal
                name={ZIP_MODAL}
                style={{
                    height: '32vh',
                    top: '34vh',
                    width: '30vw',
                    left: '35vw',
                }}
                contentContainerStyle={{ backgroundColor: 'white' }}
                topbar={<IoCloudDownloadOutline size={25} color="var(--corporate-color-7)" />}
            >
                <Content stack={stack} />
            </Modal>
        );
    }

    return null;
};

const Content = ({ stack }) => {
    const [{ type, nodeId, filename }, close] = useModalState(ZIP_MODAL);
    const [text, setText] = useState('');
    const [complete, setComplete] = useState(false);

    useEffect(() => {
        const load = async () => {
            const subscriber = {
                next: event => {
                    switch (event.type) {
                        case STATES.PULLING:
                            setText('Loading meetings...');
                            break;
                        case STATES.LOADING:
                            const [progress, max] = event.progress;
                            setText(`Loading Minutes... [${progress}/${max}]`);
                            break;
                        case STATES.GENERATING:
                            setText('Generating .zip archive...');
                            break;
                        case STATES.DOWNLOADING:
                            setText('Download should be starting momentarily!');
                            break;
                        default:
                            break;
                    }
                },
                complete: () => setComplete(true),
                error: () => {
                    setText('Something went wrong!');
                    setComplete(true);
                },
            };

            switch (type) {
                case TYPES.PROJECT:
                    await projectZipDowload(stack, nodeId, filename, subscriber);
                    setTimeout(close, 5000);
                    break;
                case TYPES.SUBPROJECT:
                    await subprojectZipDowload(stack, nodeId, filename, subscriber);
                    setTimeout(close, 5000);
                    break;
                default:
                    close();
            }
        };

        load();
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Loader containerStyle={{ height: 'fit-content' }} />
            <div style={{ fontWeight: 100, fontSize: 18, marginTop: 12 }}>{text}</div>
            <div style={{ marginTop: 12 }}>This might take a moment...</div>
            {complete && (
                <Button onClick={close} style={{ marginTop: 12 }}>
                    <IoCloseOutline size={20} />
                    <p>Close</p>
                </Button>
            )}
        </div>
    );
};
