import { useMemo } from 'react';
import { useActionStatuses } from './useActionStatuses';
import { colors } from '../../../../common/consts';

const getDelay = (action, time) => time - new Date(action?.top?.dueDate).getTime();

const getDelays = (actions, statusTypes) => {
    if (!actions) return { labels: [], data: [], colors: [] };

    const lateActions = actions.filter(
        action => action?.top?.dueDate && action?.top?.status === 'Late',
    );

    const currTime = new Date().getTime();

    const sortedActions = lateActions.sort((a, b) => getDelay(b, currTime) - getDelay(a, currTime));

    const labels2 = sortedActions.map(action => action.businessId);
    const labels = labels2.map(label => {
        const splitted = label.split('-');
        const newLabel = `${splitted[1]}-${splitted[2]}-${splitted[5]}${splitted[6]}`;
        const newLabelDashboard = `${splitted[1]}-DB-${(splitted[4] || '').toUpperCase()}${
            splitted[5]
        }`;
        if (splitted[2] === 'Dashboard') return newLabelDashboard;
        return newLabel;
    });
    const data = sortedActions.map(action => {
        const delayMs = getDelay(action, currTime);
        const delayDays = (delayMs / (1000 * 60 * 60 * 24)).toFixed(1);
        return delayDays;
    });
    const statuses = sortedActions.map(action => action?.top?.status);
    const _colors = statuses.map(status => {
        const index = statusTypes.indexOf(status);
        return colors.at(index % colors.length);
    });

    return {
        labels,
        data,
        statuses,
        colors: _colors,
    };
};

export const useActionDelayChart = (stack, actions) => {
    const statusTypes = useActionStatuses(stack);

    return useMemo(() => getDelays(actions, statusTypes), [actions]);
};
