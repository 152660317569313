import React from 'react';
import * as R from 'ramda';
import { BsArrowRight } from 'react-icons/bs';
import {
    ORGANIZATION_ID_KEY,
    PROJECT_ID_KEY,
    SUBPROJECT_ID_KEY,
} from '../../common/hooks.config.new';
import { useNodeFromVar } from '../../hooks/useNode';

export const ProjectIndicator = ({ stack }) => {
    const { node: organization } = useNodeFromVar(stack, ORGANIZATION_ID_KEY);
    const { node: project } = useNodeFromVar(stack, PROJECT_ID_KEY);
    const { node: subproject } = useNodeFromVar(stack, SUBPROJECT_ID_KEY);

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
                style={{
                    color: 'var(--corporate-color-6)',
                    fontSize: '16px',
                    cursor: 'default',
                }}
                className="font-weight-100"
            >
                {R.propOr('', 'name', organization)}
            </p>
            <p
                style={{
                    color: 'var(--corporate-color-6)',
                    marginRight: '24px',
                    marginLeft: '24px',
                }}
                className="font-weight-100"
            >
                <BsArrowRight size={20} />
            </p>
            <p
                style={{
                    color: 'var(--corporate-color-6)',
                    fontSize: '16px',
                    cursor: 'default',
                }}
                className="font-weight-100"
            >
                {R.propOr('', 'title', project)}
            </p>
            <p
                style={{
                    color: 'var(--corporate-color-6)',
                    marginRight: '24px',
                    marginLeft: '24px',
                }}
                className="font-weight-100"
            >
                <BsArrowRight size={22} />
            </p>
            <p
                style={{
                    color: 'var(--corporate-color-6)',
                    fontSize: '16px',
                    cursor: 'default',
                }}
                className="font-weight-100"
            >
                {R.propOr('', 'title', subproject)}
            </p>
        </div>
    );
};
