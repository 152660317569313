import * as R from 'ramda';
import { getProjectContacts, selectContactBagWithEmail } from '../hooks/useProjectContacts';
import { applyFilter, filterTops, searchAllTops } from './filteredtops.util';
import { constructNodeView } from './got-adjunct';
import {
    api,
    createGraph,
    DASHBOARD_FILTER_KEY,
    DASHBOARD_STATE_KEY,
    PROJECT_ID_KEY,
    SEARCH_STRING_KEY,
    SORTED_GROUPED_TOPS_KEY,
    store,
    SUBPROJECT_ID_KEY,
    TOP_ACTION_EDGE,
} from './hooks.config.new';

const createCsvCell = str => `"${R.compose(R.replace(/"/g, "'"))(str)}"`;

const getTopTable = R.compose(
    R.join('\n'),
    R.map(R.join(',')),
    R.prepend([
        'ID',
        'Type',
        'Description',
        'Author',
        'Responsible',
        'Status',
        'Due Date',
        'Category',
        'Reference',
        'Savings',
        'Cost Increase',
        'Project Phase',
        'Subproject',
        'Protocol ID',
        'Meeting Title',
        'Meeting Type',
        'Meeting Date',
        'Linked Opportunity',
    ]),
    R.map(
        ({
            top,
            responsible,
            protocolId,
            meetingDate,
            meeting,
            meetingType,
            subproject,
            author,
            linkedOpp,
        }) => [
            createCsvCell(R.propOr('', 'businessId', top)),
            createCsvCell(R.compose(R.toUpper, R.propOr('', 'type'))(top)),
            createCsvCell(R.propOr('', 'description', top)),
            createCsvCell(R.propOr('', 'name', author)),
            createCsvCell(R.propOr('', 'name', responsible)),
            createCsvCell(R.propOr('', 'status', top)),
            createCsvCell(R.compose(R.slice(0, 10), R.propOr('', 'dueDate'))(top)),
            createCsvCell(R.propOr('', 'category', top)),
            createCsvCell(R.propOr('', 'reference', top)),
            createCsvCell(R.propOr('', 'savings', top)),
            createCsvCell(R.propOr('', 'costIncrease', top)),
            createCsvCell(R.propOr('', 'phase', top)),
            createCsvCell(subproject || ''),
            createCsvCell(R.defaultTo('', protocolId)),
            createCsvCell(meeting || ''),
            createCsvCell(meetingType || ''),
            createCsvCell(R.compose(R.slice(0, 10), R.defaultTo(''))(meetingDate)),
            createCsvCell(R.defaultTo('', linkedOpp)),
        ],
    ),
);

export const generateCsv = async stack => {
    const { tops: sortedGroupedTops = {} } = store.getVar(...stack)(SORTED_GROUPED_TOPS_KEY);
    const topRows = R.compose(
        R.sortBy(R.prop('businessId')),
        R.values,
        R.map(R.last),
    )(sortedGroupedTops);

    const dashboardState = store.getVar(...stack)(DASHBOARD_STATE_KEY);
    const filter = store.getVar(...stack)(DASHBOARD_FILTER_KEY);
    const searchString = store.getVar(...stack)(SEARCH_STRING_KEY);

    const subprojectId = store.getVar(...stack)(SUBPROJECT_ID_KEY);
    const { [subprojectId]: subprojectBag } = store.getView(...stack)(
        constructNodeView(subprojectId),
    );
    const subprojectBusinessId = R.pathOr('', ['node', 'businessId'], subprojectBag);

    const projectId = store.getVar(...stack)(PROJECT_ID_KEY);
    const contactBags = getProjectContacts(stack, projectId);
    const user = await api.getCurrentUser().email;
    const userName = R.compose(
        R.pathOr(user, ['node', 'name']),
        selectContactBagWithEmail(user),
    )(contactBags);

    const statusses =
        projectId && R.compose(R.propOr([], 'oppStatusTypes'), store.getNode(...stack))(projectId);

    const { searchedTops } = filterTops({
        filter,
        statusses,
        searchString,
        dashboardState,
        subprojectBusinessId,
        userName,
        showTops: true,
    })(topRows);

    const allDashboardTops =
        dashboardState === 'subproject'
            ? applyFilter(filter, statusses)(searchAllTops(`#${subprojectBusinessId}`, topRows))
            : dashboardState === 'user'
            ? applyFilter(filter, statusses)(searchAllTops(`@${userName}`, topRows))
            : topRows;

    return getTopTable(await addLinkedOpps(stack)(searchedTops || allDashboardTops));
};

const constructTopOppView = topId =>
    topId
        ? {
              [topId]: {
                  edges: {
                      [TOP_ACTION_EDGE]: {
                          reverse: true,
                          as: 'opps',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

const addLinkedOpps = stack => async tops => {
    const { pull } = createGraph(...stack);
    const userContactTopsView = R.compose(
        R.mergeAll,
        R.map(top => constructTopOppView(top.top.id)),
    )(tops);
    await pull(userContactTopsView);
    const topsOppViewResult = store.getView(...stack)(userContactTopsView);
    const topsWithOpps = R.map(top => {
        const topId = top.top.id;
        const linkedOpp = R.compose(
            R.pathOr('', ['node', 'businessId']),
            R.head,
            R.values,
            R.pathOr({}, [topId, 'opps']),
        )(topsOppViewResult);
        return R.assoc('linkedOpp', linkedOpp, top);
    })(tops);
    return topsWithOpps;
};

export const downloadCsv = content => {
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const dataUri = R.compose(
            R.replace(/#/g, '%23'), // replace all # with %23 so that it doesnt kill the data URI when it appears
            encodeURI,
        )(content);
        link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${dataUri}`);
        link.setAttribute('download', 'tops.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};
