import React, { useCallback, useState } from 'react';
import * as R from 'ramda';
import { IoCheckmarkOutline } from 'react-icons/io5';
import { useModalState } from '../../hooks/useModal';
import { TextField } from '../../Components/Common/Fields';
import { ModalMobile } from '../Common/ModalMobile';
import {
    EDIT_ATTENDEE_MODAL_MOBILE,
    MEETING_ATTENDEE_EDGE,
    MEETING_ID_KEY,
    useGraph,
} from '../../common/hooks.config.new';
import { DropdownField } from '../../Components/Common/Dropdown';
import { Button } from '../../Components/Common/Controls';

export const EditAttendeeModalMobile = ({ stack }) => {
    const [isOpen, close] = useModalState(EDIT_ATTENDEE_MODAL_MOBILE);
    return (
        <ModalMobile name={EDIT_ATTENDEE_MODAL_MOBILE} stack={stack} accept>
            {isOpen ? <ModalContent stack={stack} close={close} /> : null}
        </ModalMobile>
    );
};

const ModalContent = ({ stack, close }) => {
    const { assoc, useVar } = useGraph(...stack);
    const [attendeeBag] = useModalState(EDIT_ATTENDEE_MODAL_MOBILE);
    const [localData, setLocalData] = useState(attendeeBag.metadata);
    const [meetingId] = useVar(MEETING_ID_KEY);
    const assocAttendee = useCallback(assoc(MEETING_ATTENDEE_EDGE)(meetingId), [meetingId]);

    return (
        <>
            <div className="modal-headline-mobile">
                Edit Attendee
                <div
                    className="modal-headline-filler"
                    style={{ backgroundColor: 'var(--corporate-color-14-ligh)' }}
                />
            </div>
            <TextField
                label="Comment"
                value={R.propOr('', 'comment', attendeeBag.metadata)}
                onChange={value => {
                    assocAttendee(
                        attendeeBag.node,
                        R.assoc('comment', value, attendeeBag.metadata),
                    );
                }}
                className="input-mobile"
                labelClass="label-mobile"
            />
            <DropdownField
                mobile
                label="Presence"
                value={localData.presence || R.propOr('', 'presence', attendeeBag.metadata)}
                options={[
                    { value: 'A', label: 'Attended' },
                    { value: 'P', label: 'Partly Attended' },
                    { value: 'N', label: 'Not Attended' },
                    { value: 'M', label: 'Minutes Keeper' },
                ]}
                onChange={option => {
                    setLocalData(R.assoc('presence', option.value, localData));
                    assocAttendee(
                        attendeeBag.node,
                        R.assoc('presence', option.value, attendeeBag.metadata),
                    );
                }}
                labelClass="dd-label-mobile"
            />
            <Button onClick={() => close()} className="modal-add-button zIndex10000">
                <IoCheckmarkOutline size="5.5rem" />
            </Button>
            <div style={{ height: '20vh' }} />
        </>
    );
};
