import * as R from 'ramda';
import { newId, newIsoDate, newMsDate } from '../common/util';
import {
    GROUPED_TOPS_KEY,
    MEETING_ATTENDEE_EDGE,
    MEETING_TOP_EDGE,
    PROJECT_ID_KEY,
    SORTED_GROUPED_TOPS_KEY,
    SUBPROJECT_ID_KEY,
    SUBPROJECT_MEETING_EDGE,
    TOP_ATTACHMENT_EDGE,
    TOP_RESPONSIBLE_EDGE,
    createGraph,
    store,
    useGraph,
} from '../common/hooks.config.new';
import { createDummyGraph } from '../common/got-adjunct';
import { getTopInfo } from '../common/topinfo.util';
import { useUserEmail } from './useUserEmail';
import { useContact } from './useContact';
import { getSubprojectChoices } from './useSubprojectChoices';
import { getAttachments } from './useAttachments';

const viewBody = {
    edges: {
        [TOP_ATTACHMENT_EDGE]: {
            include: {
                node: true,
                edges: true,
            },
        },
    },
};

const refreshTopAttachments = async (stack, topIds) => {
    const view = {};

    for (let i = 0; i < topIds.length; i += 1) {
        if (topIds[i]) {
            view[topIds[i]] = viewBody;
        }
    }

    const { pull } = createGraph(...stack);
    await pull(view);
};

export const getDummyMeetingId = subprojectId => `${subprojectId}-dummy-meeting`;

const originalTopSubproject = (businessId, tops) =>
    R.compose(
        R.dropLast(1),
        R.unnest,
        R.map(R.split(' [')),
        R.map(R.prop('subproject')),
        R.filter(pv => pv.meeting !== 'DUMMY'),
        R.values,
        R.prop(businessId),
    )(tops);

export const useCreateHistory = (
    stack,
    { setNewTops, dashboard = true, varUpdates = true } = {},
) => {
    const { useVar, useNode } = useGraph(...stack);

    const [projectId] = useVar(PROJECT_ID_KEY);
    const project = useNode(projectId);

    const user = useUserEmail();
    const { contact } = useContact(stack, projectId, user);

    const createHistory = async (tops, { meeting, newStatus, newPhase, newCategory } = {}) => {
        const subprojectId = store.getVar(...stack)(SUBPROJECT_ID_KEY);

        if (!contact) return;
        const author = {
            node: contact.node,
            nodeId: contact.nodeId,
            metadata: { presence: 'M', order: newMsDate() },
        };

        // load all attachments for tops
        await refreshTopAttachments(
            stack,
            R.map(top => top?.top?.id, tops),
        );

        // clone tops
        const editedTops = R.map(top => {
            const newTopId = newId();
            return { newId: newTopId, ...top };
        })(tops);
        setNewTops && setNewTops(editedTops);

        const { add, inheritRights, assoc, getGraph } = createDummyGraph();

        // TODO: maybe create meeting for every top (some meetings are just duplicates then)
        // since top neets to remain in it's subproject, which is not necessarily the selected one

        // use input meeting or create dummy meeting
        const newMeeting = meeting || {
            id: getDummyMeetingId(subprojectId),
            isDummy: true,
            title: 'DUMMY',
            closed: true,
            date: newIsoDate(),
        };
        add(SUBPROJECT_MEETING_EDGE)(subprojectId)(newMeeting);
        inheritRights(newMeeting.id)(subprojectId);

        // adding author contact as attendee
        assoc(MEETING_ATTENDEE_EDGE)(newMeeting.id)(author.node, {
            presence: 'M',
            order: newMsDate(),
        });

        const subprojectChoices = getSubprojectChoices(stack, projectId);
        let groupedProjectTops = store.getVar(...stack)(GROUPED_TOPS_KEY)?.tops;
        R.forEach(top => {
            // create new top nodes
            const newTop = R.compose(
                R.assoc('id', top.newId),
                R.assoc('modifiedDate', newIsoDate()),
                dashboard ? R.assoc('dashboard', true) : R.identity,
                newStatus !== undefined ? R.assoc('status', newStatus) : R.identity,
                newPhase !== undefined ? R.assoc('phase', newPhase) : R.identity,
                newCategory !== undefined ? R.assoc('category', newCategory) : R.identity,
            )(top.top);

            // add and inherit, assoc previous responsible
            add(MEETING_TOP_EDGE)(newMeeting.id)(newTop, { order: newMsDate() });
            inheritRights(top.newId)(subprojectId);
            top.responsible && assoc(TOP_RESPONSIBLE_EDGE)(top.newId)(top.responsible);

            // assoc previous attachments
            const attachmentBags = getAttachments(stack, top.top.id, TOP_ATTACHMENT_EDGE);
            attachmentBags.forEach(attachmentBag => {
                assoc(TOP_ATTACHMENT_EDGE)(newTop.id)(attachmentBag.node);
            });

            // update histories
            const businessId = R.prop('businessId', newTop);

            // find original subproject
            const originalSubproject = R.find(
                R.propEq('title', originalTopSubproject(businessId, groupedProjectTops)[0]),
                subprojectChoices,
            );

            // get top info to insert into groupedTops
            const topInfo = getTopInfo({
                project,
                subproject: originalSubproject, // TODO: other functions just used the selected subproject here
                meeting: newMeeting,
                attendees: [author],
                top: newTop,
                responsibles: { responsibleBag: { node: top.responsible } }, // simulating view edge bag
            });

            // update groupedTops
            groupedProjectTops = R.assocPath([businessId, top.newId], topInfo, groupedProjectTops);
        })(editedTops);

        // merge graph
        store.mergeGraph(getGraph(), R.last(stack));

        // set groupedTops var
        varUpdates && store.setVar(R.last(stack))(GROUPED_TOPS_KEY, { tops: groupedProjectTops });
        // sort groupedTops to update sortedGroupedTops
        const sortedGroupedTops = R.map(
            R.compose(
                R.sortBy(
                    R.compose(
                        entry =>
                            R.propOr(false, 'meetingDate', entry) ||
                            R.propOr(false, 'modifiedDate', entry) ||
                            R.propOr(false, 'createdDate', entry),
                    ),
                ),
                R.values(),
            ),
        )(groupedProjectTops);
        varUpdates &&
            store.setVar(R.last(stack))(SORTED_GROUPED_TOPS_KEY, { tops: sortedGroupedTops });
    };

    return createHistory;
};
