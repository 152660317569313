import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { useSpring, animated } from 'react-spring';
import { IoChevronDownOutline } from 'react-icons/io5';
import { useGraph } from '../../common/hooks.config.new';
import { useMeetingTypesChoices } from '../../hooks/useChoices';
import { useMeetingId } from '../../hooks/useMeetingId';
import { DropdownField } from '../../Components/Common/Dropdown';
import { DateField, TextField } from '../../Components/Common/Fields';

export const MeetingInformationsMobile = ({ stack, meeting, subprojectId, projectId }) => {
    const [showDetails, setShowDetails] = useState(!R.prop('businessId', meeting));
    const detailButton = useSpring({
        transform: showDetails ? 'rotate(-180deg)' : 'rotate(0deg)',
    });
    const { update: updateMeeting } = useGraph(...stack);
    const meetingTypesChoices = useMeetingTypesChoices(stack);

    useEffect(() => {
        if (meeting && meeting.id && !meeting.date) {
            // meeting loaded and has no date
            updateMeeting(R.assoc('date', new Date().toISOString(), meeting));
        }
    }, [meeting]);
    useMeetingId(stack, projectId, subprojectId, meeting, updateMeeting);
    return (
        <div className="meeting-informations-area-mobile">
            <div
                onClick={() => setShowDetails(!showDetails)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '0 2rem 0 2rem',
                }}
            >
                <div
                    className="modal-headline-mobile"
                    style={{ marginLeft: 0, fontSize: '4.5rem' }}
                >
                    Informations
                </div>
                <animated.div style={detailButton}>
                    <IoChevronDownOutline size="4.5rem" color="var(--corporate-color-2)" />
                </animated.div>
            </div>
            {showDetails && (
                <div className="meeting-informations-mobile fader">
                    <DropdownField
                        mobile
                        className="infront"
                        label="Type"
                        value={R.propOr('', 'type', meeting)}
                        options={meetingTypesChoices}
                        onChange={option => updateMeeting(R.assoc('type', option.value, meeting))}
                        labelClass="dd-label-mobile"
                    />
                    <TextField
                        disabled
                        label="Meeting ID"
                        value={R.propOr('', 'businessId', meeting)}
                        onChange={() => {}}
                        className="input-mobile"
                        labelClass="label-mobile"
                    />
                    {!R.prop('businessId', meeting) && (
                        <p className="dashboard-message-mobile">
                            Select a meeting type to edit this meeting
                        </p>
                    )}
                    {R.prop('businessId', meeting) && (
                        <>
                            <TextField
                                fieldDivStyle={{ flex: 0.5 }}
                                label="Title"
                                value={R.propOr('', 'title', meeting)}
                                onChange={value => updateMeeting(R.assoc('title', value, meeting))}
                                className="input-mobile"
                                labelClass="label-mobile"
                            />
                            <TextField
                                label="Location"
                                value={R.propOr('', 'location', meeting)}
                                onChange={value =>
                                    updateMeeting(R.assoc('location', value, meeting))
                                }
                                className="input-mobile"
                                labelClass="label-mobile"
                            />
                            <div className="mobile-dates">
                                <DateField
                                    className="mobile"
                                    containerStyle={{ display: 'flex', flex: 1 }}
                                    labelClass="date-label-mobile"
                                    label="Date"
                                    value={R.prop('date', meeting)}
                                    onChange={value =>
                                        updateMeeting(R.assoc('date', value, meeting))
                                    }
                                    mobile
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
