import * as R from 'ramda';
import React, { useCallback, useMemo } from 'react';
import { arrayMove } from '@dnd-kit/sortable';
import { setOrder } from '../../../common/got-adjunct';
import { MEETING_ATTENDEE_EDGE, useGraph } from '../../../common/hooks.config.new';
import { Attendee } from './Attendee';
import { useMeetingAttendees } from '../../../hooks/useMeetingAttendees';
import { AttendeeListRow } from '../../Common/ListRows/AttendeeListRow';
import { SortableItem, SortableList } from '../../Common/SortableList';

export const MeetingAttendees = ({ stack, meetingId, showAttendees }) => {
    const { assoc, dissoc, mergeGraph } = useGraph(...stack);
    const sortedAttendeeBags = useMeetingAttendees(stack, meetingId);
    const filteredAttendeesBags = R.filter(R.path(['node', 'id']))(sortedAttendeeBags);

    const assocAttendee = useCallback(assoc(MEETING_ATTENDEE_EDGE)(meetingId), [meetingId]);
    const dissocAttendee = useCallback(dissoc(MEETING_ATTENDEE_EDGE)(meetingId), [meetingId]);

    const sortedAttendeeNodes = useMemo(
        () => R.map(R.prop('node'), filteredAttendeesBags),
        [filteredAttendeesBags],
    );

    const move = (oldIndex, newIndex) => {
        R.compose(
            mergeGraph,
            setOrder(MEETING_ATTENDEE_EDGE)(meetingId),
            R.map(R.prop('id')),
            items => arrayMove(items, oldIndex, newIndex),
        )(sortedAttendeeNodes);
    };

    if (showAttendees) {
        return (
            <div className="fader" style={{ width: '100%' }}>
                <SortableList
                    items={sortedAttendeeNodes}
                    onMove={move}
                    renderItem={(node, index) => {
                        const { node: attendee, metadata } = filteredAttendeesBags[index];
                        return (
                            <SortableItem id={node.id}>
                                <AttendeeListRow onRemove={() => dissocAttendee(attendee)}>
                                    <div className="attendee-row">
                                        <Attendee
                                            attendee={attendee}
                                            metadata={metadata}
                                            assocAttendee={assocAttendee}
                                        />
                                    </div>
                                </AttendeeListRow>
                            </SortableItem>
                        );
                    }}
                />
            </div>
        );
    }

    return null;
};
