import React, { useState } from 'react';
import * as R from 'ramda';
import { BsViewList } from 'react-icons/bs';
import { useHistory } from 'react-router';
import {
    IoAnalyticsOutline,
    IoArrowForwardOutline,
    IoCloseOutline,
    IoCreateOutline,
    IoDocumentAttachOutline,
    IoLinkOutline,
    IoMailOutline,
    IoMenuOutline,
    IoResizeOutline,
    IoShareSocialOutline,
    IoTrailSignOutline,
    IoUnlinkOutline,
} from 'react-icons/io5';
import {
    DASHBOARD_STATE_KEY,
    ORGANIZATION_ID_KEY,
    PROJECT_ID_KEY,
    PROJECT_MILESTONES_MODAL,
    SEARCH_STRING_KEY,
    SUBPROJECT_ID_KEY,
    TOPS_TO_UPDATE_KEY,
    TOP_LINKS_MODAL,
    useGraph,
} from '../../common/hooks.config.new';
import { useModal } from '../../hooks/useModal';
import { Button, FancyCheckbox } from '../Common/Controls';
import { useNode } from '../../hooks/useNode';

export const TopButtonRow = ({
    top,
    responsible,
    previousVersions,
    canAdminProject,
    canWriteProject,
    subprojectId,
    stack,
    editMessage,
    userName,
    scorecard,
    dissocAction,
    oppId,
    actionId,
    actions,
    opp,
    assocAction,
    noEdit,
    global,
    editOnly,
    oppTracking,
    kanban,
    meetingId,
    protocolId,
    showTop,
    milestone,
    connectPredecessor,
    connectSuccessor,
    connectMode,
    onAttachmentClick,
}) => {
    // TODO set vars on main only
    const { useVar } = useGraph(R.head(stack));
    const [topsToUpdate, setTopsToUpdate] = useVar(TOPS_TO_UPDATE_KEY);
    const topType = scorecard ? top?.top?.top?.type : top?.type;
    const [menu, setMenu] = useState(false);
    return (
        <div
            className="dashboard-top-button-row"
            style={{ paddingRight: 1, alignItems: menu && 'flex-start' }}
        >
            {!global && (
                <div
                    className="top-business-id"
                    style={{
                        color: 'var(--corporate-color-1)',
                        height: 30,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {top.businessId || R.pathOr('', ['top', 'top', 'businessId'], top)}
                </div>
            )}
            {global && <GlobalTopButtons stack={stack} top={top} />}
            {opp && !connectMode && (
                <Button
                    onClick={() => {
                        assocAction(opp);
                    }}
                    className="assoc-button"
                    style={{ height: 30, justifyContent: 'flex-end' }}
                >
                    <IoLinkOutline size={23} />
                    <p>Add to scorecard</p>
                </Button>
            )}
            {(connectSuccessor || connectPredecessor) && (
                <div style={{ display: 'flex' }}>
                    <ConnectPredecessorButton top={top} connectPredecessor={connectPredecessor} />
                    <ConnectSuccessorButton top={top} connectSuccessor={connectSuccessor} />
                </div>
            )}
            {editOnly && (
                <FancyCheckbox
                    style={{ color: 'var(--corporate-color-1)' }}
                    customHeight={40}
                    label="Add to Minute"
                    onChange={value =>
                        value
                            ? setTopsToUpdate(
                                  R.assoc(
                                      top.id,
                                      { top, responsible, previousVersions },
                                      topsToUpdate,
                                  ),
                              )
                            : setTopsToUpdate(R.dissoc(top.id, topsToUpdate))
                    }
                    value={R.propOr(false, top.id, topsToUpdate)}
                />
            )}
            {!menu && !global && !editOnly && !opp
                ? !showTop && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                          {!noEdit && (
                              <FancyCheckbox
                                  style={{ color: 'var(--corporate-color-1)' }}
                                  containerStyle={{
                                      flexDirection: 'row-reverse',
                                      justifyContent: 'space-between',
                                  }}
                                  placeholderStyle={{
                                      marginLeft: 6,
                                      color: R.has(top.id, topsToUpdate)
                                          ? 'var(--corporate-color-13)'
                                          : 'var(--corporate-color-1-light)',
                                      transition: '0.4s ease',
                                  }}
                                  customHeight={40}
                                  label="Edit"
                                  onChange={value => {
                                      if (value) {
                                          setTopsToUpdate(
                                              R.assoc(
                                                  top.id,
                                                  { top, responsible, previousVersions },
                                                  topsToUpdate,
                                              ),
                                          );
                                      } else {
                                          setTopsToUpdate(R.dissoc(top.id, topsToUpdate));
                                      }
                                  }}
                                  value={R.propOr(false, top.id, topsToUpdate)}
                              />
                          )}
                          {!noEdit && (
                              <Button onClick={() => setMenu(true)} className="top-menu-button">
                                  <IoMenuOutline size={21} />
                              </Button>
                          )}
                      </div>
                  )
                : !global &&
                  !editOnly &&
                  !opp && (
                      <div className="top-menu-container">
                          <TopMenu
                              canAdminProject={canAdminProject}
                              canWriteProject={canWriteProject}
                              subprojectId={subprojectId}
                              editMessage={editMessage}
                              userName={userName}
                              scorecard={scorecard}
                              dissocAction={dissocAction}
                              oppId={oppId}
                              actionId={actionId}
                              actions={actions}
                              opp={opp}
                              assocAction={assocAction}
                              noEdit={noEdit}
                              oppTracking={oppTracking}
                              kanban={kanban}
                              meetingId={meetingId}
                              protocolId={protocolId}
                              milestone={milestone}
                              setMenu={setMenu}
                              top={top}
                              topType={topType}
                              responsible={responsible}
                              previousVersions={previousVersions}
                              setTopsToUpdate={setTopsToUpdate}
                              topsToUpdate={topsToUpdate}
                          />
                      </div>
                  )}
            {onAttachmentClick && (
                <Button onClick={onAttachmentClick} style={{ marginRight: 24, height: 30 }}>
                    <IoDocumentAttachOutline size={23} color="var(--corporate-color-1)" />
                    <p>Files</p>
                </Button>
            )}
        </div>
    );
};

const TopMenu = ({
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
    scorecard,
    dissocAction,
    oppId,
    actionId,
    actions,
    opp,
    assocAction,
    noEdit,
    oppTracking,
    kanban,
    meetingId,
    protocolId,
    milestone,
    setMenu,
    top,
    topType,
    responsible,
    previousVersions,
}) => {
    const openScorecardModal = useModal('scoreCard');
    const openEditTopsModal = useModal('editTops');
    const openEditScorecardTopModal = useModal('editScorecardTop');
    const openReminderModal = useModal('reminder');
    const openProjectOppsModal = useModal('project-opps');
    const openProjectActionsModal = useModal('project-actions');
    const openShowTopModal = useModal('showTopModal');
    const openProjectMilestonesModal = useModal(PROJECT_MILESTONES_MODAL);
    const openMeetingPdfModal = useModal('protocol-pdf');
    const openScEditFtBd = t => {
        openEditScorecardTopModal({ top: t });
    };
    return (
        <div className="top-menu" style={{ width: 175 }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Button
                    onClick={() => setMenu(false)}
                    style={{
                        height: 30,
                        color: 'var(--corporate-color-12)',
                        width: 175,
                        justifyContent: 'space-between',
                    }}
                >
                    <IoCloseOutline size={22} />
                    <p>Close menu</p>
                </Button>
                {kanban && (
                    <Button
                        style={{ width: 175, justifyContent: 'space-between' }}
                        onClick={() => {
                            openShowTopModal({
                                top,
                                meetingId,
                                protocolId,
                            });
                            setMenu(false);
                        }}
                    >
                        <IoResizeOutline size={20} />
                        <p>Show full item</p>
                    </Button>
                )}
                <Button
                    style={{ width: 175, justifyContent: 'space-between' }}
                    onClick={() => {
                        openMeetingPdfModal({ meetingId, topId: top.id });
                        setMenu(false);
                    }}
                >
                    <IoDocumentAttachOutline size={20} />
                    <p>Show attachments</p>
                </Button>
                {topType === 'o' && oppTracking && (
                    <>
                        {((!scorecard && !opp) || milestone) && (
                            <Button
                                onClick={() => {
                                    setMenu(false);
                                    openScorecardModal({ id: top.id });
                                }}
                                style={{ height: 30, width: 175, justifyContent: 'space-between' }}
                            >
                                <IoAnalyticsOutline size={22} />
                                <p>Scorecard</p>
                            </Button>
                        )}
                        {canWriteProject && !noEdit && (
                            <Button
                                onClick={() => {
                                    setMenu(false);
                                    openProjectActionsModal({ top });
                                }}
                                style={{ height: 30, width: 175, justifyContent: 'space-between' }}
                            >
                                <IoLinkOutline size={23} />
                                <p>Link actions</p>
                            </Button>
                        )}
                    </>
                )}
                <Button
                    onClick={() => {
                        setMenu(false);
                        openProjectMilestonesModal({ id: top.id });
                    }}
                    style={{ height: 30, width: 175, justifyContent: 'space-between' }}
                >
                    <IoTrailSignOutline size={22} />
                    <p>Link milestone</p>
                </Button>
                {canWriteProject && topType !== 'o' && !noEdit && oppTracking && (
                    <Button
                        onClick={() => {
                            setMenu(false);
                            openProjectOppsModal({ id: top.id, top });
                        }}
                        style={{ height: 30, width: 175, justifyContent: 'space-between' }}
                    >
                        <IoLinkOutline size={23} />
                        <p>Link opportunity</p>
                    </Button>
                )}
                {canAdminProject && !noEdit && (
                    <Button
                        style={{ height: 30, width: 175, justifyContent: 'space-between' }}
                        onClick={() => {
                            setMenu(false);
                            subprojectId
                                ? scorecard
                                    ? openScEditFtBd(top)
                                    : openEditTopsModal({
                                          id: top.id,
                                          top: { top, responsible, previousVersions },
                                      })
                                : editMessage();
                        }}
                    >
                        <IoCreateOutline size={21} />
                        <p>Edit item</p>
                    </Button>
                )}
                {opp && (
                    <Button
                        onClick={() => {
                            setMenu(false);
                            assocAction(opp);
                        }}
                        className="assoc-button"
                        style={{ height: 30, width: 175, justifyContent: 'flex-end' }}
                    >
                        <IoLinkOutline size={23} />
                        <p>Add to scorecard</p>
                    </Button>
                )}
                {canAdminProject && (
                    <Button
                        onClick={() => {
                            setMenu(false);
                            openReminderModal({
                                top,
                                responsible,
                                userName,
                            });
                        }}
                        style={{ height: 30, width: 175, justifyContent: 'space-between' }}
                    >
                        <IoMailOutline size={22} />
                        <p>Send reminder</p>
                    </Button>
                )}
                {canWriteProject && scorecard && actions && (
                    <Button
                        onClick={() => {
                            setMenu(false);
                            dissocAction(oppId)({ id: actionId });
                        }}
                        className="dissoc-button"
                        style={{ height: 30, width: 175, justifyContent: 'space-between' }}
                    >
                        <IoUnlinkOutline size={23} />
                        <p>Remove</p>
                    </Button>
                )}
                <LinkedTopsButton top={top} setMenu={setMenu} />
            </div>
        </div>
    );
};

const GlobalTopButtons = ({ stack, top }) => {
    const { useVar } = useGraph(...stack);
    const { node: project } = useNode(stack, top.projectId);
    const [, setOrganizationId] = useVar(ORGANIZATION_ID_KEY);
    const [, setProjectId] = useVar(PROJECT_ID_KEY);
    const [, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [, setSearchString] = useVar(SEARCH_STRING_KEY);
    const [, setDashboardState] = useVar(DASHBOARD_STATE_KEY);
    const history = useHistory();
    return (
        <div
            style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
            }}
        >
            <div
                className="top-business-id font-weight-bold"
                style={{
                    color: 'var(--corporate-color-1)',
                    flex: 1,
                    justifyContent: 'flex-start',
                }}
            >
                {R.propOr('', 'title', project)}
            </div>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    style={{ height: 30 }}
                    onClick={() => {
                        setOrganizationId(top.orgId);
                        setProjectId(top.projectId);
                        setSubprojectId(undefined);
                        setDashboardState('project');
                        setSearchString(top.node.description);
                        history.push('dashboard');
                    }}
                >
                    <BsViewList size={20} />
                    <p>
                        Show{' '}
                        {top.node.type === 'a'
                            ? 'Action'
                            : top.node.type === 'o'
                            ? 'Opportunity'
                            : ''}{' '}
                        in project dashboard
                    </p>
                </Button>
                <Button
                    style={{ height: 30 }}
                    onClick={() => {
                        setOrganizationId(top.orgId);
                        setProjectId(top.projectId);
                        setSubprojectId(undefined);
                        setDashboardState('project');
                        history.push('dashboard');
                    }}
                >
                    <IoShareSocialOutline size={22} />
                    <p>Open project dashboard</p>
                </Button>
            </div>
        </div>
    );
};

const LinkedTopsButton = ({ top, setMenu }) => {
    const openModal = useModal(TOP_LINKS_MODAL);

    if (top.type === 'a') {
        return (
            <Button
                onClick={() => {
                    setMenu(false);
                    openModal(top);
                }}
                style={{ height: 30, width: 175, justifyContent: 'space-between' }}
            >
                <IoLinkOutline size={23} />
                <p>Connected actions</p>
            </Button>
        );
    }

    return null;
};

const ConnectPredecessorButton = ({ top, connectPredecessor }) => {
    if (top.type === 'a' && connectPredecessor) {
        return (
            <Button
                onClick={() => connectPredecessor({ top })}
                style={{ height: 30, justifyContent: 'space-between' }}
                className="background-color-var-cc13-light-hover"
            >
                <IoArrowForwardOutline size={18} />
                <IoLinkOutline size={23} />
                <p>Connect as predecessor</p>
            </Button>
        );
    }

    return null;
};

const ConnectSuccessorButton = ({ top, connectSuccessor }) => {
    if (top.type === 'a' && connectSuccessor) {
        return (
            <Button
                onClick={() => connectSuccessor({ top })}
                style={{ height: 30, justifyContent: 'space-between', marginLeft: 9 }}
                className="background-color-var-cc14-light-hover"
            >
                <IoLinkOutline size={23} />
                <IoArrowForwardOutline size={18} />
                <p>Connect as successor</p>
            </Button>
        );
    }

    return null;
};
