/* eslint-disable no-continue */
import { useMemo } from 'react';
import { useActionStatuses } from './useActionStatuses';

const countActionStatuses = (actions, statusTypes) => {
    if (!actions || !statusTypes) return { labels: [], data: [] };

    const statusCounts = {};
    // foreach business id
    for (let i = 0; i < actions.length; i += 1) {
        const action = actions[i];
        if (action?.topType !== 'a' || !action?.businessId) continue;

        const status = action?.top?.status;
        if (!status) continue;

        // count status
        statusCounts[status] = (statusCounts[status] || 0) + 1;
    }

    // create array of same order as statusTypes
    const counts = new Array(statusTypes.length);
    for (let i = 0; i < statusTypes.length; i += 1) {
        counts[i] = statusCounts[statusTypes[i]] || 0;
    }
    return counts;
};

export const useActionStatusesDonut = (stack, actions) => {
    const statusTypes = useActionStatuses(stack);

    const statusCounts = useMemo(
        () => countActionStatuses(actions, statusTypes),
        [actions, statusTypes],
    );

    return {
        labels: statusTypes,
        data: statusCounts,
    };
};
