import React, { useEffect } from 'react';
import * as R from 'ramda';
import {
    IoArrowForwardOutline,
    IoCloseOutline,
    IoEllipsisVertical,
    IoTrashOutline,
} from 'react-icons/io5';
import { FaSortAmountDown, FaSortAmountDownAlt } from 'react-icons/fa';
import { useCloseModal, useModalState } from '../../../hooks/useModal';
import {
    DASHBOARD_FILTER_KEY,
    FILTER_BAR,
    OPP_TRACKING,
    SEARCH_STRING_KEY,
    useGraph,
} from '../../../common/hooks.config.new';
import {
    PROJECT_ACTION_STATUS_TYPE_CHOICES,
    PROJECT_CONTACT_CHOICES,
    PROJECT_OPP_CATEGORY_TYPE_CHOICES,
    PROJECT_OPP_STATUS_TYPE_MILESTONES_CHOICES,
    PROJECT_PHASE_CHOICES,
    useMeetingTypesChoices,
    useProjectChoices,
} from '../../../hooks/useChoices';
import { DateField } from '../../Common/Fields';
import { Button, FancyCheckbox } from '../../Common/Controls';
import { DropdownField, MultiDropdownField } from '../../Common/Dropdown';
import { SearchBar } from './SearchBar';

const topTypeChoices = R.map(type => ({ value: R.head(type).toLowerCase(), label: type }))([
    'Actions',
    'Opportunities',
    'Risks',
    'Statements',
    'Informations',
    'Decisions',
]);
const sortTypeChoices = R.map(type => ({ value: type, label: type }))([
    'None',
    'Meeting date',
    'Due date',
    'Status',
]);

export const DashboardFilterBanner = ({ stack, protocol }) => {
    const [isOpen] = useModalState(FILTER_BAR);
    if (isOpen) {
        return <Content stack={stack} protocol={protocol} />;
    }

    return null;
};

const Content = ({ stack, protocol }) => {
    const { useVar } = useGraph(...stack);

    const meetingTypesChoices = useMeetingTypesChoices(stack);
    const { choices: actionStatusTypeChoices } = useProjectChoices(
        stack,
        PROJECT_ACTION_STATUS_TYPE_CHOICES,
    );
    const { choices: contactChoices } = useProjectChoices(stack, PROJECT_CONTACT_CHOICES);
    const { choices: oppCategoryTypeChoices } = useProjectChoices(
        stack,
        PROJECT_OPP_CATEGORY_TYPE_CHOICES,
    );
    const { choices: oppStatusTypeChoices } = useProjectChoices(
        stack,
        PROJECT_OPP_STATUS_TYPE_MILESTONES_CHOICES,
    );
    const { choices: projectPhaseChoices } = useProjectChoices(stack, PROJECT_PHASE_CHOICES);

    const [, setSearchString] = useVar(SEARCH_STRING_KEY);
    const [filter, _setFilter] = useVar(DASHBOARD_FILTER_KEY);
    const [oppTracking] = useVar(OPP_TRACKING);
    const close = useCloseModal(FILTER_BAR);
    const setFilter = val => {
        if (R.is(Function, val)) {
            _setFilter(val(filter));
        } else {
            _setFilter(val);
        }
    };
    useEffect(() => {
        !filter && setFilter({});
    }, [filter]);

    return (
        <div
            className={
                protocol ? 'protocol-screen-filter-banner' : 'dashboard-screen-filter-banner'
            }
            style={{ height: 'auto', flexWrap: 'wrap' }}
        >
            {protocol && (
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                        marginBottom: 12,
                    }}
                >
                    <div style={{ flex: 1 }} />
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                        <SearchBar stack={stack} />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <Button style={{ marginLeft: 3 }} onClick={() => setSearchString('')}>
                            <IoTrashOutline size={17} />
                        </Button>
                    </div>
                </div>
            )}
            <div className="subrow-container">
                <div className="subrow">
                    <div style={{ marginBottom: 6, display: 'flex', alignItems: 'center' }}>
                        <p
                            style={{
                                fontSize: 15,
                                color: 'var(--corporate-color-1)',
                            }}
                            className="font-weight-100"
                        >
                            Sorting
                        </p>
                        <Button
                            style={{ color: 'var(--corporate-color-1-light2)', marginLeft: 3 }}
                            onClick={() => {
                                setFilter(R.dissoc('sort', filter));
                            }}
                        >
                            <IoTrashOutline size={16} />
                        </Button>
                    </div>
                    <DropdownField
                        label="Sort by"
                        value={R.pathOr('None', ['sort', 'type', 'value'], filter)}
                        editable
                        options={sortTypeChoices}
                        onChange={value => setFilter(R.assocPath(['sort', 'type'], value))}
                    />
                    <SortButton
                        value={R.path(['sort', 'direction'], filter)}
                        onChange={value => setFilter(R.assocPath(['sort', 'direction'], value))}
                    />
                </div>
                <div className="subrow">
                    <div style={{ marginBottom: 6, display: 'flex', alignItems: 'center' }}>
                        <p
                            style={{
                                fontSize: 15,
                                color: 'var(--corporate-color-1)',
                            }}
                            className="font-weight-100"
                        >
                            Dates
                        </p>
                        <Button
                            style={{ color: 'var(--corporate-color-1-light2)', marginLeft: 3 }}
                            onClick={() => {
                                setFilter({ ...filter, meetingDate: {}, dueDate: {} });
                            }}
                        >
                            <IoTrashOutline size={16} />
                        </Button>
                    </div>
                    <div className="subrow-container" style={{ alignItems: 'center' }}>
                        <DateField
                            className="small"
                            label="Meeting from"
                            value={R.path(['meetingDate', 'from'], filter)}
                            onChange={value =>
                                setFilter(R.assocPath(['meetingDate', 'from'], value))
                            }
                        />
                        <IoArrowForwardOutline style={{ margin: '0 12 12 8' }} />
                        <DateField
                            className="small"
                            label="Meeting to"
                            value={R.path(['meetingDate', 'to'], filter)}
                            onChange={value => setFilter(R.assocPath(['meetingDate', 'to'], value))}
                        />
                    </div>
                    <div className="subrow-container" style={{ alignItems: 'center' }}>
                        <DateField
                            className="small"
                            label="Due from"
                            value={R.path(['dueDate', 'from'], filter)}
                            onChange={value => setFilter(R.assocPath(['dueDate', 'from'], value))}
                        />
                        <IoArrowForwardOutline style={{ margin: '0 12 12 8' }} />
                        <DateField
                            className="small"
                            label="Due to"
                            value={R.path(['dueDate', 'to'], filter)}
                            onChange={value => setFilter(R.assocPath(['dueDate', 'to'], value))}
                        />
                    </div>
                </div>
                <div className="subrow">
                    <div style={{ marginBottom: 6, display: 'flex', alignItems: 'center' }}>
                        <p
                            style={{
                                fontSize: 15,
                                color: 'var(--corporate-color-1)',
                            }}
                            className="font-weight-100"
                        >
                            Responsibles
                        </p>
                        <Button
                            style={{ color: 'var(--corporate-color-1-light2)', marginLeft: 3 }}
                            onClick={() => {
                                setFilter(R.dissoc('responsible', filter));
                            }}
                        >
                            <IoTrashOutline size={16} />
                        </Button>
                    </div>
                    <MultiDropdownField
                        label="Responsible..."
                        options={contactChoices}
                        values={R.pathOr([], ['responsible', 'values'], filter)}
                        onChange={selectedOptions =>
                            setFilter(R.assocPath(['responsible', 'values'], selectedOptions))
                        }
                    />
                </div>
                <div className="subrow">
                    <div style={{ marginBottom: 6, display: 'flex', alignItems: 'center' }}>
                        <p
                            style={{
                                fontSize: 15,
                                color: 'var(--corporate-color-1)',
                            }}
                            className="font-weight-100"
                        >
                            Phases
                        </p>
                        <Button
                            style={{ color: 'var(--corporate-color-1-light2)', marginLeft: 3 }}
                            onClick={() => {
                                setFilter(R.dissoc('phase', filter));
                            }}
                        >
                            <IoTrashOutline size={16} />
                        </Button>
                    </div>
                    <MultiDropdownField
                        label="Phase..."
                        options={projectPhaseChoices}
                        values={R.pathOr([], ['phase', 'values'], filter)}
                        onChange={selectedOptions =>
                            setFilter(R.assocPath(['phase', 'values'], selectedOptions))
                        }
                    />
                </div>
                <div className="subrow">
                    <div style={{ marginBottom: 6, display: 'flex', alignItems: 'center' }}>
                        <p
                            style={{
                                fontSize: 15,
                                color: 'var(--corporate-color-1)',
                            }}
                            className="font-weight-100"
                        >
                            Meetings
                        </p>
                        <Button
                            style={{ color: 'var(--corporate-color-1-light2)', marginLeft: 3 }}
                            onClick={() => {
                                setFilter({
                                    ...filter,
                                    meetingType: {},
                                });
                            }}
                        >
                            <IoTrashOutline size={16} />
                        </Button>
                    </div>
                    <div className="subrow">
                        <div style={{ display: 'flex' }}>
                            <MultiDropdownField
                                label="Type..."
                                options={meetingTypesChoices}
                                values={R.pathOr([], ['meetingType', 'values'], filter)}
                                onChange={selectedOptions =>
                                    setFilter(
                                        R.assocPath(['meetingType', 'values'], selectedOptions),
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="subrow">
                    <div style={{ marginBottom: 6, display: 'flex', alignItems: 'center' }}>
                        <p
                            style={{
                                fontSize: 15,
                                color: 'var(--corporate-color-1)',
                            }}
                            className="font-weight-100"
                        >
                            Agenda Items
                        </p>
                        <Button
                            style={{ color: 'var(--corporate-color-1-light2)', marginLeft: 3 }}
                            onClick={() => {
                                setFilter({
                                    ...filter,
                                    topType: {},
                                });
                            }}
                        >
                            <IoTrashOutline size={16} />
                        </Button>
                    </div>
                    <div className="subrow">
                        <div style={{ display: 'flex' }}>
                            <MultiDropdownField
                                label="Type..."
                                options={topTypeChoices}
                                values={R.pathOr([], ['topType', 'values'], filter)}
                                onChange={selectedOptions =>
                                    setFilter(R.assocPath(['topType', 'values'], selectedOptions))
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="subrow">
                    <div style={{ marginBottom: 6, display: 'flex', alignItems: 'center' }}>
                        <p
                            style={{
                                fontSize: 15,
                                color: 'var(--corporate-color-1)',
                            }}
                            className="font-weight-100"
                        >
                            Actions
                        </p>
                        <Button
                            style={{ color: 'var(--corporate-color-1-light2)', marginLeft: 3 }}
                            onClick={() => {
                                setFilter({
                                    ...filter,
                                    actionStatus: {},
                                });
                            }}
                        >
                            <IoTrashOutline size={16} />
                        </Button>
                    </div>
                    <div className="subrow">
                        <div style={{ display: 'flex' }}>
                            <MultiDropdownField
                                label="Status..."
                                options={actionStatusTypeChoices}
                                values={R.pathOr([], ['actionStatus', 'values'], filter)}
                                onChange={selectedOptions =>
                                    setFilter(
                                        R.assocPath(['actionStatus', 'values'], selectedOptions),
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="subrow">
                    <div style={{ marginBottom: 6, display: 'flex', alignItems: 'center' }}>
                        <p
                            style={{
                                fontSize: 15,
                                color: 'var(--corporate-color-1)',
                            }}
                            className="font-weight-100"
                        >
                            Opportunities
                        </p>
                        <Button
                            style={{ color: 'var(--corporate-color-1-light2)', marginLeft: 3 }}
                            onClick={() => {
                                setFilter({
                                    ...filter,
                                    oppStatus: {},
                                    category: {},
                                    isCritical: false,
                                });
                            }}
                        >
                            <IoTrashOutline size={16} />
                        </Button>
                    </div>
                    <div className="subrow">
                        <div style={{ display: 'flex' }}>
                            {oppTracking && (
                                <>
                                    <MultiDropdownField
                                        label="Category..."
                                        options={oppCategoryTypeChoices}
                                        values={R.pathOr([], ['category', 'values'], filter)}
                                        onChange={selectedOptions =>
                                            setFilter(
                                                R.assocPath(
                                                    ['category', 'values'],
                                                    selectedOptions,
                                                ),
                                            )
                                        }
                                    />
                                    <MultiDropdownField
                                        label="Status..."
                                        options={oppStatusTypeChoices}
                                        values={R.pathOr([], ['oppStatus', 'values'], filter)}
                                        onChange={selectedOptions =>
                                            setFilter(
                                                R.assocPath(
                                                    ['oppStatus', 'values'],
                                                    selectedOptions,
                                                ),
                                            )
                                        }
                                    />
                                </>
                            )}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                paddingRight: 12,
                            }}
                        >
                            <FancyCheckbox
                                style={{ color: 'var(--corporate-color-1)' }}
                                customHeight={40}
                                label="Critical Opps"
                                onChange={() => {
                                    setFilter({ ...filter, isCritical: !filter.isCritical });
                                }}
                                value={R.propOr(false, 'isCritical', filter)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: !protocol ? '12 0 12 0' : '12 0 0 0',
                }}
            >
                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <FancyCheckbox
                        style={{ color: 'var(--corporate-color-1)' }}
                        containerStyle={{ marginRight: 12 }}
                        customHeight={40}
                        label="Show histories"
                        onChange={() => {
                            setFilter({ ...filter, showHistories: !filter.showHistories });
                        }}
                        value={R.propOr(false, 'showHistories', filter)}
                    />
                    {!protocol && (
                        <FancyCheckbox
                            style={{ color: 'var(--corporate-color-1)' }}
                            customHeight={40}
                            label="Show items"
                            onChange={() => {
                                setFilter({ ...filter, showTops: !filter.showTops });
                            }}
                            value={R.propOr(false, 'showTops', filter)}
                        />
                    )}
                </div>
                <div
                    style={{
                        width: 0,
                        height: 30,
                        borderLeft: '1px solid var(--corporate-color-1-light2)',
                        margin: '0 12 0 18',
                    }}
                />
                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        style={{ color: 'var(--corporate-color-10)' }}
                        onClick={() => {
                            setFilter({});
                            setSearchString('');
                            !protocol && close();
                        }}
                    >
                        <IoTrashOutline size={18} />
                        <p>Clear filter</p>
                    </Button>
                    {!protocol && (
                        <Button onClick={() => close()} style={{ marginLeft: 6 }}>
                            <IoCloseOutline size={22} />
                            <p style={{ marginLeft: 3 }}>Hide filter</p>
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export const SortButton = ({ value = 'asc', onChange, style, iconSize, containerStyle }) => (
    <Button
        onClick={() => {
            !value && onChange('desc');
            value === 'desc' && onChange('asc');
            value === 'asc' && onChange('');
        }}
        style={containerStyle}
    >
        {value === '' && (
            <div className="button-icon" style={style}>
                <IoEllipsisVertical size={iconSize || 20} />
                <p style={{ fontSize: 'inherit', marginLeft: '1rem' }}>No Sorting</p>
            </div>
        )}
        {value === 'asc' && (
            <div className="button-icon" style={style}>
                <FaSortAmountDownAlt size={iconSize || 20} />
                <p style={{ fontSize: 'inherit', marginLeft: '1rem' }}>Ascending</p>
            </div>
        )}
        {value === 'desc' && (
            <div className="button-icon" style={style}>
                <FaSortAmountDown size={iconSize || 20} />
                <p style={{ fontSize: 'inherit', marginLeft: '1rem' }}>Descending</p>
            </div>
        )}
    </Button>
);
