import React, { useMemo, useState } from 'react';
import * as R from 'ramda';
import { IoAddOutline, IoSearchOutline } from 'react-icons/io5';
import {
    useGraph,
    CREATE_DASHBOARD_TOP_MODAL_MOBILE,
    TOPS_TO_UPDATE_KEY,
    DASHBOARD_FILTER_KEY,
    SORTED_GROUPED_TOPS_KEY,
    MEETING_TOP_EDGE,
    TOP_RESPONSIBLE_EDGE,
    PROTOCOL,
    store,
    PROJECT_ID_KEY,
    SEARCH_STRING_KEY,
    MEETING_ID_KEY,
    SUBPROJECT_ID_KEY,
} from '../../common/hooks.config.new';
import { useModalState } from '../../hooks/useModal';
import { createDummyGraph, createOfflinePush } from '../../common/got-adjunct';
import { useToast } from '../../hooks/useToast';
import { filterTops } from '../../common/filteredtops.util';
import { useNode } from '../../hooks/useNode';
import { ModalMobile } from '../Common/ModalMobile';
import { ListRow } from '../../Components/Common/ListRow';
import { TopMobile } from '../Elements/TopMobile';
import { Button } from '../../Components/Common/Controls';
import { FilterFlyout } from '../Elements/FilterFlyoutMobile';
import { newId, newIsoDate, newMsDate } from '../../common/util';

export const AddDashboardTopModalMobile = ({ stack: parentStack }) => {
    const stack = R.append(CREATE_DASHBOARD_TOP_MODAL_MOBILE, parentStack);
    const { useVar } = useGraph(...stack);
    const [isOpen, close] = useModalState(CREATE_DASHBOARD_TOP_MODAL_MOBILE);
    const [filterMenu, setFilterMenu] = useState(false);
    const [topsToUpdate, setTopsToUpdate] = useVar(TOPS_TO_UPDATE_KEY);
    return (
        <div className="modal-container-compensation">
            <ModalMobile
                name={CREATE_DASHBOARD_TOP_MODAL_MOBILE}
                hideButtons={filterMenu}
                onBack={() => setTopsToUpdate({})}
            >
                {isOpen ? (
                    <ModalMobileContent
                        stack={stack}
                        close={close}
                        filterMenu={filterMenu}
                        setFilterMenu={setFilterMenu}
                        useVar={useVar}
                        topsToUpdate={topsToUpdate}
                        setTopsToUpdate={setTopsToUpdate}
                    />
                ) : null}
            </ModalMobile>
        </div>
    );
};

const ModalMobileContent = ({
    stack,
    filterMenu,
    setFilterMenu,
    close,
    useVar,
    topsToUpdate,
    setTopsToUpdate,
}) => {
    const [filter, setFilter] = useVar(DASHBOARD_FILTER_KEY);
    const [searchString, setSearchString] = useVar(SEARCH_STRING_KEY);
    const filterValuesSum = R.sum([
        R.length(R.pathOr([], ['topType', 'values'], filter)),
        R.length(R.pathOr([], ['meetingType', 'values'], filter)),
        R.length(R.pathOr([], ['oppStatus', 'values'], filter)),
        R.length(R.pathOr([], ['actionStatus', 'values'], filter)),
        R.length(R.pathOr([], ['category', 'values'], filter)),
        R.length(R.pathOr([], ['responsible', 'values'], filter)),
        R.length(searchString),
    ]);
    const [{ tops: sortedGroupedTops = {} } = {}] = useVar(SORTED_GROUPED_TOPS_KEY);
    const [projectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [currentMeetingId] = useVar(MEETING_ID_KEY);
    const projectBag = useNode(stack, projectId);
    const statusses = R.pathOr([], ['node', 'oppStatusTypes'], projectBag);

    const topRows = useMemo(
        () =>
            R.compose(
                R.sortBy(R.prop('businessId')),
                R.values,
                R.map(entries => ({
                    ...R.last(entries),
                    previousVersions: R.dropLast(1, entries),
                })),
            )(sortedGroupedTops),
        [sortedGroupedTops],
    );

    const { searchedTops } = filterTops({
        showTops: true,
        filter,
        statusses,
        searchString,
    })(topRows);

    const setToast = useToast('mobile-project-dashboard');
    const toastConfig = {
        textOnStart: 'Saving data...',
        textOnSuccess: 'Successfully saved.',
        textOnError: 'You are offline. Please sync when back online.',
    };
    const push = createOfflinePush(stack, setToast, toastConfig);
    const save = async () => {
        await push();
    };

    const addTopsToMeeting = () => {
        const {
            add,
            inheritRights,
            assoc,
            getGraph,
        } = createDummyGraph();
        R.forEach(({ top, responsible }) => {
            const newTopId = newId();
            const newTop = R.compose(
                R.assoc('modifiedDate', newIsoDate()),
                R.assoc('id', newTopId),
            )(top);
            add(MEETING_TOP_EDGE)(currentMeetingId)(newTop, { order: newMsDate() });
            inheritRights(newTopId)(subprojectId);
            responsible && assoc(TOP_RESPONSIBLE_EDGE)(newTopId)(responsible);
        })(R.values(topsToUpdate));
        store.mergeGraph(getGraph(), PROTOCOL);
    };
    const filterIsActive =
        filterValuesSum > 0 ||
        (filter?.meetingDate && !R.isEmpty(filter?.meetingDate)) ||
        (filter?.dueDate && !R.isEmpty(filter?.dueDate)) ||
        filter?.isCritical ||
        filter?.sort;

    return (
        <>
            <>
                <div className="modal-headline-mobile">
                    Add Dashboard Items
                    <div
                        className="modal-headline-filler"
                        style={{ backgroundColor: 'var(--corporate-color-13-light2)' }}
                    />
                </div>
                {R.map(({ top, meetingId, protocolId, responsible, previousVersions }) => (
                    <ListRow
                        key={top.id}
                        style={{
                            marginTop: '2.5rem',
                            marginBottom: '3.5rem',
                            backgroundColor: R.propOr(false, top.id, topsToUpdate)
                                ? 'var(--corporate-color-13-light4)'
                                : 'white',
                            border: '1px solid var(--shadow-color-ultralight)',
                            borderRadius: '5rem',
                        }}
                        onClick={() =>
                            !R.has(top.id, topsToUpdate)
                                ? setTopsToUpdate(
                                      R.assoc(
                                          top.id,
                                          { top, responsible, previousVersions },
                                          topsToUpdate,
                                      ),
                                  )
                                : setTopsToUpdate(R.dissoc(top.id, topsToUpdate))
                        }
                    >
                        <div className="top-search-row-mobile">
                            <TopMobile
                                noButton
                                protocol
                                stack={stack}
                                meetingId={meetingId}
                                topId={R.prop('id', top)}
                                protocolId={protocolId}
                            />
                        </div>
                    </ListRow>
                ))(
                    R.length(searchString) === 0 && filterValuesSum === 0 && !filter.showTops
                        ? []
                        : searchedTops,
                )}
            </>
            {R.length(R.values(topsToUpdate)) > 0 && !filterMenu && (
                <Button
                    className="modal-add-button"
                    style={{ zIndex: 10001 }}
                    onClick={() => {
                        addTopsToMeeting();
                        setFilter({});
                        setSearchString('');
                        save();
                        close();
                    }}
                >
                    <IoAddOutline size="8rem" style={{ marginRight: 6 }} />
                </Button>
            )}
            <div className="search-button-container">
                <Button
                    onClick={() => setFilterMenu(!filterMenu)}
                    className="search-button"
                    style={{
                        boxShadow:
                            filterIsActive && '0 0 2rem 0.25rem var(--corporate-color-13-light2)',
                    }}
                >
                    <div className="search-button-icon-container">
                        <IoSearchOutline
                            size="5rem"
                            color={
                                filterIsActive
                                    ? 'var(--corporate-color-13)'
                                    : 'var(--corporate-color-2)'
                            }
                        />
                    </div>
                </Button>
            </div>
            <FilterFlyout stack={stack} filterMenu={filterMenu} setFilterMenu={setFilterMenu} />
        </>
    );
};
