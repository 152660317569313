import React, { useEffect } from 'react';
import * as R from 'ramda';
import { IoShareSocialOutline } from 'react-icons/io5';
import { Modal } from '../Common/Modal';
import { useModal, useModalState } from '../../hooks/useModal';
import { useUserEmail } from '../../hooks/useUserEmail';
import {
    DASHBOARD_FILTER_KEY,
    ORGANIZATION_ID_KEY,
    ORGANIZATION_PROJECT_EDGE,
    PROJECTS_MODAL,
    PROJECT_ID_KEY,
    PROJECT_SUBPROJECT_EDGE,
    SEARCH_STRING_KEY,
    SUBPROJECT_ID_KEY,
    SUBPROJECT_MEETING_EDGE,
    ZIP_MODAL,
    useGraph,
    CD_ID_KEY,
    OPP_TRACKING,
    PROJECT_MILESTONES_MODAL,
    PROJECT_PHASES_MODAL,
} from '../../common/hooks.config.new';
import { constructNodeView, createOfflinePush } from '../../common/got-adjunct';
import { ProjectRightsModal } from './ProjectRightsModal';
import { useToast } from '../../hooks/useToast';
import { SubprojectRightsModal } from './SubprojectRightsModal';
import { Toast } from '../Elements/Toast';
import { EditProjectModal } from './EditProjectModal';
import { EditSubprojectModal } from './EditSubprojectModal';
import { SubprojectMeetingsModal } from './SubprojectMeetingsModal';
import { ProtocolsZipModal } from './ProtocolsZipModal';
import { ProjectsList } from '../Elements/ProjectDashboard/ProjectsList';
import { SubprojectsList } from '../Elements/ProjectDashboard/SubprojectsList';
import { ProjectTypesModal } from './ProjectTypesModal';

const constructProjectsView = organizationId =>
    organizationId
        ? {
              [organizationId]: {
                  as: 'organization',
                  include: {
                      node: true,
                  },
                  edges: {
                      [ORGANIZATION_PROJECT_EDGE]: {
                          as: 'projects',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const constructSubprojectsView = projectId =>
    projectId
        ? {
              [projectId]: {
                  as: 'project',
                  include: {
                      node: true,
                  },
                  edges: {
                      [PROJECT_SUBPROJECT_EDGE]: {
                          as: 'subprojects',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const constructMeetingsView = subprojectId =>
    subprojectId
        ? {
              [subprojectId]: {
                  as: 'subproject',
                  include: {
                      node: true,
                      rights: true,
                      files: true,
                  },
                  edges: {
                      [SUBPROJECT_MEETING_EDGE]: {
                          as: 'meetings',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const ProjectsModal = ({ stack }) => {
    const [isOpen] = useModalState('projects2');
    return (
        <Modal
            name="projects2"
            style={{
                height: '87vh',
                top: '6.5vh',
                width: '95vw',
                left: '2.5vw',
            }}
            topbar={<IoShareSocialOutline size={25} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <ModalContent stack={stack} /> : null}
        </Modal>
    );
};

const ModalContent = ({ stack: parentStack }) => {
    const user = useUserEmail();

    // organazation selection
    const { useVar } = useGraph(...parentStack);
    const [organizationId] = useVar(ORGANIZATION_ID_KEY);
    const [projectId, setProjectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [, setSearchString] = useVar(SEARCH_STRING_KEY);
    const [, setFilter] = useVar(DASHBOARD_FILTER_KEY);
    const [, setCDId] = useVar(CD_ID_KEY);
    const [oppTracking] = useVar(OPP_TRACKING);

    // our graph stack
    const stack = R.append(PROJECTS_MODAL, parentStack);
    const {
        useView,
        pull,
        add,
        update,
        setFile,
        setRights,
        // remove,
        // push: push2,
    } = useGraph(...stack);

    // user rights on organization
    const organizationView = constructNodeView(organizationId, { rights: true });
    useEffect(() => {
        organizationId && pull(organizationView);
    }, [organizationId]);
    const { [organizationId]: organizationBag = {} } = useView(organizationView);
    const canAdminOrganization = R.pathOr(
        false,
        ['rights', 'user', user, 'admin'],
        organizationBag,
    );

    // project choices
    const projectsView = constructProjectsView(organizationId);
    const { organization: { projects = {} } = {} } = useView(projectsView);
    const projectChoices = R.map(projectBag => ({
        value: R.path(['node', 'id'], projectBag),
        label: R.path(['node', 'title'], projectBag),
        body: R.path(['node'], projectBag),
    }))(R.values(projects));

    // selected project
    const projectView = constructNodeView(projectId, { node: true, rights: true, files: true });
    useEffect(() => {
        projectId && pull(projectView);
    }, [projectId]);
    const { [projectId]: projectBag = {} } = useView(projectView);
    const canAdminProject = R.pathOr(false, ['rights', 'user', user, 'admin'], projectBag);
    const canWriteProject = R.pathOr(false, ['rights', 'user', user, 'write'], projectBag);
    const customerLogo = R.path(['files', 'customerLogo', 'url'], projectBag);
    const project = R.propOr({}, 'node', projectBag);

    // subproject choices
    const subprojectsView = constructSubprojectsView(projectId);
    const { project: { subprojects = {} } = {} } = useView(subprojectsView);
    const subprojectChoices = R.map(subprojectBag => ({
        value: R.path(['node', 'id'], subprojectBag),
        label: R.path(['node', 'title'], subprojectBag),
        body: R.path(['node'], subprojectBag),
    }))(R.values(subprojects));

    // selected subproject
    const subprojectView = constructMeetingsView(subprojectId);
    useEffect(() => {
        subprojectId && pull(subprojectView);
    }, [subprojectId]);
    const { subproject: subprojectBag = {} } = useView(subprojectView);
    // const canAdminSubproject = R.pathOr(false, ['rights', 'user', user, 'admin'], subprojectBag);
    const canWriteSubproject = R.pathOr(false, ['rights', 'user', user, 'write'], subprojectBag);
    const subproject = R.propOr({}, 'node', subprojectBag);

    // meeting choices
    const meetingBags = R.propOr({}, 'meetings', subprojectBag);

    const meetingChoices = R.compose(
        R.map(meetingBag => ({
            value: R.path(['node', 'id'], meetingBag),
            label: R.path(['node', 'title'], meetingBag),
            body: R.pathOr({}, ['node'], meetingBag),
        })),
        R.reverse,
        R.filter(R.pathEq(['node', 'isDummy'], undefined)),
        R.sortBy(R.path(['node', 'date'])),
        R.values,
    )(meetingBags);

    // onSave actions
    const setProjectToast = useToast('project');
    const save = async fnAfterPush => {
        setProjectToast('spinner', 'Saving project data...');
        const toastConfig = {
            textOnStart: 'Saving project data...',
        };
        const push = createOfflinePush(stack, setProjectToast, toastConfig);
        return push()
            .then(async ({ uploads }) => {
                await uploads.start();
                fnAfterPush && (await fnAfterPush());
                setProjectToast('success', 'Successfully saved.', 5000);
                pull(projectView);
            })
            .catch(error => {
                if (error.status) {
                    setProjectToast('error', error.message ? error.message : error.toString());
                } else {
                    setProjectToast(
                        'error',
                        'You are offline. Please sync when back online.',
                        5000,
                    );
                }
            });
    };

    const openProjectRightsModal = useModal('project-rights');
    const openSubprojectRightsModal = useModal('subproject-rights');
    const openProtocolPdf = useModal('protocol-pdf');
    const openEditProjectModal = useModal('edit-project');
    const openEditSubprojectModal = useModal('edit-subproject');
    const openSubprojectMeetingsModal = useModal('subproject-meetings');
    const openProjectTypesModal = useModal('project-types');
    const openProjectMilestonesModal = useModal(PROJECT_MILESTONES_MODAL);
    const openProjectPhasesModal = useModal(PROJECT_PHASES_MODAL);
    const openZipModal = useModal(ZIP_MODAL);
    const userMail = useUserEmail();

    return (
        <>
            <div className="columns">
                <ProjectsList
                    stack={stack}
                    canAdminProject={canAdminProject}
                    canAdminOrganization={canAdminOrganization}
                    add={add}
                    update={update}
                    organizationId={organizationId}
                    setRights={setRights}
                    user={user}
                    setCDId={setCDId}
                    setFilter={setFilter}
                    setProjectId={setProjectId}
                    setSubprojectId={setSubprojectId}
                    setSearchString={setSearchString}
                    openEditProjectModal={openEditProjectModal}
                    openProjectRightsModal={openProjectRightsModal}
                    openProjectTypesModal={openProjectTypesModal}
                    openProjectMilestonesModal={openProjectMilestonesModal}
                    openProjectPhasesModal={openProjectPhasesModal}
                    openZipModal={openZipModal}
                    userMail={userMail}
                    projectId={projectId}
                    projectChoices={projectChoices}
                    // project={project}
                    // dissoc={remove}
                    // save={push2}
                />
                <SubprojectsList
                    user={user}
                    setSubprojectId={setSubprojectId}
                    setSearchString={setSearchString}
                    openZipModal={openZipModal}
                    projectId={projectId}
                    canWriteProject={canWriteProject}
                    openEditSubprojectModal={openEditSubprojectModal}
                    openSubprojectMeetingsModal={openSubprojectMeetingsModal}
                    openSubprojectRightsModal={openSubprojectRightsModal}
                    subprojectId={subprojectId}
                    canAdminProject={canAdminProject}
                    project={project}
                    subprojectChoices={subprojectChoices}
                />
            </div>
            <ProjectRightsModal stack={stack} save={save} />
            <SubprojectRightsModal stack={stack} save={save} />
            <SubprojectMeetingsModal
                openProtocolPdf={openProtocolPdf}
                meetingChoices={meetingChoices}
                subproject={subproject}
            />
            <EditProjectModal stack={stack} customerLogo={customerLogo} projectView={projectView} />
            <EditSubprojectModal stack={stack} canWriteSubproject={canWriteSubproject} />
            <ProjectTypesModal
                stack={stack}
                save={save}
                update={update}
                setFile={setFile}
                project={project}
                canAdminProject={canAdminProject}
                customerLogo={customerLogo}
                projectId={projectId}
                oppTracking={oppTracking}
            />
            <ProtocolsZipModal stack={stack} />
            <Toast name="project" />
        </>
    );
};
