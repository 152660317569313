import * as Util from '@gothub-team/got-util';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useEffect, useMemo } from 'react';
import {
    createGraph,
    GROUPED_TOPS_KEY,
    LOADED_PROJECTS,
    MEETING_ATTENDEE_EDGE,
    MEETING_TOP_EDGE,
    PROJECT_ID_KEY,
    PROJECT_MEETINGS_KEY,
    PROJECT_SUBPROJECT_EDGE,
    SORTED_GROUPED_TOPS_KEY,
    store,
    SUBPROJECT_MEETING_EDGE,
    TOP_RESPONSIBLE_EDGE,
    useGraph,
} from '../../../common/hooks.config.new';
import { getTopInfo } from '../../../common/topinfo.util';
import { getModalState, useModal, useModalState } from '../../../hooks/useModal';
import { ALWAYS_PULL, getLocalSettings } from '../../../hooks/useSettings';

const constructSubprojectsView = projectId =>
    projectId
        ? {
              [projectId]: {
                  as: 'project',
                  include: {
                      node: true,
                  },
                  edges: {
                      [PROJECT_SUBPROJECT_EDGE]: {
                          as: 'subprojects',
                          include: {
                              edges: true,
                              node: true,
                          },
                      },
                  },
              },
          }
        : {};

const constructSubprojectTopsView = subprojectId => ({
    [subprojectId]: {
        as: 'subproject',
        edges: {
            [SUBPROJECT_MEETING_EDGE]: {
                as: 'meetings',
                include: {
                    edges: true,
                    node: true,
                },
                edges: {
                    [MEETING_TOP_EDGE]: {
                        as: 'tops',
                        include: {
                            node: true,
                            edges: true,
                            metadata: true,
                        },
                        edges: {
                            [TOP_RESPONSIBLE_EDGE]: {
                                as: 'responsibles',
                                include: {
                                    node: true,
                                    edges: true,
                                },
                            },
                        },
                    },
                    [MEETING_ATTENDEE_EDGE]: {
                        as: 'attendees',
                        include: {
                            node: true,
                            edges: true,
                            metadata: true,
                        },
                    },
                },
            },
        },
    },
});

const constructProjectTopsView = projectId =>
    projectId
        ? {
              [projectId]: {
                  as: 'project',
                  include: {
                      node: true,
                  },
                  edges: {
                      [PROJECT_SUBPROJECT_EDGE]: {
                          as: 'subprojects',
                          include: {
                              edges: true,
                              node: true,
                          },
                          edges: {
                              [SUBPROJECT_MEETING_EDGE]: {
                                  as: 'meetings',
                                  include: {
                                      edges: true,
                                      node: true,
                                  },
                                  edges: {
                                      [MEETING_TOP_EDGE]: {
                                          as: 'tops',
                                          include: {
                                              node: true,
                                              edges: true,
                                              metadata: true,
                                          },
                                          edges: {
                                              [TOP_RESPONSIBLE_EDGE]: {
                                                  as: 'responsibles',
                                                  include: {
                                                      node: true,
                                                      edges: true,
                                                  },
                                              },
                                          },
                                      },
                                      [MEETING_ATTENDEE_EDGE]: {
                                          as: 'attendees',
                                          include: {
                                              node: true,
                                              edges: true,
                                              metadata: true,
                                          },
                                      },
                                  },
                              },
                          },
                      },
                  },
              },
          }
        : {};

export const pullProject = async (stack, projectId) => {
    const subprojectsView = constructSubprojectsView(projectId);
    const { pull } = createGraph(...stack);
    await pull(subprojectsView);
    const subprojectsRes = store.getView(...stack)(subprojectsView);
    const subprojectIds = R.compose(
        R.map(R.prop('nodeId')),
        R.values,
        R.pathOr({}, ['project', 'subprojects']),
    )(subprojectsRes);

    await Promise.all(
        R.map(subprojectId => pull(constructSubprojectTopsView(subprojectId)))(subprojectIds),
    );
};

const groupTopsByBID = (projectBag = {}) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [getResult, , overResult] = Util.useResult({});
    const { node: project } = projectBag;

    const projectMeetings = R.compose(
        R.map(sp => {
            const filteredMeetings = R.filter(m => !m.node?.isDummy)(R.values(sp.meetings));
            return { ...sp, meetings: filteredMeetings };
        }),
        R.values,
    )(projectBag.subprojects);

    R.map(
        ({ node: subproject, meetings }) => {
            const dashboardMeetings = R.compose(
                R.filter(
                    R.anyPass([
                        R.pathOr(false, ['node', 'isDummy']),
                        R.pathOr(false, ['node', 'submitted']),
                        // Get meetings that were created before draft/submit feature was introduced
                        R.pathEq(['node', 'submitted'], undefined),
                    ]),
                ),
                R.values,
            )(meetings);
            return R.map(({ node: meeting, attendees = {}, tops }) =>
                R.map(({ node: top, responsibles }) => {
                    const topInfo = getTopInfo({
                        project,
                        subproject,
                        meeting,
                        attendees,
                        top,
                        responsibles,
                    });
                    const businessId = R.prop('businessId', top);
                    return (
                        topInfo && overResult(Util.assocPathMutate([businessId, top.id], topInfo))
                    );
                })(tops || {}),
            )(dashboardMeetings || {});
        }, // Story: Filter Draft Meetings
    )(projectBag.subprojects || {});
    return { projectTops: getResult(), projectMeetings };
};

export const DashboardTopCalculator = ({ stack }) => {
    const { useVar, useView } = useGraph(...stack);

    const [projectId] = useVar(PROJECT_ID_KEY);
    const [, stopLoading] = useModalState('loader');

    // get all project tops
    const projectTopsView = useMemo(() => constructProjectTopsView(projectId), [projectId]);
    const setLoadedProjects = useModal(LOADED_PROJECTS);
    useEffect(() => {
        if (projectId) {
            const loadedProjects = getModalState(LOADED_PROJECTS);
            const shouldLoad =
                !R.has(projectId, loadedProjects) ||
                R.propOr(true, ALWAYS_PULL, getLocalSettings());
            if (shouldLoad) {
                pullProject(stack, projectId).then(() => {
                    setLoadedProjects(R.assoc(projectId, true, loadedProjects || {}));
                    stopLoading();
                });
            }
        }
    }, [projectId]);
    const { project: projectBag } = useView(projectTopsView);

    // group all project tops
    const dashboardData = useMemo(() => groupTopsByBID(projectBag), [projectBag]);
    const groupedTops = dashboardData.projectTops;
    const allMeetings = dashboardData.projectMeetings;
    useEffect(() => {
        store.setVar(R.last(stack))(GROUPED_TOPS_KEY, { tops: groupedTops });
        store.setVar(R.last(stack))(PROJECT_MEETINGS_KEY, { meetings: allMeetings });
    }, [groupedTops]);

    // sort all project tops versions
    const sortedGroupedTops = useMemo(
        () =>
            R.map(
                R.compose(
                    R.sortBy(
                        R.compose(
                            entry =>
                                R.propOr(false, 'meetingDate', entry) ||
                                R.propOr(false, 'modifiedDate', entry) ||
                                R.propOr(false, 'createdDate', entry),
                        ),
                    ),
                    R.values(),
                ),
            )(groupedTops),
        [groupedTops],
    );
    useEffect(() => {
        store.setVar(R.last(stack))(SORTED_GROUPED_TOPS_KEY, { tops: sortedGroupedTops });
    }, [sortedGroupedTops]);
    useEffect(() => {
        if (RA.isNotNilOrEmpty(sortedGroupedTops)) {
            stopLoading();
        }
    }, [projectId]);

    return null;
};
