import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

const matchesMeetingType = filter => topInfo => {
    const filterValues = R.pathOr([], ['meetingType', 'values'], filter);
    return (
        R.length(filterValues) === 0 ||
        R.anyPass(R.map(({ value }) => R.pathEq(['meetingType'], value))(filterValues))(topInfo)
    );
};

const matchesTopType = filter => topInfo => {
    const filterValues = R.pathOr([], ['topType', 'values'], filter);
    return (
        R.length(filterValues) === 0 ||
        R.anyPass(R.map(({ value }) => R.pathEq(['top', 'type'], value))(filterValues))(topInfo)
    );
};

const matchesPhase = filter => topInfo => {
    const filterValues = R.pathOr([], ['phase', 'values'], filter);
    return (
        R.length(filterValues) === 0 ||
        R.anyPass(R.map(({ value }) => R.pathEq(['top', 'phase'], value))(filterValues))(topInfo)
    );
};

const matchesActionStatus = filter => topInfo => {
    const filterValues = R.pathOr([], ['actionStatus', 'values'], filter);
    return (
        R.length(filterValues) === 0 ||
        !R.pathEq(['top', 'type'], 'a') ||
        R.anyPass(R.map(({ value }) => R.pathEq(['top', 'status'], value))(filterValues))(topInfo)
    );
};

const matchesOppStatus = filter => topInfo => {
    const filterValues = R.pathOr([], ['oppStatus', 'values'], filter);
    return (
        R.length(filterValues) === 0 ||
        !R.pathEq(['top', 'type'], 'o') ||
        R.anyPass(R.map(({ value }) => R.pathEq(['top', 'status'], value))(filterValues))(topInfo)
    );
};

const matchesOppCategory = filter => topInfo => {
    const filterValues = R.pathOr([], ['category', 'values'], filter);
    return (
        R.length(filterValues) === 0 ||
        !R.pathEq(['top', 'type'], 'o') ||
        R.anyPass(R.map(({ value }) => R.pathEq(['top', 'category'], value))(filterValues))(topInfo)
    );
};

const matchesTopResponsible = filter => topInfo => {
    const filterValues = R.pathOr([], ['responsible', 'values'], filter);
    return (
        R.length(filterValues) === 0 ||
        R.anyPass(R.map(({ value }) => R.pathEq(['responsible', 'id'], value))(filterValues))(
            topInfo,
        )
    );
};

const matchesMeetingDate = filter => topInfo =>
    R.compose(
        between(
            R.pathOr('2000-01-01', ['meetingDate', 'from'], filter),
            R.pathOr('9999-31-12', ['meetingDate', 'to'], filter),
        ),
        R.prop('meetingDate'),
    )(topInfo);

const matchesDueDate = filter => topInfo =>
    !R.has('dueDate', filter) ||
    !R.pathEq(['top', 'type'], 'a', topInfo) ||
    R.compose(
        between(
            R.pathOr('2000-01-01', ['dueDate', 'from'], filter),
            R.pathOr('9999-31-12', ['dueDate', 'to'], filter),
        ),
        R.pathOr('2000-01-02', ['top', 'dueDate']),
    )(topInfo);

const matchesCriticalOpp =
    (filter, statusses = []) =>
    topInfo => {
        if (!R.prop('isCritical', filter) || !R.pathEq(['top', 'type'], 'o', topInfo)) {
            return true;
        }
        const opp = R.propOr({}, 'top', topInfo);
        const oppStatus = R.prop('status', opp);
        if (!oppStatus) {
            return false;
        }

        const statusIndex = R.indexOf(oppStatus, statusses);
        const statusDueDate = `${'dueDate_'}${statusses[statusIndex + 1]}`;
        const msDueDate = new Date(opp[statusDueDate]).getTime();
        return msDueDate < Date.now() && opp;
    };

const sortTops = filter => topInfos => {
    const sortType = R.pathOr('None', ['sort', 'type', 'value'], filter);
    const sortDirection = R.pathOr('asc', ['sort', 'direction'], filter);
    switch (sortType) {
        case 'Meeting date':
            return sort(sortDirection, R.path(['meetingDate']))(topInfos);
        case 'Due date':
            return sort(sortDirection, R.path(['top', 'dueDate']))(topInfos);
        case 'Status':
            return sort(sortDirection, R.path(['top', 'status']))(topInfos);
        default:
            return topInfos;
    }
};

export const applyFilter = (filter, statusses) =>
    R.compose(
        sortTops(filter),
        R.filter(
            R.allPass([
                matchesMeetingType(filter),
                matchesTopType(filter),
                matchesPhase(filter),
                matchesActionStatus(filter),
                matchesOppCategory(filter),
                matchesOppStatus(filter),
                matchesTopResponsible(filter),
                matchesMeetingDate(filter),
                matchesDueDate(filter),
                matchesCriticalOpp(filter, statusses),
            ]),
        ),
        R.defaultTo([]),
    );

const between = (v1, v2) => value => v1 <= value && value <= v2;

const sort = (direction, selector) =>
    direction === 'asc' || direction === 'desc'
        ? R.sortWith([direction === 'asc' ? R.ascend(selector) : R.descend(selector)])
        : R.identity;

export const keywordSearch = (searchString, tops) => {
    // TODO a single function to filter for a whole search string
    const string = searchString.replace(/[^a-zA-Z0-9" ",-]+[u00C0-u017F]/g, '').toLowerCase();

    if (
        string.length > 0 &&
        !R.startsWith('#', string) &&
        !R.startsWith('+', string) &&
        !R.startsWith('@', string)
    ) {
        return tops.filter(
            t =>
                (t.top.description &&
                    t.top.description
                        .replace(/[^a-zA-Z0-9" ",-]+[u00C0-u017F]/g, '')
                        .toLowerCase()
                        .search(string) !== -1) ||
                (t.previousVersions?.length > 0 &&
                    (t.previousVersions?.length || 0) * -1 !==
                        t.previousVersions
                            .map(p =>
                                !p.top.description
                                    ? -1
                                    : p.top.description
                                          .replace(/[^a-zA-Z0-9" ",-]+[u00C0-u017F]/g, '')
                                          .toLowerCase()
                                          .search(string),
                            )
                            .reduce((a, b) => a + b, 0)) ||
                (t.businessId && t.businessId.toLowerCase().search(string) !== -1),
        );
    }

    return [];
};

export const searchAllTops = (searchInput, searchTops) => {
    const searchTerms = searchInput.split(',').filter(a => a);

    const responsibleSearchTerms = R.filter(R.startsWith('@'))(searchTerms);
    const getMultipleResponsiblesTops =
        responsibleSearchTerms.length > 0
            ? responsibleSearchTerms.map(r => {
                  const responsibleTops = searchTops.filter(st =>
                      R.includes(R.tail(r), R.pathOr(false, ['responsible', 'name'], st)),
                  );
                  return responsibleTops;
              })
            : [];
    const multipleResponsiblesTops = R.unnest(getMultipleResponsiblesTops);

    const filterSearchTops = term =>
        R.cond([
            [
                R.always(R.startsWith('#', term)),
                R.filter(R.propSatisfies(R.includes(R.tail(term)), 'subproject')),
            ],
            [
                R.always(R.startsWith('+', term)),
                R.filter(t => R.includes(R.tail(term), R.propOr(false, 'topType', t))),
            ],
            [R.always(R.T), R.filter(top => top.topType === term)],
        ]);

    const result = R.compose(...R.map(filterSearchTops)(searchTerms))(searchTops);

    return searchTerms.length > 0 ? R.concat(result, multipleResponsiblesTops) : [];
};

export const filterTops =
    ({
        filter,
        statusses,
        searchString,
        dashboardState,
        subprojectBusinessId,
        userName,
        showTops,
    }) =>
    topRows => {
        let tops = [];

        switch (dashboardState) {
            case 'user':
                tops = applyFilter(filter, statusses)(searchAllTops(`@${userName}`, topRows));
                break;
            case 'subproject':
                tops = applyFilter(
                    filter,
                    statusses,
                )(searchAllTops(`#${subprojectBusinessId}`, topRows));
                break;
            case 'project':
            default:
                tops = applyFilter(filter, statusses)(topRows);
                break;
        }

        if (showTops) {
            const searchedTops = RA.isNonEmptyString(searchString)
                ? keywordSearch(searchString, tops)
                : tops;

            return { searchedTops };
        }

        if (RA.isNonEmptyString(searchString) && !showTops) {
            // TODO if we could search for selectors and string at the same time, this would be way easier and smoother
            const searchedTops = searchAllTops(searchString, tops);
            if (searchedTops.length > 0) {
                return { searchedTops };
            }

            const keywordSearchedTops = keywordSearch(searchString, tops);
            return { searchedTops: keywordSearchedTops };
        }

        const unsortedActions = searchAllTops('a', tops);
        const actions = R.sortBy(R.pathOr('3000', ['top', 'dueDate']), unsortedActions);
        const opps = searchAllTops('o', tops);
        return { actions, opps };
    };

export const filterMeetings =
    ({
        filter,
        searchString,
        dashboardState,
        subprojectBusinessId,
        userName,
        searchedTops = [],
        filterMode,
    }) =>
    subprojects => {
        let meetings = [];

        switch (dashboardState) {
            case 'user':
                meetings = R.compose(
                    R.filter(m => m),
                    R.map(m => {
                        const isUserAttendee = R.filter(a => a.node.name === userName)(
                            R.values(m.attendees),
                        );
                        return isUserAttendee.length > 0 && m;
                    }),
                    R.unnest,
                    R.map(s => R.values(s.meetings)),
                    R.values,
                )(subprojects);
                break;
            case 'subproject':
                meetings = R.compose(
                    R.unnest,
                    R.map(s => R.values(s.meetings)),
                    R.filter(s => s.node.businessId === subprojectBusinessId),
                    R.values,
                )(subprojects);
                break;
            case 'project':
            default:
                meetings = R.compose(
                    R.unnest,
                    R.map(s => R.values(s.meetings)),
                    R.values,
                )(subprojects);
                break;
        }

        const meetingsPerType = (
            meetingTypes = filter?.meetingType?.values,
            filteredMeetings = [],
        ) => {
            const meetingsForFilterTypes = filteredMeetings;
            if (R.length(filter?.meetingType?.values) > 0) {
                if (R.length(meetingTypes) > 0) {
                    const typeMeetings = R.filter(m => m.node.type === R.head(meetingTypes).value)(
                        meetings,
                    );
                    const newMeetings = R.concat(meetingsForFilterTypes, typeMeetings);
                    const newTypes = R.tail(meetingTypes);
                    return meetingsPerType(newTypes, newMeetings);
                }
                return meetingsForFilterTypes;
            }
            return meetings;
        };

        const meetingsInTimeframe = filter
            ? R.filter(
                  R.compose(
                      between(
                          R.pathOr('2000-01-01', ['meetingDate', 'from'], filter),
                          R.pathOr('9999-31-12', ['meetingDate', 'to'], filter),
                      ),
                      R.path(['node', 'date']),
                  ),
              )(meetingsPerType())
            : meetings;

        // if tops are searched via searchbar, there can only be submitted meetings
        if (RA.isNonEmptyString(searchString)) {
            const string = searchString
                .replace(/[^a-zA-Z0-9" ",-]+[u00C0-u017F]/g, '')
                .toLowerCase();
            if (
                string.length > 0 &&
                !R.startsWith('#', string) &&
                !R.startsWith('+', string) &&
                !R.startsWith('@', string)
            ) {
                return R.filter(
                    m =>
                        (m.node.title &&
                            m.node.title
                                .replace(/[^a-zA-Z0-9" ",-]+[u00C0-u017F]/g, '')
                                .toLowerCase()
                                .search(string) !== -1) ||
                        (m.node.location &&
                            m.node.location
                                .replace(/[^a-zA-Z0-9" ",-]+[u00C0-u017F]/g, '')
                                .toLowerCase()
                                .search(string) !== -1) ||
                        // find meetings that contain the top in which the description includes the searchstring
                        (R.length(R.values(m.tops)) > 0 &&
                            R.length(R.values(m.tops)) * -1 !==
                                R.map(t =>
                                    !t.node.description
                                        ? -1
                                        : t.node.description
                                              .replace(/[^a-zA-Z0-9" ",-]+[u00C0-u017F]/g, '')
                                              .toLowerCase()
                                              .search(string),
                                )(R.values(m.tops)).reduce((a, b) => a + b, 0)),
                )(meetingsInTimeframe);
            }
            return [];
        }

        if (filterMode) {
            const chartTopIds = R.map(ct => {
                const lastVersionId = [ct.top.id];
                const prevVersionIds = R.map(pv => pv.top.id)(ct.previousVersions);
                const allVersionIds = R.concat(lastVersionId, prevVersionIds);
                return allVersionIds;
            })(searchedTops);
            // find meetings that contain tops
            const chartTopMeetings = R.filter(
                tss =>
                    R.length(
                        R.filter(ts => R.includes(ts.nodeId, R.unnest(chartTopIds)) && ts)(
                            R.values(tss.tops),
                        ),
                    ) > 0,
            )(meetingsPerType());
            return chartTopMeetings;
        }
        // this potentially also returns draft meetings, because it was maybe not explicitely searched for any tops
        return meetingsInTimeframe;
    };
