import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

const isFileDragEvent = event => {
    const types = event?.dataTransfer?.types;
    if (!types) return false;

    return types.indexOf('Files') >= 0;
};

export const OnlyOnFileDrag = ({ renderTrue, renderFalse }) => (
    <OnlyOnDrag
        fnEvalDragEvent={isFileDragEvent}
        renderTrue={renderTrue}
        renderFalse={renderFalse}
        inactiveTimeout={1000}
    />
);

const isActionDragEvent = event => {
    const isAction = R.find(R.equals('action'))(event.dataTransfer.types);
    return isAction;
};

export const OnlyOnActionDrag = ({ renderTrue, renderFalse }) => (
    <OnlyOnDrag
        fnEvalDragEvent={isActionDragEvent}
        renderTrue={renderTrue}
        renderFalse={renderFalse}
    />
);

export const OnlyOnDrag = ({
    fnEvalDragEvent = () => true,
    renderTrue: RenderTrue = () => null,
    renderFalse: RenderFalse = () => null,
    exitTimeout = 100,
    inactiveTimeout = 100,
}) => {
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
        const timerRef = { current: undefined };

        const onDragOver = event => {
            const isValidDragEvent = fnEvalDragEvent(event);

            if (isValidDragEvent) {
                setDragging(true);
                if (timerRef.current) {
                    window.clearTimeout(timerRef.current);
                    timerRef.current = undefined;
                }
                timerRef.current = window.setTimeout(() => {
                    setDragging(false);
                }, inactiveTimeout);
            } else if (!timerRef.current) {
                timerRef.current = window.setTimeout(() => {
                    setDragging(false);
                }, exitTimeout);
            }
        };

        const onDragExit = () => {
            if (!timerRef.current) {
                timerRef.current = window.setTimeout(() => {
                    setDragging(false);
                }, exitTimeout);
            }
        };

        document.addEventListener('dragover', onDragOver);
        document.addEventListener('dragleave', onDragExit);
        document.addEventListener('drop', onDragExit);

        return () => {
            document.removeEventListener('dragover', onDragOver);
            document.removeEventListener('dragleave', onDragExit);
            document.removeEventListener('drop', onDragExit);
        };
    }, []);

    if (dragging) {
        return <RenderTrue />;
    }

    return <RenderFalse />;
};
