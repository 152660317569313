import * as R from 'ramda';
import React from 'react';
import { IoTrashOutline } from 'react-icons/io5';
import { useGraph } from '../../common/hooks.config.new';
import { useNode } from '../../hooks/useNode';
import { useTopResponsible } from '../../hooks/useTopResponsible';
import { usePreviousVersions } from '../../hooks/useTopHistories';
import { formatShortDate } from '../../common/util';
import { Button } from '../../Components/Common/Controls';
import { useProjectCurrency } from '../../hooks/useChoices';

export const _ProtocolTopMobile = ({ stack, topId, oppTracking = true, global, onRemove }) => {
    const { node: top } = useNode(stack, topId);

    const { update: updateTop } = useGraph(...stack);
    const [responsible] = useTopResponsible(stack, topId);

    const previousVersions = usePreviousVersions(stack, R.prop('businessId', top));

    const lastVersionAuthor = R.compose(R.pathOr('', ['author', 'name']), R.last)(previousVersions);

    const lastVersionDate = R.compose(
        formatShortDate,
        R.defaultTo(R.prop('modifiedDate', top) || R.prop('createdDate', top) || ''),
        R.prop('editedDate'),
        R.last,
    )(previousVersions);

    const updateTopType = type => updateTop(R.assoc('type', type, top));
    if (top) {
        return (
            <div className="top kanban-top" key={top.id}>
                <div className="latest">
                    <TopSwitch
                        top={top}
                        global={global}
                        stack={stack}
                        updateTopType={updateTopType}
                        responsible={responsible}
                        oppTracking={oppTracking}
                        lastVersionAuthor={lastVersionAuthor}
                        lastVersionDate={lastVersionDate}
                        previousVersions={previousVersions}
                        onRemove={onRemove}
                    />
                </div>
            </div>
        );
    }
    return null;
};

const TopSwitch = ({
    top,
    global,
    stack,
    responsible,
    oppTracking,
    lastVersionAuthor,
    lastVersionDate,
    previousVersions,
    onRemove,
}) => {
    switch (top.type) {
        case 'a':
            return (
                <TopA
                    top={top}
                    global={global}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                    onRemove={onRemove}
                />
            );
        case 'o':
            if (oppTracking) {
                return (
                    <TopO
                        top={top}
                        global={global}
                        stack={stack}
                        responsible={responsible}
                        lastVersionAuthor={lastVersionAuthor}
                        lastVersionDate={lastVersionDate}
                        previousVersions={previousVersions}
                        onRemove={onRemove}
                    />
                );
            }
            return null;
        case 'r':
            return (
                <TopR
                    top={top}
                    global={global}
                    stack={stack}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                    onRemove={onRemove}
                />
            );
        case 's':
            return (
                <TopS
                    top={top}
                    global={global}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                    onRemove={onRemove}
                />
            );
        case 'i':
            return (
                <TopI
                    top={top}
                    global={global}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                    onRemove={onRemove}
                />
            );
        case 'd':
            return (
                <TopD
                    top={top}
                    global={global}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                    onRemove={onRemove}
                />
            );
        case '1':
            return (
                <Headline
                    top={top}
                    global={global}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                    onRemove={onRemove}
                />
            );
        case '2':
            return (
                <Headline
                    top={top}
                    global={global}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                    onRemove={onRemove}
                />
            );
        case '3':
            return (
                <Headline
                    top={top}
                    global={global}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                    onRemove={onRemove}
                />
            );
        default:
            return <NoType onRemove={onRemove} />;
    }
};

const NoType = ({ onRemove }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
        }}
    >
        <div
            style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-start',
                paddingLeft: '0.5rem',
            }}
        >
            <div
                className="top-type-badge-protocol-mobile"
                style={{
                    backgroundColor: 'white',
                    color: 'var(--corporate-color-2)',
                    border: '2px solid var(--corporate-color-2-light)',
                    fontWeight: 'normal',
                }}
            >
                ?
            </div>
        </div>
        {onRemove && (
            <Button
                onClick={e => {
                    e.stopPropagation();
                    onRemove();
                }}
                style={{ height: '4rem', width: '4rem', borderRadius: '2rem' }}
            >
                <IoTrashOutline size="3.5rem" color="var(--corporate-color-2-light)" />
            </Button>
        )}
    </div>
);

const TopA = ({ top, responsible, onRemove }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
        }}
    >
        <div
            style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-start',
                paddingLeft: '0.5rem',
            }}
        >
            <div
                className="top-type-badge-protocol-mobile"
                style={{ backgroundColor: 'var(--corporate-color-14)' }}
            >
                A
            </div>
        </div>
        <div
            style={{
                display: 'flex',
                alignItems: 'flex-start',
                flex: 9,
                flexDirection: 'column',
            }}
        >
            {R.prop('description', top) && (
                <div
                    style={{
                        margin: '1rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <p className="top-info-mobile">{R.propOr('', 'description', top)}</p>
                    <p className="top-info-label-mobile">Description</p>
                </div>
            )}
            <div style={{ display: 'flex', margin: '1rem', width: '100%' }}>
                {R.prop('status', top) && (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <p className="top-info-mobile" style={{ whiteSpace: 'nowrap' }}>
                            {R.propOr('', 'status', top)}
                        </p>
                        <p className="top-info-label-mobile">Status</p>
                    </div>
                )}
                {R.prop('name', responsible) && (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <p className="top-info-mobile" style={{ whiteSpace: 'nowrap' }}>
                            {R.propOr('', 'name', responsible)}
                        </p>
                        <p className="top-info-label-mobile">Responsible</p>
                    </div>
                )}
            </div>
            <div style={{ display: 'flex', margin: '1rem', width: '100%' }}>
                {R.prop('phase', top) && (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <p className="top-info-mobile">{R.propOr('', 'phase', top)}</p>
                        <p className="top-info-label-mobile">Phase</p>
                    </div>
                )}
                {R.prop('dueDate', top) && (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <p className="top-info-mobile">
                            {formatShortDate(R.propOr('', 'dueDate', top))}
                        </p>
                        <p className="top-info-label-mobile">Due Date</p>
                    </div>
                )}
            </div>
        </div>
        {onRemove && (
            <Button
                onClick={e => {
                    e.stopPropagation();
                    onRemove();
                }}
                style={{ height: '4rem', width: '4rem', borderRadius: '2rem' }}
            >
                <IoTrashOutline size="3.5rem" color="var(--corporate-color-2-light)" />
            </Button>
        )}
    </div>
);

const TopO = ({ stack, top, responsible, onRemove }) => {
    const currency = useProjectCurrency(stack);
    const formato = val =>
        new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency,
        }).format(val);
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                overflow: 'hidden',
                paddingRight: 6,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'flex-start',
                    paddingLeft: '0.5rem',
                }}
            >
                <div
                    className="top-type-badge-protocol-mobile"
                    style={{ backgroundColor: 'var(--corporate-color-13)' }}
                >
                    O
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flex: 9,
                    flexDirection: 'column',
                }}
            >
                {R.prop('description', top) && (
                    <div
                        style={{
                            margin: '1rem',
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <p className="top-info-mobile">{R.propOr('', 'description', top)}</p>
                        <p className="top-info-label-mobile">Description</p>
                    </div>
                )}
                <div style={{ display: 'flex', margin: '1rem', width: '100%' }}>
                    {R.prop('status', top) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile" style={{ whiteSpace: 'nowrap' }}>
                                {R.propOr('', 'status', top)}
                            </p>
                            <p className="top-info-label-mobile">Status</p>
                        </div>
                    )}
                    {R.prop('name', responsible) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile" style={{ whiteSpace: 'nowrap' }}>
                                {R.propOr('', 'name', responsible)}
                            </p>
                            <p className="top-info-label-mobile">Responsible</p>
                        </div>
                    )}
                </div>
                <div style={{ display: 'flex', margin: '1rem', width: '100%' }}>
                    {R.prop('category', top) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile">{R.propOr('', 'category', top)}</p>
                            <p className="top-info-label-mobile">Category</p>
                        </div>
                    )}
                    {R.prop('savings', top) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile">
                                {formato(R.propOr(0, 'savings', top))}
                            </p>
                            <p className="top-info-label-mobile">Savings</p>
                        </div>
                    )}
                </div>
                <div style={{ display: 'flex', margin: '1rem', width: '100%' }}>
                    {R.prop('phase', top) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile">{R.propOr('', 'phase', top)}</p>
                            <p className="top-info-label-mobile">Phase</p>
                        </div>
                    )}
                </div>
            </div>
            {onRemove && (
                <Button
                    onClick={e => {
                        e.stopPropagation();
                        onRemove();
                    }}
                    style={{ height: '4rem', width: '4rem', borderRadius: '2rem' }}
                >
                    <IoTrashOutline size="3.5rem" color="var(--corporate-color-2-light)" />
                </Button>
            )}
        </div>
    );
};

const TopR = ({ top, stack, responsible, onRemove }) => {
    const currency = useProjectCurrency(stack);
    const formato = val =>
        new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency,
        }).format(val);
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                overflow: 'hidden',
                paddingRight: 6,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'flex-start',
                    paddingLeft: '0.5rem',
                }}
            >
                <div
                    className="top-type-badge-protocol-mobile"
                    style={{ backgroundColor: 'var(--corporate-color-12)' }}
                >
                    R
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flex: 9,
                    flexDirection: 'column',
                }}
            >
                {R.prop('description', top) && (
                    <div
                        style={{
                            margin: '1rem',
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <p className="top-info-mobile">{R.propOr('', 'description', top)}</p>
                        <p className="top-info-label-mobile">Description</p>
                    </div>
                )}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        margin: '1rem',
                        width: '100%',
                    }}
                >
                    {R.prop('category', top) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile">{R.propOr('', 'category', top)}</p>
                            <p className="top-info-label-mobile">Category</p>
                        </div>
                    )}
                    {R.prop('name', responsible) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile" style={{ whiteSpace: 'nowrap' }}>
                                {R.propOr('', 'name', responsible)}
                            </p>
                            <p className="top-info-label-mobile">Responsible</p>
                        </div>
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        margin: '1rem',
                        width: '100%',
                    }}
                >
                    {R.prop('reference', top) && (
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile">{R.propOr('', 'reference', top)}</p>
                            <p className="top-info-label-mobile">Reference</p>
                        </div>
                    )}
                    {R.prop('costIncrease', top) && (
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile">
                                {formato(R.propOr(0, 'costIncrease', top))}
                            </p>
                            <p className="top-info-label-mobile">Cost Increase</p>
                        </div>
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        margin: '1rem',
                        width: '100%',
                    }}
                >
                    {R.prop('phase', top) && (
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile">{R.propOr('', 'phase', top)}</p>
                            <p className="top-info-label-mobile">Phase</p>
                        </div>
                    )}
                </div>
            </div>
            {onRemove && (
                <Button
                    onClick={e => {
                        e.stopPropagation();
                        onRemove();
                    }}
                    style={{ height: '4rem', width: '4rem', borderRadius: '2rem' }}
                >
                    <IoTrashOutline size="3.5rem" color="var(--corporate-color-2-light)" />
                </Button>
            )}
        </div>
    );
};

const TopS = ({ top, onRemove }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
            paddingRight: 6,
        }}
    >
        <div
            style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-start',
                paddingLeft: '0.5rem',
            }}
        >
            <div className="top-type-badge-protocol-mobile" style={{ backgroundColor: 'grey' }}>
                S
            </div>
        </div>
        <div
            style={{
                display: 'flex',
                alignItems: 'flex-start',
                flex: 9,
                flexDirection: 'column',
            }}
        >
            {R.prop('description', top) && (
                <div
                    style={{
                        margin: '1rem',
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <p className="top-info-mobile">{R.propOr('', 'description', top)}</p>
                    <p className="top-info-label-mobile">Description</p>
                </div>
            )}
            {R.prop('phase', top) && (
                <div
                    style={{
                        margin: '1rem',
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <p className="top-info-mobile">{R.propOr('', 'phase', top)}</p>
                    <p className="top-info-label-mobile">Phase</p>
                </div>
            )}
        </div>
        {onRemove && (
            <Button
                onClick={e => {
                    e.stopPropagation();
                    onRemove();
                }}
                style={{ height: '4rem', width: '4rem', borderRadius: '2rem' }}
            >
                <IoTrashOutline size="3.5rem" color="var(--corporate-color-2-light)" />
            </Button>
        )}
    </div>
);

const TopI = ({ top, onRemove }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
            paddingRight: 6,
        }}
    >
        <div
            style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-start',
                paddingLeft: '0.5rem',
            }}
        >
            <div className="top-type-badge-protocol-mobile" style={{ backgroundColor: '#C5C9D3' }}>
                I
            </div>
        </div>
        <div
            style={{
                display: 'flex',
                alignItems: 'flex-start',
                flex: 9,
                flexDirection: 'column',
            }}
        >
            {R.prop('description', top) && (
                <div
                    style={{
                        margin: '1rem',
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <p className="top-info-mobile">{R.propOr('', 'description', top)}</p>
                    <p className="top-info-label-mobile">Description</p>
                </div>
            )}
            {R.prop('phase', top) && (
                <div
                    style={{
                        margin: '1rem',
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <p className="top-info-mobile">{R.propOr('', 'phase', top)}</p>
                    <p className="top-info-label-mobile">Phase</p>
                </div>
            )}
        </div>
        {onRemove && (
            <Button
                onClick={e => {
                    e.stopPropagation();
                    onRemove();
                }}
                style={{ height: '4rem', width: '4rem', borderRadius: '2rem' }}
            >
                <IoTrashOutline size="3.5rem" color="var(--corporate-color-2-light)" />
            </Button>
        )}
    </div>
);

const TopD = ({ top, onRemove }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
            paddingRight: 6,
        }}
    >
        <div
            style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-start',
                paddingLeft: '0.5rem',
            }}
        >
            <div
                className="top-type-badge-protocol-mobile"
                style={{ backgroundColor: 'var(--corporate-color-7)' }}
            >
                D
            </div>
        </div>
        <div
            style={{
                display: 'flex',
                alignItems: 'flex-start',
                flex: 9,
                flexDirection: 'column',
            }}
        >
            {R.prop('description', top) && (
                <div
                    style={{
                        margin: '1rem',
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <p className="top-info-mobile">{R.propOr('', 'description', top)}</p>
                    <p className="top-info-label-mobile">Description</p>
                </div>
            )}
            {R.prop('phase', top) && (
                <div
                    style={{
                        margin: '1rem',
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <p className="top-info-mobile">{R.propOr('', 'phase', top)}</p>
                    <p className="top-info-label-mobile">Phase</p>
                </div>
            )}
        </div>
        {onRemove && (
            <Button
                onClick={e => {
                    e.stopPropagation();
                    onRemove();
                }}
                style={{ height: '4rem', width: '4rem', borderRadius: '2rem' }}
            >
                <IoTrashOutline size="3.5rem" color="var(--corporate-color-2-light)" />
            </Button>
        )}
    </div>
);

const Headline = ({ top, onRemove }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
            paddingRight: 6,
        }}
    >
        <div
            style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-start',
                paddingLeft: '0.5rem',
            }}
        >
            <div
                className="top-type-badge-protocol-mobile"
                style={{
                    backgroundColor: 'white',
                    color: 'var(--corporate-color-1)',
                    border: '1px solid var(--corporate-color-1)',
                    boxSizing: 'border-box',
                }}
            >
                {top.type}
            </div>
        </div>
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flex: 9,
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {R.prop('description', top) && (
                    <div
                        style={{
                            margin: '1rem',
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <p className="top-info-mobile" style={{ fontWeight: 'normal' }}>
                            {`${R.slice(0, 100, R.propOr('', 'description', top))}...`}
                        </p>
                        <p className="top-info-label-mobile">Headline</p>
                    </div>
                )}
            </div>
        </div>
        {onRemove && (
            <Button
                onClick={e => {
                    e.stopPropagation();
                    onRemove();
                }}
                style={{ height: '4rem', width: '4rem', borderRadius: '2rem' }}
            >
                <IoTrashOutline size="3.5rem" color="var(--corporate-color-2-light)" />
            </Button>
        )}
    </div>
);
