import React, { useState } from 'react';
import { DASHBOARD, MAIN, OFFLINE, PROTOCOL, PROTOCOL_VARS } from '../../common/hooks.config.new';
import { ProtocolMenus } from '../Elements/ProtocolMenus';
import { ProtocolScreenMobileContent } from './ProtocolScreenContentMobile';
import { useInitProjectChoices } from '../../hooks/useChoices';
import { ContactsModalMobile } from '../Modals/ContactsModalMobile';
import { EditAttendeeModalMobile } from '../Modals/EditAttendeeModalMobile';
import { ProtocolTopCalculator } from '../../Components/Elements/ProtocolScreen/ProtocolTopCalculator';
import { Toast } from '../../Components/Elements/Toast';
import { EditProtocolTopModalMobile } from '../Modals/EditProtocolTopMobile';
import { AddDashboardTopModalMobile } from '../Modals/AddDashboardTopModalMobile';
import { PrintModalMobile } from '../Modals/PrintModalMobile';
import { useModalState } from '../../hooks/useModal';
import { LoaderMobile } from '../../Components/Elements/Loader';
import { EditContactModalMobile } from '../Modals/EditContactModalMobile';
import { ProtocolSelector } from '../Elements/ProtocolSelector';

const stack = [MAIN, OFFLINE, DASHBOARD, PROTOCOL_VARS, PROTOCOL];
const varsStack = [MAIN, OFFLINE, DASHBOARD, PROTOCOL, PROTOCOL_VARS];

export const ProtocolScreenMobile = () => {
    useInitProjectChoices([MAIN, DASHBOARD]);
    const [read, setRead] = useState(false);
    const [isLoading] = useModalState('loader');
    return (
        <>
            <Toast name="mobile-project-dashboard" mobile />
            {isLoading ? (
                <LoaderMobile />
            ) : (
                <ProtocolScreenMobileContent stack={stack} read={read} />
            )}
            <ProtocolMenus stack={stack} read={read} setRead={setRead} />
            {!read && <ProtocolSelector stack={stack} />}
            <ContactsModalMobile stack={stack} />
            <EditAttendeeModalMobile stack={stack} />
            <EditProtocolTopModalMobile stack={stack} />
            <AddDashboardTopModalMobile stack={stack} />
            <PrintModalMobile stack={stack} />
            <EditContactModalMobile stack={stack} />
            <ProtocolTopCalculator stack={varsStack} read={read} />
        </>
    );
};
