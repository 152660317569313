import * as R from 'ramda';
import React from 'react';
import { ListRow } from '../Common/ListRow';
import { ProtocolTop } from './ProtocolScreen/ProtocolTop';
import { TopButtonRow } from './TopButtonRow';

export const OppTops = ({
    stack,
    opps,
    assocAction,
    connectPredecessor,
    connectSuccessor,
    connectMode,
    noEdit,
}) => (
    <div>
        {R.map(opp => (
            <ListRow
                key={opp.id}
                style={{
                    marginLeft: 'var(--space-small)',
                    marginRight: 'var(--space-small)',
                }}
            >
                <div className="top-search-row">
                    <TopButtonRow
                        stack={stack}
                        assocAction={assocAction}
                        connectPredecessor={connectPredecessor}
                        connectSuccessor={connectSuccessor}
                        connectMode={connectMode}
                        opp={opp}
                        top={opp}
                        noEdit={noEdit}
                    />
                    <ProtocolTop stack={stack} topId={opp.id} editable={false} noButton scorecard />
                </div>
            </ListRow>
        ))(opps)}
    </div>
);
