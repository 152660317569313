import * as R from 'ramda';
import { useMemo } from 'react';

export const selectTopLinks = (topBusinessId, groupedTops) => {
    const top = R.last(R.propOr([], topBusinessId, groupedTops));
    if (!top) return [];

    const linkedBids = {};

    const prevRecursion = (_top, steps = 5) => {
        if (steps <= 0) return [];

        const prevBid = R.path(['top', 'previous', 'businessId'], _top);
        if (prevBid && !(prevBid in linkedBids)) {
            linkedBids[prevBid] = true;
            const prevTop = R.last(R.propOr([], prevBid, groupedTops));
            if (prevTop) {
                return [...prevRecursion(prevTop, steps - 1), prevTop];
            }
        }

        return [];
    };

    const nextRecursion = (_top, steps = 5) => {
        if (steps <= 0) return [];

        const nextBid = R.path(['top', 'next', 'businessId'], _top);
        if (nextBid && !(nextBid in linkedBids)) {
            linkedBids[nextBid] = true;
            const nextTop = R.last(R.propOr([], nextBid, groupedTops));
            if (nextTop) {
                return [nextTop, ...nextRecursion(nextTop, steps - 1)];
            }
        }
        return [];
    };

    return [prevRecursion(top, 5), top, nextRecursion(top, 5)];
};

export const useTopLinks = (topBusinessId, groupedTops) =>
    useMemo(() => selectTopLinks(topBusinessId, groupedTops), [topBusinessId, groupedTops]);

export const sortWithTopLinks = (topBusinessIds, groupedTopInfos) => {
    const sortedBusinessIds = {};
    const result = [];

    for (let i = 0; i < topBusinessIds.length; i += 1) {
        const topBusinessId = topBusinessIds[i];
        if (!(topBusinessId in sortedBusinessIds)) {
            const [prev, curr, next] = selectTopLinks(topBusinessId, groupedTopInfos);
            const entries = [...prev, curr, ...next];

            entries.forEach(e => {
                if (!(e.top.businessId in sortedBusinessIds)) {
                    result.push(e);
                    sortedBusinessIds[e.top.businessId] = true;
                }
            });
        }
    }

    return result;
};
