import React from 'react';
import { IoLogOutOutline } from 'react-icons/io5';
import logo from '../../../logos/pclogo.svg';
import { Button } from '../../Common/Controls';
import { useAuth } from '../../../common/auth';
import { ProjectSelector } from '../ProjectSelector';

export const GlobalDashboardTopBar = ({ stack }) => {
    const [, , logout] = useAuth();
    //
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
                className="dashboard-screen-upper-topbar border-radius-shadow"
                style={{ boxShadow: '0 5px 8px -2px var(--corporate-color-1-light4)' }}
            >
                <div style={{ display: 'flex', flex: '1' }}>
                    <img alt="logo" src={logo} style={{ width: 200 }} />
                </div>
                <div style={{ display: 'flex', flex: '1', justifyContent: 'center' }}>
                    <ProjectSelector stack={stack} global />
                </div>
                <div style={{ display: 'flex', flex: '1', justifyContent: 'flex-end' }}>
                    <Button onClick={logout} className="topbar-button">
                        <IoLogOutOutline size={28} style={{ marginRight: 6 }} />
                        Logout
                    </Button>
                </div>
            </div>
        </div>
    );
};
