import React, { useRef, useState } from 'react';
import './Modal.css';
import { IoEllipse } from 'react-icons/io5';
import { Button } from './Controls';
import { useModalState } from '../../hooks/useModal';

export const Modal = ({
    name,
    topbar,
    children,
    isOpen,
    close,
    clear,
    style,
    contentContainerStyle,
    childrenOnly,
}) => {
    const [_isOpen, _close] = useModalState(name);
    const doClose = () => {
        name && _close();
        close && close();
        clear && clear();
    };
    const [maxed, setMaxed] = useState(false);
    const backdropPointerDown = useRef(false);
    return (
        <div
            className={`modal-backdrop${_isOpen || isOpen ? ' open' : ''}`}
            onPointerDown={() => {
                backdropPointerDown.current = true;
            }}
            onPointerUp={() => {
                if (backdropPointerDown.current) {
                    doClose();
                }
                backdropPointerDown.current = false;
            }}
            style={{ backdropFilter: maxed && 'none' }}
        >
            {childrenOnly ? (
                <div style={{ ...style, position: 'absolute' }} className="top-scaling">
                    {children}
                </div>
            ) : (
                <div
                    style={{
                        ...style,
                        width: maxed ? '100vw' : style.width,
                        height: maxed ? '100vh' : style.height,
                        left: maxed ? 0 : style.left,
                        top: maxed ? 0 : style.top,
                        borderRadius: maxed ? 0 : style.borderRadius,
                    }}
                    className="modal-container"
                    onClick={e => {
                        e.stopPropagation();
                        backdropPointerDown.current = false;
                    }}
                    onPointerDown={e => {
                        e.stopPropagation();
                        backdropPointerDown.current = false;
                    }}
                    onPointerUp={e => {
                        e.stopPropagation();
                        backdropPointerDown.current = false;
                    }}
                >
                    <div className="topbar-container">
                        <div className="topbar-icons">{topbar}</div>
                        <div style={{ alignItems: 'center' }}>
                            <Button onClick={() => setMaxed(!maxed)} className="max-min-button">
                                <IoEllipse
                                    size={13}
                                    color={
                                        !maxed
                                            ? 'var(--corporate-color-13)'
                                            : 'var(--corporate-color-7)'
                                    }
                                />
                            </Button>
                            <Button onClick={() => doClose()} className="max-min-button">
                                <IoEllipse size={13} color="var(--corporate-color-12)" />
                            </Button>
                        </div>
                    </div>
                    <div className="content-container" style={contentContainerStyle}>
                        {children}
                    </div>
                </div>
            )}
        </div>
    );
};
