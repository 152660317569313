import * as R from 'ramda';
import React, { useState } from 'react';
import { IoEllipse } from 'react-icons/io5';
import { Focusable } from '../Common/Fields';
import { useGraph } from '../../common/hooks.config.new';
import { useNode } from '../../hooks/useNode';
import { useTopResponsible } from '../../hooks/useTopResponsible';
import { usePreviousVersions } from '../../hooks/useTopHistories';
import { formatShortDate } from '../../common/util';
import { Button } from '../Common/Controls';
import { useProjectCurrency } from '../../hooks/useChoices';

export const _KanbanTop = ({ stack, topId, oppTracking = true }) => {
    const { node: top } = useNode(stack, topId);
    const { update: updateTop } = useGraph(...stack);
    const [responsible] = useTopResponsible(stack, topId);
    const previousVersions = usePreviousVersions(stack, R.prop('businessId', top));
    const lastVersionAuthor = R.compose(R.pathOr('', ['author', 'name']), R.last)(previousVersions);
    const lastVersionDate = R.compose(
        formatShortDate,
        R.defaultTo(R.prop('modifiedDate', top) || R.prop('createdDate', top) || ''),
        R.prop('editedDate'),
        R.last,
    )(previousVersions);
    const updateTopType = type => updateTop(R.assoc('type', type, top));
    if (top) {
        return (
            <div className="top kanban-top" key={top.id}>
                <div className="latest">
                    <TopSwitch
                        top={top}
                        stack={stack}
                        updateTopType={updateTopType}
                        responsible={responsible}
                        oppTracking={oppTracking}
                        lastVersionAuthor={lastVersionAuthor}
                        lastVersionDate={lastVersionDate}
                        previousVersions={previousVersions}
                    />
                </div>
            </div>
        );
    }
    return null;
};

const TopSwitch = ({
    top,
    stack,
    updateTopType,
    responsible,
    oppTracking,
    lastVersionAuthor,
    lastVersionDate,
    previousVersions,
}) => {
    switch (top.type) {
        case 'a':
            return (
                <TopA
                    top={top}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                />
            );
        case 'o':
            if (oppTracking) {
                return (
                    <TopO
                        top={top}
                        stack={stack}
                        responsible={responsible}
                        lastVersionAuthor={lastVersionAuthor}
                        lastVersionDate={lastVersionDate}
                        previousVersions={previousVersions}
                    />
                );
            }
            return null;
        case 'r':
            return (
                <TopR
                    top={top}
                    stack={stack}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                />
            );
        case 's':
            return (
                <TopS
                    top={top}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                />
            );
        case 'i':
            return (
                <TopI
                    top={top}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                />
            );
        case 'd':
            return (
                <TopD
                    top={top}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                />
            );
        default:
            return <TopChoice updateTopType={updateTopType} />;
    }
};

const TopChoice = ({ updateTopType }) => (
    <Focusable autoFocus>
        {updateTopType && (
            <input
                className="field text-field font-weight-bold"
                style={{ flex: 1 }}
                onChange={e => updateTopType(R.toLower(e.nativeEvent.data))}
                value="Choose TOP type (Hotkeys: [A]ction, [O]pportunity, [R]isk, [S]tatement, [I]nformation, [D]ecision, Headline [1], [2], [3])"
            />
        )}
    </Focusable>
);

const TopA = ({ top, responsible, previousVersions, lastVersionAuthor, lastVersionDate }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
            paddingRight: 6,
        }}
    >
        <div style={{ display: 'flex', flex: 1 }}>
            <Button
                className="top-type-badge top-type-hover-effect"
                style={{
                    backgroundColor: 'var(--corporate-color-14)',
                    margin: '0 9 0 3',
                    cursor: 'default',
                    boxShadow: 'none',
                }}
                disabled
            >
                A
            </Button>
        </div>
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flex: 9,
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <div
                    style={{ color: 'var(--corporate-color-1-light)', margin: 6 }}
                    className="font-weight-100"
                >
                    {previousVersions.length > 1 ? 'edited on' : 'created on'} {lastVersionDate}{' '}
                    {lastVersionAuthor && `by ${lastVersionAuthor}`}
                </div>
                <TopDescription top={top} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', margin: 6 }}>
                        {R.prop('status', top) && (
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <p
                                    style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                                    className="font-weight-100"
                                >
                                    {R.propOr('', 'status', top)}
                                </p>
                                <p
                                    style={{
                                        fontSize: 'var(--font-size-small)',
                                        color: 'var(--corporate-color-2)',
                                        marginTop: 3,
                                    }}
                                >
                                    Status
                                </p>
                            </div>
                        )}
                        {R.prop('dueDate', top) && (
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <p
                                    style={{
                                        textAlign: 'justify',
                                        whiteSpace: 'pre-line',
                                        color:
                                            R.propOr('', 'status', top) === 'Late'
                                                ? 'var(--corporate-color-12)'
                                                : undefined,
                                        fontWeight:
                                            R.propOr('', 'status', top) === 'Late' ? 'normal' : 100,
                                    }}
                                    className="font-weight-100"
                                >
                                    {formatShortDate(R.propOr('', 'dueDate', top))}
                                </p>
                                <p
                                    style={{
                                        fontSize: 'var(--font-size-small)',
                                        color: 'var(--corporate-color-2)',
                                        marginTop: 3,
                                    }}
                                >
                                    Due Date
                                </p>
                            </div>
                        )}
                    </div>
                    <div style={{ display: 'flex', margin: 6 }}>
                        {R.prop('phase', top) && (
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <p
                                    style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                                    className="font-weight-100"
                                >
                                    {R.propOr('', 'phase', top)}
                                </p>
                                <p
                                    style={{
                                        fontSize: 'var(--font-size-small)',
                                        color: 'var(--corporate-color-2)',
                                        marginTop: 3,
                                    }}
                                >
                                    Project Phase
                                </p>
                            </div>
                        )}
                        {R.prop('name', responsible) && (
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <p
                                    style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                                    className="font-weight-100"
                                >
                                    {R.propOr('', 'name', responsible)}
                                </p>
                                <p
                                    style={{
                                        fontSize: 'var(--font-size-small)',
                                        color: 'var(--corporate-color-2)',
                                        marginTop: 3,
                                    }}
                                >
                                    Responsible
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const TopO = ({
    stack,
    top,
    responsible,
    previousVersions,
    lastVersionAuthor,
    lastVersionDate,
}) => {
    const currency = useProjectCurrency(stack);
    const formato = val =>
        new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency,
        }).format(val);
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                overflow: 'hidden',
                paddingRight: 6,
            }}
        >
            <div style={{ display: 'flex', flex: 1 }}>
                <Button
                    className="top-type-badge top-type-hover-effect"
                    style={{
                        backgroundColor: 'var(--corporate-color-13)',
                        margin: '0 9 0 3',
                        cursor: 'default',
                        boxShadow: 'none',
                    }}
                    disabled
                >
                    O
                </Button>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 9,
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div
                        style={{ color: 'var(--corporate-color-1-light)', margin: 6 }}
                        className="font-weight-100"
                    >
                        {previousVersions.length > 1 ? 'edited on' : 'created on'} {lastVersionDate}{' '}
                        {lastVersionAuthor && `by ${lastVersionAuthor}`}
                    </div>
                    <TopDescription top={top} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', margin: 6 }}>
                            {R.prop('category', top) && (
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <p
                                        style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                                        className="font-weight-100"
                                    >
                                        {R.propOr('', 'category', top)}
                                    </p>
                                    <p
                                        style={{
                                            fontSize: 'var(--font-size-small)',
                                            color: 'var(--corporate-color-2)',
                                            marginTop: 3,
                                        }}
                                    >
                                        Category
                                    </p>
                                </div>
                            )}
                            {R.prop('status', top) && (
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <p
                                        style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                                        className="font-weight-100"
                                    >
                                        {R.propOr('', 'status', top)}
                                    </p>
                                    <p
                                        style={{
                                            fontSize: 'var(--font-size-small)',
                                            color: 'var(--corporate-color-2)',
                                            marginTop: 3,
                                        }}
                                    >
                                        Status
                                    </p>
                                </div>
                            )}
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <p
                                    style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                                    className="font-weight-100"
                                >
                                    {formato(R.propOr(0, 'savings', top))}
                                </p>
                                <p
                                    style={{
                                        fontSize: 'var(--font-size-small)',
                                        color: 'var(--corporate-color-2)',
                                        marginTop: 3,
                                    }}
                                >
                                    Savings
                                </p>
                            </div>
                        </div>
                        <div style={{ display: 'flex', margin: 6 }}>
                            {R.prop('phase', top) && (
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <p
                                        style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                                        className="font-weight-100"
                                    >
                                        {R.propOr('Not assigned', 'phase', top)}
                                    </p>
                                    <p
                                        style={{
                                            fontSize: 'var(--font-size-small)',
                                            color: 'var(--corporate-color-2)',
                                            marginTop: 3,
                                        }}
                                    >
                                        Project Phase
                                    </p>
                                </div>
                            )}
                            {R.prop('name', responsible) && (
                                <div
                                    style={{
                                        flex: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <p
                                        style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                                        className="font-weight-100"
                                    >
                                        {R.propOr('', 'name', responsible)}
                                    </p>
                                    <p
                                        style={{
                                            fontSize: 'var(--font-size-small)',
                                            color: 'var(--corporate-color-2)',
                                            marginTop: 3,
                                        }}
                                    >
                                        Responsible
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const TopR = ({
    top,
    stack,
    responsible,
    previousVersions,
    lastVersionAuthor,
    lastVersionDate,
}) => {
    const currency = useProjectCurrency(stack);
    const formato = val =>
        new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency,
        }).format(val);
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                overflow: 'hidden',
                paddingRight: 6,
            }}
        >
            <div style={{ display: 'flex', flex: 1 }}>
                <Button
                    className="top-type-badge top-type-hover-effect"
                    style={{
                        backgroundColor: 'var(--corporate-color-12)',
                        margin: '0 9 0 3',
                        cursor: 'default',
                        boxShadow: 'none',
                    }}
                    disabled
                >
                    R
                </Button>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 9,
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div
                        style={{ color: 'var(--corporate-color-1-light)', margin: 6 }}
                        className="font-weight-100"
                    >
                        {previousVersions.length > 1 ? 'edited on' : 'created on'} {lastVersionDate}{' '}
                        {lastVersionAuthor && `by ${lastVersionAuthor}`}
                    </div>
                    <TopDescription top={top} />
                    <div style={{ display: 'flex', alignItems: 'flex-start', margin: 6 }}>
                        {R.prop('category', top) && (
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <p
                                    style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                                    className="font-weight-100"
                                >
                                    {R.propOr('', 'category', top)}
                                </p>
                                <p
                                    style={{
                                        fontSize: 'var(--font-size-small)',
                                        color: 'var(--corporate-color-2)',
                                        marginTop: 3,
                                    }}
                                >
                                    Category
                                </p>
                            </div>
                        )}
                        {R.prop('reference', top) && (
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <p
                                    style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                                    className="font-weight-100"
                                >
                                    {R.propOr('', 'reference', top)}
                                </p>
                                <p
                                    style={{
                                        fontSize: 'var(--font-size-small)',
                                        color: 'var(--corporate-color-2)',
                                        marginTop: 3,
                                    }}
                                >
                                    Reference
                                </p>
                            </div>
                        )}
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p
                                style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                                className="font-weight-100"
                            >
                                {formato(R.propOr(0, 'costIncrease', top))}
                            </p>
                            <p
                                style={{
                                    fontSize: 'var(--font-size-small)',
                                    color: 'var(--corporate-color-2)',
                                    marginTop: 3,
                                }}
                            >
                                Cost Increase
                            </p>
                        </div>
                        {R.prop('phase', top) && (
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <p
                                    style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                                    className="font-weight-100"
                                >
                                    {R.propOr('Not assigned', 'phase', top)}
                                </p>
                                <p
                                    style={{
                                        fontSize: 'var(--font-size-small)',
                                        color: 'var(--corporate-color-2)',
                                        marginTop: 3,
                                    }}
                                >
                                    Project Phase
                                </p>
                            </div>
                        )}
                        {R.prop('name', responsible) && (
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <p
                                    style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                                    className="font-weight-100"
                                >
                                    {R.propOr('', 'name', responsible)}
                                </p>
                                <p
                                    style={{
                                        fontSize: 'var(--font-size-small)',
                                        color: 'var(--corporate-color-2)',
                                        marginTop: 3,
                                    }}
                                >
                                    Responsible
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const TopS = ({ top, previousVersions, lastVersionAuthor, lastVersionDate }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
            paddingRight: 6,
        }}
    >
        <div style={{ display: 'flex', flex: 1 }}>
            <Button
                className="top-type-badge top-type-hover-effect"
                style={{
                    backgroundColor: 'grey',
                    margin: '0 9 0 3',
                    cursor: 'default',
                    boxShadow: 'none',
                }}
                disabled
            >
                S
            </Button>
        </div>
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flex: 9,
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <div
                    style={{ color: 'var(--corporate-color-1-light)', margin: 6 }}
                    className="font-weight-100"
                >
                    {previousVersions.length > 1 ? 'edited on' : 'created on'} {lastVersionDate}{' '}
                    {lastVersionAuthor && `by ${lastVersionAuthor}`}
                </div>
                <TopDescription top={top} />
                <div style={{ display: 'flex', alignItems: 'flex-start', margin: 6 }}>
                    {R.prop('phase', top) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p
                                style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                                className="font-weight-100"
                            >
                                {R.propOr('Not assigned', 'phase', top)}
                            </p>
                            <p
                                style={{
                                    fontSize: 'var(--font-size-small)',
                                    color: 'var(--corporate-color-2)',
                                    marginTop: 3,
                                }}
                            >
                                Phase
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
);

const TopI = ({ top, previousVersions, lastVersionAuthor, lastVersionDate }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
            paddingRight: 6,
        }}
    >
        <div style={{ display: 'flex', flex: 1 }}>
            <Button
                className="top-type-badge top-type-hover-effect"
                style={{
                    backgroundColor: '#C5C9D3',
                    margin: '0 9 0 3',
                    cursor: 'default',
                    boxShadow: 'none',
                }}
                disabled
            >
                I
            </Button>
        </div>
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flex: 9,
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <div
                    style={{ color: 'var(--corporate-color-1-light)', margin: 6 }}
                    className="font-weight-100"
                >
                    {previousVersions.length > 1 ? 'edited on' : 'created on'} {lastVersionDate}{' '}
                    {lastVersionAuthor && `by ${lastVersionAuthor}`}
                </div>
                <TopDescription top={top} />
                <div style={{ display: 'flex', alignItems: 'flex-start', margin: 6 }}>
                    {R.prop('phase', top) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p
                                style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                                className="font-weight-100"
                            >
                                {R.propOr('Not assigned', 'phase', top)}
                            </p>
                            <p
                                style={{
                                    fontSize: 'var(--font-size-small)',
                                    color: 'var(--corporate-color-2)',
                                    marginTop: 3,
                                }}
                            >
                                Phase
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
);

const TopD = ({ top, previousVersions, lastVersionAuthor, lastVersionDate }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
            paddingRight: 6,
        }}
    >
        <div style={{ display: 'flex', flex: 1 }}>
            <Button
                className="top-type-badge top-type-hover-effect"
                style={{
                    backgroundColor: 'var(--corporate-color-7)',
                    margin: '0 9 0 3',
                    cursor: 'default',
                    boxShadow: 'none',
                }}
                disabled
            >
                D
            </Button>
        </div>
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flex: 9,
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <div
                    style={{ color: 'var(--corporate-color-1-light)', margin: 6 }}
                    className="font-weight-100"
                >
                    {previousVersions.length > 1 ? 'edited on' : 'created on'} {lastVersionDate}{' '}
                    {lastVersionAuthor && `by ${lastVersionAuthor}`}
                </div>
                <TopDescription top={top} />
                <div style={{ display: 'flex', alignItems: 'flex-start', margin: 6 }}>
                    {R.prop('phase', top) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p
                                style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}
                                className="font-weight-100"
                            >
                                {R.propOr('Not assigned', 'phase', top)}
                            </p>
                            <p
                                style={{
                                    fontSize: 'var(--font-size-small)',
                                    color: 'var(--corporate-color-2)',
                                    marginTop: 3,
                                }}
                            >
                                Phase
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
);

const TopDescription = ({ top }) => {
    const [maxout, setMaxout] = useState(false);
    return (
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            {R.prop('description', top) && (
                <>
                    <div
                        style={{
                            margin: 6,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            flex: 1,
                        }}
                    >
                        <p
                            style={{
                                textAlign: 'start',
                                whiteSpace: 'pre-line',
                            }}
                            className="font-weight-100"
                        >
                            {maxout
                                ? R.propOr('', 'description', top)
                                : `${R.slice(0, 100, R.propOr('', 'description', top))}${
                                      R.length(R.propOr('', 'description', top)) > 100 ? '...' : ''
                                  }${
                                      R.length(R.propOr('', 'description', top)) > 100 ? '...' : ''
                                  }`}
                        </p>
                        <p
                            style={{
                                fontSize: 'var(--font-size-small)',
                                color: 'var(--corporate-color-2)',
                                marginTop: 3,
                            }}
                        >
                            Description
                        </p>
                    </div>
                    {R.length(R.propOr('', 'description', top)) > 100 && (
                        <Button onClick={() => setMaxout(!maxout)}>
                            <IoEllipse
                                size="0.75rem"
                                color={
                                    !maxout
                                        ? 'var(--corporate-color-13)'
                                        : 'var(--corporate-color-7)'
                                }
                            />
                        </Button>
                    )}
                </>
            )}
        </div>
    );
};
