import React from 'react';
import { colors } from '../../../../common/consts';
import { useActionStatuses } from '../hooks/useActionStatuses';
import { Button } from '../../../Common/Controls';

export const LegendButton = ({ status, color, onClick, active }) => {
    const styleObj = active
        ? {
              backgroundColor: 'white',
              boxShadow: '0 5px 8px -2px var(--corporate-color-1-light4)',
          }
        : {
              backgroundColor: 'transparent',
          };

    return (
        <Button
            onClick={() => {
                onClick();
            }}
            className="hover-scale"
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                border: 'none',
                boxSizing: 'borderBox',
                paddingLeft: status !== 'All' && 3,
                ...styleObj,
            }}
        >
            {color && <div className="legend-color" style={{ backgroundColor: color }} />}
            <div>{status}</div>
        </Button>
    );
};

export const Legend = ({ stack }) => {
    const labels = useActionStatuses(stack);
    const legend = labels.map((st, i) => {
        const el = { label: st, color: colors[i] };
        return el;
    });
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: '3rem',
                width: '100%',
                marginTop: '5rem',
            }}
        >
            {legend.map(item => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key={item.label}
                >
                    <div className="legend-color" style={{ backgroundColor: item.color }} />
                    <div className="legend-label">{item.label}</div>
                </div>
            ))}
        </div>
    );
};
