import React from 'react';
import './ModalMobile.css';
import { IoAddOutline, IoChevronBackOutline, IoSaveOutline } from 'react-icons/io5';
import { useModalState } from '../../hooks/useModal';
import { Button } from '../../Components/Common/Controls';
import { useToast } from '../../hooks/useToast';
import { createOfflinePush } from '../../common/got-adjunct';

export const ModalMobile = ({
    name,
    children,
    close,
    clear,
    style,
    contentContainerStyle,
    isOpen,
    onSave,
    onBack,
    stack,
    add,
    hideButtons,
}) => {
    const [_isOpen, _close] = useModalState(name);
    const doClose = () => {
        name && _close();
        close && close();
        clear && clear();
    };

    const setToast = useToast('mobile-project-dashboard');

    const save = (startText, endText) => {
        const toastConfig = {
            textOnStart: startText || 'Saving data...',
            textOnSuccess: endText || 'Succesfully saved...',
        };
        const push = createOfflinePush(stack, setToast, toastConfig);
        return push();
    };

    return (
        <div className={`modal-backdrop-mobile${_isOpen || isOpen ? ' open' : ''}`}>
            <div
                style={style}
                className="modal-container-mobile"
                onClick={e => e.stopPropagation()}
            >
                <div className="modal-content-container-mobile" style={contentContainerStyle}>
                    {children}
                </div>
            </div>
            {!hideButtons && (
                <>
                    <Button
                        onClick={() => {
                            onBack && onBack();
                            doClose();
                        }}
                        className="back-button"
                    >
                        <IoChevronBackOutline
                            size="6rem"
                            style={{ marginRight: '0.5rem', color: 'var(--corporate-color-2)' }}
                        />
                    </Button>
                    {onSave && (
                        <Button
                            onClick={() => {
                                save();
                                doClose();
                            }}
                            className="save-modal-button"
                        >
                            <IoSaveOutline size="5rem" />
                        </Button>
                    )}
                    {add && (
                        <Button onClick={() => add()} className="add-button zIndex10000">
                            <IoAddOutline size="8rem" />
                        </Button>
                    )}
                </>
            )}
        </div>
    );
};
