import { useMemo } from 'react';
import { sortBagsByOrder } from '../common/got-adjunct';
import { MEETING_TOP_EDGE, TOP_RESPONSIBLE_EDGE, useGraph } from '../common/hooks.config.new';

export const useMeetingTops = (stack, meetingId) => {
    const { useView } = useGraph(...stack);
    const {
        [meetingId]: { tops: topBags },
    } = useView({
        [meetingId]: {
            edges: {
                [MEETING_TOP_EDGE]: {
                    as: 'tops',
                    include: {
                        node: true,
                        edges: true,
                        metadata: true,
                    },
                },
            },
        },
    });
    return useMemo(() => sortBagsByOrder(topBags), [topBags]);
};

export const useMeetingTopsWithResponsibles = (stack, meetingId) => {
    const { useView } = useGraph(...stack);
    const {
        [meetingId]: { tops: topBags },
    } = useView({
        [meetingId]: {
            edges: {
                [MEETING_TOP_EDGE]: {
                    as: 'tops',
                    include: {
                        node: true,
                        edges: true,
                        metadata: true,
                    },
                    edges: {
                        [TOP_RESPONSIBLE_EDGE]: {
                            as: 'responsibles',
                            include: {
                                node: true,
                                edge: true,
                                metadata: true,
                            },
                        },
                    },
                },
            },
        },
    });
    return useMemo(() => sortBagsByOrder(topBags), [topBags]);
};
