import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { api, store } from './hooks.config.new';

export const useAuth = message => {
    const dispatch = useDispatch();
    const [loggedIn, setLoggedIn] = useState(false);

    const login = () => {
        setLoggedIn(true);
    };

    const handleLogout = () => {
        store.clearAll();
        dispatch({ type: 'persist/PURGE', result: () => {} });
        api.logout();
        setLoggedIn(false);
    };

    const logout = () => {
        // eslint-disable-next-line no-restricted-globals
        const cnfrm = confirm(
            message ||
                'Offline data will be lost on logout. Make sure there is no red sync sign in the top right corner!',
        );
        if (cnfrm) {
            handleLogout();
            window.location.reload();
        }
    };

    useEffect(() => {
        if (api.getCurrentUser()) {
            login();
        } else {
            handleLogout();
        }
    }, []);

    return [loggedIn, login, logout];
};
