import React, { useState } from 'react';
import * as R from 'ramda';
import { IoCloseOutline, IoCreateOutline, IoEllipseSharp } from 'react-icons/io5';
import { useNodeFromVar } from '../../hooks/useNode';
import { PROJECT_ID_KEY } from '../../common/hooks.config.new';
import { formatShortDate, newId, newIsoDate } from '../../common/util';
import { useTimelineMeetings } from '../../hooks/useTimelineMeetings';
import { useNavigator } from '../../hooks/useNavigator';
import { useModal } from '../../hooks/useModal';
import { Button } from '../../Components/Common/Controls';
import { ListRow } from '../../Components/Common/ListRow';

export const MeetingsTimelineMobile = ({ stack, meetings, filter }) => {
    const { node: project } = useNodeFromVar(stack, PROJECT_ID_KEY);

    const [showDetails, setShowDetails] = useState({});
    const [hideMonth, setHideMonth] = useState({});
    const startDateToDisplay =
        formatShortDate(filter?.meetingDate?.from) ||
        formatShortDate(project?.createdDate) ||
        formatShortDate(R.last(meetings)?.node?.date) ||
        'Start date';
    const endDateToDisplay =
        formatShortDate(filter?.meetingDate?.to) || formatShortDate(newIsoDate()) || 'End date';
    const getMeetingsPerMonth = useTimelineMeetings(
        endDateToDisplay,
        startDateToDisplay,
        meetings,
        filter,
    );
    const { navigateToProtocol } = useNavigator(stack);
    const openProtocolPdf = useModal('protocol-pdf');
    const descending = R.pathOr(false, ['sort', 'direction'], filter) === 'desc' && true;

    return (
        <>
            <div
                className="list-indicator-container"
                style={{ justifyContent: 'space-between', marginTop: '3rem' }}
            >
                {R.length(meetings) > 0 && (
                    <Button
                        onClick={() => {
                            setShowDetails(
                                R.fromPairs(
                                    R.map(m => {
                                        const id = m.nodeId;
                                        return [id, true];
                                    })(meetings),
                                ),
                            );
                            !R.isEmpty(showDetails) && setShowDetails({});
                        }}
                        style={{ backgroundColor: 'white' }}
                    >
                        <IoEllipseSharp
                            size="3.5rem"
                            color={
                                !R.isEmpty(showDetails)
                                    ? 'var(--corporate-color-7)'
                                    : 'var(--corporate-color-13)'
                            }
                        />
                        <p
                            style={{
                                fontSize: 'var(--font-size-mobile-normal)',
                                marginLeft: '1rem',
                            }}
                        >
                            {!R.isEmpty(showDetails) ? 'Collapse all' : 'Expand all'}
                        </p>
                    </Button>
                )}
            </div>
            {R.length(meetings) > 0 ? (
                <div className="timeline-container-mobile">
                    <div className="date-badge-mobile">
                        {descending ? startDateToDisplay : endDateToDisplay}
                    </div>
                    <div className="timeline-inner-container-mobile">
                        <div
                            className="timeline-line-mobile"
                            style={{ padding: descending && '30 0 0 0' }}
                        >
                            {R.map(year => (
                                <div key={year}>
                                    {R.map(
                                        month =>
                                            R.length(month.meetings) > 0 && (
                                                <MonthSection
                                                    key={newId()}
                                                    stack={stack}
                                                    month={month}
                                                    hideMonth={hideMonth}
                                                    setHideMonth={setHideMonth}
                                                    showDetails={showDetails}
                                                    setShowDetails={setShowDetails}
                                                    navigateToProtocol={navigateToProtocol}
                                                    openProtocolPdf={openProtocolPdf}
                                                    year={year}
                                                    descending={descending}
                                                />
                                            ),
                                    )(
                                        descending
                                            ? year?.meetingsPerMonth
                                            : R.reverse(year?.meetingsPerMonth),
                                    )}
                                </div>
                            ))(
                                descending
                                    ? getMeetingsPerMonth()
                                    : R.reverse(getMeetingsPerMonth()),
                            )}
                        </div>
                    </div>
                    <div className="date-badge-mobile">
                        {descending ? endDateToDisplay : startDateToDisplay}
                    </div>
                </div>
            ) : (
                <div className="dashboard-message-mobile">No meetings found</div>
            )}
        </>
    );
};

const MonthSection = ({
    stack,
    month,
    hideMonth,
    showDetails,
    setShowDetails,
    navigateToProtocol,
    openProtocolPdf,
    year,
    descending,
}) => (
    <>
        {descending && <div className="month-badge-mobile">{`${month.name} ${year.year}`}</div>}
        <div style={{ position: 'relative', left: '-0.3rem', top: '1rem' }}>
            {/* <Button
                style={{
                    color: R.propEq(month.monthId, true, hideMonth) ? 'var(--corporate-color-13)' : 'var(--corporate-color-7)',
                }}
                onClick={() => {
                    !R.propEq(month.monthId, true, hideMonth)
                        ? setHideMonth({ ...hideMonth, [month.monthId]: true })
                        : setHideMonth({ ...hideMonth, [month.monthId]: false });
                }}
            >
                <IoEllipseSharp size="3.5rem" />
            </Button> */}
        </div>
        {!R.propEq(month.monthId, true, hideMonth) && (
            <>
                {R.map(meeting => (
                    <div
                        key={meeting.nodeId}
                        className="timeline-row-mobile"
                        style={{
                            flexDirection: R.propEq(meeting.nodeId, true, showDetails) && 'column',
                            marginTop: R.propEq(meeting.nodeId, true, showDetails) && 24,
                        }}
                    >
                        <div
                            className="date-badge-light-mobile"
                            style={{
                                marginTop: R.propEq(meeting.nodeId, true, showDetails) && '3rem',
                                borderColor:
                                    R.propEq(meeting.nodeId, true, showDetails) && 'transparent',
                                boxShadow:
                                    R.propEq(meeting.nodeId, true, showDetails) &&
                                    '0 5px 8px -2px var(--shadow-color-normal)',
                            }}
                        >
                            {formatShortDate(meeting.node.date)}
                        </div>
                        {!R.propEq(meeting.nodeId, true, showDetails) && (
                            <div className="timeline-row-line-mobile" />
                        )}
                        {R.propEq(meeting.nodeId, true, showDetails) ? (
                            <DetailedMeeting
                                stack={stack}
                                meeting={meeting}
                                showDetails={showDetails}
                                setShowDetails={setShowDetails}
                                navigateToProtocol={navigateToProtocol}
                                openProtocolPdf={openProtocolPdf}
                            />
                        ) : (
                            <div
                                className="meeting-badge-mobile"
                                onClick={() =>
                                    setShowDetails({ ...showDetails, [meeting.nodeId]: true })
                                }
                            >
                                {meeting.node.businessId || 'No meeting ID'}
                            </div>
                        )}
                    </div>
                ))(descending ? R.reverse(month.meetings) : month.meetings)}
            </>
        )}
        {!R.propEq(month.monthId, true, hideMonth) && (
            <div style={{ position: 'relative', left: '-0.3rem', top: '-1rem' }}>
                {/* <Button
                style={{
                    color: R.propEq(month.monthId, true, hideMonth) ? 'var(--corporate-color-13)' : 'var(--corporate-color-7)',
                }}
                onClick={() => {
                    !R.propEq(month.monthId, true, hideMonth)
                        ? setHideMonth({ ...hideMonth, [month.monthId]: true })
                        : setHideMonth({ ...hideMonth, [month.monthId]: false });
                }}
            >
                <IoEllipseSharp size="3.5rem" />
            </Button> */}
            </div>
        )}
        {!descending && <div className="month-badge-mobile">{`${month.name} ${year.year}`}</div>}
    </>
);

const DetailedMeeting = ({ meeting, showDetails, setShowDetails, navigateToProtocol }) => (
    <div className="scale-animation">
        <ListRow key={meeting.nodeId} className="detailed-meeting-container-mobile">
            <div className="dashboard-meeting-button-row-mobile">
                <div style={{ display: 'flex', fontSize: 'var(--font-size-mobile-medium)' }}>
                    {meeting.node.businessId}
                </div>
                <Button
                    onClick={() => setShowDetails(R.omit([meeting.nodeId], showDetails))}
                    className="button-icon"
                    style={{ color: 'white' }}
                >
                    <IoCloseOutline size="4rem" />
                </Button>
            </div>
            <div style={{ padding: '2rem', background: 'white' }}>
                {meeting.node.title && (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginBottom: '2rem',
                        }}
                    >
                        <p className="top-info-mobile">
                            {R.pathOr('', ['node', 'title'], meeting)}
                        </p>
                        <p className="top-info-label-mobile">Meeting Title</p>
                    </div>
                )}
                {meeting.node.location && (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginBottom: '2rem',
                        }}
                    >
                        <p className="top-info-mobile">
                            {R.pathOr('', ['node', 'location'], meeting)}
                        </p>
                        <p className="top-info-label-mobile">Meeting Location</p>
                    </div>
                )}
                <div className="detailed-meeting-buttons-mobile">
                    {!meeting.node.closed && (
                        <Button
                            onClick={() => navigateToProtocol(meeting.nodeId)}
                            style={{ marginRight: 6, backgroundColor: 'transparent' }}
                        >
                            <IoCreateOutline size="5rem" />
                        </Button>
                    )}
                    {/* <Button
                        onClick={() => openProtocolPdf({ id: meeting.nodeId })}
                    >
                        <IoDocumentAttachOutline size="4.5rem" />
                        <p style={{ fontSize: 'var(--font-size-mobile-normal)', color: 'var(--corporate-color-2)' }}>Show PDF / Files</p>
                    </Button> */}
                </div>
            </div>
        </ListRow>
    </div>
);
