import React from 'react';

const gap = '0.5rem';
const height = '1.5rem';

const Bar = ({ index, data, colors, max, handleHoverStart, handleHoverStop, onClick }) => {
    const width = `${(data / max) * 100}%`;
    return (
        <div
            className="bar"
            onMouseEnter={() => {
                handleHoverStart(index);
            }}
            onMouseLeave={handleHoverStop}
            onClick={() => {
                onClick(index);
            }}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width,
                backgroundColor: colors[index],
                borderRadius: '0rem 1rem 1rem 0rem',
                border: '1px solid white',
                borderLeft: 'none',
                boxSizing: 'border-box',
            }}
        />
    );
};

const LayeredBar = ({
    indexI,
    indexJ,
    value,
    max,
    color,
    zIndex,
    handleHoverStart,
    handleHoverStop,
    onClick,
    reverse,
}) => {
    const width = `${(value / max) * 100}%`;

    const styleObj = reverse
        ? {
              borderRadius: '1rem 0rem 0rem 1rem',
              right: 0,
              borderRight: 'none',
              transformOrigin: 'right',
          }
        : {
              borderRadius: '0rem 1rem 1rem 0rem',
              left: 0,
              borderLeft: 'none',
          };
    return (
        <div
            className="bar scale-x"
            onMouseEnter={() => {
                handleHoverStart(indexI, indexJ);
            }}
            onMouseLeave={handleHoverStop}
            onClick={() => {
                onClick(indexI, indexJ);
            }}
            style={{
                position: 'absolute',
                top: 0,
                height: '100%',
                width,
                backgroundColor: color,
                zIndex,
                boxSizing: 'border-box',
                border: '1px solid white',
                transition: 'width 0.5s',
                ...styleObj,
            }}
        />
    );
};

const layerData = arr => {
    const layeredArr = [];
    let acc = 0;
    for (let i = 0; i < arr.length; i += 1) {
        const val = arr[i] || 0;
        acc += val;
        layeredArr[i] = acc;
    }

    return layeredArr;
};

const LayeredBars = ({
    index,
    data,
    colors,
    max,
    handleHoverStart,
    handleHoverStop,
    onClick,
    reverse,
}) => {
    const layeredData = layerData(data);

    return data.map((d, j) => (
        <LayeredBar
            // eslint-disable-next-line react/no-array-index-key
            key={j}
            indexI={index}
            indexJ={j}
            zIndex={data.length - j}
            value={layeredData[j]}
            color={colors[j]}
            max={max}
            handleHoverStart={handleHoverStart}
            handleHoverStop={handleHoverStop}
            onClick={onClick}
            reverse={reverse}
        />
    ));
};

const AxisIndicator = ({ left, right, val }) => (
    <>
        <div
            style={{
                position: 'absolute',
                height: 'calc(100% + 1rem)',
                top: '-0.5rem',
                width: '1px',
                backgroundColor: 'var(--corporate-color-1-light5)',
                left,
                right,
            }}
        />
        <div
            style={{
                position: 'absolute',
                left,
                right,
                top: '-1rem',
                color: 'var(--corporate-color-1-light2)',
                transform: left ? 'translate(-50%, -100%)' : 'translate(50%, -100%)',
            }}
        >
            {val}
        </div>
        <div
            style={{
                position: 'absolute',
                left,
                right,
                top: 'calc(100% + 1rem)',
                color: 'var(--corporate-color-1-light2)',
                transform: left ? 'translate(-50%, 0)' : 'translate(50%, 0)',
            }}
        >
            {val}
        </div>
    </>
);

const AxisIndicators = ({ max, reverse }) => {
    const maxInt = Math.floor(max);
    const divisor = Math.ceil(maxInt / 15);
    const arr = new Array(Math.floor(max / divisor) + 1).fill(0);

    return (
        <>
            {reverse &&
                arr.map((_, i) => (
                    <AxisIndicator right={`${(i * divisor * 100) / max}%`} val={i * divisor} />
                ))}
            {!reverse &&
                arr.map((_, i) => (
                    <AxisIndicator left={`${(i * divisor * 100) / max}%`} val={i * divisor} />
                ))}
        </>
    );
};

const createBarChart = (BarComponent, fnMax, layered) => {
    const INNER_COMPONENT = ({
        data,
        labels,
        colors,
        handleHoverStart,
        handleHoverStop,
        onClick,
        reverse,
    }) => {
        const max = fnMax(data);

        return (
            <div
                className="horizontal-bar-chart fader"
                style={{
                    display: 'flex',
                    flexDirection: reverse ? 'row-reverse' : 'row',
                    alignItems: 'center',
                    marginTop: '2rem',
                    marginBottom: '2rem',
                }}
            >
                <div
                    className="labels"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap,
                    }}
                >
                    {data.map((_, i) => (
                        <div
                            key={i || ''}
                            className="label"
                            onMouseEnter={() => {
                                handleHoverStart(i);
                            }}
                            onMouseLeave={handleHoverStop}
                            onClick={() => {
                                onClick(i);
                            }}
                            style={{
                                height,
                                display: 'flex',
                                alignItems: 'center',
                                paddingRight: '1rem',
                                paddingLeft: '1rem',
                            }}
                        >
                            {labels[i] || ''}
                        </div>
                    ))}
                </div>
                <div
                    className={`bars ${layered ? '' : 'scale-x'}`}
                    style={{
                        position: 'relative',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap,
                    }}
                >
                    <AxisIndicators max={max} reverse={reverse} />
                    {data.map((d, i) => (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            className="layered-bars"
                            style={{
                                position: 'relative',
                                width: '100%',
                                height,
                            }}
                        >
                            <BarComponent
                                index={i}
                                key={labels[i]}
                                data={d}
                                colors={colors}
                                max={max}
                                handleHoverStart={handleHoverStart}
                                handleHoverStop={handleHoverStop}
                                onClick={onClick}
                                reverse={reverse}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return INNER_COMPONENT;
};

const maxLayered = data => {
    const max = data.reduce((acc, d) => {
        const sum = d.reduce((a, b) => a + b, 0);
        return Math.max(acc, sum);
    }, 1);
    return max;
};

const maxSimple = data => {
    const max = data.reduce((acc, d) => Math.max(acc, d), 1);
    return max;
};

export const BarChart = createBarChart(Bar, maxSimple);
export const LayeredBarChart = createBarChart(LayeredBars, maxLayered, true);
