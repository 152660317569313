import React from 'react';
import { AppDesktop } from './AppDesktop';
import { AppMobile } from './AppMobile';

const App = () => {
    const mediaQuery = window.matchMedia(
        'screen and (max-width: 1170px) and (orientation: portrait)',
    );
    if (mediaQuery.matches) {
        return <AppMobile />;
    }

    return <AppDesktop />;
};

export default App;
