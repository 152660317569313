import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import {
    IoAddOutline,
    IoArrowForwardOutline,
    IoChevronForwardOutline,
    IoEllipsisHorizontalOutline,
    IoSearchOutline,
    IoTrashOutline,
} from 'react-icons/io5';
import { useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { Button } from '../Common/Controls';
import { DateField, TextField } from '../Common/Fields';
import { useNodeFromVar } from '../../hooks/useNode';
import { PROJECT_ID_KEY, PROJECT_PHASES_MODAL, useGraph } from '../../common/hooks.config.new';
import { useToast } from '../../hooks/useToast';
import { createOfflinePush } from '../../common/got-adjunct';
import { newId, newIsoDate } from '../../common/util';
import { SaveButton } from '../Elements/SaveButton';
import { Spacer } from '../Elements/Spacer';

export const ProjectPhasesModal = ({ stack }) => {
    const [isOpen, close] = useModalState(PROJECT_PHASES_MODAL);
    return (
        <Modal
            name={PROJECT_PHASES_MODAL}
            style={{
                height: '90vh',
                top: '5vh',
                width: '50vw',
                left: '25vw',
            }}
            topbar={<IoEllipsisHorizontalOutline size={26} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <ModalContent stack={stack} close={close} /> : null}
        </Modal>
    );
};

const ModalContent = ({ stack: parentStack, close }) => {
    const stack = R.append(PROJECT_PHASES_MODAL, parentStack);
    const { update } = useGraph(...stack);
    const { node: project } = useNodeFromVar(stack, PROJECT_ID_KEY);
    const projectPhases = R.propOr({}, 'phases', project);
    const [search, setSearch] = useState(false);
    const [phaseSearch, setPhaseSearch] = useState('');
    const phasesSearch = searchString => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]+[u00C0-u017F]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.compose(
                R.sortBy(R.prop('name')),
                R.filter(p => p.name && p.name.toUpperCase().search(string) !== -1),
            )(R.values(projectPhases));
        }
        return R.values(projectPhases);
    };

    const inputRef = useRef();
    useEffect(() => {
        search && setTimeout(() => inputRef.current.focus(), 600);
    }, [search]);

    const setDashbaordToast = useToast('dashboard');
    const save = () => {
        setDashbaordToast('spinner', 'Saving phases data...');
        const toastConfig = {
            textOnStart: 'Saving phases data...',
            textOnSuccess: 'Succesfully saved...',
        };
        const push = createOfflinePush(stack, setDashbaordToast, toastConfig);
        return push();
    };

    return (
        <div className="columns">
            <div>
                <Headline
                    project={project}
                    update={update}
                    search={search}
                    setSearch={setSearch}
                    phaseSearch={phaseSearch}
                    setPhaseSearch={setPhaseSearch}
                />
                {RA.mapIndexed(phase => (
                    <ProjectPhase update={update} project={project} phaseId={phase.id} />
                ))(phasesSearch(phaseSearch))}
                {R.length(R.values(projectPhases)) > 0 && (
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            style={{ paddingLeft: 0, width: 200 }}
                            onClick={() => {
                                const newPhaseId = newId();
                                const newPhase = {
                                    id: newPhaseId,
                                    createdDate: newIsoDate(),
                                };
                                update(R.assocPath(['phases', newPhaseId], newPhase, project));
                            }}
                        >
                            <IoAddOutline size={23} />
                            <p style={{ marginLeft: 5 }}>Create project phase</p>
                        </Button>
                    </div>
                )}
                <Spacer />
                <SaveButton save={save} close={close} />
            </div>
        </div>
    );
};

const Headline = ({ project, update, search, setSearch, phaseSearch, setPhaseSearch }) => {
    const inputRef = useRef();
    useEffect(() => {
        search && setTimeout(() => inputRef.current.focus(), 600);
    }, [search]);
    return (
        <div className="modal-headline">
            <div style={{ flex: 1 }}>Project phases</div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                <Button
                    style={{ paddingLeft: 12 }}
                    onClick={() => {
                        const newPhaseId = newId();
                        const newPhase = {
                            id: newPhaseId,
                            createdDate: newIsoDate(),
                        };
                        update(R.assocPath(['phases', newPhaseId], newPhase, project));
                    }}
                >
                    <IoAddOutline size={23} />
                    <p style={{ marginLeft: 5 }}>Create project phase</p>
                </Button>
                <Button style={{ marginLeft: 6 }} onClick={() => setSearch(!search)}>
                    {search ? <IoChevronForwardOutline size={19} /> : <IoSearchOutline size={21} />}
                </Button>
                {search && (
                    <div className="animated-searchbar" style={{ flex: 1 }}>
                        <input
                            ref={inputRef}
                            placeholder="search project milestones..."
                            value={phaseSearch}
                            onChange={e => setPhaseSearch(e.target.value)}
                        />
                        <Button style={{ marginLeft: 3 }} onClick={() => setPhaseSearch('')}>
                            <IoTrashOutline size={17} />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

const ProjectPhase = ({ update, project, phaseId }) => (
    <div className="project-phase">
        <div className="modal-subheadline" style={{ margin: '0 0 12 0' }}>
            {R.pathOr('New project phase', ['phases', phaseId, 'name'], project)}
            <Button onClick={() => update(R.dissocPath(['phases', phaseId], project))}>
                <IoTrashOutline />
            </Button>
        </div>
        <TextField
            realtime
            style={{ resize: 'none' }}
            label="Phase name"
            rows="6"
            value={R.pathOr('', ['phases', phaseId, 'name'], project)}
            onChange={value => update(R.assocPath(['phases', phaseId, 'name'], value, project))}
            editable
        />
        {/* <LargeTextField
            style={{ resize: 'none' }}
            label="Phase description"
            rows="6"
            value={R.pathOr('', ['phases', phaseId, 'description'], project)}
            onChange={value => update(R.assocPath(['phases', phaseId, 'description'], value, project))}
            editable
        /> */}
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 12,
                flex: 1,
                justifyContent: 'center',
            }}
        >
            <DateField
                className="medium"
                label="Start Date"
                locale="en-gb"
                value={R.pathOr('', ['phases', phaseId, 'startDate'], project)}
                onChange={value =>
                    update(R.assocPath(['phases', phaseId, 'startDate'], value, project))
                }
                editable
            />
            <IoArrowForwardOutline
                style={{ margin: '0 12 12 8' }}
                size={20}
                color="var(--corporate-color-2)"
            />
            <DateField
                className="medium"
                label="End Date"
                locale="en-gb"
                value={R.pathOr('', ['phases', phaseId, 'endDate'], project)}
                onChange={value =>
                    update(R.assocPath(['phases', phaseId, 'endDate'], value, project))
                }
                editable
            />
        </div>
    </div>
);
