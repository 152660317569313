import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import {
    IoChevronForwardOutline,
    IoRemoveOutline,
    IoDocumentOutline,
    IoSearchOutline,
    IoTrashOutline,
} from 'react-icons/io5';
import { Modal } from '../Common/Modal';
import { ListField } from '../Common/Fields';
import { useModalState } from '../../hooks/useModal';
import { formatShortDate } from '../../common/util';
import { Button } from '../Common/Controls';

export const SubprojectMeetingsModal = ({ openProtocolPdf, meetingChoices, subproject }) => {
    const [isOpen] = useModalState('subproject-meetings');
    return (
        <Modal
            name="subproject-meetings"
            style={{
                width: '70vw',
                left: '15vw',
                height: '90vh',
                top: '5vh',
            }}
            topbar={<IoDocumentOutline size={23} color="var(--corporate-color-7)" />}
        >
            {isOpen ? (
                <ModalContent
                    openProtocolPdf={openProtocolPdf}
                    meetingChoices={meetingChoices}
                    subproject={subproject}
                />
            ) : null}
        </Modal>
    );
};

export const ModalContent = ({ openProtocolPdf, meetingChoices, subproject }) => {
    const meetingSearch = searchString => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]+[u00C0-u017F]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.filter(m => m.body.title && m.body.title.toUpperCase().search(string) !== -1)(
                meetingChoices,
            );
        }
        return meetingChoices;
    };
    const [search, setSearch] = useState(false);
    const [minutesSearch, setMinutesSearch] = useState('');
    return (
        <div className="columns">
            <div>
                <Headline
                    search={search}
                    setSearch={setSearch}
                    subproject={subproject}
                    minutesSearch={minutesSearch}
                    setMinutesSearch={setMinutesSearch}
                />
                <MinutesList
                    meetingSearch={meetingSearch}
                    minutesSearch={minutesSearch}
                    openProtocolPdf={openProtocolPdf}
                />
            </div>
        </div>
    );
};

const Headline = ({ search, setSearch, subproject, minutesSearch, setMinutesSearch }) => {
    const inputRef = useRef();
    useEffect(() => {
        search && setTimeout(() => inputRef.current.focus(), 600);
    }, [search]);
    return (
        <div className="modal-headline">
            <div>
                <p style={{ fontSize: 'inherit', marginRight: 6 }}>Subproject Minutes</p>
                <IoRemoveOutline size={22} />
                <p style={{ fontSize: 'inherit', marginLeft: 6 }}>{subproject.title}</p>
            </div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                <Button style={{ marginLeft: 6 }} onClick={() => setSearch(!search)}>
                    {search ? <IoChevronForwardOutline size={19} /> : <IoSearchOutline size={21} />}
                </Button>
                {search && (
                    <div className="animated-searchbar">
                        <input
                            ref={inputRef}
                            placeholder="search minutes..."
                            value={minutesSearch}
                            onChange={e => setMinutesSearch(e.target.value)}
                        />
                        <Button style={{ marginLeft: 3 }} onClick={() => setMinutesSearch('')}>
                            <IoTrashOutline size={17} />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

const MinutesList = ({ meetingSearch, minutesSearch, openProtocolPdf }) => (
    <ListField className label="Minutes" style={{ flex: 1, flexBasis: 150 }}>
        {R.map(m => (
            <div
                key={m.value}
                onClick={() => {
                    openProtocolPdf({ meetingId: m.value });
                }}
                className="row"
                style={{ padding: 12 }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flex: 4.5,
                        color: 'var(--corporate-color-1)',
                    }}
                >
                    <IoDocumentOutline size={18} color="var(--corporate-color-2-light)" />
                    <p style={{ marginLeft: 6, cursor: 'pointer' }}>{m.label}</p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: 'var(--color-2)',
                        flex: 1,
                    }}
                >
                    {m.body.businessId}
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flex: 1,
                        color: 'var(--corporate-color-1)',
                    }}
                >
                    {formatShortDate(m.body.date)}
                </div>
            </div>
        ))(meetingSearch(minutesSearch))}
    </ListField>
);
