import React, { useState } from 'react';
import * as R from 'ramda';
import {
    IoCloseOutline,
    IoCreateOutline,
    IoDocumentAttachOutline,
    IoEllipseSharp,
} from 'react-icons/io5';
import { useNodeFromVar } from '../../../hooks/useNode';
import { formatShortDate, newId, newIsoDate } from '../../../common/util';
import { TextField } from '../../Common/Fields';
import { ListRow } from '../../Common/ListRow';
import { useTimelineMeetings } from '../../../hooks/useTimelineMeetings';
import { Button } from '../../Common/Controls';
import { PROJECT_ID_KEY } from '../../../common/hooks.config.new';
import { useNavigator } from '../../../hooks/useNavigator';
import { useModal } from '../../../hooks/useModal';

export const MeetingsTimeline = ({ stack, meetings, filter }) => {
    const { node: project } = useNodeFromVar(stack, PROJECT_ID_KEY);

    const [showDetails, setShowDetails] = useState({});
    const [hideMonth, setHideMonth] = useState({});
    const startDateToDisplay =
        formatShortDate(filter?.meetingDate?.from) ||
        formatShortDate(project?.createdDate) ||
        formatShortDate(R.last(meetings)?.node?.date) ||
        'Start date';
    const endDateToDisplay =
        formatShortDate(filter?.meetingDate?.to) || formatShortDate(newIsoDate()) || 'End date';
    const getMeetingsPerMonth = useTimelineMeetings(
        endDateToDisplay,
        startDateToDisplay,
        meetings,
        filter,
    );
    const { navigateToProtocol } = useNavigator(stack);
    const openProtocolPdf = useModal('protocol-pdf');
    const descending = R.pathOr(false, ['sort', 'direction'], filter) === 'desc' && true;

    return (
        <>
            <div className="list-indicator-container" style={{ justifyContent: 'space-between' }}>
                <div className="list-indicator-foundation">
                    <div
                        className="list-indicator"
                        style={{ color: 'white', backgroundColor: 'var(--corporate-color-2)' }}
                    >
                        Meetings
                    </div>
                </div>
                {R.length(meetings) > 0 && (
                    <Button
                        onClick={() => {
                            setShowDetails(
                                R.fromPairs(
                                    R.map(m => {
                                        const id = m.nodeId;
                                        return [id, true];
                                    })(meetings),
                                ),
                            );
                            !R.isEmpty(showDetails) && setShowDetails({});
                        }}
                        style={{ height: 30 }}
                    >
                        <IoEllipseSharp
                            size={10}
                            color={
                                !R.isEmpty(showDetails)
                                    ? 'var(--corporate-color-7)'
                                    : 'var(--corporate-color-13)'
                            }
                        />
                        <p>{!R.isEmpty(showDetails) ? 'Collapse all' : 'Expand all'}</p>
                    </Button>
                )}
            </div>
            {R.length(meetings) > 0 ? (
                <div className="timeline-container">
                    <div className="date-badge">
                        {descending ? startDateToDisplay : endDateToDisplay}
                    </div>
                    <div className="timeline-inner-container">
                        <div
                            className="timeline-line"
                            style={{ padding: descending && '30 0 0 0' }}
                        >
                            {R.map(year => (
                                <div key={year?.year}>
                                    {R.map(
                                        month =>
                                            R.length(month.meetings) > 0 && (
                                                <MonthSection
                                                    key={newId()}
                                                    stack={stack}
                                                    month={month}
                                                    hideMonth={hideMonth}
                                                    setHideMonth={setHideMonth}
                                                    showDetails={showDetails}
                                                    setShowDetails={setShowDetails}
                                                    navigateToProtocol={navigateToProtocol}
                                                    openProtocolPdf={openProtocolPdf}
                                                    year={year}
                                                    descending={descending}
                                                />
                                            ),
                                    )(
                                        descending
                                            ? year?.meetingsPerMonth
                                            : R.reverse(year?.meetingsPerMonth),
                                    )}
                                </div>
                            ))(
                                descending
                                    ? getMeetingsPerMonth()
                                    : R.reverse(getMeetingsPerMonth()),
                            )}
                        </div>
                    </div>
                    <div className="date-badge">
                        {descending ? endDateToDisplay : startDateToDisplay}
                    </div>
                </div>
            ) : (
                <div className="no-content-message">No meetings found</div>
            )}
        </>
    );
};

const MonthSection = ({
    stack,
    month,
    hideMonth,
    setHideMonth,
    showDetails,
    setShowDetails,
    navigateToProtocol,
    openProtocolPdf,
    year,
    descending,
}) => (
    <>
        {descending && <div className="month-badge">{`${month.name} ${year.year}`}</div>}
        <div style={{ position: 'relative', right: -1 }}>
            <Button
                style={{
                    height: 30,
                    color: R.propEq(month.monthId, true, hideMonth)
                        ? 'var(--corporate-color-13)'
                        : 'var(--corporate-color-7)',
                }}
                onClick={() => {
                    !R.propEq(month.monthId, true, hideMonth)
                        ? setHideMonth({ ...hideMonth, [month.monthId]: true })
                        : setHideMonth({ ...hideMonth, [month.monthId]: false });
                }}
            >
                <IoEllipseSharp size={10} />
            </Button>
        </div>
        {!R.propEq(month.monthId, true, hideMonth) && (
            <>
                {R.map(meeting => (
                    <div
                        key={meeting.nodeId}
                        className="timeline-row"
                        style={{
                            flexDirection: R.propEq(meeting.nodeId, true, showDetails) && 'column',
                            marginTop: R.propEq(meeting.nodeId, true, showDetails) && 24,
                        }}
                    >
                        <div
                            className="date-badge-light"
                            style={{
                                borderColor:
                                    R.propEq(meeting.nodeId, true, showDetails) && 'transparent',
                                boxShadow:
                                    R.propEq(meeting.nodeId, true, showDetails) &&
                                    '0 5px 8px -2px var(--corporate-color-1-light4)',
                            }}
                        >
                            {formatShortDate(meeting.node.date)}
                        </div>
                        {!R.propEq(meeting.nodeId, true, showDetails) && (
                            <div className="timeline-row-line" />
                        )}
                        {R.propEq(meeting.nodeId, true, showDetails) ? (
                            <DetailedMeeting
                                stack={stack}
                                meeting={meeting}
                                showDetails={showDetails}
                                setShowDetails={setShowDetails}
                                navigateToProtocol={navigateToProtocol}
                                openProtocolPdf={openProtocolPdf}
                            />
                        ) : (
                            <div
                                className="meeting-badge"
                                onClick={() =>
                                    setShowDetails({ ...showDetails, [meeting.nodeId]: true })
                                }
                            >
                                {meeting.node.businessId || 'No meeting ID'}
                            </div>
                        )}
                    </div>
                ))(descending ? R.reverse(month.meetings) : month.meetings)}
            </>
        )}
        {!R.propEq(month.monthId, true, hideMonth) && (
            <div style={{ position: 'relative', right: -1 }}>
                <Button
                    style={{
                        height: 30,
                        color: R.propEq(month.monthId, true, hideMonth)
                            ? 'var(--corporate-color-13)'
                            : 'var(--corporate-color-7)',
                    }}
                    onClick={() => {
                        !R.propEq(month.monthId, true, hideMonth)
                            ? setHideMonth({ ...hideMonth, [month.monthId]: true })
                            : setHideMonth({ ...hideMonth, [month.monthId]: false });
                    }}
                >
                    <IoEllipseSharp size={10} />
                </Button>
            </div>
        )}
        {!descending && <div className="month-badge">{`${month.name} ${year.year}`}</div>}
    </>
);

const DetailedMeeting = ({
    meeting,
    showDetails,
    setShowDetails,
    openProtocolPdf,
    navigateToProtocol,
}) => (
    <div className="scale-animation">
        <ListRow
            key={meeting.nodeId}
            style={{
                width: 285,
                marginTop: 6,
                marginBottom: 12,
            }}
        >
            <div className="dashboard-meeting-button-row">
                <div style={{ display: 'flex' }}>{meeting.node.businessId}</div>
                <Button
                    onClick={() => setShowDetails(R.omit([meeting.nodeId], showDetails))}
                    className="button-icon"
                    style={{ color: 'white' }}
                >
                    <IoCloseOutline size={19} />
                </Button>
            </div>
            <div style={{ padding: '0 12 6 12' }}>
                {meeting.node.title && (
                    <TextField
                        valueStyle={{ marginTop: 12 }}
                        label="Meeting title"
                        onChange={() => {}}
                        editable={false}
                        value={meeting.node.title}
                    />
                )}
                {meeting.node.location && (
                    <TextField
                        valueStyle={{ marginTop: 12 }}
                        label="Meeting location"
                        onChange={() => {}}
                        editable={false}
                        value={meeting.node.location}
                    />
                )}
                <div className="detailed-meeting-container">
                    {!meeting.node.closed && (
                        <Button
                            onClick={() => navigateToProtocol(meeting.nodeId)}
                            className="button-icon"
                            style={{ marginRight: 6 }}
                        >
                            <IoCreateOutline size={20} />
                            <p>Edit</p>
                        </Button>
                    )}
                    <Button
                        onClick={() => openProtocolPdf({ meetingId: meeting.nodeId })}
                        className="button-icon"
                    >
                        <IoDocumentAttachOutline size={19} />
                        <p>Show PDF / Files</p>
                    </Button>
                </div>
            </div>
        </ListRow>
    </div>
);
