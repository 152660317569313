import React from 'react';

export const Spacer = ({ style }) => (
    <div
        style={{
            width: '100%',
            height: 500,
            backgroundColor: 'rgba(248, 247, 248)',
            margin: '5rem 0',
            ...style,
        }}
    />
);
