import React from 'react';
import {
    IoCreateOutline,
    IoDocumentAttachOutline,
    IoMailOutline,
    IoNewspaperOutline,
    IoSaveOutline,
    IoShareSocialOutline,
} from 'react-icons/io5';
import { Button } from '../../Common/Controls';
import { ProjectIndicator } from '../ProjectIndicator';
import { OfflineSyncer } from '../OfflineSyncer';
import { MAIN, MEETING_ATTACHMENT_EDGE, OFFLINE, PROTOCOL } from '../../../common/hooks.config.new';

export const ProtocolTopBar = ({
    stack,
    setLoading,
    navigateToDashboard,
    setOfflineSyncToast,
    setReadingMode,
    hasOfflineData,
    readingMode,
    openAttachmentsModal,
    push,
    openPrintModal,
    meetingId,
    subprojectId,
}) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="protocol-screen-upper-topbar">
            <div style={{ display: 'flex', flex: 1 }}>
                <Button
                    className="dashboard-toggle"
                    onClick={() => {
                        setLoading();
                        setTimeout(() => navigateToDashboard(), 10);
                    }}
                >
                    <IoShareSocialOutline size={25} />
                    <p>Project Dashboard</p>
                </Button>
            </div>
            <div style={{ display: 'flex', flex: 1.75, justifyContent: 'center' }}>
                <ProjectIndicator stack={stack} />
            </div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                <OfflineSyncer
                    graphNames={[OFFLINE, PROTOCOL]}
                    mergeToGraphName={MAIN}
                    onProgress={() => setOfflineSyncToast('spinner', 'Syncing offline data...')}
                    onSuccess={() => setOfflineSyncToast('success', 'Successfully saved.', 5000)}
                    onError={error => {
                        if (error.status) {
                            setOfflineSyncToast(
                                'error',
                                error.message ? error.message : error.toString(),
                            );
                        } else {
                            setOfflineSyncToast(
                                'error',
                                'You are offline. Please sync when back online.',
                                5000,
                            );
                        }
                    }}
                >
                    <IoSaveOutline size={24} />
                    <p>Save</p>
                </OfflineSyncer>
                <Button
                    style={{
                        marginLeft: 6,
                        color: hasOfflineData && 'var(--corporate-color-6)',
                    }}
                    className="topbar-button"
                    onClick={() => setReadingMode(!readingMode)}
                >
                    {readingMode ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <IoCreateOutline size={24} />
                            <p>Edit</p>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <IoNewspaperOutline size={22} />
                            <p>Read</p>
                        </div>
                    )}
                </Button>
                <Button
                    onClick={() =>
                        openAttachmentsModal({
                            fromId: meetingId,
                            edgeTypes: MEETING_ATTACHMENT_EDGE,
                            inheritFromId: subprojectId,
                        })
                    }
                    style={{
                        marginLeft: 6,
                        color: hasOfflineData && 'var(--corporate-color-6)',
                    }}
                    className="topbar-button"
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IoDocumentAttachOutline size={24} />
                        <p>Files</p>
                    </div>
                </Button>
                <Button
                    style={{
                        marginLeft: 6,
                        color: hasOfflineData && 'var(--corporate-color-6)',
                    }}
                    className="topbar-button"
                    onClick={async () => {
                        await push();
                        openPrintModal();
                    }}
                >
                    <IoMailOutline size={28} />
                    <p>Send</p>
                </Button>
            </div>
        </div>
    </div>
);
