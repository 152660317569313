import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { IoCloseOutline, IoLockOpenOutline } from 'react-icons/io5';
import { Modal } from '../Common/Modal';
import { ListField } from '../Common/Fields';
import { DropdownField } from '../Common/Dropdown';
import { Button, Checkbox } from '../Common/Controls';
import { useModalState } from '../../hooks/useModal';
import {
    ORGANIZATION_ID_KEY,
    CONTACT_DIRECTORY_CONTACT_EDGE,
    MEETING_TOP_EDGE,
    PROJECT_SUBPROJECT_EDGE,
    store,
    SUBPROJECT_MEETING_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { useUserEmail } from '../../hooks/useUserEmail';
import { Id } from '../../common/util';
import { SaveButton } from '../Elements/SaveButton';
import { useRightsModal } from '../../hooks/useRightsModal';

const constructContactsView = projectId =>
    projectId
        ? {
              [Id.contactDirectory(projectId)]: {
                  as: 'contactDirectory',
                  edges: {
                      [CONTACT_DIRECTORY_CONTACT_EDGE]: {
                          as: 'contacts',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const ProjectRightsModal = ({ stack, save }) => {
    const [isOpen, close] = useModalState('project-rights');
    return (
        <Modal
            name="project-rights"
            style={{
                width: '50vw',
                left: '25vw',
                height: '75vh',
                top: '12.5vh',
            }}
            topbar={<IoLockOpenOutline size={23} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <ModalContent stack={stack} save={save} close={close} /> : null}
        </Modal>
    );
};

export const ModalContent = ({ stack, save, close }) => {
    const { useView, pull, useVar, setRights } = useGraph(...stack);
    const [organizationId] = useVar(ORGANIZATION_ID_KEY);
    const [projectId] = useModalState('project-rights');

    const user = useUserEmail();

    const fullProjectView = constructProjectView(projectId);
    const [pullFullProjectPromise, setPullFullProjectPromise] = useState();
    useEffect(() => {
        const promise = store.pull(fullProjectView, R.head(stack));
        setPullFullProjectPromise(promise);
    }, []);

    const { saveRights, setUserRights, userRights } = useRightsModal(stack, projectId, fullProjectView);
    const onSave = async () => {
        await pullFullProjectPromise;
        save(saveRights);
    };

    const contactsView = constructContactsView(projectId);
    useEffect(() => {
        projectId && pull(contactsView);
    }, [projectId]);
    const { contactDirectory } = useView(contactsView);
    const contacts = R.compose(
        R.values,
        R.map(R.prop('node')),
        R.propOr({}, 'contacts'),
    )(contactDirectory);

    return (
        <div className="columns">
            <div>
                <div className="modal-headline">
                    <div>Manage Project Rights</div>
                </div>
                <div className="field-column">
                    <DropdownField
                        options={R.compose(
                            R.sortBy(R.prop('label')),
                            R.map(contact => ({
                                value: contact.id,
                                label: contact.name,
                                body: contact,
                            })),
                        )(contacts)}
                        onChange={contact => {
                            setUserRights(contact.body.email, { read: true });
                            setRights(organizationId)(contact.body.email, {
                                read: true,
                            });
                        }}
                        label="Add Existing Contacts"
                    />
                </div>
                <ContactRightsList
                    user={user}
                    setProjectRights={setUserRights}
                    userRights={userRights}
                />
                <SaveButton save={onSave} close={close} />
            </div>
        </div>
    );
};

const constructProjectView = projectId =>
    projectId
        ? {
              [projectId]: {
                  edges: {
                      [PROJECT_SUBPROJECT_EDGE]: {
                          include: {
                              edges: true,
                          },
                          edges: {
                              [SUBPROJECT_MEETING_EDGE]: {
                                  include: {
                                      edges: true,
                                  },
                                  edges: {
                                      [MEETING_TOP_EDGE]: {
                                          include: {
                                              edges: true,
                                          },
                                      },
                                  },
                              },
                          },
                      },
                  },
              },
              [Id.contactDirectory(projectId)]: {
                  edges: {
                      [CONTACT_DIRECTORY_CONTACT_EDGE]: {
                          include: {
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

const ContactRightsList = ({ user, setProjectRights, userRights }) => (
    <ListField className style={{ flex: 1, flexBasis: 150 }} label="Authorized contacts">
        {R.map(userRight => (
            <div key={userRight.user} className="row">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {userRight.user}
                    {user === userRight.user && <p className="user-badge">User</p>}
                </div>
                <div className="buttons">
                    <Checkbox
                        label="Read"
                        value={userRight.read}
                        onChange={isRight => {
                            setProjectRights(userRight.user, { read: isRight });
                        }}
                    />
                    <Checkbox
                        label="Write"
                        value={userRight.write}
                        onChange={isRight => setProjectRights(userRight.user, { write: isRight })}
                    />
                    <Checkbox
                        label="Admin"
                        value={userRight.admin}
                        onChange={isRight => setProjectRights(userRight.user, { admin: isRight })}
                    />
                    <Button
                        onClick={() => {
                            setProjectRights(userRight.user, {
                                read: false,
                                write: false,
                                admin: false,
                            });
                        }}
                        disabled={userRight.isCurrentUser}
                    >
                        <IoCloseOutline size={20} />
                    </Button>
                </div>
            </div>
        ))(userRights)}
    </ListField>
);
