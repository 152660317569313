import 'chart.js/auto';
import React, { useRef } from 'react';
import { Doughnut, getElementAtEvent } from 'react-chartjs-2';
import * as R from 'ramda';
import '../ChartsArea.css';
import { useProjectCurrency } from '../../../hooks/useChoices';
import { DASHBOARD_FILTER_KEY, useGraph } from '../../../common/hooks.config.new';
import { colors } from '../../../common/consts';

const donutOptions = {
    aspectRatio: 1,
    offset: 1,
    responsive: false,
    cutout: '85%',
    radius: '80%',
    animation: false,
    plugins: {
        legend: {
            display: false,
        },
    },
};

export const OppsCountDonut = ({
    chartedOpps,
    setGlobalDashboardTops,
    criticalOppsCount,
    donutHeight = 340,
    donutWidth = 340,
    containerWidth,
    fontBig,
    fontSmall,
    topMargin,
    global,
    stack,
}) => {
    const { useVar } = useGraph(...stack);
    const oppsCount = R.compose(R.sum, R.map(R.compose(R.length, R.prop('tops'))))(chartedOpps);
    const [filter, setFilter] = useVar(DASHBOARD_FILTER_KEY);
    const data = {
        labels: chartedOpps.map(ct => ct.type),
        datasets: [
            {
                label: 'Reference',
                data: chartedOpps.map(ct => ct.tops.length),
                backgroundColor: global ? ['#005980', '#FF3A20'] : colors,
                borderRadius: global ? 50 : 25,
            },
        ],
    };
    const chartRef = useRef(null);
    return (
        <div className="doughnut-container" style={{ width: containerWidth }}>
            <Doughnut
                ref={chartRef}
                data={data}
                options={donutOptions}
                onClick={event => {
                    const element = getElementAtEvent(chartRef.current, event);
                    if (element.length > 0) {
                        const { index } = element[0];
                        const filterEntry = {
                            label: chartedOpps[index].type,
                            value: chartedOpps[index].type,
                        };
                        setGlobalDashboardTops && setGlobalDashboardTops(chartedOpps[index].tops);
                        !setGlobalDashboardTops &&
                            setFilter(
                                R.compose(
                                    R.assoc('showTops', true),
                                    R.assocPath(
                                        ['topType', 'values'],
                                        [{ value: 'o', label: 'o' }],
                                    ),
                                    R.assocPath(['oppStatus', 'values'], [filterEntry]),
                                )(filter),
                            );
                    }
                }}
                height={donutHeight}
                width={donutWidth}
            />
            <div className="inner-text">
                <p className="title" style={{ fontSize: fontBig }}>
                    Opportunities:
                </p>
                <p className="info" style={{ fontSize: fontBig, marginTop: topMargin }}>
                    {oppsCount}
                </p>
                <p
                    className="title-critical"
                    style={{
                        color: criticalOppsCount === 0 && 'var(--color-2)',
                        fontSize: fontSmall,
                        marginTop: topMargin && topMargin + 20,
                    }}
                >
                    Critical:
                </p>
                <p
                    className="info-critical"
                    style={{
                        color: criticalOppsCount === 0 && 'var(--color-2)',
                        fontSize: fontSmall,
                        marginTop: topMargin,
                    }}
                >
                    {criticalOppsCount}
                </p>
            </div>
        </div>
    );
};

export const OppLegend = ({ oppStatusTypeChoices }) => {
    const legend = oppStatusTypeChoices.map((o, i) => {
        const el = { label: o.label, color: colors[i] };
        return el;
    });
    return (
        <div>
            {legend.map(item => (
                <div className="legend-item" key={item.label}>
                    <div className="legend-color" style={{ backgroundColor: item.color }} />
                    <div className="legend-label">{item.label}</div>
                </div>
            ))}
        </div>
    );
};

export const OppsSavingsDonut = ({ stack, chartedOpps, criticalOpps }) => {
    const { useVar } = useGraph(...stack);
    const currency = useProjectCurrency(stack);
    const formato = val =>
        new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency,
        }).format(val);

    const chartedSavings = R.map(
        R.compose(R.sum, R.map(R.pathOr(0, ['top', 'savings'])), R.prop('tops')),
    )(chartedOpps);
    const totalSavingsSum = R.sum(chartedSavings);
    const criticalSavingsSum = R.sum(
        R.map(co => (co.top.savings ? co.top.savings : 0))(criticalOpps),
    );
    const [filter, setFilter] = useVar(DASHBOARD_FILTER_KEY);

    const data = {
        labels: chartedOpps.map(ct => ct.type),
        datasets: [
            {
                label: 'Reference',
                data: chartedSavings,
                backgroundColor: colors,
                borderRadius: 20,
            },
        ],
    };

    const chartRef = useRef(null);

    return (
        <div className="doughnut-container">
            <Doughnut
                ref={chartRef}
                data={data}
                options={donutOptions}
                onClick={event => {
                    const element = getElementAtEvent(chartRef.current, event);
                    if (element.length > 0) {
                        const { index } = element[0];
                        const filterEntry = {
                            label: chartedOpps[index].type,
                            value: chartedOpps[index].type,
                        };
                        setFilter(
                            R.compose(
                                R.assoc('showTops', true),
                                R.assocPath(['topType', 'values'], [{ value: 'o', label: 'o' }]),
                                R.assocPath(['oppStatus', 'values'], [filterEntry]),
                            )(filter),
                        );
                    }
                }}
                height={340}
                width={340}
            />
            <div className="inner-text">
                <p className="title">Savings:</p>
                <p className="info">{formato(totalSavingsSum)}</p>
                <p
                    className="title-critical"
                    style={{ color: criticalSavingsSum === 0 && 'var(--color-2)' }}
                >
                    Critical:
                </p>
                <p
                    className="info-critical"
                    style={{ color: criticalSavingsSum === 0 && 'var(--color-2)' }}
                >
                    {formato(criticalSavingsSum)}
                </p>
            </div>
        </div>
    );
};
