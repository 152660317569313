import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import {
    IoDocumentOutline,
    IoCreateOutline,
    IoCheckmarkDoneOutline,
    IoCheckmarkOutline,
    IoTrashOutline,
    IoSearchOutline,
    IoChevronForwardOutline,
    IoDocumentLockOutline,
} from 'react-icons/io5';
import { Modal } from '../Common/Modal';
import { ListField } from '../Common/Fields';
import {
    EXISTING_MEETINGS_MODAL,
    MEETING_TOP_EDGE,
    PROTOCOL,
    store,
    SUBPROJECT_ID_KEY,
    SUBPROJECT_MEETING_EDGE,
    TOPS_TO_UPDATE_KEY,
    TOP_RESPONSIBLE_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { useModalState } from '../../hooks/useModal';
import { useNavigator } from '../../hooks/useNavigator';
import { createDummyGraph, createOfflinePush } from '../../common/got-adjunct';
import { Button } from '../Common/Controls';
import { useToast } from '../../hooks/useToast';
import { Toast } from '../Elements/Toast';
import { formatShortDate, newId, newIsoDate, newMsDate } from '../../common/util';

const constructMeetingsView = subprojectId =>
    subprojectId
        ? {
              [subprojectId]: {
                  as: 'subproject',
                  edges: {
                      [SUBPROJECT_MEETING_EDGE]: {
                          as: 'meetings',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const ExistingMeetingsModal = ({ stack }) => {
    const [isOpen, close] = useModalState('existingMeetings');
    return (
        <Modal
            name="existingMeetings"
            style={{
                width: '95vw',
                left: '2.5vw',
                height: '87vh',
                top: '6.5vh',
            }}
            topbar={<IoDocumentOutline size={25} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <ExistingMeetingsModalContent stack={stack} close={close} /> : null}
        </Modal>
    );
};

const ExistingMeetingsModalContent = ({ stack: parentStack, close }) => {
    const stack = R.append(EXISTING_MEETINGS_MODAL, parentStack);
    const { useVar, useView, pull, update, remove } = useGraph(...stack);

    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);

    const meetingsView = constructMeetingsView(subprojectId);
    useEffect(() => {
        subprojectId && pull(meetingsView);
    }, [subprojectId]);
    const { subproject } = useView(meetingsView);
    const meetingChoices = R.compose(
        R.map(meetingBag => ({
            value: R.path(['node', 'id'], meetingBag),
            label: R.path(['node', 'title'], meetingBag),
            body: R.path(['node'], meetingBag),
        })),
        R.reverse,
        R.sortBy(R.path(['node', 'date'])),
        R.filter(meetingBag => !R.pathOr(false, ['node', 'closed'], meetingBag)),
        R.values,
        R.propOr({}, 'meetings'),
    )(subproject);

    const submittedMeetings = R.filter(mc => mc.body.submitted === true)(meetingChoices);
    const draftMeetings = R.filter(mc => (!mc.body.submitted ? true : mc.body.submitted === false))(
        meetingChoices,
    );

    const { navigateToProtocol } = useNavigator(parentStack);

    const addTopsToUpdate = meetingId => {
        const topsToUpdate = store.getVar(...stack)(TOPS_TO_UPDATE_KEY);

        const { add, inheritRights, assoc, getGraph } = createDummyGraph();

        R.forEach(({ top, responsible }) => {
            const newTopId = newId();
            const newTop = R.compose(
                R.assoc('modifiedDate', newIsoDate()),
                R.assoc('id', newTopId),
            )(top);
            add(MEETING_TOP_EDGE)(meetingId)(newTop, { order: newMsDate() });
            inheritRights(newTopId)(subprojectId);
            responsible && assoc(TOP_RESPONSIBLE_EDGE)(newTopId)(responsible);
        })(R.values(topsToUpdate));

        store.mergeGraph(getGraph(), PROTOCOL);
        navigateToProtocol(meetingId);
    };

    const setToast = useToast('existing-meetings');
    const toastConfig = {
        textOnStart: 'Saving meeting data...',
        textOnSuccess: 'Successfully saved.',
        textOnError: 'You are offline. Please sync when back online.',
    };
    const _push = createOfflinePush(stack, setToast, toastConfig);

    const closeMeeting = meeting => {
        // eslint-disable-next-line no-restricted-globals
        const confirmed = confirm(
            'Are you sure to freeze this meeting? The meeting will not be accessible via dashboard.',
        );
        if (confirmed) {
            update(R.assoc('closed', true, meeting));
            _push(); // = Push
        }
    };

    const deleteMeeting = meeting => {
        // eslint-disable-next-line no-restricted-globals
        const confirmed = confirm('Are you sure to delete this meeting?');
        if (confirmed) {
            remove(SUBPROJECT_MEETING_EDGE)(subprojectId)(meeting);
            _push(); // = Push
        }
    };

    const submitMeeting = meeting => {
        // eslint-disable-next-line no-restricted-globals
        const confirmed = confirm('Are you sure to submit this meeting?');
        if (confirmed) {
            update(R.assoc('submitted', true, meeting));
            _push(); // = Push
        }
    };

    const meetingSearch = searchString => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]+[u00C0-u017F]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.filter(m => m.body.title && m.body.title.toUpperCase().search(string) !== -1)(
                submittedMeetings,
            );
        }
        return submittedMeetings;
    };

    const [draftSearch, setDraftSearch] = useState('');
    const [submittedSearch, setSubmittedSearch] = useState('');
    const [search, setSearch] = useState(false);
    const [searchSub, setSearchSub] = useState(false);

    return (
        <>
            <div className="columns">
                <div style={{ marginRight: 20 }}>
                    <DraftsHeadline
                        search={search}
                        setSearch={setSearch}
                        draftSearch={draftSearch}
                        setDraftSearch={setDraftSearch}
                    />
                    <DraftsList
                        draftSearch={draftSearch}
                        close={close}
                        addTopsToUpdate={addTopsToUpdate}
                        meetingSearch={meetingSearch}
                        deleteMeeting={deleteMeeting}
                        submitMeeting={submitMeeting}
                        draftMeetings={draftMeetings}
                    />
                </div>
                <div style={{ marginLeft: 20 }}>
                    <SubmittedHeadline
                        searchSub={searchSub}
                        setSearchSub={setSearchSub}
                        submitMeeting={submitMeeting}
                        setSubmittedSearch={setSubmittedSearch}
                    />
                    <SubmittedsList
                        submittedSearch={submittedSearch}
                        close={close}
                        addTopsToUpdate={addTopsToUpdate}
                        meetingSearch={meetingSearch}
                        closeMeeting={closeMeeting}
                    />
                </div>
            </div>
            <Toast name="existing-meetings" />
        </>
    );
};

const DraftsHeadline = ({ search, setSearch, draftSearch, setDraftSearch }) => {
    const draftRef = useRef();
    useEffect(() => {
        search && setTimeout(() => draftRef.current.focus(), 600);
    }, [search]);
    return (
        <div className="modal-headline">
            <div>Draft Meetings</div>
            <div style={{ display: 'flex', flex: 1.5, justifyContent: 'flex-end' }}>
                <Button style={{ marginLeft: 6 }} onClick={() => setSearch(!search)}>
                    {search ? <IoChevronForwardOutline size={19} /> : <IoSearchOutline size={21} />}
                </Button>
                {search && (
                    <div className="animated-searchbar-half">
                        <input
                            ref={draftRef}
                            placeholder="search drafts..."
                            value={draftSearch}
                            onChange={e => setDraftSearch(e.target.value)}
                        />
                        <Button style={{ marginLeft: 3 }} onClick={() => setDraftSearch('')}>
                            <IoTrashOutline size={17} />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

const DraftsList = ({
    draftSearch,
    close,
    addTopsToUpdate,
    meetingSearch,
    deleteMeeting,
    submitMeeting,
    draftMeetings,
}) => (
    <ListField className style={{ flex: 1, flexBasis: 150 }} label="Drafts">
        {R.map(meetingChoice => (
            <div
                key={meetingChoice.body.id}
                className="row hover-effect"
                style={{ cursor: 'default', alignItems: 'flex-start' }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flex: 2,
                        fontSize: 'inherit',
                        flexDirection: 'column',
                    }}
                >
                    <div style={{ display: 'flex', width: '100%', fontSize: 'inherit' }}>
                        <div>
                            <IoDocumentLockOutline
                                size={20}
                                color="var(--corporate-color-2-light)"
                                style={{ marginRight: 12 }}
                            />
                        </div>
                        <div
                            style={{
                                fontSize: 'inherit',
                                fontWeight: 100,
                            }}
                        >
                            {meetingChoice.label}
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            margin: '6 0 0 32',
                        }}
                    >
                        <div style={{ color: 'var(--color-2)', fontWeight: 600, marginRight: 6 }}>
                            Business ID:
                        </div>
                        <div style={{ color: 'var(--color-2)' }}>
                            {meetingChoice.body.businessId}
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            margin: '6 0 0 32',
                        }}
                    >
                        <div style={{ color: 'var(--color-2)', fontWeight: 600, marginRight: 6 }}>
                            Meeting Date:
                        </div>
                        <div style={{ color: 'var(--color-2)' }}>
                            {formatShortDate(meetingChoice.body.date)}
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 2 }}>
                    <Button
                        onClick={() => {
                            addTopsToUpdate(meetingChoice.body.id);
                            close();
                        }}
                        style={{ color: 'var(--corporate-color-2)' }}
                    >
                        <IoCreateOutline size={19} />
                        <p>Edit</p>
                    </Button>
                    <Button
                        style={{ marginLeft: 6, color: 'var(--corporate-color-2)' }}
                        onClick={() => submitMeeting(meetingChoice.body)}
                    >
                        <IoCheckmarkOutline size={20} />
                        <p>Submit</p>
                    </Button>
                    <Button
                        style={{ marginLeft: 6, color: 'var(--corporate-color-2)' }}
                        onClick={() => deleteMeeting(meetingChoice.body)}
                    >
                        <IoTrashOutline size={17} />
                        <p>Delete</p>
                    </Button>
                </div>
            </div>
        ))(draftSearch.length > 0 ? meetingSearch(draftSearch, draftMeetings) : draftMeetings)}
    </ListField>
);

const SubmittedHeadline = ({ searchSub, setSearchSub, submitMeeting, setSubmittedSearch }) => {
    const subRef = useRef();
    useEffect(() => {
        searchSub && setTimeout(() => subRef.current.focus(), 600);
    }, [searchSub]);
    return (
        <div className="modal-headline">
            <div style={{ flex: 1 }}>Submitted Meetings</div>
            <div style={{ display: 'flex', flex: 1.5, justifyContent: 'flex-end' }}>
                <Button style={{ marginLeft: 6 }} onClick={() => setSearchSub(!searchSub)}>
                    {searchSub ? (
                        <IoChevronForwardOutline size={19} />
                    ) : (
                        <IoSearchOutline size={21} />
                    )}
                </Button>
                {searchSub && (
                    <div className="animated-searchbar-half">
                        <input
                            ref={subRef}
                            placeholder="search submitted..."
                            value={submitMeeting}
                            onChange={e => setSubmittedSearch(e.target.value)}
                        />
                        <Button style={{ marginLeft: 3 }} onClick={() => setSubmittedSearch('')}>
                            <IoTrashOutline size={17} />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

const SubmittedsList = ({
    submittedSearch,
    close,
    addTopsToUpdate,
    meetingSearch,
    closeMeeting,
}) => (
    <ListField className style={{ flex: 1, flexBasis: 150 }} label="Submitted">
        {R.map(meetingChoice => (
            <div
                key={meetingChoice.body.id}
                className="row hover-effect"
                style={{ cursor: 'default', alignItems: 'flex-start' }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flex: 2,
                        fontSize: 'inherit',
                        flexDirection: 'column',
                    }}
                >
                    <div style={{ display: 'flex', width: '100%', fontSize: 'inherit' }}>
                        <div>
                            <IoDocumentLockOutline
                                size={20}
                                color="var(--corporate-color-2-light)"
                                style={{ marginRight: 12 }}
                            />
                        </div>
                        <div
                            style={{
                                fontSize: 'inherit',
                                fontWeight: 100,
                            }}
                        >
                            {meetingChoice.label}
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            margin: '6 0 0 32',
                        }}
                    >
                        <div style={{ color: 'var(--color-2)', marginRight: 6 }}>Business ID:</div>
                        <div style={{ color: 'var(--color-2)' }}>
                            {meetingChoice.body.businessId}
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            margin: '6 0 0 32',
                        }}
                    >
                        <div style={{ color: 'var(--color-2)', marginRight: 6 }}>Meeting Date:</div>
                        <div style={{ color: 'var(--color-2)' }}>
                            {formatShortDate(meetingChoice.body.date)}
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                    <Button
                        onClick={() => {
                            addTopsToUpdate(meetingChoice.body.id);
                            close();
                        }}
                        style={{ color: 'var(--corporate-color-2)' }}
                    >
                        <IoCreateOutline size={19} />
                        <p>Edit</p>
                    </Button>
                    <Button
                        style={{ marginLeft: 6, color: 'var(--corporate-color-2)' }}
                        onClick={() => closeMeeting(meetingChoice.body)}
                    >
                        <IoCheckmarkDoneOutline size={20} />
                        <p>Freeze</p>
                    </Button>
                </div>
            </div>
        ))(meetingSearch(submittedSearch))}
    </ListField>
);
