import React from 'react';
import * as R from 'ramda';
import { ALWAYS_PULL } from '../../../hooks/useSettings';
import { FancyCheckbox } from '../../Common/Controls';

export const PullModeToggle = ({ settings, setSettings, mobile }) => (
    <FancyCheckbox
        value={R.propOr(true, ALWAYS_PULL, settings)}
        onChange={value => {
            setSettings(R.assoc(ALWAYS_PULL, value, settings));
        }}
        label={mobile ? 'Auto load cloud data' : 'Auto Refresh'}
        customHeight="var(--control-height)"
        containerStyle={{
            padding: '0 0.5rem 0 var(--space-norsmall)',
            justifyContent: 'space-between',
        }}
        mobile={mobile}
        placeholderStyle={{ fontSize: mobile && '3.5rem' }}
        labelStyle={{ marginLeft: mobile && 20 }}
    />
);
