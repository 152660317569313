import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useAuth } from './common/auth';
import { AuthScreen } from './common/AuthScreen';
import { DashboardScreen } from './Components/Screens/DashboardScreen';
import { GlobalDashboardScreen } from './Components/Screens/GlobalDashboardScreen';
import { ProtocolScreen } from './Components/Screens/ProtocolScreen';
import './AppNew.css';
import logo from './logos/pclogo.png';
import { Toast } from './Components/Elements/Toast';

export const AppDesktop = () => {
    const [loggedIn, login] = useAuth();

    if (loggedIn) {
        return (
            <>
                <Switch>
                    <Route exact path="/global-dashboard">
                        <GlobalDashboardScreen />
                    </Route>
                    <Route exact path="/dashboard">
                        <DashboardScreen />
                    </Route>
                    <Route exact path="/protocol">
                        <ProtocolScreen />
                    </Route>
                    <Redirect exact from="/*" to="/dashboard" />
                </Switch>
                <Toast name="app" />
            </>
        );
    }

    return (
        <Switch>
            <Route path="/change-password/:emailAdress/:password">
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        backgroundColor: 'rgba(248,247,248)',
                    }}
                >
                    <img alt="logo" src={logo} style={{ width: 300 }} />
                    <AuthScreen onLoggedIn={() => login()} defaultState="changePassword" />
                </div>
            </Route>
            <Route exact path="/*">
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        backgroundColor: 'rgba(248,247,248)',
                    }}
                >
                    <img alt="logo" src={logo} style={{ width: 300 }} />
                    <AuthScreen onLoggedIn={() => login()} />
                </div>
            </Route>
        </Switch>
    );
};
