import React from 'react';
import * as R from 'ramda';
import { IoGitNetworkOutline, IoHomeOutline } from 'react-icons/io5';
import { animated, useSpring } from 'react-spring';
import { VscCircleOutline } from 'react-icons/vsc';
import { useSelector } from 'react-redux';
import {
    ORGANIZATION_ID_KEY,
    PROJECT_ID_KEY,
    SUBPROJECT_ID_KEY,
} from '../../common/hooks.config.new';
import { useNodeFromVar } from '../../hooks/useNode';

export const ProtocolSelector = ({ stack }) => {
    const openModals = R.compose(
        R.filter(m => m !== false),
        R.values,
        R.omit(['loader', 'loaded-projects']),
    )(useSelector(R.propOr({}, 'modals')));
    const hasOpenModals = R.length(openModals) > 0;
    const projectSelectorStyle = useSpring({
        opacity: hasOpenModals ? 0 : 1,
    });
    const { node: organization } = useNodeFromVar(stack, ORGANIZATION_ID_KEY);
    const { node: project } = useNodeFromVar(stack, PROJECT_ID_KEY);
    const { node: subproject } = useNodeFromVar(stack, SUBPROJECT_ID_KEY);

    const organizationName = R.propOr('Organization', 'name', organization);
    const projectName = R.propOr('Project', 'title', project);
    const subprojectName = R.propOr('Subproject', 'title', subproject);

    return (
        <ProtocolProjectSelector
            organizationName={organizationName}
            projectName={projectName}
            subprojectName={subprojectName}
            projectSelectorStyle={projectSelectorStyle}
        />
    );
};

const ProtocolProjectSelector = ({
    projectSelectorStyle,
    organizationName,
    projectName,
    subprojectName,
}) => (
    <animated.div className="protocol-project-selector-mobile fader" style={projectSelectorStyle}>
        <div
            className="project-selector-entry-mobile"
            style={{
                color: organizationName === 'Organization' ? 'var(--color-3)' : 'inherit',
                justifyContent: 'flex-start',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '4rem',
                    minWidth: '4rem',
                    height: '4rem',
                    padding: '1rem',
                    paddingLeft: 0,
                    borderRadius: '5rem',
                }}
            >
                <IoHomeOutline size="3.5rem" color="var(--corporate-color-7)" />
            </div>
            {organizationName === 'Organization'
                ? 'Organization'
                : R.slice(0, 10, organizationName)}
        </div>
        <div
            className="project-selector-entry-mobile"
            style={{
                color: projectName === 'Project' ? 'var(--color-3)' : 'inherit',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '4rem',
                    minWidth: '4rem',
                    height: '4rem',
                    padding: '1rem',
                    borderRadius: '5rem',
                    marginRight: '0.25rem',
                }}
            >
                <IoGitNetworkOutline
                    size="3.75rem"
                    style={{ transform: 'rotate(90deg)' }}
                    color="var(--corporate-color-7)"
                />
            </div>
            {R.slice(0, 10, projectName)}
        </div>
        <div
            className="project-selector-entry-mobile"
            style={{
                color: subprojectName === 'Subproject' ? 'var(--color-3)' : 'inherit',
                justifyContent: 'flex-end',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '4rem',
                    minWidth: '4rem',
                    height: '4rem',
                    padding: '1rem',
                    paddingRight: '0.1rem',
                    borderRadius: '5rem',
                }}
            >
                <VscCircleOutline size="3.75rem" color="var(--corporate-color-7)" />
            </div>
            {R.slice(0, 10, subprojectName)}
        </div>
    </animated.div>
);
