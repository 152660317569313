import * as R from 'ramda';
import React, { useCallback, useEffect, useMemo } from 'react';
import { IoEllipseSharp } from 'react-icons/io5';
import { filterMeetings, filterTops } from '../../../common/filteredtops.util';
import { constructNodeView } from '../../../common/got-adjunct';
import {
    ACTIONS_KANBAN_BOARD,
    DASHBOARD_FILTER_KEY,
    DASHBOARD_STATE_KEY,
    OPP_TRACKING,
    ORGANIZATION_ID_KEY,
    PHASES_KANBAN_BOARD,
    PROJECT_ID_KEY,
    PROJECT_MEETINGS_KEY,
    SEARCH_STRING_KEY,
    SORTED_GROUPED_TOPS_KEY,
    SUBPROJECT_ID_KEY,
    TOP_ATTACHMENT_EDGE,
    TOPS_TO_UPDATE_KEY,
    useGraph,
} from '../../../common/hooks.config.new';
import { EditTopsModal } from '../../Modals/EditTopsModal';
import { useUserEmail } from '../../../hooks/useUserEmail';
import { ListRow } from '../../Common/ListRow';
import { ChartsAndBoardsArea } from './ChartsAndBoardsArea';
import { DashboardTop } from './DashboardTop';
import { useContact } from '../../../hooks/useContact';
import { TopButtonRow } from '../TopButtonRow';
import { Toast } from '../Toast';
import { useToast } from '../../../hooks/useToast';
import { filterCriticalOpps } from '../../../common/filterCriticalOpps';
import { MeetingsTimeline } from './MeetingsTimeline';
import { LoadAttachments } from '../HasAttachments';
import { Button } from '../../Common/Controls';

export const TopsAndChartsArea = ({ stack }) => {
    const { useVar, useView, pull } = useGraph(...stack);

    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [{ tops: sortedGroupedTops = {} } = {}] = useVar(SORTED_GROUPED_TOPS_KEY);
    const [{ meetings: subprojectMeetings } = {}] = useVar(PROJECT_MEETINGS_KEY);

    const topRows = useMemo(
        () =>
            R.compose(
                R.sortBy(R.prop('businessId')),
                R.values,
                R.map(entries => ({
                    ...R.last(entries),
                    previousVersions: R.dropLast(1, entries),
                })),
            )(sortedGroupedTops),
        [sortedGroupedTops],
    );

    const [dashboardState] = useVar(DASHBOARD_STATE_KEY);
    const [filter = {}] = useVar(DASHBOARD_FILTER_KEY);
    const [searchString] = useVar(SEARCH_STRING_KEY);
    const [projectId] = useVar(PROJECT_ID_KEY);
    const user = useUserEmail();

    const { contact } = useContact(stack, projectId, user);
    const userName = R.pathOr(user, ['node', 'name'], contact);

    const projectView = constructNodeView(projectId, { node: true, rights: true });
    const projViewRes = useView(projectView);
    const projectBag = R.propOr({}, projectId, projViewRes);
    const canWriteProject = R.pathOr(false, ['rights', 'user', user, 'write'], projectBag);
    const canAdminProject = R.pathOr(false, ['rights', 'user', user, 'admin'], projectBag);
    const statusses = R.pathOr([], ['node', 'oppStatusTypes'], projectBag);
    useEffect(() => {
        projectId && pull(projectView);
    }, [projectId]);

    const { [subprojectId]: subprojectBag } = useView(constructNodeView(subprojectId));
    const subprojectBusinessId = R.pathOr('', ['node', 'businessId'], subprojectBag);

    const { searchedTops, actions, opps } = filterTops({
        showTops: filter?.showTops || false,
        filter,
        statusses,
        searchString,
        dashboardState,
        subprojectBusinessId,
        userName,
    })(topRows);

    const filterMode =
        R.length(filter?.topTypes?.values) > 0 ||
        R.length(filter?.actionStatus?.values) > 0 ||
        R.length(filter?.oppStatus?.values) > 0 ||
        R.length(filter?.category?.values) > 0 ||
        R.length(filter?.responsible?.values) > 0 ||
        R.length(filter?.dueDate?.from) > 0 ||
        R.length(filter?.dueDate?.to) > 0;

    const searchedMeetings = filterMeetings({
        filter,
        searchString,
        dashboardState,
        subprojectBusinessId,
        userName,
        searchedTops,
        filterMode,
    })(subprojectMeetings);

    return (
        <TopsAndChartsContent
            stack={stack}
            searchedTops={searchedTops}
            actions={actions}
            opps={opps}
            dashboardState={dashboardState}
            projectId={projectId}
            subprojectId={subprojectId}
            canAdminProject={canAdminProject}
            canWriteProject={canWriteProject}
            userName={userName}
            searchedMeetings={searchedMeetings}
            filter={filter}
        />
    );
};

const TopsAndChartsContent = ({
    stack,
    searchedTops,
    actions,
    opps,
    dashboardState,
    projectId,
    canAdminProject,
    canWriteProject,
    subprojectId,
    userName,
    searchedMeetings,
    filter,
}) => {
    const selectTopNode = useCallback(R.prop('top'));
    const { useVar } = useGraph(R.head(stack));
    const [topsToUpdate, setTopsToUpdate] = useVar(TOPS_TO_UPDATE_KEY);
    const dashboardActionStateChangeStack = R.append('dashboardActions', stack);
    const { push, update: updateDashboardcAtion } = useGraph(...dashboardActionStateChangeStack);
    const [organizationId] = useVar(ORGANIZATION_ID_KEY);
    const [oppTracking] = useVar(OPP_TRACKING);

    const msDate = Date.now();

    useEffect(() => {
        const openActions = actions ? actions.filter(a => selectTopNode(a).status === 'Open') : [];
        projectId &&
            openActions.map(a => {
                const msDueDate = new Date(selectTopNode(a).dueDate).getTime();
                if (msDueDate < msDate && selectTopNode(a).status === 'Open') {
                    updateDashboardcAtion(R.assoc('status', 'Late', selectTopNode(a)));
                }
                return null;
            });
        push();
    }, [projectId]);

    const criticalOpps = filterCriticalOpps(stack, projectId)(opps, selectTopNode);

    const topsToDisplay = oppTracking
        ? searchedTops
        : R.filter(t => t.topType !== 'o')(searchedTops || []);

    const setEditMessageToast = useToast('edit-message');
    // TODO change subproject in edit modal to tops sub
    const editMessage = message => {
        setEditMessageToast('error', message || 'Select a subproject to edit this item...', 5000);
    };

    const sortedMeetings =
        filter?.meetingDate?.sort === 'desc'
            ? R.sortBy(R.path(['node', 'date']))(searchedMeetings)
            : R.reverse(R.sortBy(R.path(['node', 'date']))(searchedMeetings));

    const selectAllTops = () => {
        const ttu = {};
        for (let i = 0; i < topsToDisplay.length; i += 1) {
            ttu[topsToDisplay[i].top.id] = {
                top: topsToDisplay[i].top,
                responsible: topsToDisplay[i].responsible,
                previousVersions: topsToDisplay[i].previousVersions,
            };
        }
        setTopsToUpdate(ttu);
    };

    const allTopsSelected = R.length(topsToDisplay) === R.length(R.values(topsToUpdate));

    return (
        <>
            {projectId ? (
                <>
                    {opps && actions && (
                        <ChartsAndBoardsArea
                            stack={stack}
                            opps={opps}
                            criticalOpps={criticalOpps}
                            actions={actions}
                            dashboardState={dashboardState}
                            fnSelectNode={selectTopNode}
                            oppTracking={oppTracking}
                            canAdminProject={canAdminProject}
                            canWriteProject={canWriteProject}
                            subprojectId={subprojectId}
                            editMessage={editMessage}
                            userName={userName}
                        />
                    )}
                    {searchedTops && (
                        <div className="dashboard-area">
                            <div style={{ width: '75vw' }}>
                                <div className="list-indicator-container">
                                    <Button
                                        onClick={() => {
                                            allTopsSelected ? setTopsToUpdate({}) : selectAllTops();
                                        }}
                                        style={{ height: 30 }}
                                    >
                                        <IoEllipseSharp
                                            size={10}
                                            color={
                                                allTopsSelected
                                                    ? 'var(--corporate-color-7)'
                                                    : 'var(--corporate-color-13)'
                                            }
                                        />
                                        <p>{allTopsSelected ? 'Clear all' : 'Select all'}</p>
                                    </Button>
                                    <div className="list-indicator-foundation">
                                        <div className="list-indicator">Agenda Items</div>
                                    </div>
                                </div>
                                {R.length(topsToDisplay) > 0 ? (
                                    R.map(
                                        ({
                                            top,
                                            responsible,
                                            previousVersions,
                                            meetingId,
                                            protocolId,
                                        }) => (
                                            <ListRow
                                                key={top.id}
                                                style={{
                                                    marginLeft: 'var(--space-small)',
                                                    marginRight: 'var(--space-small)',
                                                }}
                                            >
                                                <div className="top-search-row">
                                                    <TopButtonRow
                                                        top={top}
                                                        stack={stack}
                                                        responsible={responsible}
                                                        previousVersions={previousVersions}
                                                        canAdminProject={canAdminProject}
                                                        canWriteProject={canWriteProject}
                                                        subprojectId={subprojectId}
                                                        editMessage={editMessage}
                                                        userName={userName}
                                                        oppTracking={oppTracking}
                                                        meetingId={meetingId}
                                                    />
                                                    <DashboardTop
                                                        noButton
                                                        stack={stack}
                                                        meetingId={meetingId}
                                                        topId={R.prop('id', top)}
                                                        protocolId={protocolId}
                                                    />
                                                    <LoadAttachments
                                                        stack={stack}
                                                        node={top}
                                                        edgeTypes={TOP_ATTACHMENT_EDGE}
                                                    />
                                                </div>
                                            </ListRow>
                                        ),
                                    )(topsToDisplay)
                                ) : (
                                    <div className="no-content-message">No agenda items found</div>
                                )}
                            </div>
                            <div className="dashboard-divider" />
                            <div style={{ width: '25vw' }}>
                                <MeetingsTimeline
                                    stack={stack}
                                    filter={filter}
                                    meetings={sortedMeetings}
                                />
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <p className="dashboard-message">
                    {!organizationId
                        ? 'Select or create an organization and a project'
                        : 'Select or create a project'}
                </p>
            )}
            <Toast name="edit-message" />
            <Toast name="setOrg" />
            <Toast name={ACTIONS_KANBAN_BOARD} />
            <Toast name={PHASES_KANBAN_BOARD} />
            <EditTopsModal stack={stack} />
        </>
    );
};
