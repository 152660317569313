import React, { useState } from 'react';
import * as R from 'ramda';
import '../ChartsArea.css';
import {
    PROJECT_OPP_CATEGORY_TYPE_CHOICES,
    PROJECT_OPP_STATUS_TYPE_CHOICES,
    useProjectChoices,
    useProjectCurrency,
} from '../../../hooks/useChoices';
import { DASHBOARD_FILTER_KEY, useGraph } from '../../../common/hooks.config.new';

export const OpportunitiesTable = ({ stack, status, opps, criticalOpps, chartedOpps }) => {
    const { useVar } = useGraph(...stack);
    const cleanOpps = opps.filter(o => (o.top.category ? o : null));
    const { choices: oppCategoryTypeChoices } = useProjectChoices(
        stack,
        PROJECT_OPP_CATEGORY_TYPE_CHOICES,
    );
    const oppCategories = R.groupBy(R.path(['top', 'category']), opps);
    const preCategorizedOpps = oppCategoryTypeChoices.map(categoryType => ({
        type: categoryType.label,
        tops: oppCategories[categoryType.label] || [],
    }));
    const categorizedOpps = R.append({ type: 'Critical', tops: criticalOpps })(preCategorizedOpps);

    const categorizedSavings = R.map(category => {
        const savings = R.compose(
            R.sum,
            R.map(R.pathOr(0, ['top', 'savings'])),
            R.prop('tops'),
        )(category);
        return { ...category, savings };
    })(categorizedOpps);

    const { choices: oppStatusTypeChoices } = useProjectChoices(
        stack,
        PROJECT_OPP_STATUS_TYPE_CHOICES,
    );

    const preSortedCategorizedOpps = R.map(categorySavings => {
        const _oppStatus = R.groupBy(
            R.path(['top', 'status']),
            R.propOr([], 'tops', categorySavings),
        );
        const _chartedOpps = oppStatusTypeChoices.map(statusType => ({
            type: statusType.label,
            tops: _oppStatus[statusType.label] || [],
        }));
        return { ...categorySavings, sortedTops: _chartedOpps };
    })(categorizedSavings);

    const sortedCategorizedOpps = R.init(preSortedCategorizedOpps);
    const sortedCriticalOpps = R.last(preSortedCategorizedOpps);

    const totalSavingsSum = R.compose(R.sum, R.map(R.propOr(0, 'savings')))(categorizedSavings);

    const currency = useProjectCurrency(stack);
    const formato = val =>
        new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency,
        }).format(val);

    const [highlight, setHighlight] = useState({});
    const [filter, setFilter] = useVar(DASHBOARD_FILTER_KEY);

    return (
        <>
            <div className="table-container">
                <TableHeader
                    status={status}
                    highlight={highlight}
                    oppStatusTypeChoices={oppStatusTypeChoices}
                />
                <TableBody
                    highlight={highlight}
                    setHighlight={setHighlight}
                    sortedCategorizedOpps={sortedCategorizedOpps}
                    formato={formato}
                    filter={filter}
                    setFilter={setFilter}
                />
                <TableFooter
                    opps={opps}
                    cleanOpps={cleanOpps}
                    chartedOpps={chartedOpps}
                    highlight={highlight}
                    setHighlight={setHighlight}
                    totalSavingsSum={totalSavingsSum}
                    formato={formato}
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
            <CriticalTable
                sortedCriticalOpps={sortedCriticalOpps}
                highlight={highlight}
                setHighlight={setHighlight}
                formato={formato}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

const TableHeader = ({ status, highlight, oppStatusTypeChoices }) => (
    <div className="table-header">
        <div className="header-entry-left" style={{ fontSize: 18 }}>
            {status ? 'Status' : 'Category'}
        </div>
        <div
            className="header-entry-middle"
            style={{ fontWeight: highlight && highlight.columnIndex === -1 && 900 }}
        >
            Opportunities
        </div>
        {oppStatusTypeChoices.map((stc, i) => (
            <div
                className="header-entry-middle"
                key={stc.label}
                style={{ fontWeight: highlight && highlight.columnIndex === i && 900 }}
            >
                {stc.label}
            </div>
        ))}
        <div className="header-entry-right" style={{ fontSize: 18 }}>
            Savings
        </div>
    </div>
);

const TableBody = ({
    highlight,
    setHighlight,
    sortedCategorizedOpps,
    formato,
    filter,
    setFilter,
}) => (
    <>
        {sortedCategorizedOpps.map((c, i) => (
            <div className="table-row" key={c.type}>
                <div
                    className="table-entry-left"
                    style={{
                        color: c.type === 'Critical' && 'var(--color-error)',
                        fontWeight: highlight && highlight.rowIndex === i && 900,
                    }}
                >
                    {c.type}
                </div>
                <div
                    className="table-entry-middle"
                    style={{
                        color: c.tops.length === 0 && 'var(--color-2)',
                        cursor: c.tops.length === 0 && 'default',
                        backgroundColor: c.tops.length === 0 && 'transparent',
                        fontWeight: c.tops.length === 0 && 'normal',
                    }}
                    onMouseEnter={() =>
                        c.tops.length > 0 && setHighlight({ rowIndex: i, columnIndex: -1 })
                    }
                    onMouseLeave={() => c.tops.length > 0 && setHighlight()}
                    onClick={() =>
                        c.tops.length > 0 &&
                        setFilter(
                            R.compose(
                                R.assoc('showTops', true),
                                R.assocPath(['topType', 'values'], [{ value: 'o', label: 'o' }]),
                                R.assocPath(
                                    ['category', 'values'],
                                    [{ value: c.type, label: c.type }],
                                ),
                            )(filter),
                        )
                    }
                >
                    {c.tops.length}
                </div>
                {c.sortedTops.map((cc, index) => (
                    <div
                        onMouseEnter={() =>
                            cc.tops.length > 0 && setHighlight({ rowIndex: i, columnIndex: index })
                        }
                        onMouseLeave={() => cc.tops.length > 0 && setHighlight()}
                        className="table-entry-middle"
                        key={cc.type}
                        style={{
                            color: cc.tops.length === 0 && 'var(--color-2)',
                            cursor: cc.tops.length === 0 && 'default',
                            backgroundColor: cc.tops.length === 0 && 'transparent',
                            fontWeight: cc.tops.length === 0 && 'normal',
                        }}
                        onClick={() =>
                            cc.tops.length > 0 &&
                            setFilter(
                                R.compose(
                                    R.assoc('showTops', true),
                                    R.assocPath(
                                        ['topType', 'values'],
                                        [{ value: 'o', label: 'o' }],
                                    ),
                                    R.assocPath(
                                        ['oppStatus', 'values'],
                                        [{ value: cc.type, label: cc.type }],
                                    ),
                                    R.assocPath(
                                        ['category', 'values'],
                                        [{ value: c.type, label: c.type }],
                                    ),
                                )(filter),
                            )
                        }
                    >
                        {cc.tops.length}
                    </div>
                ))}
                <div
                    className="table-entry-right"
                    style={{
                        color: c.savings === 0 && 'var(--color-2)',
                        fontWeight: highlight && highlight.rowIndex === i && 900,
                    }}
                >
                    {formato(c.savings)}
                </div>
            </div>
        ))}
    </>
);

const TableFooter = ({
    opps,
    cleanOpps,
    chartedOpps,
    highlight,
    setHighlight,
    totalSavingsSum,
    formato,
    filter,
    setFilter,
}) => (
    <div className="table-footer">
        <div
            className="header-entry-left"
            style={{
                color: 'var(--corporate-color-11)',
                fontSize: 18,
                fontWeight: highlight && highlight.rowIndex === -2 && 900,
            }}
        >
            Total
        </div>
        <div
            className="footer-entry-middle"
            style={{
                color: R.length(opps) === 0 && 'var(--color-2)',
                cursor: R.length(opps) === 0 && 'default',
                backgroundColor: R.length(opps) === 0 && 'transparent',
                fontWeight: R.length(opps) === 0 && 'normal',
            }}
            onClick={() =>
                R.length(opps) > 0 &&
                setFilter(
                    R.compose(
                        R.assoc('showTops', true),
                        R.assocPath(['topType', 'values'], [{ value: 'o', label: 'o' }]),
                    )(filter),
                )
            }
            onMouseEnter={() => setHighlight({ columnIndex: -1, rowIndex: -2 })}
            onMouseLeave={() => setHighlight()}
        >
            {R.length(cleanOpps)}
        </div>
        {chartedOpps.map((stt, i) => (
            <div
                className="table-entry-middle"
                key={stt.type}
                style={{
                    color: stt.tops.length === 0 && 'var(--color-2)',
                    cursor: stt.tops.length === 0 && 'default',
                    backgroundColor: stt.tops.length === 0 && 'transparent',
                    fontWeight: stt.tops.length === 0 && 'normal',
                }}
                onMouseEnter={() =>
                    stt.tops.length > 0 && setHighlight({ columnIndex: i, rowIndex: -2 })
                }
                onMouseLeave={() => stt.tops.length > 0 && setHighlight()}
                onClick={() =>
                    stt.tops.length > 0 &&
                    setFilter(
                        R.compose(
                            R.assoc('showTops', true),
                            R.assocPath(['topType', 'values'], [{ value: 'o', label: 'o' }]),
                            R.assocPath(
                                ['oppStatus', 'values'],
                                [{ value: stt.type, label: stt.type }],
                            ),
                        )(filter),
                    )
                }
            >
                {stt.tops.length}
            </div>
        ))}
        <div
            className="footer-entry-right"
            style={{
                fontSize: 18,
                fontWeight: highlight && highlight.rowIndex === -2 && 900,
            }}
        >
            {formato(totalSavingsSum)}
        </div>
    </div>
);

const CriticalTable = ({
    sortedCriticalOpps,
    highlight,
    setHighlight,
    formato,
    filter,
    setFilter,
}) => (
    <div className="table-container" style={{ marginTop: 6, marginBottom: 30 }}>
        <div className="table-footer-critical">
            <div
                className="header-entry-left"
                style={{
                    color:
                        R.length(sortedCriticalOpps.tops) === 0
                            ? 'var(--color-2)'
                            : 'var(--corporate-color-12',
                    fontWeight: highlight && highlight.rowIndex === -1 && 900,
                    fontSize: 18,
                }}
            >
                Critical
            </div>
            <div
                className="footer-entry-middle"
                style={{
                    color:
                        R.length(sortedCriticalOpps.tops) === 0
                            ? 'var(--color-2)'
                            : 'var(--corporate-color-12',
                    cursor: R.length(sortedCriticalOpps.tops) === 0 && 'default',
                    backgroundColor: R.length(sortedCriticalOpps.tops) === 0 && 'transparent',
                    fontWeight: R.length(sortedCriticalOpps.tops) === 0 && 'normal',
                }}
                onClick={() =>
                    R.length(sortedCriticalOpps.tops) > 0 &&
                    setFilter(
                        R.compose(
                            R.assoc('isCritical', true),
                            R.assoc('showTops', true),
                            R.assocPath(['topType', 'values'], [{ value: 'o', label: 'o' }]),
                        )(filter),
                    )
                }
                onMouseEnter={() =>
                    R.length(sortedCriticalOpps.tops) > 0 &&
                    setHighlight({ columnIndex: -1, rowIndex: -1 })
                }
                onMouseLeave={() => R.length(sortedCriticalOpps.tops) > 0 && setHighlight()}
            >
                {R.length(sortedCriticalOpps.tops)}
            </div>
            {sortedCriticalOpps.sortedTops.map((stt, i) => (
                <div
                    className="table-entry-middle"
                    key={stt.type}
                    style={{
                        color:
                            stt.tops.length === 0 ? 'var(--color-2)' : 'var(--corporate-color-12',
                        cursor: stt.tops.length === 0 && 'default',
                        backgroundColor: stt.tops.length === 0 && 'transparent',
                        fontWeight: stt.tops.length === 0 && 'normal',
                    }}
                    onMouseEnter={() =>
                        stt.tops.length > 0 && setHighlight({ columnIndex: i, rowIndex: -1 })
                    }
                    onMouseLeave={() => stt.tops.length > 0 && setHighlight()}
                    onClick={() =>
                        stt.tops.length > 0 &&
                        setFilter(
                            R.compose(
                                R.assoc('isCritical', true),
                                R.assoc('showTops', true),
                                R.assocPath(['topType', 'values'], [{ value: 'o', label: 'o' }]),
                                R.assocPath(
                                    ['oppStatus', 'values'],
                                    [{ value: stt.type, label: stt.type }],
                                ),
                            )(filter),
                        )
                    }
                >
                    {stt.tops.length}
                </div>
            ))}
            <div
                className="footer-entry-right"
                style={{
                    color:
                        sortedCriticalOpps.savings === 0
                            ? 'var(--color-2)'
                            : 'var(--corporate-color-12',
                    fontSize: 18,
                    fontWeight: highlight && highlight.rowIndex === -1 && 900,
                }}
            >
                {formato(sortedCriticalOpps.savings)}
            </div>
        </div>
    </div>
);
