import { useRef } from 'react';
import { api } from '../common/hooks.config.new';

export const useUserEmail = () => {
    const ref = useRef();

    if (!ref.current) {
        try {
            ref.current = api.getCurrentUser()?.email;
        } catch (err) {
            console.error('Error getting user email', err);
        }
    }

    return ref.current;
};
