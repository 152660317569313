import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams } from 'react-router';
import { api } from './hooks.config.new';
import { useToast } from '../hooks/useToast';
import { Toast } from '../Components/Elements/Toast';

export const TASKS = {
    LOGIN: 'login',
    VERIFY: 'verify',
    FORGOT_PASSWORD: 'forgotPassword',
    CHANGE_PASSWORD: 'changePassword',
    REGISTER: 'register',
};

export const AuthScreen = ({ onLoggedIn, defaultState }) => {
    const params = useParams();
    const [task, setTask] = useState({
        name: defaultState || TASKS.LOGIN,
        email: params.emailAdress || '',
        password: '',
        oldPassword: params.password || '',
    });

    // const register = (email, password) => api.registerInit({ email, password });
    const activate = verificationCode =>
        api.registerVerify({ email: task.email, verificationCode });
    const resendActivationCode = () => api.registerVerifyResend({ email: task.email });
    const confirmPassword = (verificationCode, password) =>
        api.resetPasswordVerify({ email: task.email, password, verificationCode });
    const changePassword = newPassword =>
        api
            .resetPasswordVerify({
                email: task.email,
                password: newPassword,
                oldPassword: task.oldPassword,
            })
            .then(() => onLoggedIn(true));

    return (
        <div className="centerContainer">
            <div className="centerBox">
                <div
                    style={{
                        marginLeft: '15%',
                        marginRight: '15%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    {task.name === TASKS.LOGIN && (
                        <Login setTask={setTask} onLoggedIn={onLoggedIn} />
                    )}
                    {/* {task.name === TASKS.REGISTER
            && (
            <Register
                setTask={setTask}
                register={register}
            />
            )} */}
                    {task.name === TASKS.FORGOT_PASSWORD && (
                        <ForgotPassword setTask={setTask} confirmPassword={confirmPassword} />
                    )}
                    {task.name === TASKS.CHANGE_PASSWORD && (
                        <ChangePassword setTask={setTask} changePassword={changePassword} />
                    )}
                    {task.name === TASKS.VERIFY && (
                        <Activate
                            setTask={setTask}
                            resendCode={resendActivationCode}
                            activate={activate}
                        />
                    )}
                </div>
            </div>
            <div
                style={{
                    position: 'fixed',
                    bottom: '0.1rem',
                    right: '0.2rem',
                    color: 'var(--color-2)',
                    fontSize: '1rem',
                    fontWeight: 300,
                }}
            >
                v1.0.24
            </div>
            <Toast name="auth-toast" />
        </div>
    );
};

const Login = ({ setTask, onLoggedIn }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const forgotPassword = () => api.resetPasswordInit({ email });
    const login = () =>
        api
            .login({ email, password })
            .then(() => {
                onLoggedIn(true);
            })
            .catch(err => {
                err.name === 'LoginVerifyError' &&
                    setToast('error', 'Username or password is incorrect...', 7500);
                err.name === 'UserNotFoundError' &&
                    setToast('error', 'User does not exist...', 7500);
                err.name === 'InvalidEmailError' && setToast('error', 'Mail is invalid...', 7500);
                err.code === 'UserNotConfirmedException' && setTask({ name: 'activate', email });
            });
    const setToast = useToast('auth-toast');

    const inputCheck = () => {
        if (!email) {
            setToast('error', 'Mail address is missing....', 7500);
            return false;
        }
        if (!password) {
            setToast('error', 'Password is missing....', 7500);
            return false;
        }
        return true;
    };
    useHotkeys(
        'enter',
        () => {
            inputCheck() && login() && setToast('spinner', 'Logging in...', 7500);
        },
        { enableOnTags: ['INPUT'] },
    );
    return (
        <>
            <h1
                style={{
                    fontFamily: 'sans-serif',
                    marginBottom: '36px',
                    fontSize: '38px',
                    color: 'var(--corporate-color-2)',
                }}
                className="font-weight-100"
            >
                Sign in
            </h1>
            <input
                placeholder="E-Mail"
                name="username"
                value={email}
                onChange={e => setEmail(e.target.value)}
                style={{
                    width: '70%',
                    marginLeft: '0',
                    paddingLeft: '12px',
                    height: '35px ',
                    marginBottom: '3px',
                    borderRadius: '20px',
                }}
            />
            <input
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                style={{
                    width: '70%',
                    marginLeft: '0',
                    paddingLeft: '12px',
                    height: '35px ',
                    marginBottom: '24px',
                    borderRadius: '20px',
                }}
            />
            <button
                onClick={() =>
                    inputCheck() && login() && setToast('spinner', 'Logging in...', 7500)
                }
                style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
                className="button-blue"
            >
                Sign in
            </button>
            <button
                onClick={() =>
                    email &&
                    forgotPassword(email) &&
                    setTask({ name: TASKS.FORGOT_PASSWORD, email })
                }
                style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
                className="button-quiet"
            >
                Forgot password
            </button>
            {/* <button
                onClick={() => setTask({ name: TASKS.REGISTER, email, password })}
                style={{ width: '70%', marginLeft: '0' }}
                className="button-quiet"
            >
                Sign up
            </button> */}
        </>
    );
};

// const Register = ({ setTask, register }) => {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [passwordConfirm, setPasswordConfirm] = useState('');
//     return (
//         <>
//             <h1 style={{
//                 fontFamily: 'sans-serif', marginBottom: '36px', fontSize: '38px', color: 'var(--corporate-color-2)', fontWeight: 100,
//             }}
//             >
//                 Sign up
//             </h1>
//             <input
//                 placeholder="E-mail"
//                 name="username"
//                 value={email}
//                 onChange={e => setEmail(e.target.value)}
//                 style={{
//                     width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '3px', borderRadius: '20px',
//                 }}
//             />
//             <input
//                 type="password"
//                 name="password"
//                 placeholder="Password"
//                 value={password}
//                 onChange={e => setPassword(e.target.value)}
//                 style={{
//                     width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '3px', borderRadius: '20px',
//                 }}
//             />
//             <input
//                 type="password"
//                 name="password-confirm"
//                 placeholder="Conform password"
//                 value={passwordConfirm}
//                 onChange={e => setPasswordConfirm(e.target.value)}
//                 style={{
//                     width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '24px', borderRadius: '20px',
//                 }}
//             />
//             <button
//                 onClick={() => password === passwordConfirm
//           && register(email, password)
//           && setTask({ name: TASKS.VERIFY, email })}
//                 style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
//                 className="button-blue"
//             >
//                 Sign up
//             </button>
//             <button
//                 onClick={() => setTask({ name: TASKS.LOGIN, email })}
//                 style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
//                 className="button-quiet"
//             >
//                 Back to sign in
//             </button>
//         </>
//     );
// };

const ForgotPassword = ({ setTask, confirmPassword }) => {
    const [validationCode, setValidationCode] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    useHotkeys(
        'enter',
        () => {
            validationCode &&
                password === passwordConfirm &&
                confirmPassword(validationCode, password);
        },
        { enableOnTags: ['INPUT'] },
    );
    return (
        <>
            <h1
                style={{
                    fontFamily: 'sans-serif',
                    marginBottom: '36px',
                    fontSize: '38px',
                    color: 'var(--corporate-color-2)',
                }}
                className="font-weight-100"
            >
                Forgot password
            </h1>
            <input
                placeholder="Validation code"
                name="validation-code"
                value={validationCode}
                onChange={e => setValidationCode(e.target.value)}
                style={{
                    width: '70%',
                    marginLeft: '0',
                    paddingLeft: '12px',
                    height: '35px ',
                    marginBottom: '3px',
                    borderRadius: '20px',
                }}
            />
            <input
                type="password"
                name="password"
                placeholder="New password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                style={{
                    width: '70%',
                    marginLeft: '0',
                    paddingLeft: '12px',
                    height: '35px ',
                    marginBottom: '3px',
                    borderRadius: '20px',
                }}
            />
            <input
                type="password"
                name="password-confirm"
                placeholder="Confirm new password"
                value={passwordConfirm}
                onChange={e => setPasswordConfirm(e.target.value)}
                style={{
                    width: '70%',
                    marginLeft: '0',
                    paddingLeft: '12px',
                    height: '35px ',
                    marginBottom: '24px',
                    borderRadius: '20px',
                }}
            />
            <button
                onClick={() =>
                    password === passwordConfirm &&
                    confirmPassword(validationCode, password) &&
                    setTask({ name: TASKS.LOGIN })
                }
                style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
                className="button-blue"
            >
                Set new password
            </button>
            <button
                onClick={() => setTask({ name: TASKS.LOGIN })}
                style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
                className="button-quiet"
            >
                Back to sign in
            </button>
        </>
    );
};

const ChangePassword = ({ setTask, changePassword }) => {
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    useHotkeys(
        'enter',
        async () => {
            if (password === passwordConfirm) {
                try {
                    await changePassword(password);
                } catch (err) {
                    console.log(err);
                }
            }
        },
        { enableOnTags: ['INPUT'] },
    );

    return (
        <>
            <h1
                style={{
                    fontFamily: 'sans-serif',
                    marginBottom: '36px',
                    fontSize: '38px',
                    color: 'var(--corporate-color-2)',
                }}
                className="font-weight-100"
            >
                Set password
            </h1>
            <input
                type="password"
                name="password"
                placeholder="New password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                style={{
                    width: '70%',
                    marginLeft: '0',
                    paddingLeft: '12px',
                    height: '35px ',
                    marginBottom: '3px',
                    borderRadius: '20px',
                }}
            />
            <input
                type="password"
                name="password-confirm"
                placeholder="Confirm new password"
                value={passwordConfirm}
                onChange={e => setPasswordConfirm(e.target.value)}
                style={{
                    width: '70%',
                    marginLeft: '0',
                    paddingLeft: '12px',
                    height: '35px ',
                    marginBottom: '24px',
                    borderRadius: '20px',
                }}
            />
            <button
                onClick={async () => {
                    if (password === passwordConfirm) {
                        try {
                            await changePassword(password);
                        } catch (err) {
                            console.log(err);
                        }
                    }
                }}
                style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
                className="button-blue"
            >
                Set password
            </button>
            <button
                onClick={() => setTask({ name: TASKS.LOGIN })}
                style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
                className="button-quiet"
            >
                Back to sign in
            </button>
        </>
    );
};

const Activate = ({ setTask, activate, resendCode }) => {
    const [activationCode, setActivationCode] = useState('');
    useHotkeys(
        'enter',
        () => {
            activationCode && activate(activationCode);
        },
        { enableOnTags: ['INPUT'] },
    );
    return (
        <>
            <h1
                style={{
                    fontFamily: 'sans-serif',
                    marginBottom: '36px',
                    fontSize: '38px',
                    color: 'var(--corporate-color-2)',
                }}
                className="font-weight-100"
            >
                Activation
            </h1>
            <input
                placeholder="Activation code"
                name="activation-code"
                value={activationCode}
                onChange={e => setActivationCode(e.target.value)}
                style={{
                    width: '70%',
                    marginLeft: '0',
                    paddingLeft: '12px',
                    height: '35px ',
                    marginBottom: '24px',
                    borderRadius: '20px',
                }}
            />
            <button
                onClick={() =>
                    activationCode && activate(activationCode) && setTask({ name: TASKS.LOGIN })
                }
                style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
                className="button-blue"
            >
                Activate
            </button>
            <button
                onClick={() => resendCode()}
                style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
                className="button-quiet"
            >
                Send code again
            </button>
            <button
                onClick={() => setTask({ name: TASKS.LOGIN })}
                style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
                className="button-quiet"
            >
                Back to sign in
            </button>
        </>
    );
};
