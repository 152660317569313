import React, { memo, useState } from 'react';
import * as R from 'ramda';
import '../../Components/Elements/ChartsArea.css';
import { BsLayoutThreeColumns } from 'react-icons/bs';
import { IoBarChartOutline, IoSwapHorizontalOutline } from 'react-icons/io5';
import { Button } from '../../Components/Common/Controls';
import { PROJECT_OPP_STATUS_TYPE_CHOICES, useProjectChoices } from '../../hooks/useChoices';
import {
    ActionsKanbanBoardMobile,
    OppsCategoryKanbanBoardMobile,
    OppsStatusKanbanBoardMobile,
    ProjectPhasesKanbanBoardMobile,
} from './KanbanBoardsMobile';
import { ProjectPhasesGanttChartMobile } from './GanttChartsMobile';
import { OppLegendMobile, OppsCountDonutMobile, OppsSavingsDonutMobile } from './OppChartsMobile';
import { OppTableMobile } from './OppTableMobile';
import { ActionsChartMobile } from './ActionChartsMobile';

const _Opportunities = ({
    stack,
    opps,
    criticalOpps,
    dashboardState,
    oppTracking,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
}) => {
    const { choices: oppStatusTypeChoices } = useProjectChoices(
        stack,
        PROJECT_OPP_STATUS_TYPE_CHOICES,
    );
    const oppStatus = R.groupBy(R.path(['top', 'status']), opps);
    const chartedOpps = oppStatusTypeChoices.map(statusType => ({
        type: statusType.label,
        tops: oppStatus[statusType.label] || [],
    }));
    const [oppsChart, setOppsChart] = useState(true);
    const [sortByCategory, setSortByCategory] = useState(true);

    return (
        <div className="mobile-dashboard-area">
            <div className="header-container">
                <div className="headline-mobile">
                    {dashboardState === 'user'
                        ? 'My'
                        : dashboardState === 'subproject'
                        ? 'Subproject'
                        : 'Project'}{' '}
                    Opps
                </div>
                <div style={{ display: 'flex' }}>
                    {!oppsChart && (
                        <Button
                            onClick={() => setSortByCategory(!sortByCategory)}
                            className="opps-sorting-button"
                        >
                            <div>
                                <IoSwapHorizontalOutline
                                    size="4rem"
                                    style={{ marginRight: '0.25rem' }}
                                />
                            </div>
                            {sortByCategory ? 'S' : 'C'}
                        </Button>
                    )}
                    <Button
                        onClick={() => setOppsChart(!oppsChart)}
                        className="screen-selector-button"
                    >
                        {!oppsChart ? (
                            <IoBarChartOutline size="4rem" />
                        ) : (
                            <BsLayoutThreeColumns size="4rem" />
                        )}
                    </Button>
                </div>
            </div>
            {oppsChart ? (
                <div className="layout">
                    <div className="opp-charts-mobile">
                        <div className="chart-legend-mobile" style={{ marginTop: 30 }}>
                            <OppsCountDonutMobile
                                stack={stack}
                                chartedOpps={chartedOpps}
                                criticalOppsCount={R.length(criticalOpps)}
                            />
                            <OppLegendMobile oppStatusTypeChoices={oppStatusTypeChoices} />
                            <OppsSavingsDonutMobile
                                stack={stack}
                                chartedOpps={chartedOpps}
                                criticalOpps={criticalOpps}
                            />
                        </div>
                    </div>
                    <div className="tables" style={{ marginTop: 12 }}>
                        <OppTableMobile
                            stack={stack}
                            opps={opps}
                            criticalOpps={criticalOpps}
                            chartedOpps={chartedOpps}
                        />
                    </div>
                </div>
            ) : sortByCategory ? (
                <OppsCategoryKanbanBoardMobile
                    stack={stack}
                    opps={opps}
                    criticalOpps={criticalOpps}
                    canAdminProject={canAdminProject}
                    canWriteProject={canWriteProject}
                    subprojectId={subprojectId}
                    editMessage={editMessage}
                    userName={userName}
                    oppTracking={oppTracking}
                />
            ) : (
                <OppsStatusKanbanBoardMobile
                    stack={stack}
                    opps={opps}
                    criticalOpps={criticalOpps}
                    canAdminProject={canAdminProject}
                    canWriteProject={canWriteProject}
                    subprojectId={subprojectId}
                    editMessage={editMessage}
                    userName={userName}
                    oppTracking={oppTracking}
                />
            )}
        </div>
    );
};
const Opportunities = memo(_Opportunities, R.equals);

const _Actions = ({
    stack,
    dashboardState,
    fnSelectNode,
    actions,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
}) => {
    const [actionsChart, setActionsChart] = useState(false);
    return (
        <div className="mobile-dashboard-area">
            <div className="header-container">
                <div className="headline-mobile">
                    {dashboardState === 'user'
                        ? 'My'
                        : dashboardState === 'subproject'
                        ? 'Subproject'
                        : 'Project'}{' '}
                    Actions
                </div>
                <Button
                    onClick={() => setActionsChart(!actionsChart)}
                    className="screen-selector-button"
                >
                    {!actionsChart ? (
                        <IoBarChartOutline size="4rem" />
                    ) : (
                        <BsLayoutThreeColumns size="4rem" />
                    )}
                </Button>
            </div>
            {actionsChart ? (
                <div className="actions-chart-mobile">
                    <div className="inner-mobile">
                        <p className="actions-headline-mobile">Actions by status</p>
                        <ActionsChartMobile
                            stack={stack}
                            actions={actions}
                            fnSelectNode={fnSelectNode}
                        />
                    </div>
                </div>
            ) : (
                <ActionsKanbanBoardMobile
                    stack={stack}
                    actions={actions}
                    canAdminProject={canAdminProject}
                    canWriteProject={canWriteProject}
                    subprojectId={subprojectId}
                    editMessage={editMessage}
                    userName={userName}
                />
            )}
        </div>
    );
};
const Actions = memo(_Actions, R.equals);

const _Phases = ({
    stack,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
    oppTracking,
}) => {
    const [phasesChart, setPhasesChart] = useState(false);
    return (
        <div className="mobile-dashboard-area">
            <div className="header-container">
                <div className="headline-mobile">Project Phases</div>
                <Button
                    onClick={() => setPhasesChart(!phasesChart)}
                    className="screen-selector-button"
                >
                    {phasesChart ? (
                        <IoBarChartOutline size="4rem" />
                    ) : (
                        <BsLayoutThreeColumns size="4rem" />
                    )}
                </Button>
            </div>
            {phasesChart ? (
                <ProjectPhasesKanbanBoardMobile
                    stack={stack}
                    canAdminProject={canAdminProject}
                    canWriteProject={canWriteProject}
                    subprojectId={subprojectId}
                    editMessage={editMessage}
                    userName={userName}
                    oppTracking={oppTracking}
                />
            ) : (
                <ProjectPhasesGanttChartMobile stack={stack} />
            )}
        </div>
    );
};
const Phases = memo(_Phases, R.equals);

const _ChartsAndBoardsAreaMobile = ({
    stack,
    opps,
    criticalOpps,
    actions,
    tops,
    dashboardState,
    fnSelectNode,
    oppTracking,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
}) => (
    <div className="charts-area">
        <Actions
            stack={stack}
            dashboardState={dashboardState}
            oppTracking={oppTracking}
            actions={actions}
            fnSelectNode={fnSelectNode}
            canAdminProject={canAdminProject}
            canWriteProject={canWriteProject}
            subprojectId={subprojectId}
            editMessage={editMessage}
            userName={userName}
        />
        {oppTracking && (
            <Opportunities
                stack={stack}
                opps={opps}
                criticalOpps={criticalOpps}
                dashboardState={dashboardState}
                oppTracking={oppTracking}
                canAdminProject={canAdminProject}
                canWriteProject={canWriteProject}
                subprojectId={subprojectId}
                editMessage={editMessage}
                userName={userName}
            />
        )}
        <Phases
            stack={stack}
            dashboardState={dashboardState}
            oppTracking={oppTracking}
            tops={tops}
            fnSelectNode={fnSelectNode}
            canAdminProject={canAdminProject}
            canWriteProject={canWriteProject}
            subprojectId={subprojectId}
            editMessage={editMessage}
            userName={userName}
        />
    </div>
);
export const ChartsAndBoardsAreaMobile = memo(_ChartsAndBoardsAreaMobile, R.equals);
