import React, { useState } from 'react';
import { useModalState } from '../../../../hooks/useModal';
import { Modal } from '../../../Common/Modal';
import { ActionHistoryChart } from '../components/ActionHistoryChart';
import { ActionDelayChart } from '../components/ActionDelayChart';
import { Button } from '../../../Common/Controls';

export const ACTION_CHARTS_MODAL = 'action-charts-modal';

export const ActionChartsModal = ({ stack, actions }) => {
    const [isOpen, close] = useModalState(ACTION_CHARTS_MODAL);

    return (
        <div className="modal-container-compensation">
            <Modal
                name={ACTION_CHARTS_MODAL}
                style={{
                    height: '90vh',
                    top: '5vh',
                    width: '95vw',
                    left: '2.5vw',
                }}
                close={close}
            >
                {isOpen ? <ModalContent stack={stack} actions={actions} close={close} /> : null}
            </Modal>
        </div>
    );
};

const MODES = {
    HISTORIES: 'histories',
    DELAY: 'delay',
};

const ModalContent = ({ stack, actions, close }) => {
    const [mode, setMode] = useState(MODES.HISTORIES);

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    padding: '1rem 2rem',
                    gap: '1rem',
                }}
            >
                {/* <Button
                    onClick={() => {
                        if (mode === MODES.HISTORIES) {
                            setMode(MODES.DELAY);
                        } else if (mode === MODES.DELAY) {
                            setMode(MODES.HISTORIES);
                        }
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        border: 'none',
                        boxSizing: 'borderBox',
                        marginLeft: 18,
                        backgroundColor: 'white',
                        boxShadow: '0 5px 8px -2px var(--corporate-color-1-light4)',
                    }}
                >
                    <div>{mode === MODES.HISTORIES ? 'Late Actions' : 'Histories'}</div>
                </Button> */}
                <Button
                    onClick={() => {
                        setMode(MODES.DELAY);
                    }}
                    style={{
                        backgroundColor: 'white',
                        border:
                            mode === MODES.DELAY
                                ? '1px solid var(--corporate-color-13)'
                                : '1px solid transparent',
                        color: mode === MODES.DELAY && 'var(--corporate-color-13)',
                        boxSizing: 'borderBox',
                        boxShadow:
                            mode === MODES.DELAY
                                ? '0 5px 8px -2px var(--corporate-color-13-light)'
                                : '0 5px 8px -2px var(--corporate-color-1-light4)',
                    }}
                    className="font-weight-100 hover-scale"
                >
                    Late actions
                </Button>
                <Button
                    onClick={() => {
                        setMode(MODES.HISTORIES);
                    }}
                    style={{
                        backgroundColor: 'white',
                        border:
                            mode === MODES.HISTORIES
                                ? '1px solid var(--corporate-color-13)'
                                : '1px solid transparent',
                        color: mode === MODES.HISTORIES && 'var(--corporate-color-13)',
                        boxSizing: 'borderBox',
                        boxShadow:
                            mode === MODES.HISTORIES
                                ? '0 5px 8px -2px var(--corporate-color-13-light)'
                                : '0 5px 8px -2px var(--corporate-color-1-light4)',
                    }}
                    className="font-weight-100 hover-scale"
                >
                    Histories
                </Button>
            </div>
            <div style={{ paddingLeft: '1rem', paddingRight: '3rem' }}>
                {mode === MODES.HISTORIES && (
                    <ActionHistoryChart stack={stack} actions={actions} onClick={close} />
                )}
                {mode === MODES.DELAY && (
                    <ActionDelayChart stack={stack} actions={actions} onClick={close} />
                )}
            </div>
        </div>
    );
};
