import React, { useCallback, useEffect, useMemo } from 'react';
import * as R from 'ramda';
import {
    ACTIONS_KANBAN_BOARD,
    DASHBOARD_FILTER_KEY,
    DASHBOARD_STATE_KEY,
    OPP_TRACKING,
    ORGANIZATION_ID_KEY,
    PHASES_KANBAN_BOARD,
    PROJECT_ID_KEY,
    PROJECT_MEETINGS_KEY,
    SEARCH_STRING_KEY,
    SORTED_GROUPED_TOPS_KEY,
    SUBPROJECT_ID_KEY,
    useGraph,
} from '../../common/hooks.config.new';
import { useUserEmail } from '../../hooks/useUserEmail';
import { useContact } from '../../hooks/useContact';
import { constructNodeView } from '../../common/got-adjunct';
import { filterMeetings, filterTops } from '../../common/filteredtops.util';
import { filterCriticalOpps } from '../../common/filterCriticalOpps';
import { useToast } from '../../hooks/useToast';
import { Toast } from '../../Components/Elements/Toast';
import { ChartsAndBoardsAreaMobile } from '../Elements/ChartsAndBoardsAreaMobile';
import { useNodeFromVar } from '../../hooks/useNode';
import { TopsAndMeetingsAreaMobile } from '../Elements/TopsAndMeetingsAreaMobile';

export const ProjectDashboardMobileContent = ({ stack, selectorMenu, setSelectorMenu }) => {
    const { useVar, useView, pull } = useGraph(...stack);

    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [{ tops: sortedGroupedTops = {} } = {}] = useVar(SORTED_GROUPED_TOPS_KEY);
    const [{ meetings: subprojectMeetings } = {}] = useVar(PROJECT_MEETINGS_KEY);

    const topRows = useMemo(
        () =>
            R.compose(
                R.sortBy(R.prop('businessId')),
                R.values,
                R.map(entries => ({
                    ...R.last(entries),
                    previousVersions: R.dropLast(1, entries),
                })),
            )(sortedGroupedTops),
        [sortedGroupedTops],
    );

    const [dashboardState] = useVar(DASHBOARD_STATE_KEY);
    const [filter = {}] = useVar(DASHBOARD_FILTER_KEY);
    const [searchString] = useVar(SEARCH_STRING_KEY);
    const [projectId] = useVar(PROJECT_ID_KEY);
    const user = useUserEmail();

    const { node: organization } = useNodeFromVar(stack, ORGANIZATION_ID_KEY);

    const { contact } = useContact(stack, projectId, user);
    const userName = R.pathOr(user, ['node', 'name'], contact);

    const projectView = constructNodeView(projectId, { node: true, rights: true });
    const projViewRes = useView(projectView);
    const projectBag = R.propOr({}, projectId, projViewRes);
    const canWriteProject = R.pathOr(false, ['rights', 'user', user, 'write'], projectBag);
    const canAdminProject = R.pathOr(false, ['rights', 'user', user, 'admin'], projectBag);
    const statusses = R.pathOr([], ['node', 'oppStatusTypes'], projectBag);
    useEffect(() => {
        projectId && pull(projectView);
    }, [projectId]);

    const { [subprojectId]: subprojectBag } = useView(constructNodeView(subprojectId));
    const subprojectBusinessId = R.pathOr('', ['node', 'businessId'], subprojectBag);

    const { searchedTops, actions, opps } = filterTops({
        showTops: filter?.showTops || false,
        filter,
        statusses,
        searchString,
        dashboardState,
        subprojectBusinessId,
        userName,
    })(topRows);

    const filterMode =
        R.length(filter?.topTypes?.values) > 0 ||
        R.length(filter?.actionStatus?.values) > 0 ||
        R.length(filter?.oppStatus?.values) > 0 ||
        R.length(filter?.category?.values) > 0 ||
        R.length(filter?.responsible?.values) > 0 ||
        R.length(filter?.dueDate?.from) > 0 ||
        R.length(filter?.dueDate?.to) > 0;

    const searchedMeetings = filterMeetings({
        filter,
        searchString,
        dashboardState,
        subprojectBusinessId,
        userName,
        searchedTops,
        filterMode,
    })(subprojectMeetings);

    const organizationName = R.propOr('Organization', 'name', organization);
    const projectName = R.pathOr('Project', ['node', 'title'], projectBag);
    const subprojectName = R.pathOr('Subproject', ['node', 'title'], subprojectBag);

    return (
        <ScreenContent
            stack={stack}
            searchedTops={searchedTops}
            actions={actions}
            opps={opps}
            dashboardState={dashboardState}
            projectId={projectId}
            subprojectId={subprojectId}
            canAdminProject={canAdminProject}
            canWriteProject={canWriteProject}
            userName={userName}
            searchedMeetings={searchedMeetings}
            filter={filter}
            organizationName={organizationName}
            projectName={projectName}
            subprojectName={subprojectName}
            selectorMenu={selectorMenu}
            setSelectorMenu={setSelectorMenu}
        />
    );
};

const ScreenContent = ({
    stack,
    searchedTops,
    actions,
    opps,
    dashboardState,
    projectId,
    canAdminProject,
    canWriteProject,
    subprojectId,
    userName,
    searchedMeetings,
    filter,
}) => {
    const selectTopNode = useCallback(R.prop('top'));
    const { useVar, update } = useGraph(...stack);
    const [organizationId] = useVar(ORGANIZATION_ID_KEY);
    const [oppTracking] = useVar(OPP_TRACKING);

    const msDate = Date.now();

    // TODO check for performance
    // Push after map
    useEffect(() => {
        const openActions = actions ? actions.filter(a => selectTopNode(a).status === 'Open') : [];
        projectId &&
            openActions.map(a => {
                const msDueDate = new Date(selectTopNode(a).dueDate).getTime();
                if (msDueDate < msDate && selectTopNode(a).status === 'Open') {
                    update(R.assoc('status', 'Late', selectTopNode(a)));
                }
                return null;
            });
    }, [projectId]);

    const criticalOpps = filterCriticalOpps(stack, projectId)(opps, selectTopNode);

    const topsToDisplay = oppTracking
        ? searchedTops
        : R.filter(t => t.topType !== 'o')(searchedTops || []);

    const setEditMessageToast = useToast('edit-message');
    // TODO change subproject in edit modal to tops sub
    const editMessage = message => {
        setEditMessageToast('hint', message || 'Select a subproject to edit this item...', 5000);
    };

    const sortedMeetings =
        filter?.meetingDate?.sort === 'desc'
            ? R.sortBy(R.path(['node', 'date']))(searchedMeetings)
            : R.reverse(R.sortBy(R.path(['node', 'date']))(searchedMeetings));

    return (
        <div className="mobile-screen-container fader">
            <div style={{ height: '7.5rem' }} />
            {projectId ? (
                <>
                    {opps && actions && (
                        <ChartsAndBoardsAreaMobile
                            stack={stack}
                            opps={opps}
                            criticalOpps={criticalOpps}
                            actions={actions}
                            dashboardState={dashboardState}
                            fnSelectNode={selectTopNode}
                            oppTracking={oppTracking}
                            canAdminProject={canAdminProject}
                            canWriteProject={canWriteProject}
                            subprojectId={subprojectId}
                            editMessage={editMessage}
                            userName={userName}
                        />
                    )}
                    {searchedTops && (
                        <TopsAndMeetingsAreaMobile
                            topsToDisplay={topsToDisplay}
                            stack={stack}
                            canAdminProject={canAdminProject}
                            canWriteProject={canWriteProject}
                            subprojectId={subprojectId}
                            editMessage={editMessage}
                            userName={userName}
                            oppTracking={oppTracking}
                            filter={filter}
                            sortedMeetings={sortedMeetings}
                        />
                    )}
                </>
            ) : (
                <p className="dashboard-message-mobile">
                    {!organizationId
                        ? 'Select or create an organization and a project'
                        : 'Select or create a project'}
                </p>
            )}
            <Toast name="edit-message" />
            <Toast name="setOrg" />
            <Toast name={ACTIONS_KANBAN_BOARD} />
            <Toast name={PHASES_KANBAN_BOARD} />
            <div style={{ height: '20vh' }} />
        </div>
    );
};
