import * as R from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import {
    IoAddOutline,
    IoChevronForwardOutline,
    IoCreateOutline,
    IoLinkOutline,
    IoSearchOutline,
    IoTrailSignOutline,
    IoTrashOutline,
} from 'react-icons/io5';
import { Modal } from '../Common/Modal';
import { ListField } from '../Common/Fields';
import { Button } from '../Common/Controls';
import { useModal, useModalState } from '../../hooks/useModal';
import {
    MILESTONE_ID_KEY,
    MILESTONE_TOPS_MODAL,
    MILESTONE_TOP_EDGE,
    PROJECT_ID_KEY,
    PROJECT_MILESTONES_MODAL,
    PROJECT_MILESTONE_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { Toast } from '../Elements/Toast';
import { EditProjectMilestoneModal } from './EditProjectMilestoneModal';
import { formatShortDate, newId, newIsoDate, newMsDate } from '../../common/util';
import { useUserEmail } from '../../hooks/useUserEmail';
import { useToast } from '../../hooks/useToast';
import { createOfflinePush } from '../../common/got-adjunct';

const constructMilestonesView = projectId =>
    projectId
        ? {
              [projectId]: {
                  as: 'project',
                  include: {
                      node: true,
                  },
                  edges: {
                      [PROJECT_MILESTONE_EDGE]: {
                          as: 'milestones',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const ProjectMilestonesModal = ({ stack }) => {
    const [isOpen, close] = useModalState(PROJECT_MILESTONES_MODAL);
    return (
        <Modal
            name={PROJECT_MILESTONES_MODAL}
            style={{
                height: '90vh',
                top: '5vh',
                width: '80vw',
                left: '10vw',
            }}
            topbar={<IoTrailSignOutline size={24} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <ModalContent stack={stack} close={close} /> : null}
        </Modal>
    );
};

const ModalContent = ({ stack: parentStack, close }) => {
    const stack = R.append(PROJECT_MILESTONES_MODAL, parentStack);
    const { update, add, setRights, useView, pull, assoc, dissoc, inheritRights } = useGraph(
        ...stack,
    );
    const { useVar } = useGraph(...parentStack);

    const openEditProjectMilestoneModal = useModal('edit-project-milestone');
    const openMilestoneTopsModal = useModal(MILESTONE_TOPS_MODAL);
    const [projectId] = useVar(PROJECT_ID_KEY);
    const [, setMilestoneId] = useVar(MILESTONE_ID_KEY);
    const [search, setSearch] = useState(false);
    const [milestoneSearch, setMilestoneSearch] = useState('');
    const milestonesView = constructMilestonesView(projectId);
    useEffect(() => {
        pull(milestonesView);
    }, []);
    const { project: { milestones = {} } = {} } = useView(milestonesView);
    const user = useUserEmail();
    const [top] = useModalState(PROJECT_MILESTONES_MODAL);

    const milestonesSearch = searchString => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]+[u00C0-u017F]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.compose(
                R.sortBy(R.prop('name')),
                R.filter(p => p.node.name && p.node.name.toUpperCase().search(string) !== -1),
            )(R.values(milestones));
        }
        return R.values(milestones);
    };

    const setDashbaordToast = useToast('dashboard');
    const save = (startText, endText) => {
        setDashbaordToast('spinner', startText || 'Saving milestone data...');
        const toastConfig = {
            textOnStart: startText || 'Saving milestone data...',
            textOnSuccess: endText || 'Succesfully saved...',
        };
        const push = createOfflinePush(stack, setDashbaordToast, toastConfig);
        return push();
    };

    return (
        <>
            <div className="columns">
                <div>
                    <Headline
                        user={user}
                        projectId={projectId}
                        add={add}
                        setRights={setRights}
                        inheritRights={inheritRights}
                        setMilestoneId={setMilestoneId}
                        openEditProjectMilestoneModal={openEditProjectMilestoneModal}
                        search={search}
                        setSearch={setSearch}
                        milestoneSearch={milestoneSearch}
                        setMilestoneSearch={setMilestoneSearch}
                    />
                    <MilestonesList
                        setMilestoneId={setMilestoneId}
                        openEditProjectMilestoneModal={openEditProjectMilestoneModal}
                        openMilestoneTopsModal={openMilestoneTopsModal}
                        top={top}
                        assoc={assoc}
                        save={save}
                        close={close}
                        dissoc={dissoc}
                        projectId={projectId}
                        milestoneSearch={milestoneSearch}
                        milestonesSearch={milestonesSearch}
                    />
                </div>
            </div>
            <EditProjectMilestoneModal save={save} stack={stack} update={update} />
            <Toast name={PROJECT_MILESTONES_MODAL} />
        </>
    );
};

const Headline = ({
    user,
    projectId,
    add,
    setRights,
    inheritRights,
    setMilestoneId,
    openEditProjectMilestoneModal,
    search,
    setSearch,
    milestoneSearch,
    setMilestoneSearch,
}) => {
    const inputRef = useRef();
    useEffect(() => {
        search && setTimeout(() => inputRef.current.focus(), 600);
    }, [search]);
    return (
        <div className="modal-headline">
            <div style={{ flex: 1 }}>Project milestones</div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                <Button
                    onClick={() => {
                        const newMilestoneId = newId();
                        const newMilestone = {
                            id: newMilestoneId,
                            createdDate: newIsoDate(),
                        };
                        add(PROJECT_MILESTONE_EDGE)(projectId)(newMilestone, {
                            order: newMsDate(),
                        });
                        setRights(newMilestoneId)(user, {
                            read: true,
                            write: true,
                            admin: true,
                        });
                        inheritRights(newMilestoneId)(projectId);
                        setMilestoneId(newMilestoneId);
                        openEditProjectMilestoneModal();
                    }}
                >
                    <IoAddOutline size={23} />
                    <p style={{ marginLeft: 5 }}>Create project milestone</p>
                </Button>
                <Button style={{ marginLeft: 6 }} onClick={() => setSearch(!search)}>
                    {search ? <IoChevronForwardOutline size={19} /> : <IoSearchOutline size={21} />}
                </Button>
                {search && (
                    <div className="animated-searchbar" style={{ flex: 1 }}>
                        <input
                            ref={inputRef}
                            placeholder="search project milestones..."
                            value={milestoneSearch}
                            onChange={e => setMilestoneSearch(e.target.value)}
                        />
                        <Button style={{ marginLeft: 3 }} onClick={() => setMilestoneSearch('')}>
                            <IoTrashOutline size={17} />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

const MilestonesList = ({
    setMilestoneId,
    openEditProjectMilestoneModal,
    openMilestoneTopsModal,
    top,
    assoc,
    save,
    close,
    dissoc,
    projectId,
    milestoneSearch,
    milestonesSearch,
}) => (
    <ListField className style={{ flex: 1, flexBasis: 150 }} label="Project milestones">
        {R.map(milestone => (
            <div
                key={milestone.nodeId}
                onClick={() => setMilestoneId(milestone.nodeId)}
                className="row hover-effect"
            >
                <div
                    style={{
                        fontSize: 'inherit',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flex: 2,
                        alignItems: 'center',
                    }}
                >
                    <IoTrailSignOutline
                        size={20}
                        color="var(--corporate-color-2-light)"
                        style={{ marginRight: 12 }}
                    />
                    <div
                        style={{
                            fontSize: 'inherit',
                            flex: 1,
                        }}
                    >
                        {R.pathOr('', ['node', 'name'], milestone)}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            fontSize: 15,
                            color: 'darkgray',
                            flex: 1,
                            justifyContent: 'center',
                        }}
                    >
                        {formatShortDate(R.pathOr('', ['node', 'dueDate'], milestone))}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                    <Button
                        onClick={() => openMilestoneTopsModal()}
                        style={{ marginRight: 12, color: 'var(--corporate-color-2)' }}
                    >
                        <IoLinkOutline size={20} />
                        <p>Linked items</p>
                    </Button>
                    <Button
                        onClick={() => openEditProjectMilestoneModal()}
                        style={{ marginRight: 12, color: 'var(--corporate-color-2)' }}
                    >
                        <IoCreateOutline size={20} />
                        <p>Edit</p>
                    </Button>
                    <Button
                        onClick={() => {
                            dissoc(PROJECT_MILESTONE_EDGE)(projectId)(milestone.node);
                            save('Deleting milestone...', 'Succesfully deleted.');
                            setMilestoneId();
                        }}
                        style={{ marginRight: 12, color: 'var(--corporate-color-2)' }}
                    >
                        <IoTrashOutline size={18} />
                        <p>Delete</p>
                    </Button>
                    {top.id && (
                        <>
                            <div
                                style={{
                                    borderLeft: '1px solid var(--corporate-color-2-light)',
                                    margin: '0 12 0 0',
                                }}
                            />
                            <Button
                                className="background-color-var-cc13-light background-color-var-cc13-hover"
                                onClick={() => {
                                    assoc(MILESTONE_TOP_EDGE)(milestone.nodeId)(top, {
                                        order: newMsDate(),
                                    });
                                    save(
                                        'Connecting item with milestone...',
                                        'Succesfully connected.',
                                    );
                                    close();
                                }}
                                style={{ color: 'var(--corporate-color-2)' }}
                            >
                                <IoLinkOutline size={20} />
                                <p>Connect</p>
                            </Button>
                        </>
                    )}
                </div>
            </div>
        ))(milestonesSearch(milestoneSearch))}
    </ListField>
);
