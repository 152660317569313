import 'chart.js/auto';
import React, { useRef } from 'react';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import '../../Components/Elements/ChartsArea.css';
import * as R from 'ramda';
import { DASHBOARD_FILTER_KEY, useGraph } from '../../common/hooks.config.new';
import { PROJECT_ACTION_STATUS_TYPE_CHOICES, useProjectChoices } from '../../hooks/useChoices';
import { colors } from '../../common/consts';

const actionsChartOptions = {
    animation: false,
    indexAxis: 'x',
    responsive: true,
    maxBarThickness: 50,
    scales: {
        x: {
            grid: {
                display: false,
            },
            ticks: {
                stepSize: 1,
                font: {
                    size: 40,
                },
            },
        },
        y: {
            ticks: {
                stepSize: 1,
                font: {
                    size: 40,
                },
            },
        },
    },
    plugins: {
        indexAxis: {
            stepSize: 1,
        },
        legend: {
            display: false,
        },
    },
};

export const ActionsChartMobile = ({
    stack,
    actions,
    fnSelectNode = R.prop('node'),
    allActionStatusTypes,
    setGlobalDashboardTops,
}) => {
    const { useVar } = useGraph(...stack);
    const { choices: actionsStatusTypeChoices } = useProjectChoices(
        stack,
        PROJECT_ACTION_STATUS_TYPE_CHOICES,
    );
    const statusTypesToUse = allActionStatusTypes || actionsStatusTypeChoices;
    const actionStatus = stat =>
        actions.length > 0 ? actions.filter(a => fnSelectNode(a).status === stat) : [];
    const chartedTops = R.append(
        { type: 'Total', tops: actions },
        statusTypesToUse.map(ast => {
            const types = { type: ast.label, tops: actionStatus(ast.label) };
            return types;
        }),
    );
    const [filter, setFilter] = useVar(DASHBOARD_FILTER_KEY);
    const data = {
        labels: chartedTops.map(ct => ct.type),
        datasets: [
            {
                label: 'Number of actions',
                data: chartedTops.map(ct => ct.tops.length),
                backgroundColor: colors,
                borderRadius: 50,
            },
        ],
    };

    const chartRef = useRef(null);

    return (
        <div className="fader" style={{ width: '100%' }}>
            <Bar
                ref={chartRef}
                data={data}
                options={actionsChartOptions}
                onClick={event => {
                    const element = getElementAtEvent(chartRef.current, event);
                    if (element.length > 0) {
                        const { index } = element[0];
                        const filterEntry = {
                            label: chartedTops[index].type,
                            value: chartedTops[index].type,
                        };
                        setGlobalDashboardTops && setGlobalDashboardTops(chartedTops[index].tops);
                        !setGlobalDashboardTops &&
                            chartedTops[index].type !== 'Total' &&
                            setFilter(
                                R.compose(
                                    R.assoc('showTops', true),
                                    R.assocPath(
                                        ['topType', 'values'],
                                        [{ value: 'a', label: 'a' }],
                                    ),
                                    R.assocPath(['actionStatus', 'values'], [filterEntry]),
                                )(filter),
                            );
                        !setGlobalDashboardTops &&
                            chartedTops[index].type === 'Total' &&
                            setFilter(
                                R.compose(
                                    R.assoc('showTops', true),
                                    R.assocPath(
                                        ['topType', 'values'],
                                        [{ value: 'a', label: 'a' }],
                                    ),
                                )(filter),
                            );
                    }
                }}
            />
        </div>
    );
};
