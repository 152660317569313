import React from 'react';
import { FileField } from '../../Common/Fields';
import { TOP_ATTACHMENT_EDGE } from '../../../common/hooks.config.new';
import { useAttachmentFns } from '../../../hooks/useAttachments';
import { useToast } from '../../../hooks/useToast';

export const TopFileField = ({ stack, meetingId, topId, inheritFromId, shouldPush = false }) => {
    const setToast = useToast('app');
    const { addAttachments, save } = useAttachmentFns(
        stack,
        topId,
        TOP_ATTACHMENT_EDGE,
        inheritFromId,
    );
    return (
        <FileField
            dragMessage="Drop files here to attach them to the TOP"
            style={{ width: '100%', height: '100%' }}
            className="invisible-background"
            onChange={async files => {
                addAttachments(files, meetingId, shouldPush);
                if (shouldPush) {
                    setToast('spinner', 'Adding attachments', 5000);
                    await save();
                    setToast('success', 'Attachments added', 5000);
                }
            }}
        />
    );
};
