import React, { useEffect } from 'react';
import * as R from 'ramda';
import { useModalState } from '../../hooks/useModal';
import { ImageField, TextField } from '../../Components/Common/Fields';
import { FancyCheckbox } from '../../Components/Common/Controls';
import { ModalMobile } from '../Common/ModalMobile';
import {
    CONTACT_DIRECTORY_CONTACT_EDGE,
    EDIT_PROJECT_MODAL_MOBILE,
    ORGANIZATION_ID_KEY,
    ORGANIZATION_PROJECT_EDGE,
    PROJECT_CONTACT_DIRECTORY_EDGE,
    PROJECT_ID_KEY,
    SUBPROJECT_ID_KEY,
    useGraph,
} from '../../common/hooks.config.new';
import { constructNodeView } from '../../common/got-adjunct';
import { DropdownField } from '../../Components/Common/Dropdown';
import { Id, newId, newIsoDate, newMsDate } from '../../common/util';

export const EditProjectModalMobile = ({ stack: parentStack }) => {
    const [isOpen] = useModalState(EDIT_PROJECT_MODAL_MOBILE);
    const stack = R.append(EDIT_PROJECT_MODAL_MOBILE, parentStack);
    const { clear } = useGraph(...stack);
    return (
        <ModalMobile name={EDIT_PROJECT_MODAL_MOBILE} stack={stack} onBack={clear} onSave>
            {isOpen ? <ModalContent stack={stack} parentStack={parentStack} /> : null}
        </ModalMobile>
    );
};

const ModalContent = ({ stack, parentStack }) => {
    const { update, setFile, useView, pull, push, add, setRights } = useGraph(...stack);
    const { useVar } = useGraph(...parentStack);

    const [{ projectId: listProjectId, isNew, user }] = useModalState(EDIT_PROJECT_MODAL_MOBILE);
    const [organizationId] = useVar(ORGANIZATION_ID_KEY);
    const [projectId, setProjectId] = useVar(PROJECT_ID_KEY);
    const [, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);

    const projectIdToUse = isNew ? projectId : listProjectId;

    const projectView = constructNodeView(projectIdToUse, {
        node: true,
        rights: true,
        files: true,
    });
    useEffect(() => {
        projectIdToUse && pull(projectView);
    }, [projectIdToUse]);
    const { [projectIdToUse]: projectBag = {} } = useView(projectView);
    const canAdminProject = R.pathOr(false, ['rights', 'user', user, 'admin'], projectBag);
    const customerLogo = R.path(['files', 'customerLogo', 'url'], projectBag);
    const project = R.propOr({}, 'node', projectBag);

    useEffect(() => {
        if (isNew) {
            const newProjectId = newId();
            const defaultActionStatusTypes = 'Open\nLate\nDone';
            const newProject = {
                id: newProjectId,
                createdDate: newIsoDate(),
            };
            const newContactDirectoryId = Id.contactDirectory(newProjectId);
            const newContactDirectory = {
                id: newContactDirectoryId,
            };
            add(ORGANIZATION_PROJECT_EDGE)(organizationId)(newProject, { order: newMsDate() });
            add(PROJECT_CONTACT_DIRECTORY_EDGE)(newProjectId)(newContactDirectory);
            update(
                R.assoc('actionStatusTypes', R.split('\n', defaultActionStatusTypes), newProject),
            );
            setRights(newProjectId)(user, {
                read: true,
                write: true,
                admin: true,
            });
            setProjectId(newProjectId);
            setSubprojectId(undefined);
            const newContactId = newId();
            const newContact = {
                id: newContactId,
                name: `User - ${user}`,
                email: user,
            };
            add(CONTACT_DIRECTORY_CONTACT_EDGE)(newContactDirectoryId)(newContact);
        }
    }, [isNew]);

    return (
        <>
            <div className="modal-headline-mobile">
                Edit Project
                <div
                    className="modal-headline-filler"
                    style={{ backgroundColor: 'var(--corporate-color-14-ligh)' }}
                />
            </div>
            <TextField
                label="Project name"
                value={project.title || ''}
                onChange={value => update(R.assoc('title', value, project))}
                className="input-mobile"
                labelClass="label-mobile"
            />
            <TextField
                label="Project ID"
                value={project.businessId || ''}
                onChange={value => update(R.assoc('businessId', value, project))}
                className="input-mobile"
                labelClass="label-mobile"
            />
            <TextField
                label="Customer name"
                value={project.customerName || ''}
                onChange={value => update(R.assoc('customerName', value, project))}
                className="input-mobile"
                labelClass="label-mobile"
            />
            <DropdownField
                mobile
                options={[
                    { value: 'EUR', label: 'Euro' },
                    { value: 'USD', label: 'US-Dollar' },
                    { value: 'CHF', label: 'Swiss Franc' },
                ]}
                onChange={value => {
                    update(R.assoc('currency', value.value, project));
                }}
                value={project.currency || ''}
                label="Currency"
                labelClass="dd-label-mobile"
            />
            <ImageField
                label="Customer logo"
                value={customerLogo || ''}
                onChange={file => {
                    if (!file) {
                        return;
                    }
                    setFile(projectIdToUse)('customerLogo', file.name, file);
                    push();
                }}
                className="mobile-img-field"
                labelStyle={{ fontSize: '2rem', color: 'var(--color-2)' }}
                labelClass="label-mobile margin-bottom"
            />
            <FancyCheckbox
                label="Show time stamp in PDF"
                placeholderStyle={{ fontSize: '3.5rem' }}
                labelStyle={{ marginLeft: 20 }}
                mobile
                containerStyle={{ justifyContent: 'center', paddingLeft: '2rem' }}
                onChange={() =>
                    canAdminProject && update(R.assoc('showAd', !project.showAd, project))
                }
                value={project.showAd || false}
            />
            <div style={{ height: '20vh' }} />
        </>
    );
};
