import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { IoLogInOutline } from 'react-icons/io5';
import { useParams, useHistory } from 'react-router';
import { api } from '../../common/hooks.config.new';
import { Button } from '../../Components/Common/Controls';
import { TextField } from '../../Components/Common/Fields';
import { Toast } from '../../Components/Elements/Toast';
import { useToast } from '../../hooks/useToast';
import logo from '../../logos/pclogo.png';
import { ClickAnimator } from '../Elements/ClickAnimator';

export const AuthScreenMobile = ({ onLoggedIn, defaultState }) => {
    const params = useParams();
    const [, setTask] = useState({
        name: defaultState || 'login',
        email: params.emailAdress || '',
        password: '',
        oldPassword: params.password || '',
    });

    // const login = (email, password) => api.login({ email, password })
    //     .then(() => {
    //         history.push('/dashboard');
    //         onLoggedIn(true);
    //     })
    //     .catch(err => err.name === 'UserNotVerifiedError'
    // && setTask({ name: 'activate', email }));

    return (
        <div
            style={{ backgroundColor: 'var(--corporate-color-1)', height: '100%', width: '100vw' }}
        >
            <div className="modal-container-mobile" style={{ backgroundColor: 'transparent' }}>
                <div
                    className="modal-content-container-mobile"
                    style={{ backgroundColor: 'transparent' }}
                >
                    <img
                        alt="logo"
                        src={logo}
                        style={{
                            width: '30rem',
                            position: 'absolute',
                            top: '0.5rem',
                            left: '0.5rem',
                        }}
                    />

                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            width: '100vw',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Login setTask={setTask} onLoggedIn={onLoggedIn} />
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '0.5rem',
                            right: '0.75rem',
                            color: 'var(--color-2)',
                            fontSize: '2.25rem',
                            fontWeight: 300,
                        }}
                    >
                        v1.0.24
                    </div>
                </div>
            </div>
            <Toast name="auth-toast" mobile />
        </div>
    );
};

const Login = ({ onLoggedIn, setTask }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();
    // const forgotPassword = () => api.resetPasswordInit({ email });
    const login = () =>
        api
            .login({ email, password })
            .then(() => {
                history.push('/dashboard');
                onLoggedIn(true);
            })
            .catch(err => {
                err.name === 'LoginVerifyError' &&
                    setToast('error', 'Username or password is incorrect...', 7500);
                err.name === 'UserNotFoundError' &&
                    setToast('error', 'User does not exist...', 7500);
                err.name === 'InvalidEmailError' && setToast('error', 'Mail is invalid...', 7500);
                err.code === 'UserNotConfirmedException' && setTask({ name: 'activate', email });
            });
    const setToast = useToast('auth-toast');

    const inputCheck = () => {
        if (!email) {
            setToast('error', 'Mail address is missing....', 7500);
            return false;
        }
        if (!password) {
            setToast('error', 'Password is missing....', 7500);
            return false;
        }
        return true;
    };
    useHotkeys(
        'enter',
        () => {
            inputCheck() && login() && setToast('spinner', 'Logging in...', 7500);
        },
        { enableOnTags: ['INPUT'] },
    );
    return (
        <div className="login-container-mobile">
            <div
                className="modal-headline-mobile"
                style={{
                    justifyContent: 'center',
                    marginLeft: 0,
                    marginBottom: '4rem',
                    fontSize: '7rem',
                    fontWeight: 100,
                    color: 'white',
                }}
            >
                Sign in
            </div>
            <TextField
                realtime
                label="E-Mail"
                value={email}
                onChange={value => setEmail(value)}
                className="input-mobile"
                labelClass="label-mobile"
                style={{ borderRadius: '5rem' }}
            />
            <TextField
                realtime
                label="Password"
                type="password"
                value={password}
                onChange={value => setPassword(value)}
                className="input-mobile"
                labelClass="label-mobile"
                style={{ borderRadius: '5rem' }}
            />
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    marginBottom: '3rem',
                }}
            >
                <ClickAnimator
                    content={
                        <Button
                            onClick={() => login(email, password)}
                            className="login-button-mobile"
                            style={{
                                fontSize: '3.5rem',
                                backgroundColor: 'var(--corporate-color-7)',
                                color: 'var(--corporate-color-1)',
                            }}
                        >
                            <IoLogInOutline size="5rem" style={{ marginRight: '1rem' }} />
                            Sign in
                        </Button>
                    }
                    positioning={{ position: 'relative' }}
                />
            </div>
            {/* <button
                onClick={() => email
          && forgotPassword(email)
          && setTask({ name: 'forgotPassword', email })}
            >
                Forgot password
            </button> */}
            {/* <button
                onClick={() => setTask({ name: 'register', email, password })}
                style={{ width: '70%', marginLeft: '0' }}
                className="button-quiet"
            >
                Sign up
            </button> */}
        </div>
    );
};

// const Register = ({ setTask, register }) => {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [passwordConfirm, setPasswordConfirm] = useState('');
//     return (
//         <>
//             <h1 style={{
//                 fontFamily: 'sans-serif', marginBottom: '36px', fontSize: '38px', color: 'var(--corporate-color-2)', fontWeight: 100,
//             }}
//             >
//                 Sign up
//             </h1>
//             <input
//                 placeholder="E-mail"
//                 name="username"
//                 value={email}
//                 onChange={e => setEmail(e.target.value)}
//                 style={{
//                     width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '3px', borderRadius: '20px',
//                 }}
//             />
//             <input
//                 type="password"
//                 name="password"
//                 placeholder="Password"
//                 value={password}
//                 onChange={e => setPassword(e.target.value)}
//                 style={{
//                     width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '3px', borderRadius: '20px',
//                 }}
//             />
//             <input
//                 type="password"
//                 name="password-confirm"
//                 placeholder="Conform password"
//                 value={passwordConfirm}
//                 onChange={e => setPasswordConfirm(e.target.value)}
//                 style={{
//                     width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '24px', borderRadius: '20px',
//                 }}
//             />
//             <button
//                 onClick={() => password === passwordConfirm
//           && register(email, password)
//           && setTask({ name: 'activate', email })}
//                 style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
//                 className="button-blue"
//             >
//                 Sign up
//             </button>
//             <button
//                 onClick={() => setTask({ name: 'login', email })}
//                 style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
//                 className="button-quiet"
//             >
//                 Back to sign in
//             </button>
//         </>
//     );
// };

// const ForgotPassword = ({ setTask, confirmPassword }) => {
//     const [validationCode, setValidationCode] = useState('');
//     const [password, setPassword] = useState('');
//     const [passwordConfirm, setPasswordConfirm] = useState('');
//     useHotkeys('enter', () => { validationCode && password === passwordConfirm && confirmPassword(validationCode, password); }, { enableOnTags: ['INPUT'] });
//     return (
//         <>
//             <h1
//                 style={{
//                     fontFamily: 'sans-serif', marginBottom: '36px', fontSize: '38px', color: 'var(--corporate-color-2)',
//                 }}
//                 className="font-weight-100"
//             >
//                 Forgot password
//             </h1>
//             <input
//                 placeholder="Validation code"
//                 name="validation-code"
//                 value={validationCode}
//                 onChange={e => setValidationCode(e.target.value)}
//                 style={{
//                     width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '3px', borderRadius: '20px',
//                 }}
//             />
//             <input
//                 type="password"
//                 name="password"
//                 placeholder="New password"
//                 value={password}
//                 onChange={e => setPassword(e.target.value)}
//                 style={{
//                     width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '3px', borderRadius: '20px',
//                 }}
//             />
//             <input
//                 type="password"
//                 name="password-confirm"
//                 placeholder="Confirm new password"
//                 value={passwordConfirm}
//                 onChange={e => setPasswordConfirm(e.target.value)}
//                 style={{
//                     width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '24px', borderRadius: '20px',
//                 }}
//             />
//             <button
//                 onClick={() => password === passwordConfirm
//           && confirmPassword(validationCode, password)
//           && setTask({ name: 'login' })}
//                 style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
//                 className="button-blue"
//             >
//                 Set new password
//             </button>
//             <button
//                 onClick={() => setTask({ name: 'login' })}
//                 style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
//                 className="button-quiet"
//             >
//                 Back to sign in
//             </button>
//         </>
//     );
// };

// const ChangePassword = ({
//     setTask, changePassword,
// }) => {
//     const [password, setPassword] = useState('');
//     const [passwordConfirm, setPasswordConfirm] = useState('');
//     useHotkeys('enter', async () => {
//         if (password === passwordConfirm) {
//             try {
//                 await changePassword(password);
//             } catch (err) {
//                 console.log(err);
//             }
//         }
//     }, { enableOnTags: ['INPUT'] });

//     return (
//         <>
//             <h1
//                 style={{
//                     fontFamily: 'sans-serif', marginBottom: '36px', fontSize: '38px', color: 'var(--corporate-color-2)',
//                 }}
//                 className="font-weight-100"
//             >
//                 Set password
//             </h1>
//             <input
//                 type="password"
//                 name="password"
//                 placeholder="New password"
//                 value={password}
//                 onChange={e => setPassword(e.target.value)}
//                 style={{
//                     width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '3px', borderRadius: '20px',
//                 }}
//             />
//             <input
//                 type="password"
//                 name="password-confirm"
//                 placeholder="Confirm new password"
//                 value={passwordConfirm}
//                 onChange={e => setPasswordConfirm(e.target.value)}
//                 style={{
//                     width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '24px', borderRadius: '20px',
//                 }}
//             />
//             <button
//                 onClick={async () => {
//                     if (password === passwordConfirm) {
//                         try {
//                             await changePassword(password);
//                         } catch (err) {
//                             console.log(err);
//                         }
//                     }
//                 }}
//                 style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
//                 className="button-blue"
//             >
//                 Set password
//             </button>
//             <button
//                 onClick={() => setTask({ name: 'login' })}
//                 style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
//                 className="button-quiet"
//             >
//                 Back to sign in
//             </button>
//         </>
//     );
// };

// const Activate = ({ setTask, activate, resendCode }) => {
//     const [activationCode, setActivationCode] = useState('');
//     useHotkeys('enter', () => { activationCode && activate(activationCode); }, { enableOnTags: ['INPUT'] });
//     return (
//         <>
//             <h1
//                 style={{
//                     fontFamily: 'sans-serif', marginBottom: '36px', fontSize: '38px', color: 'var(--corporate-color-2)',
//                 }}
//                 className="font-weight-100"
//             >
//                 Activation
//             </h1>
//             <input
//                 placeholder="Activation code"
//                 name="activation-code"
//                 value={activationCode}
//                 onChange={e => setActivationCode(e.target.value)}
//                 style={{
//                     width: '70%', marginLeft: '0', paddingLeft: '12px', height: '35px ', marginBottom: '24px', borderRadius: '20px',
//                 }}
//             />
//             <button
//                 onClick={() => activationCode
//           && activate(activationCode)
//           && setTask({ name: 'login' })}
//                 style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
//                 className="button-blue"
//             >
//                 Activate
//             </button>
//             <button
//                 onClick={() => resendCode()}
//                 style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
//                 className="button-quiet"
//             >
//                 Send code again
//             </button>
//             <button
//                 onClick={() => setTask({ name: 'login' })}
//                 style={{ width: '70%', marginLeft: '0', borderRadius: '20px' }}
//                 className="button-quiet"
//             >
//                 Back to sign in
//             </button>
//         </>
//     );
// };
