import * as R from 'ramda';
import React, { useState } from 'react';
import { IoEllipse } from 'react-icons/io5';
import { useGraph } from '../../common/hooks.config.new';
import { useNode } from '../../hooks/useNode';
import { useTopResponsible } from '../../hooks/useTopResponsible';
import { usePreviousVersions } from '../../hooks/useTopHistories';
import { formatShortDate } from '../../common/util';
import { useProjectCurrency } from '../../hooks/useChoices';
import { Button } from '../../Components/Common/Controls';

export const _DashboardTopMobile = ({ stack, topId, oppTracking = true, global }) => {
    const { node: top } = useNode(stack, topId);

    const { update: updateTop } = useGraph(...stack);
    const [responsible] = useTopResponsible(stack, topId);

    const previousVersions = usePreviousVersions(stack, R.prop('businessId', top));

    const lastVersionAuthor = R.compose(R.pathOr('', ['author', 'name']), R.last)(previousVersions);

    const lastVersionDate = R.compose(
        formatShortDate,
        R.defaultTo(R.prop('modifiedDate', top) || R.prop('createdDate', top) || ''),
        R.prop('editedDate'),
        R.last,
    )(previousVersions);

    const updateTopType = type => updateTop(R.assoc('type', type, top));
    if (top) {
        return (
            <div className="top kanban-top" key={top.id}>
                <div className="latest">
                    <TopSwitch
                        top={top}
                        global={global}
                        stack={stack}
                        updateTopType={updateTopType}
                        responsible={responsible}
                        oppTracking={oppTracking}
                        lastVersionAuthor={lastVersionAuthor}
                        lastVersionDate={lastVersionDate}
                        previousVersions={previousVersions}
                    />
                </div>
            </div>
        );
    }
    return null;
};

const TopSwitch = ({
    top,
    global,
    stack,
    responsible,
    oppTracking,
    lastVersionAuthor,
    lastVersionDate,
    previousVersions,
}) => {
    switch (top.type) {
        case 'a':
            return (
                <TopA
                    top={top}
                    global={global}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                />
            );
        case 'o':
            if (oppTracking) {
                return (
                    <TopO
                        top={top}
                        global={global}
                        stack={stack}
                        responsible={responsible}
                        lastVersionAuthor={lastVersionAuthor}
                        lastVersionDate={lastVersionDate}
                        previousVersions={previousVersions}
                    />
                );
            }
            return null;
        case 'r':
            return (
                <TopR
                    top={top}
                    global={global}
                    stack={stack}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                />
            );
        case 's':
            return (
                <TopS
                    top={top}
                    global={global}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                />
            );
        case 'i':
            return (
                <TopI
                    top={top}
                    global={global}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                />
            );
        case 'd':
            return (
                <TopD
                    top={top}
                    global={global}
                    responsible={responsible}
                    lastVersionAuthor={lastVersionAuthor}
                    lastVersionDate={lastVersionDate}
                    previousVersions={previousVersions}
                />
            );
        default:
            return <NoType />;
    }
};

const NoType = () => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
        }}
    >
        <div
            style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-start',
                paddingLeft: '0.5rem',
            }}
        >
            <div
                className="top-type-badge-protocol-mobile"
                style={{
                    backgroundColor: 'white',
                    color: 'var(--corporate-color-2)',
                    border: '2px solid var(--corporate-color-2-light)',
                    fontWeight: 'normal',
                }}
            >
                ?
            </div>
        </div>
    </div>
);

const TopA = ({
    top,
    global,
    responsible,
    previousVersions,
    lastVersionAuthor,
    lastVersionDate,
}) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
        }}
    >
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <Button
                className="top-type-badge-mobile"
                style={{
                    backgroundColor: 'var(--corporate-color-14)',
                    cursor: 'default',
                    boxShadow: 'none',
                }}
                disabled
            >
                A
            </Button>
        </div>
        <div
            style={{
                display: 'flex',
                alignItems: 'flex-start',
                flex: 9,
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '1rem',
                }}
            >
                {global && (
                    <div
                        className="top-business-id"
                        style={{
                            color: 'var(--corporate-color-1)',
                            display: 'flex',
                            flex: 1,
                            height: '4rem',
                            marginBottom: 0,
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: 'var(--font-size-mobile-medium)',
                            marginRight: '10%',
                        }}
                    >
                        {top.businessId || R.pathOr('', ['top', 'top', 'businessId'], top)}
                    </div>
                )}
                <div className="edited-indicator-mobile">
                    {previousVersions.length > 1 ? 'edited on' : 'created on'} {lastVersionDate}{' '}
                    {lastVersionAuthor && `by ${lastVersionAuthor}`}
                </div>
            </div>
            <TopDescription top={top} />
            <div style={{ display: 'flex', margin: '1rem', width: '100%' }}>
                {R.prop('status', top) && (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <p className="top-info-mobile" style={{ whiteSpace: 'nowrap' }}>
                            {R.propOr('', 'status', top)}
                        </p>
                        <p className="top-info-label-mobile">Status</p>
                    </div>
                )}
                {R.prop('name', responsible) && (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <p className="top-info-mobile" style={{ whiteSpace: 'nowrap' }}>
                            {R.propOr('', 'name', responsible)}
                        </p>
                        <p className="top-info-label-mobile">Responsible</p>
                    </div>
                )}
            </div>
            <div style={{ display: 'flex', margin: '1rem', width: '100%' }}>
                {R.prop('phase', top) && (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <p className="top-info-mobile">{R.propOr('', 'phase', top)}</p>
                        <p className="top-info-label-mobile">Phase</p>
                    </div>
                )}
                {R.prop('dueDate', top) && (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <p
                            className="top-info-mobile"
                            style={{
                                color:
                                    R.propOr('', 'status', top) === 'Late'
                                        ? 'var(--corporate-color-12)'
                                        : undefined,
                            }}
                        >
                            {formatShortDate(R.propOr('', 'dueDate', top))}
                        </p>
                        <p className="top-info-label-mobile">Due Date</p>
                    </div>
                )}
            </div>
        </div>
    </div>
);

const TopO = ({
    top,
    stack,
    global,
    responsible,
    previousVersions,
    lastVersionAuthor,
    lastVersionDate,
}) => {
    const currency = useProjectCurrency(stack);
    const formato = val =>
        new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency,
        }).format(val);
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                overflow: 'hidden',
            }}
        >
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                <Button
                    className="top-type-badge-mobile"
                    style={{
                        backgroundColor: 'var(--corporate-color-13)',
                        cursor: 'default',
                        boxShadow: 'none',
                    }}
                    disabled
                >
                    O
                </Button>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flex: 9,
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '1rem',
                    }}
                >
                    {global && (
                        <div
                            className="top-business-id"
                            style={{
                                color: 'var(--corporate-color-1)',
                                display: 'flex',
                                flex: 1,
                                height: '4rem',
                                marginBottom: 0,
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: 'var(--font-size-mobile-medium)',
                                marginRight: '10%',
                            }}
                        >
                            {top.businessId || R.pathOr('', ['top', 'top', 'businessId'], top)}
                        </div>
                    )}
                    <div className="edited-indicator-mobile">
                        {previousVersions.length > 1 ? 'edited on' : 'created on'} {lastVersionDate}{' '}
                        {lastVersionAuthor && `by ${lastVersionAuthor}`}
                    </div>
                </div>
                <TopDescription top={top} />
                <div style={{ display: 'flex', margin: '1rem', width: '100%' }}>
                    {R.prop('status', top) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile" style={{ whiteSpace: 'nowrap' }}>
                                {R.propOr('', 'status', top)}
                            </p>
                            <p className="top-info-label-mobile">Status</p>
                        </div>
                    )}
                    {R.prop('name', responsible) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile" style={{ whiteSpace: 'nowrap' }}>
                                {R.propOr('', 'name', responsible)}
                            </p>
                            <p className="top-info-label-mobile">Responsible</p>
                        </div>
                    )}
                </div>
                <div style={{ display: 'flex', margin: '1rem', width: '100%' }}>
                    {R.prop('category', top) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile">{R.propOr('', 'category', top)}</p>
                            <p className="top-info-label-mobile">Category</p>
                        </div>
                    )}
                    {R.prop('savings', top) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile">
                                {formato(R.propOr(0, 'savings', top))}
                            </p>
                            <p className="top-info-label-mobile">Savings</p>
                        </div>
                    )}
                </div>
                <div style={{ display: 'flex', margin: '1rem', width: '100%' }}>
                    {R.prop('phase', top) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile">{R.propOr('', 'phase', top)}</p>
                            <p className="top-info-label-mobile">Phase</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const TopR = ({
    top,
    stack,
    global,
    responsible,
    previousVersions,
    lastVersionAuthor,
    lastVersionDate,
}) => {
    const currency = useProjectCurrency(stack);
    const formato = val =>
        new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency,
        }).format(val);
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                overflow: 'hidden',
            }}
        >
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                <Button
                    className="top-type-badge-mobile"
                    style={{
                        backgroundColor: 'var(--corporate-color-12)',
                        cursor: 'default',
                        boxShadow: 'none',
                    }}
                    disabled
                >
                    R
                </Button>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flex: 9,
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '1rem',
                    }}
                >
                    {global && (
                        <div
                            className="top-business-id"
                            style={{
                                color: 'var(--corporate-color-1)',
                                display: 'flex',
                                flex: 1,
                                height: '4rem',
                                marginBottom: 0,
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: 'var(--font-size-mobile-medium)',
                            }}
                        >
                            {top.businessId || R.pathOr('', ['top', 'top', 'businessId'], top)}
                        </div>
                    )}
                    <div className="edited-indicator-mobile">
                        {previousVersions.length > 1 ? 'edited on' : 'created on'} {lastVersionDate}{' '}
                        {lastVersionAuthor && `by ${lastVersionAuthor}`}
                    </div>
                </div>
                <TopDescription top={top} />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        margin: '1rem',
                        width: '100%',
                    }}
                >
                    {R.prop('category', top) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile">{R.propOr('', 'category', top)}</p>
                            <p className="top-info-label-mobile">Category</p>
                        </div>
                    )}
                    {R.prop('name', responsible) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile" style={{ whiteSpace: 'nowrap' }}>
                                {R.propOr('', 'name', responsible)}
                            </p>
                            <p className="top-info-label-mobile">Responsible</p>
                        </div>
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        margin: '1rem',
                        width: '100%',
                    }}
                >
                    {R.prop('reference', top) && (
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile">{R.propOr('', 'reference', top)}</p>
                            <p className="top-info-label-mobile">Reference</p>
                        </div>
                    )}
                    {R.prop('costIncrease', top) && (
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile">
                                {formato(R.propOr(0, 'costIncrease', top))}
                            </p>
                            <p className="top-info-label-mobile">Cost Increase</p>
                        </div>
                    )}
                </div>
                <div style={{ display: 'flex', margin: '1rem', width: '100%' }}>
                    {R.prop('phase', top) && (
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <p className="top-info-mobile">{R.propOr('', 'phase', top)}</p>
                            <p className="top-info-label-mobile">Phase</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const TopS = ({ top, global, previousVersions, lastVersionAuthor, lastVersionDate }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
        }}
    >
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <Button
                className="top-type-badge-mobile"
                style={{
                    backgroundColor: 'grey',
                    cursor: 'default',
                    boxShadow: 'none',
                }}
                disabled
            >
                S
            </Button>
        </div>
        <div
            style={{
                display: 'flex',
                alignItems: 'flex-start',
                flex: 9,
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '1rem',
                }}
            >
                {global && (
                    <div
                        className="top-business-id"
                        style={{
                            color: 'var(--corporate-color-1)',
                            display: 'flex',
                            flex: 1,
                            height: '4rem',
                            marginBottom: 0,
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: 'var(--font-size-mobile-medium)',
                        }}
                    >
                        {top.businessId || R.pathOr('', ['top', 'top', 'businessId'], top)}
                    </div>
                )}
                <div className="edited-indicator-mobile">
                    {previousVersions.length > 1 ? 'edited on' : 'created on'} {lastVersionDate}{' '}
                    {lastVersionAuthor && `by ${lastVersionAuthor}`}
                </div>
            </div>
            <TopDescription top={top} />
            <div style={{ display: 'flex', margin: '1rem', width: '100%' }}>
                {R.prop('phase', top) && (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <p className="top-info-mobile">{R.propOr('', 'phase', top)}</p>
                        <p className="top-info-label-mobile">Phase</p>
                    </div>
                )}
            </div>
        </div>
    </div>
);

const TopI = ({ top, global, previousVersions, lastVersionAuthor, lastVersionDate }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
        }}
    >
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <Button
                className="top-type-badge-mobile"
                style={{
                    backgroundColor: '#C5C9D3',
                    cursor: 'default',
                    boxShadow: 'none',
                }}
                disabled
            >
                I
            </Button>
        </div>
        <div
            style={{
                display: 'flex',
                alignItems: 'flex-start',
                flex: 9,
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '1rem',
                }}
            >
                {global && (
                    <div
                        className="top-business-id"
                        style={{
                            color: 'var(--corporate-color-1)',
                            display: 'flex',
                            flex: 1,
                            height: '4rem',
                            marginBottom: 0,
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: 'var(--font-size-mobile-medium)',
                        }}
                    >
                        {top.businessId || R.pathOr('', ['top', 'top', 'businessId'], top)}
                    </div>
                )}
                <div className="edited-indicator-mobile">
                    {previousVersions.length > 1 ? 'edited on' : 'created on'} {lastVersionDate}{' '}
                    {lastVersionAuthor && `by ${lastVersionAuthor}`}
                </div>
            </div>
            <TopDescription top={top} />
            <div style={{ display: 'flex', margin: '1rem', width: '100%' }}>
                {R.prop('phase', top) && (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <p className="top-info-mobile">{R.propOr('', 'phase', top)}</p>
                        <p className="top-info-label-mobile">Phase</p>
                    </div>
                )}
            </div>
        </div>
    </div>
);

const TopD = ({ top, global, previousVersions, lastVersionAuthor, lastVersionDate }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
        }}
    >
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <Button
                className="top-type-badge-mobile"
                style={{
                    backgroundColor: 'var(--corporate-color-7)',
                    cursor: 'default',
                    boxShadow: 'none',
                }}
                disabled
            >
                D
            </Button>
        </div>
        <div
            style={{
                display: 'flex',
                alignItems: 'flex-start',
                flex: 9,
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '1rem',
                }}
            >
                {global && (
                    <div
                        className="top-business-id"
                        style={{
                            color: 'var(--corporate-color-1)',
                            display: 'flex',
                            flex: 1,
                            height: '4rem',
                            marginBottom: 0,
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: 'var(--font-size-mobile-medium)',
                        }}
                    >
                        {top.businessId || R.pathOr('', ['top', 'top', 'businessId'], top)}
                    </div>
                )}
                <div className="edited-indicator-mobile">
                    {previousVersions.length > 1 ? 'edited on' : 'created on'} {lastVersionDate}{' '}
                    {lastVersionAuthor && `by ${lastVersionAuthor}`}
                </div>
            </div>
            <TopDescription top={top} />
            <div style={{ display: 'flex', margin: '1rem', width: '100%' }}>
                {R.prop('phase', top) && (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <p className="top-info-mobile">{R.propOr('', 'phase', top)}</p>
                        <p className="top-info-label-mobile">Phase</p>
                    </div>
                )}
            </div>
        </div>
    </div>
);

const TopDescription = ({ top }) => {
    const [maxout, setMaxout] = useState(false);
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                width: '100%',
            }}
        >
            {R.prop('description', top) && (
                <>
                    <div
                        style={{
                            margin: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            flex: 1,
                        }}
                    >
                        <p className="top-info-mobile">
                            {maxout
                                ? R.propOr('', 'description', top)
                                : `${R.slice(0, 100, R.propOr('', 'description', top))}${
                                      R.length(R.propOr('', 'description', top)) > 100 ? '...' : ''
                                  }`}
                        </p>
                        <p className="top-info-label-mobile">Description</p>
                    </div>
                    {R.length(R.propOr('', 'description', top)) > 100 && (
                        <Button
                            onClick={() => setMaxout(!maxout)}
                            style={{ backgroundColor: 'transparent', marginTop: '1.25rem' }}
                        >
                            <IoEllipse
                                size="2rem"
                                color={
                                    !maxout
                                        ? 'var(--corporate-color-13)'
                                        : 'var(--corporate-color-7)'
                                }
                            />
                        </Button>
                    )}
                </>
            )}
        </div>
    );
};
