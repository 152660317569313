import * as R from 'ramda';

const parseIcsDateToISO = icsDate => {
    const year = R.slice(0, 4, icsDate);
    const month = R.slice(4, 6, icsDate);
    const day = R.slice(6, 8, icsDate);
    const hour = R.slice(9, 11, icsDate);
    const minute = R.slice(11, 13, icsDate);
    return `${year}-${month}-${day}T${hour}:${minute}:00.000Z`;
};

const parseAttendee = line => {
    const segments = R.split(';', line);
    const name = R.compose(
        R.head,
        R.split(':'),
        R.replace('CN=', ''),
        R.find(str => str.startsWith('CN=')),
    )(segments);

    const email = R.compose(
        contactEntries => {
            const mailToIndex = R.findIndex(str => str === 'mailto', contactEntries);
            return mailToIndex >= 0 ? R.toLower(contactEntries[mailToIndex + 1]) : undefined;
        },
        R.split(':'),
        R.find(str => str.includes('mailto')),
    )(segments);

    if (name) {
        const contact = { name };
        if (email) {
            contact.email = email;
        }
        return contact;
    }

    return undefined;
};

export const parseIcs = icsData => {
    const NEW_LINE = /\r\n|\n|\r/;
    const lines = icsData.replaceAll('\r\n\t', '').replaceAll('\r\n ', '').split(NEW_LINE);

    const data = {};
    const attendees = [];

    let isEvent = false;
    let isAlarm = false;

    for (let i = 0; i < lines.length; i += 1) {
        const line = lines[i];

        if (line.startsWith('BEGIN:VALARM')) {
            isAlarm = true;
        } else if (line.startsWith('END:VALARM')) {
            isAlarm = false;
        }

        if (isEvent && !isAlarm) {
            if (line.startsWith('END:VEVENT')) {
                break;
            }
            const segments = R.split(':', line);
            const tail = R.compose(R.join(':'), R.tail)(segments);
            const [key, val] = segments;
            if (key.startsWith('DTSTART')) {
                data.startDate = parseIcsDateToISO(val);
            } else if (key.startsWith('DTEND')) {
                data.endDate = parseIcsDateToISO(val);
            } else if (key.startsWith('DESCRIPTION')) {
                data.description = tail;
            } else if (key.startsWith('LOCATION')) {
                data.location = tail;
            } else if (key.startsWith('SUMMARY')) {
                data.summary = tail;
            } else if (line.startsWith('ORGANIZER')) {
                const attendee = parseAttendee(line);
                if (attendee) {
                    attendee.type = 'ORGANIZER';
                    attendees.push(attendee);
                }
            } else if (line.startsWith('ATTENDEE')) {
                const attendee = parseAttendee(line);
                if (attendee) {
                    attendee.type = 'ATTENDEE';
                    attendees.push(attendee);
                }
            }
        } else if (line.startsWith('BEGIN:VEVENT')) {
            isEvent = true;
        }
    }

    data.attendees = attendees;

    return data;
};
