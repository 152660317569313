import React, { useEffect } from 'react';
import * as R from 'ramda';
import { IoDocumentLockOutline } from 'react-icons/io5';
import { VscCircleOutline } from 'react-icons/vsc';
import {
    DASHBOARD_FILTER_KEY,
    PROJECT_ID_KEY,
    PROJECT_SUBPROJECT_EDGE,
    SEARCH_STRING_KEY,
    SUBMITTED_MEETINGS_MODAL_MOBILE,
    SUBPROJECT_ID_KEY,
    SUBPROJECT_MEETING_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { useModalState } from '../../hooks/useModal';
import { ModalMobile } from '../Common/ModalMobile';
import { ListField } from '../../Components/Common/Fields';
import { useNavigator } from '../../hooks/useNavigator';
import { DropdownField } from '../../Components/Common/Dropdown';

const constructSubprojectsView = projectId =>
    projectId
        ? {
              [projectId]: {
                  as: 'project',
                  edges: {
                      [PROJECT_SUBPROJECT_EDGE]: {
                          as: 'subprojects',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

const constructMeetingsView = subprojectId =>
    subprojectId
        ? {
              [subprojectId]: {
                  as: 'subproject',
                  edges: {
                      [SUBPROJECT_MEETING_EDGE]: {
                          as: 'meetings',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const SubmittedMeetingsModalMobile = ({ stack }) => {
    const [isOpen, close] = useModalState(SUBMITTED_MEETINGS_MODAL_MOBILE);

    return (
        <ModalMobile name={SUBMITTED_MEETINGS_MODAL_MOBILE}>
            {isOpen ? <ModalContent stack={stack} close={close} /> : null}
        </ModalMobile>
    );
};

const ModalContent = ({ stack: parentStack, close }) => {
    const stack = R.append(SUBMITTED_MEETINGS_MODAL_MOBILE, parentStack);
    const { useView, pull } = useGraph(...stack);
    const { useVar } = useGraph(...parentStack);

    const [subprojectId, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [, setFilter] = useVar(DASHBOARD_FILTER_KEY);
    const [, setSearchString] = useVar(SEARCH_STRING_KEY);

    const meetingsView = constructMeetingsView(subprojectId);
    useEffect(() => {
        subprojectId && pull(meetingsView);
    }, [subprojectId]);
    const { subproject } = useView(meetingsView);
    const meetingChoices = R.compose(
        R.map(meetingBag => ({
            value: R.path(['node', 'id'], meetingBag),
            label: R.path(['node', 'title'], meetingBag),
            body: R.path(['node'], meetingBag),
        })),
        R.reverse,
        R.sortBy(R.path(['node', 'date'])),
        R.filter(meetingBag => !R.pathOr(false, ['node', 'closed'], meetingBag)),
        R.values,
        R.propOr({}, 'meetings'),
    )(subproject);

    const submittedMeetings = R.filter(mc => mc.body.submitted === true)(meetingChoices);
    const { navigateToProtocol } = useNavigator(parentStack);

    const [projectId] = useVar(PROJECT_ID_KEY);

    const subprojectsView = constructSubprojectsView(projectId);
    useEffect(() => {
        projectId && !subprojectId && pull(subprojectsView);
    }, [projectId]);
    const { project: { subprojects = {} } = {} } = useView(subprojectsView);
    const subprojectChoices = R.compose(
        R.sortBy(R.prop('label')),
        R.map(subprojectBag => ({
            value: R.path(['node', 'id'], subprojectBag),
            label: R.path(['node', 'title'], subprojectBag),
            body: R.path(['node'], subprojectBag),
        })),
    )(R.values(subprojects));

    return (
        <>
            <div className="modal-headline-mobile">
                Submitted Meetings
                <div className="modal-headline-filler" />
            </div>
            {subprojectId ? (
                <ListField className style={{ flex: 1, flexBasis: 150 }}>
                    {R.map(meetingChoice => (
                        <div
                            key={meetingChoice.body.id}
                            className="row-mobile hover-effect"
                            onClick={() => {
                                close();
                                navigateToProtocol(meetingChoice.body.id);
                            }}
                        >
                            <IoDocumentLockOutline
                                size="5rem"
                                color="var(--corporate-color-2-light)"
                                style={{ marginRight: '2rem' }}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className="main-title">{meetingChoice.label}</div>
                                <div className="sub-title">{meetingChoice.body.businessId}</div>
                            </div>
                        </div>
                    ))(submittedMeetings)}
                </ListField>
            ) : (
                <>
                    <p className="dashboard-message-mobile" style={{ marginBottom: '2rem' }}>
                        Select or create a subproject
                    </p>
                    <DropdownField
                        customWidth="calc(96vw - 1rem)"
                        mobile
                        label={
                            !subprojectId ? (
                                'Subprojects'
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: 'inherit',
                                    }}
                                >
                                    <VscCircleOutline
                                        size="2rem"
                                        color="var(--corporate-color-7)"
                                        style={{ marginRight: '0.5rem' }}
                                    />
                                    <p style={{ fontSize: 'inherit' }}>Subproject</p>
                                </div>
                            )
                        }
                        options={subprojectChoices}
                        value={subprojectId || ''}
                        onChange={option => {
                            setSubprojectId(option.value);
                            setSearchString('');
                            setFilter({});
                        }}
                        labelClass="dd-label-mobile"
                    />
                </>
            )}
            <div style={{ height: '20vh' }} />
        </>
    );
};
