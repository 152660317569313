import React, { useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { IoAddOutline } from 'react-icons/io5';
import {
    useGraph,
    CREATE_DASHBOARD_TOP_MODAL,
    FILTER_BAR,
    TOPS_TO_UPDATE_KEY,
    DASHBOARD_FILTER_KEY,
    SEARCH_STRING_KEY,
    SORTED_GROUPED_TOPS_KEY,
    MEETING_TOP_EDGE,
    TOP_RESPONSIBLE_EDGE,
    PROTOCOL,
    store,
    PROJECT_ID_KEY,
    TOP_ATTACHMENT_EDGE,
} from '../../common/hooks.config.new';
import { useModal, useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { Toast } from '../Elements/Toast';
import { createDummyGraph, createOfflinePush } from '../../common/got-adjunct';
import { useToast } from '../../hooks/useToast';
import { DashboardFilterBanner } from '../Elements/ProjectDashboard/FilterBanner';
import { ListRow } from '../Common/ListRow';
import { TopButtonRow } from '../Elements/TopButtonRow';
import { DashboardTop } from '../Elements/ProjectDashboard/DashboardTop';
import { filterTops } from '../../common/filteredtops.util';
import { useNode } from '../../hooks/useNode';
import { newId, newIsoDate, newMsDate } from '../../common/util';
import { LoadAttachments } from '../Elements/HasAttachments';

export const AddDashboardTopModal = ({ stack: parentStack }) => {
    const [isOpen, close] = useModalState('add-dashboard-tops');
    const stack = R.append(CREATE_DASHBOARD_TOP_MODAL, parentStack);
    const { clear, useVar } = useGraph(...stack);
    const [searchString, setSearchString] = useVar(SEARCH_STRING_KEY);
    return (
        <div className="modal-container-compensation">
            <Modal
                name="add-dashboard-tops"
                style={{
                    height: '90vh',
                    top: '5vh',
                    width: '95vw',
                    left: '2.5vw',
                }}
                close={() => {
                    setSearchString('');
                    clear();
                }}
                topbar={<IoAddOutline size={24} color="var(--corporate-color-7)" />}
            >
                {isOpen ? (
                    <ModalContent stack={stack} close={close} searchString={searchString} />
                ) : null}
            </Modal>
        </div>
    );
};

const ModalContent = ({ stack, searchString, close }) => {
    const { useVar } = useGraph(...stack);
    const [filter] = useVar(DASHBOARD_FILTER_KEY);
    const filterValuesSum = R.sum([
        R.length(R.pathOr([], ['topType', 'values'], filter)),
        R.length(R.pathOr([], ['meetingType', 'values'], filter)),
        R.length(R.pathOr([], ['oppStatus', 'values'], filter)),
        R.length(R.pathOr([], ['actionStatus', 'values'], filter)),
        R.length(R.pathOr([], ['category', 'values'], filter)),
        R.length(R.pathOr([], ['responsible', 'values'], filter)),
    ]);
    const [topsToUpdate] = useVar(TOPS_TO_UPDATE_KEY);
    const [{ tops: sortedGroupedTops = {} } = {}] = useVar(SORTED_GROUPED_TOPS_KEY);
    const [projectId] = useVar(PROJECT_ID_KEY);
    const projectBag = useNode(stack, projectId);
    const statusses = R.pathOr([], ['node', 'oppStatusTypes'], projectBag);

    const topRows = useMemo(
        () =>
            R.compose(
                R.sortBy(R.prop('businessId')),
                R.values,
                R.map(entries => ({
                    ...R.last(entries),
                    previousVersions: R.dropLast(1, entries),
                })),
            )(sortedGroupedTops),
        [sortedGroupedTops],
    );

    const { searchedTops } = filterTops({
        showTops: true,
        filter,
        statusses,
        searchString,
    })(topRows);

    const setToast = useToast('add-dashboard-tops');
    const toastConfig = {
        textOnStart: 'Saving data...',
        textOnSuccess: 'Successfully saved.',
        textOnError: 'You are offline. Please sync when back online.',
    };
    const push = createOfflinePush(stack, setToast, toastConfig);
    const save = async () => {
        await push();
    };

    const openFilterModal = useModal(FILTER_BAR);
    useEffect(() => {
        openFilterModal();
        [window.scrollTo(0, 0)];
    }, []);

    const [meetingData] = useModalState('add-dashboard-tops');

    const addTopsToMeeting = () => {
        const { add, inheritRights, assoc, getGraph } = createDummyGraph();
        R.forEach(({ top, responsible }) => {
            const newTopId = newId();
            const newTop = R.compose(
                R.assoc('modifiedDate', newIsoDate()),
                R.assoc('id', newTopId),
            )(top);
            add(MEETING_TOP_EDGE)(meetingData.meetingId)(newTop, { order: newMsDate() });
            inheritRights(newTopId)(meetingData.subprojectId);
            responsible && assoc(TOP_RESPONSIBLE_EDGE)(newTopId)(responsible);
        })(R.values(topsToUpdate));
        store.mergeGraph(getGraph(), PROTOCOL);
    };

    return (
        <>
            <>
                <div className="protocol-screen-filterbanner-container">
                    <DashboardFilterBanner stack={stack} protocol />
                </div>
                {R.map(({ top, responsible, previousVersions, meetingId, protocolId }) => (
                    <ListRow
                        key={top.id}
                        style={{
                            marginLeft: 'var(--space-small)',
                            marginRight: 'var(--space-small)',
                        }}
                    >
                        <div className="top-search-row">
                            <TopButtonRow
                                top={top}
                                responsible={responsible}
                                previousVersions={previousVersions}
                                stack={stack}
                                editOnly
                            />
                            <DashboardTop
                                noButton
                                stack={stack}
                                meetingId={meetingId}
                                topId={R.prop('id', top)}
                                protocolId={protocolId}
                            />
                            <LoadAttachments
                                stack={stack}
                                node={top}
                                edgeTypes={TOP_ATTACHMENT_EDGE}
                            />
                        </div>
                    </ListRow>
                ))(
                    R.length(searchString) === 0 && filterValuesSum === 0 && !filter.showTops
                        ? []
                        : searchedTops,
                )}
            </>
            {R.length(R.values(topsToUpdate)) > 0 && (
                <div className="new-save-button-container">
                    <div
                        className="new-save-button"
                        onClick={() => {
                            addTopsToMeeting();
                            save();
                            close();
                        }}
                    >
                        <IoAddOutline size={30} style={{ marginRight: 6 }} />
                        Add selected items
                    </div>
                </div>
            )}
            <Toast name="add-dashboard-tops" />
        </>
    );
};
