import React from 'react';
import {
    IoPeopleOutline,
    IoHomeOutline,
    IoDocumentOutline,
    IoDocumentsOutline,
    IoFunnelOutline,
    IoShareSocialOutline,
    IoGridOutline,
    IoTrashOutline,
    IoCloudUploadOutline,
} from 'react-icons/io5';
import * as R from 'ramda';
import { useHistory } from 'react-router';
import { ProjectSelector } from '../ProjectSelector';
import { Button } from '../../Common/Controls';
import { useModal, useModalState } from '../../../hooks/useModal';
import {
    DASHBOARD_FILTER_KEY,
    FILTER_BAR,
    OFFLINE,
    PROJECT_ID_KEY,
    PROTOCOL,
    SEARCH_STRING_KEY,
    SORTED_GROUPED_TOPS_KEY,
    SUBPROJECT_ID_KEY,
    useGraph,
} from '../../../common/hooks.config.new';
import { useNode, usePullNode } from '../../../hooks/useNode';
import { useUserEmail } from '../../../hooks/useUserEmail';
import { NewMeetingButton } from './NewMeetingButton';
import { SearchBar } from './SearchBar';
import { OfflineSyncer } from '../OfflineSyncer';
import { useToast } from '../../../hooks/useToast';
import { CSVExportButton } from './CSVExportButton';
import { DashboardFilterBanner } from './FilterBanner';
import { DashboardMenu } from './DashboardMenu';

export const DashboardTopBar = ({ stack }) => {
    const openProjectsModal = useModal('projects2');
    const openTemplatesModal = useModal('templates');
    const openOrganizationsModal = useModal('organizations');
    const openContactsModal = useModal('contacts');
    const openExistingMeetingsModal = useModal('existingMeetings');
    const openFilterModal = useModal(FILTER_BAR);
    const [isOpen, close] = useModalState(FILTER_BAR);

    // selected subproject rights
    const user = useUserEmail();
    const { useVar } = useGraph(...stack);
    const [, setSearchString] = useVar(SEARCH_STRING_KEY);
    const [projectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [, setSortedGroupedTops] = useVar(SORTED_GROUPED_TOPS_KEY);
    const [filter] = useVar(DASHBOARD_FILTER_KEY);

    usePullNode(stack, subprojectId, { rights: true });
    const subprojectBag = useNode(stack, subprojectId, { rights: true });
    const canWriteSubproject = R.pathOr(false, ['rights', 'user', user, 'write'], subprojectBag);

    const setOfflineSyncToast = useToast('offlineSync');
    const history = useHistory();
    const [, setFilter] = useVar(DASHBOARD_FILTER_KEY);

    const filterValuesSum = R.sum([
        R.length(R.pathOr([], ['topType', 'values'], filter)),
        R.length(R.pathOr([], ['meetingType', 'values'], filter)),
        R.length(R.pathOr([], ['oppStatus', 'values'], filter)),
        R.length(R.pathOr([], ['actionStatus', 'values'], filter)),
        R.length(R.pathOr([], ['category', 'values'], filter)),
        R.length(R.pathOr([], ['responsible', 'values'], filter)),
    ]);
    const filterIsActive =
        filterValuesSum > 0 ||
        (filter?.meetingDate && !R.isEmpty(filter?.meetingDate)) ||
        (filter?.dueDate && !R.isEmpty(filter?.dueDate)) ||
        filter?.isCritical ||
        filter?.sort;

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="dashboard-screen-upper-topbar">
                <div style={{ display: 'flex', flex: '1' }}>
                    <Button
                        className="dashboard-toggle"
                        onClick={() => {
                            setSortedGroupedTops({});
                            setSearchString('');
                            history.push('global-dashboard');
                        }}
                    >
                        <IoGridOutline size={21} />
                        <p style={{ marginLeft: 9 }}>Global Dashboard</p>
                    </Button>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                    }}
                >
                    <ProjectSelector stack={stack} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: '1',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <OfflineSyncer
                        graphNames={[OFFLINE, PROTOCOL]}
                        onProgress={() => setOfflineSyncToast('spinner', 'Syncing offline data...')}
                        onSuccess={() =>
                            setOfflineSyncToast('success', 'Successfully saved.', 5000)
                        }
                        onError={error => {
                            if (error.status) {
                                setOfflineSyncToast(
                                    'error',
                                    error.message ? error.message : error.toString(),
                                );
                            } else {
                                setOfflineSyncToast(
                                    'error',
                                    'You are offline. Please sync when back online.',
                                    5000,
                                );
                            }
                        }}
                    >
                        <IoCloudUploadOutline size={24} color="var(--color-error)" />
                    </OfflineSyncer>
                    <DashboardMenu stack={stack} />
                </div>
            </div>
            <div className="dashboard-screen-lower-topbar">
                <div style={{ display: 'flex', flex: '1', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex' }}>
                        <Button onClick={openOrganizationsModal}>
                            <div className="button-icon">
                                <IoHomeOutline size={24} color="var(--corporate-color-1)" />
                                <p>Organizations</p>
                            </div>
                        </Button>
                        <Button onClick={openProjectsModal} style={{ marginLeft: 6 }}>
                            <div className="button-icon">
                                <IoShareSocialOutline size={27} color="var(--corporate-color-1)" />
                                <p>Projects</p>
                            </div>
                        </Button>
                        <Button onClick={openContactsModal} style={{ marginLeft: 6 }}>
                            <div className="button-icon">
                                <IoPeopleOutline size={28} color="var(--corporate-color-1)" />
                                <p>Contacts</p>
                            </div>
                        </Button>
                    </div>
                    {filterIsActive && (
                        <Button onClick={() => setFilter({})}>
                            <IoTrashOutline size={16} color="var(--corporate-color-2)" />
                        </Button>
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: '1.2',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        onClick={() => {
                            isOpen ? close() : openFilterModal();
                        }}
                        style={{
                            marginRight: 12,
                            backgroundColor: isOpen
                                ? 'var(--corporate-color-1-light4)'
                                : filterIsActive
                                ? 'var(--corporate-color-13-light)'
                                : null,
                        }}
                    >
                        <div className="button-icon">
                            <IoFunnelOutline size={26} color="var(--corporate-color-1)" />
                            <p>Filter</p>
                        </div>
                    </Button>
                    <SearchBar stack={stack} />
                    <CSVExportButton stack={stack} />
                </div>
                {canWriteSubproject && subprojectId ? (
                    <div
                        style={{
                            display: 'flex',
                            flex: '1',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <Button onClick={openExistingMeetingsModal} style={{ marginLeft: 6 }}>
                            <div className="button-icon">
                                <IoDocumentOutline size={26} color="var(--corporate-color-1)" />
                                <p>Meetings</p>
                            </div>
                        </Button>
                        <Button onClick={openTemplatesModal} style={{ marginLeft: 6 }}>
                            <div className="button-icon">
                                <IoDocumentsOutline size={26} color="var(--corporate-color-1)" />
                                <p>Use template</p>
                            </div>
                        </Button>
                        <NewMeetingButton stack={stack} />
                    </div>
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            flex: '1',
                            justifyContent: 'flex-end',
                            color: 'var(--corporate-color-1-light)',
                        }}
                    >
                        {projectId && 'Select a subproject to access meetings'}
                    </div>
                )}
            </div>
            <DashboardFilterBanner stack={stack} />
        </div>
    );
};
