import React from 'react';
import * as R from 'ramda';
import { Buffer } from 'buffer';
import { FileField } from '../../Common/Fields';
import { parseIcs } from '../../../common/ics.util';
import { useToast } from '../../../hooks/useToast';
import { MEETING_ATTENDEE_EDGE, MEETING_ICS, useGraph } from '../../../common/hooks.config.new';
import {
    selectContactBagWithEmail,
    useProjectContactsFns,
} from '../../../hooks/useProjectContacts';
import { newId, newMsDate } from '../../../common/util';
import { parseMsg } from '../../../common/msg.util';

export const useUpdateMeetingFromBlob = (stack, projectId, meetingId, setToggleDropzone) => {
    const { update, assoc } = useGraph(...stack);

    const { addContact, inheritFromContactDirectory, refresh, getContracts } =
        useProjectContactsFns(stack, projectId);

    const setMeetingToast = useToast(MEETING_ICS);

    const updateMeetingWithEvent = (event, contactBags) => {
        if (event) {
            const nodePatch = {
                id: meetingId,
                date: event.startDate,
                location: event.location,
                title: event.summary,
            };
            update(nodePatch);

            const attendees = R.propOr([], 'attendees', event);
            attendees.forEach(({ email }) => {
                if (email) {
                    const contactBag = selectContactBagWithEmail(email)(contactBags);
                    if (contactBag) {
                        assoc(MEETING_ATTENDEE_EDGE)(meetingId)(contactBag.node, {
                            order: newMsDate(),
                        });
                    } else {
                        const [name] = R.split('@', email);
                        const newContactId = newId();
                        const newContact = {
                            id: newContactId,
                            name,
                            email,
                            invited: false,
                        };

                        addContact(newContact, { order: newMsDate() });
                        inheritFromContactDirectory(newContact);
                        assoc(MEETING_ATTENDEE_EDGE)(meetingId)(newContact, { order: newMsDate() });
                    }
                }
            });
        }
    };

    const updateMeetingFromBlob = async blob => {
        try {
            await refresh();
            const contactBags = getContracts(stack, projectId);
            if (blob && blob.type === 'text/calendar') {
                const arrayBuffer = await blob.arrayBuffer();
                const icsData = Buffer.from(arrayBuffer).toString();
                const event = parseIcs(icsData);
                updateMeetingWithEvent(event, contactBags);
            } else if (blob) {
                const event = parseMsg(await blob.arrayBuffer());
                updateMeetingWithEvent(event, contactBags);
            } else {
                throw new Error('Invalid input');
            }
            setMeetingToast('success', 'Meeting updated', 5000);
            setToggleDropzone(false);
        } catch (err) {
            console.error(err);
            setMeetingToast('error', 'Unable to parse data.', 5000);
        }
    };

    return updateMeetingFromBlob;
};

export const MeetingIcsField = ({ stack, projectId, meetingId, setToggleDropzone }) => {
    const updateMeetingFromBlob = useUpdateMeetingFromBlob(
        stack,
        projectId,
        meetingId,
        setToggleDropzone,
    );

    return (
        <FileField
            dragMessage="Drop .ics files here to import meeting properties"
            style={{ width: '100%' }}
            className="invisible-background"
            onChange={async e => {
                const blob = R.nth(0, e);
                (await blob) && updateMeetingFromBlob(blob);
            }}
        />
    );
};

const pickFile = async () =>
    new Promise(resolve => {
        const input = document.createElement('input');
        input.type = 'file';
        input.onchange = () => {
            const file = R.nth(0, input.files);
            resolve(file);
        };
        input.click();
    });

export const MeetingIcsButton = ({ stack, projectId, meetingId }) => {
    const updateMeetingFromBlob = useUpdateMeetingFromBlob(stack, projectId, meetingId);

    return (
        <button
            onClick={async () => {
                const blob = await pickFile();
                blob && updateMeetingFromBlob(blob);
            }}
            style={{
                color: 'var(--corporate-color-1-light2)',
                fontSize: 12,
                position: 'absolute',
                bottom: -9,
                right: 0,
                cursor: 'pointer',
                border: 'none',
                margin: 0,
                padding: 0,
                backgroundColor: 'transparent',
            }}
        >
            Drop your ics file here
        </button>
    );
};
