import { useEffect } from 'react';

export const useOutsideClick = (ref, enabled, onClick) => {
    useEffect(() => {
        const handleMouseDown = e =>
            enabled && ref.current && !ref.current.contains(e.target) && onClick && onClick();

        document.addEventListener('mousedown', handleMouseDown);
        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, [enabled, onClick]);
};
