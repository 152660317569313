/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import * as R from 'ramda';
import { IoLinkOutline, IoTrashOutline } from 'react-icons/io5';
import {
    PROJECT_ID_KEY,
    PROJECT_OPPS_MODAL,
    store,
    TOP_ACTION_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { useModalState } from '../../hooks/useModal';
import { useProjectOpps } from '../../hooks/useProjectOpps';
import { Modal } from '../Common/Modal';
import { OppTops } from '../Elements/OppTops';
import { useToast } from '../../hooks/useToast';
import { Toast } from '../Elements/Toast';
import { createOfflinePush } from '../../common/got-adjunct';
import { Button } from '../Common/Controls';

export const ProjectOppsModal = ({ stack, noEdit }) => {
    const [isOpen] = useModalState('project-opps');
    return (
        <Modal
            name="project-opps"
            style={{
                height: '90vh',
                top: '5vh',
                width: '80vw',
                left: '10vw',
            }}
            topbar={<IoLinkOutline size={26} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <ProjectOppModalContent stack={stack} noEdit={noEdit} /> : null}
        </Modal>
    );
};

const ProjectOppModalContent = ({ stack: parentStack, noEdit }) => {
    const stack = R.append(PROJECT_OPPS_MODAL, parentStack);
    const { assoc, update } = useGraph(...stack);
    const [selectedActionId] = useModalState('project-opps');
    const projectId = store.getVar(...stack)(PROJECT_ID_KEY);
    const opps = useProjectOpps(stack, projectId);

    const setToast = useToast('assoc-message');
    const toastConfig = {
        textOnStart: 'Saving data...',
        textOnSuccess: 'Successfully saved.',
        textOnError: 'You are offline. Please sync when back online.',
    };
    const push = createOfflinePush(stack, setToast, toastConfig);
    const assocAction = top => {
        assoc(TOP_ACTION_EDGE)(top.id)({ id: selectedActionId.id });
        update(R.assoc('oppCategory', R.propOr('', 'category', top), selectedActionId));
        push();
    };

    const [oppSearch, setOppSearch] = useState('');

    const searchedOpps = searchString => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]+[u00C0-u017F]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.filter(
                o =>
                    (o.description && o.description.toUpperCase().search(string) !== -1) ||
                    (o.businessId && o.businessId.toUpperCase().search(string) !== -1),
            )(opps);
        }
        return opps;
    };

    return (
        <>
            <div className="columns">
                <div>
                    <Headline oppSearch={oppSearch} setOppSearch={setOppSearch} />
                    <OppTops
                        noEdit={noEdit}
                        stack={stack}
                        opps={searchedOpps(oppSearch)}
                        assocAction={assocAction}
                    />
                </div>
            </div>
            <Toast name="assoc-message" />
        </>
    );
};

const Headline = ({ oppSearch, setOppSearch }) => (
    <div className="modal-headline">
        <div style={{ flex: 1 }}>Available actions</div>
        <div style={{ display: 'flex', alignItems: 'center', flex: 2 }}>
            <div style={{ flex: 1 }} />
            <div className="animated-searchbar-half" style={{ flex: 3, animation: 'none' }}>
                <input
                    placeholder="search project opportunities..."
                    value={oppSearch}
                    onChange={e => setOppSearch(e.target.value)}
                    style={{ marginBottom: 0 }}
                />
            </div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-start' }}>
                <Button style={{ marginLeft: 3 }} onClick={() => setOppSearch('')}>
                    <IoTrashOutline size={17} />
                </Button>
            </div>
        </div>
        <div style={{ flex: 1 }} />
    </div>
);
