import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import {
    IoDocumentsOutline,
    IoTrashOutline,
    IoSearchOutline,
    IoChevronForwardOutline,
} from 'react-icons/io5';
import { ImFilePdf } from 'react-icons/im';
import { useModal, useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { Button } from '../Common/Controls';
import { ListField } from '../Common/Fields';
import {
    PROJECT_ID_KEY,
    PROTOCOL,
    store,
    SUBPROJECT_ID_KEY,
    useGraph,
} from '../../common/hooks.config.new';
import { useNode, usePullNode } from '../../hooks/useNode';
import { useSubprojectMeetings } from '../../hooks/useSubprojectMeetings';
import { cloneAsTemplate } from '../../common/cloneAsTemplate.util';
import { useNavigator } from '../../hooks/useNavigator';
import { newId } from '../../common/util';

export const TemplatesModal = ({ stack }) => {
    const [isOpen, close] = useModalState('templates');
    return (
        <Modal
            name="templates"
            style={{
                height: '87vh',
                top: '6.5vh',
                width: '60vw',
                left: '20vw',
            }}
            topbar={<IoDocumentsOutline size={24} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <TemplatesModalContent stack={stack} close={close} /> : null}
        </Modal>
    );
};

const TemplatesModalContent = ({ stack, close }) => {
    const { useVar } = useGraph(...stack);

    const [projectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);

    const { node: project } = useNode(stack, projectId);
    const { node: subproject } = useNode(stack, subprojectId);
    const { pull, meetingBags } = useSubprojectMeetings(stack, subprojectId);
    const sortedMeetingBags = R.compose(
        R.filter(R.pathEq(['node', 'isDummy'], undefined)),
        R.reverse,
        R.sortBy(R.path(['node', 'date'])),
        R.values,
    )(meetingBags);

    usePullNode(stack, projectId);
    useEffect(() => {
        subprojectId && pull();
    }, [subprojectId]); // pulls subproject ndoe and its meetings

    const openProtocolPdf = useModal('protocol-pdf');

    const meetingSearch = searchString => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]+[u00C0-u017F]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.filter(m => m.node.title && m.node.title.toUpperCase().search(string) !== -1)(
                sortedMeetingBags,
            );
        }
        return sortedMeetingBags;
    };

    const [templatesSearch, setTemplatesSearch] = useState('');
    const [search, setSearch] = useState(false);

    return (
        <div className="columns">
            <div>
                <Headline
                    search={search}
                    setSearch={setSearch}
                    templatesSearch={templatesSearch}
                    setTemplatesSearch={setTemplatesSearch}
                />
                <ListField className style={{ flex: 1, flexBasis: 150 }} label="Templates">
                    {R.map(({ node: meeting }) => (
                        <ProtocolItem
                            key={meeting.id}
                            stack={stack}
                            openProtocolPdf={openProtocolPdf}
                            meeting={meeting}
                            subprojectId={subprojectId}
                            projectBusinessId={R.propOr('', 'businessId', project)}
                            subprojectBusinessId={R.propOr('', 'businessId', subproject)}
                            close={close}
                        />
                    ))(meetingSearch(templatesSearch))}
                </ListField>
            </div>
        </div>
    );
};

const Headline = ({ search, setSearch, templatesSearch, setTemplatesSearch }) => {
    const inputRef = useRef();
    useEffect(() => {
        search && setTimeout(() => inputRef.current.focus(), 600);
    }, [search]);
    return (
        <div className="modal-headline">
            <div>Available Templates</div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                <Button style={{ marginLeft: 6 }} onClick={() => setSearch(!search)}>
                    {search ? <IoChevronForwardOutline size={19} /> : <IoSearchOutline size={21} />}
                </Button>
                {search && (
                    <div className="animated-searchbar">
                        <input
                            ref={inputRef}
                            placeholder="search templates..."
                            value={templatesSearch}
                            onChange={e => setTemplatesSearch(e.target.value)}
                        />
                        <Button style={{ marginLeft: 3 }} onClick={() => setTemplatesSearch('')}>
                            <IoTrashOutline size={17} />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

const ProtocolItem = ({
    stack,
    meeting,
    openProtocolPdf,
    subprojectId,
    projectBusinessId,
    subprojectBusinessId,
    close,
}) => {
    const { navigateToProtocol } = useNavigator(stack);
    // const { mergeGraph } = useGraph(...stack);

    return (
        <div className="row hover-effect" style={{ cursor: 'default' }}>
            <div
                style={{
                    display: 'flex',
                    flex: 2,
                    justifyContent: 'flex-start',
                    fontSize: 'inherit',
                    fontWeight: 100,
                }}
            >
                <IoDocumentsOutline
                    size={20}
                    color="var(--corporate-color-2-light)"
                    style={{ marginRight: 12 }}
                />
                {meeting.title}
            </div>
            <div
                style={{
                    color: 'var(--color-2)',
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                }}
            >
                {meeting.businessId}
            </div>
            <div style={{ display: 'flex', flex: 2, justifyContent: 'flex-end' }}>
                <Button
                    onClick={() => {
                        openProtocolPdf && openProtocolPdf({ meetingId: meeting.id });
                    }}
                    style={{ color: 'var(--corporate-color-2)' }}
                >
                    <ImFilePdf size={17} />
                    <p>Open PDF</p>
                </Button>
                <Button
                    onClick={async () => {
                        const newMeetingId = newId();
                        const templateGraph = await cloneAsTemplate({
                            stack,
                            meeting,
                            newMeetingId,
                            projectBusinessId,
                            subprojectId,
                            subprojectBusinessId,
                        });
                        store.mergeGraph(templateGraph, PROTOCOL);
                        navigateToProtocol(newMeetingId);
                        close();
                    }}
                    style={{ color: 'var(--corporate-color-2)', marginLeft: 6 }}
                >
                    <IoDocumentsOutline size={20} />
                    <p>Use As Template</p>
                </Button>
            </div>
        </div>
    );
};
