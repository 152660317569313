import React, { useEffect } from 'react';
import * as R from 'ramda';
import { IoCreateOutline, IoSaveOutline } from 'react-icons/io5';
import { useModalState } from '../../hooks/useModal';
import { TextField } from '../Common/Fields';
import { Modal } from '../Common/Modal';
import { useToast } from '../../hooks/useToast';
import { Button, FancyCheckbox } from '../Common/Controls';
import {
    PROJECT_SUBPROJECT_EDGE,
    SUBPROJECT_MEETING_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { createOfflinePush } from '../../common/got-adjunct';
import { isValidMail, newIsoDate, newMsDate } from '../../common/util';

export const constructMeetingsView = subprojectId =>
    subprojectId
        ? {
              [subprojectId]: {
                  as: 'subproject',
                  include: {
                      node: true,
                      rights: true,
                      files: true,
                  },
                  edges: {
                      [SUBPROJECT_MEETING_EDGE]: {
                          as: 'meetings',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const EditSubprojectModal = ({ stack: parentStack, projectView, canWriteSubproject }) => {
    const stack = R.append('edit-subproject', parentStack);
    const { clear } = useGraph(...stack);
    const [isOpen, close] = useModalState('edit-subproject');
    return (
        <Modal
            name="edit-subproject"
            style={{
                height: '60vh',
                width: '50vw',
                top: '20vh',
                left: '25vw',
            }}
            topbar={<IoCreateOutline size={25} color="var(--corporate-color-7)" />}
            close={close}
            clear={clear}
        >
            {isOpen ? (
                <EditSubprojectModalContent
                    stack={stack}
                    canWriteSubproject={canWriteSubproject}
                    projectView={projectView}
                    close={close}
                />
            ) : null}
        </Modal>
    );
};

const EditSubprojectModalContent = ({ stack, projectView, close }) => {
    const { update, pull, add, setRights, inheritRights, useView } = useGraph(...stack);
    const { useVar } = useGraph(...stack);
    const [{ subprojectId, isNew, user, projectId }] = useModalState('edit-subproject');

    const [, setSubprojectId] = useVar('subprojectId');

    useEffect(() => {
        if (isNew && subprojectId) {
            const newSubproject = {
                id: subprojectId,
                createdDate: newIsoDate(),
            };
            add(PROJECT_SUBPROJECT_EDGE)(projectId)(newSubproject, {
                order: newMsDate(),
            });
            setRights(subprojectId)(user, {
                read: true,
                write: true,
            });
            inheritRights(subprojectId)(projectId);
            setSubprojectId(subprojectId);
        }
    }, [subprojectId, isNew]);

    // selected subproject
    const subprojectView = constructMeetingsView(subprojectId);
    useEffect(() => {
        isNew && subprojectId && pull(subprojectView);
    }, [subprojectId]);
    const { subproject: subprojectBag = {} } = useView(subprojectView);
    const canWriteSubproject = R.pathOr(false, ['rights', 'user', user, 'write'], subprojectBag);
    const subproject = R.propOr({}, 'node', subprojectBag);

    const setToast = useToast('dashboard');
    const save = async () => {
        setToast('spinner', 'Saving subproject data...');
        const toastConfig = {
            textOnStart: 'Saving subproject data...',
        };
        const push = createOfflinePush(stack, setToast, toastConfig);
        return push()
            .then(({ uploads }) => {
                uploads.subscribe({
                    complete: () => {
                        setToast('success', 'Successfully saved.', 5000);
                        pull(projectView);
                    },
                });
                uploads.start();
            })
            .catch(error => {
                if (error.status) {
                    setToast('error', error.message ? error.message : error.toString());
                } else {
                    setToast('error', 'You are offline. Please sync when back online.', 5000);
                }
            });
    };
    const checkAndSave = () => {
        if (!subproject.businessId) {
            return setToast('hint', 'Create a subproject id', 5000);
        }
        if (/[^a-zA-Z0-9]/.test(subproject.businessId)) {
            return setToast('hint', 'The subproject id may only contain letters and numbers', 5000);
        }
        if (subproject.replyTo && !isValidMail(subproject.replyTo)) {
            return setToast('hint', 'Please enter a valid reply-to mail address', 5000);
        }
        if (subproject.businessId && !/[^a-zA-Z0-9]/.test(subproject.businessId)) {
            save();
            close();
        }
        return null;
    };
    return (
        <div className="columns">
            <div>
                <div className="modal-headline">
                    <div>Edit Subproject</div>
                </div>
                <TextField
                    label="Subproject name"
                    value={subproject.title || ''}
                    onChange={value => update(R.assoc('title', value, subproject))}
                    editable={canWriteSubproject}
                />
                <TextField
                    label="Subproject ID"
                    value={subproject.businessId || ''}
                    onChange={value => update(R.assoc('businessId', value, subproject))}
                    editable={canWriteSubproject}
                />
                <TextField
                    label="Reply-to mail address"
                    value={subproject.replyTo || ''}
                    onChange={value => update(R.assoc('replyTo', value, subproject))}
                    editable={canWriteSubproject}
                />
                {canWriteSubproject && (
                    <FancyCheckbox
                        label="Always use meeting minutes author mail as reply-to mail"
                        onChange={() =>
                            update(R.assoc('replyToAuthor', !subproject.replyToAuthor, subproject))
                        }
                        value={subproject.replyToAuthor || false}
                        containerStyle={{ justifyContent: 'space-between' }}
                    />
                )}
                <div className="new-save-button-container">
                    <Button onClick={() => checkAndSave()} className="new-save-button">
                        <IoSaveOutline size={22} style={{ marginRight: 9 }} />
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};
