import React from 'react';
import { IoChevronBackOutline, IoSaveOutline } from 'react-icons/io5';
import { Button } from '../../Components/Common/Controls';

export const SubscreenMenuButtonsRow = ({ onMoveBack, onSave }) => (
    <div className="menu-button-row">
        <Button onClick={() => onMoveBack()} className="menu-button">
            <IoChevronBackOutline
                size="7rem"
                style={{ marginRight: '0.5rem', color: 'var(--corporate-color-2)' }}
            />
        </Button>
        {onSave && (
            <Button className="save-subscreen-button" onClick={() => onSave()}>
                <IoSaveOutline size="6rem" />
            </Button>
        )}
    </div>
);
