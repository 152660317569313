import { useEffect } from 'react';
import * as R from 'ramda';
import { store, TOP_ACTION_EDGE, TOP_OPP, useGraph } from '../common/hooks.config.new';

const constructTopOppView = topId =>
    topId
        ? {
              [topId]: {
                  edges: {
                      [TOP_ACTION_EDGE]: {
                          reverse: true,
                          as: 'opp',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const useTopsOpp = (stack, topIds, trigger) => {
    const { useView, pull } = useGraph(...stack);
    const userContactTopsView = R.compose(
        R.mergeAll,
        R.map(id => constructTopOppView(id)),
    )(topIds);
    useEffect(() => {
        pull(userContactTopsView);
    }, [trigger]);
    const topsOppViewResult = useView(userContactTopsView);
    useEffect(() => {
        store.setVar(R.last(stack))(TOP_OPP, topsOppViewResult);
    }, [trigger]);

    return null;
};
