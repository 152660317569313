/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as R from 'ramda';
import '../../Components/Elements/ChartsArea.css';
import {
    IoChevronDownOutline,
    IoChevronForwardOutline,
    IoSearchOutline,
    IoTrashOutline,
} from 'react-icons/io5';
import {
    ACTIONS_KANBAN_BOARD,
    PHASES_KANBAN_BOARD,
    PROJECT_ID_KEY,
    SORTED_GROUPED_TOPS_KEY,
    useGraph,
} from '../../common/hooks.config.new';
import { useNodeFromVar } from '../../hooks/useNode';
import { OnlyOnActionDrag, OnlyOnDrag } from '../../Components/Common/OnlyOnDrag';
import { useToast } from '../../hooks/useToast';
import { createNodePatch, createOfflinePush } from '../../common/got-adjunct';
import { Button } from '../../Components/Common/Controls';
import { ListRow } from '../../Components/Common/ListRow';
import { TopMobile } from './TopMobile';
import { TopButtonRowMobile } from './TopButtonRowMobile';
import { useCreateHistory } from '../../hooks/useCreateHistory';

export const ActionsKanbanBoardMobile = ({
    stack: parentStack,
    actions,
    oppTracking,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
}) => {
    const stack = R.append(ACTIONS_KANBAN_BOARD, parentStack);
    const { node: project } = useNodeFromVar(stack, PROJECT_ID_KEY);
    const projectActionTypes = R.propOr([], 'actionStatusTypes', project);
    const containerRef = useRef();
    const scrollX = value => {
        const scrollSpeed = 3;
        const distance = value * scrollSpeed;
        if (containerRef.current) {
            const prev = containerRef.current.scrollLeft;
            containerRef.current.scrollLeft = distance + prev;
        }
    };
    const [draggedOverColumn, setDraggedOverColumn] = useState();

    return (
        <div className="kanban-container-mobile fader" ref={containerRef}>
            <OnlyOnDrag
                renderTrue={() => (
                    <>
                        <div
                            style={{
                                position: 'absolute',
                                left: 0,
                                height: '90vh',
                                width: 100,
                            }}
                            onDragOver={() => scrollX(-1)}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                right: 0,
                                height: '90vh',
                                width: 100,
                            }}
                            onDragOver={() => {
                                scrollX(1);
                            }}
                        />
                    </>
                )}
            />
            {R.map(status => (
                <ActionStatusBucket
                    key={status}
                    stack={stack}
                    status={status}
                    actions={actions}
                    oppTracking={oppTracking}
                    canAdminProject={canAdminProject}
                    canWriteProject={canWriteProject}
                    subprojectId={subprojectId}
                    editMessage={editMessage}
                    userName={userName}
                    draggedOverColumn={draggedOverColumn}
                    setDraggedOverColumn={setDraggedOverColumn}
                />
            ))(projectActionTypes)}
        </div>
    );
};

const ActionStatusBucket = ({
    stack,
    status,
    actions,
    oppTracking,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
    draggedOverColumn,
    setDraggedOverColumn,
}) => {
    const { update } = useGraph(...stack);
    const [search, setSearch] = useState(false);
    const [searchString, setSearchString] = useState('');
    const inputRef = useRef();
    useEffect(() => {
        const timer = search && window.setTimeout(() => inputRef.current.focus(), 600);
        return () => {
            timer && window.clearTimeout(timer);
        };
    }, [search]);
    const topsSearch = sString => {
        const string = sString.replace(/[^a-zA-Z0-9" "]+[u00C0-u017F]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.compose(
                R.filter(
                    c => c.top.description && c.top.description.toUpperCase().search(string) !== -1,
                ),
            )(actions);
        }
        return actions;
    };

    const setToast = useToast(ACTIONS_KANBAN_BOARD);
    const save = (startText, endText) => {
        const toastConfig = {
            textOnStart: startText || 'Saving data...',
            textOnSuccess: endText || 'Succesfully saved...',
        };
        const push = createOfflinePush(stack, setToast, toastConfig);
        return push();
    };

    const createHistory = useCreateHistory(stack);

    const connectTops = (prevTop, nextTop) => {
        const prevTopId = prevTop.top.id;
        const nextTopId = nextTop.top.id;
        const nextBusinessId = nextTop.top.businessId;
        const prevBusinessId = prevTop.top.businessId;
        update(createNodePatch(prevTopId, 'next', { id: nextTopId, businessId: nextBusinessId }));
        update(
            createNodePatch(nextTopId, 'previous', { id: prevTopId, businessId: prevBusinessId }),
        );
        save('Connecting items...', 'Succesfully connected.');
    };

    const topsCount = R.length(
        R.filter(action => R.pathOr('Not assigned', ['top', 'status'], action) === status)(actions),
    );
    const [noOfRenderedTops, setNoOfRenderedTops] = useState(10);
    const bucketTops = R.filter(
        action => R.pathOr('Not assigned', ['top', 'status'], action) === status,
    )(topsSearch(searchString));
    const topsToDisplay = R.slice(0, noOfRenderedTops, bucketTops);

    return (
        <div
            className="board-element-mobile"
            style={
                draggedOverColumn === status
                    ? {
                          boxShadow: '0 5px 8px -2px var(--corporate-color-13)',
                          borderColor: 'var(--corporate-color-13)',
                      }
                    : {}
            }
            onDrop={async ev => {
                if (subprojectId && canWriteProject) {
                    const transferedTop = ev.dataTransfer.getData('top');
                    const top = JSON.parse(transferedTop);

                    if (top?.top?.status !== status && status !== 'Not assigned') {
                        await createHistory([top], { newStatus: status });
                        await save();
                    }
                } else editMessage('Select a subproject to move this action...');
                setDraggedOverColumn();
            }}
            onDragOver={ev => ev.preventDefault()}
            onDragEnter={() => {
                setDraggedOverColumn(status);
            }}
            onDragEnd={() => setDraggedOverColumn()}
        >
            <div className="header">
                {search ? (
                    <div className="animated-searchbar-mobile">
                        <Button
                            onClick={() => setSearch(!search)}
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <IoChevronForwardOutline size="4rem" />
                        </Button>
                        <input
                            ref={inputRef}
                            placeholder={`search ${status.toLowerCase()} actions...`}
                            value={searchString}
                            onChange={e => setSearchString(e.target.value)}
                        />
                        <Button
                            style={{ marginLeft: 3, backgroundColor: 'transparent' }}
                            onClick={() => setSearchString('')}
                        >
                            <IoTrashOutline size="3rem" />
                        </Button>
                    </div>
                ) : (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                className="count"
                                style={{
                                    color: topsCount === 0 && 'white',
                                    backgroundColor:
                                        draggedOverColumn === status
                                            ? 'var(--corporate-color-13-light)'
                                            : topsCount === 0 && 'darkgray',
                                }}
                            >
                                {topsCount}
                            </div>
                            <div
                                className="status"
                                style={
                                    draggedOverColumn === status
                                        ? { color: 'var(--corporate-color-13)' }
                                        : {}
                                }
                            >
                                {status}
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Button
                                style={{ marginLeft: 6, backgroundColor: 'transparent' }}
                                onClick={() => setSearch(true)}
                            >
                                <IoSearchOutline
                                    size="4.5rem"
                                    color={
                                        draggedOverColumn === status
                                            ? 'var(--corporate-color-13)'
                                            : undefined
                                    }
                                />
                            </Button>
                        </div>
                    </>
                )}
            </div>
            <div className="content">
                {R.map(top => (
                    <ListRow
                        key={top.top.id}
                        style={{
                            margin: '0.5rem 1rem 3rem 1rem',
                            border: '1px solid var(--color-3)',
                        }}
                        draggable={canWriteProject}
                        onDrag={ev => {
                            const topToTransfer = JSON.stringify(top);
                            ev.dataTransfer.setData('top', topToTransfer);
                            top.top.type === 'a' && ev.dataTransfer.setData('action', true);
                        }}
                    >
                        <div className="top-search-row" style={{ position: 'relative' }}>
                            {top.top.type === 'a' &&
                                canWriteProject &&
                                draggedOverColumn === status && (
                                    <OnDrag top={top} connectTops={connectTops} />
                                )}
                            <TopButtonRowMobile
                                top={top.top}
                                responsible={top.responsible}
                                previousVersions={top.previousVersions}
                                canAdminProject={canAdminProject}
                                canWriteProject={canWriteProject}
                                subprojectId={subprojectId}
                                stack={stack}
                                editMessage={editMessage}
                                userName={userName}
                                oppTracking={oppTracking}
                                meetingId={top.meetingId}
                                protocolId={top.protocolId}
                                kanban
                            />
                            <TopMobile
                                noButton
                                stack={stack}
                                meetingId={top.meetingId}
                                topId={R.path(['top', 'id'], top)}
                                protocolId={top.protocolId}
                            />
                        </div>
                    </ListRow>
                ))(topsToDisplay)}
                {R.length(bucketTops) > 10 && R.length(bucketTops) > noOfRenderedTops && (
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        <Button
                            className="show-more-tops-button-mobile"
                            onClick={() => setNoOfRenderedTops(noOfRenderedTops + 10)}
                            style={{ backgroundColor: 'white' }}
                        >
                            <div>
                                <IoChevronDownOutline size="4.5rem" />
                            </div>
                        </Button>
                    </div>
                )}
                <div style={{ height: '15rem' }} />
            </div>
        </div>
    );
};

export const ProjectPhasesKanbanBoardMobile = ({
    stack: parentStack,
    oppTracking,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
}) => {
    const stack = R.append(PHASES_KANBAN_BOARD, parentStack);
    const { node: project } = useNodeFromVar(stack, PROJECT_ID_KEY);
    const { useVar } = useGraph(...stack);
    const projectPhases = [...R.values(R.propOr([], 'phases', project)), { name: 'Not assigned' }];

    const [{ tops: sortedGroupedTops = {} } = {}] = useVar(SORTED_GROUPED_TOPS_KEY);

    const topRows = useMemo(
        () =>
            R.compose(
                R.sortBy(R.prop('businessId')),
                R.values,
                R.map(entries => ({
                    ...R.last(entries),
                    previousVersions: R.dropLast(1, entries),
                })),
            )(sortedGroupedTops),
        [sortedGroupedTops],
    );

    const containerRef = useRef();
    const scrollX = value => {
        const scrollSpeed = 3;
        const distance = value * scrollSpeed;
        if (containerRef.current) {
            const prev = containerRef.current.scrollLeft;
            containerRef.current.scrollLeft = distance + prev;
        }
    };
    const [draggedOverColumn, setDraggedOverColumn] = useState();

    return (
        <div className="kanban-container-mobile fader" ref={containerRef}>
            <OnlyOnDrag
                renderTrue={() => (
                    <>
                        <div
                            style={{
                                position: 'absolute',
                                left: 0,
                                height: '90vh',
                                width: 100,
                            }}
                            onDragOver={() => scrollX(-1)}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                right: 0,
                                height: '90vh',
                                width: 100,
                            }}
                            onDragOver={() => {
                                scrollX(1);
                            }}
                        />
                    </>
                )}
            />
            {R.map(phase => (
                <ProjectPhaseBucket
                    key={phase.name}
                    stack={stack}
                    phase={phase}
                    sortedTops={topRows}
                    oppTracking={oppTracking}
                    canAdminProject={canAdminProject}
                    canWriteProject={canWriteProject}
                    subprojectId={subprojectId}
                    editMessage={editMessage}
                    userName={userName}
                    draggedOverColumn={draggedOverColumn}
                    setDraggedOverColumn={setDraggedOverColumn}
                />
            ))(projectPhases)}
        </div>
    );
};

const ProjectPhaseBucket = ({
    stack,
    phase,
    sortedTops,
    oppTracking,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
    draggedOverColumn,
    setDraggedOverColumn,
}) => {
    const { update } = useGraph(...stack);
    const phaseName = R.propOr('', 'name', phase);
    const [search, setSearch] = useState(false);
    const [searchString, setSearchString] = useState('');
    const inputRef = useRef();
    useEffect(() => {
        const timer = search && window.setTimeout(() => inputRef.current.focus(), 600);
        return () => {
            timer && window.clearTimeout(timer);
        };
    }, [search]);

    const topsSearch = sString => {
        const string = sString.replace(/[^a-zA-Z0-9" "]+[u00C0-u017F]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.compose(
                R.filter(
                    c => c.top.description && c.top.description.toUpperCase().search(string) !== -1,
                ),
            )(sortedTops);
        }
        return sortedTops;
    };

    const setToast = useToast(PHASES_KANBAN_BOARD);

    const save = (startText, endText) => {
        const toastConfig = {
            textOnStart: startText || 'Saving data...',
            textOnSuccess: endText || 'Succesfully saved...',
        };
        const push = createOfflinePush(stack, setToast, toastConfig);
        return push();
    };

    const createHistory = useCreateHistory(stack);

    const connectTops = (prevTop, nextTop) => {
        const prevTopId = prevTop.top.id;
        const nextTopId = nextTop.top.id;
        const nextBusinessId = nextTop.top.businessId;
        const prevBusinessId = prevTop.top.businessId;
        update(createNodePatch(prevTopId, 'next', { id: nextTopId, businessId: nextBusinessId }));
        update(
            createNodePatch(nextTopId, 'previous', { id: prevTopId, businessId: prevBusinessId }),
        );
        save('Connecting actions...', 'Actions succesfully connected.');
    };

    const topsCount = R.length(
        R.filter(top => R.pathOr('Not assigned', ['top', 'phase'], top) === phaseName)(sortedTops),
    );
    const [noOfRenderedTops, setNoOfRenderedTops] = useState(10);
    const bucketTops = R.filter(
        action => R.pathOr('Not assigned', ['top', 'phase'], action) === phaseName,
    )(topsSearch(searchString));
    const topsToDisplay = R.slice(0, noOfRenderedTops, bucketTops);
    return (
        <div
            className="board-element-mobile"
            style={
                draggedOverColumn === phaseName
                    ? {
                          boxShadow: '0 5px 8px -2px var(--corporate-color-13)',
                          borderColor: 'var(--corporate-color-13)',
                      }
                    : {}
            }
            onDrop={async ev => {
                if (subprojectId && canWriteProject) {
                    const transferedTop = ev.dataTransfer.getData('top');
                    const top = JSON.parse(transferedTop);
                    top.top.phase === phaseName || phaseName === 'Not assigned'
                        ? null
                        : createHistory([top], phaseName);

                    if (top?.top?.phase !== phaseName && phaseName !== 'Not assigned') {
                        await createHistory([top], { newPhase: phaseName });
                        await save();
                    }
                } else editMessage('Select a subproject to move this action...');
                setDraggedOverColumn();
            }}
            onDragOver={ev => ev.preventDefault()}
            onDragEnter={() => {
                setDraggedOverColumn(phaseName);
            }}
            onDragEnd={() => setDraggedOverColumn()}
        >
            <div className="header">
                {search ? (
                    <div className="animated-searchbar-mobile">
                        <Button
                            onClick={() => setSearch(!search)}
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <IoChevronForwardOutline size="4rem" />
                        </Button>
                        <input
                            ref={inputRef}
                            placeholder={`search ${phaseName.toLowerCase()} items...`}
                            value={searchString}
                            onChange={e => setSearchString(e.target.value)}
                        />
                        <Button
                            style={{ marginLeft: 3, backgroundColor: 'transparent' }}
                            onClick={() => setSearchString('')}
                        >
                            <IoTrashOutline size="3rem" />
                        </Button>
                    </div>
                ) : (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                className="count"
                                style={{
                                    color: topsCount === 0 && 'white',
                                    backgroundColor:
                                        draggedOverColumn === phaseName
                                            ? 'var(--corporate-color-13-light)'
                                            : topsCount === 0 && 'darkgray',
                                }}
                            >
                                {topsCount}
                            </div>
                            <div
                                className="status"
                                style={
                                    draggedOverColumn === phaseName
                                        ? { color: 'var(--corporate-color-13)' }
                                        : {}
                                }
                            >
                                {R.propOr('', 'name', phase)}
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Button
                                style={{ marginLeft: 6, backgroundColor: 'transparent' }}
                                onClick={() => setSearch(true)}
                            >
                                <IoSearchOutline
                                    size="4.5rem"
                                    color={
                                        draggedOverColumn === phaseName
                                            ? 'var(--corporate-color-13)'
                                            : undefined
                                    }
                                />
                            </Button>
                        </div>
                    </>
                )}
            </div>
            <div className="content">
                {R.map(top => (
                    <ListRow
                        key={top.top.id}
                        style={{
                            margin: '0.5rem 1rem 3rem 1rem',
                            border: '1px solid var(--color-3)',
                            boxSizing: 'border-box',
                        }}
                        draggable={canWriteProject}
                        onDrag={ev => {
                            const topToTransfer = JSON.stringify(top);
                            ev.dataTransfer.setData('top', topToTransfer);
                            top.top.type === 'a' && ev.dataTransfer.setData('action', true);
                        }}
                    >
                        <div className="top-search-row" style={{ position: 'relative' }}>
                            {top.top.type === 'a' &&
                                canWriteProject &&
                                draggedOverColumn === phaseName && (
                                    <OnDrag top={top} connectTops={connectTops} />
                                )}
                            <TopButtonRowMobile
                                top={top.top}
                                responsible={top.responsible}
                                previousVersions={top.previousVersions}
                                canAdminProject={canAdminProject}
                                canWriteProject={canWriteProject}
                                subprojectId={subprojectId}
                                stack={stack}
                                editMessage={editMessage}
                                userName={userName}
                                oppTracking={oppTracking}
                                meetingId={top.meetingId}
                                protocolId={top.protocolId}
                                kanban
                            />
                            <TopMobile
                                noButton
                                stack={stack}
                                meetingId={top.meetingId}
                                topId={R.path(['top', 'id'], top)}
                                protocolId={top.protocolId}
                            />
                        </div>
                    </ListRow>
                ))(topsToDisplay)}
                {R.length(bucketTops) > 10 && R.length(bucketTops) > noOfRenderedTops && (
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        <Button
                            className="show-more-tops-button-mobile"
                            onClick={() => setNoOfRenderedTops(noOfRenderedTops + 10)}
                            style={{ backgroundColor: 'white' }}
                        >
                            <div>
                                <IoChevronDownOutline size="4.5rem" />
                            </div>
                        </Button>
                    </div>
                )}
                <div style={{ height: '15rem' }} />
            </div>
        </div>
    );
};

export const OppsCategoryKanbanBoardMobile = ({
    stack: parentStack,
    oppTracking,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
    opps,
}) => {
    const stack = R.append(PHASES_KANBAN_BOARD, parentStack);
    const { node: project } = useNodeFromVar(stack, PROJECT_ID_KEY);
    const oppCategories = R.propOr([], 'oppCategoryTypes', project);

    const containerRef = useRef();
    const scrollX = value => {
        const scrollSpeed = 3;
        const distance = value * scrollSpeed;
        if (containerRef.current) {
            const prev = containerRef.current.scrollLeft;
            containerRef.current.scrollLeft = distance + prev;
        }
    };
    const [draggedOverColumn, setDraggedOverColumn] = useState();

    return (
        <div className="kanban-container-mobile fader" ref={containerRef}>
            <OnlyOnDrag
                renderTrue={() => (
                    <>
                        <div
                            style={{
                                position: 'absolute',
                                left: 0,
                                height: '90vh',
                                width: 100,
                            }}
                            onDragOver={() => scrollX(-1)}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                right: 0,
                                height: '90vh',
                                width: 100,
                            }}
                            onDragOver={() => {
                                scrollX(1);
                            }}
                        />
                    </>
                )}
            />
            {R.map(category => (
                <OppCategoryBucket
                    key={category}
                    stack={stack}
                    category={category}
                    sortedTops={opps}
                    oppTracking={oppTracking}
                    canAdminProject={canAdminProject}
                    canWriteProject={canWriteProject}
                    subprojectId={subprojectId}
                    editMessage={editMessage}
                    userName={userName}
                    draggedOverColumn={draggedOverColumn}
                    setDraggedOverColumn={setDraggedOverColumn}
                />
            ))(oppCategories)}
        </div>
    );
};

const OppCategoryBucket = ({
    stack,
    category,
    sortedTops,
    oppTracking,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
    draggedOverColumn,
    setDraggedOverColumn,
}) => {
    const [search, setSearch] = useState(false);
    const [searchString, setSearchString] = useState('');
    const inputRef = useRef();
    useEffect(() => {
        const timer = search && window.setTimeout(() => inputRef.current.focus(), 600);
        return () => {
            timer && window.clearTimeout(timer);
        };
    }, [search]);

    const topsSearch = sString => {
        const string = sString.replace(/[^a-zA-Z0-9" "]+[u00C0-u017F]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.compose(
                R.filter(
                    c => c.top.description && c.top.description.toUpperCase().search(string) !== -1,
                ),
            )(sortedTops);
        }
        return sortedTops;
    };

    const setToast = useToast(PHASES_KANBAN_BOARD);

    const save = (startText, endText) => {
        const toastConfig = {
            textOnStart: startText || 'Saving data...',
            textOnSuccess: endText || 'Succesfully saved...',
        };
        const push = createOfflinePush(stack, setToast, toastConfig);
        return push();
    };

    const createHistory = useCreateHistory(stack);

    const topsCount = R.length(
        R.filter(top => R.pathOr('Not Assigned', ['top', 'category'], top) === category)(
            sortedTops,
        ),
    );
    const [noOfRenderedTops, setNoOfRenderedTops] = useState(10);
    const bucketTops = R.filter(
        action => R.pathOr('Not assigned', ['top', 'category'], action) === category,
    )(topsSearch(searchString));
    const topsToDisplay = R.slice(0, noOfRenderedTops, bucketTops);
    return (
        <div
            className="board-element-mobile"
            style={
                draggedOverColumn === category
                    ? {
                          boxShadow: '0 5px 8px -2px var(--corporate-color-13)',
                          borderColor: 'var(--corporate-color-13)',
                      }
                    : {}
            }
            onDrop={async ev => {
                if (subprojectId && canWriteProject) {
                    const transferedTop = ev.dataTransfer.getData('top');
                    const top = JSON.parse(transferedTop);
                    if (top?.top?.phase !== category && category !== 'Not Assigned') {
                        await createHistory([top], { newCategory: category });
                        await save();
                    }
                } else editMessage('Select a subproject to move this action...');
                setDraggedOverColumn();
            }}
            onDragOver={ev => ev.preventDefault()}
            onDragEnter={() => {
                setDraggedOverColumn(category);
            }}
            onDragEnd={() => setDraggedOverColumn()}
        >
            <div className="header">
                {search ? (
                    <div className="animated-searchbar-mobile">
                        <Button
                            onClick={() => setSearch(!search)}
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <IoChevronForwardOutline size="4rem" />
                        </Button>
                        <input
                            ref={inputRef}
                            placeholder={`search ${category.toLowerCase()} opportunities...`}
                            value={searchString}
                            onChange={e => setSearchString(e.target.value)}
                        />
                        <Button
                            style={{ marginLeft: 3, backgroundColor: 'transparent' }}
                            onClick={() => setSearchString('')}
                        >
                            <IoTrashOutline size="3rem" />
                        </Button>
                    </div>
                ) : (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                className="count"
                                style={{
                                    color: topsCount === 0 && 'white',
                                    backgroundColor:
                                        draggedOverColumn === category
                                            ? 'var(--corporate-color-13-light)'
                                            : topsCount === 0 && 'darkgray',
                                }}
                            >
                                {topsCount}
                            </div>
                            <div
                                className="status"
                                style={
                                    draggedOverColumn === category
                                        ? { color: 'var(--corporate-color-13)' }
                                        : {}
                                }
                            >
                                {category}
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Button
                                style={{ marginLeft: 6, backgroundColor: 'transparent' }}
                                onClick={() => setSearch(true)}
                            >
                                <IoSearchOutline
                                    size="4.5rem"
                                    color={
                                        draggedOverColumn === category
                                            ? 'var(--corporate-color-13)'
                                            : undefined
                                    }
                                />
                            </Button>
                        </div>
                    </>
                )}
            </div>
            <div className="content">
                {R.map(top => (
                    <ListRow
                        key={top.top.id}
                        style={{
                            margin: '0.5rem 1rem 3rem 1rem',
                            border: '1px solid var(--color-3)',
                            boxSizing: 'border-box',
                        }}
                        draggable={canWriteProject}
                        onDrag={ev => {
                            const topToTransfer = JSON.stringify(top);
                            ev.dataTransfer.setData('top', topToTransfer);
                            top.top.type === 'a' && ev.dataTransfer.setData('action', true);
                        }}
                    >
                        <div className="top-search-row" style={{ position: 'relative' }}>
                            <TopButtonRowMobile
                                top={top.top}
                                responsible={top.responsible}
                                previousVersions={top.previousVersions}
                                canAdminProject={canAdminProject}
                                canWriteProject={canWriteProject}
                                subprojectId={subprojectId}
                                stack={stack}
                                editMessage={editMessage}
                                userName={userName}
                                oppTracking={oppTracking}
                                meetingId={top.meetingId}
                                protocolId={top.protocolId}
                                kanban
                            />
                            <TopMobile
                                noButton
                                stack={stack}
                                meetingId={top.meetingId}
                                topId={R.path(['top', 'id'], top)}
                                protocolId={top.protocolId}
                            />
                        </div>
                    </ListRow>
                ))(topsToDisplay)}
                {R.length(bucketTops) > 10 && R.length(bucketTops) > noOfRenderedTops && (
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        <Button
                            className="show-more-tops-button-mobile"
                            onClick={() => setNoOfRenderedTops(noOfRenderedTops + 10)}
                            style={{ backgroundColor: 'white' }}
                        >
                            <div>
                                <IoChevronDownOutline size="4.5rem" />
                            </div>
                        </Button>
                    </div>
                )}
                <div style={{ height: '15rem' }} />
            </div>
        </div>
    );
};

export const OppsStatusKanbanBoardMobile = ({
    stack: parentStack,
    oppTracking,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
    opps,
}) => {
    const stack = R.append(PHASES_KANBAN_BOARD, parentStack);
    const { node: project } = useNodeFromVar(stack, PROJECT_ID_KEY);
    const oppStatusses = R.propOr([], 'oppStatusTypes', project);

    const containerRef = useRef();
    const scrollX = value => {
        const scrollSpeed = 3;
        const distance = value * scrollSpeed;
        if (containerRef.current) {
            const prev = containerRef.current.scrollLeft;
            containerRef.current.scrollLeft = distance + prev;
        }
    };
    const [draggedOverColumn, setDraggedOverColumn] = useState();

    return (
        <div className="kanban-container-mobile fader" ref={containerRef}>
            <OnlyOnDrag
                renderTrue={() => (
                    <>
                        <div
                            style={{
                                position: 'absolute',
                                left: 0,
                                height: '90vh',
                                width: 100,
                            }}
                            onDragOver={() => scrollX(-1)}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                right: 0,
                                height: '90vh',
                                width: 100,
                            }}
                            onDragOver={() => {
                                scrollX(1);
                            }}
                        />
                    </>
                )}
            />
            {R.map(status => (
                <OppStatusBucket
                    key={status}
                    stack={stack}
                    status={status}
                    sortedTops={opps}
                    oppTracking={oppTracking}
                    canAdminProject={canAdminProject}
                    canWriteProject={canWriteProject}
                    subprojectId={subprojectId}
                    editMessage={editMessage}
                    userName={userName}
                    draggedOverColumn={draggedOverColumn}
                    setDraggedOverColumn={setDraggedOverColumn}
                />
            ))(oppStatusses)}
        </div>
    );
};

const OppStatusBucket = ({
    stack,
    status,
    sortedTops,
    oppTracking,
    canAdminProject,
    canWriteProject,
    subprojectId,
    editMessage,
    userName,
    draggedOverColumn,
    setDraggedOverColumn,
}) => {
    const [search, setSearch] = useState(false);
    const [searchString, setSearchString] = useState('');
    const inputRef = useRef();
    useEffect(() => {
        const timer = search && window.setTimeout(() => inputRef.current.focus(), 600);
        return () => {
            timer && window.clearTimeout(timer);
        };
    }, [search]);

    const topsSearch = sString => {
        const string = sString.replace(/[^a-zA-Z0-9" "]+[u00C0-u017F]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.compose(
                R.filter(
                    c => c.top.description && c.top.description.toUpperCase().search(string) !== -1,
                ),
            )(sortedTops);
        }
        return sortedTops;
    };

    const setToast = useToast(PHASES_KANBAN_BOARD);

    const save = (startText, endText) => {
        const toastConfig = {
            textOnStart: startText || 'Saving data...',
            textOnSuccess: endText || 'Succesfully saved...',
        };
        const push = createOfflinePush(stack, setToast, toastConfig);
        return push();
    };

    const createHistory = useCreateHistory(stack);

    const topsCount = R.length(
        R.filter(top => R.pathOr('Not Assigned', ['top', 'status'], top) === status)(sortedTops),
    );
    const [noOfRenderedTops, setNoOfRenderedTops] = useState(10);
    const bucketTops = R.filter(
        action => R.pathOr('Not assigned', ['top', 'status'], action) === status,
    )(topsSearch(searchString));
    const topsToDisplay = R.slice(0, noOfRenderedTops, bucketTops);
    return (
        <div
            className="board-element-mobile"
            style={
                draggedOverColumn === status
                    ? {
                          boxShadow: '0 5px 8px -2px var(--corporate-color-13)',
                          borderColor: 'var(--corporate-color-13)',
                      }
                    : {}
            }
            onDrop={async ev => {
                if (subprojectId && canWriteProject) {
                    const transferedTop = ev.dataTransfer.getData('top');
                    const top = JSON.parse(transferedTop);

                    if (top?.top?.phase !== status && status !== 'Not Assigned') {
                        await createHistory([top], { newStatus: status });
                        await save();
                    }
                } else editMessage('Select a subproject to move this action...');
                setDraggedOverColumn();
            }}
            onDragOver={ev => ev.preventDefault()}
            onDragEnter={() => {
                setDraggedOverColumn(status);
            }}
            onDragEnd={() => setDraggedOverColumn()}
        >
            <div className="header">
                {search ? (
                    <div className="animated-searchbar-mobile">
                        <Button
                            onClick={() => setSearch(!search)}
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <IoChevronForwardOutline size="4rem" />
                        </Button>
                        <input
                            ref={inputRef}
                            placeholder={`search ${status.toLowerCase()} opportunities...`}
                            value={searchString}
                            onChange={e => setSearchString(e.target.value)}
                        />
                        <Button
                            style={{ marginLeft: 3, backgroundColor: 'transparent' }}
                            onClick={() => setSearchString('')}
                        >
                            <IoTrashOutline size="3rem" />
                        </Button>
                    </div>
                ) : (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                className="count"
                                style={{
                                    color: topsCount === 0 && 'white',
                                    backgroundColor:
                                        draggedOverColumn === status
                                            ? 'var(--corporate-color-13-light)'
                                            : topsCount === 0 && 'darkgray',
                                }}
                            >
                                {topsCount}
                            </div>
                            <div
                                className="status"
                                style={
                                    draggedOverColumn === status
                                        ? { color: 'var(--corporate-color-13)' }
                                        : {}
                                }
                            >
                                {status}
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Button
                                style={{ marginLeft: 6, backgroundColor: 'transparent' }}
                                onClick={() => setSearch(true)}
                            >
                                <IoSearchOutline
                                    size="4.5rem"
                                    color={
                                        draggedOverColumn === status
                                            ? 'var(--corporate-color-13)'
                                            : undefined
                                    }
                                />
                            </Button>
                        </div>
                    </>
                )}
            </div>
            <div className="content">
                {R.map(top => (
                    <ListRow
                        key={top.top.id}
                        style={{
                            margin: '0.5rem 1rem 3rem 1rem',
                            border: '1px solid var(--color-3)',
                            boxSizing: 'border-box',
                        }}
                        draggable={canWriteProject}
                        onDrag={ev => {
                            const topToTransfer = JSON.stringify(top);
                            ev.dataTransfer.setData('top', topToTransfer);
                            top.top.type === 'a' && ev.dataTransfer.setData('action', true);
                        }}
                    >
                        <div className="top-search-row" style={{ position: 'relative' }}>
                            <TopButtonRowMobile
                                top={top.top}
                                responsible={top.responsible}
                                previousVersions={top.previousVersions}
                                canAdminProject={canAdminProject}
                                canWriteProject={canWriteProject}
                                subprojectId={subprojectId}
                                stack={stack}
                                editMessage={editMessage}
                                userName={userName}
                                oppTracking={oppTracking}
                                meetingId={top.meetingId}
                                protocolId={top.protocolId}
                                kanban
                            />
                            <TopMobile
                                noButton
                                stack={stack}
                                meetingId={top.meetingId}
                                topId={R.path(['top', 'id'], top)}
                                protocolId={top.protocolId}
                            />
                        </div>
                    </ListRow>
                ))(topsToDisplay)}
                {R.length(bucketTops) > 10 && R.length(bucketTops) > noOfRenderedTops && (
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        <Button
                            className="show-more-tops-button-mobile"
                            onClick={() => setNoOfRenderedTops(noOfRenderedTops + 10)}
                            style={{ backgroundColor: 'white' }}
                        >
                            <div>
                                <IoChevronDownOutline size="4.5rem" />
                            </div>
                        </Button>
                    </div>
                )}
                <div style={{ height: '15rem' }} />
            </div>
        </div>
    );
};

const OnDrag = ({ top, connectTops }) => {
    const [draggedOverTop, setDraggedOverTop] = useState({ id: '', side: '' });
    return (
        <OnlyOnActionDrag
            renderTrue={() => (
                <>
                    <div
                        className="prev-drop-indicator"
                        style={
                            draggedOverTop.id === top.top.id && draggedOverTop.side === 'left'
                                ? { backgroundColor: 'var(--corporate-color-14-light)' }
                                : {}
                        }
                        onDrop={ev => {
                            const transferedTop = ev.dataTransfer.getData('top');
                            const action = JSON.parse(transferedTop);
                            if (action.top.id !== top.top.id) {
                                connectTops(action, top);
                            }
                            ev.stopPropagation();
                        }}
                        onDragOver={ev => ev.preventDefault()}
                        onDragEnter={() =>
                            top.top.id !== draggedOverTop.id &&
                            setDraggedOverTop({ id: top.top.id, side: 'left' })
                        }
                        onDragLeave={() => setDraggedOverTop({ id: '', side: '' })}
                    >
                        <p className="drop-info">Link as predecessor</p>
                    </div>
                    <div
                        className="next-drop-indicator"
                        style={
                            draggedOverTop.id === top.top.id && draggedOverTop.side === 'right'
                                ? { backgroundColor: 'var(--corporate-color-13-light)' }
                                : {}
                        }
                        onDrop={ev => {
                            const transferedTop = ev.dataTransfer.getData('top');
                            const action = JSON.parse(transferedTop);
                            if (action.top.id !== top.top.id) {
                                connectTops(top, action);
                            }
                            ev.stopPropagation();
                        }}
                        onDragOver={ev => ev.preventDefault()}
                        onDragEnter={() =>
                            top.top.id !== draggedOverTop.id &&
                            setDraggedOverTop({ id: top.top.id, side: 'right' })
                        }
                        onDragLeave={() => setDraggedOverTop({ id: '', side: '' })}
                    >
                        <p className="drop-info">Link as successor</p>
                    </div>
                </>
            )}
        />
    );
};
