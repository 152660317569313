import React, { useState } from 'react';
import * as R from 'ramda';
import { GlobalItemsCountMobile } from '../Elements/GlobalItemsCountMobile';
import { GlobalTopsListMobile } from '../Elements/GlobalTopsListMobile';
import {
    ALL_ACTION_TYPES,
    OPP_TRACKING_GLOBAL,
    useGraph,
    USER_TOPS,
} from '../../common/hooks.config.new';
import { GlobalOppsAreaMobile } from '../Elements/GlobalOppsAreaMobile';
import { GlobalActionsAreaMobile } from '../Elements/GlobalActionsAreaMobile';
import { GLobalProjectsOverviewMobile } from '../Elements/GlobalProjectsOverviewMobile';
import { GlobalTableMobile } from '../Elements/GlobalTableMobile';
import { GlobalMenu } from '../Elements/GlobalMenu';

export const GlobalDashboardMobileContent = ({ stack }) => {
    const [dashboardTops, setDashboardTops] = useState([]);
    const [dashboardState, setDashboardState] = useState('dashboard');

    switch (dashboardState) {
        case 'dashboard':
            return (
                <GlobalUserOverview
                    stack={stack}
                    setDashboardTops={setDashboardTops}
                    setDashboardState={setDashboardState}
                />
            );
        case 'projects':
            return (
                <GLobalProjectsOverviewMobile
                    stack={stack}
                    setDashboardTops={setDashboardTops}
                    setDashboardState={setDashboardState}
                />
            );
        case 'tops':
            return (
                <GlobalTopsListMobile
                    stack={stack}
                    setDashboardTops={setDashboardTops}
                    setDashboardState={setDashboardState}
                    dashboardTops={dashboardTops}
                />
            );
        default:
            return (
                <GlobalUserOverview
                    stack={stack}
                    setDashboardTops={setDashboardTops}
                    setDashboardState={setDashboardState}
                />
            );
    }
};

const GlobalUserOverview = ({ stack, setDashboardTops, setDashboardState }) => {
    const { useVar } = useGraph(...stack);
    const [projectsUserTops] = useVar(USER_TOPS);
    const [allActionStatusTypes] = useVar(ALL_ACTION_TYPES);
    const [oppTrackingGlobal] = useVar(OPP_TRACKING_GLOBAL);
    const usersOrgIDsWithOppTracking = R.compose(
        R.filter(o => o),
        R.map(org => R.pathOr('', ['node', 'orgId'], org)),
    )(oppTrackingGlobal);

    const userCanTrackOpps = R.length(oppTrackingGlobal) > 0;
    const projectsCount = R.length(R.keys(projectsUserTops));

    const allActions = R.compose(
        R.unnest,
        R.map(R.pathOr({}, ['tops', 'actions'])),
        R.values,
    )(projectsUserTops);
    const allCriticalActions = R.compose(
        R.unnest,
        R.map(R.pathOr({}, ['tops', 'criticalActions'])),
        R.values,
    )(projectsUserTops);

    // filter opps from orgs without tracking
    const opps = R.compose(
        R.unnest,
        R.map(R.pathOr({}, ['tops', 'opps'])),
        R.values,
    )(projectsUserTops);

    // check trackingOpp nodes pull in GDB
    // const trackingOpps = R.filter(top => R.includes(top.orgId, usersOrgIDsWithOppTracking))(opps);
    const allOpps = opps;

    const criticalOpps = R.compose(
        R.unnest,
        R.map(R.pathOr({}, ['tops', 'criticalOpps'])),
        R.values,
    )(projectsUserTops);
    const trackingCriticalOpps = R.filter(top => R.includes(top.orgId, usersOrgIDsWithOppTracking))(
        criticalOpps,
    );
    const allCriticalOpps =
        R.length(usersOrgIDsWithOppTracking) === 0 ? criticalOpps : trackingCriticalOpps;

    const chartedOpps = {
        tops: [
            { type: 'all', tops: R.without(allCriticalOpps, allOpps) },
            { type: 'critical', tops: allCriticalOpps },
        ],
    };

    return (
        <div className="mobile-screen-container fader">
            <GlobalItemsCountMobile
                setDashboardState={setDashboardState}
                setDashboardTops={setDashboardTops}
                projectsCount={projectsCount}
                allActions={allActions}
                allOpps={allOpps}
                userCanTrackOpps={userCanTrackOpps}
            />
            {userCanTrackOpps ? (
                <GlobalOppsAreaMobile
                    stack={stack}
                    chartedOpps={chartedOpps}
                    allCriticalOpps={allCriticalOpps}
                    setDashboardState={setDashboardState}
                    setDashboardTops={setDashboardTops}
                />
            ) : (
                <div style={{ height: '7.5vh' }} />
            )}
            <GlobalTableMobile
                stack={stack}
                projectsUserTops={projectsUserTops}
                allActions={allActions}
                allCriticalActions={allCriticalActions}
                allOpps={allOpps}
                allCriticalOpps={allCriticalOpps}
                setDashboardState={setDashboardState}
                setDashboardTops={setDashboardTops}
                userCanTrackOpps={userCanTrackOpps}
            />
            <GlobalActionsAreaMobile
                stack={stack}
                allActions={allActions}
                setDashboardState={setDashboardState}
                setDashboardTops={setDashboardTops}
                allActionStatusTypes={allActionStatusTypes}
            />
            <div style={{ height: '20vh' }} />
            <GlobalMenu />
        </div>
    );
};
