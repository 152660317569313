import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import { IoCreateOutline } from 'react-icons/io5';
import {
    EDIT_TOP_MODAL,
    store,
    useGraph,
    TOPS_TO_UPDATE_KEY,
    MAIN,
    TOP_ATTACHMENT_EDGE,
    SUBPROJECT_ID_KEY,
} from '../../common/hooks.config.new';
import { useModal, useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { Toast } from '../Elements/Toast';
import { ListRow } from '../Common/ListRow';
import { ProtocolTop } from '../Elements/ProtocolScreen/ProtocolTop';
import { useToast } from '../../hooks/useToast';
import { TopButtonRow } from '../Elements/TopButtonRow';
import { SaveButton } from '../Elements/SaveButton';
import { LoadAttachments } from '../Elements/HasAttachments';
import { useCreateHistory } from '../../hooks/useCreateHistory';
import { AttachmentsModal, MODAL_ATTACHMENTS_EDIT_TOPS } from './AttachmentsModal';
import { refreshAttachments, useAttachmentFns } from '../../hooks/useAttachments';

export const EditTopsModal = ({ stack: parentStack }) => {
    const [isOpen] = useModalState('editTops');
    const stack = R.append(EDIT_TOP_MODAL, parentStack);
    const { clear } = useGraph(...stack);
    return (
        <div className="modal-container-compensation">
            <Modal
                name="editTops"
                style={{
                    height: '90vh',
                    top: '5vh',
                    width: '90vw',
                    left: '5vw',
                }}
                close={() => {
                    store.setVar(MAIN)(TOPS_TO_UPDATE_KEY, {});
                    clear();
                }}
                topbar={<IoCreateOutline size={24} color="var(--corporate-color-7)" />}
            >
                {isOpen ? <EditTopModalContent stack={stack} /> : null}
            </Modal>
        </div>
    );
};

const EditTopModalContent = ({ stack }) => {
    const [newTops, setNewTops] = useState([]);
    const topsToUpdate = R.values(store.getVar(...stack)(TOPS_TO_UPDATE_KEY));

    const { useVar, useNode } = useGraph(...stack);
    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [selectedTop] = useModalState('editTops');
    const selectedTopBag = useNode(selectedTop.id);
    const selectedTopBusinessId = R.path(['businessId'], selectedTopBag);

    const createHistory = useCreateHistory(stack, { setNewTops });

    const promiseRef = useRef();
    useEffect(() => {
        const promise = createHistory(
            R.length(topsToUpdate) === 0 ? [selectedTop.top] : topsToUpdate,
        );
        promiseRef.current = promise;
    }, []);

    const setToast = useToast('app');

    const { save: _save } = useAttachmentFns(stack);

    const save = async () => {
        promiseRef.current && (await promiseRef.current);
        setToast('spinner', 'Saving data...', 5000);
        await _save();
        setToast('success', 'Succesfully saved', 5000);

        await Promise.all(
            newTops.map(t => refreshAttachments(stack, t.newId, TOP_ATTACHMENT_EDGE, true)),
        );
    };

    const openAddAttachmentsModal = useModal(MODAL_ATTACHMENTS_EDIT_TOPS);

    return (
        <>
            <div className="columns">
                <div>
                    <div className="modal-headline">
                        <div>
                            {R.length(topsToUpdate) > 0
                                ? 'Edit TOPs'
                                : `Edit Top: ${selectedTopBusinessId}`}
                        </div>
                    </div>
                    {R.map(t => (
                        <ListRow
                            key={t.newId}
                            style={{ boxShadow: '0px 0px 10px 0px var(--shadow-color-light)' }}
                        >
                            <div className="top-search-row">
                                <TopButtonRow
                                    top={t.top}
                                    stack={stack}
                                    noEdit
                                    onAttachmentClick={() =>
                                        openAddAttachmentsModal({
                                            fromId: t.newId,
                                            edgeTypes: TOP_ATTACHMENT_EDGE,
                                            inheritFromId: subprojectId,
                                            shouldPush: false,
                                        })
                                    }
                                />
                                <ProtocolTop
                                    stack={stack}
                                    topId={t.newId}
                                    inheritFromId={subprojectId}
                                    editable
                                    noButton
                                    noId
                                    allowDrag
                                />
                                <TopAttachments stack={stack} topId={t.newId} />
                            </div>
                        </ListRow>
                    ))(newTops)}
                </div>
            </div>
            <SaveButton save={save} />
            <Toast name="editTop" />
            <AttachmentsModal stack={stack} name={MODAL_ATTACHMENTS_EDIT_TOPS} />
        </>
    );
};

const TopAttachments = ({ stack, topId }) => {
    const { useNode } = useGraph(...stack);
    const top = useNode(topId);

    return <LoadAttachments stack={stack} node={top} edgeTypes={TOP_ATTACHMENT_EDGE} />;
};
