import React from 'react';
import * as R from 'ramda';
import { IoCreateOutline } from 'react-icons/io5';
import { useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { FancyCheckbox } from '../Common/Controls';
import { DateField, TextField } from '../Common/Fields';
import { MILESTONE_ID_KEY } from '../../common/hooks.config.new';
import { useNodeFromVar } from '../../hooks/useNode';
import { ChoiceDropdownField } from '../Elements/ChoiceDropdownField';
import { PROJECT_PHASE_CHOICES } from '../../hooks/useChoices';
import { SaveButton } from '../Elements/SaveButton';

export const EditProjectMilestoneModal = ({ stack, save, update }) => {
    const [isOpen, close] = useModalState('edit-project-milestone');
    return (
        <Modal
            name="edit-project-milestone"
            style={{
                height: '32vh',
                top: '34vh',
                width: '50vw',
                left: '25vw',
            }}
            topbar={<IoCreateOutline size={26} color="var(--corporate-color-7)" />}
        >
            {isOpen ? (
                <ModalContent stack={stack} save={save} update={update} close={close} />
            ) : null}
        </Modal>
    );
};

const ModalContent = ({ stack, update, save, close }) => {
    const { node: milestone } = useNodeFromVar(stack, MILESTONE_ID_KEY);

    return (
        <div className="columns">
            <div>
                <div className="modal-headline">
                    <div>Edit milestone</div>
                </div>
                <TextField
                    label="Milestone name"
                    value={R.propOr('', 'name', milestone)}
                    onChange={value => update(R.assoc('name', value, milestone))}
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <DateField
                        className="medium"
                        label="Due Date"
                        value={R.propOr('', 'dueDate', milestone)}
                        locale="en-gb"
                        onChange={value => update(R.assoc('dueDate', value, milestone))}
                        editable
                    />
                    <ChoiceDropdownField
                        stack={stack}
                        choiceType={PROJECT_PHASE_CHOICES}
                        label="Project phase"
                        value={R.propOr('', 'phase', milestone)}
                        editable
                        onChange={option => update(R.assoc('phase', option.value, milestone))}
                    />
                    <FancyCheckbox
                        placeholderStyle={{ color: 'var(--corporate-color-2)' }}
                        label="Phases final Milestone"
                        onChange={() => update(R.assoc('final', !milestone.final, milestone))}
                        value={R.propOr(false, 'final', milestone)}
                    />
                </div>
                <SaveButton save={save} close={close} />
            </div>
        </div>
    );
};
