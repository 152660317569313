import { useMemo } from 'react';
import { useActionStatuses } from './useActionStatuses';
import { colors } from '../../../../common/consts';

const getHistoryCount = action => (action.previousVersions?.length || 0) + 1;

const countHistories = (actions, statusTypes) => {
    if (!actions) return { labels: [], data: [], colors: [] };

    const sortedActions = actions.sort((a, b) => getHistoryCount(b) - getHistoryCount(a));

    const bids = sortedActions.map(action => action.businessId);
    const labels = bids.map(label => {
        const localLabel = label || '';
        const splitted = localLabel.split('-');
        const newLabel = `${splitted[1]}-${splitted[2]}-${splitted[5]}${splitted[6]}`;
        const newLabelDashboard = `${splitted[1]}-DB-${(splitted[4] || '').toUpperCase()}${
            splitted[5]
        }`;
        if (splitted[2] === 'Dashboard') return newLabelDashboard;
        return newLabel;
    });
    const data = sortedActions.map(getHistoryCount);
    const statuses = sortedActions.map(action => action?.top?.status);
    const _colors = statuses.map(status => {
        const index = statusTypes.indexOf(status);
        return colors.at(index % colors.length);
    });

    return {
        labels,
        bids,
        data,
        statuses,
        colors: _colors,
    };
};

export const useActionHistoryChart = (stack, actions) => {
    const statusTypes = useActionStatuses(stack);

    return useMemo(() => countHistories(actions, statusTypes), [actions]);
};
