import React from 'react';
import * as R from 'ramda';
import {
    IoAddOutline,
    IoCaretDownOutline,
    IoCaretUpOutline,
    IoPersonAddOutline,
    IoTodayOutline,
} from 'react-icons/io5';
import { Meeting } from './Meeting';
import { MeetingAttendees } from './MeetingAttendees';
import { MeetingTops } from './MeetingTops';
import { Button } from '../../Common/Controls';
import { Spacer } from '../Spacer';

export const MeetingContent = ({
    stack,
    meetingId,
    projectId,
    subprojectId,
    meeting,
    openContactsModal,
    openAddDashboardTopsModal,
    showAttendees,
    attendeeCount,
    filter,
    setFilter,
}) => {
    const [toggleDropzone, setToggleDropzone] = React.useState(false);
    return (
        <>
            <MeetingHeadline
                toggleDropzone={toggleDropzone}
                setToggleDropzone={setToggleDropzone}
            />
            <div className="meeting-header">
                <Meeting
                    stack={stack}
                    meeting={meeting}
                    subprojectId={subprojectId}
                    projectId={projectId}
                    toggleDropzone={toggleDropzone}
                    setToggleDropzone={setToggleDropzone}
                />
            </div>
            <AttendeesHeadline openContactsModal={openContactsModal} />
            {showAttendees && attendeeCount > 0 && (
                <div className="meeting-header" style={{ paddingBottom: 0 }}>
                    <MeetingAttendees
                        stack={stack}
                        meetingId={meetingId}
                        openContactsModal={openContactsModal}
                        showAttendees={showAttendees}
                    />
                </div>
            )}
            {meeting.type && meeting.businessId ? (
                <>
                    <ContentHeadline
                        meetingId={meetingId}
                        subprojectId={subprojectId}
                        openAddDashboardTopsModal={openAddDashboardTopsModal}
                        filter={filter}
                        setFilter={setFilter}
                    />
                    <MeetingTops stack={stack} meetingId={meetingId} inheritFromId={subprojectId} />
                    <Spacer />
                </>
            ) : (
                <div className="protocol-notice">
                    Select a meeting type in the dropdown menu at the top left to create a meeting
                    ID
                </div>
            )}
        </>
    );
};

const MeetingHeadline = ({ toggleDropzone, setToggleDropzone }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            margin: '18 0 6 0',
            padding: '0 24 0 24',
        }}
    >
        <div className="meeting-area">Meeting</div>
        <div
            style={{
                flex: 1,
                height: 1,
                margin: '0 6 0 12',
                backgroundColor: 'var(--corporate-color-1-light4)',
            }}
        />

        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '12 0 12 0',
            }}
        >
            <Button
                onClick={() => {
                    setToggleDropzone(!toggleDropzone);
                }}
            >
                <div className="button-icon">
                    <IoTodayOutline size={23} />
                    <p>{toggleDropzone ? 'Close dropzone' : 'Import ics file'}</p>
                </div>
            </Button>
        </div>
    </div>
);

const AttendeesHeadline = ({ openContactsModal }) => (
    <div style={{ display: 'flex', alignItems: 'center', padding: '0 24 0 24' }}>
        <div className="meeting-area">Attendees</div>
        <div
            style={{
                flex: 1,
                height: 1,
                margin: '0 6 0 12',
                backgroundColor: 'var(--corporate-color-1-light4)',
            }}
        />
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '12 0 12 0',
            }}
        >
            <Button onClick={openContactsModal}>
                <div className="button-icon">
                    <IoPersonAddOutline size={23} />
                    <p>Manage Attendees</p>
                </div>
            </Button>
        </div>
    </div>
);

const ContentHeadline = ({
    meetingId,
    subprojectId,
    openAddDashboardTopsModal,
    filter,
    setFilter,
}) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            margin: '12 0 24 0',
            padding: '0 24 0 24',
        }}
    >
        <div className="meeting-area">Content</div>
        <div
            style={{
                flex: 1,
                height: 1,
                marginLeft: 12,
                backgroundColor: 'var(--corporate-color-1-light4)',
            }}
        />
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}
        >
            <Button
                onClick={() =>
                    openAddDashboardTopsModal({
                        meetingId,
                        subprojectId,
                    })
                }
                style={{ marginRight: 6 }}
            >
                <div className="button-icon">
                    <IoAddOutline size={23} />
                    <p>Add dashboard items</p>
                </div>
            </Button>
            <Button
                onClick={() => {
                    setFilter({
                        ...filter,
                        showHistories: !filter.showHistories,
                    });
                }}
            >
                {R.propOr(false, 'showHistories', filter) ? (
                    <div className="button-icon">
                        <IoCaretUpOutline size={20} />
                        <p>Hide Histories</p>
                    </div>
                ) : (
                    <div className="button-icon">
                        <IoCaretDownOutline size={20} />
                        <p>Show Histories</p>
                    </div>
                )}
            </Button>
        </div>
    </div>
);
