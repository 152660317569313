export const colors = [
    '#E3D0A1',
    '#64A6C1',
    '#A5668B',
    '#FFD166',
    '#003459',
    '#96BACE',
    '#0ea700e1',
    '#007EA7',
    '#70163C',
    '#E3D0A1CC',
    '#64A6C1CC',
    '#A5668BCC',
    '#FFD166CC',
    '#003459CC',
    '#96BACECC',
    '#0ea700e1CC',
    '#007EA7CC',
    '#70163CCC',
    '#E3D0A166',
    '#64A6C166',
    '#A5668B66',
    '#FFD16666',
    '#00345966',
    '#96BACE66',
    '#007EA766',
    '#70163C66',
];
