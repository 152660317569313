import React, { useState } from 'react';
import { DASHBOARD, MAIN, OFFLINE } from '../../common/hooks.config.new';
import { Screen } from '../Common/Screen';
import './DashboardScreen.css';
import { GlobalDashboardTopBar } from '../Elements/GlobalDashboard/GlobalDashboardTopBar';
import '../Elements/ChartsArea.css';
import { ProjectsOverview } from '../Elements/GlobalDashboard/ProjectsOverview';
import { GlobalDashboardCalculator } from '../Elements/GlobalDashboard/GlobalDashboardCalculator';
import { Loader } from '../Elements/Loader';
import { useModalState } from '../../hooks/useModal';
import { TopsList } from '../Elements/GlobalDashboard/GlobalElements';
import { GlobalUserOverview } from '../Elements/GlobalDashboard/GlobalUserOverview';

export const GlobalDashboardScreen = () => {
    const stack = [MAIN, OFFLINE, DASHBOARD];
    const [isLoading] = useModalState('loader');
    return (
        <div className="screen-fader">
            <Screen topbar={<GlobalDashboardTopBar stack={stack} />} lpheight={60}>
                <GlobalDashboardCalculator stack={stack} />
                {isLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            height: '80vh',
                            alignItems: 'center',
                        }}
                    >
                        <Loader />
                    </div>
                ) : (
                    <GlobalDashboardContent stack={stack} />
                )}
            </Screen>
        </div>
    );
};

const GlobalDashboardContent = ({ stack }) => {
    const [dashboardTops, setDashboardTops] = useState([]);
    const [dashboardState, setDashboardState] = useState('dashboard');

    switch (dashboardState) {
        case 'dashboard':
            return (
                <GlobalUserOverview
                    stack={stack}
                    setDashboardTops={setDashboardTops}
                    setDashboardState={setDashboardState}
                />
            );
        case 'projects':
            return (
                <ProjectsOverview
                    stack={stack}
                    setDashboardTops={setDashboardTops}
                    setDashboardState={setDashboardState}
                />
            );
        case 'tops':
            return (
                <TopsList
                    stack={stack}
                    setDashboardTops={setDashboardTops}
                    setDashboardState={setDashboardState}
                    dashboardTops={dashboardTops}
                />
            );
        default:
            return (
                <GlobalUserOverview
                    stack={stack}
                    setDashboardTops={setDashboardTops}
                    setDashboardState={setDashboardState}
                />
            );
    }
};
