import React, { useRef } from 'react';
import * as R from 'ramda';
import { Doughnut, getElementAtEvent } from 'react-chartjs-2';
import { useActionStatusesDonut } from '../hooks/useActionStatusesDonut';
import { colors } from '../../../../common/consts';
import { DASHBOARD_FILTER_KEY, useGraph } from '../../../../common/hooks.config.new';

const donutOptions = {
    aspectRatio: 1,
    offset: 1,
    responsive: false,
    cutout: '85%',
    radius: '80%',
    animation: false,
    plugins: {
        legend: {
            display: false,
        },
    },
};

export const ActionStatusesDonut = ({ stack, actions }) => {
    const { data, labels } = useActionStatusesDonut(stack, actions);
    const total = data.reduce((acc, curr) => acc + curr, 0);
    const chartRef = useRef(null);
    const donutData = {
        labels,
        datasets: [
            {
                label: 'Reference',
                data,
                backgroundColor: colors,
                borderRadius: 25,
            },
        ],
    };

    const { useVar } = useGraph(...stack);
    const [filter, setFilter] = useVar(DASHBOARD_FILTER_KEY);

    return (
        <div style={{ flex: 1 }}>
            <div className="doughnut-container">
                <Doughnut
                    ref={chartRef}
                    data={donutData}
                    options={donutOptions}
                    onClick={event => {
                        const { index } = getElementAtEvent(chartRef.current, event)[0];

                        setFilter(
                            R.compose(
                                R.assoc('showTops', true),
                                R.assocPath(['topType', 'values'], [{ value: 'a', label: 'a' }]),
                                R.assocPath(
                                    ['actionStatus', 'values'],
                                    [{ value: labels[index], label: labels[index] }],
                                ),
                            )(filter),
                        );
                    }}
                    height={340}
                    width={340}
                />
                <div className="inner-text">
                    <p className="title" style={{ marginBottom: 0 }}>
                        Actions:
                    </p>
                    <p className="info" style={{ marginBottom: 0 }}>
                        {total}
                    </p>
                </div>
            </div>
        </div>
    );
};
