/* eslint-disable guard-for-in */
import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { BASE_STATE, MAIN, OFFLINE, store } from '../../common/hooks.config.new';
import { createOfflinePush } from '../../common/got-adjunct';
import { Button } from '../../Components/Common/Controls';
import { ClickAnimator } from './ClickAnimator';

export const OfflineSyncerMobile = ({
    graphNames = [],
    mergeToGraphName = MAIN,
    mergeToOfflineName = OFFLINE,
    onProgress,
    onSuccess,
    onError,
    children,
    global,
}) => {
    const hasOfflineData = useSelector(
        R.anyPass(R.map(graphName => R.path([BASE_STATE, graphName, 'graph']))(graphNames)),
    );

    const syncAll = async () => {
        let error;
        if (R.is(Array, graphNames)) {
            // eslint-disable-next-line no-restricted-syntax
            for (const graphName of graphNames) {
                if (R.is(String, graphName)) {
                    if (error) {
                        // if any previous pushes fail, merge higher graphs into offline in sequence
                        store.merge(graphName, mergeToOfflineName);
                        store.clear(graphName);
                    } else {
                        // if all previous pushes successful, attempt to push higher graphs in sequence
                        try {
                            if (graphName === mergeToOfflineName) {
                                // eslint-disable-next-line no-await-in-loop
                                await store.push(mergeToOfflineName, mergeToGraphName);
                            } else {
                                const push = createOfflinePush(
                                    [mergeToGraphName, graphName],
                                    undefined,
                                    { mergeToOfflineName },
                                );
                                // eslint-disable-next-line no-await-in-loop
                                await push();
                            }
                        } catch (e) {
                            error = e;
                        }
                    }
                }
            }
        }

        if (error) {
            throw error;
        }
    };

    const sync = async () => {
        onProgress && onProgress();
        return syncAll()
            .then(() => onSuccess && onSuccess())
            .catch(error => onError && onError(error));
    };

    return hasOfflineData ? (
        <div
            className="save-dashboard-button-container"
            style={{ alignItems: global && 'flex-end', paddingBottom: global && '3rem' }}
        >
            <ClickAnimator
                content={
                    <Button
                        onClick={sync}
                        className="save-dashboard-button"
                        style={{ backgroundColor: 'white' }}
                    >
                        {children}
                    </Button>
                }
                positioning={{ position: 'absolute', bottom: global ? '3rem' : '9rem' }}
            />
        </div>
    ) : null;
};
