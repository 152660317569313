import React from 'react';
import * as R from 'ramda';
import {
    ALL_ACTION_TYPES,
    OPP_TRACKING_GLOBAL,
    USER_TOPS,
    useGraph,
} from '../../../common/hooks.config.new';
import { ActionsArea, ItemsCount, OppsArea } from './GlobalElements';
import { GlobalTable } from './GlobalTable';

export const GlobalUserOverview = ({ stack, setDashboardTops, setDashboardState }) => {
    const { useVar } = useGraph(...stack);
    const [projectsUserTops] = useVar(USER_TOPS);
    const [allActionStatusTypes] = useVar(ALL_ACTION_TYPES);
    const [oppTrackingGlobal] = useVar(OPP_TRACKING_GLOBAL);
    // const usersOrgIDsWithOppTracking = R.compose(
    //     R.filter(o => o),
    //     R.map(org => R.pathOr('', ['node', 'orgId'], org)),
    // )(oppTrackingGlobal);

    const userCanTrackOpps = R.length(oppTrackingGlobal) > 0;
    const projectsCount = R.length(R.keys(projectsUserTops));

    const allActions = R.compose(
        R.unnest,
        R.map(R.pathOr({}, ['tops', 'actions'])),
        R.values,
    )(projectsUserTops);
    const allCriticalActions = R.compose(
        R.unnest,
        R.map(R.pathOr({}, ['tops', 'criticalActions'])),
        R.values,
    )(projectsUserTops);

    // filter opps from orgs without tracking
    const opps = R.compose(
        R.unnest,
        R.map(R.pathOr({}, ['tops', 'opps'])),
        R.values,
    )(projectsUserTops);

    // check trackingOpp nodes pull in GDB
    // const trackingOpps = R.filter(top => R.includes(top.orgId, usersOrgIDsWithOppTracking))(opps);
    const allOpps = opps;

    const criticalOpps = R.compose(
        R.unnest,
        R.map(R.pathOr({}, ['tops', 'criticalOpps'])),
        R.values,
    )(projectsUserTops);
    // const trackingCriticalOpps = R.filter(top => R.includes(top.orgId, usersOrgIDsWithOppTracking))(
    //     criticalOpps,
    // );
    // const allCriticalOpps =
    //     R.length(usersOrgIDsWithOppTracking) === 0 ? criticalOpps : trackingCriticalOpps;

    const chartedOpps = {
        tops: [
            { type: 'all', tops: R.without(criticalOpps, allOpps) },
            { type: 'critical', tops: criticalOpps },
        ],
    };

    return (
        <>
            <div className="global-dashboard-area" style={{ paddingTop: 12, paddingBottom: 12 }}>
                <ItemsCount
                    setDashboardState={setDashboardState}
                    setDashboardTops={setDashboardTops}
                    projectsCount={projectsCount}
                    allActions={allActions}
                    allOpps={allOpps}
                    userCanTrackOpps={userCanTrackOpps}
                />
            </div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                {userCanTrackOpps && (
                    <OppsArea
                        stack={stack}
                        chartedOpps={chartedOpps}
                        allCriticalOpps={criticalOpps}
                        setDashboardState={setDashboardState}
                        setDashboardTops={setDashboardTops}
                    />
                )}
                <GlobalTable
                    stack={stack}
                    projectsUserTops={projectsUserTops}
                    allActions={allActions}
                    allCriticalActions={allCriticalActions}
                    allOpps={allOpps}
                    allCriticalOpps={criticalOpps}
                    setDashboardState={setDashboardState}
                    setDashboardTops={setDashboardTops}
                    userCanTrackOpps={userCanTrackOpps}
                />
            </div>
            <div className="global-dashboard-area" style={{ marginBottom: 24, paddingTop: 0 }}>
                <ActionsArea
                    stack={stack}
                    allActions={allActions}
                    setDashboardState={setDashboardState}
                    setDashboardTops={setDashboardTops}
                    allActionStatusTypes={allActionStatusTypes}
                />
            </div>
        </>
    );
};
