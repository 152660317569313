import * as R from 'ramda';
import React from 'react';
import { IoCheckmarkOutline, IoCloseOutline, IoLockClosedOutline } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '../Common/Controls';
import { MiniLoader, MiniLoaderMobile } from './Loader';

export const Toast = ({ name, mobile }) => {
    const toast = useSelector(R.pathOr(undefined, ['toasts', name]), R.equals);
    const dispatch = useDispatch();
    return toast ? (
        <div style={{ position: 'relative', zIndex: 100000 }}>
            <div
                style={{
                    zIndex: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
                className={`toast${mobile ? '-mobile' : ''} ${toast.type}`}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {toast.type === 'spinner' ? (
                        <Loader mobile={mobile} />
                    ) : toast.type === 'success' ? (
                        <IoCheckmarkOutline
                            size={mobile ? '6rem' : 25}
                            style={{ marginRight: mobile ? '1rem' : 6 }}
                        />
                    ) : toast.type === 'error' ? (
                        <IoCloseOutline
                            size={mobile ? '6rem' : 25}
                            style={{ marginRight: mobile ? '1rem' : 6 }}
                        />
                    ) : toast.type === 'hint' ? (
                        <div>
                            <IoLockClosedOutline
                                size={mobile ? '5rem' : 25}
                                style={{ marginRight: mobile ? '1rem' : 6 }}
                            />
                        </div>
                    ) : null}
                    <p style={{ fontSize: mobile ? '3.5rem' : 16 }}>{toast.message}</p>
                </div>
                {mobile && (toast.type === 'success' || toast.type === 'hint') && (
                    <Button
                        style={{ backgroundColor: 'white' }}
                        onClick={() => dispatch({ type: 'HIDE_TOAST', payload: { name } })}
                    >
                        <IoCloseOutline
                            size="4.5rem"
                            color={
                                toast.type === 'success'
                                    ? 'var(--corporate-color-13-light2)'
                                    : 'var(--corporate-color-14-light)'
                            }
                        />
                    </Button>
                )}
            </div>
        </div>
    ) : null;
};

const Loader = ({ mobile }) => (mobile ? <MiniLoaderMobile /> : <MiniLoader />);
