import React, { useState } from 'react';
import { useModalState } from '../../../../hooks/useModal';
import { Modal } from '../../../Common/Modal';
import { ResponsibleStatusChart } from '../components/ResponsibleStatusChart';
import { useActionStatuses } from '../hooks/useActionStatuses';
import { LegendButton } from '../components/Legend';
import { colors } from '../../../../common/consts';

export const RESPONSIBLE_STATUS_MODAL = 'responsible-status-modal';

export const ResponsibleStatusModal = ({ stack, actions }) => {
    const [isOpen, close] = useModalState(RESPONSIBLE_STATUS_MODAL);

    return (
        <div className="modal-container-compensation">
            <Modal
                name={RESPONSIBLE_STATUS_MODAL}
                style={{
                    height: '90vh',
                    top: '5vh',
                    width: '95vw',
                    left: '2.5vw',
                }}
                close={close}
            >
                {isOpen ? <ModalContent stack={stack} actions={actions} close={close} /> : null}
            </Modal>
        </div>
    );
};

const toObj = (arr, val) => {
    const obj = {};
    for (let i = 0; i < arr.length; i += 1) {
        obj[arr[i]] = val;
    }
    return obj;
};

const filterActions = (actions, statusMap) =>
    actions.filter(action => {
        const status = action?.top?.status;
        return statusMap[status];
    });

const ModalContent = ({ stack, actions, close }) => {
    const statusTypes = useActionStatuses(stack);

    const [statusMap, setStatusMap] = useState(toObj(statusTypes, false));

    const noneActive = statusTypes.every(status => !statusMap[status]);
    const allActive = statusTypes.every(status => statusMap[status]);
    const filteredActions = noneActive || allActive ? actions : filterActions(actions, statusMap);

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    padding: '1rem 2rem',
                    gap: '1rem',
                }}
            >
                {statusTypes.map((status, i) => (
                    <LegendButton
                        active={statusMap[status]}
                        color={colors[i]}
                        onClick={() => {
                            setStatusMap({
                                ...statusMap,
                                [status]: !statusMap[status],
                            });
                        }}
                        status={status}
                    />
                ))}
                <LegendButton
                    active
                    onClick={() => {
                        setStatusMap(toObj(statusTypes, false));
                    }}
                    status="All"
                />
            </div>
            <div style={{ paddingLeft: '1rem', paddingRight: '3rem' }}>
                <ResponsibleStatusChart stack={stack} actions={filteredActions} onClick={close} />
            </div>
        </div>
    );
};
