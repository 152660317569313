import React from 'react';
import { IoExpand } from 'react-icons/io5';
import { Button } from '../../../Common/Controls';

export const ChartHeadline = ({ onClick, children }) => (
    <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1.5 }}
        // onMouseEnter={() => setHovered(true)}
        // onMouseLeave={() => setHovered(false)}
    >
        <div
            style={{ fontSize: '1.5rem', color: 'var(--corporate-color-2)' }}
            className="font-weight-100"
        >
            {children}
        </div>
        {onClick && (
            <Button
                onClick={() => {
                    onClick();
                }}
                className="hover-scale"
                style={{
                    // opacity: hovered ? 1 : 0,
                    // transition: 'opacity 0.3s',
                    backgroundColor: 'white',
                    border: '1px solid transparent',
                    boxSizing: 'borderBox',
                    marginLeft: 18,
                    boxShadow: '0 5px 8px -2px var(--corporate-color-1-light4)',
                }}
            >
                <IoExpand size={24} />
            </Button>
        )}
    </div>
);
