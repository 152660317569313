import { useState } from 'react';
import * as R from 'ramda';
import { useGraph } from '../common/hooks.config.new';
import { useUserEmail } from './useUserEmail';
import { pushRightsOverView } from '../common/got-adjunct';

const selectUser = R.propOr({}, 'user');
export const useRightsModal = (stack, nodeId, view) => {
    const { useRights } = useGraph(...stack);

    const gotRights = useRights(nodeId, selectUser);
    const [rightsPatches, setRightsPatches] = useState({});
    const rights = R.mergeDeepRight(gotRights, rightsPatches);

    const user = useUserEmail();
    const userRights = R.compose(
        R.sortBy(R.ifElse(R.propOr(false, 'isCurrentUser'), R.always('0000'), R.prop('user'))),
        R.filter(
            R.anyPass([
                R.propOr(false, 'read'),
                R.propOr(false, 'write'),
                R.propOr(false, 'admin'),
            ]),
        ),
        R.values,
        R.mapObjIndexed((val, key) => ({ ...val, user: key, isCurrentUser: key === user })),
    )(rights);

    const setUserRights = async (email, rightPatch) => {
        const prevPatch = R.propOr({}, email, rightsPatches);
        const newPatch = R.mergeDeepRight(prevPatch, rightPatch);
        setRightsPatches(R.assoc(email, newPatch));
    };

    const saveRights = () => pushRightsOverView(...stack)(view)(rightsPatches);

    return {
        userRights, setUserRights, saveRights,
    };
};
