import { setup } from '@gothub-team/got-react';
import { store as reduxStore } from '../redux/store';
import { API_DOMAIN_2, BACKEND_API_DOMAIN } from './config';
import { createProcubionApi } from './procubion-api';

export const BASE_STATE = 'got2';

export const { api, store, useGraph, createGraph } = setup({
    host: `https://${API_DOMAIN_2}`,
    // host: 'http://localhost:4000/dev',
    reduxStore,
    // adminMode: true,
    baseState: BASE_STATE,
    onError: err => {
        console.warn(err);
        throw err;
    },
});

export const procubionApi = createProcubionApi({
    host: `https://${BACKEND_API_DOMAIN}/`,
    // host: 'http://localhost:4000/dev/',
    getAuthToken: () => api.getCurrentSession().idToken,
});

// graph names
export const MAIN = 'main';
export const OFFLINE = 'offline';
export const CONTACTS = 'contacts';
export const PROTOCOL_MAIN = 'main-protocol';
export const DASHBOARD = 'dashboard';
export const PROTOCOL_VARS = 'protocol-vars';
export const PROTOCOL = 'protocol';
export const ATTACHMENT_MODAL_MAIN = 'attachment-modal-main';
export const ATTACHMENT_MODAL = 'attachment-modal';
export const ORGANIZATIONS_MODAL = 'organizations-modal';
export const PROJECTS_MODAL = 'projects-modal';
export const CONTACTS_MODAL = 'contacts-modal';
export const NEW_CONTACT_MODAL = 'new-contact-modal';
export const SCORE_CARD_MODAL = 'score-card-modal';
export const PROTOCOL_PDF_MODAL = 'protocol-pdf-modal';
export const EDIT_TOP_MODAL = 'edit-top-modal';
export const EXISTING_MEETINGS_MODAL = 'existing-meetings-modal';
export const PROJECT_ACTIONS_MODAL = 'project-actions-modal';
export const PROJECT_OPPS_MODAL = 'project-opps-modal';
export const CREATE_DASHBOARD_TOP_MODAL = 'create-dashboard-top-modal';
export const ACTIONS_KANBAN_BOARD = 'actions-kanban-board';
export const PHASES_KANBAN_BOARD = 'phases-kanban-board';
export const PROJECT_MILESTONES_MODAL = 'project-milestones-modal';
export const PROJECT_PHASES_MODAL = 'project-phases-modal';
export const MILESTONE_TOPS_MODAL = 'milestone-tops-modal';
export const TOP_LINKS_MODAL = 'top-links';
export const PHASE_TOPS_MODAL = 'phase-tops-modal';
export const CONNECT_ACTIONS_MODAL = 'connect-actions-modal';
export const DELETE_CONTACT_MODAL = 'delete-contact-modal';

// modals
export const FILTER_BAR = 'filter-bar';
export const ZIP_MODAL = 'zip-modal';
export const LOADED_PROJECTS = 'loaded-projects';
export const MEETING_ICS = 'meeting-ics';

// edges
export const ROOT_ORGANIZATION_EDGE = 'root/organization';
export const ORGANIZATION_PROJECT_EDGE = 'organization/project';
export const ORGANIZATION_OPPORTUNITY_TRACKING_EDGE = 'organization/opportunity-tracking';
export const PROJECT_SUBPROJECT_EDGE = 'project/subproject';
export const SUBPROJECT_MEETING_EDGE = 'subproject/meeting';
export const PROJECT_CONTACT_DIRECTORY_EDGE = 'project/contact-directory';
export const CONTACT_DIRECTORY_CONTACT_EDGE = 'contact-directory/contact';
export const MEETING_ATTENDEE_EDGE = 'meeting/attendee';
export const MEETING_TOP_EDGE = 'meeting/top';
export const MEETING_ATTACHMENT_EDGE = 'meeting/attachment';
export const TOP_RESPONSIBLE_EDGE = 'top/responsible';
export const TOP_ACTION_EDGE = 'top/action';
export const PROJECT_MILESTONE_EDGE = 'project/milestone';
export const MILESTONE_TOP_EDGE = 'milestone/top';
export const TOP_ATTACHMENT_EDGE = 'top/attachment';

// var keys
export const ORGANIZATION_ID_KEY = 'organizationId';
export const PROJECT_ID_KEY = 'projectId';
export const SUBPROJECT_ID_KEY = 'subprojectId';
export const MEETING_ID_KEY = 'meetingId';
export const PROJECT_MEETINGS_KEY = 'projectMeetings';
export const GROUPED_TOPS_KEY = 'groupedTops';
export const SORTED_GROUPED_TOPS_KEY = 'sortedGroupedTops';
export const TOP_ROWS_KEY = 'topRows';
export const TOP_HISTORIES_KEY = 'topHistories';
export const DASHBOARD_STATE_KEY = 'dashboardState';
export const DASHBOARD_FILTER_KEY = 'dashboardFilter';
export const TOPS_TO_UPDATE_KEY = 'topsToUpdate';
export const SEARCH_STRING_KEY = 'searchString';
export const USER_TOPS = 'userTops';
export const TOP_OPP = 'topOpp';
export const ALL_ACTION_TYPES = 'allActionTypes';
export const SHOW_TOPS_WITH_HISTORY = 'showTopsWithHistory';
export const OPP_TRACKING = 'oppTracking';
export const OPP_TRACKING_GLOBAL = 'oppTrackingGlobal';
export const CD_ID_KEY = 'contactDirectoryId';
export const MILESTONE_ID_KEY = 'phaseId';
export const CONTACT_ID_KEY = 'contactId';

// modals mobile
export const ATTACHMENT_MODAL_MAIN_MOBILE = 'attachment-modal-main-mobile';
export const ATTACHMENT_MODAL_MOBILE = 'attachment-modal-mobile';
export const ORGANIZATIONS_MODAL_MOBILE = 'organizations-modal-mobile';
export const PROJECTS_MODAL_MOBILE = 'projects-modal-mobile';
export const SUBPROJECTS_MODAL_MOBILE = 'subprojects-modal-mobile';
export const CONTACTS_MODAL_MOBILE = 'contacts-modal-mobile';
export const NEW_CONTACT_MODAL_MOBILE = 'new-contact-modal-mobile';
export const SCORE_CARD_MODAL_MOBILE = 'score-card-modal-mobile';
export const PROTOCOL_PDF_MODAL_MOBILE = 'protocol-pdf-modal-mobile';
export const EDIT_TOP_MODAL_MOBILE = 'edit-top-modal-mobile';
export const DRAFT_MEETINGS_MODAL_MOBILE = 'draft-meetings-modal-mobile';
export const SUBMITTED_MEETINGS_MODAL_MOBILE = 'submitted-meetings-modal-mobile';
export const TEMPLATES_MODAL_MOBILE = 'templates-modal-mobile';
export const PROJECT_ACTIONS_MODAL_MOBILE = 'project-actions-modal-mobile';
export const PROJECT_OPPS_MODAL_MOBILE = 'project-opps-modal-mobile';
export const CREATE_DASHBOARD_TOP_MODAL_MOBILE = 'create-dashboard-top-modal-mobile';
export const PROJECT_MILESTONES_MODAL_MOBILE = 'project-milestones-modal-mobile';
export const PROJECT_PHASES_MODAL_MOBILE = 'project-phases-modal-mobile';
export const MILESTONE_TOPS_MODAL_MOBILE = 'milestone-tops-modal-mobile';
export const TOP_LINKS_MODAL_MOBILE = 'top-links-mobile';
export const PHASE_TOPS_MODAL_MOBILE = 'phase-tops-modal-mobile';
export const CONNECT_ACTIONS_MODAL_MOBILE = 'connect-actions-modal-mobile';
export const EDIT_ORG_MODAL_MOBILE = 'edit-org-modal-mobile';
export const EDIT_PROJECT_MODAL_MOBILE = 'edit-project-modal-mobile';
export const EDIT_SUBPROJECT_MODAL_MOBILE = 'edit-subproject-modal-mobile';
export const EDIT_CONTACT_MODAL_MOBILE = 'edit-contact-modal-mobile';
export const EDIT_ATTENDEE_MODAL_MOBILE = 'edit-attendee-modal-mobile';
export const EDIT_DASHBOARD_TOP_MODAL_MOBILE = 'edit-dashboard-top-modal-mobile';
export const EDIT_PROTOCOL_TOP_MODAL_MOBILE = 'edit-protocol-top-modal-mobile';
export const PRINT_MODAL_MOBILE = 'print-modal-mobile';
export const SETTINGS_MODAL_MOBILE = 'settings-modal-mobile';

// PROPS

export const FILE_PROP_NAME = 'file';
