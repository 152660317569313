import React, { useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import { IoEllipsisHorizontalOutline, IoRemoveOutline } from 'react-icons/io5';
import {
    PHASE_TOPS_MODAL,
    OPP_TRACKING,
    PROJECT_ID_KEY,
    SORTED_GROUPED_TOPS_KEY,
    SUBPROJECT_ID_KEY,
    useGraph,
} from '../../common/hooks.config.new';
import { useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { OppActions } from '../Elements/OppActions';
import { Toast } from '../Elements/Toast';
import { constructNodeView } from '../../common/got-adjunct';
import { useToast } from '../../hooks/useToast';
import '../Screens/DashboardScreen.css';
import { useUserEmail } from '../../hooks/useUserEmail';
import { sortWithTopLinks } from '../../hooks/useTopLinks';
import { ItemsCountBar } from '../Elements/ProjectDashboard/ItemsCountBar';
import { formatShortDate } from '../../common/util';

export const PhaseTopsModal = ({ stack }) => {
    const [isOpen, close] = useModalState(PHASE_TOPS_MODAL);
    return (
        <Modal
            name={PHASE_TOPS_MODAL}
            style={{
                height: '87vh',
                top: '6.5vh',
                width: '85vw',
                left: '7.5vw',
            }}
            topbar={<IoEllipsisHorizontalOutline size={24} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <ModalContent stack={stack} close={close} /> : null}
        </Modal>
    );
};

const ModalContent = ({ stack: parentStack }) => {
    const stack = R.append(PHASE_TOPS_MODAL, parentStack);
    const { useVar, useView, pull } = useGraph(...stack);

    const [phase] = useModalState(PHASE_TOPS_MODAL);
    const [oppTracking] = useVar(OPP_TRACKING);

    const [showTopType, setShowTopType] = useState('a');

    const [{ tops: sortedGroupedTops = {} }] = useVar(SORTED_GROUPED_TOPS_KEY);
    const topRows = useMemo(
        () =>
            R.compose(
                R.sortBy(R.prop('businessId')),
                R.values,
                R.map(entries => ({
                    ...R.last(entries),
                    previousVersions: R.dropLast(1, entries),
                })),
            )(sortedGroupedTops),
        [sortedGroupedTops],
    );
    const tops = R.filter(top => top.top.phase === phase.name)(topRows);
    const filteredTops = useMemo(
        () => R.filter(top => (showTopType ? top.top.type === showTopType : true))(tops),
        [showTopType, tops],
    );
    const topInfos = useMemo(
        () =>
            R.compose(
                R.filter(R.identity),
                R.map(topInfo => ({
                    nodeId: topInfo.top.nodeId || '',
                    id: topInfo.top?.id,
                    top: topInfo,
                })),
                topBusinessIds => {
                    const pickedGroupedTops = R.pick(topBusinessIds, sortedGroupedTops);
                    return sortWithTopLinks(topBusinessIds, pickedGroupedTops);
                },
                R.map(top => top.businessId),
            )(filteredTops),
        [filteredTops, sortedGroupedTops],
    );

    const [projectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);
    const projectView = constructNodeView(projectId, { node: true, rights: true, files: true });
    useEffect(() => {
        projectId && pull(projectView);
    }, [projectId]);
    const { [projectId]: projectBag = {} } = useView(projectView);
    const user = useUserEmail();
    const canAdminProject = R.pathOr(false, ['rights', 'user', user, 'admin'], projectBag);
    const canWriteProject = R.pathOr(false, ['rights', 'user', user, 'write'], projectBag);

    const itemsCount = topType =>
        R.compose(
            R.length,
            R.filter(top => top.top.type === topType),
        )(R.values(tops));

    const setEditMessageToast = useToast('edit-message');
    // TODO change subproject in edit modal to tops sub
    const editMessage = () => {
        setEditMessageToast('error', 'Select a subproject to edit this item...', 5000);
    };

    return (
        <>
            <div className="columns" style={{ alignItems: 'flex-start' }}>
                <div style={{ flex: 2.5, marginRight: 15 }}>
                    <Headline phase={phase} />
                    <ItemsCountBar
                        showTopType={showTopType}
                        setShowTopType={setShowTopType}
                        itemsCount={itemsCount}
                    />
                    {topInfos.length > 0 && (
                        <OppActions
                            stack={stack}
                            actionBags={topInfos}
                            canWriteProject={canWriteProject}
                            canAdminProject={canAdminProject}
                            editMessage={editMessage}
                            subprojectId={subprojectId}
                            oppTracking={oppTracking}
                            showTopType={showTopType}
                            milestone
                        />
                    )}
                </div>
            </div>
            <Toast name="milestone-tops" />
        </>
    );
};

const Headline = ({ phase }) => (
    <div className="modal-headline" style={{ marginBottom: 24, alignItems: 'flex-start' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <p style={{ fontSize: 26, fontWeight: 'normal', whiteSpace: 'nowrap' }}>
                Project Phase
            </p>
            <IoRemoveOutline size={22} style={{ margin: '5 6 0 6' }} />
            <p style={{ fontSize: 26 }}>{phase.name}</p>
        </div>
        <div style={{ justifyContent: 'flex-end' }}>
            <p style={{ fontSize: 26, fontWeight: 'normal', marginRight: 12 }}>Timeframe:</p>
            {formatShortDate(phase.startDate)} - {formatShortDate(phase.endDate)}
        </div>
    </div>
);
