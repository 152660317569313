import React, { useMemo } from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useMeetingTops } from '../../../hooks/useMeetingTops';
import { ReadingViewTop } from './ReadingViewTop';
import { topHierarchy } from '../../../common/util';

export const ReadingView = ({ stack, meetingId }) => {
    const sortedTopBags = useMeetingTops(stack, meetingId);
    const hierarchy = useMemo(
        () => R.compose(topHierarchy, R.map(R.prop('node')))(sortedTopBags),
        [sortedTopBags],
    );

    return (
        <div
            style={{
                margin: 50,
                padding: '100 100',
                background: 'white',
                boxShadow: '0 0.25rem 1rem rgba(0,0,0,0.15)',
            }}
            className="fader slide-in"
        >
            <div
                style={{
                    display: 'flex',
                    fontWeight: 100,
                    fontSize: 34,
                    flex: 1,
                }}
            >
                Meeting Content
            </div>
            <div
                style={{
                    borderBottom: '2px solid black',
                    display: 'flex',
                    marginTop: 100,
                }}
            >
                <div style={{ fontSize: 18, fontWeight: 'bold', flex: 0.5 }}>No.</div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: 18,
                        fontWeight: 'bold',
                        flex: 0.5,
                    }}
                >
                    Type
                </div>
                <div style={{ flex: 0.5 }} />
                <div style={{ fontSize: 18, fontWeight: 'bold', flex: 6 }}>Description</div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: 18,
                        fontWeight: 'bold',
                        flex: 1,
                    }}
                >
                    Due Date
                </div>
                <div
                    style={{
                        display: 'flex',
                        fontSize: 18,
                        fontWeight: 'bold',
                        flex: 1.5,
                        justifyContent: 'flex-end',
                    }}
                >
                    Responsible
                </div>
            </div>
            {RA.mapIndexed(({ node: top }, i) => (
                <ReadingViewTop hierarchy={hierarchy[top.id]} top={top} stack={stack} i={i} />
            ))(sortedTopBags)}
        </div>
    );
};
