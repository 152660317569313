import React, { useEffect } from 'react';
import * as R from 'ramda';
import { useModalState } from '../../hooks/useModal';
import { TextField } from '../../Components/Common/Fields';
import { ModalMobile } from '../Common/ModalMobile';
import {
    CONTACT_DIRECTORY_CONTACT_EDGE,
    CONTACT_ID_KEY,
    EDIT_CONTACT_MODAL_MOBILE,
    PROJECT_ID_KEY,
    useGraph,
} from '../../common/hooks.config.new';
import { Id, newId } from '../../common/util';

export const EditContactModalMobile = ({ stack: parentStack }) => {
    const stack = R.append(EDIT_CONTACT_MODAL_MOBILE, parentStack);
    const { clear } = useGraph(...stack);
    const [isOpen] = useModalState(EDIT_CONTACT_MODAL_MOBILE);
    return (
        <ModalMobile name={EDIT_CONTACT_MODAL_MOBILE} stack={stack} onBack={clear} onSave>
            {isOpen ? <ModalContent stack={stack} /> : null}
        </ModalMobile>
    );
};

const ModalContent = ({ stack }) => {
    const { update, useVar, add, inheritRights } = useGraph(...stack);
    const [{ contact, isNew }] = useModalState(EDIT_CONTACT_MODAL_MOBILE);
    const [projectId] = useVar(PROJECT_ID_KEY);
    const [contactId, setContactId] = useVar(CONTACT_ID_KEY);
    const contactToUse = isNew ? { id: contactId } : contact;
    useEffect(() => {
        if (isNew) {
            const newContactId = newId();
            const newContact = {
                id: newContactId,
            };
            add(CONTACT_DIRECTORY_CONTACT_EDGE)(Id.contactDirectory(projectId))(newContact);
            inheritRights(newContactId)(Id.contactDirectory(projectId));
            setContactId(newContactId);
        }
    }, []);
    return (
        <>
            <div className="modal-headline-mobile">
                Edit Contact
                <div
                    className="modal-headline-filler"
                    style={{ backgroundColor: 'var(--corporate-color-14-ligh)' }}
                />
            </div>
            <TextField
                label="Name"
                value={contactToUse.name || ''}
                onChange={value => update(R.assoc('name', value, contactToUse))}
                className="input-mobile"
                labelClass="label-mobile"
            />
            <TextField
                label="E-Mail"
                value={contactToUse.email || ''}
                onChange={value => update(R.assoc('email', value, contactToUse))}
                className="input-mobile"
                labelClass="label-mobile"
            />
            <TextField
                label="Department"
                value={contactToUse.department || ''}
                onChange={value => update(R.assoc('department', value, contactToUse))}
                className="input-mobile"
                labelClass="label-mobile"
            />
            <div style={{ height: '20vh' }} />
        </>
    );
};
