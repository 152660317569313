import React, { useEffect, useMemo } from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { IoTrailSignOutline } from 'react-icons/io5';
import {
    PROJECT_ID_KEY,
    PROJECT_MILESTONE_EDGE,
    SORTED_GROUPED_TOPS_KEY,
    useGraph,
} from '../../common/hooks.config.new';
import { useNodeFromVar } from '../../hooks/useNode';
import { newIsoDate } from '../../common/util';

const constructMilestonesView = projectId =>
    projectId
        ? {
              [projectId]: {
                  as: 'project',
                  include: {
                      edges: true,
                  },
                  edges: {
                      [PROJECT_MILESTONE_EDGE]: {
                          as: 'milestones',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const ProjectPhasesGanttChartMobile = ({ stack }) => {
    const { useVar, useView, pull } = useGraph(...stack);

    const { node: project } = useNodeFromVar(stack, PROJECT_ID_KEY);

    const projectId = R.propOr('', 'id', project);

    const milestonesView = constructMilestonesView(projectId);
    useEffect(() => {
        pull(milestonesView);
    }, []);
    const { project: { milestones = {} } = {} } = useView(milestonesView);

    const getPhasesMilestoneId = phase =>
        R.compose(
            R.prop('nodeId'),
            R.find(R.pathEq(['node', 'phase'], phase)),
            R.values,
        )(milestones);

    const calendarWeeks = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
        49, 50, 51, 52,
    ];
    const today = newIsoDate();
    // TODO Implement Schaltjahr condition until February 2024
    const daysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const thisYear = R.slice(0, 4, today);
    const startOfFirstCalenderWeek = year => {
        const firstYearsCW =
            8 - (new Date(`01.01.${year}`).getDay() === 0 ? 7 : new Date(`01.01.${year}`).getDay());
        return firstYearsCW;
    };

    const phases = R.values(R.propOr([], 'phases', project));
    const adjustedPhases = R.compose(
        R.unnest,
        R.map(phase => {
            if (R.slice(0, 4, phase.endDate) > R.slice(0, 4, phase.startDate)) {
                const splittedPhase = [
                    {
                        ...phase,
                        endDate: `${R.slice(0, 4, phase.startDate)}-12-31`,
                        splittedPhase: 'end',
                    },
                    {
                        ...phase,
                        startDate: `${R.slice(0, 4, phase.endDate)}.01.0${
                            1 + startOfFirstCalenderWeek(R.slice(0, 4, phase.endDate))
                        }`,
                        splittedPhase: 'start',
                    },
                ];

                return splittedPhase;
            }
            return phase;
        }),
        R.filter(phase => new Date(phase.startDate).getTime() < new Date(phase.endDate).getTime()),
        R.filter(phase => phase.startDate && phase.endDate),
    )(phases);

    const phasesStartYears = R.compose(
        R.uniq,
        R.map(R.slice(0, 4)),
        R.map(R.prop('startDate')),
    )(adjustedPhases);

    const phasesEndYears = R.compose(
        R.uniq,
        R.map(R.slice(0, 4)),
        R.map(R.prop('endDate')),
    )(adjustedPhases);

    const phasesYears = R.uniq(R.concat(phasesStartYears, phasesEndYears));

    const calcCalenderWeek = (date, year) => {
        if (date) {
            const fullMonthsTillNow = R.slice(5, 7, date) - 1;
            const avgDaysPerMonth =
                fullMonthsTillNow === 0
                    ? 0
                    : R.sum(R.slice(0, fullMonthsTillNow, daysPerMonth)) / fullMonthsTillNow;
            const daysTillDate =
                fullMonthsTillNow * avgDaysPerMonth + parseFloat(R.slice(8, 10, date));
            const currentCalenderWeek = Math.ceil(
                (daysTillDate - startOfFirstCalenderWeek(year)) / 7,
            );

            return currentCalenderWeek;
        }
        return null;
    };

    const currentCW = calcCalenderWeek(today, thisYear);

    const earliestCW = year => {
        if (R.indexOf(year, phasesYears) > 0) return 0;
        const startDatesToMS = R.compose(
            R.map(date => new Date(date).getTime()),
            R.map(R.propOr(undefined, 'startDate')),
        )(R.filter(phase => R.slice(0, 4, phase.startDate) === year)(adjustedPhases));
        const earliestStartDate =
            R.length(startDatesToMS) > 0 ? new Date(Math.min(...startDatesToMS)).toISOString() : [];
        const phasesFirstCW = calcCalenderWeek(earliestStartDate, year) - 1;
        return phasesFirstCW;
    };

    const latestCW = year => {
        if (R.indexOf(year, phasesYears) < R.length(phasesYears) - 1) return 52;
        const endDatesToMS = R.compose(
            R.map(date => new Date(date).getTime()),
            R.map(R.propOr(undefined, 'endDate')),
        )(R.filter(phase => R.slice(0, 4, phase.startDate) === year)(adjustedPhases));
        const startDatesToMS = R.compose(
            R.map(date => new Date(date).getTime()),
            R.map(R.propOr(undefined, 'startDate')),
        )(R.filter(phase => R.slice(0, 4, phase.startDate) === year)(adjustedPhases));
        const latestStartDate =
            R.length(startDatesToMS) > 0 ? new Date(Math.max(...endDatesToMS)).toISOString() : [];
        const phasesLastCW = calcCalenderWeek(latestStartDate, year);
        return phasesLastCW;
    };

    const [{ tops: sortedGroupedTops = {} } = {}] = useVar(SORTED_GROUPED_TOPS_KEY);

    const topRows = useMemo(
        () =>
            R.compose(
                R.sortBy(R.prop('businessId')),
                R.values,
                R.map(entries => ({
                    ...R.last(entries),
                    previousVersions: R.dropLast(1, entries),
                })),
            )(sortedGroupedTops),
        [sortedGroupedTops],
    );

    const topsCount = phaseName =>
        R.length(
            R.filter(top => R.pathOr('Not Assigned', ['top', 'phase'], top) === phaseName)(topRows),
        );

    // const [, setMilestoneId] = useVar(MILESTONE_ID_KEY);
    // const openMilestoneTopsModal = useModal(MILESTONE_TOPS_MODAL);
    // const openPhaseTopsModal = useModal(PHASE_TOPS_MODAL);

    const cwElementWidth = 242;

    const calcWidth = ({ phase, year }) => {
        const width =
            (calcCalenderWeek(R.propOr(0, 'endDate', phase), year) -
                (calcCalenderWeek(R.propOr(0, 'startDate', phase), year) - 1)) *
                cwElementWidth +
            1;
        return width;
    };

    const calcPhaseMarginLeft = ({ phase, year }) => {
        const marginLeft =
            (calcCalenderWeek(R.propOr(0, 'startDate', phase), year) - 1 - earliestCW(year)) *
            cwElementWidth;
        return marginLeft;
    };

    const calcMilestoneMarginLeft = ({ phase, year }) => {
        const marginLeft =
            (calcCalenderWeek(R.propOr(0, 'startDate', phase), year) - earliestCW(year)) *
                cwElementWidth +
            (calcCalenderWeek(R.propOr(0, 'endDate', phase), year) -
                calcCalenderWeek(R.propOr(0, 'startDate', phase), year)) *
                cwElementWidth -
            40;
        return marginLeft;
    };

    const phaseColors = ({ phase, year }) => {
        const phaseIsActive =
            calcCalenderWeek(R.propOr(0, 'startDate', phase), year) <= currentCW &&
            currentCW < calcCalenderWeek(R.propOr(0, 'endDate', phase), year);
        const phaseIsFinished = calcCalenderWeek(R.propOr(0, 'endDate', phase), year) < currentCW;
        if (phaseIsActive) {
            return {
                borderColor: 'var(--corporate-color-14)',
                boxShadow: '0 5px 8px -2px var(--corporate-color-14)',
            };
        }
        if (phaseIsFinished) {
            return {
                borderColor: 'var(--corporate-color-13)',
                boxShadow: '0 5px 8px -2px var(--corporate-color-13)',
            };
        }
        return {};
    };

    return (
        <div className="gantt-chart-mobile fader">
            <div style={{ display: 'flex', paddingLeft: 24 }}>
                {R.map(year => (
                    <div
                        key={year}
                        className="gantt-content-mobile"
                        style={{ border: R.length(phasesYears) <= 1 && 'none' }}
                    >
                        <div className="year-mobile">{year}</div>
                        <div className="cws-row-mobile">
                            {RA.mapIndexed((cw, index) => (
                                <div className="cw-container-mobile" key={cw}>
                                    <div
                                        className="cw-line-mobile"
                                        style={{
                                            borderColor:
                                                cw === currentCW && 'var(--corporate-color-14)',
                                        }}
                                    >
                                        {(R.indexOf(year, phasesYears) === 0 ||
                                            (R.indexOf(year, phasesYears) > 0 && index > 0)) && (
                                            <div
                                                className="cw-start-mobile"
                                                style={{
                                                    backgroundColor:
                                                        cw === currentCW &&
                                                        'var(--corporate-color-14)',
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div
                                        className="cw-indicator-mobile"
                                        style={{
                                            borderColor:
                                                cw === currentCW && 'var(--corporate-color-14)',
                                            color: cw === currentCW && 'var(--corporate-color-14)',
                                            boxShadow:
                                                cw === currentCW &&
                                                '0 5px 8px -2px var(--corporate-color-14)',
                                        }}
                                    >
                                        CW-0
                                        {cw}
                                    </div>
                                    <div
                                        className="cw-line-mobile"
                                        style={{
                                            justifyContent:
                                                cw === R.length(calendarWeeks) && 'flex-end',
                                            borderColor:
                                                cw === currentCW && 'var(--corporate-color-14)',
                                        }}
                                    />
                                </div>
                            ))(calendarWeeks.slice(earliestCW(year), latestCW(year)))}
                        </div>
                        <div className="phases-rows-mobile">
                            {R.map(
                                phase =>
                                    R.prop('startDate', phase) &&
                                    R.prop('endDate', phase) && (
                                        <div key={phase.id}>
                                            <div
                                                style={{
                                                    marginLeft: calcPhaseMarginLeft({
                                                        phase,
                                                        year,
                                                    }),
                                                    width: calcWidth({ phase, year }),
                                                    borderColor: phaseColors({ phase, year })
                                                        .borderColor,
                                                    boxShadow: phaseColors({ phase, year })
                                                        .boxShadow,
                                                    borderTopRightRadius:
                                                        phase.splittedPhase === 'end' && 0,
                                                    borderBottomRightRadius:
                                                        phase.splittedPhase === 'end' && 0,
                                                    borderTopLeftRadius:
                                                        phase.splittedPhase === 'start' && 0,
                                                    borderBottomLeftRadius:
                                                        phase.splittedPhase === 'start' && 0,
                                                }}
                                                className="gantt-phase-mobile"
                                                // onClick={() => openPhaseTopsModal(phase)}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <p className="top-count-mobile">
                                                        {topsCount(R.propOr('', 'name', phase))}
                                                    </p>
                                                    <p className="phase-name-mobile">
                                                        {R.propOr('', 'name', phase)}
                                                    </p>
                                                </div>
                                            </div>
                                            {getPhasesMilestoneId(R.prop('name', phase)) && (
                                                <div
                                                    // onClick={() => {
                                                    //     setMilestoneId(getPhasesMilestoneId(R.prop('name', phase)));
                                                    //     openMilestoneTopsModal();
                                                    // }}
                                                    className="milestone-indicator-mobile"
                                                    style={{
                                                        marginLeft: calcMilestoneMarginLeft({
                                                            phase,
                                                            year,
                                                        }),
                                                    }}
                                                >
                                                    <IoTrailSignOutline
                                                        style={{
                                                            transform: 'rotate(-45deg)',
                                                            fontSize: 'inherit',
                                                            color: 'inherit',
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ),
                            )(
                                R.filter(phase => R.slice(0, 4, phase.startDate) === year)(
                                    adjustedPhases,
                                ),
                            )}
                            <div style={{ height: 50 }} />
                        </div>
                    </div>
                ))(phasesYears)}
            </div>
        </div>
    );
};
