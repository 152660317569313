import * as R from 'ramda';
// import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { IoMailOutline, IoSendOutline } from 'react-icons/io5';
import { useModalState } from '../../../hooks/useModal';
import { Modal } from '../../Common/Modal';
import { LargeTextField, TextField } from '../../Common/Fields';
import '../PrintModal.css';
import { useGraph, procubionApi } from '../../../common/hooks.config.new';
import { Button } from '../../Common/Controls';
import { useToast } from '../../../hooks/useToast';
import { Toast } from '../../Elements/Toast';
import { useUserEmail } from '../../../hooks/useUserEmail';
import { useContact } from '../../../hooks/useContact';
import { ProtocolTop } from '../../Elements/ProtocolScreen/ProtocolTop';
import { useNode } from '../../../hooks/useNode';
import { useProjectCurrency } from '../../../hooks/useChoices';
import { getSubject, getTextBody } from './text';
import { getHtmlBody } from './html';
import { formatShortDate } from '../../../common/util';
import { usePreviousVersions } from '../../../hooks/useTopHistories';

export const ReminderModal = ({ stack }) => {
    const [isOpen, close] = useModalState('reminder');
    return (
        <Modal
            name="reminder"
            style={{
                height: '80vh',
                top: '10vh',
                width: '80vw',
                left: '10vw',
            }}
            topbar={<IoMailOutline size={24} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <ReminderModalContent stack={stack} isOpen={isOpen} close={close} /> : null}
        </Modal>
    );
};
//
const ReminderModalContent = ({ stack, isOpen, close }) => {
    const { useVar } = useGraph(...stack, 'top-reminder');

    const [projectId] = useVar('projectId');
    const printRef = useRef();
    const [_selectedTop] = useModalState('reminder');

    const previousVersions = usePreviousVersions(stack, R.prop('businessId', _selectedTop.top));
    const lastVersionAuthor = R.compose(R.pathOr('', ['author', 'name']), R.last)(previousVersions);

    const lastVersionDate = R.compose(
        formatShortDate,
        R.defaultTo(
            R.prop('modifiedDate', _selectedTop.top) ||
                R.prop('createdDate', _selectedTop.top) ||
                '',
        ),
        R.prop('editedDate'),
        R.last,
    )(previousVersions);

    const { node: project } = useNode(stack, projectId);

    const user = useUserEmail();
    const { contact } = useContact(stack, projectId, user);

    const sender = contact && {
        name: contact.node.name,
        email: contact.node.email,
    };

    const currency = useProjectCurrency(stack);

    const selectedTop = {
        ..._selectedTop,
        edited: previousVersions.length > 1,
        lastVersionAuthor,
        lastVersionDate,
        currency,
    };

    const [fields, setFields] = useState({});
    useEffect(() => {
        setFields({
            subject: getSubject(project, selectedTop),
            text: getTextBody(selectedTop, currency),
        });
    }, [isOpen]);

    const setPrintToast = useToast('print');

    const sendReminder = async () => {
        const recipients = selectedTop.responsible ? [selectedTop.responsible.email] : [];

        if (sender.name && recipients.length > 0) {
            setPrintToast('spinner', 'Sending mail...');
            try {
                await procubionApi.sendMail({
                    sender,
                    recipients,
                    subject: fields.subject,
                    text: fields.text,
                    html: getHtmlBody(selectedTop, fields.text),
                });
                setPrintToast('success', 'Mail successfully sent.', 5000);
                close();
            } catch (error) {
                setPrintToast('error', error.toString(), 10000);
            }
        } else {
            setPrintToast('error', 'Some parts of your mail are missing.', 10000);
        }
    };
    return (
        <>
            <div className="columns">
                <div>
                    <TextField
                        label="Subject"
                        value={fields.subject}
                        onChange={value => setFields(R.assoc('subject', value))}
                    />
                    <LargeTextField
                        label="Body"
                        rows={18}
                        value={fields.text}
                        onChange={value => setFields(R.assoc('text', value))}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            onClick={() => {
                                sendReminder();
                            }}
                            style={{ width: '30%' }}
                        >
                            <div className="button-icon">
                                <IoSendOutline size={25} />
                                <p style={{ marginLeft: 12, fontSize: 17, fontWeight: 'normal' }}>
                                    Send
                                </p>
                            </div>
                        </Button>
                    </div>
                    <div style={{ marginTop: 24 }}>Attachment as PNG:</div>
                    <div
                        className="top-search-row"
                        ref={printRef}
                        style={{
                            border: '1px solid var(--corporate-color-1)',
                            borderRadius: '12px',
                            padding: 12,
                        }}
                    >
                        <div style={{ fontWeight: 'bold', color: 'var(--corporate-color-1)' }}>
                            {selectedTop.top.businessId}
                        </div>
                        <ProtocolTop
                            noButton
                            stack={stack}
                            editable={false}
                            topId={R.prop('id', selectedTop.top)}
                        />
                    </div>
                </div>
            </div>
            <Toast name="print" />
        </>
    );
};
