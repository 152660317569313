import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import {
    IoAddOutline,
    IoPeopleOutline,
    IoLockOpenOutline,
    IoCreateOutline,
    IoTrashOutline,
    IoMailOutline,
    IoSearchOutline,
    IoChevronForwardOutline,
    IoPersonOutline,
} from 'react-icons/io5';
import { useModal, useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { Button, FancyCheckbox } from '../Common/Controls';
import { ListField } from '../Common/Fields';
import {
    api,
    CONTACTS_MODAL,
    CONTACT_DIRECTORY_CONTACT_EDGE,
    MEETING_ATTENDEE_EDGE,
    ORGANIZATION_ID_KEY,
    PROJECT_ID_KEY,
    useGraph,
    DELETE_CONTACT_MODAL,
} from '../../common/hooks.config.new';
import { Id, newId, newMsDate } from '../../common/util';
import { useUserEmail } from '../../hooks/useUserEmail';
import { useToast } from '../../hooks/useToast';
import { constructNodeView, createOfflinePush } from '../../common/got-adjunct';
import { Toast } from '../Elements/Toast';
import { CDRightsModal } from './CDRightsModal';
import { EditContactModal } from './EditContactModal';
import { useMeetingAttendees } from '../../hooks/useMeetingAttendees';
import { useProjectContacts } from '../../hooks/useProjectContacts';

export const ContactsModal = ({ stack, protocol, meetingId }) => {
    const [isOpen] = useModalState('contacts');
    return (
        <Modal
            name="contacts"
            style={{
                height: '87vh',
                top: '6.5vh',
                width: protocol ? '85vw' : '80vw',
                left: protocol ? '7.5vw' : '10vw',
            }}
            topbar={<IoPeopleOutline size={26} color="var(--corporate-color-7)" />}
        >
            {isOpen ? (
                <ModalContent stack={stack} protocol={protocol} meetingId={meetingId} />
            ) : null}
        </Modal>
    );
};

const ModalContent = ({ stack: parentStack, protocol, meetingId }) => {
    const [contact, setContact] = useState({});
    const stack = R.append(CONTACTS_MODAL, parentStack);
    const { useView, pull, useVar, add, update, inheritRights, remove } = useGraph(...stack);
    const { dissoc: dissocProtocol, assoc } = useGraph(...parentStack);

    const [projectId] = useVar(PROJECT_ID_KEY);
    const {
        refresh: refreshContacts,
        contactBags,
        userCDRights,
    } = useProjectContacts(stack, projectId);
    useEffect(() => {
        refreshContacts();
    }, [refreshContacts]);
    const projectContacts = R.map(R.prop('node'), contactBags);

    const user = useUserEmail();
    const [organizationId] = useVar(ORGANIZATION_ID_KEY);
    const organizationView = constructNodeView(organizationId, { rights: true });
    useEffect(() => {
        organizationId && pull(organizationView);
    }, [organizationId]);
    const { [organizationId]: organizationBag = {} } = useView(organizationView);
    const isOrganizationAdmin = R.pathOr(false, ['rights', 'user', user, 'admin'], organizationBag);
    const canAdminCD = R.pathOr(false, [user, 'admin'], userCDRights);
    const canWriteCD = R.pathOr(false, [user, 'write'], userCDRights);

    const setToast = useToast('contacts');
    const toastConfig = {
        textOnStart: 'Saving contact data...',
        textOnSuccess: 'Successfully saved.',
        textOnError: 'You are offline. Please sync when back online.',
    };
    const _push = createOfflinePush(stack, setToast, toastConfig);
    const push = async () => {
        contact.id &&
            update({
                id: contact.id,
                name: contact.name,
                email: R.toLower(contact.email),
                department: contact.department,
            });
        return _push();
    };

    const openContactDirectoryRightsModal = useModal('cd-rights');
    const openEditContactModal = useModal('new-contact');
    const openDeleteContactModal = useModal(DELETE_CONTACT_MODAL);

    const contactsSearch = searchString => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]+[u00C0-u017F]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.compose(
                R.sortBy(R.prop('name')),
                R.filter(c => c.name && c.name.toUpperCase().search(string) !== -1),
            )(projectContacts);
        }
        return projectContacts;
    };

    const [contactSearchString, setContactSearchString] = useState('');
    const [search, setSearch] = useState(false);

    const assocAttendee = protocol && assoc(MEETING_ATTENDEE_EDGE)(meetingId);
    const dissocAttendee = protocol && dissocProtocol(MEETING_ATTENDEE_EDGE)(meetingId);
    const sortedAttendeeBags = useMeetingAttendees(stack, meetingId);
    const attendees = R.map(R.prop('node'))(sortedAttendeeBags);

    return (
        <>
            <div className="columns">
                <div>
                    <Headline
                        canWriteCD={canWriteCD}
                        projectId={projectId}
                        add={add}
                        inheritRights={inheritRights}
                        setContact={setContact}
                        openEditContactModal={openEditContactModal}
                        canAdminCD={canAdminCD}
                        openContactDirectoryRightsModal={openContactDirectoryRightsModal}
                        search={search}
                        setSearch={setSearch}
                        contactSearchString={contactSearchString}
                        setContactSearchString={setContactSearchString}
                    />
                    <ContactsList
                        user={user}
                        canWriteCD={canWriteCD}
                        setContact={setContact}
                        dissoc={remove}
                        push={push}
                        update={update}
                        openEditContactModal={openEditContactModal}
                        setToast={setToast}
                        projectId={projectId}
                        isOrganizationAdmin={isOrganizationAdmin}
                        organizationId={organizationId}
                        protocol={protocol}
                        attendees={attendees}
                        assocAttendee={assocAttendee}
                        dissocAttendee={dissocAttendee}
                        contactsSearch={contactsSearch}
                        contactSearchString={contactSearchString}
                        openDeleteContactModal={openDeleteContactModal}
                    />
                </div>
            </div>
            <Toast name="contacts" />
            <CDRightsModal stack={stack} push={push} />
            <EditContactModal push={push} contact={contact} setContact={setContact} stack={stack} />
        </>
    );
};

const Headline = ({
    canWriteCD,
    projectId,
    add,
    inheritRights,
    setContact,
    openEditContactModal,
    canAdminCD,
    openContactDirectoryRightsModal,
    search,
    setSearch,
    contactSearchString,
    setContactSearchString,
}) => {
    const inputRef = useRef();
    useEffect(() => {
        search && setTimeout(() => inputRef.current.focus(), 600);
    }, [search]);
    return (
        <div className="modal-headline">
            <div>Project Contacts</div>
            <div style={{ display: 'flex', flex: 3, justifyContent: 'flex-end' }}>
                {canWriteCD && (
                    <Button
                        onClick={() => {
                            const newContactId = newId();
                            const newContact = {
                                id: newContactId,
                            };
                            add(CONTACT_DIRECTORY_CONTACT_EDGE)(Id.contactDirectory(projectId))(
                                newContact,
                            );
                            inheritRights(newContactId)(Id.contactDirectory(projectId));
                            setContact(newContact);
                            openEditContactModal();
                        }}
                    >
                        <IoAddOutline size={23} />
                        <p style={{ marginLeft: 5 }}>Create Contact</p>
                    </Button>
                )}
                {canAdminCD && (
                    <Button
                        onClick={() => {
                            openContactDirectoryRightsModal();
                        }}
                        style={{ marginLeft: 6 }}
                    >
                        <IoLockOpenOutline size={18} />
                        <p style={{ marginLeft: 5 }}>Manage Contact Directory Rights</p>
                    </Button>
                )}
                <Button style={{ marginLeft: 6 }} onClick={() => setSearch(!search)}>
                    {search ? <IoChevronForwardOutline size={19} /> : <IoSearchOutline size={21} />}
                </Button>
                {search && (
                    <div className="animated-searchbar">
                        <input
                            ref={inputRef}
                            placeholder="search project contacts..."
                            value={contactSearchString}
                            onChange={e => setContactSearchString(e.target.value)}
                        />
                        <Button
                            style={{ marginLeft: 3 }}
                            onClick={() => setContactSearchString('')}
                        >
                            <IoTrashOutline size={17} />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

const ContactsList = ({
    user,
    canWriteCD,
    setContact,
    push,
    update,
    openEditContactModal,
    setToast,
    isOrganizationAdmin,
    organizationId,
    protocol,
    attendees,
    assocAttendee,
    dissocAttendee,
    contactsSearch,
    contactSearchString,
    openDeleteContactModal,
    projectId,
}) => (
    <ListField className style={{ flex: 1, flexBasis: 150 }} label="Project Contacts">
        {R.map(cont => (
            <div
                key={R.propOr('', 'id', cont)}
                className="row hover-effect"
                style={{ cursor: 'default', paddingLeft: 12, paddingRight: 6 }}
            >
                <div
                    style={{
                        fontSize: 'inherit',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flex: 1,
                        alignItems: 'center',
                    }}
                >
                    <IoPersonOutline
                        size={20}
                        color="var(--corporate-color-2-light)"
                        style={{ marginRight: 12 }}
                    />
                    <div
                        style={{
                            fontSize: 'inherit',
                            fontWeight: 100,
                        }}
                    >
                        {R.propOr('', 'name', cont)}
                    </div>
                    {R.propOr(false, 'blocked', cont) && <p className="blocked-badge">Blocked</p>}
                    {R.propOr('', 'email', cont) === user && <p className="user-badge">User</p>}
                    {R.propOr(false, 'invited', cont) && <p className="invited-badge">Invited</p>}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                    <div style={{ color: 'var(--color-2)' }}>{R.propOr('', 'email', cont)}</div>
                </div>
                {canWriteCD && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                        <Button
                            onClick={() => {
                                setContact(cont);
                                openEditContactModal();
                            }}
                            style={{ marginRight: 12, color: 'var(--corporate-color-2)' }}
                        >
                            <IoCreateOutline size={19} />
                            <p>Edit</p>
                        </Button>
                        <Button
                            style={{ marginRight: 12, color: 'var(--corporate-color-2)' }}
                            onClick={() => {
                                openDeleteContactModal({
                                    cont,
                                    projectId,
                                });
                            }}
                        >
                            <IoTrashOutline size={17} />
                            <p>Delete</p>
                        </Button>
                        {isOrganizationAdmin && (
                            <Button
                                style={{ color: 'var(--corporate-color-2)' }}
                                onClick={async () => {
                                    try {
                                        cont.email &&
                                            (await api.inviteUser({
                                                id: organizationId,
                                                email: cont.email,
                                            }));
                                        setToast('success', 'User was invited', 5000);
                                        update({ ...cont, invited: true });
                                        push();
                                    } catch (err) {
                                        const status = R.path(['response', 'status'], err);
                                        const message = R.path(['response', 'data'], err);
                                        if (status === 400 && message === 'User already exists') {
                                            setToast('error', 'User already exists', 5000);
                                        } else {
                                            setToast(
                                                'error',
                                                `Something went wrong ${message}`,
                                                5000,
                                            );
                                        }
                                    }
                                }}
                            >
                                <IoMailOutline size={19} />
                                <p>Invite</p>
                            </Button>
                        )}
                        {protocol && (
                            <FancyCheckbox
                                placeholderStyle={{
                                    color: 'var(--corporate-color-2)',
                                    marginLeft: 9,
                                    paddingLeft: 18,
                                    borderLeft: '1px solid var(--corporate-color-6)',
                                }}
                                label="Attendee"
                                onChange={() =>
                                    R.includes(cont, attendees)
                                        ? dissocAttendee(cont)
                                        : assocAttendee(cont, { order: newMsDate() })
                                }
                                value={R.includes(cont, attendees)}
                            />
                        )}
                    </div>
                )}
            </div>
        ))(contactsSearch(contactSearchString))}
    </ListField>
);
