import React, { useEffect, useRef, useState } from 'react';
import {
    IoAddOutline,
    IoChevronForwardOutline,
    IoCloseOutline,
    IoCloudDownloadOutline,
    IoCreateOutline,
    IoDocumentOutline,
    IoEllipseOutline,
    IoLockOpenOutline,
    IoMenuOutline,
    IoSearchOutline,
    IoTrashOutline,
} from 'react-icons/io5';
import * as R from 'ramda';
import { Button } from '../../Common/Controls';
import { ListField } from '../../Common/Fields';
import { TYPES } from '../../Modals/ProtocolsZipModal';
import { newId } from '../../../common/util';
import { Spacer } from '../Spacer';

export const SubprojectsList = ({
    user,
    setSubprojectId,
    setSearchString,
    openZipModal,
    projectId,
    canWriteProject,
    openEditSubprojectModal,
    openSubprojectMeetingsModal,
    openSubprojectRightsModal,
    subprojectId,
    canAdminProject,
    project,
    subprojectChoices,
}) => {
    const subRef = useRef();
    const [searchSub, setSearchSub] = useState(false);
    const [subprojectSearchString, setSubprojectSearchString] = useState('');
    const [menu, setMenu] = useState('');
    useEffect(() => {
        searchSub && setTimeout(() => subRef.current.focus(), 600);
    }, [searchSub]);
    const subprojectSearch = searchString => {
        const string = searchString.replace(/[^a-zA-Z0-9" "]+[u00C0-u017F]/g, '').toUpperCase();
        if (string.length > 0) {
            return R.filter(s => s.label && s.label.toUpperCase().search(string) !== -1)(
                subprojectChoices,
            );
        }
        return subprojectChoices;
    };
    return (
        <div style={{ marginLeft: 15 }}>
            <div className="modal-headline">
                <div>Subprojects</div>
                <div style={{ display: 'flex', flex: 3, justifyContent: 'flex-end' }}>
                    {canWriteProject && (
                        <Button
                            onClick={() => {
                                const newSubprojectId = newId();
                                openEditSubprojectModal({
                                    isNew: true,
                                    subprojectId: newSubprojectId,
                                    user,
                                    projectId,
                                });
                            }}
                        >
                            <IoAddOutline size={20} />
                            <p style={{ marginLeft: 5 }}>Create Subproject</p>
                        </Button>
                    )}
                    <Button style={{ marginLeft: 6 }} onClick={() => setSearchSub(!searchSub)}>
                        {searchSub ? (
                            <IoChevronForwardOutline size={19} />
                        ) : (
                            <IoSearchOutline size={21} />
                        )}
                    </Button>
                    {searchSub && (
                        <div className="animated-searchbar-half">
                            <input
                                ref={subRef}
                                placeholder="search subprojects..."
                                value={subprojectSearchString}
                                onChange={e => setSubprojectSearchString(e.target.value)}
                            />
                            <Button
                                style={{ marginLeft: 3 }}
                                onClick={() => setSubprojectSearchString('')}
                            >
                                <IoTrashOutline size={17} />
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <ListField className style={{ flex: 1, flexBasis: 150 }} label="Subprojects">
                {R.map(subprojectChoice => (
                    <div
                        key={subprojectChoice?.body?.id}
                        className="row hover-effect"
                        style={{
                            cursor: 'pointer',
                            backgroundColor:
                                subprojectChoice?.body?.id === subprojectId &&
                                'var(--corporate-color-5l)',
                            borderBottomColor:
                                subprojectChoice?.body?.id === subprojectId && 'transparent',
                            paddingLeft: 9,
                            height: 35,
                            alignItems: 'flex-start',
                        }}
                        onClick={() => {
                            if (subprojectId !== subprojectChoice?.body?.id) {
                                setSubprojectId(subprojectChoice?.body?.id);
                                setSearchString('');
                            }
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                flex: 1,
                                fontSize: 'inherit',
                                alignItems: 'center',
                            }}
                        >
                            <IoEllipseOutline
                                size={10}
                                color="var(--corporate-color-1)"
                                style={{ marginRight: 12 }}
                            />
                            <div
                                style={{
                                    fontWeight:
                                        subprojectChoice?.body?.id === subprojectId
                                            ? 'normal'
                                            : 100,
                                    color: 'var(--corporate-color-1)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '35',
                                    fontSize: 'inherit',
                                }}
                            >
                                {subprojectChoice?.label}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                            {subprojectChoice?.body?.id === menu ? (
                                <div className="top-menu-container">
                                    <div
                                        className="top-menu"
                                        style={{
                                            width: 175,
                                        }}
                                    >
                                        <Button
                                            onClick={() => setMenu('')}
                                            style={{
                                                height: 30,
                                                color: 'var(--corporate-color-12)',
                                                paddingLeft: 9,
                                                width: 175,
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <IoCloseOutline size={20} />
                                            <p>Close menu</p>
                                        </Button>
                                        <Button
                                            style={{
                                                color: 'white',
                                                width: 175,
                                                justifyContent: 'space-between',
                                            }}
                                            onClick={() => {
                                                setMenu('');
                                                openEditSubprojectModal({
                                                    subprojectId: subprojectChoice?.body?.id,
                                                    user,
                                                    projectId,
                                                });
                                            }}
                                        >
                                            <IoCreateOutline size={19} />
                                            <p>Edit</p>
                                        </Button>
                                        <Button
                                            style={{
                                                color: 'white',
                                                width: 175,
                                                justifyContent: 'space-between',
                                            }}
                                            onClick={() => {
                                                setMenu('');
                                                openSubprojectMeetingsModal();
                                            }}
                                        >
                                            <IoDocumentOutline size={18} />
                                            <p>Show Minutes</p>
                                        </Button>
                                        {canAdminProject && (
                                            <Button
                                                style={{
                                                    color: 'white',
                                                    width: 175,
                                                    justifyContent: 'space-between',
                                                }}
                                                onClick={() => {
                                                    setMenu('');
                                                    openSubprojectRightsModal(
                                                        subprojectChoice?.body?.id,
                                                    );
                                                }}
                                            >
                                                <IoLockOpenOutline size={17} />
                                                <p>Manage Rights</p>
                                            </Button>
                                        )}
                                        <Button
                                            style={{
                                                color: 'white',
                                                width: 175,
                                                justifyContent: 'space-between',
                                            }}
                                            onClick={() => {
                                                setMenu('');
                                                openZipModal({
                                                    type: TYPES.SUBPROJECT,
                                                    nodeId: subprojectChoice?.body?.id,
                                                    filename: `[${R.pathOr(
                                                        '',
                                                        ['businessId'],
                                                        project,
                                                    )}]-[${R.pathOr(
                                                        '',
                                                        ['body', 'businessId'],
                                                        subprojectChoice,
                                                    )}]_Minutes.zip`,
                                                });
                                            }}
                                        >
                                            <IoCloudDownloadOutline size={19} />
                                            <p>Download Minutes</p>
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div style={{ height: 35, display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        onClick={() => setMenu(subprojectChoice?.body?.id)}
                                        style={{
                                            height: 30,
                                            justifyContent: 'flex-start',
                                            borderRadius: 'var(--space-normbig)',
                                        }}
                                    >
                                        <IoMenuOutline size={21} />
                                        <p>Menu</p>
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                ))(subprojectSearch(subprojectSearchString))}
                <Spacer
                    style={{ backgroundColor: 'transparent', height: 1000, margin: '10rem 0' }}
                />
            </ListField>
        </div>
    );
};
