/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext, useMemo, useState } from 'react';
import {
    defaultDropAnimationSideEffects,
    DndContext,
    DragOverlay,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const dropAnimationConfig = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: '0.4',
            },
        },
    }),
};

export const SortableList = ({ items, onMove, renderItem }) => {
    const [activeId, setActiveId] = useState(null);
    const activeIndex = useMemo(
        () => items.findIndex(item => item.id === activeId),
        [activeId, items],
    );
    const activeItem = items[activeIndex];

    const sensors = useSensors(useSensor(PointerSensor));

    return (
        <DndContext
            sensors={sensors}
            onDragStart={({ active }) => {
                setActiveId(active.id);
            }}
            onDragEnd={({ active, over }) => {
                if (over && active.id !== over?.id) {
                    const oldIndex = items.findIndex(({ id }) => id === active.id);
                    const newIndex = items.findIndex(({ id }) => id === over.id);
                    onMove && onMove(oldIndex, newIndex);
                }
                setActiveId(null);
            }}
            onDragCancel={() => {
                setActiveId(null);
            }}
        >
            <SortableContext items={items}>
                {items.map((item, index) => (
                    <React.Fragment key={item.id}>{renderItem(item, index)}</React.Fragment>
                ))}
            </SortableContext>
            <DragOverlay dropAnimation={dropAnimationConfig}>
                {activeIndex >= 0 && activeItem ? renderItem(activeItem, activeIndex) : null}
            </DragOverlay>
        </DndContext>
    );
};

const SortableItemContext = createContext({
    attributes: {},
    listeners: undefined,
    ref() {},
    provided: false,
});

export const SortableItem = ({ className, id, children }) => {
    const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
        id,
    });
    const context = useMemo(
        () => ({
            attributes,
            listeners,
            provided: true,
        }),
        [attributes, listeners],
    );
    const style = {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <SortableItemContext.Provider value={context}>
            <div className={className} ref={setNodeRef} style={style}>
                {children}
            </div>
        </SortableItemContext.Provider>
    );
};

export const DragHandle = ({ className, children }) => {
    const sortableContext = useContext(SortableItemContext);
    const { attributes, listeners, ref, provided } = sortableContext;

    if (provided) {
        return (
            <div className={className} {...attributes} {...listeners} ref={ref}>
                {children}
            </div>
        );
    }

    return null;
};
