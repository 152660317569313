import React, { useRef, useState } from 'react';
import { IoDownloadOutline, IoOpenOutline, IoSyncOutline } from 'react-icons/io5';
import { BsFilePdf, BsTable } from 'react-icons/bs';
import { useSpring, animated, easings } from 'react-spring';
import { Button } from '../../Common/Controls';
import { downloadCsv, generateCsv } from '../../../common/csv-export.util';
import { useTopsOpp } from '../../../hooks/useMutate';
import { newId } from '../../../common/util';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { usePrintDashboardPDF } from '../../../hooks/usePrintProtocol';
import { PROJECT_ID_KEY, useGraph } from '../../../common/hooks.config.new';
import { useToast } from '../../../hooks/useToast';

export const CSVExportButton = ({ stack }) => {
    const [menu, setMenu] = useState(false);
    const ref = useRef(null);
    useOutsideClick(ref, menu, () => setMenu(false));
    const menuSpring = useSpring({
        opacity: menu ? 1 : 0,
        scale: menu ? 1 : 0,
        config: { easing: easings.easeOutCubic, duration: 400 },
    });
    const { useVar } = useGraph(...stack);
    const [projectId] = useVar(PROJECT_ID_KEY);
    const setToast = useToast('dashboard');
    return (
        <div
            style={{
                position: 'relative',
            }}
            ref={ref}
        >
            <Button
                onClick={() => {
                    if (!projectId) {
                        setToast('hint', 'Please select a project first.', 5000);
                        return;
                    }
                    setMenu(!menu);
                }}
                style={{
                    marginLeft: 12,
                }}
            >
                <div className="button-icon">
                    <IoDownloadOutline size={28} color="var(--corporate-color-1)" />
                    <p>Export</p>
                </div>
            </Button>
            <animated.div
                style={{
                    position: 'absolute',
                    top: 'calc(100% + 2px)',
                    right: '50%',
                    transform: 'translateX(50%)',
                    backgroundColor: 'var(--corporate-color-1)',
                    borderRadius: 'var(--space-normbig)',
                    padding: '0.5rem',
                    zIndex: 1000,
                    transformOrigin: 'top',
                    width: 'fit-content',
                    flex: 1,
                    gap: '0.5rem',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 5px 8px -2px var(--corporate-color-1-light4)',
                    ...menuSpring,
                }}
            >
                <ExportCSVButton stack={stack} />
                {menu && <ExportPDFButton stack={stack} />}
            </animated.div>
        </div>
    );
};

const ExportCSVButton = ({ stack }) => {
    const [trigger, setTrigger] = useState();
    useTopsOpp(stack, [], trigger);
    return (
        <Button
            onClick={async () => {
                setTrigger(newId());
                downloadCsv(await generateCsv(stack));
            }}
        >
            <div
                style={{
                    color: 'white',
                    width: 175,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '0.5rem',
                }}
            >
                <BsTable size={20} />
                Export TOPs as CSV
            </div>
        </Button>
    );
};

const ExportPDFButton = ({ stack: parentStack }) => {
    const stack = [...parentStack, 'export-pdf'];
    const [protocolUrl, generateProtocol] = usePrintDashboardPDF(stack);
    const [loading, setLoading] = useState(false);
    return (
        <>
            <Button
                onClick={async () => {
                    setLoading(true);
                    await generateProtocol();
                    setLoading(false);
                }}
            >
                <div
                    style={{
                        color: 'white',
                        width: 175,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '0.5rem',
                    }}
                >
                    {loading ? (
                        <IoSyncOutline
                            size={20}
                            className="spinit"
                            style={{ color: 'var(--corporate-color-7)' }}
                        />
                    ) : (
                        <BsFilePdf size={20} />
                    )}
                    Export TOPs as PDF
                </div>
            </Button>
            {protocolUrl && (
                <Button
                    onClick={() => {
                        window.open(protocolUrl);
                    }}
                >
                    <div
                        style={{
                            color: 'white',
                            width: 175,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '0.5rem',
                        }}
                    >
                        <IoOpenOutline size={20} />
                        Open PDF
                    </div>
                </Button>
            )}
        </>
    );
};
