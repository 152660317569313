import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { IoCheckmarkOutline } from 'react-icons/io5';
import { useModalState } from '../../hooks/useModal';
import { ModalMobile } from '../Common/ModalMobile';
import {
    EDIT_PROTOCOL_TOP_MODAL_MOBILE,
    MEETING_ID_KEY,
    MEETING_TOP_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { Button } from '../../Components/Common/Controls';
import { useNode } from '../../hooks/useNode';
import {
    CurrencyField,
    DateField,
    LargeTextField,
    TextField,
} from '../../Components/Common/Fields';
import {
    PROJECT_ACTION_STATUS_TYPE_CHOICES,
    PROJECT_CONTACT_CHOICES,
    PROJECT_OPP_CATEGORY_TYPE_CHOICES,
    PROJECT_OPP_STATUS_TYPE_CHOICES,
    PROJECT_PHASE_CHOICES,
} from '../../hooks/useChoices';
import {
    ChoiceDropdownField,
    ResponsibleChoiceDropdownField,
} from '../../Components/Elements/ChoiceDropdownField';
import { useTopResponsible } from '../../hooks/useTopResponsible';
import { newId, newIsoDate, newMsDate } from '../../common/util';

export const EditProtocolTopModalMobile = ({ stack }) => {
    const [isOpen, close] = useModalState(EDIT_PROTOCOL_TOP_MODAL_MOBILE);
    return (
        <ModalMobile name={EDIT_PROTOCOL_TOP_MODAL_MOBILE} stack={stack} accept>
            {isOpen ? <ModalContent stack={stack} close={close} /> : null}
        </ModalMobile>
    );
};

const ModalContent = ({ stack, close }) => {
    const { useVar, update, add, inheritRights } = useGraph(...stack);
    const [{ isNew, topType, topId }] = useModalState(EDIT_PROTOCOL_TOP_MODAL_MOBILE);

    const [meetingId] = useVar(MEETING_ID_KEY);

    const [newTopId] = useState(newId());

    const topIdToUse = isNew ? newTopId : topId;

    const createTop = order => {
        add(MEETING_TOP_EDGE)(meetingId)(
            {
                id: newTopId,
                type: topType,
                createdDate: newIsoDate(),
            },
            { order },
        );
        inheritRights(newTopId)(meetingId);
    };

    useEffect(() => {
        isNew && createTop(newMsDate());
    }, [isNew]);

    const top = useNode(stack, topIdToUse);
    const [responsible, setResponsible] = useTopResponsible(stack, topIdToUse);

    const getColor = type => {
        switch (type) {
            case 'a':
                return 'var(--corporate-color-14)';
            case 'o':
                return 'var(--corporate-color-13)';
            case 's':
                return 'grey';
            case 'i':
                return '#C5C9D3';
            case 'd':
                return 'var(--corporate-color-7)';
            case 'r':
                return 'var(--corporate-color-12)';
            default:
                return 'var(--corporate-color-2)';
        }
    };

    const [animation, setAnimation] = useState('pulse-type-indicator');

    return (
        <>
            <div className="modal-headline-mobile">
                Create Agenda Item
                <div
                    className="modal-headline-filler"
                    style={{ backgroundColor: getColor(top?.node?.type) }}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {!top?.node?.type ? (
                    <div
                        onClick={() => update(R.dissoc('type', top.node))}
                        className="no-top-type-indicator fader"
                    >
                        ?
                    </div>
                ) : (
                    <div
                        onClick={() => update(R.assoc('type', '', top.node))}
                        className={`item-type type-${top?.node?.type} ${animation}`}
                        style={{
                            color:
                                R.includes(top?.node?.type, ['1', '2', '3']) &&
                                'var(--corporate-color-1)',
                        }}
                    >
                        {top?.node?.type.toUpperCase()}
                    </div>
                )}
                {!top?.node?.type && (
                    <div className="select-top-type-message fader">Select top type</div>
                )}
                <div
                    className="select-top-type-message fader"
                    style={{ fontSize: '2.5rem', color: 'var(--corporate-color-2-light)' }}
                >
                    {R.pathOr('', ['node', 'businessId'], top)}
                </div>
            </div>
            <div style={{ marginTop: '4rem' }}>
                <TopInputsSwitch
                    top={top.node || {}}
                    topType={R.pathOr('', ['node', 'type'], top)}
                    stack={stack}
                    update={update}
                    responsible={responsible}
                    setResponsible={setResponsible}
                />
            </div>
            {!top?.node?.type && (
                <SelectTopTypeButtons
                    top={top.node || {}}
                    update={update}
                    animation={animation}
                    setAnimation={setAnimation}
                />
            )}
            {top?.node?.type && (
                <Button onClick={() => close()} className="modal-add-button zIndex10000">
                    <IoCheckmarkOutline size="5.5rem" />
                </Button>
            )}
            <div style={{ height: '20vh' }} />
        </>
    );
};

const SelectTopTypeButtons = ({ top, update, setAnimation, animation }) => (
    <div>
        <Button
            className="item-button item-button-3"
            style={{
                backgroundColor: 'var(--corporate-color-14)',
                bottom: '63rem',
                boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
            }}
            onClick={() => {
                update(R.assoc('type', 'a', top));
                animation !== 'fade-scale-mobile-buttons' &&
                    setAnimation('fade-scale-mobile-buttons');
            }}
        >
            A
        </Button>
        <Button
            className="item-button item-button-2"
            style={{
                backgroundColor: 'var(--corporate-color-13)',
                bottom: '51rem',
                boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
            }}
            onClick={() => {
                update(R.assoc('type', 'o', top));
                animation !== 'fade-scale-mobile-buttons' &&
                    setAnimation('fade-scale-mobile-buttons');
            }}
        >
            O
        </Button>
        <Button
            className="item-button item-button-1"
            style={{
                backgroundColor: 'grey',
                bottom: '39rem',
                boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
            }}
            onClick={() => {
                update(R.assoc('type', 's', top));
                animation !== 'fade-scale-mobile-buttons' &&
                    setAnimation('fade-scale-mobile-buttons');
            }}
        >
            S
        </Button>
        <Button
            className="item-button item-button-a"
            style={{
                backgroundColor: 'var(--corporate-color-7)',
                bottom: '27rem',
                boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
            }}
            onClick={() => {
                update(R.assoc('type', 'd', top));
                animation !== 'fade-scale-mobile-buttons' &&
                    setAnimation('fade-scale-mobile-buttons');
            }}
        >
            D
        </Button>
        <Button
            className="item-button item-button-s"
            style={{
                backgroundColor: '#C5C9D3',
                bottom: '15rem',
                boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
            }}
            onClick={() => {
                update(R.assoc('type', 'i', top));
                animation !== 'fade-scale-mobile-buttons' &&
                    setAnimation('fade-scale-mobile-buttons');
            }}
        >
            I
        </Button>
        <Button
            className="item-button item-button-i"
            style={{
                backgroundColor: 'var(--corporate-color-12)',
                bottom: '3rem',
                boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
            }}
            onClick={() => {
                update(R.assoc('type', 'r', top));
                animation !== 'fade-scale-mobile-buttons' &&
                    setAnimation('fade-scale-mobile-buttons');
            }}
        >
            R
        </Button>
        <Button
            className="item-button item-button-o"
            style={{
                color: 'var(--corporate-color-2)',
                bottom: '27rem',
                right: '15rem',
                backgroundColor: 'white',
                boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
            }}
            onClick={() => {
                update(R.assoc('type', '3', top));
                animation !== 'fade-scale-mobile-buttons' &&
                    setAnimation('fade-scale-mobile-buttons');
            }}
        >
            3
        </Button>
        <Button
            className="item-button item-button-d"
            style={{
                color: 'var(--corporate-color-2)',
                bottom: '15rem',
                right: '15rem',
                backgroundColor: 'white',
                boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
            }}
            onClick={() => {
                update(R.assoc('type', '2', top));
                animation !== 'fade-scale-mobile-buttons' &&
                    setAnimation('fade-scale-mobile-buttons');
            }}
        >
            2
        </Button>
        <Button
            className="item-button item-button-r"
            style={{
                color: 'var(--corporate-color-2)',
                bottom: '3rem',
                right: '15rem',
                backgroundColor: 'white',
                boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
            }}
            onClick={() => {
                update(R.assoc('type', '1', top));
                animation !== 'fade-scale-mobile-buttons' &&
                    setAnimation('fade-scale-mobile-buttons');
            }}
        >
            1
        </Button>
    </div>
);

const TopInputsSwitch = ({ top, topType, update, stack, responsible, setResponsible }) => {
    switch (topType) {
        case 'a':
            return (
                <TypeAInputs
                    top={top}
                    update={update}
                    stack={stack}
                    responsible={responsible}
                    setResponsible={setResponsible}
                />
            );
        case 'o':
            return (
                <TypeOInputs
                    top={top}
                    update={update}
                    stack={stack}
                    responsible={responsible}
                    setResponsible={setResponsible}
                />
            );
        case 's':
            return <TypeISDInputs stack={stack} top={top} update={update} />;
        case 'd':
            return <TypeISDInputs stack={stack} top={top} update={update} />;
        case 'i':
            return <TypeISDInputs stack={stack} top={top} update={update} />;
        case 'r':
            return (
                <TypeRInputs
                    top={top}
                    update={update}
                    stack={stack}
                    responsible={responsible}
                    setResponsible={setResponsible}
                />
            );
        case '1':
            return <HeadlineInputs top={top} update={update} stack={stack} />;
        case '2':
            return <HeadlineInputs top={top} update={update} stack={stack} />;
        case '3':
            return <HeadlineInputs top={top} update={update} stack={stack} />;
        default:
            return null;
    }
};

const TypeAInputs = ({ top, update, stack, responsible, setResponsible }) => (
    <div className="fader">
        <div className="mobile-textarea-height-25">
            <LargeTextField
                rows={18}
                label="Description"
                value={R.propOr('', 'description', top)}
                onChange={value => update(R.assoc('description', value, top))}
                className="input-mobile"
                labelClass="label-mobile"
            />
        </div>
        <ChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_ACTION_STATUS_TYPE_CHOICES}
            onChange={value => update(R.assoc('status', value.value, top))}
            value={R.propOr('', 'status', top)}
            label="Status"
            labelClass="dd-label-mobile"
        />
        <ChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_PHASE_CHOICES}
            label="Phase"
            value={R.propOr('', 'phase', top)}
            onChange={option => update(R.assoc('phase', option.value, top))}
            labelClass="dd-label-mobile"
        />
        <div className="mobile-dates">
            <DateField
                className="mobile"
                containerStyle={{ display: 'flex', flex: 1 }}
                labelClass="date-label-mobile"
                late={R.propOr('', 'status', top) === 'Late'}
                label="Due Date"
                value={R.propOr('', 'dueDate', top)}
                locale="en-gb"
                onChange={value => update(R.assoc('dueDate', value, top))}
                mobile
            />
        </div>
        <ResponsibleChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_CONTACT_CHOICES}
            onChange={contact => setResponsible(contact.node)}
            value={R.propOr('', 'id', responsible)}
            label="Responsible"
            labelClass="dd-label-mobile"
        />
    </div>
);

const TypeOInputs = ({ top, update, stack, responsible, setResponsible }) => (
    <div className="fader">
        <div className="mobile-textarea-height-25">
            <LargeTextField
                rows={18}
                label="Description"
                value={R.propOr('', 'description', top)}
                onChange={value => update(R.assoc('description', value, top))}
                className="input-mobile"
                labelClass="label-mobile"
            />
        </div>
        <CurrencyField
            stack={stack}
            label="Savings"
            value={R.propOr('', 'savings', top)}
            onChange={value => update(R.assoc('savings', value, top))}
            className="input-mobile"
            labelClass="label-mobile"
        />
        <ChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_OPP_STATUS_TYPE_CHOICES}
            onChange={value => update(R.assoc('status', value.value, top))}
            value={R.propOr('', 'status', top)}
            label="Status"
            labelClass="dd-label-mobile"
        />
        <ChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_OPP_CATEGORY_TYPE_CHOICES}
            onChange={value => update(R.assoc('category', value.value, top))}
            value={R.propOr('', 'category', top)}
            label="Category"
            labelClass="dd-label-mobile"
        />
        <ChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_PHASE_CHOICES}
            label="Phase"
            value={R.propOr('', 'phase', top)}
            onChange={option => update(R.assoc('phase', option.value, top))}
            labelClass="dd-label-mobile"
        />
        <ResponsibleChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_CONTACT_CHOICES}
            onChange={contact => setResponsible(contact.node)}
            value={R.propOr('', 'id', responsible)}
            label="Responsible"
            labelClass="dd-label-mobile"
        />
    </div>
);

const TypeRInputs = ({ top, update, stack, responsible, setResponsible }) => (
    <div className="fader">
        <div className="mobile-textarea-height-25">
            <LargeTextField
                rows={18}
                label="Description"
                value={R.propOr('', 'description', top)}
                onChange={value => update(R.assoc('description', value, top))}
                className="input-mobile"
                labelClass="label-mobile"
            />
        </div>
        <TextField
            label="Category"
            value={R.propOr('', 'category', top)}
            onChange={value => update(R.assoc('category', value, top))}
            className="input-mobile"
            labelClass="label-mobile"
        />
        <TextField
            label="Reference"
            value={R.propOr('', 'reference', top)}
            onChange={value => update(R.assoc('reference', value, top))}
            className="input-mobile"
            labelClass="label-mobile"
        />
        <TextField
            label="Cost Increase"
            value={R.propOr('', 'costIncrease', top)}
            onChange={value => update(R.assoc('costIncrease', value, top))}
            className="input-mobile"
            labelClass="label-mobile"
        />
        <ChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_PHASE_CHOICES}
            label="Project phase"
            value={R.propOr('', 'phase', top)}
            onChange={option => update(R.assoc('phase', option.value, top))}
            labelClass="dd-label-mobile"
        />
        <ResponsibleChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_CONTACT_CHOICES}
            onChange={contact => setResponsible(contact.node)}
            value={R.propOr('', 'id', responsible)}
            label="Responsible"
            labelClass="dd-label-mobile"
        />
    </div>
);

const TypeISDInputs = ({ stack, top, update }) => (
    <div className="fader">
        <div className="mobile-textarea-height-25">
            <LargeTextField
                rows={18}
                label="Description"
                value={R.propOr('', 'description', top)}
                onChange={value => update(R.assoc('description', value, top))}
                className="input-mobile"
                labelClass="label-mobile"
            />
        </div>
        <ChoiceDropdownField
            mobile
            stack={stack}
            choiceType={PROJECT_PHASE_CHOICES}
            label="Project phase"
            value={R.propOr('', 'phase', top)}
            onChange={option => update(R.assoc('phase', option.value, top))}
            labelClass="dd-label-mobile"
        />
    </div>
);

const HeadlineInputs = ({ top, update }) => (
    <div className="fader">
        <TextField
            label="Headline"
            value={R.propOr('', 'description', top)}
            onChange={value => update(R.assoc('description', value, top))}
            className="input-mobile"
            labelClass="label-mobile"
        />
    </div>
);
