import React, { useEffect } from 'react';
import * as R from 'ramda';
import { useSpring, animated, easings } from 'react-spring';
import { IoChevronDownOutline, IoTrashOutline } from 'react-icons/io5';
import {
    DASHBOARD_FILTER_KEY,
    DASHBOARD_STATE_KEY,
    OPP_TRACKING,
    SEARCH_STRING_KEY,
    useGraph,
} from '../../common/hooks.config.new';
import {
    PROJECT_ACTION_STATUS_TYPE_CHOICES,
    PROJECT_CONTACT_CHOICES,
    PROJECT_OPP_CATEGORY_TYPE_CHOICES,
    PROJECT_OPP_STATUS_TYPE_MILESTONES_CHOICES,
    PROJECT_PHASE_CHOICES,
    useMeetingTypesChoices,
    useProjectChoices,
} from '../../hooks/useChoices';
import { DropdownField, MultiDropdownField } from '../../Components/Common/Dropdown';
import { DateField, TextField } from '../../Components/Common/Fields';
import { Button, FancyCheckbox } from '../../Components/Common/Controls';
import { SortButton } from '../../Components/Elements/ProjectDashboard/FilterBanner';
import { ClickAnimator } from './ClickAnimator';

export const FilterFlyout = ({ stack, filterMenu, setFilterMenu }) => {
    const { useVar } = useGraph(...stack);
    const [filter, _setFilter] = useVar(DASHBOARD_FILTER_KEY);
    const [searchString, setSearchString] = useVar(SEARCH_STRING_KEY);
    const [dashboardState, setDashboardState] = useVar(DASHBOARD_STATE_KEY);
    const filterMenuFlyout = useSpring({
        bottom: filterMenu ? '0vh' : '-61vh',
        config: { duration: 500, easing: easings.easeOutCubic },
    });

    const meetingTypesChoices = useMeetingTypesChoices(stack);
    const { choices: actionStatusTypeChoices } = useProjectChoices(
        stack,
        PROJECT_ACTION_STATUS_TYPE_CHOICES,
    );
    const { choices: contactChoices } = useProjectChoices(stack, PROJECT_CONTACT_CHOICES);
    const { choices: oppCategoryTypeChoices } = useProjectChoices(
        stack,
        PROJECT_OPP_CATEGORY_TYPE_CHOICES,
    );
    const { choices: oppStatusTypeChoices } = useProjectChoices(
        stack,
        PROJECT_OPP_STATUS_TYPE_MILESTONES_CHOICES,
    );
    const { choices: projectPhaseChoices } = useProjectChoices(stack, PROJECT_PHASE_CHOICES);
    const topTypeChoices = R.map(type => ({ value: R.head(type).toLowerCase(), label: type }))([
        'Actions',
        'Opportunities',
        'Risks',
        'Statements',
        'Informations',
        'Decisions',
    ]);
    const sortTypeChoices = R.map(type => ({ value: type, label: type }))([
        'None',
        'Meeting date',
        'Due date',
        'Status',
    ]);

    const [oppTracking] = useVar(OPP_TRACKING);
    const setFilter = val => {
        if (R.is(Function, val)) {
            _setFilter(val(filter));
        } else {
            _setFilter(val);
        }
    };
    useEffect(() => {
        !filter && setFilter({});
    }, [filter]);

    const filterValuesSum = R.sum([
        R.length(R.pathOr([], ['topType', 'values'], filter)),
        R.length(R.pathOr([], ['meetingType', 'values'], filter)),
        R.length(R.pathOr([], ['oppStatus', 'values'], filter)),
        R.length(R.pathOr([], ['actionStatus', 'values'], filter)),
        R.length(R.pathOr([], ['category', 'values'], filter)),
        R.length(R.pathOr([], ['responsible', 'values'], filter)),
        R.length(searchString),
    ]);
    const filterIsActive =
        filterValuesSum > 0 ||
        (filter?.meetingDate && !R.isEmpty(filter?.meetingDate)) ||
        (filter?.dueDate && !R.isEmpty(filter?.dueDate)) ||
        filter?.isCritical ||
        filter?.sort;

    return (
        <animated.div className="filter-menu-flyout-container zIndex10000" style={filterMenuFlyout}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: 'calc(100% - 8rem)',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '6rem',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'flex-start',
                        position: 'relative',
                    }}
                >
                    <TextField
                        realtime
                        label="Search agenda items..."
                        value={searchString || ''}
                        onChange={value => setSearchString(value)}
                        className="input-mobile"
                        labelClass="label-mobile"
                        fieldDivStyle={{ width: '100%' }}
                        style={{ borderRadius: '5rem' }}
                    />
                    <ClickAnimator
                        content={
                            <Button
                                style={{
                                    height: '8rem',
                                    marginRight: '2.5rem',
                                    backgroundColor: 'transparent',
                                    position: 'absolute',
                                    right: '1rem',
                                }}
                                onClick={() => setSearchString('')}
                            >
                                <IoTrashOutline size="3.5rem" color="var(--color-2)" />
                            </Button>
                        }
                        positioning={{ position: 'relative' }}
                    />
                </div>
                <div
                    style={{
                        height: '60vh',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        width: '90vw',
                    }}
                >
                    <p
                        style={{
                            fontSize: '3.5rem',
                            color: 'var(--corporate-color-1)',
                            marginBottom: '2rem',
                        }}
                    >
                        Dashboard Settings
                    </p>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '2rem',
                        }}
                    >
                        <ClickAnimator
                            content={
                                <Button
                                    onClick={() => {
                                        setDashboardState('user');
                                        setSearchString('');
                                    }}
                                    style={{
                                        border:
                                            dashboardState === 'user'
                                                ? '1px solid var(--corporate-color-13)'
                                                : '1px solid transparent',
                                        color:
                                            dashboardState === 'user' &&
                                            'var(--corporate-color-13)',
                                        boxSizing: 'borderBox',
                                        marginLeft: 18,
                                        boxShadow:
                                            dashboardState === 'user'
                                                ? '0 5px 8px -2px var(--corporate-color-13-light)'
                                                : '0 5px 8px -2px var(--shadow-color-ultralight)',
                                        borderRadius: '10rem',
                                        fontSize: '3.5rem',
                                        fontWeight: 300,
                                        backgroundColor: 'white',
                                    }}
                                >
                                    User
                                </Button>
                            }
                            positioning={{ position: 'relative' }}
                        />
                        <ClickAnimator
                            content={
                                <Button
                                    onClick={() => {
                                        setDashboardState('project');
                                        setSearchString('');
                                    }}
                                    style={{
                                        border:
                                            !dashboardState || dashboardState === 'project'
                                                ? '1px solid var(--corporate-color-13)'
                                                : '1px solid transparent',
                                        color:
                                            (!dashboardState || dashboardState === 'project') &&
                                            'var(--corporate-color-13)',
                                        boxSizing: 'borderBox',
                                        marginLeft: 18,
                                        boxShadow:
                                            !dashboardState || dashboardState === 'project'
                                                ? '0 5px 8px -2px var(--corporate-color-13-light)'
                                                : '0 5px 8px -2px var(--shadow-color-ultralight)',
                                        borderRadius: '10rem',
                                        fontSize: '3.5rem',
                                        fontWeight: 300,
                                        backgroundColor: 'white',
                                    }}
                                >
                                    Project
                                </Button>
                            }
                            positioning={{ position: 'relative' }}
                        />
                        <ClickAnimator
                            content={
                                <Button
                                    onClick={() => {
                                        setDashboardState('subproject');
                                        setSearchString('');
                                    }}
                                    style={{
                                        border:
                                            dashboardState === 'subproject'
                                                ? '1px solid var(--corporate-color-13)'
                                                : '1px solid transparent',
                                        color:
                                            dashboardState === 'subproject' &&
                                            'var(--corporate-color-13)',
                                        boxSizing: 'borderBox',
                                        marginLeft: 18,
                                        marginRight: 24,
                                        boxShadow:
                                            dashboardState === 'subproject'
                                                ? '0 5px 8px -2px var(--corporate-color-13-light)'
                                                : '0 5px 8px -2px var(--shadow-color-ultralight)',
                                        borderRadius: '10rem',
                                        fontSize: '3.5rem',
                                        fontWeight: 300,
                                        backgroundColor: 'white',
                                    }}
                                >
                                    Subproject
                                </Button>
                            }
                            positioning={{ position: 'relative' }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            padding: '2.5rem 0 7rem 0',
                        }}
                    >
                        <FancyCheckbox
                            style={{ color: 'var(--corporate-color-1)', marginTop: '0.5rem' }}
                            customHeight={40}
                            label="Show Items"
                            onChange={() => {
                                setFilter({ ...filter, showTops: !filter.showTops });
                            }}
                            value={R.propOr(false, 'showTops', filter)}
                            placeholderStyle={{ fontSize: '3.5rem' }}
                            labelStyle={{ marginLeft: 20 }}
                            mobile
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: 'calc(96vw - 4rem)',
                        }}
                    >
                        <div
                            style={{
                                marginBottom: '1rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p
                                    style={{
                                        fontSize: '3.5rem',
                                        color: 'var(--corporate-color-1)',
                                    }}
                                >
                                    Sorting
                                </p>
                                <ClickAnimator
                                    content={
                                        <Button
                                            style={{
                                                color: 'var(--corporate-color-1-light2)',
                                                marginLeft: '1rem',
                                                backgroundColor: 'white',
                                            }}
                                            onClick={() => {
                                                setFilter(R.dissoc('sort', filter));
                                            }}
                                        >
                                            <IoTrashOutline size="3.5rem" />
                                        </Button>
                                    }
                                    positioning={{ position: 'relative' }}
                                />
                            </div>
                            <ClickAnimator
                                content={
                                    <SortButton
                                        containerStyle={{ height: 'fit-content' }}
                                        style={{
                                            fontSize: '3rem',
                                            color: 'var(--corporate-color-2)',
                                        }}
                                        iconSize="3rem"
                                        value={R.path(['sort', 'direction'], filter)}
                                        onChange={value =>
                                            setFilter(R.assocPath(['sort', 'direction'], value))
                                        }
                                    />
                                }
                                positioning={{ position: 'relative' }}
                            />
                        </div>
                        <DropdownField
                            filter
                            mobile
                            label="Sorting"
                            value={R.path(['sort', 'type', 'value'], filter)}
                            editable
                            options={sortTypeChoices}
                            onChange={value => setFilter(R.assocPath(['sort', 'type'], value))}
                            labelClass="dd-label-mobile no-left-margin"
                            customWidth="calc(96vw - 4rem)"
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: 'calc(96vw - 4rem)',
                        }}
                    >
                        <div
                            style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}
                        >
                            <p
                                style={{
                                    fontSize: '3.5rem',
                                    color: 'var(--corporate-color-1)',
                                }}
                            >
                                Responsibles
                            </p>
                            <ClickAnimator
                                content={
                                    <Button
                                        style={{
                                            color: 'var(--corporate-color-1-light2)',
                                            marginLeft: '1rem',
                                            backgroundColor: 'white',
                                        }}
                                        onClick={() => {
                                            setFilter(R.dissoc('responsible', filter));
                                        }}
                                    >
                                        <IoTrashOutline size="3.5rem" />
                                    </Button>
                                }
                                positioning={{ position: 'relative' }}
                            />
                        </div>
                        <MultiDropdownField
                            mobile
                            label="Responsible..."
                            customWidth="calc(96vw - 4rem)"
                            options={contactChoices}
                            values={R.pathOr([], ['responsible', 'values'], filter)}
                            onChange={selectedOptions =>
                                setFilter(R.assocPath(['responsible', 'values'], selectedOptions))
                            }
                            labelClass="dd-label-mobile no-left-margin"
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: 'calc(96vw - 4rem)',
                        }}
                    >
                        <div
                            style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}
                        >
                            <p
                                style={{
                                    fontSize: '3.5rem',
                                    color: 'var(--corporate-color-1)',
                                }}
                            >
                                Meeting Dates
                            </p>
                            <ClickAnimator
                                content={
                                    <Button
                                        style={{
                                            color: 'var(--corporate-color-1-light2)',
                                            marginLeft: '1rem',
                                            backgroundColor: 'white',
                                        }}
                                        onClick={() => {
                                            setFilter({ ...filter, meetingDate: {} });
                                        }}
                                    >
                                        <IoTrashOutline size="3.5rem" />
                                    </Button>
                                }
                                positioning={{ position: 'relative' }}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                width: '100%',
                            }}
                        >
                            <div className="mobile-dates">
                                <DateField
                                    className="mobile no-left-margin"
                                    containerStyle={{ display: 'flex', width: '100%' }}
                                    labelClass="date-label-mobile"
                                    label="From..."
                                    value={R.path(['meetingDate', 'from'], filter)}
                                    onChange={value =>
                                        setFilter(R.assocPath(['meetingDate', 'from'], value))
                                    }
                                    mobile
                                />
                            </div>
                            <div className="mobile-dates">
                                <DateField
                                    className="mobile no-left-margin"
                                    containerStyle={{ display: 'flex', width: '100%' }}
                                    labelClass="date-label-mobile"
                                    label="To..."
                                    value={R.path(['meetingDate', 'to'], filter)}
                                    onChange={value =>
                                        setFilter(R.assocPath(['meetingDate', 'to'], value))
                                    }
                                    mobile
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: 'calc(96vw - 4rem)',
                        }}
                    >
                        <div
                            style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}
                        >
                            <p
                                style={{
                                    fontSize: '3.5rem',
                                    color: 'var(--corporate-color-1)',
                                }}
                            >
                                Due Dates
                            </p>
                            <ClickAnimator
                                content={
                                    <Button
                                        style={{
                                            color: 'var(--corporate-color-1-light2)',
                                            marginLeft: '1rem',
                                            backgroundColor: 'white',
                                        }}
                                        onClick={() => {
                                            setFilter({ ...filter, dueDate: {} });
                                        }}
                                    >
                                        <IoTrashOutline size="3.5rem" />
                                    </Button>
                                }
                                positioning={{ position: 'relative' }}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                width: '100%',
                            }}
                        >
                            <div className="mobile-dates">
                                <DateField
                                    className="mobile no-left-margin"
                                    containerStyle={{ display: 'flex', width: '100%' }}
                                    labelClass="date-label-mobile"
                                    label="From..."
                                    value={R.path(['dueDate', 'from'], filter)}
                                    onChange={value =>
                                        setFilter(R.assocPath(['dueDate', 'from'], value))
                                    }
                                    mobile
                                />
                            </div>
                            <div className="mobile-dates">
                                <DateField
                                    className="mobile no-left-margin"
                                    containerStyle={{ display: 'flex', width: '100%' }}
                                    labelClass="date-label-mobile"
                                    label="To..."
                                    value={R.path(['dueDate', 'to'], filter)}
                                    onChange={value =>
                                        setFilter(R.assocPath(['dueDate', 'to'], value))
                                    }
                                    mobile
                                    popperPlacement="top-end"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: 'calc(96vw - 4rem)',
                        }}
                    >
                        <div
                            style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}
                        >
                            <p
                                style={{
                                    fontSize: '3.5rem',
                                    color: 'var(--corporate-color-1)',
                                }}
                            >
                                Phases
                            </p>
                            <ClickAnimator
                                content={
                                    <Button
                                        style={{
                                            color: 'var(--corporate-color-1-light2)',
                                            marginLeft: '1rem',
                                            backgroundColor: 'white',
                                        }}
                                        onClick={() => {
                                            setFilter(R.dissoc('phase', filter));
                                        }}
                                    >
                                        <IoTrashOutline size="3.5rem" />
                                    </Button>
                                }
                                positioning={{ position: 'relative' }}
                            />
                        </div>
                        <MultiDropdownField
                            mobile
                            label="Phase..."
                            options={projectPhaseChoices}
                            values={R.pathOr([], ['phase', 'values'], filter)}
                            onChange={selectedOptions =>
                                setFilter(R.assocPath(['phase', 'values'], selectedOptions))
                            }
                            customWidth="calc(96vw - 4rem)"
                            labelClass="dd-label-mobile no-left-margin"
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: 'calc(96vw - 4rem)',
                        }}
                    >
                        <div
                            style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}
                        >
                            <p
                                style={{
                                    fontSize: '3.5rem',
                                    color: 'var(--corporate-color-1)',
                                }}
                            >
                                Meetings
                            </p>
                            <ClickAnimator
                                content={
                                    <Button
                                        style={{
                                            color: 'var(--corporate-color-1-light2)',
                                            marginLeft: '1rem',
                                            backgroundColor: 'white',
                                        }}
                                        onClick={() => {
                                            setFilter({
                                                ...filter,
                                                meetingType: {},
                                            });
                                        }}
                                    >
                                        <IoTrashOutline size="3.5rem" />
                                    </Button>
                                }
                                positioning={{ position: 'relative' }}
                            />
                        </div>
                        <MultiDropdownField
                            mobile
                            label="Type..."
                            options={meetingTypesChoices}
                            values={R.pathOr([], ['meetingType', 'values'], filter)}
                            onChange={selectedOptions =>
                                setFilter(R.assocPath(['meetingType', 'values'], selectedOptions))
                            }
                            customWidth="calc(96vw - 4rem)"
                            labelClass="dd-label-mobile no-left-margin"
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: 'calc(96vw - 4rem)',
                        }}
                    >
                        <div
                            style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}
                        >
                            <p
                                style={{
                                    fontSize: '3.5rem',
                                    color: 'var(--corporate-color-1)',
                                }}
                            >
                                Agenda Items
                            </p>
                            <ClickAnimator
                                content={
                                    <Button
                                        style={{
                                            color: 'var(--corporate-color-1-light2)',
                                            marginLeft: '1rem',
                                            backgroundColor: 'white',
                                        }}
                                        onClick={() => {
                                            setFilter({
                                                ...filter,
                                                topType: {},
                                            });
                                        }}
                                    >
                                        <IoTrashOutline size="3.5rem" />
                                    </Button>
                                }
                                positioning={{ position: 'relative' }}
                            />
                        </div>
                        <MultiDropdownField
                            mobile
                            label="Type..."
                            options={topTypeChoices}
                            values={R.pathOr([], ['topType', 'values'], filter)}
                            onChange={selectedOptions =>
                                setFilter(R.assocPath(['topType', 'values'], selectedOptions))
                            }
                            customWidth="calc(96vw - 4rem)"
                            labelClass="dd-label-mobile no-left-margin"
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: 'calc(96vw - 4rem)',
                        }}
                    >
                        <div
                            style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}
                        >
                            <p
                                style={{
                                    fontSize: '3.5rem',
                                    color: 'var(--corporate-color-1)',
                                }}
                            >
                                Actions
                            </p>
                            <ClickAnimator
                                content={
                                    <Button
                                        style={{
                                            color: 'var(--corporate-color-1-light2)',
                                            marginLeft: '1rem',
                                            backgroundColor: 'white',
                                        }}
                                        onClick={() => {
                                            setFilter({
                                                ...filter,
                                                actionStatus: {},
                                            });
                                        }}
                                    >
                                        <IoTrashOutline size="3.5rem" />
                                    </Button>
                                }
                                positioning={{ position: 'relative' }}
                            />
                        </div>
                        <MultiDropdownField
                            mobile
                            label="Status..."
                            options={actionStatusTypeChoices}
                            values={R.pathOr([], ['actionStatus', 'values'], filter)}
                            onChange={selectedOptions =>
                                setFilter(R.assocPath(['actionStatus', 'values'], selectedOptions))
                            }
                            customWidth="calc(96vw - 4rem)"
                            labelClass="dd-label-mobile no-left-margin"
                        />
                    </div>
                    {oppTracking && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                width: 'calc(96vw - 4rem)',
                            }}
                        >
                            <div
                                style={{
                                    marginBottom: '1rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: '3.5rem',
                                        color: 'var(--corporate-color-1)',
                                    }}
                                >
                                    Opportunities
                                </p>
                                <ClickAnimator
                                    content={
                                        <Button
                                            style={{
                                                color: 'var(--corporate-color-1-light2)',
                                                marginLeft: '1rem',
                                                backgroundColor: 'white',
                                            }}
                                            onClick={() => {
                                                setFilter({
                                                    ...filter,
                                                    oppStatus: {},
                                                    category: {},
                                                    isCritical: false,
                                                });
                                            }}
                                        >
                                            <IoTrashOutline size="3.5rem" />
                                        </Button>
                                    }
                                    positioning={{ position: 'relative' }}
                                />
                            </div>
                            <MultiDropdownField
                                mobile
                                label="Category..."
                                options={oppCategoryTypeChoices}
                                values={R.pathOr([], ['category', 'values'], filter)}
                                onChange={selectedOptions =>
                                    setFilter(R.assocPath(['category', 'values'], selectedOptions))
                                }
                                customWidth="calc(96vw - 4rem)"
                                labelClass="dd-label-mobile no-left-margin"
                            />
                            <MultiDropdownField
                                mobile
                                label="Status..."
                                options={oppStatusTypeChoices}
                                values={R.pathOr([], ['oppStatus', 'values'], filter)}
                                onChange={selectedOptions =>
                                    setFilter(R.assocPath(['oppStatus', 'values'], selectedOptions))
                                }
                                customWidth="calc(96vw - 4rem)"
                                labelClass="dd-label-mobile no-left-margin"
                            />
                            <div
                                style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
                            >
                                <FancyCheckbox
                                    style={{ color: 'var(--corporate-color-1)' }}
                                    customHeight={40}
                                    label="Critical Opps"
                                    onChange={() => {
                                        setFilter({ ...filter, isCritical: !filter.isCritical });
                                    }}
                                    value={R.propOr(false, 'isCritical', filter)}
                                    placeholderStyle={{ fontSize: '3rem' }}
                                    labelStyle={{ marginLeft: 20 }}
                                    mobile
                                />
                            </div>
                        </div>
                    )}
                    <div style={{ height: '20vh' }} />
                </div>
            </div>
            <div className="search-button-container">
                <Button
                    onClick={() => {
                        setFilterMenu(false);
                    }}
                    className="filter-button"
                    style={{
                        backgroundColor: 'var(--corporate-color-1)',
                        color: 'white',
                    }}
                >
                    <div className="search-button-icon-container">
                        <IoChevronDownOutline size="6rem" />
                    </div>
                </Button>
                {filterIsActive && filterMenu && (
                    <Button
                        onClick={() => {
                            setFilter({});
                            setSearchString('');
                            setFilterMenu(false);
                        }}
                        className="filter-button"
                        style={{ marginLeft: '3rem', backgroundColor: 'var(--corporate-color-1)' }}
                    >
                        <div className="search-button-icon-container">
                            <IoTrashOutline
                                style={{ marginTop: '0.5rem' }}
                                size="4rem"
                                color="var(--corporate-color-12)"
                            />
                        </div>
                    </Button>
                )}
            </div>
        </animated.div>
    );
};
