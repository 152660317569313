/* eslint-disable react/jsx-props-no-spreading */
import * as R from 'ramda';
import React, { useCallback, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { arrayMove } from '@dnd-kit/sortable';
import { getOrderAfter, setOrder } from '../../../common/got-adjunct';
import {
    MEETING_TOP_EDGE,
    OPP_TRACKING,
    TOP_ATTACHMENT_EDGE,
    useGraph,
} from '../../../common/hooks.config.new';
import { ListRow } from '../../Common/ListRow';
import { newId, newIsoDate, newMsDate, topHierarchy } from '../../../common/util';
import { useTopBusinessIds } from '../../../hooks/useTopBusinessIds';
import { useMeetingTops } from '../../../hooks/useMeetingTops';
import { ProtocolTop } from './ProtocolTop';
import { useModal } from '../../../hooks/useModal';
import { SortableItem, SortableList } from '../../Common/SortableList';
import { LoadAttachments } from '../HasAttachments';

export const MeetingTops = ({ stack, meetingId, inheritFromId }) => {
    const sortedTopBags = useMeetingTops(stack, meetingId);

    const { add, remove, inheritRights, mergeGraph, useVar } = useGraph(...stack);

    const openScorecardModal = useModal('scoreCard');
    const openProjectActionsModal = useModal('project-actions');
    const openProjectOppsModal = useModal('project-opps');
    const openAddAttachmentsModal = useModal('attachments');
    const [oppTracking] = useVar(OPP_TRACKING);

    useTopBusinessIds(stack, sortedTopBags);
    const hierarchy = useMemo(
        () => R.compose(topHierarchy, R.map(R.prop('node')))(sortedTopBags),
        [sortedTopBags],
    );

    const removeTop = useCallback(remove(MEETING_TOP_EDGE)(meetingId), [meetingId]);
    const createTop = order => {
        const newTopId = newId();
        add(MEETING_TOP_EDGE)(meetingId)(
            {
                id: newTopId,
                createdDate: newIsoDate(),
            },
            { order },
        );
        inheritRights(newTopId)(inheritFromId || meetingId);
    };

    const [insertionIndex, setInsertionIndex] = useState();

    useHotkeys(
        'shift+enter',
        () => {
            insertionIndex || insertionIndex === 0
                ? R.compose(createTop, getOrderAfter(insertionIndex))(sortedTopBags)
                : createTop(newMsDate());
        },
        { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] },
    );

    const leftMargin = length => {
        if (length === 3) {
            return 40;
        }
        if (length === 4) {
            return 40;
        }
        if (length === 5) {
            return 80;
        }
        if (length === 6) {
            return 80;
        }
        if (length === 7) {
            return 120;
        }
        if (length === 8) {
            return 120;
        }
        return null;
    };

    const sortedTopNodes = useMemo(() => R.map(R.prop('node'), sortedTopBags), [sortedTopBags]);
    const move = (oldIndex, newIndex) => {
        R.compose(mergeGraph, setOrder(MEETING_TOP_EDGE)(meetingId), R.map(R.prop('id')), items =>
            arrayMove(items, oldIndex, newIndex),
        )(sortedTopNodes);
    };

    if (sortedTopBags.length === 0) {
        return <div className="protocol-notice">Press Shift + Enter to add meeting content</div>;
    }

    return (
        <SortableList
            items={sortedTopNodes}
            onMove={move}
            renderItem={(node, index) => {
                const { id, type } = node;
                return (
                    <SortableItem id={id}>
                        <div style={{ marginLeft: leftMargin(hierarchy[id].length) }}>
                            <ListRow
                                buttonColor={R.includes(type, ['1', '2', '3']) && 'white'}
                                onRemove={() => removeTop(node)}
                                onInsert={() =>
                                    R.compose(createTop, getOrderAfter(index))(sortedTopBags)
                                }
                                opp={type === 'o'}
                                action={type === 'a'}
                                openScorecard={openScorecardModal}
                                openProjectOpps={openProjectOppsModal}
                                openProjectActions={openProjectActionsModal}
                                onAttachmentClick={() =>
                                    openAddAttachmentsModal({
                                        fromId: id,
                                        edgeTypes: TOP_ATTACHMENT_EDGE,
                                        inheritFromId: meetingId,
                                        meetingId,
                                        shouldPush: true,
                                    })
                                }
                                topId={id}
                                style={{
                                    boxShadow:
                                        insertionIndex === index &&
                                        '0px 12px 24px rgba(0, 0, 0, 0.25',
                                    transform: insertionIndex === index && 'scale(1.02)',
                                    transition: '0.3s ease',
                                    marginTop: index === 0 && 18,
                                    overflow: 'hidden',
                                }}
                            >
                                <div
                                    className="top-row"
                                    onFocus={() => setInsertionIndex(index)}
                                    onBlur={() => setInsertionIndex()}
                                >
                                    <div
                                        className={
                                            R.includes(type, ['1', '2', '3'])
                                                ? 'top-hierarchy-headline'
                                                : 'top-hierarchy'
                                        }
                                    >
                                        {hierarchy[id]}
                                    </div>
                                    <ProtocolTop
                                        stack={stack}
                                        topId={id}
                                        oppTracking={oppTracking}
                                        allowDrag={!R.includes(type, ['1', '2', '3'])}
                                        inheritFromId={meetingId}
                                        meetingId={meetingId}
                                        shouldPush
                                    />
                                    <LoadAttachments
                                        stack={stack}
                                        node={node}
                                        edgeTypes={TOP_ATTACHMENT_EDGE}
                                    />
                                </div>
                            </ListRow>
                        </div>
                    </SortableItem>
                );
            }}
        />
    );
};
