import React, { useEffect } from 'react';
import * as R from 'ramda';
import { IoPersonOutline } from 'react-icons/io5';
import {
    CONTACTS_MODAL_MOBILE,
    useGraph,
    PROJECT_ID_KEY,
    EDIT_CONTACT_MODAL_MOBILE,
    MEETING_ATTENDEE_EDGE,
    MEETING_ID_KEY,
} from '../../common/hooks.config.new';
import { useModal, useModalState } from '../../hooks/useModal';
import { ModalMobile } from '../Common/ModalMobile';
import { ListField } from '../../Components/Common/Fields';
import { useProjectContacts } from '../../hooks/useProjectContacts';
import { FancyCheckbox } from '../../Components/Common/Controls';
import { useMeetingAttendees } from '../../hooks/useMeetingAttendees';
import { newMsDate } from '../../common/util';

export const ContactsModalMobile = ({ stack }) => {
    const [isOpen, close] = useModalState(CONTACTS_MODAL_MOBILE);
    const openEditContactModalMobile = useModal(EDIT_CONTACT_MODAL_MOBILE);

    return (
        <ModalMobile
            name={CONTACTS_MODAL_MOBILE}
            add={() => openEditContactModalMobile({ isNew: true })}
        >
            {isOpen ? <ModalContent stack={stack} close={close} /> : null}
        </ModalMobile>
    );
};

const ModalContent = ({ stack: parentStack }) => {
    const stack = R.append(CONTACTS_MODAL_MOBILE, parentStack);
    const { useVar } = useGraph(...stack);
    const { dissoc: dissocProtocol, assoc } = useGraph(...parentStack);

    const [screen] = useModalState(CONTACTS_MODAL_MOBILE);
    const protocol = screen.screen === 'protocol';

    const [projectId] = useVar(PROJECT_ID_KEY);
    const { refresh: refreshContacts, contactBags } = useProjectContacts(stack, projectId);
    useEffect(() => {
        refreshContacts();
    }, [refreshContacts]);
    const projectContacts = R.map(R.prop('node'), contactBags);
    const openEditContactModalMobile = useModal(EDIT_CONTACT_MODAL_MOBILE);

    const [meetingId] = useVar(MEETING_ID_KEY);
    const assocAttendee = protocol && assoc(MEETING_ATTENDEE_EDGE)(meetingId);
    const dissocAttendee = protocol && dissocProtocol(MEETING_ATTENDEE_EDGE)(meetingId);
    const sortedAttendeeBags = useMeetingAttendees(stack, meetingId);
    const attendees = R.map(R.prop('node'))(sortedAttendeeBags);

    return (
        <>
            <div className="modal-headline-mobile">
                Project Contacts
                <div className="modal-headline-filler" />
            </div>
            <ListField className style={{ flex: 1, flexBasis: 150 }}>
                {R.map(contact => (
                    <ContactElement
                        key={contact.id}
                        contact={contact}
                        protocol={protocol}
                        openEditContactModalMobile={openEditContactModalMobile}
                        attendees={attendees}
                        assocAttendee={assocAttendee}
                        dissocAttendee={dissocAttendee}
                    />
                ))(projectContacts)}
            </ListField>
            <div style={{ height: '20vh' }} />
        </>
    );
};

const ContactElement = ({
    contact,
    openEditContactModalMobile,
    protocol,
    attendees,
    assocAttendee,
    dissocAttendee,
}) => (
    <div
        key={contact.id}
        className="row-mobile hover-effect"
        style={{ paddingRight: '2rem' }}
        onClick={e => {
            e.stopPropagation();
            openEditContactModalMobile({ contact });
        }}
    >
        <IoPersonOutline
            size="5rem"
            color="var(--corporate-color-2-light)"
            style={{ marginRight: '2rem' }}
        />
        <div className="row-mobile-content">
            <div className="main-title">{contact.name}</div>
            <div className="sub-title">{contact.email}</div>
        </div>
        {protocol && (
            <FancyCheckbox
                placeholderStyle={{ fontSize: '3.5rem' }}
                labelStyle={{ marginLeft: 20 }}
                mobile
                onChange={() => {
                    R.includes(contact, attendees)
                        ? dissocAttendee(contact)
                        : assocAttendee(contact, { order: newMsDate() });
                }}
                value={R.includes(contact, attendees)}
            />
        )}
    </div>
);
