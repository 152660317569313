import * as R from 'ramda';
import React, { useState } from 'react';
import {
    IoPeopleOutline,
    IoHomeOutline,
    IoDocumentsOutline,
    IoDocumentLockOutline,
    IoChevronBackOutline,
    IoAddOutline,
    IoSearchOutline,
    IoPersonOutline,
    IoRemoveOutline,
    IoAppsOutline,
    IoDocumentTextOutline,
    IoGitNetworkOutline,
    IoLogOutOutline,
    IoSettingsOutline,
    IoCloudUploadOutline,
} from 'react-icons/io5';
import { VscCircleOutline } from 'react-icons/vsc';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { animated, useSpring } from 'react-spring';
import { useAuth } from '../../common/auth';
import { createDummyGraph } from '../../common/got-adjunct';
import logo from '../../logos/pclogo.png';
import {
    CONTACTS_MODAL_MOBILE,
    DASHBOARD_FILTER_KEY,
    DRAFT_MEETINGS_MODAL_MOBILE,
    EDIT_CONTACT_MODAL_MOBILE,
    EDIT_DASHBOARD_TOP_MODAL_MOBILE,
    EDIT_ORG_MODAL_MOBILE,
    EDIT_PROJECT_MODAL_MOBILE,
    EDIT_SUBPROJECT_MODAL_MOBILE,
    MEETING_TOP_EDGE,
    OFFLINE,
    ORGANIZATIONS_MODAL_MOBILE,
    ORGANIZATION_ID_KEY,
    PROJECTS_MODAL_MOBILE,
    PROJECT_ID_KEY,
    PROTOCOL,
    SEARCH_STRING_KEY,
    SETTINGS_MODAL_MOBILE,
    store,
    SUBMITTED_MEETINGS_MODAL_MOBILE,
    SUBPROJECTS_MODAL_MOBILE,
    SUBPROJECT_ID_KEY,
    SUBPROJECT_MEETING_EDGE,
    TEMPLATES_MODAL_MOBILE,
    TOPS_TO_UPDATE_KEY,
    TOP_RESPONSIBLE_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { Button } from '../../Components/Common/Controls';
import { useModal } from '../../hooks/useModal';
import { useNavigator } from '../../hooks/useNavigator';
import { useToast } from '../../hooks/useToast';
import { useUserEmail } from '../../hooks/useUserEmail';
import { ClickAnimator } from './ClickAnimator';
import { FilterFlyout } from './FilterFlyoutMobile';
import { OfflineSyncerMobile } from './OfflineSyncerMobile';
import { newId, newIsoDate, newMsDate } from '../../common/util';
import { ALWAYS_PULL, useSettings } from '../../hooks/useSettings';
import { RefreshButton } from '../../Components/Elements/ProjectDashboard/RefreshButton';

export const ProjectDashboardMenus = ({ stack }) => {
    const [navMenu, setNavMenu] = useState(false);
    const [addMenu, setAddMenu] = useState(false);
    const [filterMenu, setFilterMenu] = useState(false);
    const { useVar } = useGraph(...stack);
    const [organizationId] = useVar(ORGANIZATION_ID_KEY);
    const [projectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);

    return (
        <>
            <NavMenuFlyout
                stack={stack}
                navMenu={navMenu}
                setNavMenu={setNavMenu}
                subprojectId={subprojectId}
                projectId={projectId}
                organizationId={organizationId}
            />
            <MenuButtonsRow
                stack={stack}
                navMenu={navMenu}
                addMenu={addMenu}
                filterMenu={filterMenu}
                setNavMenu={setNavMenu}
                setAddMenu={setAddMenu}
                setFilterMenu={setFilterMenu}
                projectId={projectId}
            />
            <AddContentFlyout
                stack={stack}
                addMenu={addMenu}
                setAddMenu={setAddMenu}
                navMenu={navMenu}
                filterMenu={filterMenu}
                subprojectId={subprojectId}
                projectId={projectId}
                organizationId={organizationId}
            />
            <FilterFlyout stack={stack} filterMenu={filterMenu} setFilterMenu={setFilterMenu} />
            {filterMenu && (
                <div
                    className="flyout-backdrop"
                    onClick={() => {
                        filterMenu && setFilterMenu(false);
                    }}
                />
            )}
        </>
    );
};

const MenuButtonsRow = ({
    stack,
    navMenu,
    addMenu,
    filterMenu,
    setNavMenu,
    setAddMenu,
    setFilterMenu,
    projectId,
}) => {
    const { useVar } = useGraph(...stack);
    const openModals = R.compose(
        R.filter(m => m !== false),
        R.values,
        R.dissoc('loaded-projects'),
    )(useSelector(R.propOr({}, 'modals')));

    const hasOpenModals = R.length(openModals) > 0;
    const menuButtons = useSpring({
        opacity: navMenu || filterMenu || hasOpenModals ? 0 : 1,
    });
    const addItemButtonStyle = useSpring({
        transform: addMenu ? 'rotate(-45deg)' : 'rotate(0deg',
        color: addMenu ? 'rgb(210, 75, 93)' : 'white',
    });

    const [filter] = useVar(DASHBOARD_FILTER_KEY);
    const [searchString] = useVar(SEARCH_STRING_KEY);

    const filterValuesSum = R.sum([
        R.length(R.pathOr([], ['topType', 'values'], filter)),
        R.length(R.pathOr([], ['meetingType', 'values'], filter)),
        R.length(R.pathOr([], ['oppStatus', 'values'], filter)),
        R.length(R.pathOr([], ['actionStatus', 'values'], filter)),
        R.length(R.pathOr([], ['category', 'values'], filter)),
        R.length(R.pathOr([], ['responsible', 'values'], filter)),
        R.length(searchString),
    ]);
    const filterIsActive =
        filterValuesSum > 0 ||
        (filter?.meetingDate && !R.isEmpty(filter?.meetingDate)) ||
        (filter?.dueDate && !R.isEmpty(filter?.dueDate)) ||
        filter?.isCritical ||
        filter?.sort;

    const setOfflineSyncToast = useToast('mobile-project-dashboard');
    const setEditMessageToast = useToast('mobile-project-dashboard');
    const editMessage = () => {
        setEditMessageToast('hint', 'Select a project first.', 5000);
    };

    return (
        <animated.div className="menu-button-row" style={menuButtons}>
            <Button
                className="menu-button"
                style={{ backgroundColor: 'var(--corporate-color-1)' }}
                onClick={() => {
                    setNavMenu(!navMenu);
                    addMenu && setAddMenu(false);
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        color: 'white',
                    }}
                >
                    <IoRemoveOutline size="8rem" style={{ position: 'relative', top: '6.7rem' }} />
                    <IoRemoveOutline size="8rem" />
                    <IoRemoveOutline
                        size="8rem"
                        style={{ position: 'relative', bottom: '6.7rem' }}
                    />
                </div>
            </Button>
            <ClickAnimator
                content={
                    <Button
                        className="add-button"
                        style={{ backgroundColor: 'var(--corporate-color-1)' }}
                        onClick={() => setAddMenu(!addMenu)}
                    >
                        <animated.div style={addItemButtonStyle}>
                            <IoAddOutline size="8rem" />
                        </animated.div>
                    </Button>
                }
                positioning={{ position: 'absolute', right: '0rem', bottom: '0rem' }}
            />
            <div className="search-button-container">
                <Button
                    onClick={() => (projectId ? setFilterMenu(!filterMenu) : editMessage())}
                    className="search-button-db"
                    style={{
                        boxShadow:
                            filterIsActive && '0 0 2rem 0.25rem var(--corporate-color-13-light2)',
                        backgroundColor: 'var(--corporate-color-1)',
                    }}
                >
                    <div className="search-button-icon-container">
                        <IoSearchOutline
                            size="5rem"
                            color={filterIsActive ? 'var(--corporate-color-13)' : 'white'}
                        />
                    </div>
                </Button>
            </div>
            <OfflineSyncerMobile
                graphNames={[OFFLINE, PROTOCOL]}
                onProgress={() => setOfflineSyncToast('spinner', 'Syncing offline data...')}
                onSuccess={() => setOfflineSyncToast('success', 'Successfully saved.', 5000)}
                onError={error => {
                    if (error.status) {
                        setOfflineSyncToast(
                            'error',
                            error.message ? error.message : error.toString(),
                        );
                    } else {
                        setOfflineSyncToast(
                            'error',
                            'You are offline. Please sync when back online.',
                            5000,
                        );
                    }
                }}
            >
                <IoCloudUploadOutline size="5rem" />
            </OfflineSyncerMobile>
        </animated.div>
    );
};

const NavMenuFlyout = ({ stack, navMenu, setNavMenu, projectId, organizationId }) => {
    const { useVar } = useGraph(...stack);
    const navMenuFlyout = useSpring({
        width: '97.5vw',
        left: navMenu ? '0vw' : '-100vw',
    });
    const history = useHistory();
    const setLoading = useModal('loader');
    const [, setSearchString] = useVar(SEARCH_STRING_KEY);
    const openSubmittedMeetingsModalMobile = useModal(SUBMITTED_MEETINGS_MODAL_MOBILE);
    const openDraftMeetingsModalMobile = useModal(DRAFT_MEETINGS_MODAL_MOBILE);
    const openTemplatesModalMobile = useModal(TEMPLATES_MODAL_MOBILE);
    const openContactsModalMobile = useModal(CONTACTS_MODAL_MOBILE);
    const openSuprojectsModalMobile = useModal(SUBPROJECTS_MODAL_MOBILE);
    const openProjectsModalMobile = useModal(PROJECTS_MODAL_MOBILE);
    const openOrganizationsModalMobile = useModal(ORGANIZATIONS_MODAL_MOBILE);
    const openSettingsModalMobile = useModal(SETTINGS_MODAL_MOBILE);
    const [, , logout] = useAuth(
        'Offline data will be lost on logout. Make sure there is no blue save icon in the bottom middle!',
    );
    const setEditMessageToast = useToast('mobile-project-dashboard');
    const editMessage = message => {
        setEditMessageToast('hint', message || 'Select a subproject to edit this item.', 5000);
    };
    const [settings] = useSettings();
    return (
        <animated.div className="nav-menu-flyout-container" style={navMenuFlyout}>
            <Button
                className="menu-button"
                style={{ left: '1rem', bottom: '2rem', boxShadow: 'none' }}
                onClick={() => setNavMenu(false)}
            >
                <IoChevronBackOutline size="6rem" color="var(--corporate-color-2)" />
            </Button>
            <div className="nav-menu-content">
                <div className="nav-menu-header">
                    <img
                        alt="logo"
                        src={logo}
                        style={{
                            width: '22.5rem',
                        }}
                    />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {!R.propOr(true, ALWAYS_PULL, settings) && (
                            <RefreshButton stack={stack} mobile />
                        )}
                        <Button
                            onClick={() => {
                                setNavMenu(false);
                                openSettingsModalMobile();
                            }}
                            className="logout-button-mobile"
                        >
                            <IoSettingsOutline size="5rem" color="var(--corporate-color-2)" />
                        </Button>
                        <Button onClick={logout} className="logout-button-mobile">
                            <IoLogOutOutline size="5.5rem" color="var(--corporate-color-2)" />
                        </Button>
                    </div>
                </div>
                <div
                    onClick={() => {
                        setLoading();
                        setNavMenu(false);
                        setTimeout(() => {
                            setSearchString('');
                            history.push('global-dashboard');
                        }, 750);
                    }}
                    className="nav-menu-row"
                    style={{
                        backgroundColor: 'var(--corporate-color-1)',
                        color: 'var(--corporate-color-7)',
                    }}
                >
                    <IoAppsOutline size="5.5rem" />
                    <p className="menu-entry">Global Dashboard</p>
                </div>
                <div
                    onClick={() => {
                        setNavMenu(false);
                        openOrganizationsModalMobile();
                    }}
                    className="nav-menu-row"
                >
                    <IoHomeOutline size="5.5rem" />
                    <p className="menu-entry">Organisations</p>
                </div>
                <div
                    className="nav-menu-row"
                    onClick={() => {
                        setNavMenu(false);
                        organizationId
                            ? openProjectsModalMobile()
                            : editMessage('Select an organization to see its projects.');
                    }}
                >
                    <IoGitNetworkOutline size="5.5rem" style={{ transform: 'rotate(90deg)' }} />
                    <p className="menu-entry">Projects</p>
                </div>
                <div
                    className="nav-menu-row"
                    onClick={() => {
                        setNavMenu(false);
                        projectId
                            ? openSuprojectsModalMobile()
                            : editMessage('Select a project to see its subprojects.');
                    }}
                >
                    <VscCircleOutline size="4rem" style={{ marginLeft: '0.75rem' }} />
                    <p className="menu-entry">Subprojects</p>
                </div>
                <div
                    className="nav-menu-row"
                    onClick={() => {
                        setNavMenu(false);
                        projectId
                            ? openContactsModalMobile({ screen: 'dashboard' })
                            : editMessage('Select a project to see its contacts.');
                    }}
                >
                    <IoPeopleOutline size="5.5rem" />
                    <p className="menu-entry">Project Contacts</p>
                </div>
                <div
                    className="nav-menu-row"
                    onClick={() => {
                        setNavMenu(false);
                        projectId
                            ? openTemplatesModalMobile()
                            : editMessage('Select a project first.');
                    }}
                >
                    <IoDocumentsOutline size="5.5rem" />
                    <p className="menu-entry">Meeting Templates</p>
                </div>
                <div
                    className="nav-menu-row"
                    onClick={() => {
                        setNavMenu(false);
                        projectId
                            ? openDraftMeetingsModalMobile()
                            : editMessage('Select a project first.');
                    }}
                >
                    <IoDocumentTextOutline size="5.5rem" />
                    <p className="menu-entry">Draft Meetings</p>
                </div>
                <div
                    className="nav-menu-row"
                    onClick={() => {
                        setNavMenu(false);
                        projectId
                            ? openSubmittedMeetingsModalMobile()
                            : editMessage('Select a project first.');
                    }}
                >
                    <IoDocumentLockOutline size="5.5rem" />
                    <p className="menu-entry">Submitted Meetings</p>
                </div>
            </div>
        </animated.div>
    );
};

const AddContentFlyout = ({
    stack,
    addMenu,
    setAddMenu,
    navMenu,
    filterMenu,
    subprojectId,
    projectId,
    organizationId,
}) => {
    const openEditOrgModalMobile = useModal(EDIT_ORG_MODAL_MOBILE);
    const openEditProjectModalMobile = useModal(EDIT_PROJECT_MODAL_MOBILE);
    const openEditSubprojectModalMobile = useModal(EDIT_SUBPROJECT_MODAL_MOBILE);
    const openEditContactModalMobile = useModal(EDIT_CONTACT_MODAL_MOBILE);
    const openEditDashboardTopModalMobile = useModal(EDIT_DASHBOARD_TOP_MODAL_MOBILE);
    const menuButtons = useSpring({
        opacity: navMenu || filterMenu ? 0 : 1,
    });
    const { navigateToProtocol } = useNavigator(stack);

    const createNewMeeting = () => {
        const topsToUpdate = store.getVar(...stack)(TOPS_TO_UPDATE_KEY);
        const { add, inheritRights, assoc, getGraph } = createDummyGraph();

        const newMeetingId = newId();
        const newMeeting = {
            id: newMeetingId,
            submitted: false,
        };
        add(SUBPROJECT_MEETING_EDGE)(subprojectId)(newMeeting);
        inheritRights(newMeetingId)(subprojectId);

        R.forEach(({ top, responsible }) => {
            const newTopId = newId();
            const newTop = R.compose(
                R.assoc('modifiedDate', newIsoDate()),
                R.assoc('id', newTopId),
            )(top);
            add(MEETING_TOP_EDGE)(newMeetingId)(newTop, { order: newMsDate() });
            inheritRights(newTopId)(subprojectId);
            responsible && assoc(TOP_RESPONSIBLE_EDGE)(newTopId)(responsible);
        })(R.values(topsToUpdate));

        store.mergeGraph(getGraph(), PROTOCOL);
        navigateToProtocol(newMeetingId);
    };
    const user = useUserEmail();

    const setEditMessageToast = useToast('mobile-project-dashboard');
    const editMessage = message => {
        setEditMessageToast('hint', message || 'Select a subproject to edit this item.', 5000);
    };

    return (
        addMenu && (
            <animated.div style={menuButtons}>
                <Button
                    className="item-button item-button-a"
                    style={{
                        backgroundColor: 'var(--corporate-color-1)',
                        color: 'var(--corporate-color-7)',
                        bottom: '75rem',
                    }}
                    onClick={() => {
                        subprojectId && setAddMenu(false);
                        subprojectId
                            ? createNewMeeting()
                            : editMessage('Select a subproject to create a new meeting.');
                    }}
                >
                    <div>
                        <IoDocumentTextOutline size="5rem" />
                    </div>
                </Button>
                <Button
                    className="item-button item-button-o"
                    style={{ bottom: '63rem', backgroundColor: 'white' }}
                    onClick={() => {
                        subprojectId && setAddMenu(false);
                        subprojectId
                            ? openEditDashboardTopModalMobile({ isNew: true, user })
                            : editMessage('Select a subproject to create a new item.');
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingRight: '0.3rem',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <p
                                style={{
                                    color: 'var(--corporate-color-14)',
                                    fontSize: '2.5rem',
                                    fontWeight: 'bold',
                                }}
                            >
                                A
                            </p>
                            <p
                                style={{
                                    color: 'var(--corporate-color-13)',
                                    fontSize: '2.5rem',
                                    fontWeight: 'bold',
                                }}
                            >
                                O
                            </p>
                            <p style={{ color: 'grey', fontSize: '2.5rem', fontWeight: 'bold' }}>
                                S
                            </p>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <p
                                style={{
                                    color: 'var(--corporate-color-7)',
                                    fontSize: '2.5rem',
                                    fontWeight: 'bold',
                                }}
                            >
                                D
                            </p>
                            <p style={{ color: '#C5C9D3', fontSize: '2.5rem', fontWeight: 'bold' }}>
                                I
                            </p>
                            <p
                                style={{
                                    color: 'var(--corporate-color-12)',
                                    fontSize: '2.5rem',
                                    fontWeight: 'bold',
                                }}
                            >
                                R
                            </p>
                        </div>
                    </div>
                </Button>
                <Button
                    className="item-button item-button-s"
                    style={{
                        bottom: '51rem',
                        color: 'var(--corporate-color-1)',
                        backgroundColor: 'white',
                    }}
                    onClick={() => {
                        setAddMenu(false);
                        projectId
                            ? openEditContactModalMobile({ isNew: true })
                            : editMessage('Select a project to create a new contact.');
                    }}
                >
                    <div>
                        <IoPersonOutline size="5rem" />
                    </div>
                </Button>
                <Button
                    className="item-button item-button-d"
                    style={{
                        bottom: '39rem',
                        color: 'var(--corporate-color-1)',
                        backgroundColor: 'white',
                    }}
                    onClick={() => {
                        setAddMenu(false);
                        projectId
                            ? openEditSubprojectModalMobile({ isNew: true, user })
                            : editMessage('Select a project to create a new subproject.');
                    }}
                >
                    <div>
                        <VscCircleOutline size="4rem" />
                    </div>
                </Button>
                <Button
                    className="item-button item-button-i"
                    style={{
                        bottom: '27rem',
                        color: 'var(--corporate-color-1)',
                        backgroundColor: 'white',
                    }}
                    onClick={() => {
                        setAddMenu(false);
                        openEditProjectModalMobile({ isNew: true, user });
                    }}
                >
                    <div style={{ transform: 'rotate(90deg)' }}>
                        <IoGitNetworkOutline size="5rem" />
                    </div>
                </Button>
                <Button
                    className="item-button item-button-r"
                    style={{
                        bottom: '15rem',
                        color: 'var(--corporate-color-1)',
                        backgroundColor: 'white',
                    }}
                    onClick={() => {
                        setAddMenu(false);
                        organizationId
                            ? openEditOrgModalMobile({ isNew: true, user })
                            : editMessage('Select an organization to create a new project.');
                    }}
                >
                    <div>
                        <IoHomeOutline size="5rem" />
                    </div>
                </Button>
            </animated.div>
        )
    );
};
