import React from 'react';
import './Screen.css';

export const Screen = ({ topbar, children, lpheight }) => (
    <div className="screen-container">
        <div
            className="topbar-container"
            style={{ height: lpheight, position: 'sticky', top: 0, zIndex: 2 }}
        >
            {topbar}
        </div>
        <div className="content-container">{children}</div>
    </div>
);
