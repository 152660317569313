import axios from 'axios';
import { API_DOMAIN } from './config';
import { api } from './hooks.config.new';

const host = `https://${API_DOMAIN}`;
// const host = 'http://localhost:4000/dev';

export const getSignedUrl = async (nodeId, prop, accept) => {
    if (nodeId && prop) {
        const authHeader = {
            headers: {
                Authorization: await api.getCurrentSession().idToken,
            },
        };

        const {
            data: { url: fileUrl, contentType: fileContentType },
        } = await axios.post(`${host}/media/get-signed-url`, { nodeId, prop }, authHeader);

        if (!accept || fileContentType === accept) {
            return fileUrl;
        }

        return null;
    }

    throw new Error('Invalid Parameters');
};
