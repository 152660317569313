import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './AppMobile.css';
import { GlobalDashboardMobile } from './MobileApp/Screens/GlobalDashboardMobile';
import { ProjectDashboardMobile } from './MobileApp/Screens/ProjectDashboardMobile';
import { ProtocolScreenMobile } from './MobileApp/Screens/ProtocolScreenMobile';
import { useAuth } from './common/auth';
import { AuthScreenMobile } from './MobileApp/Common/AuthScreenMobile';

export const AppMobile = () => {
    const [loggedIn, login] = useAuth();
    if (loggedIn) {
        return (
            <div className="mobile-app-container" style={{ backgroundColor: 'rgba(248,247,248)' }}>
                <Switch>
                    <Route exact path="/global-dashboard">
                        <GlobalDashboardMobile />
                    </Route>
                    <Route exact path="/dashboard">
                        <ProjectDashboardMobile />
                    </Route>
                    <Route exact path="/protocol">
                        <ProtocolScreenMobile />
                    </Route>
                    <Redirect exact from="/*" to="/dashboard" />
                </Switch>
            </div>
        );
    }

    return (
        <Route exact path="/*">
            <AuthScreenMobile onLoggedIn={() => login()} />
        </Route>
    );
};
