import * as R from 'ramda';
import { useEffect, useRef } from 'react';
import { newId } from '../common/util';
import { useNode } from './useNode';
import { useSubprojectMeetings } from './useSubprojectMeetings';

export const useMeetingId = (stack, projectId, subprojectId, meeting, updateMeeting) => {
    const { meetingBags } = useSubprojectMeetings(stack, subprojectId);
    const { node: project } = useNode(stack, projectId);
    const notInitialRender = useRef(true);

    const meetingTypes = R.propOr([], 'meetingTypes', project);

    // recursive meeting type shortcut creation
    const meetingTypeShortcuts = (redundancyLevel = 0, types = meetingTypes, newIndexes = []) => {
        const rl = redundancyLevel + 1;
        const shortcuts = meetingTypes.map(mt =>
            mt
                .split(' ')
                .map(splitted => splitted.slice(0, 1))
                .join(''),
        );

        const findDuplicateIndexes = shortcuts
            .map((item, index) => (shortcuts.indexOf(item) !== index ? index : null))
            .filter(t => t);

        const duplicateIndexes = newIndexes.length === 0 ? findDuplicateIndexes : newIndexes;

        const fixDuplicates = meetingTypes.map((mt, i) =>
            !R.includes(i, duplicateIndexes)
                ? rl === 1
                    ? shortcuts[i]
                    : types[i]
                : mt
                      .split(' ')
                      .map(splitted => splitted.slice(0, rl + 1))
                      .join(''),
        );

        const findNewDuplicateIndexes = fixDuplicates
            .map((item, index) => (fixDuplicates.indexOf(item) !== index ? index : null))
            .filter(t => t);

        if (findNewDuplicateIndexes.length === 0) {
            return fixDuplicates;
        }
        return meetingTypeShortcuts(rl, fixDuplicates, findNewDuplicateIndexes);
    };

    // construct 1 array per meeting type
    const meetingBagsArr = R.values(meetingBags) || [];
    const sortedMeetings = meetingTypes.map(mt =>
        meetingBagsArr.filter(mb => (mb.node.type === mt ? mt : null)),
    );

    // index for comparison
    const typeIndex = meeting ? meetingTypes.findIndex(mt => mt === meeting.type) : null;
    const finalShortcuts = meetingTypeShortcuts();

    // construct meeting ID
    const idTag = finalShortcuts[typeIndex];
    const idNumber = sortedMeetings[typeIndex]
        ? R.tail(R.tail((sortedMeetings[typeIndex].length / 1000).toString()))
        : '000';
    const uuidBackup = newId().slice(0, 4);
    const meetingBusinessId = `${idTag}-${idNumber}-${uuidBackup}`;

    useEffect(() => {
        if (notInitialRender.current) {
            notInitialRender.current = false;
        } else {
            meeting &&
                meeting.type &&
                updateMeeting(R.assoc('businessId', meetingBusinessId, meeting));
        }
    }, [meeting && meeting.type]);
};
