import React from 'react';
import { IoAddOutline } from 'react-icons/io5';
import * as R from 'ramda';
import { PROTOCOL, store, TOPS_TO_UPDATE_KEY } from '../../../common/hooks.config.new';
import { Button } from '../../Common/Controls';
import { useNavigator } from '../../../hooks/useNavigator';
import { newId } from '../../../common/util';
import { useCreateHistory } from '../../../hooks/useCreateHistory';

export const NewMeetingButton = ({ stack }) => {
    const { navigateToProtocol } = useNavigator(stack);

    const createHistories = useCreateHistory([...stack, PROTOCOL], {
        dashboard: false,
        varUpdates: false,
    });

    const createNewMeeting = async () => {
        const topsToUpdate = store.getVar(...stack)(TOPS_TO_UPDATE_KEY);

        const newMeetingId = newId();
        const newMeeting = {
            id: newMeetingId,
            submitted: false,
        };
        await createHistories(R.values(topsToUpdate), {
            meeting: newMeeting,
        });
        navigateToProtocol(newMeetingId);
    };

    return (
        <Button onClick={() => createNewMeeting()} style={{ marginLeft: 6, paddingLeft: 6 }}>
            <div className="button-icon">
                <IoAddOutline size={29} color="var(--corporate-color-1)" />
                <p style={{ marginLeft: 3 }}>New protocol</p>
            </div>
        </Button>
    );
};
