import axios from 'axios';
import JSZip from 'jszip';
import * as R from 'ramda';
import { getSignedUrl } from './hooks.config';
import {
    MEETING_TOP_EDGE,
    PROJECT_SUBPROJECT_EDGE,
    store,
    SUBPROJECT_MEETING_EDGE,
} from './hooks.config.new';
import { newId } from './util';

export const STATES = {
    PULLING: 'pulling',
    LOADING: 'loading',
    GENERATING: 'generating',
    DOWNLOADING: 'downloading',
};

export const downloadDataUri = (fileName, dataUri) => {
    const elementId = `a-${newId()}`;
    const link = document.createElement('a');
    link.id = elementId;
    link.hidden = true;
    link.href = dataUri;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
};

export const downloadPDFsAsZip = async (
    meetingBags = [],
    filename = 'minutes.zip',
    subscriber = {},
) => {
    const { next = () => {}, error = () => {}, complete = () => {} } = subscriber;

    try {
        // emitting progress = 0 event
        let completed = 0;
        next({ type: STATES.LOADING, progress: [completed, R.length(meetingBags)] });

        // downloading protocols and adding them to zip
        const zip = new JSZip();
        await Promise.all(
            R.map(async meetingBag => {
                try {
                    // getting signed url, later to be replaced by getting url from filebag
                    const url = await getSignedUrl(meetingBag.nodeId, 'protocol');

                    // downloading pdf
                    const { data: arrayBuffer } = await axios.get(url, {
                        responseType: 'arraybuffer',
                    });

                    // adding pdf in `all` folder
                    const protocolName = `${R.pathOr(
                        'undefined-businessId',
                        ['node', 'businessId'],
                        meetingBag,
                    )}.pdf`;
                    zip.folder('_all').file(protocolName, arrayBuffer);
                    // adding pds to folders of all categories present in the meeting
                    const categories = R.propOr([], 'categories', meetingBag);
                    if (R.length(categories) > 0) {
                        R.forEach(
                            category => zip.folder(category).file(protocolName, arrayBuffer),
                            categories,
                        );
                    } else {
                        zip.folder('_no-category').file(protocolName, arrayBuffer);
                    }
                } catch (err) {
                    const status = R.path(['response', 'status'], err);
                    // 404 just means there was no protocol attached, anything else might be more serious
                    status !== 404 && console.error(err);
                }

                // emitting progress event
                completed += 1;
                next({ type: STATES.LOADING, progress: [completed, R.length(meetingBags)] });
                return null;
            })(meetingBags),
        );

        next({ type: STATES.GENERATING });

        const base64string = await zip.generateAsync({ type: 'base64' });
        next({ type: STATES.DOWNLOADING });
        downloadDataUri(filename, `data:application/zip;base64,${base64string}`);
        // emitting complete event
        complete();
    } catch (err) {
        // emitting error event
        error(err);
    }
};

const createProjectMeetingView = projectId =>
    projectId
        ? {
              [projectId]: {
                  as: 'project',
                  include: {
                      node: true,
                  },
                  edges: {
                      [PROJECT_SUBPROJECT_EDGE]: {
                          as: 'subprojects',
                          include: {
                              edges: true,
                              node: true,
                          },
                          edges: {
                              [SUBPROJECT_MEETING_EDGE]: {
                                  as: 'meetings',
                                  include: {
                                      edges: true,
                                      node: true,
                                  },
                                  edges: {
                                      [MEETING_TOP_EDGE]: {
                                          as: 'tops',
                                          include: {
                                              node: true,
                                              edges: true,
                                          },
                                      },
                                  },
                              },
                          },
                      },
                  },
              },
          }
        : {};

const createSubprojectMeetingView = subProjectId =>
    subProjectId
        ? {
              [subProjectId]: {
                  as: 'subproject',
                  edges: {
                      [SUBPROJECT_MEETING_EDGE]: {
                          as: 'meetings',
                          include: {
                              node: true,
                          },
                          edges: {
                              [MEETING_TOP_EDGE]: {
                                  as: 'tops',
                                  include: {
                                      node: true,
                                  },
                              },
                          },
                      },
                  },
              },
          }
        : {};

const selectMeetingCategories = meetingBag => {
    const tops = R.compose(R.values, R.propOr({}, 'tops'))(meetingBag);

    let categories = {};
    R.forEach(top => {
        const category = R.path(['node', 'category'], top);
        if (category && R.pathOr(false, ['node', 'type'], top) === 'o') {
            categories = R.assoc(category, true, categories);
        }
    })(tops);

    return R.keys(categories);
};

const selectSubprojectMeetings = subprojectBag =>
    R.compose(
        R.map(meetingBag =>
            R.compose(
                R.dissoc('tops'),
                R.assoc('categories', selectMeetingCategories(meetingBag)),
            )(meetingBag),
        ),
        R.values,
        R.propOr({}, 'meetings'),
    )(subprojectBag);

export const subprojectZipDowload = async (stack, subProjectId, filename, subscriber = {}) => {
    const view = createSubprojectMeetingView(subProjectId);
    await store.pull(view);
    const meetingBags = R.compose(
        selectSubprojectMeetings,
        R.propOr({}, 'subproject'),
        store.getView(...stack),
    )(view);

    return downloadPDFsAsZip(meetingBags, filename, subscriber);
};

export const projectZipDowload = async (stack, projectId, filename, subscriber = {}) => {
    const { next = () => {} } = subscriber;
    next({ type: STATES.PULLING });
    const view = createProjectMeetingView(projectId);
    await store.pull(view);
    const meetingBags = R.compose(
        R.unnest,
        R.map(selectSubprojectMeetings),
        R.values,
        R.pathOr({}, ['project', 'subprojects']),
        store.getView(...stack),
    )(view);

    return downloadPDFsAsZip(meetingBags, filename, subscriber);
};
