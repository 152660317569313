import React from 'react';
import * as R from 'ramda';
import { IoCreateOutline, IoSearchOutline, IoTrashOutline } from 'react-icons/io5';
import {
    MAIN,
    store,
    TOP_ATTACHMENT_EDGE,
    TOPS_TO_UPDATE_KEY,
} from '../../common/hooks.config.new';
import { useModal, useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { Toast } from '../Elements/Toast';
import { ListRow } from '../Common/ListRow';
import { Button } from '../Common/Controls';
import { TopButtonRow } from '../Elements/TopButtonRow';
import { DashboardTop } from '../Elements/ProjectDashboard/DashboardTop';
import { LoadAttachments } from '../Elements/HasAttachments';

export const TopsToUpdateModal = ({ stack, onSave }) => {
    const [isOpen, close] = useModalState('topsToUpdate');
    return (
        <Modal
            name="topsToUpdate"
            style={{
                height: '94vh',
                top: '3vh',
                width: '90vw',
                left: '5vw',
            }}
            topbar={<IoSearchOutline size={24} color="var(--corporate-color-7)" />}
        >
            {isOpen ? (
                <TopsToUpdateModalContent stack={stack} onSave={onSave} close={close} />
            ) : null}
        </Modal>
    );
};

const TopsToUpdateModalContent = ({ stack, close }) => {
    const topsToUpdate = R.values(store.getVar(...stack)(TOPS_TO_UPDATE_KEY));
    const openEditTopsModal = useModal('editTops');

    return (
        <>
            <TopsList stack={stack} topsToUpdate={topsToUpdate} />
            <Buttons
                close={close}
                topsToUpdate={topsToUpdate}
                openEditTopsModal={openEditTopsModal}
            />
            <Toast name="editTop" />
        </>
    );
};

const TopsList = ({ stack, topsToUpdate }) =>
    R.length(topsToUpdate) > 0 && (
        <div className="columns">
            <div>
                <div className="modal-headline">
                    <div>Items to edit</div>
                </div>
                {R.map(t => (
                    <ListRow key={t.top.id}>
                        <div className="top-search-row">
                            <TopButtonRow top={t.top} stack={stack} noEdit />
                            <DashboardTop stack={stack} topId={t.top.id} noButton global />
                            <LoadAttachments
                                stack={stack}
                                node={t.top}
                                edgeTypes={TOP_ATTACHMENT_EDGE}
                            />
                        </div>
                    </ListRow>
                ))(topsToUpdate)}
            </div>
        </div>
    );

const Buttons = ({ close, topsToUpdate, openEditTopsModal }) => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 18,
        }}
    >
        {R.length(topsToUpdate) > 0 ? (
            <div style={{ display: 'flex' }}>
                <Button
                    onClick={() => {
                        store.setVar(MAIN)(TOPS_TO_UPDATE_KEY, {});
                        close();
                    }}
                    style={{ color: 'var(--corporate-color-10)' }}
                >
                    <IoTrashOutline size={24} />
                    <p style={{ fontSize: 16, marginLeft: 9 }}>Remove Items</p>
                </Button>
                <Button
                    onClick={() => {
                        close();
                        openEditTopsModal();
                    }}
                    style={{ marginLeft: 24 }}
                >
                    <IoCreateOutline size={24} color="var(--corporate-color-1)" />
                    <p style={{ fontSize: 16, marginLeft: 9 }}>Edit Items</p>
                </Button>
            </div>
        ) : (
            <p style={{ fontSize: 28, color: 'var(--corporate-color-1)', fontWeight: 100 }}>
                Add items to edit
            </p>
        )}
    </div>
);
