import React, { useState } from 'react';
import * as R from 'ramda';
import { useNode } from '../../hooks/useNode';

export const TopButtonRowMobile = ({ top, stack, global }) => {
    const [menu] = useState(false);
    const { node: project } = useNode(stack, top.projectId);
    // const getColor = type => {
    //     switch (type) {
    //         case 'a':
    //             return 'var(--corporate-color-14)';
    //         case 'o':
    //             return 'var(--corporate-color-13)';
    //         case 's':
    //             return 'grey';
    //         case 'i':
    //             return '#C5C9D3';
    //         case 'd':
    //             return 'var(--corporate-color-7)';
    //         case 'r':
    //             return 'var(--corporate-color-12)';
    //         default:
    //             return undefined;
    //     }
    // };

    return (
        <div
            className="dashboard-top-button-row-mobile"
            style={{
                paddingRight: 1,
                alignItems: menu && 'start',
                backgroundColor: 'var(--corporate-color-5l)',
                color: 'var(--corporate-color-1)',
                border: 'none',
            }}
        >
            {!global && (
                <div
                    className="top-business-id"
                    style={{
                        color: 'var(--color-1)',
                        display: 'flex',
                        height: '4rem',
                        alignItems: 'center',
                        fontSize: '2.25rem',
                        fontWeight: 'normal',
                    }}
                >
                    {top.businessId || R.pathOr('', ['top', 'top', 'businessId'], top)}
                </div>
            )}
            {global && (
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        alignItems: menu ? 'start' : 'center',
                    }}
                >
                    <div
                        className="top-business-id"
                        style={{
                            color: 'var(--corporate-color-1)',
                            display: 'flex',
                            height: '4rem',
                            alignItems: 'center',
                            fontSize: '2.25rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {R.propOr('', 'title', project)}
                    </div>
                </div>
            )}
            {/* {opp && !connectMode && (
            <Button
                onClick={() => {
                    assocAction(opp);
                }}
                className="assoc-button"
                style={{ height: '6rem', justifyContent: 'flex-end' }}
            >
                <IoLinkOutline size="4rem" />
                <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>Add to scorecard</p>
            </Button>
            )}
            {(connectSuccessor || connectPredecessor) && (
            <div style={{ display: 'flex' }}>
                <ConnectPredecessorButton
                    top={top}
                    connectPredecessor={connectPredecessor}
                />
                <ConnectSuccessorButton
                    top={top}
                    connectSuccessor={connectSuccessor}
                />
            </div>
            )}
            {editOnly && (
            <FancyCheckbox
                style={{ color: 'var(--corporate-color-1)' }}
                customHeight={40}
                label="Add to Minute"
                onChange={value => value
                    ? setTopsToUpdate(R.assoc(
                        top.id,
                        { top, responsible, previousVersions },
                        topsToUpdate,
                    ))
                    : setTopsToUpdate(R.dissoc(top.id, topsToUpdate))}
                value={R.propOr(false, top.id, topsToUpdate)}
            />
            )}
            {!menu && !editOnly && !opp
                ? (
                    !showTop && (
                    <Button onClick={() => setMenu(true)} style={{ height: '6rem', justifyContent: 'flex-start', borderRadius: 'var(--space-normbig)' }}>
                        <IoMenuOutline size="4rem" />
                    </Button>
                    )
                )
                : !editOnly && !opp && (
                    <div
                        className="top-menu-container-mobile"
                        style={{ width: '40vw' }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Button
                                onClick={() => setMenu(false)}
                                style={{
                                    height: '6rem', color: 'var(--corporate-color-10)', width: '40vw', justifyContent: 'space-between',
                                }}
                            >
                                <IoCloseOutline size="4rem" />
                                <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>Close menu</p>
                            </Button>
                            {global && (
                                <>
                                    <Button
                                        style={{ height: '6rem', width: '40vw', justifyContent: 'space-between' }}
                                        onClick={() => {
                                            setOrganizationId(top.orgId);
                                            setProjectId(top.projectId);
                                            setSubprojectId(undefined);
                                            setDashboardState('project');
                                            setSearchString(top.node.description);
                                            history.push('dashboard');
                                        }}
                                    >
                                        <BsViewList size="4rem" />
                                        <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>
                                            Show full item
                                        </p>
                                    </Button>
                                    <Button
                                        style={{ height: '6rem', width: '40vw', justifyContent: 'space-between' }}
                                        onClick={() => {
                                            setOrganizationId(top.orgId);
                                            setProjectId(top.projectId);
                                            setSubprojectId(undefined);
                                            setDashboardState('project');
                                            history.push('dashboard');
                                        }}
                                    >
                                        <IoShareSocialOutline size="4rem" />
                                        <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>Open project</p>
                                    </Button>
                                </>
                            )}
                            {kanban
                                && (
                                    <>
                                        <Button
                                            style={{ width: '40vw', height: '6rem', justifyContent: 'space-between' }}
                                            onClick={() => {
                                                openShowTopModal({
                                                    top, meetingId, protocolId,
                                                });
                                                setMenu(false);
                                            }}
                                        >
                                            <IoResizeOutline size="4rem" />
                                            <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>Show full item</p>
                                        </Button>
                                        <Button
                                            style={{ width: '40vw', height: '6rem', justifyContent: 'space-between' }}
                                            onClick={() => {
                                                openMeetingPdfModal({ id: meetingId });
                                                setMenu(false);
                                            }}
                                        >
                                            <IoDocumentAttachOutline size="4rem" />
                                            <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>Show PDF</p>
                                        </Button>
                                    </>
                                )}
                            {topType === 'o' && oppTracking && (
                                <>
                                    {((!scorecard && !opp) || milestone) && (
                                    <Button
                                        onClick={() => {
                                            setMenu(false);
                                            openScorecardModal({ id: top.id });
                                        }}
                                        style={{ height: '6rem', width: '40vw', justifyContent: 'space-between' }}
                                    >
                                        <IoAnalyticsOutline
                                            size="4rem"
                                            color="var(--corporate-color-1)"
                                        />
                                        <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>Scorecard</p>
                                    </Button>
                                    )}
                                    {canWriteProject && !noEdit && (
                                    <Button
                                        onClick={() => {
                                            setMenu(false);
                                            openProjectActionsModal({ top });
                                        }}
                                        style={{ height: '6rem', width: '40vw', justifyContent: 'space-between' }}
                                    >
                                        <IoLinkOutline
                                            size="4rem"
                                            color="var(--corporate-color-1)"
                                        />
                                        <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>Link actions</p>
                                    </Button>
                                    )}
                                </>
                            )}
                            {!global && (
                            <Button
                                onClick={() => {
                                    setMenu(false);
                                    openProjectMilestonesModal({ id: top.id });
                                }}
                                style={{ height: '6rem', width: '40vw', justifyContent: 'space-between' }}
                            >
                                <IoTrailSignOutline size="4rem" />
                                <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>Link milestone</p>
                            </Button>
                            )}
                            {canWriteProject
                    && topType !== 'o'
                    && !noEdit
                    && oppTracking && (
                    <Button
                        onClick={() => {
                            setMenu(false);
                            openProjectOppsModal({ id: top.id, top });
                        }}
                        style={{ height: '6rem', width: '40vw', justifyContent: 'space-between' }}
                    >
                        <IoLinkOutline
                            size="4rem"
                            color="var(--corporate-color-1)"
                        />
                        <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>Link opportunity</p>
                    </Button>
                            )}
                            {canAdminProject && !noEdit && (
                            <Button
                                style={{ height: '6rem', width: '40vw', justifyContent: 'space-between' }}
                                onClick={() => {
                                    setMenu(false);
                                    subprojectId
                                        ? (scorecard
                                            ? openScEditFtBd(top)
                                            : openEditTopsModal({
                                                id: top.id,
                                                top: { top, responsible, previousVersions },
                                            }))
                                        : editMessage();
                                }}
                            >
                                <IoCreateOutline
                                    size="4rem"
                                    color="var(--corporate-color-1)"
                                />
                                <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>Edit item</p>
                            </Button>
                            )}
                            {opp && (
                            <Button
                                onClick={() => {
                                    setMenu(false);
                                    assocAction(opp);
                                }}
                                className="assoc-button"
                                style={{ height: '6rem', width: '40vw', justifyContent: 'flex-end' }}
                            >
                                <IoLinkOutline size="4rem" />
                                <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>Add to scorecard</p>
                            </Button>
                            )}
                            {canAdminProject
                    && (
                    <Button
                        onClick={() => {
                            setMenu(false);
                            openReminderModal({
                                top, responsible, userName,
                            });
                        }}
                        style={{ height: '6rem', width: '40vw', justifyContent: 'space-between' }}
                    >
                        <IoMailOutline
                            size="4rem"
                            color="var(--corporate-color-1)"
                        />
                        <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>Send reminder</p>
                    </Button>
                    )}
                            {canWriteProject && scorecard && actions && (
                            <Button
                                onClick={() => {
                                    setMenu(false);
                                    dissocAction(oppId)({ id: actionId });
                                }}
                                className="dissoc-button"
                                style={{ height: '6rem', width: '40vw', justifyContent: 'space-between' }}
                            >
                                <IoUnlinkOutline size="4rem" />
                                <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>Remove</p>
                            </Button>
                            )}
                            <LinkedTopsButton
                                top={top}
                                setMenu={setMenu}
                            />
                        </div>
                        {!noEdit && (
                        <FancyCheckbox
                            mobile
                            style={{ color: 'var(--corporate-color-1)' }}
                            containerStyle={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}
                            placeholderStyle={{ marginLeft: 6, fontSize: 'var(--font-size-mobile-normal)' }}
                            customHeight="6rem"
                            label="Add to editing list"
                            onChange={value => {
                                if (value) {
                                    setTopsToUpdate(R.assoc(
                                        top.id,
                                        { top, responsible, previousVersions },
                                        topsToUpdate,
                                    ));
                                    setTimeout(() => setMenu(false), 500);
                                } else {
                                    setTopsToUpdate(R.dissoc(top.id, topsToUpdate));
                                    setTimeout(() => setMenu(false), 500);
                                }
                            }}
                            value={R.propOr(false, top.id, topsToUpdate)}
                        />
                        )}
                    </div>
                )} */}
        </div>
    );
};

// const LinkedTopsButton = ({ top, setMenu }) => {
//     const openModal = useModal(TOP_LINKS_MODAL);

//     if (top.type === 'a') {
//         return (
//             <Button
//                 onClick={() => {
//                     setMenu(false);
//                     openModal(top);
//                 }}
//                 style={{ height: '6rem', width: '40vw', justifyContent: 'space-between' }}
//             >
//                 <IoLinkOutline size="4rem" />
//                 <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>Connected actions</p>
//             </Button>
//         );
//     }

//     return null;
// };

// const ConnectPredecessorButton = ({ top, connectPredecessor }) => {
//     if (top.type === 'a' && connectPredecessor) {
//         return (
//             <Button
//                 onClick={() => connectPredecessor({ top })}
//                 style={{ height: '6rem', justifyContent: 'space-between' }}
//                 className="background-color-var-cc13-light-hover"
//             >
//                 <IoArrowForwardOutline size="4rem" />
//                 <IoLinkOutline size="4rem" />
//                 <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>Connect as predecessor</p>
//             </Button>
//         );
//     }

//     return null;
// };

// const ConnectSuccessorButton = ({ top, connectSuccessor }) => {
//     if (top.type === 'a' && connectSuccessor) {
//         return (
//             <Button
//                 onClick={() => connectSuccessor({ top })}
//                 style={{ height: '6rem', justifyContent: 'space-between', marginLeft: 9 }}
//                 className="background-color-var-cc14-light-hover"
//             >
//                 <IoLinkOutline size="4rem" />
//                 <IoArrowForwardOutline size="4rem" />
//                 <p style={{ fontSize: 'var(--font-size-mobile-normal)' }}>Connect as successor</p>
//             </Button>
//         );
//     }

//     return null;
// };
