import * as R from 'ramda';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { useNode } from '../../hooks/useNode';
import './Fields.css';

const defaultDropdownStyles = {
    control: base => ({
        ...base,
        '&:hover': {
            cursor: 'pointer',
            border: '1px solid var(--corporate-color-2)',
        },
        'cursor': 'pointer',
        'borderRadius': 'var(--space-small)',
        'border': '1px solid var(--corporate-color-1-light5)',
        'minHeight': '34px',
        'height': 'fit-content',
        'marginRight': '4px',
        'transition': '0.2s ease',
        'outline': 'none',
    }),
    valueContainer: provided => ({
        ...provided,
        minHeight: '34px',
        height: 'fit-content',
        padding: '0',
        paddingLeft: '6px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    }),
    indicatorsContainer: provided => ({
        ...provided,
        height: '34px',
    }),
    multiValue: provided => ({
        ...provided,
        marginTop: '6px',
        borderRadius: '12px',
        overflow: 'hidden',
    }),
    multiValueRemove: provided => ({
        ...provided,
    }),
    menu: base => ({
        ...base,
        borderRadius: 'var(--space-small)',
        boxShadow: '0px 5px 8px -2px rgba(0, 0, 0, 0.3)',
        overflow: 'hidden',
        animation: 'dropdown 0.3s ease',
    }),
    option: (base, state) => ({
        ...base,
        '&:hover': {
            backgroundColor: 'var(--corporate-color-2)',
            color: 'white',
            cursor: 'pointer',
        },
        'backgroundColor': state.isSelected ? 'var(--corporate-color-5l)' : 'white',
        'color': 'black',
    }),
    input: provided => ({
        ...provided,
        marginTop: '6px',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: state.data.value ? 'black' : '#757575',
    }),
};

export const ResponsibleDropdownField = ({
    stack,
    label,
    value,
    mobile,
    editable = true,
    options,
    onChange,
    className = '',
    labelClass,
    customStyles,
    noBottomLabel,
}) => {
    const { node } = useNode(stack, value);

    if (editable && onChange) {
        const selectedOption = R.compose(
            R.defaultTo({ label: editable && onChange ? `${label}...` : '', value: undefined }),
            R.find(R.propEq('value', value)),
        )(options);

        return (
            <Dropdown
                label={label}
                mobile={mobile}
                selectedOption={selectedOption}
                options={options}
                onChange={onChange}
                className={className}
                labelClass={labelClass}
                customStyles={customStyles}
                noBottomLabel={noBottomLabel}
            />
        );
    }

    return (
        <ValueDisplay
            label={label}
            value={(node && node.name) || ''}
            className={className}
            noBottomLabel={noBottomLabel}
        />
    );
};

export const DropdownField = ({
    label,
    value,
    editable = true,
    options,
    onChange,
    className = '',
    labelClass,
    customWidth,
    customStyles,
    noBottomLabel,
    dropdownDivStyle,
    mobile,
    filter,
}) => {
    if (editable && onChange) {
        const selectedOption = R.compose(
            R.defaultTo({ label: editable && onChange ? `${label}...` : '', value: undefined }),
            R.find(R.propEq('value', value)),
        )(options);

        return (
            <Dropdown
                label={label}
                mobile={mobile}
                filter={filter}
                selectedOption={selectedOption}
                options={options}
                onChange={onChange}
                className={className}
                labelClass={labelClass}
                customWidth={customWidth}
                customStyles={customStyles}
                noBottomLabel={noBottomLabel}
                dropdownDivStyle={dropdownDivStyle}
            />
        );
    }

    return (
        <ValueDisplay
            label={label}
            value={value}
            className={className}
            noBottomLabel={noBottomLabel}
        />
    );
};

export const Dropdown = ({
    label,
    selectedOption,
    options,
    onChange,
    className = '',
    customStyles = defaultDropdownStyles,
    noBottomLabel,
    dropdownDivStyle,
    mobile,
    labelClass,
    customWidth,
    filter,
}) => {
    const customStyle = {
        ...customStyles,
        menuPortal: base => ({
            ...base,
            zIndex: 9,
        }),
    };
    const mobileStyle = {
        ...customStyles,
        menuPortal: base => ({
            ...base,
            zIndex: 9,
            border: 3,
            borderRadius: '2rem',
        }),
        control: base => ({
            ...base,
            width: customWidth || 'calc(100vw - 4rem)',
            height: '8rem',
            marginLeft: !filter && '2rem',
            borderRadius: '2rem',
            borderColor: 'var(--color-2)',
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? 'var(--corporate-color-5l)' : 'white',
            fontSize: '3.5rem',
            color: 'var(--corporate-color-2)',
            fontWeight: 300,
            padding: '1rem',
        }),
        menu: base => ({
            ...base,
            borderRadius: '2rem',
            boxShadow: '0px 5px 8px -2px #757575',
            border: '1px solid #757575',
            overflow: 'hidden',
            animation: 'dropdown 0.3s ease',
            transformOrigin: 'top',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: '3.5rem',
            paddingLeft: '1rem',
            color: state.data.value ? 'black' : '#757575',
        }),
        noOptionsMessage: provided => ({
            ...provided,
            fontSize: '3.5rem',
            paddingLeft: '1rem',
        }),
        input: provided => ({
            ...provided,
            fontSize: '3.5rem',
            height: '7.5rem',
        }),
        valueContainer: provided => ({
            ...provided,
            fontSize: '3.5rem',
            height: '7.5rem',
        }),
        indicatorsContainer: provided => ({
            ...provided,
            display: 'none',
        }),
        menuList: provided => ({
            ...provided,
            minHeight: '400px',
        }),
    };
    const styles = mobile ? mobileStyle : customStyle;
    return (
        <div
            style={dropdownDivStyle}
            className={`field dropdown-field ${className} ${
                selectedOption.value ? 'selected' : ''
            }`}
        >
            <Select
                isSearchable={!mobile}
                mobile={mobile}
                styles={styles}
                menuPortalTarget={document.body}
                value={selectedOption}
                options={options}
                onChange={onChange}
                components={{
                    IndicatorSeparator: () => null,
                }}
            />
            {!noBottomLabel && <div className={`label ${labelClass}`}>{label}</div>}
        </div>
    );
};

const ValueDisplay = ({ label = '', value, className = '', noBottomLabel }) => (
    <div className={`field dropdown-field ${className} ${value ? 'selected' : ''}`}>
        <div style={{ cursor: 'default' }}>{value.name || value}</div>
        {!noBottomLabel && <div className="label">{label}</div>}
    </div>
);

export const MultiDropdownField = ({
    label,
    mobile,
    values,
    options,
    onChange,
    customWidth,
    customStyles = defaultDropdownStyles,
    className = '',
    labelClass,
    dropdownDivStyle,
}) => {
    const customStyle = {
        ...customStyles,
        menuPortal: base => ({
            ...base,
            zIndex: 9,
            border: 0,
        }),
    };

    const mobileStyle = {
        ...customStyles,
        menuPortal: base => ({
            ...base,
            zIndex: 9,
            border: 3,
            borderRadius: '2rem',
        }),
        control: base => ({
            ...base,
            width: customWidth || 'calc(100vw - 4rem)',
            height: 'fit-content',
            minHeight: '8rem',
            borderRadius: '2rem',
            borderColor: 'var(--color-2)',
            paddingLeft: '1rem',
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? 'var(--corporate-color-5l)' : 'white',
            fontSize: '3.5rem',
            fontWeight: 300,
            color: 'var(--corporate-color-1)',
            padding: '1rem',
        }),
        menu: base => ({
            ...base,
            borderRadius: '2rem',
            boxShadow: '0px 5px 8px -2px #757575',
            border: '1px solid #757575',
            overflow: 'hidden',
            animation: 'dropdown 0.3s ease',
            transformOrigin: 'top',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: '3.5rem',
            fontWeight: 300,
            paddingLeft: '1rem',
            color: state.data.value ? 'black' : '#757575',
        }),
        multiValue: provided => ({
            ...provided,
            fontSize: '3.5rem',
            fontWeight: 300,
            paddingLeft: '1rem',
            borderRadius: '1.5rem',
            margin: '1rem 1rem 0 0',
        }),
        multiValueRemove: provided => ({
            ...provided,
            width: '3rem',
            fontSize: '3rem',
            paddingRight: '1rem',
            marginLeft: '1rem',
            color: 'var(--color-error)',
        }),
        multiValueLabel: (provided, state) => ({
            ...provided,
            fontSize: '3.5rem',
            fontWeight: 300,
            paddingLeft: '1rem',
            color: state.data.value ? 'black' : '#757575',
        }),
        noOptionsMessage: provided => ({
            ...provided,
            fontSize: '3.5rem',
            fontWeight: 300,
            paddingLeft: '1rem',
        }),
        input: provided => ({
            ...provided,
            fontSize: '3.5rem',
            height: '7.5rem',
        }),
        indicatorsContainer: provided => ({
            ...provided,
            display: 'none',
        }),
        menuList: provided => ({
            ...provided,
            minHeight: '400px',
        }),
        placeholder: provided => ({
            ...provided,
            fontSize: '3.5rem',
        }),
        valueContainer: provided => ({
            ...provided,
            minHeight: '7.5rem',
            paddingBottom: '1rem',
        }),
    };
    const styles = mobile ? mobileStyle : customStyle;

    return (
        <div
            style={dropdownDivStyle}
            className={`field multidropdown-field ${className} ${
                R.length(values) > 0 ? 'selected' : ''
            }`}
        >
            <Select
                isSearchable={!mobile}
                styles={styles}
                menuPortalTarget={document.body}
                placeholder={label}
                isMulti
                value={values}
                options={options}
                onChange={onChange}
                components={{
                    IndicatorSeparator: () => null,
                }}
            />
            <div className={`label ${labelClass}`}>{label}</div>
        </div>
    );
};
