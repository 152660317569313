import { useHistory } from 'react-router';
import * as R from 'ramda';
import {
    FILTER_BAR,
    MAIN,
    MEETING_ID_KEY,
    PROTOCOL_VARS,
    SEARCH_STRING_KEY,
    SHOW_TOPS_WITH_HISTORY,
    store,
    TOPS_TO_UPDATE_KEY,
    useGraph,
} from '../common/hooks.config.new';
import { useCloseModal } from './useModal';

export const useNavigator = stack => {
    const history = useHistory();
    const { useVar } = useGraph(MAIN);
    const [, setTopsToUpdate] = useVar(TOPS_TO_UPDATE_KEY);
    const [, setShowTopsWithHistory] = useVar(SHOW_TOPS_WITH_HISTORY);
    const close = useCloseModal(FILTER_BAR);

    const navigateToProtocol = meetingId => {
        store.setVar(R.last(stack))(MEETING_ID_KEY, meetingId);
        store.setVar(R.last(stack))(SEARCH_STRING_KEY, '');
        store.clear(PROTOCOL_VARS);
        history.push('/protocol');
    };

    const navigateToDashboard = () => {
        setTopsToUpdate({});
        setShowTopsWithHistory({});
        close();
        history.push('/dashboard');
    };

    return {
        navigateToProtocol,
        navigateToDashboard,
    };
};
