import React, { useEffect } from 'react';
import * as R from 'ramda';
import { IoSearchOutline } from 'react-icons/io5';
import { useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { ListRow } from '../Common/ListRow';
import { TopButtonRow } from '../Elements/TopButtonRow';
import { DashboardTop } from '../Elements/ProjectDashboard/DashboardTop';
import {
    SHOW_TOPS_WITH_HISTORY,
    TOP_ATTACHMENT_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { LoadAttachments } from '../Elements/HasAttachments';

export const ShowTopModal = ({ stack }) => {
    const [isOpen] = useModalState('showTopModal');
    const { useVar } = useGraph(...stack);
    const [top] = useModalState('showTopModal');
    const topId = R.pathOr('', ['top', 'id'], top);
    const [topsWithHistory = {}, setTopsWithHistory] = useVar(SHOW_TOPS_WITH_HISTORY);
    return (
        <Modal
            name="showTopModal"
            style={{
                height: '60vh',
                top: '20vh',
                width: '90vw',
                left: '5vw',
            }}
            topbar={<IoSearchOutline size={24} color="var(--corporate-color-7)" />}
            close={() => setTopsWithHistory(R.dissoc(topId, topsWithHistory))}
            childrenOnly
        >
            {isOpen ? (
                <ModalContent
                    stack={stack}
                    top={top}
                    topId={topId}
                    topsWithHistory={topsWithHistory}
                    setTopsWithHistory={setTopsWithHistory}
                />
            ) : null}
        </Modal>
    );
};

const ModalContent = ({ stack, topsWithHistory, setTopsWithHistory, top, topId }) => {
    useEffect(() => {
        setTopsWithHistory(R.assoc(topId, '', topsWithHistory));
    }, [topId]);

    return (
        <ListRow key={top.id}>
            <div className="top-search-row">
                <TopButtonRow top={top.top} stack={stack} noEdit showTop />
                <DashboardTop
                    stack={stack}
                    topId={top.top.id}
                    noButton
                    protocolId={top.protocolId}
                    meetingId={top.meetingId}
                />
                <LoadAttachments stack={stack} node={top.top} edgeTypes={TOP_ATTACHMENT_EDGE} />
            </div>
        </ListRow>
    );
};
