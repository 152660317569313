import * as R from 'ramda';
import React, { useEffect } from 'react';
import { MEETING_ICS, useGraph } from '../../../common/hooks.config.new';
import { useMeetingTypesChoices } from '../../../hooks/useChoices';
import { useMeetingId } from '../../../hooks/useMeetingId';
import { DateField, TextField } from '../../Common/Fields';
import { DropdownField } from '../../Common/Dropdown';
import { OnlyOnFileDrag } from '../../Common/OnlyOnDrag';
import { MeetingIcsButton, MeetingIcsField } from './MeetingIcsField';
import { Toast } from '../Toast';

export const Meeting = ({
    stack,
    meeting,
    subprojectId,
    projectId,
    toggleDropzone,
    setToggleDropzone,
}) => {
    const { update: updateMeeting } = useGraph(...stack);
    const meetingTypesChoices = useMeetingTypesChoices(stack);

    useEffect(() => {
        if (meeting && meeting.id && !meeting.date) {
            // meeting loaded and has no date
            updateMeeting(R.assoc('date', new Date().toISOString(), meeting));
        }
    }, [meeting]);
    useMeetingId(stack, projectId, subprojectId, meeting, updateMeeting);

    if (toggleDropzone) {
        return (
            <div
                style={{
                    display: 'flex',
                    position: 'relative',
                    width: '100%',
                    justifyContent: 'space-between',
                    margin: 0,
                    padding: 0,
                    boxShadow: 'none',
                }}
                className="list-row"
            >
                <MeetingIcsField
                    stack={stack}
                    projectId={projectId}
                    meetingId={meeting.id}
                    setToggleDropzone={setToggleDropzone}
                />
                <Toast name={MEETING_ICS} />
            </div>
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                position: 'relative',
                width: '100%',
                justifyContent: 'space-between',
                margin: 0,
                padding: 0,
                boxShadow: 'none',
            }}
            className="list-row"
        >
            <OnlyOnFileDrag
                renderFalse={() => (
                    <>
                        <DropdownField
                            className="infront"
                            label="Type"
                            value={R.propOr('', 'type', meeting)}
                            options={meetingTypesChoices}
                            onChange={option =>
                                updateMeeting(R.assoc('type', option.value, meeting))
                            }
                        />
                        <TextField
                            className="infront"
                            disabled
                            label="Meeting ID"
                            value={R.propOr('', 'businessId', meeting)}
                            onChange={() => {}}
                            style={{
                                border: '1px solid var(--corporate-color-1-light5)',
                            }}
                        />
                        <TextField
                            className="infront"
                            fieldDivStyle={{ flex: 0.5 }}
                            label="Title"
                            value={R.propOr('', 'title', meeting)}
                            onChange={value => updateMeeting(R.assoc('title', value, meeting))}
                        />
                        <DateField
                            className="infront"
                            label="Date"
                            value={R.prop('date', meeting)}
                            onChange={value => updateMeeting(R.assoc('date', value, meeting))}
                        />
                        <TextField
                            className="infront"
                            label="Location"
                            value={R.propOr('', 'location', meeting)}
                            onChange={value => updateMeeting(R.assoc('location', value, meeting))}
                        />
                        <MeetingIcsButton
                            stack={stack}
                            projectId={projectId}
                            meetingId={meeting.id}
                        />
                    </>
                )}
                renderTrue={() => (
                    <MeetingIcsField
                        stack={stack}
                        projectId={projectId}
                        meetingId={meeting.id}
                        setToggleDropzone={setToggleDropzone}
                    />
                )}
            />
            <Toast name={MEETING_ICS} />
        </div>
    );
};
