import React from 'react';
import * as R from 'ramda';
import { useHistory } from 'react-router';
import { IoOpenOutline } from 'react-icons/io5';
import { useNode } from '../../hooks/useNode';
import {
    DASHBOARD_STATE_KEY,
    OPP_TRACKING_GLOBAL,
    PROJECT_ID_KEY,
    SUBPROJECT_ID_KEY,
    useGraph,
} from '../../common/hooks.config.new';
import { Button } from '../../Components/Common/Controls';
import { SubscreenMenuButtonsRow } from './SubscreenMenuButtonsRow';

export const GLobalProjectsOverviewMobile = ({ stack, setDashboardTops, setDashboardState }) => {
    const { useVar } = useGraph(...stack);
    const [projectsUserTops] = useVar('userTops');
    const [oppTrackingGlobal] = useVar(OPP_TRACKING_GLOBAL);
    const userCanTrackOpps = R.length(oppTrackingGlobal) > 0;

    return (
        <div className="mobile-screen-container fader">
            <div className="project-overview-mobile" style={{ marginTop: '12rem' }}>
                {R.map(({ projectId, tops }) => (
                    <Project
                        stack={stack}
                        projectId={projectId}
                        tops={tops}
                        setDashboardTops={setDashboardTops}
                        setDashboardState={setDashboardState}
                        userCanTrackOpps={userCanTrackOpps}
                    />
                ))(R.values(projectsUserTops))}
            </div>
            <SubscreenMenuButtonsRow onMoveBack={() => setDashboardState('dashboard')} />
        </div>
    );
};

const Project = ({
    stack,
    projectId,
    tops,
    setDashboardTops,
    setDashboardState,
    userCanTrackOpps,
}) => {
    const { node: project } = useNode(stack, projectId);
    const { useVar } = useGraph(...stack);
    const [, setProjectId] = useVar(PROJECT_ID_KEY);
    const [, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [, setProjectDashboardState] = useVar(DASHBOARD_STATE_KEY);
    const history = useHistory();

    return (
        <div className="project-mobile">
            <div className="header">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flex: 1,
                        fontSize: 'inherit',
                    }}
                >
                    {project.title}
                    <Button
                        onClick={() => {
                            setProjectId(projectId);
                            setSubprojectId(undefined);
                            setProjectDashboardState('project');
                            history.push('dashboard');
                        }}
                    >
                        <IoOpenOutline size="3.5rem" />
                    </Button>
                </div>
            </div>
            <div style={{ padding: 12, paddingTop: 0 }}>
                <div className="project-container">
                    <div className="information">{project.customerName}</div>
                </div>
                <div style={{ marginBottom: 24 }} className="project-information-container">
                    {userCanTrackOpps && (
                        <div
                            className="column hoverwienrover"
                            style={{
                                backgroundColor: R.length(tops.opps) === 0 && 'transparent',
                                cursor: R.length(tops.opps) === 0 && 'default',
                            }}
                            onClick={() => {
                                setDashboardTops(tops.opps);
                                setDashboardState('tops');
                            }}
                        >
                            <div
                                className="title"
                                style={{
                                    color: R.length(tops.opps) === 0 && 'darkgray',
                                }}
                            >
                                Opportunities
                            </div>
                            <div
                                className="count"
                                style={{
                                    color: R.length(tops.opps) === 0 && 'darkgray',
                                }}
                            >
                                {R.length(tops.opps)}
                            </div>
                        </div>
                    )}
                    <div
                        className="column hoverwienrover"
                        style={{
                            backgroundColor: R.length(tops.actions) === 0 && 'transparent',
                            cursor: R.length(tops.actions) === 0 && 'default',
                        }}
                        onClick={() => {
                            setDashboardTops(tops.actions);
                            setDashboardState('tops');
                        }}
                    >
                        <div
                            className="title"
                            style={{
                                color: R.length(tops.actions) === 0 && 'darkgray',
                            }}
                        >
                            Actions
                        </div>
                        <div
                            className="count"
                            style={{
                                color: R.length(tops.actions) === 0 && 'darkgray',
                            }}
                        >
                            {R.length(tops.actions)}
                        </div>
                    </div>
                </div>
                <div className="project-information-container">
                    {userCanTrackOpps && (
                        <div
                            className="column hoverwienrover"
                            style={{
                                backgroundColor: R.length(tops.criticalOpps) === 0 && 'transparent',
                                cursor: R.length(tops.criticalOpps) === 0 && 'default',
                            }}
                            onClick={() => {
                                setDashboardTops(tops.criticalOpps);
                                setDashboardState('tops');
                            }}
                        >
                            <div
                                className="critical-title"
                                style={{
                                    color: R.length(tops.criticalOpps) === 0 && 'darkgray',
                                }}
                            >
                                Critical Opportunities
                            </div>
                            <div
                                className="critical-count"
                                style={{
                                    color: R.length(tops.criticalOpps) === 0 && 'darkgray',
                                }}
                            >
                                {R.length(tops.criticalOpps)}
                            </div>
                        </div>
                    )}
                    <div
                        className="column hoverwienrover"
                        style={{
                            backgroundColor: R.length(tops.criticalActions) === 0 && 'transparent',
                            cursor: R.length(tops.criticalActions) === 0 && 'default',
                        }}
                        onClick={() => {
                            setDashboardTops(tops.criticalActions);
                            setDashboardState('tops');
                        }}
                    >
                        <div
                            className="critical-title"
                            style={{
                                color: R.length(tops.criticalActions) === 0 && 'darkgray',
                            }}
                        >
                            Critical Actions
                        </div>
                        <div
                            className="critical-count"
                            style={{
                                color: R.length(tops.criticalActions) === 0 && 'darkgray',
                            }}
                        >
                            {R.length(tops.criticalActions)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
