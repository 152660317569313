import * as R from 'ramda';
// import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IoMailOutline, IoSendOutline } from 'react-icons/io5';
import { useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { LargeTextField, TextField } from '../Common/Fields';
import './PrintModal.css';
import { usePrintProtocol } from '../../hooks/usePrintProtocol';
import { useMeetingAttendees } from '../../hooks/useMeetingAttendees';
import {
    MEETING_ATTACHMENT_EDGE,
    store,
    useGraph,
    procubionApi,
} from '../../common/hooks.config.new';
import { useNode } from '../../hooks/useNode';
import { defaultEmailBody } from '../../common/config';
import { formatShortDate } from '../../common/util';
import { Button } from '../Common/Controls';
import { useToast } from '../../hooks/useToast';
import { Toast } from '../Elements/Toast';
import { Loader } from '../Elements/Loader';

export const PrintModal = ({ stack }) => {
    const [isOpen, close] = useModalState('print');
    return (
        <Modal
            name="print"
            style={{
                height: '80vh',
                top: '10vh',
                width: '80vw',
                left: '10vw',
            }}
            topbar={<IoMailOutline size={26} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <PrintModalContent stack={stack} isOpen={isOpen} close={close} /> : null}
        </Modal>
    );
};

const PrintModalContent = ({ stack, isOpen, close }) => {
    const [protocolUrl, generateProtocol] = usePrintProtocol(stack);
    useEffect(() => {
        isOpen && generateProtocol();
    }, [isOpen]);

    const { useVar, update, push } = useGraph(...stack);

    const [projectId] = useVar('projectId');
    const [subprojectId] = useVar('subprojectId');
    const [meetingId] = useVar('meetingId');

    const { node: project } = useNode(stack, projectId);
    const { node: subproject } = useNode(stack, subprojectId);
    const { node: meeting } = useNode(stack, meetingId);

    const getRespondToMail = () => {
        if (subproject.replyToAuthor) {
            return sender.email;
        }
        if (!project.replyTo && !subproject.replyTo) {
            return sender.email;
        }
        if (subproject.replyTo) {
            return subproject.replyTo;
        }
        if (project.replyTo) {
            return project.replyTo;
        }
        return sender.email || '';
    };

    const _attendeeBags = useMeetingAttendees(stack, meetingId);
    const attendeeBags = R.filter(R.path(['node', 'id']))(_attendeeBags);

    const [fields, setFields] = useState({});
    useEffect(() => {
        setFields({
            subject: `[${project.title}] ${meeting.title} (${R.compose(
                formatShortDate,
                R.prop('date'),
            )(meeting)})`,
            replyTo: getRespondToMail(),
            body: defaultEmailBody(
                meeting.title,
                R.compose(formatShortDate, R.prop('date'))(meeting),
                sender.name,
            ),
        });
    }, [isOpen]);

    const sender = R.compose(
        R.pick(['name', 'email']),
        R.propOr({}, 'node'),
        R.find(R.pathEq(['metadata', 'presence'], 'M')),
        R.values,
    )(attendeeBags);

    const setPrintToast = useToast('protocol');

    const sendProtocol = async () => {
        const attachmentsView = {
            [meetingId]: {
                edges: {
                    [MEETING_ATTACHMENT_EDGE]: {
                        as: 'attachments',
                        include: {
                            node: true,
                            edges: true,
                            files: true,
                        },
                    },
                },
            },
        };

        await store.pull(attachmentsView, R.head(stack));
        const {
            [meetingId]: { attachments: attachmentBags = {} },
        } = store.getView(...stack)(attachmentsView);

        const attachmentUrls = R.compose(
            R.filter(obj => obj.filename && obj.url),
            R.values,
            R.map(attachmentBag => ({
                filename: R.path(['node', 'filename'], attachmentBag),
                url: R.path(['files', 'file', 'url'], attachmentBag),
            })),
        )(attachmentBags);

        const recipients = R.compose(
            R.values,
            R.map(R.compose(R.pick(['name', 'email']), R.prop('node'))),
        )(attendeeBags);

        if (sender.name && recipients.length > 0 && protocolUrl) {
            setPrintToast('spinner', 'Sending protocol...');
            try {
                await procubionApi.sendProtocol({
                    sender,
                    recipients,
                    protocolUrl,
                    attachmentUrls,
                    ...fields,
                });
                setPrintToast('success', 'Protocol successfully sent.', 5000);
                update(R.assoc('submitted', true, meeting));
                push();
                close();
            } catch (error) {
                setPrintToast('error', error.toString(), 10000);
            }
        } else {
            setPrintToast(
                'error',
                'Some parts of your mail are missing. Make sure you chose a minutes keeper, meeting attendees and the protocol was generated.',
                10000,
            );
        }
    };

    return (
        <>
            <div className="columns">
                <div>
                    <TextField
                        label="Subject"
                        value={fields.subject}
                        onChange={value => setFields(R.assoc('subject', value))}
                    />
                    <TextField
                        label="Reply to"
                        value={fields.replyTo}
                        onChange={value => setFields(R.assoc('replyTo', value))}
                    />
                    <LargeTextField
                        label="Body"
                        rows={18}
                        value={fields.body}
                        onChange={value => setFields(R.assoc('body', value))}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {protocolUrl && (
                            <Button
                                onClick={() => sendProtocol()}
                                style={{ width: '30%' }}
                                disabled={!protocolUrl}
                            >
                                <div className="button-icon item-button-d">
                                    <IoSendOutline size={25} />
                                    <p
                                        style={{
                                            marginLeft: 12,
                                            fontSize: 17,
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Send
                                    </p>
                                </div>
                            </Button>
                        )}
                    </div>
                </div>
                <div className="pdf-viewer">
                    {protocolUrl ? (
                        <object
                            style={{ width: '100%', height: '100%' }}
                            data={`${protocolUrl}&toolbar=0&navpanes=0&scrollbar=0`}
                            type="application/pdf"
                        >
                            <embed
                                src={`${protocolUrl}&toolbar=0&navpanes=0&scrollbar=0`}
                                type="application/pdf"
                            />
                        </object>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <Loader />
                        </div>
                    )}
                </div>
            </div>
            <Toast name="print" />
        </>
    );
};
