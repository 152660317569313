import React, { useEffect } from 'react';
import * as R from 'ramda';
import { IoCreateOutline, IoSaveOutline } from 'react-icons/io5';
import { useModalState } from '../../hooks/useModal';
import { Button, FancyCheckbox } from '../Common/Controls';
import { ImageField, TextField } from '../Common/Fields';
import { DropdownField } from '../Common/Dropdown';
import { Modal } from '../Common/Modal';
import { useToast } from '../../hooks/useToast';
import {
    CONTACT_DIRECTORY_CONTACT_EDGE,
    ORGANIZATION_PROJECT_EDGE,
    PROJECT_CONTACT_DIRECTORY_EDGE,
    PROJECT_SUBPROJECT_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { Id, isValidMail, newId, newIsoDate, newMsDate } from '../../common/util';
import { createOfflinePush } from '../../common/got-adjunct';

export const constructSubprojectsView = projectId =>
    projectId
        ? {
              [projectId]: {
                  as: 'project',
                  include: {
                      node: true,
                      rights: true,
                  },
                  edges: {
                      [PROJECT_SUBPROJECT_EDGE]: {
                          as: 'subprojects',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const EditProjectModal = ({ stack: parentStack, canAdminProject, customerLogo }) => {
    const stack = R.append('edit-project', parentStack);
    const [isOpen, close] = useModalState('edit-project');
    const { clear } = useGraph(...stack);
    return (
        <Modal
            name="edit-project"
            style={{
                height: '95vh',
                width: '50vw',
                top: '2.5vh',
                left: '25vw',
            }}
            topbar={<IoCreateOutline size={25} color="var(--corporate-color-7)" />}
            close={close}
            clear={clear}
        >
            {isOpen ? (
                <ModalContent
                    stack={stack}
                    canAdminProject={canAdminProject}
                    customerLogo={customerLogo}
                    close={close}
                />
            ) : null}
        </Modal>
    );
};

const ModalContent = ({ stack, customerLogo, close }) => {
    const { update, pull, useView, add, setRights, setFile } = useGraph(...stack);
    const { useVar } = useGraph(R.head(stack));
    const [{ projectId, isNew, user, organizationId }] = useModalState('edit-project');
    const [, setProjectId] = useVar('projectId');
    const [, setSubprojectId] = useVar('subprojectId');
    useEffect(() => {
        if (isNew) {
            const defaultActionStatusTypes = 'Open\nLate\nDone';
            const newProject = {
                id: projectId,
                createdDate: newIsoDate(),
            };
            const newContactDirectoryId = Id.contactDirectory(projectId);
            const newContactDirectory = {
                id: newContactDirectoryId,
            };
            add(ORGANIZATION_PROJECT_EDGE)(organizationId)(newProject, {
                order: newMsDate(),
            });
            add(PROJECT_CONTACT_DIRECTORY_EDGE)(projectId)(newContactDirectory);
            update(
                R.assoc('actionStatusTypes', R.split('\n', defaultActionStatusTypes), newProject),
            );
            setRights(projectId)(user, {
                read: true,
                write: true,
                admin: true,
            });
            setProjectId(projectId);
            setSubprojectId(undefined);
            const newContactId = newId();
            const newContact = {
                id: newContactId,
                name: `User - ${user}`,
                email: user,
            };
            add(CONTACT_DIRECTORY_CONTACT_EDGE)(newContactDirectoryId)(newContact);
        }
    }, [projectId, isNew]);

    // selected project
    const subprojectsView = constructSubprojectsView(projectId);
    useEffect(() => {
        isNew && projectId && pull(subprojectsView);
    }, [projectId]);
    const { project: projectBag = {} } = useView(subprojectsView);
    const project = R.propOr({}, 'node', projectBag);
    const canAdminProject = R.pathOr(false, ['rights', 'user', user, 'admin'], projectBag);
    const setToast = useToast('dashboard');
    const save = async () => {
        setToast('spinner', 'Saving project data...');
        const toastConfig = {
            textOnStart: 'Saving project data...',
        };
        const push = createOfflinePush(stack, setToast, toastConfig);
        return push()
            .then(({ uploads }) => {
                uploads.subscribe({
                    complete: () => {
                        setToast('success', 'Successfully saved.', 5000);
                    },
                });
                uploads.start();
            })
            .catch(error => {
                if (error.status) {
                    setToast('error', error.message ? error.message : error.toString());
                } else {
                    setToast('error', 'You are offline. Please sync when back online.', 5000);
                }
            });
    };

    const checkAndSave = () => {
        if (!project.businessId) {
            return setToast('hint', 'Create a project id', 5000);
        }
        if (/[^a-zA-Z0-9]/.test(project.businessId)) {
            return setToast('hint', 'The project id may only contain letters and numbers', 5000);
        }
        if (project.replyTo && !isValidMail(project.replyTo)) {
            return setToast('hint', 'Please enter a valid reply-to mail address', 5000);
        }
        if (project.businessId && !/[^a-zA-Z0-9]/.test(project.businessId)) {
            save();
            close();
        }
        return null;
    };

    return (
        <div className="columns">
            <div>
                <div className="modal-headline">
                    <div>Edit Project</div>
                </div>
                <TextField
                    label="Project name"
                    value={project.title || ''}
                    onChange={value => update(R.assoc('title', value, project))}
                    editable={canAdminProject}
                />
                <TextField
                    label="Project ID"
                    value={project.businessId || ''}
                    onChange={value => update(R.assoc('businessId', value, project))}
                    editable={canAdminProject}
                />
                <TextField
                    label="Customer name"
                    value={project.customerName || ''}
                    onChange={value => update(R.assoc('customerName', value, project))}
                    editable={canAdminProject}
                />
                <TextField
                    label="Reply-to mail address"
                    value={project.replyTo || ''}
                    onChange={value => update(R.assoc('replyTo', value, project))}
                    editable={canAdminProject}
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <DropdownField
                        options={[
                            { value: 'EUR', label: 'Euro' },
                            { value: 'USD', label: 'US-Dollar' },
                            { value: 'CHF', label: 'Swiss Franc' },
                        ]}
                        onChange={value => {
                            update(R.assoc('currency', value.value, project));
                        }}
                        value={project.currency || ''}
                        label="Currency"
                        editable={canAdminProject}
                    />
                    {canAdminProject && (
                        <FancyCheckbox
                            label="Show time stamp in PDF"
                            onChange={() =>
                                canAdminProject &&
                                update(R.assoc('showAd', !project.showAd, project))
                            }
                            value={project.showAd || false}
                        />
                    )}
                </div>
                <ImageField
                    label="Click or drag to upload customer logo"
                    value={customerLogo || ''}
                    onChange={file => {
                        if (!file) {
                            return;
                        }
                        setFile(projectId)('customerLogo', file.name, file);
                        save();
                    }}
                    className="project-img-field"
                />
                <div className="new-save-button-container">
                    <Button onClick={() => checkAndSave()} className="new-save-button">
                        <IoSaveOutline size={22} style={{ marginRight: 9 }} />
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};
