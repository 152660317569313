import React, { useCallback, useState } from 'react';
import * as R from 'ramda';
import { useSpring, animated } from 'react-spring';
import {
    IoChevronDownOutline,
    IoPersonAddOutline,
    IoPersonOutline,
    IoTrashOutline,
} from 'react-icons/io5';
import {
    CONTACTS_MODAL_MOBILE,
    EDIT_ATTENDEE_MODAL_MOBILE,
    MEETING_ATTENDEE_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { useMeetingAttendees } from '../../hooks/useMeetingAttendees';
import { useModal } from '../../hooks/useModal';
import { ListField } from '../../Components/Common/Fields';
import { Button } from '../../Components/Common/Controls';

export const MeetingAttendeesMobile = ({ stack, meetingId }) => {
    const [showDetails, setShowDetails] = useState(false);
    const detailButton = useSpring({
        transform: showDetails ? 'rotate(-180deg)' : 'rotate(0deg)',
    });
    const { dissoc } = useGraph(...stack);
    const sortedAttendeeBags = useMeetingAttendees(stack, meetingId);
    const dissocAttendee = useCallback(dissoc(MEETING_ATTENDEE_EDGE)(meetingId), [meetingId]);
    const openEditAttendeeModalMobile = useModal(EDIT_ATTENDEE_MODAL_MOBILE);
    const openContactsModalMobile = useModal(CONTACTS_MODAL_MOBILE);

    return (
        <div className="meeting-attendees-area-mobile">
            <div
                onClick={() => setShowDetails(!showDetails)}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                        className="modal-headline-mobile"
                        style={{ marginLeft: 0, fontSize: '4.5rem' }}
                    >
                        Attendees
                    </div>
                    <Button
                        onClick={e => {
                            e.stopPropagation();
                            openContactsModalMobile({ screen: 'protocol' });
                        }}
                        className="add-attendee-button"
                    >
                        <IoPersonAddOutline size="4rem" />
                    </Button>
                </div>
                <animated.div style={detailButton}>
                    <IoChevronDownOutline size="4.5rem" color="var(--corporate-color-2)" />
                </animated.div>
            </div>
            {showDetails && (
                <div className="meeting-attendees-mobile fader">
                    <ListField
                        className
                        style={{ flex: 1, flexBasis: 150 }}
                        inputStyle={{ backgroundColor: 'transparent' }}
                    >
                        {R.map(attendee => (
                            <ContactElement
                                key={attendee.nodeId}
                                attendee={attendee}
                                dissocAttendee={dissocAttendee}
                                openEditAttendeeModalMobile={openEditAttendeeModalMobile}
                            />
                        ))(sortedAttendeeBags)}
                    </ListField>
                </div>
            )}
        </div>
    );
};

const ContactElement = ({ attendee, openEditAttendeeModalMobile, dissocAttendee }) => (
    <div
        key={attendee.nodeId}
        className="row-mobile hover-effect"
        style={{ marginLeft: 0, marginRight: 0 }}
        onClick={() => openEditAttendeeModalMobile(attendee)}
    >
        <IoPersonOutline
            size="5rem"
            color="var(--corporate-color-2-light)"
            style={{ marginRight: '2rem' }}
        />
        <div className="row-mobile-content">
            <div className="main-title">{attendee.node.name}</div>
            <div className="sub-title">{attendee.node.email}</div>
        </div>
        {dissocAttendee && (
            <Button
                onClick={e => {
                    e.stopPropagation();
                    dissocAttendee(attendee.node);
                }}
                style={{
                    backgroundColor: 'white',
                    marginRight: '1rem',
                    height: '4rem',
                    width: '4rem',
                    borderRadius: '2rem',
                }}
            >
                <IoTrashOutline size="3.5rem" color="var(--corporate-color-2-light)" />
            </Button>
        )}
    </div>
);
