import React from 'react';
import { DASHBOARD, MAIN, OFFLINE } from '../../common/hooks.config.new';
import { GlobalDashboardMobileContent } from './GlobalDashboardContentMobile';
import { SelectorFlyout } from '../Elements/SelectorFlyout';
import { GlobalDashboardCalculator } from '../../Components/Elements/GlobalDashboard/GlobalDashboardCalculator';
import { Toast } from '../../Components/Elements/Toast';
import { LoaderMobile } from '../../Components/Elements/Loader';
import { useModalState } from '../../hooks/useModal';

const stack = [MAIN, OFFLINE, DASHBOARD];

export const GlobalDashboardMobile = () => (
    <>
        <Toast name="mobile-project-dashboard" mobile />
        <Content />
        <GlobalDashboardCalculator stack={stack} />
        <SelectorFlyout stack={stack} global />
    </>
);

const Content = () => {
    const [isLoading] = useModalState('loader');
    return isLoading ? <LoaderMobile /> : <GlobalDashboardMobileContent stack={stack} />;
};
