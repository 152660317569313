import React, { useEffect } from 'react';
import * as R from 'ramda';
import { useNode } from '../../hooks/useNode';
import {
    MEETING_ATTENDEE_EDGE,
    MEETING_ID_KEY,
    MEETING_TOP_EDGE,
    PROJECT_ID_KEY,
    SUBPROJECT_ID_KEY,
    TOP_RESPONSIBLE_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { ReadingViewMobile } from '../Elements/ReadingViewMobile';
import { MeetingInformationsMobile } from '../Elements/MeetingInfosMobile';
import { MeetingAttendeesMobile } from '../Elements/MeetingAttendeesMobile';
import { MeetingContentMobile } from '../Elements/MeetingContentMobile';

export const ProtocolScreenMobileContent = ({ stack, read }) => {
    const { useVar, pull } = useGraph(...stack);
    const [projectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [meetingId] = useVar(MEETING_ID_KEY);
    const { node: meeting } = useNode(stack, meetingId);

    const meetingView = {
        [meetingId]: {
            include: {
                node: true,
                // files: true,
            },
            edges: {
                [MEETING_TOP_EDGE]: {
                    include: {
                        node: true,
                        edges: true,
                        metadata: true,
                    },
                    edges: {
                        [TOP_RESPONSIBLE_EDGE]: {
                            include: {
                                node: true,
                                edges: true,
                            },
                        },
                    },
                },
                [MEETING_ATTENDEE_EDGE]: {
                    include: {
                        node: true,
                        edges: true,
                        metadata: true,
                    },
                },
            },
        },
    };

    useEffect(() => {
        meetingId && pull(meetingView);
    }, [meetingId]);

    return (
        <div className="mobile-screen-container fader">
            <div
                style={{ display: 'flex', flexDirection: 'column', paddingTop: !read ? '8rem' : 0 }}
            >
                {read ? (
                    <ReadingViewMobile stack={stack} meetingId={meetingId} />
                ) : (
                    <>
                        <MeetingInformationsMobile
                            stack={stack}
                            meeting={meeting}
                            subprojectId={subprojectId}
                            projectId={projectId}
                        />
                        {R.prop('businessId', meeting) && (
                            <>
                                <MeetingAttendeesMobile stack={stack} meetingId={meetingId} />
                                <MeetingContentMobile stack={stack} meetingId={meetingId} />
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
