import React from 'react';
import * as R from 'ramda';
import { OppsCountDonutMobile } from './OppChartsMobile';

export const GlobalOppsAreaMobile = ({
    stack,
    chartedOpps,
    allCriticalOpps,
    setDashboardState,
    setDashboardTops,
}) => (
    <div className="content-container-mobile" style={{ marginTop: '12rem', marginBottom: '5rem' }}>
        <div className="headline-mobile">My Opportunities</div>
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '3rem',
            }}
        >
            <OppsCountDonutMobile
                stack={stack}
                chartedOpps={chartedOpps.tops}
                criticalOppsCount={R.length(allCriticalOpps)}
                setGlobalDashboardTops={e => {
                    setDashboardTops(e);
                    setDashboardState('tops');
                }}
                global
            />
        </div>
    </div>
);
