/* eslint-disable guard-for-in */
import React, { useState } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { BASE_STATE, MAIN, OFFLINE, store } from '../../common/hooks.config.new';
import { Button } from '../Common/Controls';
import { createOfflinePush } from '../../common/got-adjunct';

export const OfflineSyncer = ({
    graphNames = [],
    mergeToGraphName = MAIN,
    mergeToOfflineName = OFFLINE,
    onProgress,
    onSuccess,
    onError,
    children,
}) => {
    const hasOfflineData = useSelector(
        R.anyPass(R.map(graphName => R.path([BASE_STATE, graphName, 'graph']))(graphNames)),
    );

    const syncAll = async () => {
        let error;
        if (R.is(Array, graphNames)) {
            // eslint-disable-next-line no-restricted-syntax
            for (const graphName of graphNames) {
                if (R.is(String, graphName)) {
                    if (error) {
                        // if any previous pushes fail, merge higher graphs into offline in sequence
                        store.merge(graphName, mergeToOfflineName);
                        store.clear(graphName);
                    } else {
                        // if all previous pushes successful, attempt to push higher graphs in sequence
                        try {
                            if (graphName === mergeToOfflineName) {
                                // eslint-disable-next-line no-await-in-loop
                                await store.push(mergeToOfflineName, mergeToGraphName);
                            } else {
                                const push = createOfflinePush(
                                    [mergeToGraphName, graphName],
                                    undefined,
                                    { mergeToOfflineName },
                                );
                                // eslint-disable-next-line no-await-in-loop
                                await push();
                            }
                        } catch (e) {
                            error = e;
                        }
                    }
                }
            }
        }

        if (error) {
            throw error;
        }
    };

    const sync = async () => {
        onProgress && onProgress();
        return syncAll()
            .then(() => onSuccess && onSuccess())
            .catch(error => onError && onError(error));
    };

    const [color, setColor] = useState('white');

    return hasOfflineData ? (
        <Button
            style={{ color }}
            onMouseEnter={() => setColor('var(--corporate-color-7)')}
            onMouseLeave={() => setColor('white')}
            onClick={sync}
        >
            {children}
        </Button>
    ) : null;
};
