import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { VscCircleOutline } from 'react-icons/vsc';
import { IoCloseOutline, IoCreateOutline, IoOpenOutline } from 'react-icons/io5';
import {
    useGraph,
    PROJECT_ID_KEY,
    PROJECT_SUBPROJECT_EDGE,
    SUBPROJECTS_MODAL_MOBILE,
    SUBPROJECT_ID_KEY,
    SEARCH_STRING_KEY,
    DASHBOARD_FILTER_KEY,
    EDIT_SUBPROJECT_MODAL_MOBILE,
} from '../../common/hooks.config.new';
import { useModal, useModalState } from '../../hooks/useModal';
import { ModalMobile } from '../Common/ModalMobile';
import { ListField } from '../../Components/Common/Fields';
import { Button } from '../../Components/Common/Controls';
import { useUserEmail } from '../../hooks/useUserEmail';

const constructSubprojectsView = projectId =>
    projectId
        ? {
              [projectId]: {
                  as: 'project',
                  include: {
                      node: true,
                  },
                  edges: {
                      [PROJECT_SUBPROJECT_EDGE]: {
                          as: 'subprojects',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const SubprojectsModalMobile = ({ stack }) => {
    const [isOpen, close] = useModalState(SUBPROJECTS_MODAL_MOBILE);
    const openEditSubprojectModalMobile = useModal(EDIT_SUBPROJECT_MODAL_MOBILE);
    const user = useUserEmail();
    return (
        <ModalMobile
            name={SUBPROJECTS_MODAL_MOBILE}
            add={() => openEditSubprojectModalMobile({ isNew: true, user })}
        >
            {isOpen ? <ModalContent stack={stack} close={close} /> : null}
        </ModalMobile>
    );
};

const ModalContent = ({ stack, close }) => {
    const { useVar, useView, pull } = useGraph(...stack);

    const [projectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [, setSearchString] = useVar(SEARCH_STRING_KEY);
    const [, setFilter] = useVar(DASHBOARD_FILTER_KEY);

    // subproject choices
    const subprojectsView = constructSubprojectsView(projectId);
    const { project: { subprojects = {} } = {} } = useView(subprojectsView);
    useEffect(() => {
        projectId && pull(subprojectsView);
    }, [projectId]);
    const openEditSubprojectModalMobile = useModal(EDIT_SUBPROJECT_MODAL_MOBILE);
    const sortedSubprojects = R.compose(R.sortBy(R.path(['node', 'title'])), R.values)(subprojects);

    return (
        <>
            <div className="modal-headline-mobile">
                Subprojects
                <div className="modal-headline-filler" />
            </div>
            {projectId ? (
                <ListField className style={{ flex: 1, flexBasis: 150 }}>
                    {R.map(subproject => (
                        <SubprojectElement
                            key={subproject.nodeId}
                            subproject={subproject}
                            subprojectId={subprojectId}
                            setSearchString={setSearchString}
                            setSubprojectId={setSubprojectId}
                            openEditSubprojectModalMobile={openEditSubprojectModalMobile}
                            setFilter={setFilter}
                            close={close}
                        />
                    ))(sortedSubprojects)}
                </ListField>
            ) : (
                <p className="dashboard-message-mobile">Select or create a project</p>
            )}
            <div style={{ height: '20vh' }} />
        </>
    );
};

const SubprojectElement = ({
    subproject,
    subprojectId,
    setSearchString,
    setSubprojectId,
    openEditSubprojectModalMobile,
    setFilter,
    close,
}) => {
    const [showButtons, setShowButtons] = useState(false);
    return (
        <div
            key={subproject.nodeId}
            className="row-mobile hover-effect"
            style={{
                borderColor: subproject.nodeId === subprojectId && 'var(--corporate-color-7)',
                borderWidth: subproject.nodeId === subprojectId && 3,
                boxShadow:
                    subproject.nodeId === subprojectId && '0 5px 8px -2px var(--corporate-color-7)',
            }}
            onClick={() => setShowButtons(true)}
        >
            <VscCircleOutline
                size="4rem"
                color={
                    subproject.nodeId === subprojectId
                        ? 'var(--corporate-color-7)'
                        : 'var(--corporate-color-2-light)'
                }
                style={{ marginRight: '2rem' }}
            />
            <div className="row-mobile-content">
                <div className="main-title">{subproject.node.title}</div>
                <div className="sub-title">{subproject.node.businessId}</div>
                {showButtons && (
                    <div className="list-buttons-mobile">
                        <Button
                            className="list-button"
                            onClick={e => {
                                e.stopPropagation();
                                setSubprojectId(subproject.nodeId);
                                setSearchString('');
                                setFilter({});
                                close();
                            }}
                        >
                            <IoOpenOutline size="3rem" style={{ marginRight: '1rem' }} />
                            Open
                        </Button>
                        <Button
                            className="list-button"
                            onClick={e => {
                                e.stopPropagation();
                                setShowButtons(false);
                                openEditSubprojectModalMobile({ subprojectId: subproject.nodeId });
                            }}
                        >
                            <IoCreateOutline size="3rem" style={{ marginRight: '1rem' }} />
                            Edit
                        </Button>
                        <Button
                            className="list-button"
                            onClick={e => {
                                e.stopPropagation();
                                setShowButtons(false);
                            }}
                            style={{ paddingLeft: '1.5rem' }}
                        >
                            <IoCloseOutline size="3.5rem" style={{ marginRight: '0.5rem' }} />
                            Close
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};
