import axios from 'axios';

export const createProcubionApi = ({ host, getAuthToken }) => {
    const post = async (endpoint, body) => {
        const authHeader = {
            headers: {
                Authorization: getAuthToken(),
            },
        };

        return axios.post(`${host}${endpoint}`, body, authHeader);
    };

    const sendProtocol = async body => post('send-protocol', body);
    const sendMail = async body => post('send-mail', body);
    const generateProtocol = async body => post('generate-protocol', body);

    return {
        sendProtocol,
        sendMail,
        generateProtocol,
    };
};
