import React from 'react';
import { useProjectChoices } from '../../hooks/useChoices';
import { DropdownField, ResponsibleDropdownField } from '../Common/Dropdown';

export const ChoiceDropdownField = ({
    stack,
    choiceType,
    label,
    value,
    onChange,
    editable,
    labelClass,
    mobile,
}) => {
    const { choices } = useProjectChoices(stack, choiceType);
    return (
        <DropdownField
            label={label}
            value={value}
            mobile={mobile}
            options={choices}
            editable={editable}
            onChange={onChange}
            labelClass={labelClass}
        />
    );
};

export const ResponsibleChoiceDropdownField = ({
    stack,
    choiceType,
    label,
    value,
    mobile,
    onChange,
    editable,
    labelClass,
}) => {
    const { choices } = useProjectChoices(stack, choiceType);
    return (
        <ResponsibleDropdownField
            stack={stack}
            label={label}
            value={value}
            mobile={mobile}
            editable={editable}
            options={choices}
            onChange={onChange}
            labelClass={labelClass}
        />
    );
};
