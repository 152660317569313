import React, { useEffect } from 'react';
import * as R from 'ramda';
import { IoPersonOutline, IoPersonRemoveOutline, IoTrashOutline } from 'react-icons/io5';
import { useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import {
    CONTACT_DIRECTORY_CONTACT_EDGE,
    DELETE_CONTACT_MODAL,
    TOP_RESPONSIBLE_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { Button, FancyCheckbox } from '../Common/Controls';
import { ListField } from '../Common/Fields';
import { useUserEmail } from '../../hooks/useUserEmail';
import { useProjectContacts } from '../../hooks/useProjectContacts';
import { Id } from '../../common/util';
import { useToast } from '../../hooks/useToast';
import { createOfflinePush } from '../../common/got-adjunct';
import { Loader } from '../Elements/Loader';

const constructContactTopView = contactId =>
    contactId
        ? {
              [contactId]: {
                  as: 'contact',
                  edges: {
                      [TOP_RESPONSIBLE_EDGE]: {
                          reverse: true,
                          as: 'tops',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const DeleteContactModal = ({ stack: parentStack }) => {
    const stack = R.append(DELETE_CONTACT_MODAL, parentStack);
    const [isOpen, close] = useModalState(DELETE_CONTACT_MODAL);
    return (
        <Modal
            name={DELETE_CONTACT_MODAL}
            style={{
                height: '80vh',
                width: '75vw',
                top: '10vh',
                left: '12.5vw',
            }}
            topbar={<IoPersonRemoveOutline size={25} color="var(--corporate-color-7)" />}
        >
            {isOpen ? <Content isOpen={isOpen} stack={stack} close={close} /> : null}
        </Modal>
    );
};

const Content = ({ close, stack }) => {
    const { assoc, remove, dissoc, push, useView, pull } = useGraph(...stack);
    const setToast = useToast('dashboard');

    const toastConfig = {
        textOnStart: 'Reassigning TOPs and deleting contact...',
        textOnSuccess: 'Successfully reassigned and deleted.',
        textOnError: 'You are offline. Please sync when back online.',
    };
    const save = createOfflinePush(stack, setToast, toastConfig);
    const [{ cont: contact, projectId }] = useModalState(DELETE_CONTACT_MODAL);
    const user = useUserEmail();
    const { contactBags } = useProjectContacts(stack, projectId);
    const contractNodes = R.map(R.prop('node'))(contactBags);
    const [selectedContact, setSelectedContact] = React.useState(null);
    const contactTopsView = constructContactTopView(contact?.id);
    const [loading, setLoading] = React.useState(false);
    useEffect(() => {
        const asyncFn = async () => {
            setLoading(true);
            if (contact?.id) {
                await pull(contactTopsView).catch();
            }
            setLoading(false);
        };
        asyncFn();
    }, []);
    const contactTops = useView(
        contactTopsView,
        R.compose(R.values, R.pathOr({}, ['contact', 'tops'])),
    );
    const contactHasNoTops = R.length(contactTops) === 0;

    const deleteContact = () => {
        remove(CONTACT_DIRECTORY_CONTACT_EDGE)(Id.contactDirectory(projectId))(contact);
        push();
    };
    const reassignAndDelete = () => {
        if (contactHasNoTops) {
            deleteContact();
            save();
            close();
            return;
        }
        if (!selectedContact) {
            // eslint-disable-next-line no-restricted-globals
            const confirmed = confirm(
                'Are you sure you want to delete this contact without reassigning the TOPs?',
            );
            if (confirmed) {
                deleteContact();
                save();
                close();
                return;
            }
            return;
        }
        R.forEach(top => {
            assoc(TOP_RESPONSIBLE_EDGE)(top.nodeId)(selectedContact);
            dissoc(TOP_RESPONSIBLE_EDGE)(top.nodeId)(contact);
        })(contactTops);
        deleteContact();
        save();
        close();
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Loader />
            </div>
        );
    }

    return (
        <div className="columns">
            <div>
                <div className="modal-headline">
                    <div>Delete Contact {contact?.name || ''}</div>
                </div>
                <Warning contactHasNoTops={contactHasNoTops} contactTops={contactTops} />
                <ContactList
                    contact={contact}
                    selectedContact={selectedContact}
                    setSelectedContact={setSelectedContact}
                    user={user}
                    contactHasNoTops={contactHasNoTops}
                    contractNodes={contractNodes}
                />
                <ReassignButton
                    reassignAndDelete={reassignAndDelete}
                    contactHasNoTops={contactHasNoTops}
                />
            </div>
        </div>
    );
};

const Warning = ({ contactHasNoTops, contactTops }) => (
    <p>
        This contact has been associated with <b>{R.length(contactTops)} TOPs</b>.{' '}
        {contactHasNoTops
            ? 'You can safely delete this contact.'
            : 'Please select a contact to reassign these TOPs to. If you do not reassign these TOPs, they can only be found in the dashboard via text search but not by selecting a responsible in the dashboard filter.'}
    </p>
);

const ContactList = ({
    contact,
    selectedContact,
    setSelectedContact,
    user,
    contactHasNoTops,
    contractNodes,
}) => (
    <ListField
        className
        style={{
            flex: 1,
            flexBasis: 150,
            marginTop: 12,
            opacity: contactHasNoTops ? 0.5 : 1,
            pointerEvents: contactHasNoTops ? 'none' : 'auto',
        }}
        label="Project Contacts"
    >
        {R.map(cont => {
            if (cont.id === contact?.id) {
                return null;
            }
            return (
                <ContactRow
                    cont={cont}
                    selectedContact={selectedContact}
                    setSelectedContact={setSelectedContact}
                    user={user}
                />
            );
        })(contractNodes)}
    </ListField>
);

const ContactRow = ({ cont, selectedContact, setSelectedContact, user }) => (
    <div
        key={R.propOr('', 'id', cont)}
        className="row hover-effect"
        style={{
            cursor: 'default',
            paddingLeft: 12,
            paddingRight: 6,
        }}
    >
        <div
            style={{
                fontSize: 'inherit',
                display: 'flex',
                justifyContent: 'flex-start',
                flex: 2,
                alignItems: 'center',
            }}
        >
            <IoPersonOutline
                size={20}
                color="var(--corporate-color-2-light)"
                style={{ marginRight: 12 }}
            />
            <div
                style={{
                    fontSize: 'inherit',
                    fontWeight: 100,
                }}
            >
                {R.propOr('', 'name', cont)}
            </div>
            {R.propOr(false, 'blocked', cont) && <p className="blocked-badge">Blocked</p>}
            {R.propOr('', 'email', cont) === user && <p className="user-badge">User</p>}
            {R.propOr(false, 'invited', cont) && <p className="invited-badge">Invited</p>}
        </div>
        <div style={{ display: 'flex', justifyContent: 'start', flex: 1 }}>
            <div style={{ color: 'var(--color-2)' }}>{R.propOr('', 'email', cont)}</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
            <FancyCheckbox
                placeholderStyle={{
                    color: 'var(--corporate-color-2)',
                    marginLeft: 9,
                    paddingLeft: 18,
                    borderLeft: '1px solid var(--corporate-color-6)',
                }}
                label="Reassign to"
                onChange={() => setSelectedContact(cont)}
                value={selectedContact === cont}
            />
        </div>
    </div>
);

const ReassignButton = ({ reassignAndDelete, contactHasNoTops }) => (
    <div className="new-save-button-container">
        <Button onClick={reassignAndDelete} className="new-save-button">
            <IoTrashOutline size={22} style={{ marginRight: 9 }} />
            {contactHasNoTops ? 'Delete contact' : 'Delete contact and reassign TOPs'}
        </Button>
    </div>
);
