import React, { useEffect } from 'react';
import * as RA from 'ramda-adjunct';
import { useAttachments } from '../../hooks/useAttachments';
import { loadAtom } from '../../state/loadatom';
import { TOP_ATTACHMENT_EDGE } from '../../common/hooks.config.new';
import { useLocalBlobUrl } from '../../hooks/useLocalBlobUrl';

export const AttachmentsLoadAtom = loadAtom();

export const LoadAttachments = ({ stack, node, edgeTypes }) => {
    const { hasAttachments, id } = node;

    if (!hasAttachments) return null;

    return <TrueLoadAttachments stack={stack} id={id} edgeTypes={edgeTypes} />;
};

const TrueLoadAttachments = ({ stack, id, edgeTypes }) => {
    const { refresh, attachmentBags } = useAttachments(stack, id, TOP_ATTACHMENT_EDGE);

    useEffect(() => {
        if (id && edgeTypes) refresh();
    }, [id, edgeTypes]);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '0.5em',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    color: 'darkgray',
                    marginLeft: '0.75em',
                    gap: '0.25em',
                    fontSize: '0.8rem',
                }}
            >
                Files:{' '}
                {RA.mapIndexed((attachmentBag, index) => (
                    <Attachment
                        key={attachmentBag.nodeId}
                        stack={stack}
                        attachmentBag={attachmentBag}
                        isLast={index === attachmentBags.length - 1}
                    />
                ))(attachmentBags)}
            </div>
        </div>
    );
};

const Attachment = ({ stack, attachmentBag, isLast }) => {
    const blobUrl = useLocalBlobUrl(stack, attachmentBag.nodeId, 'file');
    const url = blobUrl || attachmentBag?.files?.file?.url || '';

    if (!url) return null;
    return (
        <a
            href={url}
            alt="file"
            target="_blank"
            rel="noreferrer"
            style={{ color: 'darkgray', fontSize: '0.7rem' }}
        >
            {attachmentBag?.node?.filename || ''}
            {isLast ? '' : ', '}
        </a>
    );
};
