import { PROJECT_ID_KEY, useGraph } from '../../../../common/hooks.config.new';

const selectActionStatuses = project => project?.actionStatusTypes || [];

/**
 * @param {string[]} stack
 * @returns {string[]}
 */
export const useActionStatuses = stack => {
    const { useVar, useNode } = useGraph(...stack);
    const [projectId] = useVar(PROJECT_ID_KEY);
    const statusTypes = useNode(projectId, selectActionStatuses);

    return statusTypes;
};
