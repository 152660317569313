import React, { useState } from 'react';
import * as R from 'ramda';
import {
    IoChevronBackOutline,
    IoGridOutline,
    IoAddOutline,
    IoRemoveOutline,
    IoNewspaperOutline,
    IoSendOutline,
    IoSaveOutline,
    IoCreateOutline,
    IoLogOutOutline,
} from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import logo from '../../logos/pclogo.png';
import {
    BASE_STATE,
    CREATE_DASHBOARD_TOP_MODAL_MOBILE,
    EDIT_PROTOCOL_TOP_MODAL_MOBILE,
    OFFLINE,
    PRINT_MODAL_MOBILE,
    PROTOCOL,
} from '../../common/hooks.config.new';
import { Button } from '../../Components/Common/Controls';
import { useToast } from '../../hooks/useToast';
import { createOfflinePush } from '../../common/got-adjunct';
import { useModal } from '../../hooks/useModal';
import { useNavigator } from '../../hooks/useNavigator';
import { useAuth } from '../../common/auth';
import { ClickAnimator } from './ClickAnimator';

export const ProtocolMenus = ({ stack, read, setRead }) => {
    const [navMenu, setNavMenu] = useState(false);
    const [addItems, setAddItems] = useState(false);
    return (
        <>
            <NavMenuFlyout
                stack={stack}
                navMenu={navMenu}
                setNavMenu={setNavMenu}
                read={read}
                setRead={setRead}
            />
            <MenuButtonsRow
                stack={stack}
                navMenu={navMenu}
                read={read}
                setNavMenu={setNavMenu}
                addItems={addItems}
                setAddItems={setAddItems}
            />
        </>
    );
};

const MenuButtonsRow = ({ stack, read, navMenu, setNavMenu, addItems, setAddItems }) => {
    const openModals = R.compose(
        R.filter(m => m !== false),
        R.values,
        R.omit(['filter-bar', 'loaded-projects']),
    )(useSelector(R.propOr({}, 'modals')));

    const hasOpenModals = R.length(openModals) > 0;
    const menuButtons = useSpring({
        opacity: navMenu || hasOpenModals ? 0 : 1,
    });

    const hasOfflineData = useSelector(
        R.anyPass(
            R.map(graphName => R.path([BASE_STATE, graphName, 'graph']))([OFFLINE, PROTOCOL]),
        ),
    );
    const setToast = useToast('mobile-project-dashboard');

    const save = (startText, endText) => {
        const toastConfig = {
            textOnStart: startText || 'Saving data...',
            textOnSuccess: endText || 'Succesfully saved...',
        };
        const push = createOfflinePush(stack, setToast, toastConfig);
        return push();
    };
    return (
        <animated.div className="menu-button-row" style={menuButtons}>
            <Button
                className="menu-button"
                style={{ backgroundColor: 'var(--corporate-color-1)', color: 'white' }}
                onClick={() => {
                    setNavMenu(!navMenu);
                    addItems && setAddItems(false);
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                    }}
                >
                    <IoRemoveOutline size="8rem" style={{ position: 'relative', top: '6.7rem' }} />
                    <IoRemoveOutline size="8rem" />
                    <IoRemoveOutline
                        size="8rem"
                        style={{ position: 'relative', bottom: '6.7rem' }}
                    />
                </div>
            </Button>
            {hasOfflineData && (
                <div className="save-protocol-button-container">
                    <ClickAnimator
                        content={
                            <Button
                                onClick={() => save()}
                                className="save-protocol-button"
                                style={{ backgroundColor: 'white' }}
                            >
                                <IoSaveOutline size="5rem" />
                            </Button>
                        }
                        positioning={{ position: 'absolute', bottom: '0rem' }}
                    />
                </div>
            )}
            {!read && <AddItemsButtons addItems={addItems} setAddItems={setAddItems} />}
        </animated.div>
    );
};

const NavMenuFlyout = ({ stack, navMenu, setNavMenu, read, setRead }) => {
    const setToast = useToast('mobile-project-dashboard');

    const save = (startText, endText) => {
        const toastConfig = {
            textOnStart: startText || 'Saving data...',
            textOnSuccess: endText || 'Succesfully saved...',
        };
        const push = createOfflinePush(stack, setToast, toastConfig);
        return push();
    };
    const navMenuFlyout = useSpring({
        width: '97.5vw',
        left: navMenu ? '0vw' : '-100vw',
    });
    const { navigateToDashboard } = useNavigator(stack);
    const openAddDashboardItemsModalMobile = useModal(CREATE_DASHBOARD_TOP_MODAL_MOBILE);
    const openPrintModalMobile = useModal(PRINT_MODAL_MOBILE);
    const setLoading = useModal('loader');
    const [, , logout] = useAuth(
        'Offline data will be lost on logout. Make sure there is no blue save icon in the bottom middle!',
    );
    return (
        <animated.div className="nav-menu-flyout-container" style={navMenuFlyout}>
            <Button
                className="menu-button"
                style={{ left: '1rem', bottom: '2rem', boxShadow: 'none' }}
                onClick={() => setNavMenu(false)}
            >
                <IoChevronBackOutline size="6rem" color="var(--corporate-color-2)" />
            </Button>
            <div className="nav-menu-content">
                <div className="nav-menu-header">
                    <img
                        alt="logo"
                        src={logo}
                        style={{
                            width: '22.5rem',
                        }}
                    />
                    <Button onClick={logout} className="logout-button-mobile">
                        <IoLogOutOutline size="5.5rem" color="var(--corporate-color-2)" />
                    </Button>
                </div>
                <div
                    onClick={() => {
                        setNavMenu(false);
                        setLoading();
                        setTimeout(() => navigateToDashboard(), 500);
                    }}
                    className="nav-menu-row"
                    style={{
                        backgroundColor: 'var(--corporate-color-1)',
                        color: 'var(--corporate-color-7)',
                    }}
                >
                    <IoGridOutline size="5.5rem" />
                    <p className="menu-entry">Project Dashboard</p>
                </div>
                <div
                    onClick={() => {
                        setNavMenu(false);
                        openAddDashboardItemsModalMobile();
                    }}
                    className="nav-menu-row"
                    style={{ paddingLeft: '1rem' }}
                >
                    <IoAddOutline size="7.5rem" />
                    <p className="menu-entry">Dashboard Items</p>
                </div>
                <div
                    className="nav-menu-row"
                    onClick={() => {
                        setNavMenu(false);
                        setRead(!read);
                    }}
                >
                    {read ? (
                        <>
                            <IoCreateOutline size="5.5rem" />
                            <p className="menu-entry">Edit Mode</p>
                        </>
                    ) : (
                        <>
                            <IoNewspaperOutline size="5.5rem" />
                            <p className="menu-entry">Read Mode</p>
                        </>
                    )}
                </div>
                <div
                    className="nav-menu-row"
                    onClick={async () => {
                        await save();
                        setNavMenu(false);
                        openPrintModalMobile();
                    }}
                    style={{ paddingLeft: '1.5rem' }}
                >
                    <IoSendOutline size="5.5rem" style={{ marginLeft: '1.25rem' }} />
                    <p className="menu-entry">Send Minute</p>
                </div>
            </div>
        </animated.div>
    );
};

const AddItemsButtons = ({ addItems, setAddItems }) => {
    const openEditProtocolTopMobileModal = useModal(EDIT_PROTOCOL_TOP_MODAL_MOBILE);
    const addItemButtonStyle = useSpring({
        transform: addItems ? 'rotate(-45deg)' : 'rotate(0deg',
        color: addItems ? 'rgb(210, 75, 93)' : 'white',
    });

    return (
        <>
            <div>
                <ClickAnimator
                    content={
                        <Button
                            className="add-button"
                            onClick={() => setAddItems(!addItems)}
                            style={{
                                color: 'inherit',
                                backgroundColor: 'var(--corporate-color-1)',
                            }}
                        >
                            <animated.div style={addItemButtonStyle}>
                                <IoAddOutline size="8rem" />
                            </animated.div>
                        </Button>
                    }
                    positioning={{ position: 'absolute', right: '0rem', bottom: '0rem' }}
                />
            </div>
            {addItems && (
                <div>
                    <Button
                        className="item-button item-button-o"
                        style={{
                            color: 'var(--corporate-color-2)',
                            bottom: '39rem',
                            right: '15rem',
                            backgroundColor: 'white',
                        }}
                        onClick={() => {
                            setAddItems(false);
                            openEditProtocolTopMobileModal({ isNew: true, topType: '3' });
                        }}
                    >
                        3
                    </Button>
                    <Button
                        className="item-button item-button-d"
                        style={{
                            color: 'var(--corporate-color-2)',
                            bottom: '27rem',
                            right: '15rem',
                            backgroundColor: 'white',
                        }}
                        onClick={() => {
                            setAddItems(false);
                            openEditProtocolTopMobileModal({ isNew: true, topType: '2' });
                        }}
                    >
                        2
                    </Button>
                    <Button
                        className="item-button item-button-r"
                        style={{
                            color: 'var(--corporate-color-2)',
                            bottom: '15rem',
                            right: '15rem',
                            backgroundColor: 'white',
                        }}
                        onClick={() => {
                            setAddItems(false);
                            openEditProtocolTopMobileModal({ isNew: true, topType: '1' });
                        }}
                    >
                        1
                    </Button>
                    <Button
                        className="item-button item-button-3"
                        style={{
                            backgroundColor: 'var(--corporate-color-14)',
                            bottom: '75rem',
                            boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
                        }}
                        onClick={() => {
                            setAddItems(false);
                            openEditProtocolTopMobileModal({ isNew: true, topType: 'a' });
                        }}
                    >
                        A
                    </Button>
                    <Button
                        className="item-button item-button-2"
                        style={{
                            backgroundColor: 'var(--corporate-color-13)',
                            bottom: '63rem',
                            boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
                        }}
                        onClick={() => {
                            setAddItems(false);
                            openEditProtocolTopMobileModal({ isNew: true, topType: 'o' });
                        }}
                    >
                        O
                    </Button>
                    <Button
                        className="item-button item-button-1"
                        style={{
                            backgroundColor: 'grey',
                            bottom: '51rem',
                            boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
                        }}
                        onClick={() => {
                            setAddItems(false);
                            openEditProtocolTopMobileModal({ isNew: true, topType: 's' });
                        }}
                    >
                        S
                    </Button>
                    <Button
                        className="item-button item-button-a"
                        style={{
                            backgroundColor: 'var(--corporate-color-7)',
                            bottom: '39rem',
                            boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
                        }}
                        onClick={() => {
                            setAddItems(false);
                            openEditProtocolTopMobileModal({ isNew: true, topType: 'd' });
                        }}
                    >
                        D
                    </Button>
                    <Button
                        className="item-button item-button-s"
                        style={{
                            backgroundColor: '#C5C9D3',
                            bottom: '27rem',
                            boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
                        }}
                        onClick={() => {
                            setAddItems(false);
                            openEditProtocolTopMobileModal({
                                isNew: true,
                                topType: 'i',
                            });
                        }}
                    >
                        I
                    </Button>
                    <Button
                        className="item-button item-button-i"
                        style={{
                            backgroundColor: 'var(--corporate-color-12)',
                            bottom: '15rem',
                            boxShadow: '0 5px 8px 2px var(--shadow-color-ultralight)',
                        }}
                        onClick={() => {
                            setAddItems(false);
                            openEditProtocolTopMobileModal({
                                isNew: true,
                                topType: 'r',
                            });
                        }}
                    >
                        R
                    </Button>
                </div>
            )}
        </>
    );
};
