import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import {
    IoCloseOutline,
    IoCreateOutline,
    IoGitNetworkOutline,
    IoOpenOutline,
} from 'react-icons/io5';
import {
    useGraph,
    PROJECTS_MODAL_MOBILE,
    ORGANIZATION_PROJECT_EDGE,
    ORGANIZATION_ID_KEY,
    PROJECT_ID_KEY,
    SUBPROJECT_ID_KEY,
    SEARCH_STRING_KEY,
    DASHBOARD_FILTER_KEY,
    EDIT_PROJECT_MODAL_MOBILE,
} from '../../common/hooks.config.new';
import { useModal, useModalState } from '../../hooks/useModal';
import { ModalMobile } from '../Common/ModalMobile';
import { ListField } from '../../Components/Common/Fields';
import { Button } from '../../Components/Common/Controls';
import { useUserEmail } from '../../hooks/useUserEmail';

const constructProjectsView = organizationId =>
    organizationId
        ? {
              [organizationId]: {
                  as: 'organization',
                  include: {
                      node: true,
                  },
                  edges: {
                      [ORGANIZATION_PROJECT_EDGE]: {
                          as: 'projects',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const ProjectsModalMobile = ({ stack }) => {
    const [isOpen, close] = useModalState(PROJECTS_MODAL_MOBILE);
    const openEditProjectModalMobile = useModal(EDIT_PROJECT_MODAL_MOBILE);
    const user = useUserEmail();
    return (
        <ModalMobile
            name={PROJECTS_MODAL_MOBILE}
            add={() => openEditProjectModalMobile({ isNew: true, user })}
        >
            {isOpen ? <ModalContent stack={stack} close={close} /> : null}
        </ModalMobile>
    );
};

const ModalContent = ({ stack, close }) => {
    const { useVar, useView, pull } = useGraph(...stack);
    const [organizationId] = useVar(ORGANIZATION_ID_KEY);
    const [projectId, setProjectId] = useVar(PROJECT_ID_KEY);
    const [, setSubprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [, setSearchString] = useVar(SEARCH_STRING_KEY);
    const [, setFilter] = useVar(DASHBOARD_FILTER_KEY);
    const projectsView = constructProjectsView(organizationId);
    const { organization: { projects = {} } = {} } = useView(projectsView);
    useEffect(() => {
        organizationId && pull(projectsView);
    }, [organizationId]);
    const openEditProjectModalMobile = useModal(EDIT_PROJECT_MODAL_MOBILE);
    const sortedProjects = R.compose(R.sortBy(R.path(['node', 'title'])), R.values)(projects);

    return (
        <>
            <div className="modal-headline-mobile">
                Projects
                <div className="modal-headline-filler" />
            </div>
            {organizationId ? (
                <ListField className style={{ flex: 1, flexBasis: 150 }}>
                    {R.map(project => (
                        <ProjectElement
                            key={project.nodeId}
                            project={project}
                            projectId={projectId}
                            setProjectId={setProjectId}
                            setSubprojectId={setSubprojectId}
                            setSearchString={setSearchString}
                            openEditProjectModalMobile={openEditProjectModalMobile}
                            setFilter={setFilter}
                            close={close}
                        />
                    ))(sortedProjects)}
                </ListField>
            ) : (
                <p className="dashboard-message-mobile">Select or create an organization</p>
            )}
            <div style={{ height: '20vh' }} />
        </>
    );
};

const ProjectElement = ({
    project,
    projectId,
    setProjectId,
    setSubprojectId,
    setSearchString,
    setFilter,
    close,
    openEditProjectModalMobile,
}) => {
    const [showButtons, setShowButtons] = useState();
    return (
        <div
            key={project.nodeId}
            className="row-mobile hover-effect"
            style={{
                borderColor: project.nodeId === projectId && 'var(--corporate-color-7)',
                borderWidth: project.nodeId === projectId && 3,
                boxShadow:
                    project.nodeId === projectId && '0 5px 8px -2px var(--corporate-color-7)',
            }}
            onClick={() => setShowButtons(true)}
        >
            <IoGitNetworkOutline
                size="5rem"
                color={
                    project.nodeId === projectId
                        ? 'var(--corporate-color-7)'
                        : 'var(--corporate-color-2-light)'
                }
                style={{ marginRight: '2rem', transform: 'rotate(90deg)' }}
            />
            <div className="row-mobile-content">
                <div className="main-title">{project.node.title}</div>
                <div className="sub-title">{project.node.businessId}</div>
                {showButtons && (
                    <div className="list-buttons-mobile">
                        <Button
                            className="list-button"
                            onClick={e => {
                                e.stopPropagation();
                                setProjectId(project.nodeId);
                                setSubprojectId();
                                setSearchString('');
                                setFilter({});
                                close();
                            }}
                        >
                            <IoOpenOutline size="3rem" style={{ marginRight: '1rem' }} />
                            Open
                        </Button>
                        <Button
                            className="list-button"
                            onClick={e => {
                                e.stopPropagation();
                                setShowButtons(false);
                                openEditProjectModalMobile({ projectId: project.nodeId });
                            }}
                        >
                            <IoCreateOutline size="3rem" style={{ marginRight: '1rem' }} />
                            Edit
                        </Button>
                        <Button
                            className="list-button"
                            onClick={e => {
                                e.stopPropagation();
                                setShowButtons(false);
                            }}
                            style={{ paddingLeft: '1.5rem' }}
                        >
                            <IoCloseOutline size="3.5rem" style={{ marginRight: '0.5rem' }} />
                            Close
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};
