import React from 'react';
import { DASHBOARD, MAIN, OFFLINE } from '../../common/hooks.config.new';
import { DashboardTopCalculator } from '../../Components/Elements/ProjectDashboard/DashboardTopCalculator';
import { SelectorFlyout } from '../Elements/SelectorFlyout';
import { ProjectDashboardMenus } from '../Elements/ProjectDashboardMenus';
import { ContactsModalMobile } from '../Modals/ContactsModalMobile';
import { DraftMeetingsModalMobile } from '../Modals/DraftMeetingsModalMobile';
import { OrganizationsModalMobile } from '../Modals/OrganizationsModalMobile';
import { ProjectsModalMobile } from '../Modals/ProjectsModalMobile';
import { SubmittedMeetingsModalMobile } from '../Modals/SubmittedMeetingsModalMobile';
import { SubprojectsModalMobile } from '../Modals/SubprojectsModalMobile';
import { TemplatesMeetingsModalMobile } from '../Modals/TemplatesModalMobile';
import { ProjectDashboardMobileContent } from './ProjectDashboardContentMobile';
import { EditOrganizationModalMobile } from '../Modals/EditOrganizationModalMobile';
import { EditProjectModalMobile } from '../Modals/EditProjectModalMobile';
import { EditSubprojectModalMobile } from '../Modals/EditSubprojectModalMobile';
import { EditContactModalMobile } from '../Modals/EditContactModalMobile';
import { EditDashboardTopModalMobile } from '../Modals/EditDashboardTopMobile';
import { useInitProjectChoices } from '../../hooks/useChoices';
import { Toast } from '../../Components/Elements/Toast';
import { useModalState } from '../../hooks/useModal';
import { LoaderMobile } from '../../Components/Elements/Loader';
import { SettingsModalMobile } from '../Modals/SettingsModalMobile';

const stack = [MAIN, OFFLINE, DASHBOARD];

export const ProjectDashboardMobile = () => (
    <>
        <Toast name="mobile-project-dashboard" mobile />
        <Content />
        <ProjectDashboardMenus stack={stack} />
        <SelectorFlyout stack={stack} />
        <OrganizationsModalMobile stack={stack} />
        <ProjectsModalMobile stack={stack} />
        <SubprojectsModalMobile stack={stack} />
        <ContactsModalMobile stack={stack} />
        <SubmittedMeetingsModalMobile stack={stack} />
        <TemplatesMeetingsModalMobile stack={stack} />
        <DraftMeetingsModalMobile stack={stack} />
        <EditOrganizationModalMobile stack={stack} />
        <EditProjectModalMobile stack={stack} />
        <EditSubprojectModalMobile stack={stack} />
        <EditContactModalMobile stack={stack} />
        <EditDashboardTopModalMobile stack={stack} />
        <SettingsModalMobile />
        <DashboardTopCalculator stack={stack} />
        <ProjectChoicesContainer />
    </>
);

const Content = () => {
    const [isLoading] = useModalState('loader');
    return isLoading ? <LoaderMobile /> : <ProjectDashboardMobileContent stack={stack} />;
};

const ProjectChoicesContainer = () => {
    useInitProjectChoices(stack);
    return null;
};
