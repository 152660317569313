import React from 'react';
import * as R from 'ramda';
import { useAtom, useCreateAtom } from '@gothub-team/got-atom';
import { useResponsibleStatusChart } from '../hooks/useResponsibleStatusChart';
import { LayeredBarChart } from './HorizontalBarChart';
import { ChartToolTip } from './ChartToolTip';
import { colors } from '../../../../common/consts';
import { DASHBOARD_FILTER_KEY, useGraph } from '../../../../common/hooks.config.new';

export const ResponsibleStatusChart = ({
    stack,
    actions,
    maxElements = Number.MAX_SAFE_INTEGER,
    reverse,
    onClick,
}) => {
    const {
        data: _data,
        labels: _labels,
        statusTypes,
        responsibles,
    } = useResponsibleStatusChart(stack, actions);

    const data = _data.slice(0, maxElements);
    const labels = _labels.slice(0, maxElements);

    const TooltipAtom = useCreateAtom(null);

    const { useVar } = useGraph(...stack);
    const [filter, setFilter] = useVar(DASHBOARD_FILTER_KEY);

    return (
        <div style={{ flex: 1.5, zIndex: 1 }}>
            <LayeredBarChart
                colors={colors}
                data={data}
                labels={labels}
                handleHoverStart={(indexResponsible, indexStatus) => {
                    TooltipAtom.set([indexResponsible, indexStatus]);
                }}
                handleHoverStop={() => {
                    TooltipAtom.set(null);
                }}
                onClick={(indexResponsible, indexStatus) => {
                    const status = statusTypes[indexStatus];
                    const responsible = responsibles[indexResponsible];

                    setFilter(
                        R.compose(
                            R.assoc('showTops', true),
                            R.assocPath(['topType', 'values'], [{ value: 'a', label: 'a' }]),
                            R.assocPath(
                                ['responsible', 'values'],
                                [{ value: responsible.id, label: responsible.name }],
                            ),
                            indexStatus != null
                                ? R.assocPath(
                                      ['actionStatus', 'values'],
                                      [{ value: status, label: status }],
                                  )
                                : R.identity,
                        )(filter),
                    );

                    onClick && onClick(responsible, status);
                }}
                reverse={reverse}
            />
            <ChartToolTip tooltipAtom={TooltipAtom}>
                <ToolTipContent
                    tooltipAtom={TooltipAtom}
                    data={data}
                    labelsI={labels}
                    labelsJ={statusTypes}
                />
            </ChartToolTip>
        </div>
    );
};

const ToolTipContent = ({ tooltipAtom, data, labelsI, labelsJ }) => {
    const [indexResponsible, indexStatus] = useAtom(tooltipAtom) || [];

    if (indexResponsible == null) return null;

    if (indexStatus == null) {
        const statusCounts = data[indexResponsible] || [];
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                    backgroundColor: 'white',
                    borderRadius: '1rem',
                    padding: '1rem 2rem',
                    boxShadow: '0 5px 8px -2px var(--corporate-color-1-light4)',
                }}
            >
                <h3>{labelsI[indexResponsible]}</h3>
                {statusCounts.map((count, j) => (
                    <div
                        key={labelsJ[j]}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '0.5rem',
                        }}
                    >
                        <div className="legend-color" style={{ backgroundColor: colors[j] }} />
                        <div>
                            {`${labelsJ[j]}: `}
                            <b>{`${count}`}</b>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    const color = colors[indexStatus];

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                backgroundColor: 'white',
                borderRadius: '1rem',
                padding: '1rem 2rem',
                boxShadow: '0 5px 8px -2px var(--corporate-color-1-light4)',
            }}
        >
            <h3>{labelsI[indexResponsible]}</h3>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '0.5rem',
                }}
            >
                <div className="legend-color" style={{ backgroundColor: color }} />
                <div>
                    {`${labelsJ[indexStatus]}: `}
                    <b>{`${data[indexResponsible][indexStatus]}`}</b>
                </div>
            </div>
        </div>
    );
};
