import { formatCurrency, formatShortDate } from '../../../common/util';

export const TextBody = text => text.replaceAll('\n', '<br />');

const RiskInfos = selectedTop => `
<div style="margin-right: auto; display: block">
<div>${selectedTop?.top?.category}</div>
<div style="font-size: 14px; color: #6b7280">Category</div>
</div>
<div style="margin-right: auto; display: block">
<div>${selectedTop?.top?.status}</div>
<div style="font-size: 14px; color: #6b7280">Status</div>
</div>
<div style="margin-right: 32px; display: block">
<div>${formatCurrency(selectedTop?.currency, selectedTop?.top?.costIncrease)}</div>
<div style="font-size: 14px; color: #6b7280">Cost Increase ${selectedTop?.currency}</div>
</div>
`;

const OppInfos = selectedTop => `
<div style="margin-right: auto; display: block">
<div>${selectedTop?.top?.category}</div>
<div style="font-size: 14px; color: #6b7280">Category</div>
</div>
<div style="margin-right: auto; display: block">
<div>${selectedTop?.top?.status}</div>
<div style="font-size: 14px; color: #6b7280">Status</div>
</div>
<div style="margin-right: 32px; display: block">
<div>${formatCurrency(selectedTop?.currency, selectedTop?.top?.savings)}</div>
<div style="font-size: 14px; color: #6b7280">Savings ${selectedTop?.currency}</div>
</div>
`;

const ActionInfos = selectedTop => `
<div style="margin-right: auto; display: block">
<div>${selectedTop?.top?.status}</div>
<div style="font-size: 14px; color: #6b7280">Status</div>
</div>
<div style="margin-right: 32px; display: block">
<div>${formatShortDate(selectedTop?.top?.dueDate)}</div>
<div style="font-size: 14px; color: #6b7280">Due Date</div>
</div>
`;

const getTopInfos = selectedTop => {
    if (selectedTop.top.type === 'a') {
        return ActionInfos(selectedTop);
    }
    if (selectedTop.top.type === 'r') {
        return RiskInfos(selectedTop);
    }
    if (selectedTop.top.type === 'o') {
        return OppInfos(selectedTop);
    }
    return '';
};

export const TopBody = selectedTop => `
<div
  style="
    height: 32px;
    width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #d1d5db;
  "
>
  <div
  style="
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
  "
  >
  ${selectedTop?.top?.businessId}
  </div>
</div>
<div
  style="
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
  "
>
  <div style="margin-top: 8px; margin-bottom: 8px; color: #6b7280">
    ${selectedTop.edited ? 'edited on' : 'created on'} ${selectedTop.lastVersionDate} ${
    selectedTop.lastVersionAuthor ? `by ${selectedTop.lastVersionAuthor}` : ''
}
  </div>
  <div style="display: flex">
    <div
      style="
        margin-right: 8px;
        height: 32px;
        width: 32px;
        border-radius: 9999px;
        background-color: ${getTopTypeColor(selectedTop.top.type)};
        font-size: 20px;
      "
    >
      <div
        style="
          margin: auto;
          margin-top: 4px;
          margin-bottom: 4px;
          text-align: center;
          color: #fff;
        "
      >
        ${selectedTop.top.type.toUpperCase()}
      </div>
    </div>
    <div style="margin-right: auto; display: block; width: 40%">
      <div>${selectedTop?.top?.description}</div>
      <div style="font-size: 14px; color: #6b7280">Description</div>
    </div>
    ${getTopInfos(selectedTop)}
    <div style="margin-left: 8px; display: block">
      <div>${selectedTop?.responsible?.name}</div>
      <div style="font-size: 14px; color: #6b7280">Responsible</div>
    </div>
  </div>
</div>
`;

const getTopTypeColor = topType => {
    switch (topType) {
        case 'a':
            return '#0CAADC';
        case 'o':
            return '#16DB65';
        case 'i':
            return '#C5C9D3';
        case 's':
            return 'grey';
        case 'd':
            return '#FFD166';
        case 'r':
            return '#FF3A20';
        default:
            return 'blue';
    }
};

export const HtmlBody = (textHtml, topHtml) => `
<!DOCTYPE html>
<html lang="en" xmlns:v="urn:schemas-microsoft-com:vml" style="color-scheme: light dark;">
<head>
  <meta charset="utf-8">
  <meta name="x-apple-disable-message-reformatting">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta name="format-detection" content="telephone=no, date=no, address=no, email=no, url=no">
  <meta name="color-scheme" content="light dark">
  <meta name="supported-color-schemes" content="light dark">
  <!--[if mso]>
  <noscript>
    <xml>
      <o:OfficeDocumentSettings xmlns:o="urn:schemas-microsoft-com:office:office">
        <o:PixelsPerInch>96</o:PixelsPerInch>
      </o:OfficeDocumentSettings>
    </xml>
  </noscript>
  <style>
    td,th,div,p,a,h1,h2,h3,h4,h5,h6 {font-family: "Segoe UI", sans-serif; mso-line-height-rule: exactly;}
  </style>
  <![endif]-->
  <style>:root {
  color-scheme: light dark;
}</style></head>
<body style="word-break: break-word; -webkit-font-smoothing: antialiased; margin: 0; width: 100%; background-color: #f8fafc; padding: 0">
    <div role="article" aria-roledescription="email" aria-label lang="en">
    <div style="padding-left: 40px; padding-right: 40px; padding-top: 20px; padding-bottom: 20px; color: #000">
            ${textHtml}
        </div>
        <div style="margin-left: 40px; margin-right: 40px; margin-top: 20px; margin-bottom: 20px; overflow: hidden; border-radius: 16px; background-color: #f3f4f6; color: #000; padding-bottom: 16px">
            ${topHtml}
        </div>
    </div>
</body>
</html>
`;

export const minify = html => html.replaceAll(/\n/g, '').replaceAll(/\s{2,}/g, ' ');

export const getHtmlBody = (selectedTop, text) => {
    const textHtml = TextBody(text);
    const topHtml = TopBody(selectedTop);
    const html = HtmlBody(textHtml, topHtml);
    return minify(html);
};
