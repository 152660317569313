import React from 'react';
import * as R from 'ramda';
import { IoInformationOutline } from 'react-icons/io5';
import { useModalState } from '../../hooks/useModal';
import { Modal } from '../Common/Modal';
import { LargeTextField } from '../Common/Fields';
import { SaveButton } from '../Elements/SaveButton';

export const ProjectTypesModal = ({
    stack,
    save,
    update,
    project,
    canAdminProject,
    oppTracking,
}) => {
    const [isOpen, close] = useModalState('project-types');
    return (
        <Modal
            name="project-types"
            style={{
                height: '90vh',
                top: '5vh',
                width: '50vw',
                left: '25vw',
            }}
            topbar={<IoInformationOutline size={26} color="var(--corporate-color-7)" />}
        >
            {isOpen ? (
                <ModalContent
                    stack={stack}
                    save={save}
                    close={close}
                    update={update}
                    project={project}
                    canAdminProject={canAdminProject}
                    oppTracking={oppTracking}
                />
            ) : null}
        </Modal>
    );
};

const ModalContent = ({ save, close, update, project, canAdminProject, oppTracking }) => (
    <div className="columns">
        <div>
            <div className="modal-headline">
                <div>Project specific types</div>
            </div>
            <div className="modal-subheadline" style={{ marginTop: 12 }}>
                Meeting types
            </div>
            <LargeTextField
                style={{ resize: 'none' }}
                label="Meeting Types"
                rows="6"
                value={R.join('\n', project.meetingTypes || [])}
                onChange={value => update(R.assoc('meetingTypes', R.split('\n', value), project))}
                editable={canAdminProject}
            />
            <div className="modal-subheadline">Action status types</div>
            <LargeTextField
                style={{ resize: 'none' }}
                label="Action Status Types"
                rows="6"
                value={R.join('\n', project.actionStatusTypes || [])}
                onChange={value =>
                    update(R.assoc('actionStatusTypes', R.split('\n', value), project))
                }
                editable={canAdminProject}
            />
            {oppTracking && (
                <>
                    <div className="modal-subheadline">
                        Opportunity status types (Opportunity-Milestones / ascending order)
                    </div>
                    <LargeTextField
                        style={{ resize: 'none' }}
                        label="Opportunity Status Types (Opportunity-Milestones / ascending order)"
                        rows="6"
                        value={R.join('\n', project.oppStatusTypes || [])}
                        onChange={value =>
                            update(R.assoc('oppStatusTypes', R.split('\n', value), project))
                        }
                        editable={canAdminProject}
                    />
                    <div className="modal-subheadline">Opportunity status types (Further)</div>
                    <LargeTextField
                        style={{ resize: 'none' }}
                        label="Opportunity Status Types (Further)"
                        rows="6"
                        value={R.join('\n', project.oppStatusTypesFurther || [])}
                        onChange={value =>
                            update(R.assoc('oppStatusTypesFurther', R.split('\n', value), project))
                        }
                        editable={canAdminProject}
                    />
                    <div className="modal-subheadline">Opportunity category types</div>
                    <LargeTextField
                        style={{ resize: 'none' }}
                        label="Opportunity Category Types"
                        rows="6"
                        value={R.join('\n', project.oppCategoryTypes || [])}
                        onChange={value =>
                            update(R.assoc('oppCategoryTypes', R.split('\n', value), project))
                        }
                        editable={canAdminProject}
                    />
                </>
            )}
            <SaveButton save={save} close={close} />
        </div>
    </div>
);
