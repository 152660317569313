import * as RA from 'ramda-adjunct';
import * as R from 'ramda';
import React, { useState } from 'react';
import { IoCaretDownOutline, IoCaretUpOutline, IoCloseOutline } from 'react-icons/io5';
import { useHistory } from 'react-router';
import { DateField, LargeTextField, TextField, Focusable } from '../Common/Fields';
import {
    DASHBOARD_FILTER_KEY,
    SHOW_TOPS_WITH_HISTORY,
    useGraph,
} from '../../common/hooks.config.new';
import { useNode } from '../../hooks/useNode';
import { ChoiceDropdownField, ResponsibleChoiceDropdownField } from './ChoiceDropdownField';
import { useTopResponsible } from '../../hooks/useTopResponsible';
import { usePreviousVersions } from '../../hooks/useTopHistories';
import { CurrencyField } from './CurrencyField';
import {
    PROJECT_ACTION_STATUS_TYPE_CHOICES,
    PROJECT_CONTACT_CHOICES,
    PROJECT_OPP_CATEGORY_TYPE_CHOICES,
    PROJECT_OPP_STATUS_TYPE_CHOICES,
    PROJECT_PHASE_CHOICES,
} from '../../hooks/useChoices';
import { formatShortDate } from '../../common/util';
import { Button } from '../Common/Controls';

export const _Top = ({
    stack,
    topId,
    editable = true,
    renderPreviousControls,
    renderLatestControls,
    noButton,
    scorecard,
    noId,
    oppTracking = true,
}) => {
    const { node: top } = useNode(stack, topId);
    const history = useHistory();
    const { update: updateTop, useVar } = useGraph(...stack);
    const [responsible, setResponsible] = useTopResponsible(stack, topId);
    const [topsWithHistory = {}, setTopsWithHistory] = useVar(SHOW_TOPS_WITH_HISTORY);
    const [filter] = useVar(DASHBOARD_FILTER_KEY);
    const showAllHistories = R.propOr(false, 'showHistories', filter);
    const previousVersions = usePreviousVersions(stack, R.prop('businessId', top));
    const lastVersion =
        scorecard && previousVersions.length > 0 ? R.last(previousVersions).top : top;
    const lastVersionAuthor = R.compose(R.pathOr('', ['author', 'name']), R.last)(previousVersions);
    const lastVersionDate = R.compose(
        formatShortDate,
        R.defaultTo(R.prop('modifiedDate', top) || R.prop('createdDate', top) || ''),
        R.prop('editedDate'),
        R.last,
    )(previousVersions);
    const [menu, setMenu] = useState(false);
    const updateTopType = type => updateTop(R.assoc('type', type, top));
    if (top) {
        return (
            <div className="top" key={top.id}>
                {!R.includes(top.type, ['1', '2', '3']) && (
                    <div
                        style={{
                            display: 'flex',
                            height: 0,
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {previousVersions.length > 1 &&
                            !showAllHistories &&
                            (R.has(top.id)(topsWithHistory) ? (
                                <Button
                                    className="hide-history-button"
                                    onClick={() =>
                                        setTopsWithHistory(R.dissoc(top.id, topsWithHistory))
                                    }
                                >
                                    <IoCaretUpOutline size={18} />
                                    <p>Hide history</p>
                                </Button>
                            ) : (
                                <Button
                                    className="show-history-button"
                                    onClick={() =>
                                        setTopsWithHistory(R.assoc(top.id, '', topsWithHistory))
                                    }
                                >
                                    <IoCaretDownOutline size={18} />
                                    <p>Show history</p>
                                </Button>
                            ))}
                    </div>
                )}
                {editable && !noId && <div className="top-business-id">{top.businessId}</div>}
                {previousVersions &&
                    previousVersions.length > 0 &&
                    (R.has(top.id)(topsWithHistory) || showAllHistories) && (
                        <div className="previous">
                            {R.compose(
                                RA.mapIndexed((previousVersion, index) => (
                                    <React.Fragment key={previousVersion?.top?.id}>
                                        <div
                                            style={{
                                                color: 'var(--corporate-color-1-light)',
                                                marginTop: 6,
                                                marginBottom: 3,
                                            }}
                                        >
                                            {index === 0 ? 'created' : 'edited'} on{' '}
                                            {index === 0
                                                ? formatShortDate(previousVersion.createdDate)
                                                : formatShortDate(previousVersion.editedDate)}{' '}
                                            {previousVersion.author &&
                                                previousVersion.author.name &&
                                                `by ${previousVersion.author.name}`}
                                        </div>
                                        <div className="row">
                                            <TopSwitch
                                                stack={stack}
                                                oppTracking={oppTracking}
                                                top={previousVersion.top}
                                                responsible={previousVersion.responsible}
                                                noButton
                                            />
                                            {renderPreviousControls
                                                ? renderPreviousControls(previousVersion)
                                                : null}
                                        </div>
                                    </React.Fragment>
                                )),
                                R.dropLast(1),
                            )(previousVersions)}
                        </div>
                    )}
                {!editable && (
                    <div style={{ color: 'var(--corporate-color-1-light)', marginTop: 6 }}>
                        {previousVersions.length > 1 ? 'edited on' : 'created on'} {lastVersionDate}{' '}
                        {lastVersionAuthor && `by ${lastVersionAuthor}`}
                    </div>
                )}
                <div className="latest">
                    {menu && (
                        <div className="top-menu-container">
                            <div
                                className="top-menu"
                                style={{ flexDirection: 'row', left: 0, right: null }}
                            >
                                <Button
                                    onClick={() => {
                                        setMenu(false);
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        color: 'var(--corporate-color-10)',
                                        padding: '12 6 12 6',
                                    }}
                                >
                                    <IoCloseOutline size={22} />
                                </Button>
                                <Button
                                    className="top-type-badge hoverscale"
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: 12,
                                        backgroundColor: 'var(--corporate-color-14)',
                                    }}
                                    onClick={() => {
                                        updateTopType('a');
                                        setMenu(false);
                                    }}
                                >
                                    A
                                </Button>
                                <Button
                                    className="top-type-badge hoverscale"
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: 12,
                                        backgroundColor: 'var(--corporate-color-13)',
                                    }}
                                    onClick={() => {
                                        updateTopType('o');
                                        setMenu(false);
                                    }}
                                >
                                    O
                                </Button>
                                <Button
                                    className="top-type-badge hoverscale"
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: 12,
                                        backgroundColor: '#C5C9D3',
                                    }}
                                    onClick={() => {
                                        updateTopType('i');
                                        setMenu(false);
                                    }}
                                >
                                    I
                                </Button>
                                <Button
                                    className="top-type-badge hoverscale"
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: 12,
                                        backgroundColor: 'grey',
                                    }}
                                    onClick={() => {
                                        updateTopType('s');
                                        setMenu(false);
                                    }}
                                >
                                    S
                                </Button>
                                <Button
                                    className="top-type-badge hoverscale"
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: 12,
                                        backgroundColor: 'var(--corporate-color-7)',
                                    }}
                                    onClick={() => {
                                        updateTopType('d');
                                        setMenu(false);
                                    }}
                                >
                                    D
                                </Button>
                                <Button
                                    className="top-type-badge hoverscale"
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: 12,
                                        backgroundColor: 'var(--corporate-color-12)',
                                    }}
                                    onClick={() => {
                                        updateTopType('r');
                                        setMenu(false);
                                    }}
                                >
                                    R
                                </Button>
                            </div>
                        </div>
                    )}
                    <TopSwitch
                        stack={stack}
                        top={lastVersion}
                        updateTop={editable ? updateTop : false}
                        updateTopType={updateTopType}
                        responsible={responsible}
                        setResponsible={setResponsible}
                        noButton={noButton}
                        lastVersion
                        scorecard={scorecard}
                        history={history}
                        setMenu={setMenu}
                        menu={menu}
                        oppTracking={oppTracking}
                    />
                    {renderLatestControls ? renderLatestControls() : null}
                </div>
            </div>
        );
    }
    return null;
};

const TopSwitch = ({
    stack,
    top,
    updateTop,
    updateTopType,
    responsible,
    setResponsible,
    setAction,
    setOpp,
    noButton,
    lastVersion,
    scorecard,
    history,
    menu,
    setMenu,
    oppTracking,
}) => {
    switch (top?.type) {
        case 'a':
            return (
                <TopA
                    stack={stack}
                    top={top}
                    updateTop={updateTop}
                    responsible={responsible}
                    setResponsible={setResponsible}
                    setAction={setAction}
                    lastVersion={lastVersion}
                    history={history}
                    setMenu={setMenu}
                    menu={menu}
                />
            );
        case 'o':
            if (oppTracking) {
                return (
                    <TopO
                        stack={stack}
                        top={top}
                        updateTop={updateTop}
                        responsible={responsible}
                        setResponsible={setResponsible}
                        setOpp={setOpp}
                        noButton={noButton}
                        scorecard={scorecard}
                        history={history}
                        setMenu={setMenu}
                        menu={menu}
                    />
                );
            }
            return <TopChoice updateTopType={updateTopType} />;
        case 'r':
            return (
                <TopR
                    stack={stack}
                    top={top}
                    updateTop={updateTop}
                    responsible={responsible}
                    setResponsible={setResponsible}
                    history={history}
                    setMenu={setMenu}
                    menu={menu}
                />
            );
        case 's':
            return (
                <TopS
                    stack={stack}
                    top={top}
                    updateTop={updateTop}
                    history={history}
                    setMenu={setMenu}
                    menu={menu}
                />
            );
        case 'i':
            return (
                <TopI
                    stack={stack}
                    top={top}
                    updateTop={updateTop}
                    history={history}
                    setMenu={setMenu}
                    menu={menu}
                />
            );
        case 'd':
            return (
                <TopD
                    stack={stack}
                    top={top}
                    updateTop={updateTop}
                    history={history}
                    setMenu={setMenu}
                    menu={menu}
                />
            );
        case '1':
            return <Headline top={top} updateTop={updateTop} />;
        case '2':
            return <Headline top={top} updateTop={updateTop} />;
        case '3':
            return <Headline top={top} updateTop={updateTop} />;
        default:
            return <TopChoice updateTopType={updateTopType} />;
    }
};

const TopChoice = ({ updateTopType }) => (
    <Focusable autoFocus>
        {updateTopType && (
            <input
                className="field text-field font-weight-bold"
                style={{ flex: 1 }}
                onChange={e => updateTopType(R.toLower(e.nativeEvent.data))}
                value="Choose TOP type (Hotkeys: [A]ction, [O]pportunity, [R]isk, [S]tatement, [I]nformation, [D]ecision, Headline [1], [2], [3])"
            />
        )}
    </Focusable>
);

const TopA = ({
    stack,
    top,
    updateTop,
    responsible,
    setResponsible,
    lastVersion,
    history,
    menu,
    setMenu,
}) => (
    <>
        <Button
            className="top-type-badge top-type-hover-effect"
            style={{
                backgroundColor: 'var(--corporate-color-14)',
                cursor:
                    R.pathOr('', ['location', 'pathname'], history) === '/protocol' && 'pointer',
                boxShadow: R.path(['location', 'pathname'], history) !== '/protocol' && 'none',
            }}
            disabled={!R.pathOr('', ['location', 'pathname'], history) === '/protocol'}
            onClick={() =>
                R.pathOr('', ['location', 'pathname'], history) === '/protocol' && setMenu(!menu)
            }
        >
            A
        </Button>
        <LargeTextField
            label="Description"
            value={R.propOr('', 'description', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(R.assoc('description', value, top))}
            autoFocus
        />
        <ChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_ACTION_STATUS_TYPE_CHOICES}
            label="Status"
            value={R.propOr('', 'status', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={option => updateTop(R.assoc('status', option.value, top))}
        />
        <ChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_PHASE_CHOICES}
            label="Project phase"
            value={R.propOr('', 'phase', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={option => updateTop(R.assoc('phase', option.value, top))}
        />
        <DateField
            lastVersion={lastVersion}
            late={top.status === 'Late'}
            label="Due Date"
            value={R.propOr('', 'dueDate', top)}
            editable={RA.isTruthy(updateTop)}
            locale="en-gb"
            onChange={value => updateTop(R.assoc('dueDate', value, top))}
        />
        <ResponsibleChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_CONTACT_CHOICES}
            label="Responsible"
            value={R.propOr('', 'id', responsible)}
            editable={RA.isTruthy(updateTop)}
            onChange={contact => setResponsible(contact.node)}
        />
    </>
);

const TopO = ({ stack, top, updateTop, responsible, setResponsible, history, menu, setMenu }) => (
    <>
        <Button
            className="top-type-badge top-type-hover-effect"
            style={{
                backgroundColor: 'var(--corporate-color-13)',
                cursor:
                    R.pathOr('', ['location', 'pathname'], history) === '/protocol' && 'pointer',
                boxShadow: R.path(['location', 'pathname'], history) !== '/protocol' && 'none',
            }}
            disabled={!R.pathOr('', ['location', 'pathname'], history) === '/protocol'}
            onClick={() =>
                R.pathOr('', ['location', 'pathname'], history) === '/protocol' && setMenu(!menu)
            }
        >
            O
        </Button>
        <LargeTextField
            label="Description"
            value={R.propOr('', 'description', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(R.assoc('description', value, top))}
            autoFocus
        />
        <ChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_OPP_CATEGORY_TYPE_CHOICES}
            label="Category"
            value={R.propOr('', 'category', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={option => updateTop(R.assoc('category', option.value, top))}
        />
        <ChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_OPP_STATUS_TYPE_CHOICES}
            label="Status"
            value={R.propOr('', 'status', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={option => updateTop(R.assoc('status', option.value, top))}
        />
        <CurrencyField
            stack={stack}
            label="Savings"
            value={R.propOr('', 'savings', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(R.assoc('savings', value, top))}
        />
        <ChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_PHASE_CHOICES}
            label="Project phase"
            value={R.propOr('', 'phase', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={option => updateTop(R.assoc('phase', option.value, top))}
        />
        <ResponsibleChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_CONTACT_CHOICES}
            label="Responsible"
            value={R.propOr('', 'id', responsible)}
            editable={RA.isTruthy(updateTop)}
            onChange={contact => setResponsible(contact.node)}
        />
    </>
);

const TopR = ({ stack, top, updateTop, responsible, setResponsible, history, menu, setMenu }) => (
    <>
        <Button
            className="top-type-badge top-type-hover-effect"
            style={{
                backgroundColor: 'var(--corporate-color-12)',
                cursor:
                    R.pathOr('', ['location', 'pathname'], history) === '/protocol' && 'pointer',
                boxShadow: R.path(['location', 'pathname'], history) !== '/protocol' && 'none',
            }}
            disabled={!R.pathOr('', ['location', 'pathname'], history) === '/protocol'}
            onClick={() =>
                R.pathOr('', ['location', 'pathname'], history) === '/protocol' && setMenu(!menu)
            }
        >
            R
        </Button>
        <LargeTextField
            label="Description"
            value={R.propOr('', 'description', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(R.assoc('description', value, top))}
            autoFocus
        />
        <TextField
            label="Category"
            value={R.propOr('', 'category', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(R.assoc('category', value, top))}
        />
        <TextField
            label="Reference"
            value={R.propOr('', 'reference', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(R.assoc('reference', value, top))}
        />
        <TextField
            label="Cost Increase"
            value={R.propOr('', 'costIncrease', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(R.assoc('costIncrease', value, top))}
        />
        <ChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_PHASE_CHOICES}
            label="Project phase"
            value={R.propOr('', 'phase', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={option => updateTop(R.assoc('phase', option.value, top))}
        />
        <ResponsibleChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_CONTACT_CHOICES}
            label="Responsible"
            value={R.propOr('', 'id', responsible)}
            editable={RA.isTruthy(updateTop)}
            onChange={choice => setResponsible(choice.node)}
        />
    </>
);

const TopS = ({ top, stack, updateTop, history, menu, setMenu }) => (
    <>
        <Button
            className="top-type-badge top-type-hover-effect"
            style={{
                backgroundColor: 'grey',
                cursor:
                    R.pathOr('', ['location', 'pathname'], history) === '/protocol' && 'pointer',
                boxShadow: R.path(['location', 'pathname'], history) !== '/protocol' && 'none',
            }}
            disabled={!R.pathOr('', ['location', 'pathname'], history) === '/protocol'}
            onClick={() =>
                R.pathOr('', ['location', 'pathname'], history) === '/protocol' && setMenu(!menu)
            }
        >
            S
        </Button>
        <LargeTextField
            label="Description"
            value={R.propOr('', 'description', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(R.assoc('description', value, top))}
            autoFocus
        />
        <ChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_PHASE_CHOICES}
            label="Project phase"
            value={R.propOr('', 'phase', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={option => updateTop(R.assoc('phase', option.value, top))}
        />
    </>
);

const TopI = ({ top, stack, updateTop, history, menu, setMenu }) => (
    <>
        <Button
            className="top-type-badge top-type-hover-effect"
            style={{
                backgroundColor: '#C5C9D3',
                cursor:
                    R.pathOr('', ['location', 'pathname'], history) === '/protocol' && 'pointer',
                boxShadow: R.path(['location', 'pathname'], history) !== '/protocol' && 'none',
            }}
            disabled={!R.pathOr('', ['location', 'pathname'], history) === '/protocol'}
            onClick={() =>
                R.pathOr('', ['location', 'pathname'], history) === '/protocol' && setMenu(!menu)
            }
        >
            I
        </Button>
        <LargeTextField
            label="Description"
            value={R.propOr('', 'description', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(R.assoc('description', value, top))}
            autoFocus
        />
        <ChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_PHASE_CHOICES}
            label="Project phase"
            value={R.propOr('', 'phase', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={option => updateTop(R.assoc('phase', option.value, top))}
        />
    </>
);

const TopD = ({ top, stack, updateTop, history, menu, setMenu }) => (
    <>
        <Button
            className="top-type-badge top-type-hover-effect"
            style={{
                backgroundColor: 'var(--corporate-color-7)',
                cursor:
                    R.pathOr('', ['location', 'pathname'], history) === '/protocol' && 'pointer',
                boxShadow: R.path(['location', 'pathname'], history) !== '/protocol' && 'none',
            }}
            disabled={!R.pathOr('', ['location', 'pathname'], history) === '/protocol'}
            onClick={() =>
                R.pathOr('', ['location', 'pathname'], history) === '/protocol' && setMenu(!menu)
            }
        >
            D
        </Button>
        <LargeTextField
            label="Description"
            value={R.propOr('', 'description', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(R.assoc('description', value, top))}
            autoFocus
        />
        <ChoiceDropdownField
            stack={stack}
            choiceType={PROJECT_PHASE_CHOICES}
            label="Project phase"
            value={R.propOr('', 'phase', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={option => updateTop(R.assoc('phase', option.value, top))}
        />
    </>
);

const Headline = ({ top, updateTop }) => (
    <>
        <div className="top-type-badge-headline">{top.type}</div>
        <TextField
            style={{ width: '50vw' }}
            label="Headline"
            value={R.propOr('', 'description', top)}
            editable={RA.isTruthy(updateTop)}
            onChange={value => updateTop(R.assoc('description', value, top))}
            autoFocus
        />
    </>
);
