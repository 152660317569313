import React, { memo } from 'react';
import * as R from 'ramda';
import { _KanbanTop } from '../KanbanTop';
import { Button } from '../../Common/Controls';
import { useModal } from '../../../hooks/useModal';

const _DashboardKanbanTop = ({ stack, meetingId, topId, protocolId, global }) => {
    const openMeetingPdfModal = useModal('protocol-pdf');
    return (
        // eslint-disable-next-line react/jsx-pascal-case
        <_KanbanTop
            noButton
            stack={stack}
            topId={topId}
            editable={false}
            renderPreviousControls={pv =>
                !global && (
                    <Button
                        style={{
                            width: 130,
                            fontSize: 'var(--font-size-small)',
                            justifyContent: 'end',
                            backgroundColor:
                                (pv.protocolId === 'DB' || R.endsWith('-', pv.protocolId)) &&
                                'transparent',
                            cursor:
                                (pv.protocolId === 'DB' || R.endsWith('-', pv.protocolId)) &&
                                'default',
                            color:
                                (pv.protocolId === 'DB' || R.endsWith('-', pv.protocolId)) &&
                                'var(--corporate-color-1-light2)',
                        }}
                        onClick={() => openMeetingPdfModal({ meetingId: pv.meetingId, topId })}
                        disabled={pv.protocolId === 'DB' || R.endsWith('-', pv.protocolId)}
                    >
                        {pv.protocolId === 'DB' || R.endsWith('-', pv.protocolId)
                            ? 'Manually created in dashboard'
                            : `${'Protocol '}${pv.protocolId}`}
                    </Button>
                )
            }
            renderLatestControls={() =>
                !global && (
                    <Button
                        style={{
                            width: 130,
                            fontSize: 'var(--font-size-small)',
                            justifyContent: 'end',
                            backgroundColor:
                                (protocolId === 'DB' || R.endsWith('-', protocolId)) &&
                                'transparent',
                            cursor:
                                (protocolId === 'DB' || R.endsWith('-', protocolId)) && 'default',
                            color:
                                (protocolId === 'DB' || R.endsWith('-', protocolId)) &&
                                'var(--corporate-color-1-light2)',
                        }}
                        onClick={() => openMeetingPdfModal({ meetingId, topId })}
                        disabled={protocolId === 'DB' || R.endsWith('-', protocolId)}
                    >
                        {protocolId === 'DB' || R.endsWith('-', protocolId)
                            ? 'Manually created in dashboard'
                            : `${'Protocol '}${protocolId}`}
                    </Button>
                )
            }
        />
    );
};

export const DashboardKanbanTop = memo(_DashboardKanbanTop, R.equals);
