import {
    FILE_PROP_NAME,
    MEETING_ATTACHMENT_EDGE,
    createGraph,
    useGraph,
} from '../common/hooks.config.new';
import { newId } from '../common/util';
import { loadAtom } from '../state/loadatom';

const AttachmentsLoadAtom = loadAtom();

const createAttachmentsView = (fromId, edgeTypes) =>
    fromId && edgeTypes
        ? {
              [fromId]: {
                  as: 'parent',
                  edges: {
                      [edgeTypes]: {
                          as: 'attachments',
                          include: {
                              node: true,
                              edges: true,
                              files: true,
                          },
                      },
                  },
              },
          }
        : {};

const selectAttachments = viewRes => {
    const obj = viewRes?.parent?.attachments;

    return obj ? Object.values(obj) : [];
};

export const getAttachments = (stack, fromId, edgeTypes) => {
    const { getView } = createGraph(...stack);
    const view = createAttachmentsView(fromId, edgeTypes);
    return selectAttachments(getView(view));
};

export const refreshAttachments = async (stack, fromId, edgeTypes, forceReload = false) => {
    const { pull } = createGraph(...stack);
    const view = createAttachmentsView(fromId, edgeTypes);
    await AttachmentsLoadAtom.loadWith(fromId, () => pull(view), forceReload);
};

export const useAttachmentFns = (stack, fromId, edgeTypes, inheritFromId) => {
    const { push, inheritRights, add, remove, setFile, assoc, update, getView } = useGraph(
        ...stack,
    );

    const refresh = async (forceReload = false) =>
        refreshAttachments(stack, fromId, edgeTypes, forceReload);

    const save = async () => {
        const { uploads } = await push();

        const promise = new Promise((resolve, reject) => {
            uploads.subscribe({
                complete: () => {
                    // Make sure that server has time to process the data before refreshing the attachments
                    setTimeout(async () => {
                        try {
                            fromId && (await refresh(true));
                            resolve();
                        } catch (e) {
                            reject(e);
                        }
                    }, 2500);
                },
                error: reject,
            });
        });
        uploads.start();

        return promise;
    };

    const addAttachments = (files, meetingId) => {
        files.forEach(file => {
            const attachmentId = newId();
            const filterHashtagFromFilename = filename => filename.replace(/#/g, '');
            const filename = filterHashtagFromFilename(file?.name || '');
            add(edgeTypes)(fromId)({ id: attachmentId, filename });
            meetingId && assoc(MEETING_ATTACHMENT_EDGE)(meetingId)({ id: attachmentId });
            setFile(attachmentId)(FILE_PROP_NAME, filename, file);
            inheritRights(attachmentId)(inheritFromId || fromId);
            update({ id: fromId, hasAttachments: true });
        });
    };

    const removeAttachment = (attachmentId, meetingId) => {
        const view = createAttachmentsView(fromId, edgeTypes);
        const attachmentBags = selectAttachments(getView(view));
        const isLast = attachmentBags.length === 1;
        fromId && isLast && update({ id: fromId, hasAttachments: false });
        remove(edgeTypes)(fromId)({ id: attachmentId });
        meetingId && remove(MEETING_ATTACHMENT_EDGE)(meetingId)({ id: attachmentId });
    };

    return {
        refresh,
        push,
        addAttachments,
        removeAttachment,
        save,
        setFile,
    };
};

export const useAttachments = (stack, fromId, edgeTypes) => {
    const { useView } = useGraph(...stack);
    const attachmentFns = useAttachmentFns(stack, fromId, edgeTypes);

    const view = createAttachmentsView(fromId, edgeTypes);
    const attachmentBags = useView(view, selectAttachments);

    return {
        attachmentBags,
        ...attachmentFns,
    };
};
