import { useState } from 'react';
import { getSignedUrl } from './hooks.config';

export const useFile = (nodeId, prop, accept) => {
    const [url, setUrl] = useState(null);

    const download = async () => {
        if (nodeId && prop) {
            const fileUrl = await getSignedUrl(nodeId, prop, accept).catch(() => null);
            if (fileUrl) {
                setUrl(fileUrl);
            } else {
                setUrl(null);
            }

            return fileUrl;
        }

        return null;
    };

    return [url, download];
};

// eslint-disable-next-line no-promise-executor-return
export const setTimeoutPromise = async ms => new Promise(resolve => setTimeout(resolve, ms));
