import React from 'react';
import * as RA from 'ramda-adjunct';
import { TopMobile } from './TopMobile';
import { TopButtonRowMobile } from './TopButtonRowMobile';
import { ListRow } from '../../Components/Common/ListRow';
import { SubscreenMenuButtonsRow } from './SubscreenMenuButtonsRow';

export const GlobalTopsListMobile = ({
    stack,
    setDashboardTops,
    setDashboardState,
    dashboardTops,
}) => (
    <div className="mobile-screen-container fader" style={{ marginBottom: '30vh' }}>
        <div
            className="column"
            style={{
                marginTop: '12rem',
            }}
        >
            {RA.mapIndexed(a => (
                <ListRow
                    key={a.nodeId}
                    style={{
                        margin: '2.5rem 1rem 3.5rem 1rem',
                    }}
                >
                    <div className="top-search-row">
                        <TopButtonRowMobile stack={stack} top={a} noEdit global />
                        <TopMobile stack={stack} topId={a.nodeId} global editable noButton noId />
                    </div>
                </ListRow>
            ))(dashboardTops)}
        </div>
        <SubscreenMenuButtonsRow
            onMoveBack={() => {
                setDashboardTops([]);
                setDashboardState('dashboard');
            }}
        />
    </div>
);
