import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import {
    BASE_STATE,
    DASHBOARD,
    DASHBOARD_FILTER_KEY,
    MAIN,
    MEETING_ATTACHMENT_EDGE,
    MEETING_ATTENDEE_EDGE,
    MEETING_ID_KEY,
    MEETING_TOP_EDGE,
    OFFLINE,
    PROJECT_ID_KEY,
    PROTOCOL,
    PROTOCOL_VARS,
    SUBPROJECT_ID_KEY,
    TOP_RESPONSIBLE_EDGE,
    useGraph,
} from '../../common/hooks.config.new';
import { useAttachmentFns } from '../../hooks/useAttachments';
import { useInitProjectChoices } from '../../hooks/useChoices';
import { useModal, useModalState } from '../../hooks/useModal';
import { useToast } from '../../hooks/useToast';
import { Screen } from '../Common/Screen';
import { Toast } from '../Elements/Toast';
import { AttachmentsModal, MODAL_ATTACHMENTS } from '../Modals/AttachmentsModal';
import { ContactsModal } from '../Modals/ContactsModal';
import { PrintModal } from '../Modals/PrintModal';
import { ReminderModal } from '../Modals/ReminderModal/ReminderModal';
import { ProjectOppsModal } from '../Modals/ProjectOppsModal';
import { ScoreCardModal } from '../Modals/ScoreCardModal';
import { useNode } from '../../hooks/useNode';
import './ProtocolScreen.css';
import { ProtocolTopCalculator } from '../Elements/ProtocolScreen/ProtocolTopCalculator';
import { createOfflinePush } from '../../common/got-adjunct';
import { useNavigator } from '../../hooks/useNavigator';
import { ProjectActionsModal } from '../Modals/ProjectActionsModal';
import { ReadingView } from '../Elements/ProtocolScreen/ReadingView';
import { AddDashboardTopModal } from '../Modals/AddDashboardTopModal';
import { useMeetingAttendees } from '../../hooks/useMeetingAttendees';
import { Loader } from '../Elements/Loader';
import { MeetingContent } from '../Elements/ProtocolScreen/MeetingContent';
import { ProtocolTopBar } from '../Elements/ProtocolScreen/ProtocolTopBar';

const stack = [MAIN, OFFLINE, DASHBOARD, PROTOCOL_VARS, PROTOCOL];
const varsStack = [MAIN, OFFLINE, DASHBOARD, PROTOCOL, PROTOCOL_VARS];

export const ProtocolScreen = () => {
    const { useVar, pull } = useGraph(...stack);
    const [projectId] = useVar(PROJECT_ID_KEY);
    const [subprojectId] = useVar(SUBPROJECT_ID_KEY);
    const [meetingId] = useVar(MEETING_ID_KEY);
    const { refresh } = useAttachmentFns(stack, meetingId, MEETING_ATTACHMENT_EDGE);
    useInitProjectChoices([MAIN, DASHBOARD]);
    const meetingView = {
        [meetingId]: {
            include: {
                node: true,
                // files: true,
            },
            edges: {
                [MEETING_TOP_EDGE]: {
                    include: {
                        node: true,
                        edges: true,
                        metadata: true,
                    },
                    edges: {
                        [TOP_RESPONSIBLE_EDGE]: {
                            include: {
                                node: true,
                                edges: true,
                            },
                        },
                    },
                },
                [MEETING_ATTENDEE_EDGE]: {
                    include: {
                        node: true,
                        edges: true,
                        metadata: true,
                    },
                },
            },
        },
    };
    const { node: meeting } = useNode(stack, meetingId);
    useEffect(() => {
        meetingId && pull(meetingView);
    }, [meetingId]);
    useEffect(() => {
        meetingId && refresh(true);
    }, [meetingId]);
    const openContactsModal = useModal('contacts');
    const openAttachmentsModal = useModal('attachments');
    const openPrintModal = useModal('print');
    const openAddDashboardTopsModal = useModal('add-dashboard-tops');
    const setProtocolToast = useToast('protocol');
    const setOfflineSyncToast = useToast('offlineSync');
    const toastConfig = {
        textOnStart: 'Saving protocol data...',
        textOnSuccess: 'Successfully saved.',
    };
    const push = createOfflinePush(stack, setProtocolToast, toastConfig);
    const { navigateToDashboard } = useNavigator();
    const [filter, setFilter] = useVar(DASHBOARD_FILTER_KEY);
    const [readingMode, setReadingMode] = useState(false);
    const [showAttendees, setShowAttendees] = useState(true);
    const sortedAttendeeBags = useMeetingAttendees(stack, meetingId);
    const attendeeCount = R.length(sortedAttendeeBags);
    const hasOfflineData = useSelector(
        R.anyPass(
            R.map(graphName => R.path([BASE_STATE, graphName, 'graph']))([OFFLINE, PROTOCOL]),
        ),
    );
    const setLoading = useModal('loader');
    const [isLoading] = useModalState('loader');
    return isLoading ? (
        <div style={{ display: 'flex', alignItems: 'center', height: '100vh' }}>
            <Loader />
        </div>
    ) : (
        <ProtocolScreenContent
            setLoading={setLoading}
            navigateToDashboard={navigateToDashboard}
            setOfflineSyncToast={setOfflineSyncToast}
            setReadingMode={setReadingMode}
            hasOfflineData={hasOfflineData}
            readingMode={readingMode}
            openAddDashboardTopsModal={openAddDashboardTopsModal}
            openAttachmentsModal={openAttachmentsModal}
            push={push}
            openPrintModal={openPrintModal}
            meeting={meeting}
            meetingId={meetingId}
            openContactsModal={openContactsModal}
            showAttendees={showAttendees}
            setShowAttendees={setShowAttendees}
            projectId={projectId}
            subprojectId={subprojectId}
            attendeeCount={attendeeCount}
            filter={filter}
            setFilter={setFilter}
        />
    );
};

const ProtocolScreenContent = ({
    setLoading,
    navigateToDashboard,
    setOfflineSyncToast,
    setReadingMode,
    hasOfflineData,
    readingMode,
    openAddDashboardTopsModal,
    openAttachmentsModal,
    push,
    openPrintModal,
    meeting,
    meetingId,
    openContactsModal,
    showAttendees,
    // setShowAttendees,
    projectId,
    subprojectId,
    attendeeCount,
    filter,
    setFilter,
}) => (
    <div className="screen-fader">
        <Screen
            topbar={
                <ProtocolTopBar
                    stack={stack}
                    setLoading={setLoading}
                    navigateToDashboard={navigateToDashboard}
                    setOfflineSyncToast={setOfflineSyncToast}
                    setReadingMode={setReadingMode}
                    hasOfflineData={hasOfflineData}
                    readingMode={readingMode}
                    openAttachmentsModal={openAttachmentsModal}
                    push={push}
                    openPrintModal={openPrintModal}
                    meetingId={meetingId}
                    subprojectId={subprojectId}
                />
            }
            lpheight={60}
        >
            {meeting &&
                (readingMode ? (
                    <div>
                        <ReadingView stack={stack} meetingId={meetingId} />
                    </div>
                ) : (
                    <MeetingContent
                        stack={stack}
                        meetingId={meetingId}
                        projectId={projectId}
                        subprojectId={subprojectId}
                        meeting={meeting}
                        openContactsModal={openContactsModal}
                        openAddDashboardTopsModal={openAddDashboardTopsModal}
                        showAttendees={showAttendees}
                        attendeeCount={attendeeCount}
                        filter={filter}
                        setFilter={setFilter}
                    />
                ))}
        </Screen>
        <ContactsModal stack={stack} meetingId={meetingId} protocol />
        <AttachmentsModal stack={stack} name={MODAL_ATTACHMENTS} />
        <ProjectOppsModal stack={stack} noEdit />
        <ProjectActionsModal stack={stack} noEdit />
        <ScoreCardModal stack={stack} noEdit />
        <PrintModal stack={stack} />
        <ReminderModal stack={stack} />
        <AddDashboardTopModal stack={stack} />
        <ProtocolTopCalculator stack={varsStack} />
        <Toast name="protocol" />
        <Toast name="offlineSync" />
    </div>
);
