import React, { useState } from 'react';
import * as R from 'ramda';
import { IoLogOutOutline, IoSettingsOutline } from 'react-icons/io5';
import { useSpring, animated } from 'react-spring';
import { Portal } from 'react-overlays';
import { useAuth } from '../../../common/auth';
import { Button } from '../../Common/Controls';
import { RefreshButton } from './RefreshButton';
import { PullModeToggle } from './PullModeToggle';
import { ALWAYS_PULL, useSettings } from '../../../hooks/useSettings';

export const DashboardMenu = ({ stack }) => {
    const [, , logout] = useAuth();
    const [menu, setMenu] = useState(false);
    const [settings, setSettings] = useSettings();
    const menuMenu = useSpring({
        transform: menu
            ? 'scale(1) translateX(0%) translateY(0%)'
            : 'scale(0) translateX(50%) translateY(-50%)',
        opacity: menu ? 1 : 0,
    });
    const menuButton = useSpring({
        transform: menu ? 'rotate(-15deg)' : 'rotate(15deg)',
        color: menu ? 'var(--corporate-color-13)' : 'white',
    });
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'flex-end',
                zIndex: 2,
            }}
        >
            {!R.propOr(true, ALWAYS_PULL, settings) && <RefreshButton stack={stack} />}
            <Button
                onClick={() => setMenu(!menu)}
                style={{
                    paddingLeft: '0.2rem',
                    paddingRight: '0.2rem',
                    height: '2.5rem',
                    width: '2.5rem',
                }}
            >
                <animated.div style={menuButton}>
                    <IoSettingsOutline size={26} />
                </animated.div>
            </Button>
            <Portal>
                <animated.div className="dashboard-menu" style={menuMenu}>
                    <Button onClick={logout} className="dashboard-menu-button">
                        <IoLogOutOutline size={28} style={{ marginRight: 6 }} />
                        Logout
                    </Button>
                    <PullModeToggle settings={settings} setSettings={setSettings} />
                </animated.div>
            </Portal>
        </div>
    );
};
