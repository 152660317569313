import * as R from 'ramda';
import { useMemo } from 'react';
import {
    CONTACT_DIRECTORY_CONTACT_EDGE,
    createGraph,
    store,
    useGraph,
} from '../common/hooks.config.new';
import { Id } from '../common/util';

const constructContactsView = projectId =>
    projectId
        ? {
              [Id.contactDirectory(projectId)]: {
                  include: {
                      rights: true,
                  },
                  as: 'contactDirectory',
                  edges: {
                      [CONTACT_DIRECTORY_CONTACT_EDGE]: {
                          as: 'contacts',
                          include: {
                              node: true,
                              edges: true,
                          },
                      },
                  },
              },
          }
        : {};

export const selectContacts = R.compose(
    R.sortBy(R.path(['node', 'name'])),
    R.values,
    R.pathOr({}, ['contactDirectory', 'contacts']),
);

export const selectContactBagWithEmail = email =>
    R.find(bag => {
        const contactEmail = R.path(['node', 'email'], bag);
        return contactEmail && contactEmail.toLowerCase() === email.toLowerCase();
    });

export const getProjectContacts = (stack, projectId) => {
    const contactsView = constructContactsView(projectId);
    const viewRes = store.getView(...stack)(contactsView);

    const contactBags = selectContacts(viewRes);
    return contactBags;
};

export const useProjectContactsFns = (stack, projectId) => {
    const { pull, add, inheritRights } = createGraph(...stack);

    const contactsView = useMemo(() => constructContactsView(projectId), [projectId]);
    const refresh = useMemo(() => () => pull(contactsView), [contactsView]);

    const getContracts = () => getProjectContacts(stack, projectId);

    const addContact = useMemo(
        () => add(CONTACT_DIRECTORY_CONTACT_EDGE)(Id.contactDirectory(projectId)),
        [projectId],
    );
    const inheritFromContactDirectory = useMemo(
        () => nodeId => inheritRights(nodeId)(Id.contactDirectory(projectId)),
        [projectId],
    );

    return {
        contactsView,
        refresh,
        getContracts,
        addContact,
        inheritFromContactDirectory,
    };
};

export const useProjectContacts = (stack, projectId) => {
    const { useView } = useGraph(...stack);

    const { contactsView, addContact, inheritFromContactDirectory, refresh } =
        useProjectContactsFns(stack, projectId);

    const contactBags = useView(contactsView, selectContacts);
    const userCDRights = useView(
        contactsView,
        R.compose(
            R.path(['rights', 'user']),
            R.omit(['contacts', 'nodeId']),
            R.prop('contactDirectory'),
        ),
    );
    return {
        refresh,
        addContact,
        inheritFromContactDirectory,
        contactBags,
        userCDRights,
    };
};
