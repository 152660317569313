import * as R from 'ramda';
import { formatCurrency, formatShortDate } from '../../../common/util';

export const elseBody = selectedTop => `Dear ${
    selectedTop.responsible ? selectedTop.responsible.name : 'responsible'
},

please have a look at the following ${R.propOr(
    '',
    selectedTop.top.type,
)({
    s: 'statement',
    d: 'decision',
    i: 'information',
})}:

Business ID: ${selectedTop?.top?.businessId}
Description: ${selectedTop?.top?.description}

Best regards,
${selectedTop.userName}`;

export const riskBody = selectedTop => `Dear ${
    selectedTop.responsible ? selectedTop.responsible.name : 'responsible'
},

please have a look at the following risk:

Business ID: ${selectedTop?.top?.businessId}
Description: ${selectedTop?.top?.description}
Reference: ${selectedTop?.top?.reference}
Category: ${selectedTop?.top?.category}
Cost increase: ${formatCurrency(selectedTop?.currency, selectedTop?.top?.costIncrease)}

Best regards,
${selectedTop.userName}`;

export const oppBody = selectedTop => `Dear ${
    selectedTop.responsible ? selectedTop.responsible.name : 'responsible'
},

please have a look at the following opportunity:

Business ID: ${selectedTop?.top?.businessId}
Description: ${selectedTop?.top?.description}
Status: ${selectedTop?.top?.status}
Category: ${selectedTop?.top?.category}
Savings: ${formatCurrency(selectedTop?.currency, selectedTop?.top?.savings)}

Best regards,
${selectedTop.userName}`;

export const actionBody = selectedTop => `Dear ${
    selectedTop.responsible ? selectedTop.responsible.name : 'responsible'
},

please have a look at the following action:

Business ID: ${selectedTop?.top?.businessId}
Description: ${selectedTop?.top?.description}
Status: ${selectedTop?.top?.status}
Due date: ${formatShortDate(selectedTop?.top?.dueDate)}

Best regards,
${selectedTop?.userName}`;

export const getTextBody = selectedTop => {
    if (selectedTop.top.type === 'a') {
        return actionBody(selectedTop);
    }
    if (selectedTop.top.type === 'r') {
        return riskBody(selectedTop);
    }
    if (selectedTop.top.type === 'o') {
        return oppBody(selectedTop);
    }
    return elseBody(selectedTop);
};

const subjectTypes = {
    a: 'Action',
    o: 'Opportunity',
    r: 'Risk',
    s: 'Statement',
    d: 'Decision',
    i: 'Information',
};

export const getSubject = (project, selectedTop) => {
    const { title } = project;
    const { type } = selectedTop.top;

    return `[${title}] - ${subjectTypes[type] || ''} reminder`;
};
