/* eslint-disable react/jsx-props-no-spreading */
import * as R from 'ramda';
import React, { memo } from 'react';
import {
    IoAnalyticsOutline,
    IoCloseOutline,
    IoLinkOutline,
    IoAddOutline,
    IoArrowUpOutline,
    IoArrowDownOutline,
    IoDocumentAttachOutline,
} from 'react-icons/io5';
import { MdOutlineDragIndicator } from 'react-icons/md';
import { Button } from './Controls';
import './ListRow.css';
import { DragHandle } from './SortableList';

const _ListRow = ({
    children,
    onMove,
    onInsert,
    onRemove,
    buttonColor,
    opp,
    openScorecard,
    openProjectOpps,
    onAttachmentClick,
    openProjectActions,
    topId,
    style,
    draggable = false,
    onDrag,
    className,
    onClick,
}) => (
    <div
        className={`list-row ${className}`}
        style={style}
        draggable={draggable}
        onDragStart={onDrag}
        onClick={onClick}
    >
        {(onMove || onInsert || onRemove) && (
            <div className="list-row-buttons">
                {buttonColor !== 'white' && onAttachmentClick && (
                    <Button
                        onClick={() => onAttachmentClick()}
                        style={{ marginRight: 24, height: 30 }}
                    >
                        <IoDocumentAttachOutline size={23} color="var(--corporate-color-1)" />
                        <p>Files</p>
                    </Button>
                )}
                {opp && (
                    <>
                        <Button onClick={() => openScorecard({ id: topId })} style={{ height: 30 }}>
                            <IoAnalyticsOutline size={22} color="var(--corporate-color-1)" />
                            <p>Scorecard</p>
                        </Button>
                        <Button
                            onClick={() => openProjectActions({ id: topId })}
                            style={{
                                marginLeft: 12,
                                marginRight: 24,
                                height: 30,
                            }}
                        >
                            <IoLinkOutline size={23} color="var(--corporate-color-1)" />
                            <p>Link actions</p>
                        </Button>
                    </>
                )}
                {!opp && buttonColor !== 'white' && (
                    <Button
                        onClick={() => openProjectOpps({ id: topId })}
                        style={{ marginRight: 24, height: 30 }}
                    >
                        <IoLinkOutline size={23} color="var(--corporate-color-1)" />
                        <p>Link opportunity</p>
                    </Button>
                )}
                <DragHandle>
                    <Button style={{ height: 30, color: buttonColor }}>
                        <MdOutlineDragIndicator style={{ transform: 'rotate(90deg)' }} size={23} />
                    </Button>
                </DragHandle>
                {onMove && (
                    <Button
                        style={{
                            right: '120px',
                            color: buttonColor,
                            height: 30,
                        }}
                        onClick={() => onMove(-1)}
                    >
                        <IoArrowUpOutline size={17} />
                    </Button>
                )}
                {onMove && (
                    <Button
                        style={{
                            right: '84px',
                            color: buttonColor,
                            height: 30,
                        }}
                        onClick={() => onMove(1)}
                    >
                        <IoArrowDownOutline size={17} />
                    </Button>
                )}
                {onInsert && (
                    <Button
                        style={{
                            right: '48px',
                            color: buttonColor,
                            height: 30,
                        }}
                        onClick={onInsert}
                    >
                        <IoAddOutline size={20} />
                    </Button>
                )}
                {onRemove && (
                    <Button
                        onClick={onRemove}
                        style={{
                            right: '48px',
                            color: buttonColor,
                            height: 30,
                        }}
                    >
                        <IoCloseOutline size={20} />
                    </Button>
                )}
            </div>
        )}
        {children}
    </div>
);

export const ListRow = memo(_ListRow, R.equals);
